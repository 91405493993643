import request from '@/utils/request'
export function home () {
    return request({
      url: '/api/home',
      method: 'get',
    })
}
export function region (data) {
  return request({
    url: '/api/system/region',
    method: 'put',
    data
  })
}