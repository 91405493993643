
<template>
  <div class="content" v-loading="loading">
    <b-container fluid >
      <b-col md="12" >
          <b-row class="drs" >
              <h5 class="name mgb20 tc ft21" v-if="mid == 3">
                  {{ $t('welcome.project_sharing') }}
              </h5>
              <h5 class="name mgb20 tc ft21" v-else-if="mid == 1">{{ $t('welcome.design_style') }}</h5>
              <h5 class="name mgb20 tc ft21" v-else-if="mid == 5"> {{ $t('welcome.speech_guidance') }}</h5>
              <h5 class="name mgb20 tc ft21" v-else-if="mid == 6">{{ $t('welcome.learning_resources') }}</h5>
              <h5 class="name mgb20 tc ft21" v-else-if="mid == 20">{{ $t('welcome.project_photos') }}</h5>
              <h5 class="name mgb20 tc ft21" v-else>{{ $t('welcome.news') }}</h5>


              <ul class="drs-list" :class="resultList.length<= 4? 'center':''" >

                    <li class="thumb"  @click="goPage(item.id)" v-for="(item,index) in resultList" :key="index">
                        <div class="img-box flex">
                            <img :src="url+ 'misc/tiles/designres-design-deliverables.jpg'" v-if="item.id == 32">
                            <img :src="url+ 'misc/tiles/designres-design-guideline.jpg'" v-else-if="item.id == 27" >
                            <img :src="url+ 'misc/tiles/cat-img-26.jpg'" v-else-if="item.id == 8">
                            <img :src="url+ 'misc/tiles/photo1.jpg'" v-else-if="item.id == 24">
                            <img :src="url+ 'misc/tiles/photo2.jpg'" v-else-if="item.id == 25">
                            <img :src="url+ 'misc/tiles/photo3.jpg'" v-else-if="item.id == 26">
                            <img :src="url+ 'misc/tiles/designres-sldtalk.jpg'" v-else-if="item.id == 30">
                            <img :src="url+ 'misc/tiles/designres-product-seminars.jpg'" v-else-if="item.id == 31">
                            <img :src="url+ 'misc/tiles/cat-img-24.jpg'" v-else-if="item.id == 6">
                            <img :src="url+ 'misc/tiles/designres-exhibition-sharings.jpg'" v-else-if="item.id == 33">
                            <img :src="url+ 'misc/tiles/cat-img-21.jpg'" v-else-if="item.id == 3">
                            <img :src="url+ 'misc/tiles/cat-img-43.jpg'" v-else-if="item.id == 7">
                            <img :src="url+ 'misc/tiles/designres-design-health.jpg'" v-else-if="item.id == 22">
                            <img :src="url+ 'misc/tiles/designres-design-guide-design-considerations.jpg'" v-else-if="item.id == 35">
                            <img :src="url+ 'misc/tiles/designres-design-product.jpg'" v-else-if="item.id == 23">
                            <img :src="url+ 'misc/tiles/designres-standard-provision.jpg'" v-else-if="item.id == 36">
                            <img :src="url+ 'misc/tiles/designres-tm004.jpg'"  v-else-if="item.id == 37">
                            <img :src="url+ 'misc/tiles/designres-sales-training.jpg'" v-else-if="item.id == 39">
                            <img :src="url+ 'misc/tiles/designres-internal-training.jpg'" v-else-if="item.id == 40">
                            <img :src="url+ 'misc/tiles/designres-annual-awards.jpg'" v-else-if="item.id == 41">
                            <img :src="url+ 'misc/tiles/designres-company-info.jpg'" v-else-if="item.id == 42">
                            <img :src="url+ 'misc/tiles/designres-management-meeting.jpg'" v-else-if="item.id == 43">
                            <img :src="url+ 'misc/tiles/designres-interior-staging.jpg'" v-else-if="item.id == 45">
                            <img :src="url+ 'misc/tiles/designres-magazines.jpg'" v-else-if="item.id == 46">
                            <img :src="url+ 'misc/tiles/designres-artwork.jpg'" v-else-if="item.id == 47">

                        </div>
                        <span>{{item.name}}</span>
                    </li>
                </ul>



          </b-row>

      </b-col>

    </b-container>
  </div>
</template>

<script>
import { getDesignRef } from "@/api/design";
export default {
  data(){
    return{
      url: process.env.VUE_APP_IMG_URL,
      mid:3,
      resultList:[],
      loading:false
    }
  },
  created(){
    this.mid = this.$route.query.mid;
    this.getDesign();

  },
  watch:{
      '$route' () {
        this.mid = this.$route.query.mid;
          this.getDesign();

      }

    },

  methods:{
    getDesign(){
      if (this.mid) {
        this.loading = true;
        getDesignRef({mid:this.mid}).then(res=>{

          this.loading = false;
          this.resultList = res.data;
        })
      }

    },
    goPage(id){
      // this.$router.push(url)
      if(this.mid == 20){
          this.$router.push(`/project-photo?id=${id}`)
      }else if(this.mid == 3){
         if(id==32 ){
            this.$router.push('/deliverable')
         }else if(id==27){
            this.$router.push('/guideline?mid=20')
         }else if(id==8){
           this.$router.push(`/design-guideline?mid=${id}`)
         }
      }else if(this.mid == 6){
        this.$router.push(`/design-guideline?mid=${id}`)
        /*
        if(id == 35){
          //  this.$router.push(`/design-guideline?mid=${id}`)
           this.$router.push(`/project-file?mid=${id}&id=66`)
        }else if(id==36){
          this.$router.push(`/project-file?mid=${id}&id=10`)
        }
        */
      }

    }

  }
}
</script>

<style lang="scss" scoped>
.content{
  padding: 25px 0 40px;
  overflow-x: hidden;
  .name{
    padding: 20px 0px 20px;
    color: #333;
    .item{
      color: #999;
      &:hover{
        color: #950c0f;
      }
    }
  }
  .drs{
    max-width: 1530px;
    margin: 0 auto;
    text-align: left;
    .col-12 {
      padding: 0;
    }
    .center{
      text-align: center !important;
    }
    @media (max-width: 1559px) {
      .drs-list {
        // text-align: justify;
        .thumb {
          padding: 7.5px;
        }
      }

    }

    @media (max-width: 1023px) {
      /*margin: 0 -15px;*/
      .drs-list {
        .thumb {
          width: 33.33%;
          span{
            padding: 15px 0 8px;
          }
        }
      }
    }
    @media (max-width: 767px) {
      /*margin: 0 -15px;*/
      .drs-list {
        width: auto;
        margin-bottom: 50px;
        .thumb {
          margin: 0 0 20px 0;
        }
      }

    }
    @media (max-width: 567px) {
      .drs-list {
        .thumb {
          width: 50%;
          font-size: 13px;
          span{
            font-size: 15px;
          }
        }
      }
    }
    @media (max-width: 375px) {
      .drs-list {
        .thumb {
          span{
            font-size: 13px;
          }
        }
      }
    }
  }


}
.drs-list{

  margin: 0 auto;
  /* max-width: 1100px; */
  width: 100%;
  padding: 0;
  text-align: justify;
  font-size: 0;
  margin-bottom: 50px;
//   > ul{
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: space-between;
//   }
  .thumb{
    z-index: 0;
    display: inline-block;
    width: 20%;
    text-align: left;
    font-size: 15px;
    margin: 0px 0px 1% 0;
    padding: 15px;
    vertical-align: top;
    cursor: pointer;
    .img-box{
      position: relative;
      overflow: hidden;
      img{
        width: 100%;
        height: auto;
        transition: transform 1s;
        transition-timing-function: ease;
      }
    }
    span{
      display: block;
      font-size: 18px;
      padding: 20px 0 10px;
      text-align: center;
      letter-spacing: 1px;
      color: #555555;
      position: relative;
    }
    &:hover{
      img{
        transform: scale(1.1);
      }
    }
  }
  .forJustify{
    border: none;
    background: none;
    height: 1px;
    padding: 0px;
    /* margin: 0 2% 0 0; */

  }
}

</style>
