<template>
  <div>
    <ul class="product-list-small" v-if="productList.length > 0">
      <li class="greenAd" v-if="libInfo != null">
        <swiper class="owl-carousel" :options="swiperOption" ref="mySwiper">
          <swiper-slide>
            <div
              class="slide"
              :style="{ backgroundImage: 'url(' + url + libInfo.photo_1 + ')' }"
            ></div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="slide"
              :style="{ backgroundImage: 'url(' + url + libInfo.photo_2 + ')' }"
            ></div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="slide"
              :style="{ backgroundImage: 'url(' + url + libInfo.photo_3 + ')' }"
            ></div>
          </swiper-slide>
        </swiper>
      </li>
      <li class="greenAd" v-else-if="hl != null && hl == 2">
        <swiper class="owl-carousel" :options="swiperOption" ref="mySwiper">
          <swiper-slide>
            <a href="/#/project-file?mid=48&id=1014">
            <div
              class="slide"
              :style="{ backgroundImage: 'url(' + url +'misc/in-house-collections-1.jpg)' }"
            ></div>
            </a>
          </swiper-slide>
          <swiper-slide>
            <a href="/#/project-file?mid=48&id=1014">
            <div
              class="slide"
              :style="{ backgroundImage: 'url(' + url +'misc/in-house-collections-2.jpg)' }"
            ></div>
            </a>
          </swiper-slide>
          <swiper-slide>
            <a href="/#/project-file?mid=48&id=1014">
            <div
              class="slide"
              :style="{ backgroundImage: 'url(' + url +'misc/in-house-collections-3.jpg)' }"
            ></div>
            </a>
          </swiper-slide>
          <swiper-slide>
            <a href="/#/project-file?mid=48&id=1014">
            <div
              class="slide"
              :style="{ backgroundImage: 'url(' + url +'misc/in-house-collections-4.jpg)' }"
            ></div>
            </a>
          </swiper-slide>
          <swiper-slide>
            <a href="/#/project-file?mid=48&id=1014">
            <div
              class="slide"
              :style="{ backgroundImage: 'url(' + url +'misc/in-house-collections-5.jpg)' }"
            ></div>
            </a>
          </swiper-slide>
          <swiper-slide>
            <a href="/#/project-file?mid=48&id=1014">
            <div
              class="slide"
              :style="{ backgroundImage: 'url(' + url +'misc/in-house-collections-6.jpg)' }"
            ></div>
            </a>
          </swiper-slide>
        </swiper>
      </li>
      <li v-for="(item, index) in productList" :key="index">
        <div
          class="product-photo"
          @click="
            getDetail(
              item.id,
              item.opt_id,
              item.subopt_id,
              hl ? hl : '',
              item.input_mode
            )
          "
        >
          <div class="product-photo-con">
            <img
              class="img"
              v-lazy="url + item.path + '/s300'"
              height="105"
              width="150"
            />
          </div>
        </div>
        <div class="info-expand">
          <div style="position: relative">
            <p
              class="product-brand"
              v-html="`${item.brand.name}-${item.brand_series.name}`"
            ></p>
            <h2
              @click="
                getDetail(
                  item.id,
                  item.opt_id,
                  item.subopt_id,
                  hl ? hl : '',
                  item.input_mode
                )
              "
              v-html="item.name"
            ></h2>
            <p class="product-price">
              <span v-if="item.brand.id == 134 || hl == 2">{{
                $t("productfilter.costprice")
              }}</span>
              <span v-else>{{ $t("productfilter.retailprice") }}</span>
              <br />
              <!-- Task: sync with TA API -->
              <b class="bold"> {{ item.show_price }} {{ item.input_mode==0?"/ "+(item.punit?item.punit:item.unit):"" }}</b>
            </p>
            <div class="size-expand">
              <div class="pdb7" v-if="item.wd_dia">
                {{ $t("productfilter.Size") }}(mm): {{ item.len?item.len+"(L) x":"" }}
                {{ item.wd_dia?item.wd_dia+"(W/D) ":"" }} {{ item.hgt?"x "+item.hgt+"(H)":"" }}
              </div>
              <div
                class="trim-block"
                v-if="item.remark"
                v-html="item.remark"
              ></div>
              <div class="pdb7" v-if="item.production_lead_time">
                {{ $t("productfilter.Leadtime") }}:
                {{ item.production_lead_time }} {{ $t("productfilter.Day") }}
              </div>

            </div>
            <wish-category
              v-if="name === 'wishList' && categories"
              @updateWishCategory="updateWishCategory"
              :categories="categories[index][item.id]"
              :wish-category-list="wishCategoryList"
              :prod-id="item.id"
            />
          </div>
          <div style="position: absolute; left: 0; bottom: 0px; width: 100%">
            <div class="product-date">
              <span
                class="tax-ship"
                v-if="
                  item.inc_ship == 1 ||
                  item.inc_vat == 1 ||
                  (item.brand.brand_tag > 0 && item.brand.brand_tag < 4)
                "
              >
                <span>
                  <i
                    v-if="item.designer == 1"
                    class="fa fa-handshake-o"
                    :title="$t('productfilter.brand_products')"
                  ></i>
                  <i
                    v-else-if="item.brand.brand_tag == 1"
                    class="fa fa-handshake-o"
                    :title="$t('productfilter.brands')"
                  ></i>
                  <i
                    v-else-if="item.brand.brand_tag == 2"
                    class="fa fa-handshake-o"
                    :title="$t('productfilter.recommended_brands')"
                  ></i>
                  <i
                    v-else-if="item.brand.brand_tag == 3"
                    class="fa fa-handshake-o"
                    :title="$t('productfilter.registered_brands')"
                  ></i>
                </span>
                <span v-if="item.inc_ship == 1">
                  <i
                    class="fa fa-truck"
                    :title="$t('productfilter.inc_ship_cost')"
                  ></i>
                </span>
                <span v-if="item.inc_vat == 1">
                  <i class="icon-tax" :title="$t('productfilter.inc_vat')"></i>
                </span>
              </span>
              <span class="product-updated"
                >{{ $t("productfilter.Lastupdate") }}:
                {{ item.price_last_update | formatCreateTime }}</span
              >
            </div>

            <div class="product-count">
              <b-col cols="6">
                <span title="Viewed">
                  <i class="fa fa-eye"></i> {{ item.page_view || 0 }} </span
                >&nbsp;
                <span class="icon-wishlist" @click="getCategory(item)">
                  <i
                    class="fa mgr3"
                    :class="
                      item.wished || name === 'wishList'
                        ? 'fa-heart'
                        : 'fa-heart-o '
                    "
                  ></i>
                  <span>{{ item.added_wish_count }}</span>
                </span>
              </b-col>
              <div class="tr action-btn" v-if="item.file_cad || item.file_ppt || item.file_spec || item.file_3d">
                <!-- -->
                <div class="download-div">
                  <div class="product-footer">
                    <div class="download-section">
                      <div class="option" v-if="item.file_cad">
                        <a target="_blank" :href="url + item.file_cad">
                          <i class="fa fa-download"></i>CAD
                        </a>
                      </div>
                      <div class="option" v-if="item.file_ppt">
                        <a target="_blank" :href="url + item.file_ppt">
                          <i class="fa fa-download"></i>PPT
                        </a>
                      </div>
                      <div class="option" v-if="item.file_spec">
                        <a target="_blank" :href="url + item.file_spec">
                          <i class="fa fa-download"></i>SPEC
                        </a>
                      </div>
                      <div class="option" v-if="item.file_3d">
                        <a target="_blank" :href="url + item.file_3d">
                          <i class="fa fa-download"></i>3D
                        </a>
                      </div>
                    </div>
                  </div>
                  <span class="icon-download">
                    <i class="fa fa-download"></i>
                  </span>
                </div>
              </div>
              <div
                class="cert-icons flex flex-wrap mgt10"
                v-if="item.certs.length > 0"
              >
                <div
                  class="mgr5"
                  v-for="(cert, index) in item.certs"
                  :key="index"
                  @click="showCertInfo(cert)"
                >
                  <img :src="url + item.path" v-if="item.path" />
                  <span v-else> {{ item.name_tc }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="forJustify"></li>
      <li class="forJustify"></li>
      <li class="forJustify"></li>
      <li class="forJustify"></li>
      <li class="forJustify"></li>
      <li class="forJustify"></li>
    </ul>
    <div
      class="no-data pdt20 pdb20"
      v-if="productList.length == 0 && load == false"
    >
      <h3>{{ $t("common.noData") }}</h3>
    </div>

    <report
      :isVisible="isVisible"
      :inputmode="inputmode"
      :subopt="subopt"
      :pictLoading="pictLoading"
      :hl="hl ? hl : ''"
      ref="report"
      @reportChange="reportChange"
      @changeMaterial="changeMaterial"
    />
    <el-dialog
      class="cert-dialog"
      :visible.sync="certVisible"
      width="320px"
      :show-close="false"
    >
      <img class="swal2-image" :src="url + certInfo.img_path" width="150" />
      <h2 class="swal2-title tc mgt10">{{ certInfo.name_tc }}</h2>
      <p>{{ certInfo.desc_tc }}</p>
    </el-dialog>
    <!-- category -->
    <el-dialog
      class="category-dialog"
      :width="screenWidth > '768' ? '40%' : '90%'"
      :visible.sync="categoryFormVisible"
    >
      <div v-show="categoryShow">
        <b-row align-h="start" align-v="center">
          <b-col cols="6">
            <b-form-select
              class="select-list"
              v-model="categoryVal"
              :options="categoryList"
            ></b-form-select>
          </b-col>
          <b-col :cols="screenWidth > '768' ? '3' : '5'">
            <span class="creat-category" @click="creatChange">{{
              $t("productfilter.CreateCategory")
            }}</span>
          </b-col>
        </b-row>
      </div>
      <div v-show="creatCategoryShow">
        <b-row align-h="start" align-v="center">
          <b-col :cols="screenWidth > '768' ? '2' : '4'">
            <label for="input-none"
              >{{ $t("productfilter.CategoryName") }}:</label
            >
          </b-col>
          <b-col :cols="screenWidth > '768' ? '6' : '5'">
            <b-form-input
              type="text"
              class="form-control multiselect-search"
              id="input-small-1"
              size="sm"
              v-model="createCategoryValue"
            ></b-form-input>
          </b-col>
          <b-col cols="1"
            ><el-button size="mini" type="info" @click="creatCategory">{{
              $t("productfilter.AddBtn")
            }}</el-button></b-col
          >
        </b-row>
      </div>
      <div v-show="categoryShow" slot="footer" class="dialog-footer">
        <el-button size="mini" type="info" @click="addProductWish">{{
          $t("productfilter.Confirm")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { formatDate } from "@/utils/date";
import {
  getProductDetial,
  getProductsubopt,
  addWish,
  categoryList,
  creatCategory
} from "@/api/product";
import report from "@/components/ProductDetail.vue";
import CategoryCom from "@/components/WishCategory.vue";
// import categoryDetail from '@/components/CategoryproductDetail.vue';
export default {
  props: {
    wishCategoryList: {
      type: Array
    },
    categories: {
      type: Array
    },
    name: {
      type: String
    },
    productList: {
      type: Array
    },
    hl: {
      type: [String,Number]
    },
    load: {
      type: Boolean
    },
    libInfo: {
      type: Object
    }
  },
  data() {
    return {
      showTag: "",
      showChange: false,
      createCategoryValue: "",
      categoryShow: true,
      creatCategoryShow: false,
      categoryFormVisible: false,
      categoryVal: "",
      categoryList: [],
      formLabelWidth: "80px",
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 7000,
        },
        effect: "fade",
        speed: 1000
      },
      url: process.env.VUE_APP_IMG_URL,
      itemPhotos: [
        this.url + "/library/8/483d473d1bf94d3b9e5ebaeffe4d3202.png",
        this.url + "/library/7/544af83e8321b4eaf264055b641dc849.PNG"
      ],
      isVisible: false,
      detailData: [],
      pictLoading: false,
      subopt: {},
      screenWidth: document.body.clientWidth,
      wishStatus: false,
      certVisible: false,
      certInfo: [],
      inputmode: 0,
      productInfo: "",
      forRegion: window.localStorage.getItem("forRegion")
    };
  },
  components: {
    report,
    swiper,
    "swiper-slide": swiperSlide,
    "wish-category": CategoryCom
    // 'category-detail':categoryDetail
  },
  filters: {
    formatCreateTime(time) {
      let date = new Date(time);
      return formatDate(date, "yyyy/MM/dd");
    }
  },
  methods: {
    updateWishCategory() {
      this.$emit("updateWishCategory");
    },
    creatChange() {
      this.categoryShow = false;
      this.creatCategoryShow = true;
      this.categoryVal = null;
      this.createCategoryValue = "";
    },
    getDetail(id, optID, subOptID, hl, input_mode) {
      this.inputmode = input_mode;
      if (this.screenWidth < 768) {
        let hlVal = hl == "undefined" ? "" : hl;
        this.$router.push(
          `/productdetail?id=${id}&optID=${optID}&subOptID=${subOptID}&hl=${hlVal}`
        );
      } else {
        this.isVisible = true;
        this.pictLoading = true;
        console.log(this.forRegion);
        const data = {
          optID: optID,
          subOptID: subOptID,
          hl: hl,
          forRegion: this.forRegion
        };
        getProductDetial(id, data).then((res) => {
          this.pictLoading = false;
          console.log(res.data);
          this.$refs.report.getDetail(res.data);
        });
        // this.getsubopt(optID)
      }
    },
    reportChange(val) {
      this.isVisible = val;
      // this.getMySellArray();
    },
    getsubopt(id) {
      const data = {
        forRegion: this.forRegion
      };
      getProductsubopt(id, data).then((res) => {
        this.subopt = res.data;
        if (this.subopt.price == "HKD$ 0") {
          this.subopt.price = this.$t("productfilter.product_tbc");
        }
      });
    },
    changeMaterial(id) {
      this.getsubopt(id);
    },
    //add wish
    getCategory(item) {
      this.productInfo = item;
      if (!this.productInfo.wished && this.name === "product") {
        this.categoryShow = true;
        this.creatCategoryShow = false;
        this.categoryFormVisible = true;
        const data = {
          pid: this.productInfo.id
        };
        categoryList(data).then((res) => {
          const newList = [
            { text: `${this.$t("productfilter.NotCategory")}`, value: null }
          ];
          res.data.map((el) => {
            newList.push({ value: el.id, text: el.name });
          });
          this.categoryList = newList;
          if (!this.categoryVal && this.categoryList.length > 0) {
            this.categoryVal = this.categoryList[0].value;
          }
        });
      } else if (this.name === "wishList") {
        this.$emit("deleteWish", this.productInfo.id);
      }
    },
    creatCategory() {
      const data = {
        name: this.createCategoryValue
      };
      creatCategory(data).then((res) => {
        this.getCategory(this.productInfo);
      });
    },
    addProductWish() {
      this.categoryFormVisible = false;
      addWish({ pid: this.productInfo.id, category_id: this.categoryVal }).then(
        (res) => {
          if (res.code == 200) {
            this.productInfo.added_wish_count =
              this.productInfo.added_wish_count + 1;
            this.productInfo.wished = true;
          }
        }
      );
    },
    showCertInfo(data) {
      this.certInfo = data;
      this.certVisible = true;
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  }
};
</script>
<style lang="scss" scope="scoped">
.product-list-small {
  margin: 15px auto 0;
  width: 100%;
  padding: 0;
  text-align: center;
  text-align: justify;
  font-size: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .tax-ship {
    display: inline-block;
    font-size: 11px;
    font-weight: normal;
    /* font-style: italic; */
    color: #aaaaaa;
    clear: both;
    margin-right: 3px;
    i {
      color: #aaaaaa;
      margin-right: 3px;
    }
  }
  li {
    /* display: inline-block; */
    box-sizing: border-box;
    text-align: left;
    font: bold 18px sans-serif;
    background-color: #ffffff;
    /* border: 1px solid #DDDDDD; */
    /* border: 1px solid #ffffff; */
    /* max-width: 250px; */
    width: 15.2%;
    margin: 1% 0;
    /* padding: 18px; */
    padding: 8px 0 75px;
    vertical-align: top;
    position: relative;
    overflow: hidden;
    @media (max-width: 1650px) {
      width: 15.5%;
    }
    @media (max-width: 1500px) {
      width: 24%;
    }

    @media (max-width: 1100px) {
      width: 32%;
    }
    @media (max-width: 900px) {
      width: 48%;
      /*padding: 5px;*/
      margin: 2% 0;
    }
    @media (max-width: 758px) {
      /* z-index: 0; */
      display: inline-block;
      box-sizing: border-box;
      text-align: left;
      font: bold 18px sans-serif;
      background-color: #ffffff;
      /* border: 1px solid #ffffff;*/
      /* padding: 0px; */
      vertical-align: top;
    }
    .product-photo {
      position: relative;
      display: block;
      text-align: center;
      /* box-shadow: 0 0 10px 5px #f3f3f3 inset; */
      width: 100%;
      margin-bottom: 25px;
      /* padding: 10px 10px; */
      padding: 0px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      cursor: pointer;
      overflow: hidden;
      &::before {
        content: "";
        display: block;
        padding-top: 100%;
        margin: 0 auto;
        color: #d3d3d3;
        font-size: 40px;
      }
      .product-photo-con {
        position: absolute;
        display: flex;
        /* display: grid; */
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        align-items: center;
        img {
          display: block;
          max-width: 100%;
          max-height: 100%;
          margin: auto;
          width: auto;
          height: auto;
          transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
        }
      }
    }

    .info-expand {
      // position: absolute;
      width: 100%;
      height: 100%;
      max-height: 100%;
      transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      top: 0;
      background: rgba(255, 255, 255, 0.9);
      .product-brand {
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: 3px;
        max-width: 100%;
        min-height: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      h2 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        font-size: 17px;
        font-weight: bold;
        max-width: 200px;
        cursor: pointer;
        &:hover {
          color: #950c0f;
        }
      }
      .product-price {
        font-size: 16px;
        margin: 15px 0 4px 0;
        span {
          font-size: 12px;
          font-weight: normal;
        }
      }
      .size-expand {
        font-size: 12px;
        font-weight: normal;
        line-height: 19px;
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        overflow: hidden;
        padding-bottom: 7px;
        .trim-block {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin-bottom: 7px;
        }
      }
      .product-date {
        display: inline-block;
        font-size: 11px;
        font-weight: normal;
        /* font-style: italic; */
        color: #aaaaaa;
      }
      .product-count {
        border-top: 1px solid #ddd;
        padding-top: 10px;
        margin: 5px 0 0;
        transition: all 0.15s ease-in-out;
        -webkit-transition: all 0.15s ease-in-out;
        -moz-transition: all 0.15s ease-in-out;
        -o-transition: all 0.15s ease-in-out;
        position: relative;
        font-size: 12px;
        font-weight: normal;
        span {
          font-size: 12px;
          font-weight: normal;
        }
        i {
          padding-right: 0px;
          font-size: 15px;
        }
        .icon-wishlist {
          cursor: pointer;
          i {
            vertical-align: bottom;
          }
          span {
            padding: 10px 3px 8px;
          }
        }
        .action-btn {
          position: absolute;
          right: 0px;
          top: 0px;
          padding: 0;
          &:hover {
            // position: absolute;
            width: 100%;
            left: 0px;
            top: 0px;

            .icon-download {
              display: none;
            }
            .product-footer {
              display: block;
              opacity: 1;
              visibility: visible;
              height: auto;
              width: auto;
            }
          }
          i {
            font-size: 16px;
            transition: all 0.1s ease-in-out;
            -webkit-transition: all 0.1s ease-in-out;
            -moz-transition: all 0.1s ease-in-out;
            -o-transition: all 0.1s ease-in-out;
          }
          .download-div {
            z-index: 2;
            left: 0px;
            top: 0;
            background: #fff;
            display: flex;
            justify-content: space-between;
            span {
              padding: 10px 3px 8px 15px;
              cursor: pointer;
            }
            .icon-download {
              display: inline-block;
              z-index: 1;
              transition: none;
              vertical-align: middle;
            }
          }

          .product-footer {
            opacity: 0;
            visibility: hidden;
            min-height: 0;
            height: 0px;
            width: 1px;
            overflow: hidden;
            padding-top: 5px;
            transition: all 0.5s ease-in-out;
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            .download-section {
              height: 30px;
              border: none;
              /* padding-top: 10px; */
              // width: 75%;
              max-width: 100%;
              .option {
                float: left;
                padding: 0px;
                margin-right: 5px;
                width: auto !important;
                a {
                  display: inline-block;
                  font-size: 11px;
                  color: #ffffff;
                  border: none;
                  background: #b2b2b2;
                  margin-bottom: 5px;
                  padding: 6px 7px 4px 5px;
                  border-radius: 3px;
                  line-height: 11px;
                  i {
                    margin-right: 3px;
                    font-size: 11px;
                    color: #ffffff;
                    transition: all 0.15s ease-in-out;
                    -webkit-transition: all 0.15s ease-in-out;
                    -moz-transition: all 0.15s ease-in-out;
                    -o-transition: all 0.15s ease-in-out;
                  }
                }
              }
            }
          }
        }
        .cert-icons {
          position: absolute;
          img {
            max-height: 30px;
            max-width: 30px;
          }
        }
      }
    }
    &:hover {
      .product-photo img {
        transform: scale(1.05);
      }
    }
  }
  .forJustify {
    border: none;
    background: none;
    height: 1px;
    padding: 0px;
    margin: 0;
  }
  .greenAd {
    // border: none;
    width: 32%;
    margin: 1% 0;
    padding: 0px;
    vertical-align: top;
    overflow: hidden;
    .owl-carousel {
      height: 100%;
      .slide {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
      }
    }
    @media (max-width: 1500px) {
      width: 49%;
    }
    @media (max-width: 1100px) {
      width: 65.6%;
    }
    @media (max-width: 900px) {
      width: 100%;
      padding: 0;
      height: 40vh;
      min-height: 200px;
    }
    @media (max-width: 768px) {
      z-index:0;
      display: inline-block;
      box-sizing:border-box;
      text-align:left;
      font:bold 18px sans-serif;
      background-color:#ffffff;
      padding: 0px;
      vertical-align: middle;
      /*margin-top: 15px;*/
      margin-top: -5px;
      margin-bottom: 15px;
    }
  }
}
/deep/ .page-link {
  color: #555555;
  border: none;
}
/deep/ .page-item.active .page-link {
  background-color: #cccccc;
  border-color: #aaaaaa;
  border-radius: 4px;
}
.cert-dialog {
  .swal2-image {
    max-width: 100%;
    margin: -30px auto 0;
    display: block;
  }
  .swal2-title {
    display: block;
    position: relative;
    max-width: 100%;
    padding: 0;
    color: #595959;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    text-transform: none;
    word-wrap: break-word;
  }
  p {
    display: flex;
    justify-content: center;
    color: #545454;
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    z-index: 1;
    word-wrap: break-word;
    margin-left: -10px;
    width: 300px;
  }
}
.creat-category {
  margin-left: 20px;
  cursor: pointer;
  @media (max-width: 768px) {
    display: block;
    // width: auto;
    vertical-align: middle;
  }
}
.creat-category:hover {
  text-decoration: underline;
  color: #545454;
}
.select-list {
  height: 30px;
  padding: 6px 8px;
  font-size: 12px;
  line-height: 1.5;
  width: 100%;
  border-radius: 3px;
  margin-left: 7px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  @media (min-width: 768px) {
    display: inline-block;
    // width: auto;
    vertical-align: middle;
  }
}
.show-category {
  .update-category {
    margin-left: 10px;
    font-size: 12px;
    cursor: pointer;
  }
  .update-category:hover {
    text-decoration: underline;
  }
}
.category-select {
  width: 50%;
}
</style>
