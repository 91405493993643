<template>
  <div>
      <!-- strategic page -->
      <ul class="drs-list" v-if="type == 1">
        <li class="thumb">
            <router-link :to="{path:'/strategic/brand',query: { type: 'brands' }}">
                <div class="img-box flex">
                    <img :src="url+ 'misc/tiles/cat-img-40.jpg'">
                </div>
                <span>{{$t('Strateg.brands')}}</span>
            </router-link>
        </li>

        <li class="thumb">
            <router-link  :to="{path: '/strategic/brand',query: { type: 'distributors'}}">
                <div class="img-box flex">
                    <img :src="url+ 'misc/tiles/cat-img-52.jpg'">
                </div>
                <span>{{$t('Strateg.distributors') }}</span>
            </router-link>
        </li>
      </ul>
      <!-- Approved Brands  page -->
      <ul class="drs-list" v-else>
        <li class="thumb">
            <router-link :to="{path:'/approved/brand',query: { type: 'brands'}}">
                <div class="img-box flex">
                    <img :src="url+ 'misc/tiles/cat-img-50.jpg'">
                </div>
                <span>{{$t('approved.brands')}}</span>
            </router-link>
        </li>

        <li class="thumb">
            <router-link  :to="{path: '/approved/brand',query: { type: 'distributors',loc:'CN' }}">
                <div class="img-box flex">
                    <img :src="url+ 'misc/tiles/cat-img-54.jpg'">
                </div>
                <span>{{$t('approved.china_distributors') }}</span>
            </router-link>
        </li>
        <li class="thumb">
            <router-link  :to="{path: '/approved/brand',query: { type: 'distributors',loc:'HK' }}">
                <div class="img-box flex">
                    <img :src="url+ 'misc/tiles/cat-img-53.jpg'">
                </div>
                <span>{{$t('approved.hk_distributors') }}</span>
            </router-link>
        </li>
      </ul>

  </div>
</template>

<script>
export default {
    props: {
      type:Number,
    },
    data() {
      return {
        url: process.env.VUE_APP_IMG_URL
      };
    }
}
</script>

<style lang="scss" scoped>
.drs-list {
  margin: 0 auto;
  /* max-width: 1100px; */
  width: 100%;
  padding: 0;
  text-align: center;
  font-size: 0;
  margin-bottom: 50px;
  .thumb {
    z-index: 0;
    display: inline-block;
    width: 20%;
    text-align: left;
    font-size: 15px;
    margin: 0px 0px 1% 0;
    padding: 15px;
    vertical-align: top;
    .img-box {
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
        transition: transform 1s;
        transition-timing-function: ease;
      }
    }
    span {
      display: block;
      font-size: 18px;
      padding: 20px 0 10px;
      text-align: center;
      letter-spacing: 1px;
      color: #555555;
      position: relative;
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
  @media (max-width: 1559px) {
    text-align: center;
    .thumb {
      padding: 7.5px;
    }
  }
  @media (max-width: 1023px) {
    text-align: justify;
    .thumb {
      width: 33.33%;
      span {
        padding: 15px 0 8px;
      }
    }

  }
  @media (max-width: 767px) {
    width: auto;
    margin-bottom: 50px;
    .thumb {
      margin: 0 0 20px 0;
    }
  }
  @media (max-width: 567px) {
    .thumb {
      width: 50%;
      font-size: 13px;
      span {
        font-size: 15px;
      }
    }
  }
  @media (max-width: 375px) {
    .thumb {
      span {
        font-size: 13px;
      }
    }
  }
}
html[lang="en"] .drs-list .thumb span {
  letter-spacing: normal;
}
</style>
