<template>
  <div class="content" v-loading="loading">
    <b-container fluid >
      <b-row >
        <b-col xs="12" :class="id==224?'black':''">
          <div class="story">
            <div class="axent" v-if="id==169">
              <div class="brand-logo" style="margin:35px auto 0px;">
                  <img :src="url+logoPath" style="max-height:95px;" />
              </div>
              <div v-if="lang=='en'">
                  <div class="main-name">HANSO SERIES</div>
                  <div class="year">{{ $t('sdxBrand.san_products') }} &nbsp;&bull;&nbsp; 2019</div>

                  <div>
                  <div style="max-width:800px; margin:0 auto;">
                      Exploring Traditional Chinese Culture from the Han and Song Dynasty, Steve Leung identified a connection between the ancient crafts of Calligraphy, Architecture.
                      <br />
                      <br />All these disciplines respect the purity of form and practicality of function, but in addition, they add an artistic signature element: The Chinese Swallow tail curve.
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />

                  <div class="col2 col2-custom">
                      <div>
                      <img
                          :src="url+ 'misc/stories/story-axent-01.jpg'"
                          style="width:100%; height:auto; max-width:742px; margin-bottom:12px;"
                      />

                      <b>Han Dynasty Clerical Script - Silkworm Head and Swallow Tail</b>
                      <br />Focusing on Expression of Line Clerical Script was popular in the Han Dynasty. Rectilinear Characters created by Brush, adding artistic swallow tail flaring on major strokes.
                      </div>
                      <div>
                      <img
                          :src="url+ 'misc/stories/story-axent-02.jpg'"
                          style="width:100%; height:auto; max-width:736px; margin-bottom:12px;"
                      />

                      <b>Song Dynasty Architecture - Upturned Eaves</b>
                      <br />The roof is a key focus of Chinese Architecture Upturned Eave in the form of swallow tail is both functional and decorative Providing Sun Shade and shelter from Rain.
                      </div>
                  </div>
                  <br />
                  <br />
                  <br />

                  <div style="max-width:980px; margin:0 auto;">
                      With HANSO, AXENT and Steve Leung, internationally renowned architect, interior and product designer presents a product family of an understated Chinese aesthetics and ancient well-being philosophies.
                      <br />
                      <br />The ingenious curve of HANSO faucet is inspired by the flying swallowtail ridge, a feature of ancient Han's calligraphy script. The tail also a symbolic feature of the upturned eaves of Han & Song dynasties' architecture, conceives the creative integration of contemporary design with traditional Chinese aesthetics.
                      <br />
                      <br />HANSO smart bathroom cabinet is a contemporary expression of a poetic and smart life. Drawing inspiration from Chinese philosophy of Yin and Yang, a balance in qualities brings harmony in life. The shape of the round sink contrasts with the firm edging of the cabinet present an outwardly tough and inwardly gentle touch. The whole natural stone symbolic natural landscape cultivates inner peace while integrating with smart-tech features, such as cooling storage, sterilization and drying module curating a lifestyle of comfort and luxury. A variety of material combinations is available, from the elegant marble, textural bronze to modernistic glass, completing different styles of bathroom designs.
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />

                  <h2 class="ft21 mgb32">Digital Faucet</h2>
                  <img
                      :src="url+ 'misc/stories/story-axent-03.jpg'"
                      style="width:100%; height:auto; max-width:1346px;"
                  />
                  <br />
                  <br />

                  <div class="col2 col2-custom">
                      <div>
                      <img
                          :src="url+ 'misc/stories/story-axent-04.jpg'"
                          style="width:100%; height:auto; max-width:763px;"
                      />
                      </div>
                      <div>
                      <img
                          :src="url+ 'misc/stories/story-axent-05.jpg'"
                          style="width:100%; height:auto; max-width:763px;"
                      />
                      </div>
                  </div>
                  <br />
                  <br />
                  <br />

                  <h2 class="ft21 mgb32">Basin Counter Design</h2>
                    <img
                          :src="url+ 'misc/stories/story-axent-06.jpg'"
                      style="width:100%; height:auto; max-width:1526px;"
                    />
                  <br />
                  <br />
                  <br />
                  <br />

                  <div class="col2">
                      <div>
                      <img
                          :src="url+ 'misc/stories/story-axent-07.jpg'"
                          style="width:100%; height:auto; max-width:790px;"
                      />
                      </div>
                      <div>
                      <img
                          :src="url+ 'misc/stories/story-axent-08.jpg'"
                          style="width:100%; height:auto; max-width:790px;"
                      />
                      </div>
                  </div>
                  </div>
              </div>
              <div v-else-if="lang=='tc'">
                  <div class="main-name">HANSO 漢宋系列</div>
                  <div class="year">{{ $t('sdxBrand.san_products') }} &nbsp;&bull;&nbsp; 2019</div>

                  <div>
                  <div
                      style="max-width:695px; margin:0 auto;"
                  >梁志天探索漢、宋代的書法、建築，從中得以靈感啟發。從書法、建築及陶瓷，除了能體驗功能與實用的純淨性，更添加了一個美感元素: 燕尾</div>
                  <br />
                  <br />
                  <br />
                  <br />

                  <div class="col2 col2-custom">
                      <div>
                      <img
                          :src="url+ 'misc/stories/story-axent-01.jpg'"
                          style="width:100%; height:auto; max-width:742px; margin-bottom:12px;"
                      />

                      <b>漢隸 - 蠶頭燕尾</b>
                      <br />漢隸注重線條的表達，筆勢生動而風格多樣。蠶頭燕尾更是隸書中最講究的技法之一。
                      </div>
                      <div>
                      <img
                          :src="url+ 'misc/stories/story-axent-02.jpg'"
                          style="width:100%; height:auto; max-width:736px; margin-bottom:12px;"
                      />

                      <b>宋朝建築 - 燕尾飛簷</b>
                      <br />宛如漢隸般，中國建築的飛簷也能體驗出蠶頭燕尾中的燕尾，展現功能與美感的雙重結合。
                      </div>
                  </div>
                  <br />
                  <br />
                  <br />

                  <div style="max-width:980px; margin:0 auto;">
                      HANSO 漢宋智能龍頭和浴室盥洗台，以中國漢宋時期的書法和建築為靈感，萃取漢朝隸書筆法中的「蠶頭燕尾」和宋朝傳統建築上騰空而起的燕尾飛簷，把現代設計與中國美學融為一體。
                      <br />
                      <br />智能浴室櫃演繹了現代的生活方式和技術——冰箱用作存放護膚產品，以及恆溫模塊作消毒和加溫之用。同時亦提供多種材料配搭組合，包括大理石、陶瓷、銅和阿加力等，適合各種低調豪華的浴室風格。
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />

                  <h2 class="ft21 mgb32">智能龍頭</h2>
                    <img
                      :src="url+ 'misc/stories/story-axent-03.jpg'"
                      style="width:100%; height:auto; max-width:1346px;"
                    />
                  <br />
                  <br />

                  <div class="col2 col2-custom">
                      <div>
                      <img
                          :src="url+ 'misc/stories/story-axent-04.jpg'"
                          style="width:100%; height:auto; max-width:763px;"
                      />
                      </div>
                      <div>
                      <img
                          :src="url+ 'misc/stories/story-axent-05.jpg'"
                          style="width:100%; height:auto; max-width:763px;"
                      />
                      </div>
                  </div>
                  <br />
                  <br />
                  <br />

                  <h2 class="ft21 mgb32">浴室盥洗台</h2>
                  <img
                      :src="url+ 'misc/stories/story-axent-06.jpg'"
                      style="width:100%; height:auto; max-width:1526px;"
                  />
                  <br />
                  <br />
                  <br />
                  <br />

                  <div class="col2">
                      <div>
                      <img
                          :src="url+ 'misc/stories/story-axent-07.jpg'"
                          style="width:100%; height:auto; max-width:790px;"
                      />
                      </div>
                      <div>
                      <img
                          :src="url+ 'misc/stories/story-axent-08.jpg'"
                          style="width:100%; height:auto; max-width:790px;"
                      />
                      </div>
                  </div>
                  </div>
              </div>
              <div v-else>
                  <div class="main-name">HANSO 汉宋系列</div>
                  <div class="year">{{ $t('sdxBrand.san_products') }} &nbsp;&bull;&nbsp; 2019</div>

                  <div>
                  <div
                      style="max-width:695px; margin:0 auto;"
                  >梁志天探索汉、宋代的书法、建筑，从中得以灵感启发。从书法、建筑及陶瓷，除了能体验功能与实用的纯净性，更添加了一个美感元素: 燕尾</div>
                  <br />
                  <br />
                  <br />
                  <br />

                  <div class="col2 col2-custom">
                      <div>
                      <img
                          :src="url+ 'misc/stories/story-axent-01.jpg'"
                          style="width:100%; height:auto; max-width:742px; margin-bottom:12px;"
                      />

                      <b>汉隶 - 蚕头燕尾</b>
                      <br />汉隶注重线条的表达，笔势生动而风格多样。蚕头燕尾更是隶书中最讲究的技法之一。
                      </div>
                      <div>
                      <img
                          :src="url+ 'misc/stories/story-axent-02.jpg'"
                          style="width:100%; height:auto; max-width:736px; margin-bottom:12px;"
                      />

                      <b>宋朝建筑 - 燕尾飞檐</b>
                      <br />宛如汉隶般，中国建筑的飞檐也能体验出蚕头燕尾中的燕尾，展现功能与美感的双重结合。
                      </div>
                  </div>
                  <br />
                  <br />
                  <br />

                  <div style="max-width:980px; margin:0 auto;">
                      HANSO 汉宋智能龙头和浴室盥洗台，以中国汉宋时期的书法和建筑为灵感，萃取汉朝隶书笔法中的「蚕头燕尾」和宋朝传统建筑上腾空而起的燕尾飞檐，把现代设计与中国美学融为一体。
                      <br />
                      <br />智能浴室柜演绎了现代的生活方式和技术——冰箱用作存放护肤产品，以及恒温模块作消毒和加温之用。同时亦提供多种材料配搭组合，包括大理石、陶瓷、铜和阿加力等，适合各种低调豪华的浴室风格。
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />

                  <h2 class="ft21 mgb32">智能龙头</h2>
                  <img
                      :src="url+ 'misc/stories/story-axent-03.jpg'"
                      style="width:100%; height:auto; max-width:1346px;"
                  />
                  <br />
                  <br />

                  <div class="col2 col2-custom">
                      <div>
                      <img
                          :src="url+ 'misc/stories/story-axent-04.jpg'"
                          style="width:100%; height:auto; max-width:763px;"
                      />
                      </div>
                      <div>
                      <img
                          :src="url+ 'misc/stories/story-axent-05.jpg'"
                          style="width:100%; height:auto; max-width:763px;"
                      />
                      </div>
                  </div>
                  <br />
                  <br />
                  <br />

                  <h2 class="ft21 mgb32">浴室盥洗台</h2>
                  <img
                      :src="url+ 'misc/stories/story-axent-06.jpg'"
                      style="width:100%; height:auto; max-width:1526px;"
                  />
                  <br />
                  <br />
                  <br />
                  <br />

                  <div class="col2">
                      <div>
                      <img
                          :src="url+ 'misc/stories/story-axent-07.jpg'"
                          style="width:100%; height:auto; max-width:790px;"
                      />
                      </div>
                      <div>
                      <img
                          :src="url+ 'misc/stories/story-axent-08.jpg'"
                          style="width:100%; height:auto; max-width:790px;"
                      />
                      </div>
                  </div>
                  </div>
              </div>
              <div class="brand-btn">
                  <!--<a href="../static/storypdf/" target="_blank" class="btn ">{{ $t('sdxBrand.download_product_cat') }}</a>&nbsp;-->
                  <div @click="showContactVendorForm" class="btn">{{ $t('sdxBrand.contact_vendor') }}</div>
              </div>
            </div>
            <div class="bisazza" v-else-if="id==172">
              <div class="brand-logo" style="margin:35px auto -20px;">
                  <img :src="url+logoPath" style="max-height:108px;" />
              </div>

              <div v-if="lang=='en'">
                  <div class="main-name">LANTERNS MOSAICS</div>
                  <div class="year">Mosaic Tiles &nbsp;&bull;&nbsp; 2018</div>

                  <div>
                  <div
                      class="quote"
                  >“Light creates ambience and feel of a place, as well as the expression of a structure”</div>
                  <div class="caption">Le Corbusier</div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />

                  <div class="col2 col-valign">
                  <div>
                      <img :src="url+ 'misc/stories/story-bisazza-01.jpg'" style="width:100%; height:auto;" />
                  </div>
                  <div>
                      <div
                      class="col-text"
                      style="max-width:645px; margin:0 auto; text-align:justify;"
                      >Steve Leung found artistic inspiration from the beauty of traditional Chinese Lanterns.</div>
                  </div>
                  </div>
                  <br />

                  <img :src="url+ 'misc/stories/story-bisazza-02.jpg'" style="width:100%; height:auto;" />
                  <br />
                  <br />
                  <br />

                  <div class="col2 col-valign col-reverse">
                  <div>
                      <img :src="url+ 'misc/stories/story-bisazza-03.jpg'" style="width:100%; height:auto;" />
                  </div>
                  <div>
                      <div class="col-text" style="max-width:570px; margin:0 auto; text-align:justify;">
                      Just like the handmade Mosaic tiles of Bisazza, the lantern is both a decorative and functional object.
                      <br />
                      <br />Seductive as an individual element
                      <br />Sensational as an artistic composition .
                      </div>
                  </div>
                  </div>
              </div>

              <div v-else-if="lang=='tc'">
                  <div class="main-name">LANTERNS 瓷磚</div>
                  <div class="year">馬賽克磚 &nbsp;&bull;&nbsp; 2018</div>

                  <div>
                  <div class="quote">“光，為空間創造了氛圍和情感，也創造了結構的表達。”</div>
                  <div class="caption">勒﹒柯布西耶</div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />

                  <div class="col2 col-valign">
                  <div>
                      <img :src="url+ 'misc/stories/story-bisazza-01.jpg'" style="width:100%; height:auto;" />
                  </div>
                  <div>
                      <div
                      class="col-text"
                      style="max-width:645px; margin:0 auto; text-align:justify;"
                      >孟浩然的《同張將薊門觀燈》道：“薊門看火樹，疑是燭龍燃”。紅燈籠，在中國傳統和文化中是吉祥的象徵，其“紅紅火火”的鮮豔顏色，充滿視覺震撼和節慶感。 SLD 首次與意大利著名馬賽克品牌 BISAZZA 聯手，推出瓷磚新作 LANTERNS （譯：燈籠），從中國傳統紅燈籠中涉取靈感，以現代的設計手法，將此文化元素融入現代瓷磚中。</div>
                  </div>
                  </div>
                  <br />

                  <img :src="url+ 'misc/stories/story-bisazza-02.jpg'" style="width:100%; height:auto;" />
                  <br />
                  <br />
                  <br />

                  <div class="col2 col-valign col-reverse">
                  <div>
                      <img :src="url+ 'misc/stories/story-bisazza-03.jpg'" style="width:100%; height:auto;" />
                  </div>
                  <div>
                      <div
                      class="col-text"
                      style="max-width:645px; margin:0 auto; text-align:justify;"
                      >作品是思考的呈現，思考是創造的源泉。身處於米蘭家具展，SLD 看到了品牌的創新，見證了一次又一次的設計革命。向天才致敬，也是向不斷探索的每一位優秀設計師致敬，向人類思考力和創造力致敬。設計無界限，SLD 將一直保持無限的創造活力，為大家帶來更具價值的設計作品。</div>
                  </div>
                  </div>
              </div>
              <div v-else>
                  <div class="main-name">LANTERNS 瓷砖</div>
                  <div class="year">马赛克砖 &nbsp;&bull;&nbsp; 2018</div>

                  <div>
                  <div class="quote">“光，为空间创造了氛围和情感，也创造了结构的表达。”</div>
                  <div class="caption">勒﹒柯布西耶</div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />

                  <div class="col2 col-valign">
                  <div>
                      <img :src="url+ 'misc/stories/story-bisazza-01.jpg'" style="width:100%; height:auto;" />
                  </div>
                  <div>
                      <div
                      class="col-text"
                      style="max-width:645px; margin:0 auto; text-align:justify;"
                      >孟浩然的《同张将蓟门观灯》道：“蓟门看火树，疑是烛龙燃”。红灯笼，在中国传统和文化中是吉祥的象征，其“红红火火”的鲜艳颜色，充满视觉震撼和节庆感。SLD 首次与意大利著名马赛克品牌 BISAZZA 联手，推出瓷砖新作 LANTERNS （译：灯笼），从中国传统红灯笼中涉取灵感，以现代的设计手法，将此文化元素融入现代瓷砖中。</div>
                  </div>
                  </div>
                  <br />

                  <img :src="url+ 'misc/stories/story-bisazza-02.jpg'" style="width:100%; height:auto;" />
                  <br />
                  <br />
                  <br />

                  <div class="col2 col-valign col-reverse">
                  <div>
                      <img :src="url+ 'misc/stories/story-bisazza-03.jpg'" style="width:100%; height:auto;" />
                  </div>
                  <div>
                      <div
                      class="col-text"
                      style="max-width:645px; margin:0 auto; text-align:justify;"
                      >作品是思考的呈现，思考是创造的源泉。身处于米兰家具展，SLD 看到了品牌的创新，见证了一次又一次的设计革命。向天才致敬，也是向不断探索的每一位优秀设计师致敬，向人类思考力和创造力致敬。设计无界限，SLD 将一直保持无限的创造活力，为大家带来更具价值的设计作品。</div>
                  </div>
                  </div>
              </div>

              <div class="brand-btn">
                  <a
                  :href="url+ 'misc/stories/pdf/Bisazza - Timeless_Collezione n25-pages-Steve Leung.pdf'"
                  target="_blank"
                  class="btn "
                  >{{ $t('sdxBrand.download_product_cat') }}</a>&nbsp;
                  <div @click="showContactVendorForm" class="btn">{{ $t('sdxBrand.contact_vendor') }}</div>
              </div>


            </div>
            <div class="flou" v-else-if="id==368">


              <div class="brand-logo" style="margin:55px auto 10px; padding-right:5px;">
                <img :src="url+logoPath" style="max-height:75px;" />
              </div>


              <div v-if="lang=='en'">
                  <div class="main-name">LILIA ARMCHAIR</div>
                  <div class="year">{{ $t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2020</div>

                  <div style="max-width:540px; margin:0 auto;">
                      Unveiling our first collabration with Flou, Lilia armchair is inspired by the elegant and asymmetrical form of callalily.
                  </div>
                  <br><br>
                  <br><br>

                  <img :src="url+ 'misc/stories/story-flou-01.jpg'" style="width:100%; height:auto; max-width:1300px; margin-bottom:20px;">

                  <div class="col2" style="max-width:1300px; margin:0 auto;">
                      <div style="margin-bottom:20px;">
                          <img :src="url+ 'misc/stories/story-flou-02.jpg'" style="width:100%; height:auto;">
                      </div>
                      <div>
                          <img :src="url+ 'misc/stories/story-flou-03.jpg'" style="width:100%; height:auto;">
                      </div>
                  </div>
                  <br><br>
                  <br>

                  <div style="max-width:810px; margin:0 auto;">
                      The swivel armchair features a backrest that softly envelops the seat, while the asymmetrical armrests curled like petals. Lilia celebrates the pure beauty of nature with a contemporary touch.
                  </div>
                  <br><br>
                  <br><br>

                  <div class="col2" style="max-width:1300px; margin:0 auto;">
                      <div style="margin-bottom:20px; display:flex">
                          <img :src="url+ 'misc/stories/story-flou-06.jpg'" style="width:100%; height:auto;">
                      </div>
                      <div style="display:flex; align-items:center;">
                          <img :src="url+ 'misc/stories/story-flou-07.jpg'" style="width:100%; height:auto;">
                      </div>
                  </div>
                  <br><br>

                  <div class="col2" style="max-width:1300px; margin:0 auto;">
                      <div style="margin-bottom:20px;">
                          <img :src="url+ 'misc/stories/story-flou-04.jpg'" style="width:100%; height:auto;">
                      </div>
                      <div>
                          <img :src="url+ 'misc/stories/story-flou-05.jpg'" style="width:100%; height:auto;">
                      </div>
                  </div>
              </div>

              <div v-else-if="lang=='tc'">
                  <div class="main-name">LILIA 扶手椅</div>
                  <div class="year">{{ $t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2020</div>

                  <div style="max-width:460px; margin:0 auto;">
                      SLD 與 Flou 首次合作推出家具設計，Lilia 扶手椅的設計靈感，來源自馬蹄蘭高貴簡約、不對稱的形態。
                  </div>
                  <br><br>
                  <br><br>

                  <img :src="url+ 'misc/stories/story-flou-01.jpg'" style="width:100%; height:auto; max-width:1300px; margin-bottom:20px;">

                  <div class="col2" style="max-width:1300px; margin:0 auto;">
                      <div style="margin-bottom:20px;">
                          <img :src="url+ 'misc/stories/story-flou-02.jpg'" style="width:100%; height:auto;">
                      </div>
                      <div>
                          <img :src="url+ 'misc/stories/story-flou-03.jpg'" style="width:100%; height:auto;">
                      </div>
                  </div>
                  <br><br>
                  <br>

                  <div style="max-width:470px; margin:0 auto;">
                      流綫型椅背，包裹著舒適的座椅，扶手處像花瓣般微微向外卷曲，Lilia 以現代設計，展現大自然的純粹美學。
                  </div>
                  <br><br>
                  <br><br>

                  <div class="col2" style="max-width:1300px; margin:0 auto;">
                      <div style="margin-bottom:20px; display:flex">
                          <img :src="url+ 'misc/stories/story-flou-06.jpg'" style="width:100%; height:auto;">
                      </div>
                      <div style="display:flex; align-items:center;">
                          <img :src="url+ 'misc/stories/story-flou-07.jpg'" style="width:100%; height:auto;">
                      </div>
                  </div>
                  <br><br>

                  <div class="col2" style="max-width:1300px; margin:0 auto;">
                      <div style="margin-bottom:20px;">
                          <img :src="url+ 'misc/stories/story-flou-04.jpg'" style="width:100%; height:auto;">
                      </div>
                      <div>
                          <img :src="url+ 'misc/stories/story-flou-05.jpg'" style="width:100%; height:auto;">
                      </div>
                  </div>
              </div>
              <div v-else>
                  <div class="main-name">LILIA 扶手椅</div>
                  <div class="year">{{ $t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2020</div>

                  <div style="max-width:460px; margin:0 auto;">
                      SLD 与 Flou 首次合作推出家具设计，Lilia 扶手椅的设计灵感，来源自马蹄兰高贵简约、不对称的形态。
                  </div>
                  <br><br>
                  <br><br>

                  <img :src="url+ 'misc/stories/story-flou-01.jpg'" style="width:100%; height:auto; max-width:1300px; margin-bottom:20px;">

                  <div class="col2" style="max-width:1300px; margin:0 auto;">
                      <div style="margin-bottom:20px;">
                          <img :src="url+ 'misc/stories/story-flou-02.jpg'" style="width:100%; height:auto;">
                      </div>
                      <div>
                          <img :src="url+ 'misc/stories/story-flou-03.jpg'" style="width:100%; height:auto;">
                      </div>
                  </div>
                  <br><br>
                  <br>

                  <div style="max-width:470px; margin:0 auto;">
                      流线型椅背，包裹着舒适的座椅，扶手处像花瓣般微微向外卷曲，Lilia 以现代设计，展现大自然的纯粹美学。
                  </div>
                  <br><br>
                  <br><br>

                  <div class="col2" style="max-width:1300px; margin:0 auto;">
                      <div style="margin-bottom:20px; display:flex">
                          <img :src="url+ 'misc/stories/story-flou-06.jpg'" style="width:100%; height:auto;">
                      </div>
                      <div style="display:flex; align-items:center;">
                          <img :src="url+ 'misc/stories/story-flou-07.jpg'" style="width:100%; height:auto;">
                      </div>
                  </div>
                  <br><br>

                  <div class="col2" style="max-width:1300px; margin:0 auto;">
                      <div style="margin-bottom:20px;">
                          <img :src="url+ 'misc/stories/story-flou-04.jpg'" style="width:100%; height:auto;">
                      </div>
                      <div>
                          <img :src="url+ 'misc/stories/story-flou-05.jpg'" style="width:100%; height:auto;">
                      </div>
                  </div>

              </div>

            </div>
            <div class="fusital" v-else-if="id==60">


                <div class="brand-logo" style="margin:35px auto -5px; padding-right:20px;">
                    <img :src="url+logoPath" style="max-height:100px;" />
                </div>


                <div v-if="lang=='en'">
                    <div class="main-name">H377 SL Duemilasedici Series</div>
                    <div class="year">Door Handles &nbsp;&bull;&nbsp; 2016 ({{$t('sdxBrand.discontinued') }})</div>

                    <h2 class="ft21 mgb32">Designers of Fusital</h2>

                    <ul class="designers">
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-01.jpg'" style="width:100%; height:auto;">
                            David Chipperfield
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-02.jpg'" style="width:100%; height:auto;">
                            Antonio Citterio
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-03.jpg'" style="width:100%; height:auto;">
                            Norman Foster
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-04.jpg'" style="width:100%; height:auto;">
                            Doriana and Massimiliano Fuksas
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-05.jpg'" style="width:100%; height:auto;">
                            Frank O. Gehry
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-06.jpg'" style="width:100%; height:auto;">
                            Michael Graves
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-07.jpg'" style="width:100%; height:auto;">
                            Zaha Hadid
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-08.jpg'" style="width:100%; height:auto;">
                            Yoshimi Kono
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-09.jpg'" style="width:100%; height:auto;">
                            Chi Wing Lo
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-10.jpg'" style="width:100%; height:auto;">
                            Richard Meier
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-11.jpg'" style="width:100%; height:auto;">
                            Jean Nouvel
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-12.jpg'" style="width:100%; height:auto;">
                            Piano Design Workshop
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-13.jpg'" style="width:100%; height:auto;">
                            Andrée Putman
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-14.jpg'" style="width:100%; height:auto;">
                            Aldo Rossi
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-15.jpg'" style="width:100%; height:auto;">
                            Skidmore, Owings & Merrill
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-16.jpg'" style="width:100%; height:auto;">
                            Robert Stern
                        </li>
                    </ul>
                    <br>

                    <img :src="url+ 'misc/stories/story-fusital-17.jpg'" style="width:100%; height:auto; max-width:1160px;">
                    <br><br>
                    <br><br>
                    <br>

                    <h2 class="ft21 mgb32">The Concept</h2>

                    <div class="col2 concepts mgb10">
                        <div>
                            <h2>Circle</h2>
                            <img :src="url+ 'misc/stories/story-fusital-18.jpg'" style="width:100%; height:auto;">
                            <div style="display:flex;">
                                <div>Li Yuan Garden, Suzhou</div>
                                <div>Purple Bamboo Park, Beijing</div>
                                <div>Tulou Hakka Village, Fujian</div>
                            </div>
                        </div>
                        <div>
                            <h2>Square</h2>
                            <img :src="url+ 'misc/stories/story-fusital-19.jpg'" style="width:100%; height:auto;">
                            <div style="display:flex;">
                                <div>THR 350</div>
                                <div>Central</div>
                                <div>IFC</div>
                            </div>
                        </div>
                    </div>

                    <div class="col2 concepts mgb10">
                        <div>
                            <h2>Arch</h2>
                            <img :src="url+ 'misc/stories/story-fusital-20.jpg'" style="width:100%; height:auto;">
                            <div style="display:flex;">
                                <div>Palazzo Della Civilta, Rome</div>
                                <div>Galleria Vittorio Emanuele II, Milan</div>
                                <div>Portico, Bologna</div>
                            </div>
                        </div>
                        <div>
                            <h2>Lock</h2>
                            <img :src="url+ 'misc/stories/story-fusital-21.jpg'" style="width:100%; height:auto;">
                            <div style="display:flex;">
                                <div>Studded Protection</div>
                                <div>Circular Lock Plate</div>
                                <div>Triple Lock plate</div>
                                <div>Locking Pin</div>
                            </div>
                        </div>
                    </div>
                    <br>


                    <img :src="url+ 'misc/stories/story-fusital-22.jpg'" style="width:100%; height:auto; max-width:1302px;">
                    <br><br>
                    <br>

                    <img :src="url+ 'misc/stories/story-fusital-23.jpg'" style="width:100%; height:auto; max-width:1072px;">
                    <br><br>
                    <br><br>

                    <div class="col2">
                        <div>
                            <img :src="url+ 'misc/stories/story-fusital-24.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-fusital-25.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                </div>
                <div v-else-if="lang=='tc'">
                    <div class="main-name">H377 SL Duemilasedici Series</div>
                    <div class="year">門把手 &nbsp;&bull;&nbsp; 2016 ({{$t('sdxBrand.discontinued') }})</div>

                    <div style="max-width:1130px; margin:0 auto;">
                        是次為梁志天與意大利頂級門把手品牌 Fusital 首次聯手合作，梁氏應邀與意大利頂級門把手品牌 Fusital 合作，打造以傳統中式門鎖為靈感的門把手系列，
                        於 2016 意大利米蘭國際家具展 (Salone Internazionale del Mobile) 觸目亮相。
                        <br><br>
                        Fusital 是意大利五金界的領先者 Valli&Valli 旗下的高端品牌，曾與 Frank Gehry, Foster and Partners, Zaha Hadid 等眾多國際著名設計師合作推出門把手系列，
                        是第一個在當代設計和門把手領域推行原創設計的意大利品牌。
                    </div>
                    <br><br>

                    <ul class="designers">
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-01.jpg'" style="width:100%; height:auto;">
                            David Chipperfield
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-02.jpg'" style="width:100%; height:auto;">
                            Antonio Citterio
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-03.jpg'" style="width:100%; height:auto;">
                            Norman Foster
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-04.jpg'" style="width:100%; height:auto;">
                            Doriana and Massimiliano Fuksas
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-05.jpg'" style="width:100%; height:auto;">
                            Frank O. Gehry
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-06.jpg'" style="width:100%; height:auto;">
                            Michael Graves
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-07.jpg'" style="width:100%; height:auto;">
                            Zaha Hadid
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-08.jpg'" style="width:100%; height:auto;">
                            Yoshimi Kono
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-09.jpg'" style="width:100%; height:auto;">
                            Chi Wing Lo
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-10.jpg'" style="width:100%; height:auto;">
                            Richard Meier
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-11.jpg'" style="width:100%; height:auto;">
                            Jean Nouvel
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-12.jpg'" style="width:100%; height:auto;">
                            Piano Design Workshop
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-13.jpg'" style="width:100%; height:auto;">
                            Andrée Putman
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-14.jpg'" style="width:100%; height:auto;">
                            Aldo Rossi
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-15.jpg'" style="width:100%; height:auto;">
                            Skidmore, Owings & Merrill
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-16.jpg'" style="width:100%; height:auto;">
                            Robert Stern
                        </li>
                    </ul>
                    <br>

                    <img :src="url+ 'misc/stories/story-fusital-17.jpg'" style="width:100%; height:auto; max-width:1160px;">
                    <br><br>
                    <br><br>

                    <div style="max-width:1160px; margin:0 auto;">
                        該系列名為 H377 Serie SL Duemilasedici，其設計靈感植根於古色古香的黃銅鎖具，延續傳統中式門鎖的精湛工藝、運用多變的幾何結構組合，透過現代手法的重新演繹，
                        打造精緻而有神韻、兼具功能與美學的全新門把手。其係列產品皆以黃銅精製而成，並搭配古銅、鈦合金、黑色磨砂三種不同材質和顏色的表面，分別代表傳統、工業和
                        當代三種風格，以適應不同的空間需求。
                        <br><br>
                        「門把手是室內設計裡面很重要但也經常被忽視的一個元素，它是人進入一個空間首先接觸到的物件，因此它的觸感和視覺效果給人的第一印象非常重要。」
                        梁志天先生表示：「從設計之初，我們就希望這個系列的產品能夠兼顧功能和美學，同時在簡潔俐落的現代風格當中，又能體會到中國傳統文化的深厚底蘊和獨特魅力。」
                    </div>
                    <br><br>
                    <br>

                    <div class="col2 concepts mgb10">
                        <div>
                            <h2>圓</h2>
                            <img :src="url+ 'misc/stories/story-fusital-18.jpg'" style="width:100%; height:auto;">
                            <div style="display:flex;">
                                <div>蘇州蠡園</div>
                                <div>北京紫竹院公園</div>
                                <div>福建土樓</div>
                            </div>
                        </div>
                        <div>
                            <h2>方</h2>
                            <img :src="url+ 'misc/stories/story-fusital-19.jpg'" style="width:100%; height:auto;">
                            <div style="display:flex;">
                                <div>大坑道 350 號</div>
                                <div>中環</div>
                                <div>國際金融中心商場</div>
                            </div>
                        </div>
                    </div>

                    <div class="col2 concepts mgb10">
                        <div>
                            <h2>圓拱</h2>
                            <img :src="url+ 'misc/stories/story-fusital-20.jpg'" style="width:100%; height:auto;">
                            <div style="display:flex;">
                                <div>羅馬文化宮</div>
                                <div>米蘭埃馬努埃萊二世拱廊街</div>
                                <div>博洛尼亞拱廊</div>
                            </div>
                        </div>
                        <div>
                            <h2>鎖具</h2>
                            <img :src="url+ 'misc/stories/story-fusital-21.jpg'" style="width:100%; height:auto;">
                            <div style="display:flex;">
                                <div>裝飾保護</div>
                                <div>圓鎖板</div>
                                <div>三重鎖板</div>
                                <div>鎖銷</div>
                            </div>
                        </div>
                    </div>
                    <br>


                    <img :src="url+ 'misc/stories/story-fusital-22.jpg'" style="width:100%; height:auto; max-width:1302px;">
                    <br><br>
                    <br>

                    <img :src="url+ 'misc/stories/story-fusital-23.jpg'" style="width:100%; height:auto; max-width:1072px;">
                    <br><br>
                    <br><br>

                    <div class="col2">
                        <div>
                            <img :src="url+ 'misc/stories/story-fusital-24.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-fusital-25.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                </div>

                <div v-else>
                    <div class="main-name">H377 SL Duemilasedici Series</div>
                    <div class="year">门把手 &nbsp;&bull;&nbsp; 2016 ({{$t('sdxBrand.discontinued') }})</div>

                    <div style="max-width:1130px; margin:0 auto;">
                        是次为梁志天与意大利顶级门把手品牌 Fusital 首次联手合作，梁氏应邀与意大利顶级门把手品牌 Fusital 合作，打造以传统中式门锁为灵感的门把手系列，
                        于 2016 意大利米兰国际家具展 (Salone Internazionale del Mobile) 触目亮相。
                        <br><br>
                        Fusital 是意大利五金界的领先者 Valli&Valli 旗下的高端品牌，曾与 Frank Gehry, Foster and Partners, Zaha Hadid 等众多国际著名设计师合作推出门把手系列，
                        是第一个在当代设计和门把手领域推行原创设计的意大利品牌。
                    </div>
                    <br><br>

                    <ul class="designers">
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-01.jpg'" style="width:100%; height:auto;">
                            David Chipperfield
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-02.jpg'" style="width:100%; height:auto;">
                            Antonio Citterio
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-03.jpg'" style="width:100%; height:auto;">
                            Norman Foster
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-04.jpg'" style="width:100%; height:auto;">
                            Doriana and Massimiliano Fuksas
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-05.jpg'" style="width:100%; height:auto;">
                            Frank O. Gehry
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-06.jpg'" style="width:100%; height:auto;">
                            Michael Graves
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-07.jpg'" style="width:100%; height:auto;">
                            Zaha Hadid
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-08.jpg'" style="width:100%; height:auto;">
                            Yoshimi Kono
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-09.jpg'" style="width:100%; height:auto;">
                            Chi Wing Lo
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-10.jpg'" style="width:100%; height:auto;">
                            Richard Meier
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-11.jpg'" style="width:100%; height:auto;">
                            Jean Nouvel
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-12.jpg'" style="width:100%; height:auto;">
                            Piano Design Workshop
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-13.jpg'" style="width:100%; height:auto;">
                            Andrée Putman
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-14.jpg'" style="width:100%; height:auto;">
                            Aldo Rossi
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-15.jpg'" style="width:100%; height:auto;">
                            Skidmore, Owings & Merrill
                        </li>
                        <li>
                            <img :src="url+ 'misc/stories/story-fusital-16.jpg'" style="width:100%; height:auto;">
                            Robert Stern
                        </li>
                    </ul>
                    <br>

                    <img :src="url+ 'misc/stories/story-fusital-17.jpg'" style="width:100%; height:auto; max-width:1160px;">
                    <br><br>
                    <br><br>

                    <div style="max-width:1160px; margin:0 auto;">
                        该系列名为 H377 Serie SL Duemilasedici，其设计灵感植根于古色古香的黄铜锁具，延续传统中式门锁的精湛工艺、运用多变的几何结构组合，透过现代手法的重新演绎，
                        打造精致而有神韵、兼具功能与美学的全新门把手。其系列产品皆以黄铜精制而成，并搭配古铜、钛合金、黑色磨砂三种不同材质和颜色的表面，分别代表传统、工业和
                        当代三种风格，以适应不同的空间需求。
                        <br><br>
                        「门把手是室内设计里面很重要但也经常被忽视的一个元素，它是人进入一个空间首先接触到的物件，因此它的触感和视觉效果给人的第一印象非常重要。」
                        梁志天先生表示：「从设计之初，我们就希望这个系列的产品能够兼顾功能和美学，同时在简洁俐落的现代风格当中，又能体会到中国传统文化的深厚底蕴和独特魅力。」
                    </div>
                    <br><br>
                    <br>

                    <div class="col2 concepts mgb10">
                        <div>
                            <h2>圆</h2>
                            <img :src="url+ 'misc/stories/story-fusital-18.jpg'" style="width:100%; height:auto;">
                            <div style="display:flex;">
                                <div>苏州蠡园</div>
                                <div>北京紫竹院公园</div>
                                <div>福建土楼</div>
                            </div>
                        </div>
                        <div>
                            <h2>方</h2>
                            <img :src="url+ 'misc/stories/story-fusital-19.jpg'" style="width:100%; height:auto;">
                            <div style="display:flex;">
                                <div>大坑道 350 号</div>
                                <div>中环</div>
                                <div>国际金融中心商场</div>
                            </div>
                        </div>
                    </div>

                    <div class="col2 concepts mgb10">
                        <div>
                            <h2>圆拱</h2>
                            <img :src="url+ 'misc/stories/story-fusital-20.jpg'" style="width:100%; height:auto;">
                            <div style="display:flex;">
                                <div>罗马文化宫</div>
                                <div>米兰埃马努埃莱二世拱廊街</div>
                                <div>博洛尼亚拱廊</div>
                            </div>
                        </div>
                        <div>
                            <h2>锁具</h2>
                            <img :src="url+ 'misc/stories/story-fusital-21.jpg'" style="width:100%; height:auto;">
                            <div style="display:flex;">
                                <div>装饰保护</div>
                                <div>圆锁板</div>
                                <div>三重锁板</div>
                                <div>锁销</div>
                            </div>
                        </div>
                    </div>
                    <br>


                    <img :src="url+ 'misc/stories/story-fusital-22.jpg'" style="width:100%; height:auto; max-width:1302px;">
                    <br><br>
                    <br>

                    <img :src="url+ 'misc/stories/story-fusital-23.jpg'" style="width:100%; height:auto; max-width:1072px;">
                    <br><br>
                    <br><br>

                    <div class="col2">
                        <div>
                            <img :src="url+ 'misc/stories/story-fusital-24.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-fusital-25.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                </div>
                <div class=" brand-btn">
                    <a :href="url+ 'misc/stories/pdf/Fusital Cat 2018-Steve Leung Collection.pdf'" target="_blank" class="btn ">{{ $t('sdxBrand.download_product_cat') }}</a>&nbsp;
                    <div @click="showContactVendorForm" class="btn">{{ $t('sdxBrand.contact_vendor') }}</div>
                </div>
            </div>
            <div class="hc28"  v-else-if="id==117">


                  <div class="brand-logo" style="margin:35px auto -10px;">
                      <img :src="url+logoPath"  style="max-height:100px;" />
                  </div>


                  <div v-if="lang=='en'">
                      <div class="main-name">PIAN PIAN FURNITURE SERIES</div>
                      <div class="year">{{ $t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2015</div>


                      <div class="quote">All Men Are not Born Equal</div>
                      Balance in asymmetrical proportions

                      <br><br>
                      <br><br>

                      <img :src="url+ 'misc/stories/story-hc28-01.jpg'" style="width:100%; height:auto; max-width:624px;">

                      <br><br>
                      <br><br>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-hc28-02.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <b>Contemporary / Asymmetrical / Proportion</b>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-hc28-03.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <b>Contemporary / Asymmetrical / Playful</b>
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <div class="col2">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-hc28-04.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-hc28-05.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <div class="col2">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-hc28-06.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-hc28-07.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                  </div>
                  <div v-else-if="lang=='tc'">
                      <div class="main-name">PIAN PIAN 家具系列</div>
                      <div class="year">{{ $t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2015</div>


                      <div class="quote">不對稱。美學</div>
                      尋求不對稱比例中的平衡

                      <br><br>
                      <br><br>

                      <img :src="url+ 'misc/stories/story-hc28-01.jpg'" style="width:100%; height:auto; max-width:624px;">

                      <br><br>
                      <br><br>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-hc28-02.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <b>簡約 / 不對稱 / 比例</b><br>
                              萬物間的美，都有其黃金平衡比例。
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-hc28-03.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <b>簡約 / 不對稱 / 趣味</b><br>
                              延續梁志天的簡約風格，尋找不對稱美學中的平衡, 是這系列家具的主題。
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <div class="col2">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-hc28-04.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-hc28-05.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <div class="col2">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-hc28-06.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-hc28-07.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                  </div>
                  <div v-else>
                      <div class="main-name">PIAN PIAN 家具系列</div>
                      <div class="year">{{ $t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2015</div>


                      <div class="quote">不对称。美学</div>
                      寻求不对称比例中的平衡

                      <br><br>
                      <br><br>

                      <img :src="url+ 'misc/stories/story-hc28-01.jpg'" style="width:100%; height:auto; max-width:624px;">

                      <br><br>
                      <br><br>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-hc28-02.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <b>简约 / 不对称 / 比例</b><br>
                              万物间的美，都有其黄金平衡比例。
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-hc28-03.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <b>简约 / 不对称 / 趣味</b><br>
                              延续梁志天的简约风格，寻找不对称美学中的平衡, 是这系列家具的主题。
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <div class="col2">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-hc28-04.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-hc28-05.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <div class="col2">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-hc28-06.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-hc28-07.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                  </div>

                  <div class=" brand-btn">
                      <!--<a href="../static/storypdf/2016 - Visionnaire - Steve Leung Natures JewelBox.pdf" target="_blank" class="btn">{{ $t('sdxBrand.download_product_cat') }}</a>&nbsp;-->
                      <div @click="showContactVendorForm" class="btn">{{ $t('sdxBrand.contact_vendor') }}</div>
                  </div>

            </div>
            <div class="hc28-cosmo" v-else-if="id==124">


                  <div class="brand-logo" style="margin:35px auto -10px; padding-right:5px;">
                      <img :src="url+logoPath"  style="max-height:100px;" />
                  </div>


              <div v-if="lang=='en'">
                      <div class="main-name">UOVO FURNITURE SERIES</div>
                      <div class="year">{{ $t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2019</div>

                      <div style="max-width:688px; margin:0 auto;">
                          "UOVO" means "egg" in Italian. The collection is inspired by its smooth and controlled curves. It also symbolizes the beginning of a new life that’s vibrant, hopeful and often surprising, similar to the young metropolitans. Our contemporary and minimal design with flexible seating represents this attitude.
                      </div>
                      <br><br>
                      <br><br>

                      <div class="col2 col-valign">
                          <div>
                              <img :src="url+ 'misc/stories/story-hc28-cosmo-01.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <div class="col-text" style="max-width:660px; margin:0 auto; text-align:justify;">
                                  The UOVO collection is a combination of aesthetics and functionality. The contemporary and minimal design is characterized by its fluid and smooth lines created by the excellent craftsmanship, and it gives a sense of relaxation, elegance and personality to the collection.
                              </div>
                          </div>
                      </div>
                      <br><br>

                      <img :src="url+ 'misc/stories/story-hc28-cosmo-02.jpg'" style="width:100%; height:auto; max-width:1426px;">
                      <br><br>
                      <br>

                      <div class="col2" style="max-width:1312px; margin:0 auto;">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-hc28-cosmo-03.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-hc28-cosmo-04.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <div class="col2 col-valign col-reverse">
                          <div>
                              <img :src="url+ 'misc/stories/story-hc28-cosmo-05.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <div class="col-text" style="max-width:625px; margin:0 auto; text-align:justify;">
                                  The UOVO lounge chair brings visual delights with its sculpted shape. The design is inspired by the smooth surface and controlled curves of an eggshell. It is all about a dynamic lifestyle - relaxation, elegance and with character.
                                  <br><br>
                                  UOVO round dining table represents the understated luxury of modern life. The use of quality wood and stone materials bring a tasteful contrast that creates a state of prestige.
                                  <br><br>
                                  UOVO bed uses gentle and smooth curves to evoke the feeling of sanctuary. The subtle and comfortable form embody artistry and it sets tone of a bedroom with understated luxury.
                              </div>
                          </div>
                      </div>
              </div>
                  <div v-else-if="lang=='tc'">
                      <div class="main-name">UOVO 源家具系列</div>
                      <div class="year">{{ $t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2019</div>

                      <div style="max-width:688px; margin:0 auto;">
                          「UOVO 源」的名字由意大利語而來，UOVO 是意大利語中 「蛋」 的意思，蛋是生命孕育的開始，寓意都匯里新品牌的誕生，我們一起創作充滿活力和驚喜的家具。
                      </div>
                      <br><br>
                      <br><br>

                      <div class="col2 col-valign">
                          <div>
                              <img :src="url+ 'misc/stories/story-hc28-cosmo-01.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <div class="col-text" style="max-width:660px; margin:0 auto; text-align:justify;">
                                  源系列的設計靈感來源自於像雞蛋一樣柔潤流暢的線條和造型，讓人感覺放鬆舒適，優雅之餘也不失玩味。雞蛋圓潤的線條，是流暢的，也是有節制感的。源系列也萃取其特性，運用精準而受控制的弧線 (controlled curves)，演繹簡約的氣質，也營造一種透亮和動感。
                              </div>
                          </div>
                      </div>
                      <br><br>

                      <img :src="url+ 'misc/stories/story-hc28-cosmo-02.jpg'" style="width:100%; height:auto; max-width:1426px;">
                      <br><br>
                      <br>

                      <div class="col2" style="max-width:1312px; margin:0 auto;">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-hc28-cosmo-03.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-hc28-cosmo-04.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <div class="col2 col-valign col-reverse">
                          <div>
                              <img :src="url+ 'misc/stories/story-hc28-cosmo-05.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <div class="col-text" style="max-width:625px; margin:0 auto; text-align:justify;">
                                  家具的精緻色彩和高質材質，配以精湛工藝，轉折、圍合、綻放、收尾，帶來優雅個性的都會生活體驗。簡約而不簡單的家具，來自於線條的掌握，恰到好處的細節修飾。床品設計在視覺和質感上凸顯設計靈感雞蛋的特性，有著嬰孩般的光滑柔軟，給予空間舒適、自然有機的靈魂。
                                  <br><br>
                                  源系列的家具追求的不只是產品設計對於「美學」和「實用」的結合，我們更著重舒適度、性價比和耐看性。
                              </div>
                          </div>
                      </div>
                  </div>
                  <div v-else>

                      <div class="main-name">UOVO 源家具系列</div>
                      <div class="year">{{ $t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2019</div>

                      <div style="max-width:688px; margin:0 auto;">
                          「UOVO 源」的名字由意大利语而来，UOVO 是意大利语中 「蛋」 的意思，蛋是生命孕育的开始，寓意都汇里新品牌的诞生，我们一起创作充满活力和惊喜的家具。
                      </div>
                      <br><br>
                      <br><br>

                      <div class="col2 col-valign">
                          <div>
                              <img :src="url+ 'misc/stories/story-hc28-cosmo-01.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <div class="col-text" style="max-width:660px; margin:0 auto; text-align:justify;">
                                  源系列的设计灵感来源自于像鸡蛋一样柔润流畅的线条和造型，让人感觉放松舒适，优雅之余也不失玩味。鸡蛋圆润的线条，是流畅的，也是有节制感的。源系列也萃取其特性，运用精准而受控制的弧线 (controlled curves)，演绎简约的气质，也营造一种透亮和动感。
                              </div>
                          </div>
                      </div>
                      <br><br>

                      <img :src="url+ 'misc/stories/story-hc28-cosmo-02.jpg'" style="width:100%; height:auto; max-width:1426px;">
                      <br><br>
                      <br>

                      <div class="col2" style="max-width:1312px; margin:0 auto;">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-hc28-cosmo-03.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-hc28-cosmo-04.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <div class="col2 col-valign col-reverse">
                          <div>
                              <img :src="url+ 'misc/stories/story-hc28-cosmo-05.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <div class="col-text" style="max-width:625px; margin:0 auto; text-align:justify;">
                                  家具的精致色彩和高质材质，配以精湛工艺，转折、围合、绽放、收尾，带来优雅个性的都会生活体验。简约而不简单的家具，来自于线条的掌握，恰到好处的细节修饰。床品设计在视觉和质感上凸显设计灵感鸡蛋的特性，有着婴孩般的光滑柔软，给予空间舒适、自然有机的灵魂。
                                  <br><br>
                                  源系列的家具追求的不只是产品设计对于「美学」和「实用」的结合，我们更着重舒适度、性价比和耐看性。
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class=" brand-btn">
                      <!--<a href="../static/storypdf/2016 - Visionnaire - Steve Leung Natures JewelBox.pdf" target="_blank" class="btn">{{ $t('sdxBrand.download_product_cat') }}</a>&nbsp;-->
                      <div @click="showContactVendorForm" class="btn">{{ $t('sdxBrand.contact_vendor') }}</div>
                  </div>

            </div>

            <div class="kohler" v-else-if="id==6">


                  <div class="brand-logo" style="margin:35px auto -18px; padding-left:5px;">
                      <img :src="url+logoPath"  style="max-height:100px;" />
                  </div>


                  <div v-if="lang=='en'">
                      <div class="main-name">STEVE LEUNG COLLECTION</div>
                      <div class="year">Kitchen Cabinetry &nbsp;&bull;&nbsp; 2019</div>

                      <h2  class="ft21 mgb32">Purity and Functionality.</h2>
                      <br><br>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-kohler-01.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              Family Share / Social Gathering / Blending the boundaries of kitchen and living space
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-kohler-02.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              A separated wet/ show kitchen is a common necessity of modern living. Not only for practicality but also act as a showcase of one‘s Iifestyle. More decorative materials are employed to bring the living and dining areas closer to the kitchen space.
                          </div>
                      </div>
                      <br><br>


                      <img :src="url+ 'misc/stories/story-kohler-03.jpg'" style="width:100%; height:auto; max-width:1176px;">
                      <br><br>
                      <br><br>

                      <div class="col2 col-reverse">
                          <div style="flex:auto; margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-kohler-05.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="flex:auto;">
                              <img :src="url+ 'misc/stories/story-kohler-04.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                  </div>
                  <div v-else-if="lang=='tc'">
                      <div class="main-name">梁志天廚櫃系列</div>
                      <div class="year">廚櫃 &nbsp;&bull;&nbsp; 2019</div>

                      <h2  class="ft21 mgb32">純淨 。功能</h2>
                      <br><br>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-kohler-01.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              現代人因為家人共享、朋友社交改變；廚房與生活空間的界線已經越來越模糊。
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-kohler-02.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              區分成中/西廚房之功能佈局在現化生活愈受重視。西廚兼備有輕煮及展示生活品味之功能。因此我們把較裝飾性的材料引進門片上，把客飯廳的距離拉近。
                          </div>
                      </div>
                      <br><br>


                      <img :src="url+ 'misc/stories/story-kohler-03.jpg'" style="width:100%; height:auto; max-width:1176px;">
                      <br><br>
                      <br><br>

                      <div class="col2 col-reverse">
                          <div style="flex:auto; margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-kohler-05.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="flex:auto;">
                              <img :src="url+ 'misc/stories/story-kohler-04.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                  </div>
                  <div v-else>
                      <div class="main-name">梁志天厨柜系列</div>
                      <div class="year">厨柜 &nbsp;&bull;&nbsp; 2019</div>

                      <h2  class="ft21 mgb32">純淨 。功能</h2>
                      <br><br>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-kohler-01.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              现代人因为家人共享、朋友社交改变；厨房与生活空间的界线已经越来越模糊。
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-kohler-02.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              区分成中/西厨房之功能布局在现化生活愈受重视。西厨兼备有轻煮及展示生活品味之功能。因此我们把较装饰性的材料引进门片上，把客饭厅的距离拉近。
                          </div>
                      </div>
                      <br><br>


                      <img :src="url+ 'misc/stories/story-kohler-03.jpg'" style="width:100%; height:auto; max-width:1176px;">
                      <br><br>
                      <br><br>

                      <div class="col2 col-reverse">
                          <div style="flex:auto; margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-kohler-05.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="flex:auto;">
                              <img :src="url+ 'misc/stories/story-kohler-04.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                  </div>
                  <div class="brand-btn">
                      <a :href="url+ 'misc/stories/pdf/2018 - Kohler Steve Leung Collection.pdf'" target="_blank" class="btn">{{ $t('sdxBrand.download_product_cat') }}</a>&nbsp;
                      <div @click="showContactVendorForm" class="btn">{{ $t('sdxBrand.contact_vendor') }}</div>
                  </div>
            </div>


            <div class="moorgen" v-else-if="id==183">


                  <div class="brand-logo" style="margin:35px auto -18px; padding-left:5px;">
                      <img :src="url+logoPath"  style="max-height:90px;" />
                  </div>


                  <div v-if="lang=='en'">
                      <div class="main-name">STEVE LEUNG SUPERCAR SERIES</div>
                      <div class="year">Smart Home Products &nbsp;&bull;&nbsp; 2022</div>

                      <div style="max-width:620px; margin:0 auto;">The Moorgen Steve Leung Series was inspired by the silhouette of a fast moving sports car, adding a voluptuous line to an original flat panel design</div>
                      <br><br>
                      <br><br>

                      <div style="width:100%; height:auto; min-height:350px; display:flex; align-items:center; justify-content:center; background:#000000;">
                          <img :src="url+ 'misc/stories/story-moorgen-01.jpg'" style="width:100%; max-width:1300px; height:auto;">
                      </div>
                      <br><br>
                      <br>

                      <div class="col-auto col-min-margin">
                          <div style="margin-bottom:2px;">
                              <div class="col2 col-min-margin no-mobile" style="margin-bottom:0;">
                                  <div style="margin-bottom:0px;">
                                      <img :src="url+ 'misc/stories/story-moorgen-02.jpg'" style="width:100%; height:auto;">

                                  </div>
                                  <div style="margin-bottom:0px;">
                                      <img :src="url+ 'misc/stories/story-moorgen-03.jpg'" style="width:100%; height:auto;">

                                  </div>
                              </div>
                          </div>
                          <div>
                              <div class="col2 col-min-margin no-mobile" style="margin-bottom:0;">
                                  <div style="margin-bottom:0px;">
                                      <img :src="url+ 'misc/stories/story-moorgen-04.jpg'" style="width:100%; height:auto;">

                                  </div>
                                  <div style="margin-bottom:0px;">
                                      <img :src="url+ 'misc/stories/story-moorgen-05.jpg'" style="width:100%; height:auto;">

                                  </div>
                              </div>
                          </div>
                      </div>
                      <br><br>

                      <div class="col-auto col-no-margin">
                          <div style="flex:auto; margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-moorgen-06.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="flex:auto;">
                              <img :src="url+ 'misc/stories/story-moorgen-07.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <img :src="url+ 'misc/stories/story-moorgen-08.jpg'" style="width:100%; height:auto; max-width:1000px;">
                      <br><br>
                      <br><br>
                      <br>

                      <div class="col-auto col-min-margin">
                          <div style="flex:auto; margin-bottom:2px; display:flex; min-height:360px; align-items:center; background:#000000;">
                              <img :src="url+ 'misc/stories/story-moorgen-09.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="flex:auto; margin-bottom:2px; display:flex; align-items:end; background:#000000;">
                              <img :src="url+ 'misc/stories/story-moorgen-10.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <img :src="url+ 'misc/stories/story-moorgen-11.jpg'" style="width:100%; height:auto; max-width:1100px;">
                      <br><br>
                  </div>
                  <div v-else-if="lang=='tc'">
                      <div class="main-name">梁志天超跑系列</div>
                      <div class="year">智能家居產品 &nbsp;&bull;&nbsp; 2022</div>

                      <div style="max-width:365px; margin:0 auto;">摩根梁志天系列的靈感源於跑車的速度感與外型，於平坦的面板增添立體的曲線感</div>
                      <br><br>
                      <br><br>

                      <div style="width:100%; height:auto; min-height:350px; display:flex; align-items:center; justify-content:center; background:#000000;">
                          <img :src="url+ 'misc/stories/story-moorgen-01.jpg'" style="width:100%; max-width:1300px; height:auto;">
                      </div>
                      <br><br>
                      <br>

                      <div class="col-auto col-min-margin">
                          <div style="margin-bottom:2px;">
                              <div class="col2 col-min-margin no-mobile" style="margin-bottom:0;">
                                  <div style="margin-bottom:0px;">
                                      <img :src="url+ 'misc/stories/story-moorgen-02.jpg'" style="width:100%; height:auto;">

                                  </div>
                                  <div style="margin-bottom:0px;">
                                      <img :src="url+ 'misc/stories/story-moorgen-03.jpg'" style="width:100%; height:auto;">

                                  </div>
                              </div>
                          </div>
                          <div>
                              <div class="col2 col-min-margin no-mobile" style="margin-bottom:0;">
                                  <div style="margin-bottom:0px;">
                                      <img :src="url+ 'misc/stories/story-moorgen-04.jpg'" style="width:100%; height:auto;">

                                  </div>
                                  <div style="margin-bottom:0px;">
                                      <img :src="url+ 'misc/stories/story-moorgen-05.jpg'" style="width:100%; height:auto;">

                                  </div>
                              </div>
                          </div>
                      </div>
                      <br><br>

                      <div class="col-auto col-no-margin">
                          <div style="flex:auto; margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-moorgen-06.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="flex:auto;">
                              <img :src="url+ 'misc/stories/story-moorgen-07.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <img :src="url+ 'misc/stories/story-moorgen-08.jpg'" style="width:100%; height:auto; max-width:1000px;">
                      <br><br>
                      <br><br>
                      <br>

                      <div class="col-auto col-min-margin">
                          <div style="flex:auto; margin-bottom:2px; display:flex; min-height:360px; align-items:center; background:#000000;">
                              <img :src="url+ 'misc/stories/story-moorgen-09.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="flex:auto; margin-bottom:2px; display:flex; align-items:end; background:#000000;">
                              <img :src="url+ 'misc/stories/story-moorgen-10.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <img :src="url+ 'misc/stories/story-moorgen-11.jpg'" style="width:100%; height:auto; max-width:1100px;">
                      <br><br>
                  </div>
                  <div v-else>
                      <div class="main-name">梁志天超跑系列</div>
                      <div class="year">智能家居产品 &nbsp;&bull;&nbsp; 2022</div>

                      <div style="max-width:365px; margin:0 auto;">摩根梁志天系列的灵感源于跑车的速度感与外型，于平坦的面板增添立体的曲线感</div>
                      <br><br>
                      <br><br>

                      <div style="width:100%; height:auto; min-height:350px; display:flex; align-items:center; justify-content:center; background:#000000;">
                        <img :src="url+ 'misc/stories/story-moorgen-01.jpg'" style="width:100%; max-width:1300px; height:auto;">
                      </div>
                      <br><br>
                      <br>

                      <div class="col-auto col-min-margin">
                          <div style="margin-bottom:2px;">
                              <div class="col2 col-min-margin no-mobile" style="margin-bottom:0;">
                                  <div style="margin-bottom:0px;">
                                      <img :src="url+ 'misc/stories/story-moorgen-02.jpg'" style="width:100%; height:auto;">

                                  </div>
                                  <div style="margin-bottom:0px;">
                                      <img :src="url+ 'misc/stories/story-moorgen-03.jpg'" style="width:100%; height:auto;">
                                  </div>
                              </div>
                          </div>
                          <div>
                              <div class="col2 col-min-margin no-mobile" style="margin-bottom:0;">
                                  <div style="margin-bottom:0px;">
                                      <img :src="url+ 'misc/stories/story-moorgen-04.jpg'" style="width:100%; height:auto;">

                                  </div>
                                  <div style="margin-bottom:0px;">
                                      <img :src="url+ 'misc/stories/story-moorgen-05.jpg'" style="width:100%; height:auto;">

                                  </div>
                              </div>
                          </div>
                      </div>
                      <br><br>

                      <div class="col-auto col-no-margin">
                          <div style="flex:auto; margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-moorgen-06.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="flex:auto;">
                              <img :src="url+ 'misc/stories/story-moorgen-07.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <img :src="url+ 'misc/stories/story-moorgen-08.jpg'" style="width:100%; height:auto; max-width:1000px;">
                      <br><br>
                      <br><br>
                      <br>

                      <div class="col-auto col-min-margin">
                          <div style="flex:auto; margin-bottom:2px; display:flex; min-height:360px; align-items:center; background:#000000;">
                              <img :src="url+ 'misc/stories/story-moorgen-09.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="flex:auto; margin-bottom:2px; display:flex; align-items:end; background:#000000;">
                              <img :src="url+ 'misc/stories/story-moorgen-10.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <img :src="url+ 'misc/stories/story-moorgen-11.jpg'" style="width:100%; height:auto; max-width:1100px;">
                      <br><br>
                  </div>
                  <div class="brand-btn">
                      <a :href="url+ 'misc/stories/pdf/Moorgen - Steve Leung Collection.pdf'" target="_blank" class="btn">{{ $t('sdxBrand.download_product_cat') }}</a>&nbsp;
                      <div @click="showContactVendorForm" class="btn">{{ $t('sdxBrand.contact_vendor') }}</div>
                  </div>
            </div>



            <div class="neutra" v-else-if="id==184">


                  <div class="brand-logo" style="margin:35px auto -5px; padding-left:5px;">
                      <img :src="url+logoPath" style="max-height:80px;" />
                  </div>


                  <div v-if="lang=='en'">
                      <div class="main-name">INKSTONE SANITARY SERIES</div>
                      <div class="year">{{ $t('sdxBrand.san_products') }} &nbsp;&bull;&nbsp; 2012</div>

                      <div style="max-width:1100px; margin:0 auto;">
                          <b>Design for Asia Awards 2014 - Gold Award<br>
                          Perspective Awards 2012 - Certificate of Excellence - Product Design (Professional) - Bathroom Product</b>
                          <br><br>
                          Inspired by precious Chinese ink stones, one of the four treasures of the study, Steve’s first vanity collection “INKSTONE” reveals an understated Asian beauty and wisdom for well-being. Devoid of decoration, the pure and elegant basins capture the essence of a calligraphic image by stirring the emotions directly. It is natural while alluding to Chinese heritage and culture with its spirit.
                      </div>
                      <br><br>
                      <br><br>
                      <h2  class="ft21 mgb32">Design Concept</h2>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-01.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:0px;">
                              <img :src="url+ 'misc/stories/story-neutra-02.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-03.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-04.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <div style="max-width:950px; margin:0 auto;">
                          Minimal decoration, simple form, elegant details and just-right proportion fully integrate the profound Chinese culture and the art of calligraphy into home bathing experience.
                      </div>
                      <br><br>
                      <br><br>
                      <br>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-05.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-06.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <div style="max-width:950px; margin:0 auto;">
                          A skillful adaption of its colour, shape and texture, together with the sophisticated millstone craftsmanship, the natural texture and beauty of stones are fully illustrated.
                      </div>
                      <br><br>
                      <br><br>
                      <br>

                      <div class="col-auto col-lg-margin">
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-07.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-08.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <div class="col-auto col-min-margin">
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-09.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-10.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <div class="col-auto col-min-margin">
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-11.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-12.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                  </div>
                  <div v-else-if="lang=='tc'">
                      <div class="main-name">INKSTONE 衛浴潔具系列</div>
                      <div class="year">{{ $t('sdxBrand.san_products') }} &nbsp;&bull;&nbsp; 2012</div>

                      <div style="max-width:790px; margin:0 auto;">
                          <b>2014 亞洲最具影響力設計大獎 - 金獎<br>
                          2012 《透視》雜誌「透視室內設計大賞」產品設計 (專業組) - 浴室產品 - 優異獎</b>
                          <br><br>
                          梁志天與著名意大利浴室潔具品牌 NEUTRA 合作，於 2012 年推出其個人首項浴室潔具系列「INKSTONE」，帶來結合中國傳統藝術元素及人文養生之道的衛浴新體驗。
                      </div>
                      <br><br>
                      <br><br>
                      <h2 class="ft21 mgb32">设计概念</h2>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-01.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:0px;">
                              <img :src="url+ 'misc/stories/story-neutra-02.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-03.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-04.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <div style="max-width:950px; margin:0 auto;">
                          「INKSTONE」的設計靈感源自中國文房四寶之一 「墨硯」，除了包括書法藝術的形神氣韻，更取其平心靜氣、排除雜念等修心養性之效，與傳統浸浴養生的道理相近，是現代人的理想衛浴設備。
                      </div>
                      <br><br>
                      <br><br>
                      <br>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-05.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-06.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <div style="max-width:950px; margin:0 auto;">
                          「INKSTONE」選用的顏色、形狀和紋理與傳統墨硯相若，透過意大利浴室潔具品牌 NEUTRA 精湛的磨石工藝，把石材的天然質感和秀麗發揮得淋漓盡致；而簡潔靈巧的線條、優雅的細節和恰到好處的比例也完全貫徹了梁氏的設計風格，把博大精深的中國文化和書法藝術，融入到家居衛浴體驗之中。
                      </div>
                      <br><br>
                      <br><br>
                      <br>

                      <div class="col-auto col-lg-margin">
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-07.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-08.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <div class="col-auto col-min-margin">
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-09.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-10.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <div class="col-auto col-min-margin">
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-11.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-12.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                  </div>
                  <div v-else>
                      <div class="main-name">INKSTONE 卫浴洁具系列</div>
                      <div class="year">{{ $t('sdxBrand.san_products') }} &nbsp;&bull;&nbsp; 2012</div>

                      <div style="max-width:790px; margin:0 auto;">
                          <b>2014 亚洲最具影响力设计大奖 - 金奖<br>
                          2012 《透视》杂志「透视室内设计大赏」产品设计(专业组) - 浴室产品 - 优异奖</b>
                          <br><br>
                          梁志天与著名意大利浴室洁具品牌 NEUTRA 合作，于 2012 年推出其个人首项浴室洁具系列「INKSTONE」，带来结合中国传统艺术元素及人文养生之道的卫浴新体验。
                      </div>
                      <br><br>
                      <br><br>
                      <h2 class="ft21 mgb32">设计概念</h2>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-01.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:0px;">
                              <img :src="url+ 'misc/stories/story-neutra-02.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-03.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-04.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <div style="max-width:950px; margin:0 auto;">
                          「INKSTONE」的设计灵感源自中国文房四宝之一 「墨砚」，除了包括书法艺术的形神气韵，更取其平心静气、排除杂念等修心养性之效，与传统浸浴养生的道理相近，是现代人的理想卫浴设备。
                      </div>
                      <br><br>
                      <br><br>
                      <br>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-05.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-06.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <div style="max-width:950px; margin:0 auto;">
                          「INKSTONE」选用的颜色、形状和纹理与传统墨砚相若，透过意大利浴室洁具品牌 NEUTRA 精湛的磨石工艺，把石材的天然质感和秀丽发挥得淋漓尽致；而简洁灵巧的线条、优雅的细节和恰到好处的比例也完全贯彻了梁氏的设计风格，把博大精深的中国文化和书法艺术，融入到家居卫浴体验之中。
                      </div>
                      <br><br>
                      <br><br>
                      <br>

                      <div class="col-auto col-lg-margin">
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-07.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-08.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <div class="col-auto col-min-margin">
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-09.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-10.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <div class="col-auto col-min-margin">
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-11.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-neutra-12.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                  </div>
                  <div class=" brand-btn">
                      <!--<a href="../static/storypdf/" target="_blank" class="btn ">{{ $t('sdxBrand.download_product_cat') }}</a>&nbsp;-->
                      <div @click="showContactVendorForm" class="btn">{{ $t('sdxBrand.contact_vendor') }}</div>
                  </div>

            </div>

            <div class="riva" v-else-if="id==174">


                  <div class="brand-logo" style="margin:35px auto 15px; padding-left:5px;">
                      <img :src="url+logoPath"  style="max-height:80px;" />
                  </div>


              <div v-if="lang=='en'">
                  <div class="main-name">YING & YANG FURNITURE SERIES</div>
                  <div class="year">{{ $t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2019</div>

                  <div style="max-width:900px; margin:0 auto;">
                      The collection and its name inspired by the dual principle of Chinese philosophy, explores the wisdom of harmony and graceful balance, reimagined through a contemporary design highlighting the natural material American walnut and top-notch craftsmanship.
                  </div>
                  <br><br>
                  <br><br>

                  <div class="col-auto col-min-margin" style="max-width:1296px; margin:0 auto;">
                      <div>
                          <img :src="url+ 'misc/stories/story-riva-01.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                          <div>Object and Reflection</div>
                      </div>
                      <div>
                          <img :src="url+ 'misc/stories/story-riva-02.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                          <div>Bright and Dark</div>
                      </div>
                      <div>
                          <img :src="url+ 'misc/stories/story-riva-03.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                          <div>Light and Shadow</div>
                      </div>
                  </div>
                  <br>

                  <div style="max-width:950px; margin:0 auto;">
                      The soft, pleasant curves define the symmetrical and circular shape of the lounge chair and the coffee table, where circle is a form of completeness and abundance in Chinese culture. The round structure also subtly recalls the endless flowing of time, for a sense of vast infinity, representing tradition and the lasting brand legacy of RIVA.
                      <br><br>
                      The collection is a harmonious interplay of dual materials made visually stunning and echoing Yin and Yang philosophy. The lounge chair is made of wooden structure and refined leather, with stitching that presents an understated sophistication.
                  </div>
                  <br><br>
                  <br><br>

                  <div class="col2" style="max-width:1296px; margin:0 auto;">
                      <div style="margin-bottom:20px;">
                          <img :src="url+ 'misc/stories/story-riva-04.jpg'" style="width:100%; height:auto;">
                      </div>
                      <div>
                          <img :src="url+ 'misc/stories/story-riva-05.jpg'" style="width:100%; height:auto;">
                      </div>
                  </div>
                  <br><br>

                  <div style="max-width:980px; margin:0 auto;">
                      Celebrating RIVA 1920 the brand’s centenary anniversary (1920-2020), Yin & Yang collection will be produced in a limited number of lounge chairs and coffee tables employing the use of American walnut, a sophisticated and strong, durable wood, known for being one of the best materials for a timeless piece of furniture.
                  </div>
                  <br><br>
                  <br><br>

                  <img :src="url+ 'misc/stories/story-riva-06.jpg'" style="width:100%; height:auto; max-width:1296px;">
              </div>
                  <div v-else-if="lang=='tc'">
                      <div class="main-name">YING & YANG 家具系列</div>
                      <div class="year">{{ $t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2019</div>

                      <div style="max-width:580px; margin:0 auto;">
                          首次與意大利家具品牌 RIVA 1920 聯手合作，推出 “Yin & Yang” 系列 (譯: 陰陽) ，靈感來源於中國傳統陰陽哲學，將中國智慧融合於西方的品牌中。
                      </div>
                      <br><br>
                      <br><br>

                      <div class="col-auto col-min-margin" style="max-width:1296px; margin:0 auto;">
                          <div>
                              <img :src="url+ 'misc/stories/story-riva-01.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>Object and Reflection<br>
                              虛實
                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-riva-02.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>Bright and Dark<br>
                              明暗</div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-riva-03.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>Light and Shadow<br>
                              光影</div>
                          </div>
                      </div>
                      <br>

                      <div style="max-width:950px; margin:0 auto;">
                          此系列共包括兩件單品：一張休閒椅和一張茶几。休閒椅的實心胡桃木結構，連接從與柔軟的精製皮革相融合，縫線中呈現出低調優雅之感；咖啡桌則採用堅硬的透明水晶玻璃，配以扇形的木質底座，同柔軟而堅固的皮革交相映襯。
                      </div>
                      <br><br>
                      <br><br>

                      <div class="col2" style="max-width:1296px; margin:0 auto;">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-riva-04.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-riva-05.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <div style="max-width:980px; margin:0 auto;">
                          “Yin & Yang” 以中國傳統哲學的陰陽原則中汲取靈感，致力於探索和諧的邊界與平衡的智慧，以現代的設計和精湛考究的工藝來定義空間。咖啡桌與柔軟堅固的皮革互相映襯，以中國扇子為靈感的木質底座襯托著圓形水晶玻璃，與休閒椅一同展示著陰陽合併後的“圓” 元素，亦令人想起時光的輪迴流動，給予人一種源源不盡之感。
                          <br><br>
                          產品中的兩件物料相映成趣所產生的視覺震撼，亦呼應陰陽哲學。
                      </div>
                      <br><br>
                      <br><br>

                      <img :src="url+ 'misc/stories/story-riva-06.jpg'" style="width:100%; height:auto; max-width:1296px;">
                  </div>
                  <div v-else>
                      <div class="main-name">YING & YANG 家具系列</div>
                      <div class="year">{{ $t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2019</div>

                      <div style="max-width:580px; margin:0 auto;">
                          首次与意大利家具品牌 RIVA 1920 联手合作，推出 “Yin & Yang” 系列 (译: 阴阳) ，灵感来源于中国传统阴阳哲学，将中国智慧融合于西方的品牌中。
                      </div>
                      <br><br>
                      <br><br>

                      <div class="col-auto col-min-margin" style="max-width:1296px; margin:0 auto;">
                          <div>
                              <img :src="url+ 'misc/stories/story-riva-01.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>Object and Reflection<br>
                              虚实
                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-riva-02.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>Bright and Dark<br>
                              明暗</div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-riva-03.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>Light and Shadow<br>
                              光影</div>
                          </div>
                      </div>
                      <br>

                      <div style="max-width:950px; margin:0 auto;">
                          此系列共包括两件单品：一张休闲椅和一张茶几。休闲椅的实心胡桃木结构，连接从与柔软的精制皮革相融合，缝线中呈现出低调优雅之感；咖啡桌则采用坚硬的透明水晶玻璃，配以扇形的木质底座，同柔软而坚固的皮革交相映衬。
                      </div>
                      <br><br>
                      <br><br>

                      <div class="col2" style="max-width:1296px; margin:0 auto;">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-riva-04.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-riva-05.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <div style="max-width:980px; margin:0 auto;">
                          “Yin & Yang” 以中国传统哲学的阴阳原则中汲取灵感，致力于探索和谐的边界与平衡的智慧，以现代的设计和精湛考究的工艺来定义空间。咖啡桌与柔软坚固的皮革互相映衬，以中国扇子为灵感的木质底座衬托着圆形水晶玻璃，与休闲椅一同展示着阴阳合并后的 “圆” 元素，亦令人想起时光的轮回流动，给予人一种源源不尽之感。
                          <br><br>
                          产品中的两件物料相映成趣所产生的视觉震撼，亦呼应阴阳哲学。
                      </div>
                      <br><br>
                      <br><br>

                      <img :src="url+ 'misc/stories/story-riva-06.jpg'" style="width:100%; height:auto; max-width:1296px;">
                  </div>
                  <div class=" brand-btn">
                      <a :href="url+ 'misc/stories/pdf/Riva 1920 Yin&Yang.pdf'" target="_blank" class="btn">{{ $t('sdxBrand.download_product_cat') }}</a>&nbsp;
                      <div @click="showContactVendorForm" class="btn">{{ $t('sdxBrand.contact_vendor') }}</div>
                  </div>
            </div>

            <div class="savoir" v-else-if="id==186">


                  <div class="brand-logo" style="margin:35px auto -10px;">
                      <img :src="url+logoPath"  style="max-height:80px;" />
                  </div>


                  <div v-if="lang=='en'">
                      <div class="main-name">SCREEN BED</div>
                      <div class="year">{{$t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2018</div>

                      <div style="max-width:1000px; margin:0 auto;">
                          LUXURY BRITISH BED MAKER, Savoir Beds, unveils its latest collaborative design with award winning Hong Kong-based architect, interior and product designer, Steve Leung. The SCREEN BED is a design imbued with Chinese heritage and beautifully hand crafted by British artisans.
                      </div>
                      <br><br>
                      <br><br>
                      <h2  class="ft21 mgb32">Design Concept</h2>
                      <br>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-savoir-01.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-savoir-02.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-savoir-03.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-savoir-04.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <br><br>
                      <br>

                      <div style="max-width:1000px; margin:0 auto;">
                          Inspired by the unique structural and decorative detailing of furniture from the Chinese Ming dynasty, the SCREEN BED is a new contemporary design. The head board encapsulates the signature form of an antique Chinese coromandel folding screen. Upholstered in a luxurious grey melton wool featuring two side panels in a bold lime green, finished with a complementing Dedar Tabularasa piping. Drawing upon the vibrant Chinese history of handcrafted furniture, the operable dual-hinged panels reference the seductive ambience of a traditional folding screen, which were historically commissioned and coveted by affluent connoisseurs of the world.
                          <br><br>
                          Practical yet often decorative, apron panels provided structural bracing between the legs of chairs, tables and cabinets, becoming an iconic design feature of the period. Steve has reinterpreted this signature detail with a contemporary flourish of colour in the SCREEN BED.
                          The mitred corners of the bed base reference the complex joinery methods mastered by the craftsmen of the Antique Chinese furniture.
                      </div>
                      <br><br>
                      <br><br>

                      <div class="col2">
                          <div style="margin-bottom:12px;">
                              <div style="width:100%; height:auto; max-width:738px; margin:0 auto;">
                                  <img :src="url+ 'misc/stories/story-savoir-05.jpg'" style="width:100%; height:auto;">
                                  <div class="col2 col-min-margin no-mobile" style="margin-left:0; margin-bottom:0;">
                                      <div style="font-size:13px; margin-bottom:0; line-height:17px; text-align:center;">Standard Headboard</div>
                                      <div style="font-size:13px; margin-bottom:0; line-height:17px; text-align:center;">Headboard with Screen</div>
                                  </div>
                              </div>
                          </div>
                          <div>
                              <div style="width:100%; height:auto; max-width:738px; margin:0 auto;">
                                  <img :src="url+ 'misc/stories/story-savoir-06.jpg'" style="width:100%; height:auto;">
                                  <div class="col-auto col-min-margin no-mobile" style="margin-left:0;">
                                      <div style="flex:1;font-size:13px; line-height:17px; text-align:center;">Bed Base Block</div>
                                      <div style="flex:1;font-size:13px; line-height:17px; text-align:center;">Signature Line</div>
                                      <div style="flex:1;font-size:13px; line-height:17px; text-align:center;">Contemporary Interpretation</div>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <img :src="url+ 'misc/stories/story-savoir-07.jpg'" style="width:100%; height:auto; max-width:1116px;">
                      <br><br>

                      <img :src="url+ 'misc/stories/story-savoir-08.jpg'" style="width:100%; height:auto; max-width:1242px;">
                      <br><br>
                      <br><br>
                      <br>

                      <div class="col2">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-savoir-09.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-savoir-10.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                  </div>
                  <div v-else-if="lang=='tc'">
                      <div class="main-name">SCREEN BED 床品設計</div>
                      <div class="year">{{$t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2018</div>

                      <div style="max-width:930px; margin:0 auto;">
                          英國頂級床品品牌 Savoir Beds 與國際著名建築、室內及產品設計師梁志天先生攜手合作，隆重推出最新床品設計— Screen Bed。 Screen Bed 的設計結合了現代手法及中國傳統傢俱的元素，優美地展現英國工匠的精湛手藝。
                      </div>
                      <br><br>
                      <br><br>
                      <h2  class="ft21 mgb32">設計概念</h2>
                      <br>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-savoir-01.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-savoir-02.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-savoir-03.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-savoir-04.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <br><br>
                      <br>

                      <div style="max-width:950px; margin:0 auto;">
                          Screen Bed 的設計靈感源自中國明朝傢俱，以其獨特的結構與裝飾細節為設計概念。床頭板的條線富有中國古烏木屏風的特色，並以尊貴的灰色絨布墨爾登呢絨包裹，配合左右兩旁青綠色的屏風，並以 Dedar Tabularasa 滾邊加工，倍添設計感。兩邊雙鉸鍵接合的擋板注入了中國傳統摺疊屏風的氣質，將這個頂級品牌的傢俱特色融入設計。
                          <br><br>
                          線腳既實用又有裝飾功能，為椅腳、桌腳及櫃子提供結構上的支撐，也是明式傢俱標誌性的設計特色。梁先生以現代手法演繹中國古代傢俱的特色，斜接角位則參考了中國古代傢俱工匠大師精通的細木工藝，加上自然的顏色，從細節中呈現亦古亦今的設計。
                      </div>
                      <br><br>
                      <br><br>

                      <div class="col2">
                          <div style="margin-bottom:12px;">
                              <div style="width:100%; height:auto; max-width:738px; margin:0 auto;">
                                  <img :src="url+ 'misc/stories/story-savoir-05.jpg'" style="width:100%; height:auto;">
                                  <div class="col2 col-min-margin no-mobile" style="margin-left:0; margin-bottom:0;">
                                      <div style="font-size:13px; margin-bottom:0; line-height:17px;">Standard Headboard</div>
                                      <div style="font-size:13px; margin-bottom:0; line-height:17px;">Headboard with Screen</div>
                                  </div>
                              </div>
                          </div>
                          <div>
                              <div style="width:100%; height:auto; max-width:738px; margin:0 auto;">
                                  <img :src="url+ 'misc/stories/story-savoir-06.jpg'" style="width:100%; height:auto;">
                                  <div class="col-auto col-min-margin no-mobile" style="margin-left:0;">
                                      <div style="flex:1;font-size:13px; line-height:17px;">Bed Base Block</div>
                                      <div style="flex:1;font-size:13px; line-height:17px;">Signature Line</div>
                                      <div style="flex:1;font-size:13px; line-height:17px;">Contemporary Interpretation</div>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <img :src="url+ 'misc/stories/story-savoir-07.jpg'" style="width:100%; height:auto; max-width:1116px;">
                      <br><br>

                      <img :src="url+ 'misc/stories/story-savoir-08.jpg'" style="width:100%; height:auto; max-width:1242px;">
                      <br><br>
                      <br><br>
                      <br>

                      <div class="col2">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-savoir-09.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-savoir-10.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                  </div>
                  <div v-else>
                      <div class="main-name">SCREEN BED 床品设计</div>
                      <div class="year">{{$t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2018</div>

                      <div style="max-width:930px; margin:0 auto;">
                          英国顶级床品品牌 Savoir Beds 与国际著名建筑、室内及产品设计师梁志天先生携手合作，隆重推出最新床品设计— Screen Bed。 Screen Bed 的设计结合了现代手法及中国传统家具的元素，优美地展现英国工匠的精湛手艺。
                      </div>
                      <br><br>
                      <br><br>
                      <h2  class="ft21 mgb32">设计概念</h2>
                      <br>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-savoir-01.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-savoir-02.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-savoir-03.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-savoir-04.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <br><br>
                      <br>

                      <div style="max-width:950px; margin:0 auto;">
                          Screen Bed 的设计灵感源自中国明朝家具，以其独特的结构与装饰细节为设计概念。床头板的条线富有中国古乌木屏风的特色，并以尊贵的灰色绒布墨尔登呢绒包裹，配合左右两旁青绿色的屏风，并以 Dedar Tabularasa 滚边加工，倍添设计感。两边双铰键接合的挡板注入了中国传统折叠屏风的气质，将这个顶级品牌的家具特色融入设计。
                          <br><br>
                          线脚既实用又有装饰功能，为椅脚、桌脚及柜子提供结构上的支撑，也是明式家具标志性的设计特色。梁先生以现代手法演绎中国古代家具的特色，斜接角位则参考了中国古代家具工匠大师精通的细木工艺，加上自然的颜色，从细节中呈现亦古亦今的设计。
                      </div>
                      <br><br>
                      <br><br>

                      <div class="col2">
                          <div style="margin-bottom:12px;">
                              <div style="width:100%; height:auto; max-width:738px; margin:0 auto;">
                                  <img :src="url+ 'misc/stories/story-savoir-05.jpg'" style="width:100%; height:auto;">
                                  <div class="col2 col-min-margin no-mobile" style="margin-left:0; margin-bottom:0;">
                                      <div style="font-size:13px; margin-bottom:0; line-height:17px;">Standard Headboard</div>
                                      <div style="font-size:13px; margin-bottom:0; line-height:17px;">Headboard with Screen</div>
                                  </div>
                              </div>
                          </div>
                          <div>
                              <div style="width:100%; height:auto; max-width:738px; margin:0 auto;">
                                  <img :src="url+ 'misc/stories/story-savoir-06.jpg'" style="width:100%; height:auto;">
                                  <div class="col-auto col-min-margin no-mobile" style="margin-left:0;">
                                      <div style="flex:1;font-size:13px; line-height:17px;">Bed Base Block</div>
                                      <div style="flex:1;font-size:13px; line-height:17px;">Signature Line</div>
                                      <div style="flex:1;font-size:13px; line-height:17px;">Contemporary Interpretation</div>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <img :src="url+ 'misc/stories/story-savoir-07.jpg'" style="width:100%; height:auto; max-width:1116px;">
                      <br><br>

                      <img :src="url+ 'misc/stories/story-savoir-08.jpg'" style="width:100%; height:auto; max-width:1242px;">
                      <br><br>
                      <br><br>
                      <br>

                      <div class="col2">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-savoir-09.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-savoir-10.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                  </div>
                  <div class=" brand-btn">
                      <a :href="url+ 'misc/stories/pdf/Savoir-Design-Spec-SCREEN-BED.pdf'" target="_blank" class="btn ">{{$t('sdxBrand.download_product_cat') }}</a>&nbsp;
                      <div @click="showContactVendorForm" class="btn">{{ $t('sdxBrand.contact_vendor') }}</div>
                  </div>
            </div>

            <div class="swarovski" v-else-if="id==225">


              <div class="brand-logo" style="margin:35px auto -10px;">
                <img :src="url+logoPath" style="max-width:210px;" />
              </div>


              <div v-if="lang=='en'">
                      <div class="main-name">ALLURING LANTERNS LIGHTING COLLECTIONS</div>
                      <div class="year">{{$t('sdxBrand.lighting') }} &nbsp;&bull;&nbsp; 2020 ({{$t('sdxBrand.discontinued') }})</div>

                      <div class="col2 col-min-margin" style="max-width:1298px; margin:0 auto;">
                          <div>
                              <img :src="url+ 'misc/stories/story-swarovski-01.jpg'" style="width:100%; height:auto; margin-bottom:10px;">
                              <div style="display:flex;">
                                  <div>Repetitive</div>
                                  <div>Symmetrical</div>
                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-swarovski-02.jpg'" style="width:100%; height:auto; margin-bottom:10px;">
                              <div style="display:flex;">
                                  <div>Dramatic</div>
                                  <div>Hanging</div>
                              </div>
                          </div>
                      </div>

                      <div class="col2 col-min-margin" style="max-width:1298px; margin:0 auto;">
                          <div>
                              <img :src="url+ 'misc/stories/story-swarovski-03.jpg'" style="width:100%; height:auto; margin-bottom:10px;">
                              <div style="display:flex;">
                                  <div>Reflective Surface</div>
                                  <div>Illusion</div>
                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-swarovski-04.jpg'" style="width:100%; height:auto; margin-bottom:10px;">
                              <div style="display:flex;">
                                  <div>Hexagon & Double volume</div>
                                  <div>Endless</div>
                              </div>
                          </div>
                      </div>

                      <div class="col2 col-min-margin" style="max-width:1298px; margin:0 auto;">
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-swarovski-05.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-swarovski-06.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <br><br>
                      <br><br>
                      <h2  class="ft21 mgb32">Pendant Collection</h2>
                      <br>

                      <div class="col2" style="max-width:1298px; margin:0 auto;">
                          <div style="margin-bottom:15px;"><img :src="url+ 'misc/stories/story-swarovski-07.jpg'" style="width:100%; height:auto;"></div>
                          <div><img :src="url+ 'misc/stories/story-swarovski-08.jpg'" style="width:100%; height:auto;"></div>
                      </div>

                      <br><br>
                      <br><br>
                      <h2  class="ft21 mgb32">Moonlight Collection</h2>
                      <br>

                      <div class="col2" style="max-width:1298px; margin:0 auto;">
                          <div style="margin-bottom:15px;"><img :src="url+ 'misc/stories/story-swarovski-09.jpg'" style="width:100%; height:auto;"></div>
                          <div><img :src="url+ 'misc/stories/story-swarovski-10.jpg'" style="width:100%; height:auto;"></div>
                      </div>
              </div>
              <div v-else-if="lang=='tc'">
                      <div class="main-name">ALLURING LANTERNS 飾燈系列</div>
                      <div class="year">{{$t('sdxBrand.lighting') }} &nbsp;&bull;&nbsp; 2020 ({{$t('sdxBrand.discontinued') }})</div>

                      <div class="col2 col-min-margin" style="max-width:1298px; margin:0 auto;">
                          <div>
                              <img :src="url+ 'misc/stories/story-swarovski-01.jpg'" style="width:100%; height:auto; margin-bottom:10px;">
                              <div style="display:flex;">
                                  <div>Repetitive</div>
                                  <div>Symmetrical</div>
                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-swarovski-02.jpg'" style="width:100%; height:auto; margin-bottom:10px;">
                              <div style="display:flex;">
                                  <div>Dramatic</div>
                                  <div>Hanging</div>
                              </div>
                          </div>
                      </div>

                      <div class="col2 col-min-margin" style="max-width:1298px; margin:0 auto;">
                          <div>
                              <img :src="url+ 'misc/stories/story-swarovski-03.jpg'" style="width:100%; height:auto; margin-bottom:10px;">
                              <div style="display:flex;">
                                  <div>Reflective Surface</div>
                                  <div>Illusion</div>
                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-swarovski-04.jpg'" style="width:100%; height:auto; margin-bottom:10px;">
                              <div style="display:flex;">
                                  <div>Hexagon & Double volume</div>
                                  <div>Endless</div>
                              </div>
                          </div>
                      </div>

                      <div class="col2 col-min-margin" style="max-width:1298px; margin:0 auto;">
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-swarovski-05.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-swarovski-06.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <br><br>
                      <br><br>
                      <h2  class="ft21 mgb32">Pendant Collection</h2>
                      <br>

                      <div class="col2" style="max-width:1298px; margin:0 auto;">
                          <div style="margin-bottom:15px;"><img :src="url+ 'misc/stories/story-swarovski-07.jpg'" style="width:100%; height:auto;"></div>
                          <div><img :src="url+ 'misc/stories/story-swarovski-08.jpg'" style="width:100%; height:auto;"></div>
                      </div>

                      <br><br>
                      <br><br>
                      <h2  class="ft21 mgb32">Moonlight Collection</h2>
                      <br>

                      <div class="col2" style="max-width:1298px; margin:0 auto;">
                          <div style="margin-bottom:15px;"><img :src="url+ 'misc/stories/story-swarovski-09.jpg'" style="width:100%; height:auto;"></div>
                          <div><img :src="url+ 'misc/stories/story-swarovski-10.jpg'" style="width:100%; height:auto;"></div>
                      </div>
              </div>
              <div v-else>
                      <div class="main-name">ALLURING LANTERNS 饰灯系列</div>
                      <div class="year">{{$t('sdxBrand.lighting') }} &nbsp;&bull;&nbsp; 2020 ({{$t('sdxBrand.discontinued') }})</div>

                      <div class="col2 col-min-margin" style="max-width:1298px; margin:0 auto;">
                          <div>
                              <img :src="url+ 'misc/stories/story-swarovski-01.jpg'" style="width:100%; height:auto; margin-bottom:10px;">
                              <div style="display:flex;">
                                  <div>Repetitive</div>
                                  <div>Symmetrical</div>
                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-swarovski-02.jpg'" style="width:100%; height:auto; margin-bottom:10px;">
                              <div style="display:flex;">
                                  <div>Dramatic</div>
                                  <div>Hanging</div>
                              </div>
                          </div>
                      </div>

                      <div class="col2 col-min-margin" style="max-width:1298px; margin:0 auto;">
                          <div>
                              <img :src="url+ 'misc/stories/story-swarovski-03.jpg'" style="width:100%; height:auto; margin-bottom:10px;">
                              <div style="display:flex;">
                                  <div>Reflective Surface</div>
                                  <div>Illusion</div>
                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-swarovski-04.jpg'" style="width:100%; height:auto; margin-bottom:10px;">
                              <div style="display:flex;">
                                  <div>Hexagon & Double volume</div>
                                  <div>Endless</div>
                              </div>
                          </div>
                      </div>

                      <div class="col2 col-min-margin" style="max-width:1298px; margin:0 auto;">
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-swarovski-05.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-swarovski-06.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <br><br>
                      <br><br>
                      <h2  class="ft21 mgb32">Pendant Collection</h2>
                      <br>

                      <div class="col2" style="max-width:1298px; margin:0 auto;">
                          <div style="margin-bottom:15px;"><img :src="url+ 'misc/stories/story-swarovski-07.jpg'" style="width:100%; height:auto;"></div>
                          <div><img :src="url+ 'misc/stories/story-swarovski-08.jpg'" style="width:100%; height:auto;"></div>
                      </div>

                      <br><br>
                      <br><br>
                      <h2  class="ft21 mgb32">Moonlight Collection</h2>
                      <br>

                      <div class="col2" style="max-width:1298px; margin:0 auto;">
                          <div style="margin-bottom:15px;"><img :src="url+ 'misc/stories/story-swarovski-09.jpg'" style="width:100%; height:auto;"></div>
                          <div><img :src="url+ 'misc/stories/story-swarovski-10.jpg'" style="width:100%; height:auto;"></div>
                      </div>
                  </div>

                  <br><br>
                  <div class=" brand-btn  hide">
                      <!--<a href="/assets/images/storypdf/Savoir-Design-Spec-SCREEN-BED.pdf" target="_blank" class="btn ">{{$t('sdxBrand.download_product_cat') }}</a>&nbsp;-->
                      <div @click="showContactVendorForm" class="btn">{{ $t('sdxBrand.contact_vendor') }}</div>
                  </div>

            </div>

            <div class="ta" v-else-if="id==74">
                  <div v-if="collection=='loop'">

                      <div class="brand-logo" style="margin:55px auto 15px; max-width:85%;">
                          <img :src="url+logoPath"  style="width:100%; max-width:420px;" />
                      </div>



                      <div v-if="lang=='en'">
                          <div class="main-name">LOOP OF CHARMS COLLECTION</div>
                          <div class="year">{{$t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2020</div>

                          <div class="col2 col-valign">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-01.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      "Loop of Charms" draws inspiration from our everyday life element the circular frame through which we experience moments in life.
                                      <br><br>
                                      It takes different forms in daily life, from the invisible passing of time, camera lens that capture occasions and emotions, windows that framed inspiring views it is about continuous, significant moments in life. The designer has interpreted these moments through an interplay of circular forms and open void, setting them in a contemporary, elegant language in this collection.
                                  </div>
                              </div>
                          </div>

                          <br>

                          <div class="col2 col-valign col-reverse">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-02.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      "We are capturing a paused instant within continuation for people to observe and ponder the neglected beauty of life," the designer explained. "Loop is a ubiquitous detail in life, taking cue from that, we came up with a versatile collection that blend in effortlessly with different interiors. The colour palette is kept subtle, a range of dusky and earthy colours are used, fabricating a sophisticated, timeless aura." Soft curve is a recurring feature in the collection, these gentle edges exude a sense of harmony yet understated indulgence that submerge into the surroundings.
                                      <br><br>
                                      Materials encompass fine leather, hand finished brass, African mahogany, tropical hardwood, brushed finishes on aluminium and textured woven fabric. The loop inspired curves combine with minimal design highlights the brand’s unparalleled craftsmanship and exquisite finishing.
                                  </div>
                              </div>
                          </div>

                          <br><br>


                          <div class="col2" :style="{ background:'url(' + url + 'misc/stories/story-ta-loop-03-bg.jpg) no-repeat fixed center center' }">
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-03.png'" style="width:100%; height:auto;">
                              </div>
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-04.png'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div style="max-width:860px; margin:0 auto;">
                              Echoing the simple, fluent forms of a bowl, Annulus coffee table conveys a sense of visual calmness. The tabletop is available in marvelous Carrara marble or in black mirror to further accentuate the beauty of curves. A delicate drawer hidden within the main body gives a clever touch to fully maximize the use of this sophisticated piece of furniture.
                          </div>

                          <br><br>
                          <br><br>
                          <br>

                          <div :style="{ background:'url(' + url + 'misc/stories/story-ta-loop-05-bg.jpg) no-repeat fixed center center' }">
                              <div class="col2">
                                  <div style="margin-bottom:-80px;">
                                      <img :src="url+ 'misc/stories/story-ta-loop-05.png'" style="width:100%; height:auto;">
                                  </div>
                                  <div style="margin-bottom:0;">
                                      <img :src="url+ 'misc/stories/story-ta-loop-06.png'" style="width:100%; height:auto;">
                                  </div>
                              </div>
                              <div class="col2">
                                  <div style="margin-bottom:0;">
                                      <img :src="url+ 'misc/stories/story-ta-loop-07.png'" style="width:100%; height:auto;">
                                  </div>
                                  <div style="margin-bottom:0;">
                                      <img :src="url+ 'misc/stories/story-ta-loop-08.png'" style="width:100%; height:auto;">
                                  </div>
                              </div>
                          </div>
                          <br>

                          <div style="max-width:850px; margin:0 auto;">
                              The robust, genuine design of Billowy series exudes an everlasting sense of confidence and stillness. All Billowy console table, dresser, sideboard, tall boy chest, TV and bar cabinet are distinguished by luxurious vertical upholstery. Tabletops are available in marvelous Carrara marble or solid wood.
                          </div>

                          <br><br>
                          <br><br>
                          <br>

                          <div :style="{ background:'url(' + url + 'misc/stories/story-ta-loop-09-bg.jpg) no-repeat fixed center center' }">
                              <div class="col2" style="max-width:1340px; margin:0 auto;">
                                  <div style="margin-bottom:0;">
                                      <img :src="url+ 'misc/stories/story-ta-loop-09.png'" style="width:100%; height:auto;">
                                  </div>
                                  <div style="margin-bottom:0;">
                                      <img :src="url+ 'misc/stories/story-ta-loop-10.png'" style="width:100%; height:auto;">
                                  </div>
                              </div>
                          </div>
                          <br>

                          <div style="max-width:850px; margin:0 auto;">
                              Enhancing the beauty of each ambience through its joyful trumpet-shaped silhouette crafted in bronzed glass, Campanello lamp series is further enriched by refined material details of copper and leather finishing.
                              <br><br>
                              The Campanello series are available in floor and pendant style. The pendant style can be found in combined sets of three or four lampshades or as a standalone piece to freely mix and match with the distinctive features of each space.
                          </div>

                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2 col-valign">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-11.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      Edge side table has an unconventional slanted shape featuring black mirrored tabletop and leather that defines a powerful design statement. Suitable to be enjoyed as a standalone piece or pairing with Billowy TV cabinet, Edge plays with contrasts between void and solid, yet ensuring a balanced harmony between the forms.
                                  </div>
                              </div>
                          </div>

                          <br><br>

                          <div class="col2 col-valign col-reverse">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-12.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      The iconic armrests are the signature feature of Eleganza dining armchair. The sharp contrast between looping and straight lines creates a unique structure that emphasizes the loop detail as the key element connecting the entire design structure and capturing the eye within its centre.
                                  </div>
                              </div>
                          </div>

                          <br><br>

                          <div class="col2">
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-13.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-14.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div style="max-width:880px; margin:0 auto;">
                              Distinguished by comfortable armrests and backrest upholstered in soft mattress, Enfold sofa, dining and vanity chairs are designed in a distinctive shape resembling a voluptuous embrace that encourages to indulge in tranquil moments in the utmost comfort.
                          </div>

                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2">
                              <div style="margin-bottom:0; flex:auto;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-15.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div style="margin-bottom:0; flex:auto;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-16.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div style="max-width:775px; margin:0 auto;">
                              A captivating equilibrium between contrasting shapes and materials defines the thrilling design of Fulcrum round and rectangular dining tables, available in marble or copper coloured glass tabletop. The main body is wrapped by refined leather featuring horizontal and vertical patterns to add an extra hint of visual excitement.
                          </div>

                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2 col-valign">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-17.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      Gaze display cabinet and bookcase are perfect for decorative display and storage with a personality added by a distinctive wood structure, subtly enriched by delicate design.
                                      <br><br>
                                      Gaze display cabinet features shelves in clear tempered glass, with a back panel wrapped in fabric and embellished by light strips that enhance the elegant silhouette of the cabinet.
                                      <br><br>
                                      Gaze bookcase is distinguished by a solid wood frame that firmly holds the leather shelves within its simple yet powerful design structure. The bottom leather drawer storage cleverly maximizes the use of space.
                                  </div>
                              </div>
                          </div>

                          <br>

                          <div class="col2 col-valign col-reverse">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-18.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      Designed to offer the most comfortable retreat after a busy day, Haven king bed's headboard tastefully complements the classic shape of the bed frame. Its innovative design also brings original features within the headboard’s oval edges, such as the hidden storage paired with a mini shelf customizable to become a handy bedside table.
                                  </div>
                              </div>
                          </div>

                          <br><br>
                          <br><br>

                          <div class="col2">
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-19.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-20.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div style="max-width:870px; margin:0 auto;">
                              The versatile design of Inherit coffee, side, cocktail and sofa table allow the pieces to easily fit into various interior styles, conferring a tasteful note of visual charm into each space. Exquisite details further complement the robust wood structure, adding a touch of unexpected lightness. Tabletops are available in Carrara marble or bronze coloured glass.
                          </div>

                          <br><br>
                          <br><br>
                          <br><br>

                          <div class="col2">
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-21.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-22.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div style="max-width:850px; margin:0 auto;">
                              The smooth curves of Lean sofa bring a contemporary feel that seamlessly integrates within the style of any space. Its plump shape, in a sheer contrast with the slender outer frame, expresses a sense of spacious comfort.
                              <br><br>
                              Lean side table with can be enjoyed as a standalone piece or combined with Lean sofa to ensure a stronger personalization of the space. Table top is available at white Carrara marble or black mirror surface.
                          </div>

                          <br><br>
                          <br><br>
                          <br><br>

                          <div class="col2">
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-23.jpg'" style="width:100%; height:auto;">
                              </div>

                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-24.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>

                          <div style="max-width:860px; margin:0 auto;">
                              Loop sofa, club chair, lounge chair and bench series is distinguished by a signature organic tone of sepia glossy leather. Framed into an extended oval shape, its backrest and armrests offer a continuous embrace of comfort and coziness. The looping armrests' metal finishing tastefully complement the slender bronze legs, exuding an elegant contemporary touch.
                              <br><br>
                              Loop sofa is available in two or three seaters.
                          </div>

                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2 col-valign">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-25.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      With a simple and neat structure, Oblique night stand series conveys a strong visual statement playing with a delicate contrast between symmetrical and asymmetrical lines, curvy and straight forms, open and closed space details. The thoughtful selection of refined materials employed for the tabletop, available in marvelous white Carrara marble, gracefully integrates within the wood or real leather of the main body, making this furniture piece unique. Table top is available at white Carrara marble or black mirror surface.
                                  </div>
                              </div>
                          </div>

                          <br><br>

                          <div class="col2 col-valign col-reverse">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-26.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      Prevail president armchair and office chair are both characterized by a simple and clean design; the luxurious diamond shaped quilted backrest offers a rich tactile feel, injecting an understated visual appeal into the space. Designed on a rotating base, the elegant lines of Prevail chairs allow freedom to each movement, whilst still surrounding the entire body in a voluptuous embrace.
                                  </div>
                              </div>
                          </div>

                          <br>

                          <div class="col2 col-valign">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-27.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      Urbane table and floor lamp expand the design inspiration behind Loop of Charms collection, turning its signature “loop” element into a unique feature integrated within the lamp stand. The soft lighting emitted by these delicate pieces gently complements the interiors of each space.
                                  </div>
                              </div>
                          </div>

                          <br><br>

                          <div class="col2 col-valign col-reverse">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-28.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      Echoing the iconic "loop" element from the Loop of Charms collection, Urban Loop Mirror goes beyond and becomes a must have decorative feature of a space, setting a modern and stylish tone to the interior.
                                  </div>
                              </div>
                          </div>

                          <br><br>

                          <div class="col2 col-valign">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-29.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      Distinguished by a thrilling aerodynamic structure, Vertex writing desk gives visual excitement to working ambience . The diamond shaped quilted decoration embossed in the leather body of the drawers exudes a hint of understated luxury within the design of this remarkable piece of furniture.
                                      <br><br>
                                      Vertex vanity desk is a functional and fun piece that features a rotatable mirror provided with lighting and storage details accommodating to personalized needs.
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div v-else-if="lang=='tc'">

                          <div class="main-name">LOOP OF CHARMS 家具系列</div>
                          <div class="year">{{$t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2020</div>

                          <div class="col2 col-valign">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-01.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      “Loop of Charms” 的設計靈感來源自日常生活的元素 ——“環”在生活中在圓形框架中流動的珍貴時刻。
                                      <br><br>
                                      環，於日常生活中以各種形式體現，例如時間的流逝、捕光捉影的鏡頭、讓美景變成明信片的窗框——“環”象徵著我們在生活中所締造的珍貴時刻和回憶。 設計師通過圓形、曲線條和縷空設計的交織，創作出一個現代優雅的家具系列。
                                  </div>
                              </div>
                          </div>

                          <br>

                          <div class="col2 col-valign col-reverse">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-02.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:640px; margin:0 auto; text-align:justify; padding-top:0;">
                                      「我們捕捉停頓的瞬間，讓人們觀察和思考生活中被忽視的美好。」設計師說道「環在生活中無處不在我們從這個元素加以提煉，與Theodore Alexander 推出Loop of Charms 家具系列，它能輕易融入不同的室內空間設計當中。 系列採用低調的選色，沉穩、大地色系營造出一種穩重、精緻及經典永恆的氣質。」 設計師在系列中多次運用柔和的曲線，為空間締造低調奢華及和諧之感。
                                      <br><br>
                                      材質方面，此系列運用了精細的皮革、手工黃銅、非洲桃花心木、熱帶硬木、鋁製拉絲飾面和各式布材。 靈感來源自「環」的曲線條結合簡約的設計，凸顯了品牌對精湛工藝的不懈追求。
                                  </div>
                              </div>
                          </div>

                          <br><br>


                          <div class="col2" :style="{ background:'url(' + url + 'misc/stories/story-ta-loop-03-bg.jpg) no-repeat fixed center center' }">
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-03.png'" style="width:100%; height:auto;">
                              </div>
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-04.png'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div style="max-width:796px; margin:0 auto;">
                              呼應簡單流暢的碗形，Annulus 咖啡桌予人一種平靜感。 桌面可選卡拉拉大理石或黑色鏡面更凸顯它的曲線美。 抽屜巧妙地隱藏在桌身，精緻的家具讓空間更加實用。
                          </div>

                          <br><br>
                          <br><br>
                          <br>


                          <div :style="{ background:'url(' + url + 'misc/stories/story-ta-loop-05-bg.jpg) no-repeat fixed center center' }">
                              <div class="col2">
                                  <div style="margin-bottom:-80px;">
                                      <img :src="url+ 'misc/stories/story-ta-loop-05.png'" style="width:100%; height:auto;">
                                  </div>
                                  <div style="margin-bottom:0;">
                                      <img :src="url+ 'misc/stories/story-ta-loop-06.png'" style="width:100%; height:auto;">
                                  </div>
                              </div>
                              <div class="col2">
                                  <div style="margin-bottom:0;">
                                      <img :src="url+ 'misc/stories/story-ta-loop-07.png'" style="width:100%; height:auto;">
                                  </div>
                                  <div style="margin-bottom:0;">
                                      <img :src="url+ 'misc/stories/story-ta-loop-08.png'" style="width:100%; height:auto;">
                                  </div>
                              </div>
                          </div>
                          <br>

                          <div style="max-width:796px; margin:0 auto;">
                              Billowy 系列的設計予人沉實穩重及剛強自信的氣質。 系列的桌子、梳妝枱、邊櫃、五斗櫃、電視枱和酒櫃，均以垂直坑紋皮革軟墊作裝飾，增添低調的華麗感。 桌面可選卡拉拉大理石或木材。
                          </div>

                          <br><br>
                          <br><br>
                          <br>

                          <div :style="{ background:'url(' + url + 'misc/stories/story-ta-loop-09-bg.jpg) no-repeat fixed center center' }">
                              <div class="col2" style="max-width:1340px; margin:0 auto;">
                                  <div style="margin-bottom:0;">
                                      <img :src="url+ 'misc/stories/story-ta-loop-09.png'" style="width:100%; height:auto;">
                                  </div>
                                  <div style="margin-bottom:0;">
                                      <img :src="url+ 'misc/stories/story-ta-loop-10.png'" style="width:100%; height:auto;">
                                  </div>
                              </div>
                          </div>
                          <br>

                          <div style="max-width:796px; margin:0 auto;">
                              Campanello 燈飾系列的設計靈感來源自喇叭造型，以銅色玻璃製成精緻的銅及皮革飾面的細節，讓燈具散發優雅的質感。 Campanello 系列設有座地燈和吊燈兩種款式。 吊燈設三個或四個燈罩的組合，也可獨立使用方便靈活拼配於不同風格的空間當中。
                          </div>

                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2 col-valign">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-11.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      Edge 邊桌的設計獨特，其傾斜的形態搭配黑鏡桌面和皮革材質，為空間注入現代設計感。 邊桌可獨立使用，亦可配搭 Billowy 電視櫃，兩件家具發揮虛實對比又巧妙地達致形態上的平衡和諧。
                                  </div>
                              </div>
                          </div>

                          <br><br>

                          <div class="col2 col-valign col-reverse">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-12.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      Eleganza 餐椅採用了系列中標誌性“環”金屬扶手，環形和直線的獨特結構形成了有趣的對比，讓餐椅輕易成為空間的亮點。
                                  </div>
                              </div>
                          </div>

                          <br><br>

                          <div class="col2">
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-13.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-14.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div style="max-width:620px; margin:0 auto;">
                              舒適的扶手和軟墊靠背為 Enfold 沙發、餐椅和梳妝椅的一大特色，Enfold 的柔軟懷抱，讓您沉醉於最舒適及寧靜的時刻。
                          </div>

                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2">
                              <div style="margin-bottom:0; flex:auto;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-15.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div style="margin-bottom:0; flex:auto;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-16.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div style="max-width:620px; margin:0 auto;">
                              Fulcrum 圓形和長形餐桌的設計大膽創新運用了對比鮮明的形狀和材質，球體桌腳主體以精緻的皮革包裹，縱橫交錯的皮革紋理增添了視覺驚喜，桌面材質可選大理石或銅色玻璃。
                          </div>

                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2 col-valign">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-17.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      Gaze 展示櫃和書架適合展示裝飾品和珍藏，其獨特的木結構為精緻的設計增添個性，整件家具得以昇華。
                                      <br><br>
                                      Gaze 展示櫃的層架採用透明鋼化玻璃背板以織料包裹，並以隱藏燈條點綴，突顯出整個櫃身的優雅輪廓。
                                      <br><br>
                                      Gaze 書櫃用上實木框架，框架將皮革層架牢牢固定在簡約剛強的設計結構中。 底部的皮革抽屜則巧妙地了增加了儲物的空間。
                                  </div>
                              </div>
                          </div>

                          <br>

                          <div class="col2 col-valign col-reverse">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-18.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      Haven 床頭板為辛勞一天后的您，提供最舒適的休息體驗，床頭板精心設計與經典的床架互相搭配。 床頭板的設計創新，兩側的橢圓形架具有隱藏的存儲功能，並可定制內裡的小型層架，成為方便實用的床頭桌。
                                  </div>
                              </div>
                          </div>

                          <br><br>
                          <br><br>

                          <div class="col2">
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-19.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-20.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div style="max-width:810px; margin:0 auto;">
                              Inherit 咖啡桌、邊桌和沙發桌有多個款式，可輕易融入各種室內風格，為空間實用又雅致兼備。 堅固的木腳結構，展示品牌精緻的造工與細節。桌面可選卡拉拉大理石或青銅色玻璃。
                          </div>

                          <br><br>
                          <br><br>
                          <br><br>

                          <div class="col2">
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-21.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-22.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div style="max-width:650px; margin:0 auto;">
                              Lean 沙發的流線型營造現代感，能輕易融入不同風格的空間中。 座椅舒適飽滿，與纖長的皮革結構形成對比，讓沙發更顯寬敞舒適。
                              <br><br>
                              Lean 邊桌可獨立使用或與 Lean 沙發組合，體貼用家的個性化需要。 桌面可選用白色卡拉拉大理石或黑色鏡面。
                          </div>

                          <br><br>
                          <br><br>
                          <br><br>

                          <div class="col2">
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-23.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-24.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>

                          <div style="max-width:870px; margin:0 auto;">
                              Loop 沙發、單椅、休閒椅和長椅系列，採用了整個 Loop of Charms 系列中標誌性的深褐色光面皮革。 靠背和扶手結合為橢圓筒形，連貫的設計提供整體包裹的舒適感。 環形扶手配上金屬裝飾與細長的銅腿座互相搭配，散發出現代優雅氣息。
                              Loop 沙發可選兩座位或三座位。
                          </div>

                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2 col-valign">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-25.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      Oblique 床頭櫃的設計簡約，以對稱和不對稱、彎曲和筆直的線條； 開和關的存格做成有趣的對比，其特別的形態給予用家視覺的驚喜。 精選的材質，白色卡拉拉大理石優美地融入木材或及真皮主體，凸顯家具之獨特性。 桌面可選用白色卡拉拉大理石或黑色鏡面。
                                  </div>
                              </div>
                          </div>

                          <br><br>

                          <div class="col2 col-valign col-reverse">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-26.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      Prevail 扶手椅和辦公椅的設計簡潔； 華麗的菱形絎縫靠背，低調奢華的細節為座椅增添觸感。 Prevail 椅子的旋轉底座設計提升其靈活性，同時它亦非常舒適，就如被包裹在柔軟的懷抱中。
                                  </div>
                              </div>
                          </div>

                          <br>

                          <div class="col2 col-valign">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-27.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      Urbane 枱燈和落地燈延伸了整個家具系列的設計靈感，將其標誌性的「環」元素與燈座完美融合。 精緻的燈具發出的柔和光線，提升空間的氛圍及設計感。
                                  </div>
                              </div>
                          </div>

                          <br><br>

                          <div class="col2 col-valign col-reverse">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-28.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      Urban Loop 鏡子呼應系列中標誌性的「環」元素，為空間注入觸目的亮點及現代時尚的格調。
                                  </div>
                              </div>
                          </div>

                          <br><br>

                          <div class="col2 col-valign">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-29.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      Vertex 書桌的超現代流線形態，為工作環境加添視覺效果。 抽屜上的菱形絎縫皮革壓花細節，家具為空間賦予低調的奢華氣質。
                                      <br><br>
                                      Vertex 梳妝枱是一件功能性和趣味性兼備的作品，可旋轉的鏡子具照明功能，枱面亦有隱藏的儲物格功能，滿足用家個性化的需求。
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div v-else>

                          <div class="main-name">LOOP OF CHARMS 家具系列</div>
                          <div class="year">{{$t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2020</div>

                          <div class="col2 col-valign">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-01.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      “Loop of Charms” 的设计灵感来源自日常生活的元素 ——“环”在生活中在圆形框架中流动的珍贵时刻。
                                      <br><br>
                                      环，于日常生活中以各种形式体现，例如时间的流逝、捕光捉影的镜头、让美景变成明信片的窗框 ——“环”象征着我们在生活中所缔造的珍贵时刻和回忆。 设计师通过圆形、曲线条和缕空设计的交织，创作出一个现代优雅的家具系列。
                                  </div>
                              </div>
                          </div>

                          <br>

                          <div class="col2 col-valign col-reverse">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-02.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:640px; margin:0 auto; text-align:justify; padding-top:0;">
                                      「我们捕捉停顿的瞬间，让人们观察和思考生活中被忽视的美好。」设计师说道「环在生活中无处不在我们从这个元素加以提炼，与 Theodore Alexander 推出 Loop of Charms 家具系列，它能轻易融入不同的室内空间设计当中。系列采用低调的选色，沉稳、大地色系营造出一种稳重、精致及经典永恒的气质。」 设计师在系列中多次运用柔和的曲线，为空间缔造低调奢华及和谐之感。
                                      <br><br>
                                      材质方面，此系列运用了精细的皮革、手工黄铜、非洲桃花心木、热带硬木、铝制拉丝饰面和各式布材。 灵感来源自「环」的曲线条结合简约的设计，凸显了品牌对精湛工艺的不懈追求。
                                  </div>
                              </div>
                          </div>

                          <br><br>


                          <div class="col2" :style="{ background:'url(' + url + 'misc/stories/story-ta-loop-03-bg.jpg) no-repeat fixed center center' }">
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-03.png'" style="width:100%; height:auto;">
                              </div>
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-04.png'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div style="max-width:796px; margin:0 auto;">
                              呼应简单流畅的碗形，Annulus 咖啡桌予人一种平静感。 桌面可选卡拉拉大理石或黑色镜面更凸显它的曲线美。 抽屉巧妙地隐藏在桌身，精致的家具让空间更加实用。
                          </div>

                          <br><br>
                          <br><br>
                          <br>


                          <div :style="{ background:'url(' + url + 'misc/stories/story-ta-loop-05-bg.jpg) no-repeat fixed center center' }">
                              <div class="col2">
                                  <div style="margin-bottom:-80px;">
                                      <img :src="url+ 'misc/stories/story-ta-loop-05.png'" style="width:100%; height:auto;">
                                  </div>
                                  <div style="margin-bottom:0;">
                                      <img :src="url+ 'misc/stories/story-ta-loop-06.png'" style="width:100%; height:auto;">
                                  </div>
                              </div>
                              <div class="col2">
                                  <div style="margin-bottom:0;">
                                      <img :src="url+ 'misc/stories/story-ta-loop-07.png'" style="width:100%; height:auto;">
                                  </div>
                                  <div style="margin-bottom:0;">
                                      <img :src="url+ 'misc/stories/story-ta-loop-08.png'" style="width:100%; height:auto;">
                                  </div>
                              </div>
                          </div>
                          <br>

                          <div style="max-width:796px; margin:0 auto;">
                              Billowy 系列的设计予人沉实稳重及刚强自信的气质。 系列的桌子、梳妆枱、边柜、五斗柜、电视枱和酒柜，均以垂直坑纹皮革软垫作装饰，增添低调的华丽感。 桌面可选卡拉拉大理石或木材。
                          </div>

                          <br><br>
                          <br><br>
                          <br>

                          <div :style="{ background:'url(' + url + 'misc/stories/story-ta-loop-09-bg.jpg) no-repeat fixed center center' }">
                              <div class="col2" style="max-width:1340px; margin:0 auto;">
                                  <div style="margin-bottom:0;">
                                      <img :src="url+ 'misc/stories/story-ta-loop-09.png'" style="width:100%; height:auto;">
                                  </div>
                                  <div style="margin-bottom:0;">
                                      <img :src="url+ 'misc/stories/story-ta-loop-10.png'" style="width:100%; height:auto;">
                                  </div>
                              </div>
                          </div>
                          <br>

                          <div style="max-width:796px; margin:0 auto;">
                              Campanello 灯饰系列的设计灵感来源自喇叭造型，以铜色玻璃制成精致的铜及皮革饰面的细节，让灯具散发优雅的质感。 Campanello 系列设有座地灯和吊灯两种款式。 吊灯设三个或四个灯罩的组合，也可独立使用方便灵活拼配于不同风格的空间当中。
                          </div>

                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2 col-valign">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-11.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      Edge 边桌的设计独特，其倾斜的形态搭配黑镜桌面和皮革材质，为空间注入现代设计感。 边桌可独立使用，亦可配搭 Billowy 电视柜，两件家具发挥虚实对比又巧妙地达致形态上的平衡和谐。
                                  </div>
                              </div>
                          </div>

                          <br><br>

                          <div class="col2 col-valign col-reverse">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-12.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      Eleganza 餐椅采用了系列中标志性“环”金属扶手，环形和直线的独特结构形成了有趣的对比，让餐椅轻易成为空间的亮点。
                                  </div>
                              </div>
                          </div>

                          <br><br>

                          <div class="col2">
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-13.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-14.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div style="max-width:620px; margin:0 auto;">
                              舒适的扶手和软垫靠背为 Enfold 沙发、餐椅和梳妆椅的一大特色，Enfold 的柔软怀抱，让您沉醉于最舒适及宁静的时刻。
                          </div>

                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2">
                              <div style="margin-bottom:0; flex:auto;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-15.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div style="margin-bottom:0; flex:auto;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-16.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div style="max-width:620px; margin:0 auto;">
                              Fulcrum 圆形和长形餐桌的设计大胆创新运用了对比鲜明的形状和材质，球体桌脚主体以精致的皮革包裹，纵横交错的皮革纹理增添了视觉惊喜，桌面材质可选大理石或铜色玻璃。
                          </div>

                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2 col-valign">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-17.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      Gaze 展示柜和书架适合展示装饰品和珍藏，其独特的木结构为精致的设计增添个性，整件家具得以升华。
                                      <br><br>
                                      Gaze 展示柜的层架采用透明钢化玻璃背板以织料包裹，并以隐藏灯条点缀，突显出整个柜身的优雅轮廓。
                                      <br><br>
                                      Gaze 书柜用上实木框架，框架将皮革层架牢牢固定在简约刚强的设计结构中。 底部的皮革抽屉则巧妙地了增加了储物的空间。
                                  </div>
                              </div>
                          </div>

                          <br>

                          <div class="col2 col-valign col-reverse">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-18.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      Haven 床头板为辛劳一天后的您，提供最舒适的休息体验，床头板精心设计与经典的床架互相搭配。 床头板的设计创新，两侧的椭圆形架具有隐藏的存储功能，并可定制内里的小型层架，成为方便实用的床头桌。
                                  </div>
                              </div>
                          </div>

                          <br><br>
                          <br><br>

                          <div class="col2">
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-19.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-20.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div style="max-width:810px; margin:0 auto;">
                              Inherit 咖啡桌、边桌和沙发桌有多个款式，可轻易融入各种室内风格，为空间实用又雅致兼备。 坚固的木脚结构，展示品牌精致的造工与细节。桌面可选卡拉拉大理石或青铜色玻璃。
                          </div>

                          <br><br>
                          <br><br>
                          <br><br>

                          <div class="col2">
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-21.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-22.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div style="max-width:650px; margin:0 auto;">
                              Lean 沙发的流线型营造现代感，能轻易融入不同风格的空间中。 座椅舒适饱满，与纤长的皮革结构形成对比，让沙发更显宽敞舒适。
                              <br><br>
                              Lean 边桌可独立使用或与 Lean 沙发组合，体贴用家的个性化需要。 桌面可选用白色卡拉拉大理石或黑色镜面。
                          </div>

                          <br><br>
                          <br><br>
                          <br><br>

                          <div class="col2">
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-23.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div style="margin-bottom:0;">
                                  <img :src="url+ 'misc/stories/story-ta-loop-24.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>

                          <div style="max-width:870px; margin:0 auto;">
                              Loop 沙发、单椅、休闲椅和长椅系列，采用了整个 Loop of Charms 系列中标志性的深褐色光面皮革。 靠背和扶手结合为椭圆筒形，连贯的设计提供整体包裹的舒适感。 环形扶手配上金属装饰与细长的铜腿座互相搭配，散发出现代优雅气息。
                              Loop 沙发可选两座位或三座位。
                          </div>

                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2 col-valign">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-25.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      Oblique 床头柜的设计简约，以对称和不对称、弯曲和笔直的线条； 开和关的存格做成有趣的对比，其特别的形态给予用家视觉的惊喜。 精选的材质，白色卡拉拉大理石优美地融入木材或及真皮主体，凸显家具之独特性。 桌面可选用白色卡拉拉大理石或黑色镜面。
                                  </div>
                              </div>
                          </div>

                          <br><br>

                          <div class="col2 col-valign col-reverse">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-26.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      Prevail 扶手椅和办公椅的设计简洁； 华丽的菱形绗缝靠背，低调奢华的细节为座椅增添触感。 Prevail 椅子的旋转底座设计提升其灵活性，同时它亦非常舒适，就如被包裹在柔软的怀抱中。
                                  </div>
                              </div>
                          </div>

                          <br>

                          <div class="col2 col-valign">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-27.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      Urbane 枱灯和落地灯延伸了整个家具系列的设计灵感，将其标志性的「环」元素与灯座完美融合。 精致的灯具发出的柔和光线，提升空间的氛围及设计感。
                                  </div>
                              </div>
                          </div>

                          <br><br>

                          <div class="col2 col-valign col-reverse">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-28.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      Urban Loop 镜子呼应系列中标志性的「环」元素，为空间注入触目的亮点及现代时尚的格调。
                                  </div>
                              </div>
                          </div>

                          <br><br>

                          <div class="col2 col-valign">
                              <div>
                                  <img :src="url+ 'misc/stories/story-ta-loop-29.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify; padding-top:0;">
                                      Vertex 书桌的超现代流线形态，为工作环境加添视觉效果。 抽屉上的菱形绗缝皮革压花细节，家具为空间赋予低调的奢华气质。
                                      <br><br>
                                      Vertex 梳妆枱是一件功能性和趣味性兼备的作品，可旋转的镜子具照明功能，枱面亦有隐藏的储物格功能，满足用家个性化的需求。
                                  </div>
                              </div>
                          </div>
                      </div>



                      <div class=" brand-btn">
                          <!--<a href="/assets/images/storypdf/SLD_TA_Oct 2017.pdf" target="_blank" class="btn ">{{$t('sdxBrand.download_product_cat') }}</a>&nbsp;-->
                          <div @click="showContactVendorForm" class="btn">{{ $t('sdxBrand.contact_vendor') }}</div>
                      </div>
                  </div>

                  <div v-else>
                      <div class="ta">
                          <div class="brand-logo" style="margin:55px auto 15px; max-width:85%;">
                              <img :src="url+logoPath"  style="width:100%; max-width:420px;" />
                          </div>



                          <div v-if="lang=='en'">
                              <div class="main-name">LUXURY OF SERENITY COLLECTION</div>
                              <div class="year">{{$t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2017</div>

                              <div style="max-width:1100px; margin:0 auto;">
                                  SLD has worked with English Heritage-Inspired Theodore Alexander to launch its Luxury of Serenity collection, unveiled at the international High Point Market in North Carolina in April,2017. Exploring personal retreat with an ambience of warmth and relaxation, the collection marries elegant design with precise craftsmanship to create gently curved surfaces and expressive backrests in a collection which comprises pieces made for living room, dining room and bedroom.
                              </div>
                              <br><br>
                              <br><br>
                              <h2  class="ft21 mgb32">Design Concept</h2>
                              <br>

                              <div class="col2">
                                  <div style="margin-bottom:20px;">
                                      <div class="col2 no-mobile">
                                          <div style="margin-bottom:0px;">
                                              <img :src="url+ 'misc/stories/story-ta-01.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                              <div style="margin-bottom:0px;">
                                                  Relaxed Lifestyle
                                              </div>
                                          </div>
                                          <div style="margin-bottom:0px;">
                                              <img :src="url+ 'misc/stories/story-ta-02.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                              <div style="margin-bottom:0px;">
                                                  Diamond Detailing
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div>
                                      <div class="col2 no-mobile">
                                          <div style="margin-bottom:0px;">
                                              <img :src="url+ 'misc/stories/story-ta-03.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                              <div style="margin-bottom:0px;">
                                                  Performing Arts
                                              </div>
                                          </div>
                                          <div style="margin-bottom:0px;">
                                              <img :src="url+ 'misc/stories/story-ta-04.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                              <div style="margin-bottom:0px;">
                                                  Two Tone
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <br><br>
                              <br>

                              <img :src="url+ 'misc/stories/story-ta-05.jpg'" style="width:100%; height:auto; max-width:1240px;">
                              <br><br>

                              <img :src="url+ 'misc/stories/story-ta-06.jpg'" style="width:100%; height:auto; max-width:1240px;">
                              <br><br>
                              <br><br>
                              <br><br>

                              <img :src="url+ 'misc/stories/story-ta-07.jpg'" style="width:100%; height:auto; margin-bottom:90px;">

                              <div class="col2">
                                  <div>
                                      <img :src="url+ 'misc/stories/story-ta-08.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                      <div>
                                          Covet Sofa
                                      </div>
                                  </div>
                                  <div>
                                      <img :src="url+ 'misc/stories/story-ta-09.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                      <div>
                                          Covet Deep Desire Armchair
                                      </div>
                                  </div>
                              </div>

                              <div class="col2">
                                  <div>
                                      <img :src="url+ 'misc/stories/story-ta-10.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                      <div>
                                          Allure Attraction Coffee Table
                                      </div>
                                  </div>
                                  <div>
                                      <img :src="url+ 'misc/stories/story-ta-11.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                      <div>
                                          Contour Side Table Large & Small
                                      </div>
                                  </div>
                              </div>

                              <div class="col2">
                                  <div>
                                      <img :src="url+ 'misc/stories/story-ta-12.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                      <div>
                                          Pirouette Round Dining Table
                                      </div>
                                  </div>
                                  <div>
                                      <img :src="url+ 'misc/stories/story-ta-13.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                      <div>
                                          Covet Dining Chair
                                      </div>
                                  </div>
                              </div>

                              <div class="col2">
                                  <div style="margin-bottom:0px;">
                                      <img :src="url+ 'misc/stories/story-ta-14.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                      <div>
                                          Amour Bed
                                      </div>
                                  </div>
                                  <div style="margin-bottom:0px;">
                                      <img :src="url+ 'misc/stories/story-ta-15.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                      <div>
                                          Amour Night Stand
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div v-else-if="lang=='tc'">
                              <div class="main-name">LUXURY OF SERENITY 家具系列</div>
                              <div class="year">{{$t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2017</div>

                              <div style="max-width:1000px; margin:0 auto;">
                                  英國高級手工家具品牌 Theodore Alexander 與 SLD 是首度合作，於 2017 年四月在美國北卡羅萊納州高點市推出名為「Luxury of Serenity」家具系列。此系列以探索個人休憩空間和生活享受為設計概念，透過流線型的設計和柔軟舒適的材料，加上精湛和細膩的工藝，在客廳、餐廳和臥室締造溫暖和優雅靜謐的氣氛。
                              </div>
                              <br><br>
                              <br><br>
                              <h2  class="ft21 mgb32">設計概念</h2>
                              <br>

                              <div class="col2">
                                  <div style="margin-bottom:20px;">
                                      <div class="col2 no-mobile">
                                          <div style="margin-bottom:0px;">
                                              <img :src="url+ 'misc/stories/story-ta-01.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                              <div style="margin-bottom:0px;">
                                                  Relaxed Lifestyle
                                              </div>
                                          </div>
                                          <div style="margin-bottom:0px;">
                                              <img :src="url+ 'misc/stories/story-ta-02.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                              <div style="margin-bottom:0px;">
                                                  Diamond Detailing
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div>
                                      <div class="col2 no-mobile">
                                          <div style="margin-bottom:0px;">
                                              <img :src="url+ 'misc/stories/story-ta-03.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                              <div style="margin-bottom:0px;">
                                                  Performing Arts
                                              </div>
                                          </div>
                                          <div style="margin-bottom:0px;">
                                              <img :src="url+ 'misc/stories/story-ta-04.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                              <div style="margin-bottom:0px;">
                                                  Two Tone
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <br><br>
                              <br>

                              <img :src="url+ 'misc/stories/story-ta-05.jpg'" style="width:100%; height:auto; max-width:1240px;">
                              <br><br>

                              <img :src="url+ 'misc/stories/story-ta-06.jpg'" style="width:100%; height:auto; max-width:1240px;">
                              <br><br>
                              <br><br>
                              <br><br>

                              <img :src="url+ 'misc/stories/story-ta-07.jpg'" style="width:100%; height:auto; margin-bottom:90px;">

                              <div class="col2">
                                  <div>
                                      <img :src="url+ 'misc/stories/story-ta-08.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                      <div>
                                          Covet Sofa
                                      </div>
                                  </div>
                                  <div>
                                      <img :src="url+ 'misc/stories/story-ta-09.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                      <div>
                                          Covet Deep Desire Armchair
                                      </div>
                                  </div>
                              </div>

                              <div class="col2">
                                  <div>
                                      <img :src="url+ 'misc/stories/story-ta-10.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                      <div>
                                          Allure Attraction Coffee Table
                                      </div>
                                  </div>
                                  <div>
                                      <img :src="url+ 'misc/stories/story-ta-11.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                      <div>
                                          Contour Side Table Large & Small
                                      </div>
                                  </div>
                              </div>

                              <div class="col2">
                                  <div>
                                      <img :src="url+ 'misc/stories/story-ta-12.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                      <div>
                                          Pirouette Round Dining Table
                                      </div>
                                  </div>
                                  <div>
                                      <img :src="url+ 'misc/stories/story-ta-13.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                      <div>
                                          Covet Dining Chair
                                      </div>
                                  </div>
                              </div>

                              <div class="col2">
                                  <div style="margin-bottom:0px;">
                                      <img :src="url+ 'misc/stories/story-ta-14.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                      <div>
                                          Amour Bed
                                      </div>
                                  </div>
                                  <div style="margin-bottom:0px;">
                                      <img :src="url+ 'misc/stories/story-ta-15.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                      <div>
                                          Amour Night Stand
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div v-else>

                              <div class="main-name">LUXURY OF SERENITY 家具系列</div>
                              <div class="year">{{$t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2017</div>

                              <div style="max-width:1000px; margin:0 auto;">
                                  英国高级手工家具品牌 Theodore Alexander 与 SLD 是首度合作，于 2017 年四月在美国北卡罗莱纳州高点市推出名为「Luxury of Serenity」家具系列。此系列以探索个人休憩空间和生活享受为设计概念，透过流线型的设计和柔软舒适的材料，加上精湛和细腻的工艺，在客厅、餐厅和卧室缔造温暖和优雅静谧的气氛。
                              </div>
                              <br><br>
                              <br><br>
                              <h2  class="ft21 mgb32">设计概念</h2>
                              <br>

                              <div class="col2">
                                  <div style="margin-bottom:20px;">
                                      <div class="col2 no-mobile">
                                          <div style="margin-bottom:0px;">
                                              <img :src="url+ 'misc/stories/story-ta-01.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                              <div style="margin-bottom:0px;">
                                                  Relaxed Lifestyle
                                              </div>
                                          </div>
                                          <div style="margin-bottom:0px;">
                                              <img :src="url+ 'misc/stories/story-ta-02.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                              <div style="margin-bottom:0px;">
                                                  Diamond Detailing
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div>
                                      <div class="col2 no-mobile">
                                          <div style="margin-bottom:0px;">
                                              <img :src="url+ 'misc/stories/story-ta-03.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                              <div style="margin-bottom:0px;">
                                                  Performing Arts
                                              </div>
                                          </div>
                                          <div style="margin-bottom:0px;">
                                              <img :src="url+ 'misc/stories/story-ta-04.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                              <div style="margin-bottom:0px;">
                                                  Two Tone
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <br><br>
                              <br>

                              <img :src="url+ 'misc/stories/story-ta-05.jpg'" style="width:100%; height:auto; max-width:1240px;">
                              <br><br>

                              <img :src="url+ 'misc/stories/story-ta-06.jpg'" style="width:100%; height:auto; max-width:1240px;">
                              <br><br>
                              <br><br>
                              <br><br>

                              <img :src="url+ 'misc/stories/story-ta-07.jpg'" style="width:100%; height:auto; margin-bottom:90px;">

                              <div class="col2">
                                  <div>
                                      <img :src="url+ 'misc/stories/story-ta-08.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                      <div>
                                          Covet Sofa
                                      </div>
                                  </div>
                                  <div>
                                      <img :src="url+ 'misc/stories/story-ta-09.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                      <div>
                                          Covet Deep Desire Armchair
                                      </div>
                                  </div>
                              </div>

                              <div class="col2">
                                  <div>
                                      <img :src="url+ 'misc/stories/story-ta-10.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                      <div>
                                          Allure Attraction Coffee Table
                                      </div>
                                  </div>
                                  <div>
                                      <img :src="url+ 'misc/stories/story-ta-11.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                      <div>
                                          Contour Side Table Large & Small
                                      </div>
                                  </div>
                              </div>

                              <div class="col2">
                                  <div>
                                      <img :src="url+ 'misc/stories/story-ta-12.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                      <div>
                                          Pirouette Round Dining Table
                                      </div>
                                  </div>
                                  <div>
                                      <img :src="url+ 'misc/stories/story-ta-13.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                      <div>
                                          Covet Dining Chair
                                      </div>
                                  </div>
                              </div>

                              <div class="col2">
                                  <div style="margin-bottom:0px;">
                                      <img :src="url+ 'misc/stories/story-ta-14.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                      <div>
                                          Amour Bed
                                      </div>
                                  </div>
                                  <div style="margin-bottom:0px;">
                                      <img :src="url+ 'misc/stories/story-ta-15.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                                      <div>
                                          Amour Night Stand
                                      </div>
                                  </div>
                              </div>

                          </div>

                      </div>

                      <div class=" brand-btn">
                          <a :href="url+ 'misc/stories/pdf/SLD_TA_Oct 2017.pdf'" target="_blank" class="btn ">{{$t('sdxBrand.download_product_cat') }}</a>&nbsp;
                          <div @click="showContactVendorForm" class="btn">{{ $t('sdxBrand.contact_vendor') }}</div>
                      </div>
                  </div>
            </div>

            <div class="treca"  v-else-if="id==187">


                  <div class="brand-logo" style="margin:35px auto 0px;">
                      <img :src="url+logoPath" style="max-height:90px;" />
                  </div>


                  <div v-if="lang=='en'">
                      <div class="main-name">Bedstead & Furniture</div>
                      <div class="year">{{ $t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2017</div>

                      <div style="max-width:1240px; margin:0 auto;">
                          Inheriting the exquisite craft of haute couture TRECA, the national treasure level mattress brand in France combines rare natural fiber and high-tech fabric with the noble tradition of French court, achieves a divary status of sleep artwork. SLD is commissioned to work on the bedstead and furniture design for its two latest products, and will also provide interior and FF&E design for its showroom at the 23rd China International Furniture Expo, Shanghai.
                      </div>
                      <br><br>
                      <br><br>
                      <h2  class="ft21 mgb32">Legacy</h2>
                      <br>
                      <div class="col-auto col-min-margin" style="max-width:1240px; margin:0 auto;">
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-01.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-02.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-03.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <h2  class="ft21 mgb32">Craftsmanship</h2>
                      <br>
                      <div class="col-auto col-min-margin" style="max-width:1240px; margin:0 auto;">
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-04.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-05.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-06.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <h2  class="ft21 mgb32">Designers</h2>
                      <br>
                      <ul class="designers">
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-07.jpg'" style="width:100%; height:auto;">
                              Pascal Allaman
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-08.jpg'" style="width:100%; height:auto;">
                              Mathilde Bretillot
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-09.jpg'" style="width:100%; height:auto;">
                              Sandrine Chollet
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-10.jpg'" style="width:100%; height:auto;">
                              Eric Gizard
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-11.jpg'" style="width:100%; height:auto;">
                              Matteo Thun
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-12.jpg'" style="width:100%; height:auto;">
                              Hoffmann Kahleyss Design
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-13.jpg'" style="width:100%; height:auto;">
                              Annette Lang
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-14.jpg'" style="width:100%; height:auto;">
                              Patrick Naggar
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-15.jpg'" style="width:100%; height:auto;">
                              Andreas Weber
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-16.jpg'" style="width:100%; height:auto;">
                              Steve Leung
                          </li>
                      </ul>
                      <br><br>
                      <br>

                      <h2  class="ft21 mgb32">TRECA DNA</h2>

                      French Elegance
                      <br><br>

                      <div class="col2 col-min-margin" style="max-width:1582px; margin:0 auto;">
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-17.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-treca-18.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br>

                      <h2  class="ft21 mgb32">Design Inspiration</h2>

                      Feminine Curves
                      <br><br>

                      <div class="col2 col-min-margin" style="max-width:1582px; margin:0 auto;">
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-19.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-treca-20.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      Sophisticated Lines
                      <br><br>

                      <div class="col2 col-min-margin" style="max-width:1582px; margin:0 auto;">
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-21.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-treca-22.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <div class="col2">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-treca-23.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-treca-24.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <div class="col2">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-treca-25.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-treca-26.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <div style="background:#000; margin-bottom:20px;">
                          <img :src="url+ 'misc/stories/story-treca-27.jpg'" style="width:100%; height:auto; max-width:895px;">
                      </div>

                      <div class="col2">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-treca-28.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-treca-29.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <div class="col2">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-treca-30.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-treca-31.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <div style="background:#000; margin-bottom:40px;">
                          <img :src="url+ 'misc/stories/story-treca-32.jpg'" style="width:100%; height:auto; max-width:895px;">
                      </div>
                  </div>
                  <div v-else-if="lang=='tc'">
                      <div class="main-name">床架及配套家具</div>
                      <div class="year">{{ $t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2017</div>

                      <div style="max-width:945px; margin:0 auto;">
                          法國國寶級床墊品牌— TRECA(崔佧)秉承高級時裝定制的精湛工藝，以珍貴天然纖維和高科技面料，結合法國宮廷定制的高貴傳統，成就全球睡眠藝術品的傳奇地位。我司將為其最新兩款產品設計床架及配套家具，並為第二十三屆上海中國國際家具展覽會上崔佧品牌的展廳提供空間設計及軟裝設計。
                      </div>
                      <br><br>
                      <br><br>
                      <h2  class="ft21 mgb32">設計傳奇</h2>
                      <br>
                      <div class="col-auto col-min-margin" style="max-width:1240px; margin:0 auto;">
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-01.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-02.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-03.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <h2  class="ft21 mgb32">精湛工藝</h2>
                      <br>
                      <div class="col-auto col-min-margin" style="max-width:1240px; margin:0 auto;">
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-04.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-05.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-06.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <h2  class="ft21 mgb32">合作設計師</h2>
                      <br>
                      <ul class="designers">
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-07.jpg'" style="width:100%; height:auto;">
                              Pascal Allaman
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-08.jpg'" style="width:100%; height:auto;">
                              Mathilde Bretillot
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-09.jpg'" style="width:100%; height:auto;">
                              Sandrine Chollet
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-10.jpg'" style="width:100%; height:auto;">
                              Eric Gizard
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-11.jpg'" style="width:100%; height:auto;">
                              Matteo Thun
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-12.jpg'" style="width:100%; height:auto;">
                              Hoffmann Kahleyss Design
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-13.jpg'" style="width:100%; height:auto;">
                              Annette Lang
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-14.jpg'" style="width:100%; height:auto;">
                              Patrick Naggar
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-15.jpg'" style="width:100%; height:auto;">
                              Andreas Weber
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-16.jpg'" style="width:100%; height:auto;">
                              Steve Leung
                          </li>
                      </ul>
                      <br><br>
                      <br>

                      <h2  class="ft21 mgb32">品牌基因</h2>

                      法式典雅
                      <br><br>

                      <div class="col2 col-min-margin" style="max-width:1582px; margin:0 auto;">
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-17.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-treca-18.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br>

                      <h2  class="ft21 mgb32">設計靈感</h2>

                      嫵媚曲線美
                      <br><br>

                      <div class="col2 col-min-margin" style="max-width:1582px; margin:0 auto;">
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-19.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-treca-20.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      利落高雅的線條
                      <br><br>

                      <div class="col2 col-min-margin" style="max-width:1582px; margin:0 auto;">
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-21.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-treca-22.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <div class="col2">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-treca-23.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-treca-24.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <div class="col2">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-treca-25.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-treca-26.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <div style="background:#000; margin-bottom:20px;">
                          <img :src="url+ 'misc/stories/story-treca-27.jpg'" style="width:100%; height:auto; max-width:895px;">
                      </div>

                      <div class="col2">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-treca-28.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-treca-29.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <div class="col2">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-treca-30.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-treca-31.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <div style="background:#000; margin-bottom:40px;">
                          <img :src="url+ 'misc/stories/story-treca-32.jpg'" style="width:100%; height:auto; max-width:895px;">
                      </div>
                  </div>
                  <div v-else>
                      <div class="main-name">床架及配套家具</div>
                      <div class="year">{{ $t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2017</div>

                      <div style="max-width:945px; margin:0 auto;">
                          法国国宝级床垫品牌— TRECA(崔佧)秉承高级时装定制的精湛工艺，以珍贵天然纤维和高科技面料，结合法国宫廷定制的高贵传统，成就全球睡眠艺术品的传奇地位。我司将为其最新两款产品设计床架及配套家具，并为第二十三届上海中国国际家具展览会上崔佧品牌的展厅提供空间设计及软装设计。
                      </div>
                      <br><br>
                      <br><br>
                      <h2  class="ft21 mgb32">设计传奇</h2>
                      <br>
                      <div class="col-auto col-min-margin" style="max-width:1240px; margin:0 auto;">
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-01.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-02.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-03.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <h2  class="ft21 mgb32">精湛工艺</h2>
                      <br>
                      <div class="col-auto col-min-margin" style="max-width:1240px; margin:0 auto;">
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-04.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-05.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-06.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <h2  class="ft21 mgb32">合作设计师</h2>
                      <br>
                      <ul class="designers">
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-07.jpg'" style="width:100%; height:auto;">
                              Pascal Allaman
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-08.jpg'" style="width:100%; height:auto;">
                              Mathilde Bretillot
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-09.jpg'" style="width:100%; height:auto;">
                              Sandrine Chollet
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-10.jpg'" style="width:100%; height:auto;">
                              Eric Gizard
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-11.jpg'" style="width:100%; height:auto;">
                              Matteo Thun
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-12.jpg'" style="width:100%; height:auto;">
                              Hoffmann Kahleyss Design
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-13.jpg'" style="width:100%; height:auto;">
                              Annette Lang
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-14.jpg'" style="width:100%; height:auto;">
                              Patrick Naggar
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-15.jpg'" style="width:100%; height:auto;">
                              Andreas Weber
                          </li>
                          <li>
                              <img :src="url+ 'misc/stories/story-treca-16.jpg'" style="width:100%; height:auto;">
                              Steve Leung
                          </li>
                      </ul>
                      <br><br>
                      <br>

                      <h2  class="ft21 mgb32">品牌基因</h2>

                      法式典雅
                      <br><br>

                      <div class="col2 col-min-margin" style="max-width:1582px; margin:0 auto;">
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-17.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-treca-18.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br>

                      <h2  class="ft21 mgb32">设计灵感</h2>

                      妩媚曲线美
                      <br><br>

                      <div class="col2 col-min-margin" style="max-width:1582px; margin:0 auto;">
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-19.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-treca-20.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      利落高雅的线条
                      <br><br>

                      <div class="col2 col-min-margin" style="max-width:1582px; margin:0 auto;">
                          <div style="margin-bottom:2px;">
                              <img :src="url+ 'misc/stories/story-treca-21.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-treca-22.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <div class="col2">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-treca-23.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-treca-24.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <div class="col2">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-treca-25.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-treca-26.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <div style="background:#000; margin-bottom:20px;">
                          <img :src="url+ 'misc/stories/story-treca-27.jpg'" style="width:100%; height:auto; max-width:895px;">
                      </div>

                      <div class="col2">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-treca-28.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-treca-29.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <div class="col2">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-treca-30.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-treca-31.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                      <div style="background:#000; margin-bottom:40px;">
                          <img :src="url+ 'misc/stories/story-treca-32.jpg'" style="width:100%; height:auto; max-width:895px;">
                      </div>
                  </div>

                  <div class="brand-btn">
                      <a :href="url+ 'misc/stories/pdf/崔佧-梁志天.pdf'" target="_blank" class="btn ">{{ $t('sdxBrand.download_product_cat') }}</a>&nbsp;
                      <div @click="showContactVendorForm" class="btn">{{ $t('sdxBrand.contact_vendor') }}</div>
                  </div>
            </div>

            <div class="victoria"  v-else-if="id==182">


                  <div class="brand-logo" style="margin:35px auto -10px;">
                      <img :src="url+logoPath" style="max-height:90px;" />
                  </div>


                  <div v-if="lang=='en'">
                      <div class="main-name">TAIZU Bath</div>
                      <div class="year">{{ $t('sdxBrand.san_products') }} &nbsp;&bull;&nbsp; 2019</div>

                      <div style="max-width:945px; margin:0 auto;">
                          Taizu is the first collaboration of SLD and Victoria + Albert, a leading British bathing brand, was exclusively previewed at ISH Frankfurt on March 11. The striking opulent 1500mm spa-style round bath is named after great Emperor Taizu, founder of Song Dynasty, a celebrated age of Chinese culture.
                      </div>
                      <br><br>
                      <br><br>

                      <img :src="url+ 'misc/stories/story-victoria-01.jpg'" style="width:100%; height:auto; max-width:920px;">
                      <br><br>
                      <br><br>
                      <br>

                      <div style="max-width:960px; margin:0 auto;">
                          The contemporary bath is inspired by Song ceramics, revered for their elegant proportions and sophisticated shapes. Taizu's round form comes with gently sloping sides, a chamfered rim and delicate exterior detailing. Made from the brand’s exclusive material, QUARRYCAST™; a unique blend of Volcanic Limestone™ and high-performance resins that is naturally white, beautifully strong and easy to clean, Taizu also features a void space underneath the bath for easy installation on solid floors, and can be personalized in six standard exterior paint finishes or gloss or matt finishes.
                      </div>
                      <br><br>
                      <br><br>
                      <br>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-victoria-02.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div style="max-width:600px; margin:0 auto;">
                                  Taizu in collaboration with Victoria + Albert, is a striking, contemporary round bath inspired by ceramics from Song dynasty for its sophisticated proportions and shape.
                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-victoria-03.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>
                                  Taizu's round form comes with gently sloping sides, a chamfered rim and delicate exterior detailing.
                              </div>
                          </div>
                      </div>
                  </div>
                  <div v-else-if="lang=='tc'">
                      <div class="main-name">TAIZU 浴缸</div>
                      <div class="year">{{ $t('sdxBrand.san_products') }} &nbsp;&bull;&nbsp; 2019</div>

                      <div style="max-width:945px; margin:0 auto;">
                          Taizu 是 SLD 和頂級英國衛浴品牌 Victoria + Albert 的首個合作產品，於 3 月 11 日在 ISH Frankfurt 法蘭克福浴室設備展覽會中亮相。這個引人注目的 1500 毫米水療式圓形浴缸，以宋代朝開國君主宋太祖命名，而宋朝正是中國文化綻放的時代。
                      </div>
                      <br><br>
                      <br><br>

                      <img :src="url+ 'misc/stories/story-victoria-01.jpg'" style="width:100%; height:auto; max-width:920px;">
                      <br><br>
                      <br><br>
                      <br>

                      <div style="max-width:960px; margin:0 auto;">
                          浴缸靈感來自宋代陶瓷，因其優雅的比例和精緻的形狀備受推崇。 Taizu 由品牌獨有的 QUARRYCAST™ 打造而成，這種融合專利南非火山岩材 (Volcanic Limestone™) 及高性能樹脂的物料，擁有天然亮白、耐用及抗污易潔的優良品質。設計帶有微斜的側面、倒角邊緣和精緻的外部細節，底部空間亦令其便於安裝。 Taizu 更提供六種標準外部漆面、光澤或啞光飾面，展現個人風格。
                      </div>
                      <br><br>
                      <br><br>
                      <br>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-victoria-02.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div style="max-width:600px; margin:0 auto;">
                                  與 Victoria + Albert 合作的 Taizu 是一個引人注目的現代圓形浴缸，靈感源自宋代陶瓷的精緻比例和形狀。
                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-victoria-03.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>
                                  Taizu 的圓狀帶有微斜的側面、倒角邊緣和精緻的外部細節。
                              </div>
                          </div>
                      </div>
                  </div>
                  <div v-else>
                      <div class="main-name">TAIZU 浴缸</div>
                      <div class="year">{{ $t('sdxBrand.san_products') }} &nbsp;&bull;&nbsp; 2019</div>

                      <div style="max-width:945px; margin:0 auto;">
                          Taizu 是 SLD 和顶级英国卫浴品牌 Victoria + Albert 的首个合作产品，于 3 月 11 日在 ISH Frankfurt 法兰克福浴室设备展览会中亮相。这个引人注目的 1500 毫米水疗式圆形浴缸，以宋代朝开国君主宋太祖命名，而宋朝正是中国文化绽放的时代。
                      </div>
                      <br><br>
                      <br><br>

                      <img :src="url+ 'misc/stories/story-victoria-01.jpg'" style="width:100%; height:auto; max-width:920px;">
                      <br><br>
                      <br><br>
                      <br>

                      <div style="max-width:960px; margin:0 auto;">
                          浴缸灵感来自宋代陶瓷，因其优雅的比例和精致的形状备受推崇。Taizu 由品牌独有的 QUARRYCAST™ 打造而成，这种融合专利南非火山岩材 (Volcanic Limestone™) 及高性能树脂的物料，拥有天然亮白、耐用及抗污易洁的优良品质。设计带有微斜的侧面、倒角边缘和精致的外部细节，底部空间亦令其便于安装。Taizu 更提供六种标准外部漆面、光泽或哑光饰面，展现个人风格。
                      </div>
                      <br><br>
                      <br><br>
                      <br>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-victoria-02.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div style="max-width:600px; margin:0 auto;">
                                  与 Victoria + Albert 合作的 Taizu 是一个引人注目的现代圆形浴缸，灵感源自宋代陶瓷的精致比例和形状。
                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-victoria-03.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>
                                  Taizu 的圆状带有微斜的侧面、倒角边缘和精致的外部细节。
                              </div>
                          </div>
                      </div>
                  </div>

                  <div class="brand-btn">
                      <a :href="url+ 'misc/stories/pdf/EU_Taizu_2019.pdf'" target="_blank" class="btn ">{{ $t('sdxBrand.download_product_cat') }}</a>&nbsp;
                      <div @click="showContactVendorForm" class="btn">{{ $t('sdxBrand.contact_vendor') }}</div>
                  </div>
            </div>

            <div class="visionnaire"  v-else-if="id==189">
                  <div v-if="collection=='2022'">
                      <div class="brand-logo" style="margin:35px auto -10px;">
                          <img :src="url+logoPath" style="max-height:85px;" />
                      </div>

                      <div v-if="lang=='en'">
                          <div class="main-name">NATURE'S JEWEL BOX 2022 FURNITURE SERIES</div>
                          <div class="year">{{ $t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2022</div>

                          <div style="max-width:710px; margin:0 auto;">
                          Thrilled to unveil Nature's Jewel Box 2022, our 5th collaboration with Visionnaire since the first collection was launched back in 2015 for the brand’s 10th Anniversary!
                          </div>
                          <br><br>
                          <br><br>

                          <div class="col2 col-valign">
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-01.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify;">
                                      By exploring a different aspect of the beauty of nature: the fascinating resilience of trees. The collection celebrates trees' incredible adaptability of thriving and growing stronger amid different weather conditions and even hostile environments, and inspires end-users to re-think the natural environ from a fresh perspective.
                                      <br><br>
                                      All the furniture objects are entirely made in ashwood - known for its ability to survive in particularly difficult climates, embracing a highly versatile and contemporary design language carved through organic lines and earthy colours.
                                  </div>
                              </div>
                          </div>

                          <br><br>
                          <br><br>
                          <br>

                          <h2 class="ft21">Cabinet</h2>
                          <br>

                          <img :src="url+ 'misc/stories/story-visionnaire-2022-02.jpg'" style="width:100%; height:auto; max-width:1600px;">
                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-03.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-04.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div class="col2 col-reverse" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-06.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-05.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br><br>
                          <br><br>
                          <br>

                          <h2 class="ft21">Chair</h2>

                          <img :src="url+ 'misc/stories/story-visionnaire-2022-07.jpg'" style="width:100%; height:auto; max-width:1420px;">
                          <img :src="url+ 'misc/stories/story-visionnaire-2022-08.jpg'" style="width:100%; height:auto; max-width:920px;">
                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-09.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-10.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div class="col2 col-reverse" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-12.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-11.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br><br>
                          <br><br>
                          <br>

                          <h2 class="ft21">Sofa</h2>

                          <img :src="url+ 'misc/stories/story-visionnaire-2022-13.jpg'" style="width:100%; height:auto; max-width:1520px;">
                          <img :src="url+ 'misc/stories/story-visionnaire-2022-14.jpg'" style="width:100%; height:auto; max-width:1100px;">
                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-15.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-16.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div class="col2 col-reverse" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-18.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-17.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br><br>
                          <br><br>
                          <br>

                          <h2 class="ft21">Dining</h2>

                          <img :src="url+ 'misc/stories/story-visionnaire-2022-19.jpg'" style="width:100%; height:auto; max-width:1600px;">
                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-20.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-21.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div class="col2 col-reverse" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-23.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-22.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br><br>
                          <br><br>
                          <br>

                          <h2 class="ft21">Low Table</h2>

                          <img :src="url+ 'misc/stories/story-visionnaire-2022-24.jpg'" style="width:100%; height:auto; max-width:1300px;">
                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-25.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-26.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br><br>

                          <img :src="url+ 'misc/stories/story-visionnaire-2022-27.jpg'" style="width:100%; height:auto; max-width:1300px;">
                      </div>
                      <div v-else-if="lang=='tc'">
                          <div class="main-name">大自然的珍寶盒 2022 家具系列</div>
                          <div class="year">{{ $t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2022</div>

                          <div style="max-width:770px; margin:0 auto;">
                              SLD 與 Visionnaire 自 2015 年爲慶祝 Visionnaire 成立 10 周年而開展首次合作，至今已是第五度攜手設計，並推出名為「大自然的珍寶盒 2022」的家具系列。
                          </div>
                          <br><br>
                          <br><br>

                          <div class="col2 col-valign">
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-01.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify;">
                                      以全新的角度探索大自然之美，並以樹木頑强的生命力爲設計靈感，展現他們在不同氣候，甚至是惡劣的環境中仍展現出令人難以置信的生長力，啟發人們以新的角度重新思考和感受大自然的力量。
                        <br><br>
                        此系列所有的家具均以白蠟木製成，白蠟木即使在惡劣的氣候下繁茂生長，並因此而聞名；有機的紋理與大地色系的基調，展現了包容高和富現代設計感的特色。
                                  </div>
                              </div>
                          </div>

                          <br><br>
                          <br><br>
                          <br>

                          <h2 class="ft21">柜子</h2>
                          <br>

                          <img :src="url+ 'misc/stories/story-visionnaire-2022-02.jpg'" style="width:100%; height:auto; max-width:1600px;">
                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-03.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-04.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div class="col2 col-reverse" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-06.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-05.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br><br>
                          <br><br>
                          <br>

                          <h2 class="ft21">椅子</h2>

                          <img :src="url+ 'misc/stories/story-visionnaire-2022-07.jpg'" style="width:100%; height:auto; max-width:1420px;">
                          <img :src="url+ 'misc/stories/story-visionnaire-2022-08.jpg'" style="width:100%; height:auto; max-width:920px;">
                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-09.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-10.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div class="col2 col-reverse" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-12.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-11.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br><br>
                          <br><br>
                          <br>

                          <h2 class="ft21">沙发</h2>

                          <img :src="url+ 'misc/stories/story-visionnaire-2022-13.jpg'" style="width:100%; height:auto; max-width:1520px;">
                          <img :src="url+ 'misc/stories/story-visionnaire-2022-14.jpg'" style="width:100%; height:auto; max-width:1100px;">
                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-15.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-16.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div class="col2 col-reverse" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-18.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-17.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br><br>
                          <br><br>
                          <br>

                          <h2 class="ft21">餐桌</h2>

                          <img :src="url+ 'misc/stories/story-visionnaire-2022-19.jpg'" style="width:100%; height:auto; max-width:1600px;">
                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-20.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-21.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div class="col2 col-reverse" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-23.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-22.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br><br>
                          <br><br>
                          <br>

                          <h2 class="ft21">矮桌</h2>

                          <img :src="url+ 'misc/stories/story-visionnaire-2022-24.jpg'" style="width:100%; height:auto; max-width:1300px;">
                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-25.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-26.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br><br>

                          <img :src="url+ 'misc/stories/story-visionnaire-2022-27.jpg'" style="width:100%; height:auto; max-width:1300px;">
                      </div>
                      <div v-else>
                          <div class="main-name">大自然的珍宝盒 2022 家具系列</div>
                          <div class="year">{{ $t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2022</div>

                          <div style="max-width:770px; margin:0 auto;">
                              SLD 与 Visionnaire 自 2015 年为庆祝 Visionnaire 成立 10 周年而开展首次合作，至今已是第五度携手设计，并推出名为「大自然的珍宝盒 2022」的家具系列。
                          </div>
                          <br><br>
                          <br><br>

                          <div class="col2 col-valign">
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-01.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify;">
                                      以全新的角度探索大自然之美，并以树木顽强的生命力为设计灵感，展现他们在不同气候，甚至是恶劣的环境中仍展现出令人难以置信的生长力，启发人们以新的角度重新思考和感受大自然的力量。
                                      <br><br>
                                      此系列所有的家具均以白蜡木制成，白蜡木即使在恶劣的气候下繁茂生长，并因此而闻名；有机的纹理与大地色系的基调，展现了包容高和富现代设计感的特色。
                                  </div>
                              </div>
                          </div>

                          <br><br>
                          <br><br>
                          <br>

                          <h2 class="ft21">柜子</h2>
                          <br>

                          <img :src="url+ 'misc/stories/story-visionnaire-2022-02.jpg'" style="width:100%; height:auto; max-width:1600px;">
                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-03.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-04.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div class="col2 col-reverse" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-06.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-05.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br><br>
                          <br><br>
                          <br>

                          <h2 class="ft21">椅子</h2>

                          <img :src="url+ 'misc/stories/story-visionnaire-2022-07.jpg'" style="width:100%; height:auto; max-width:1420px;">
                          <img :src="url+ 'misc/stories/story-visionnaire-2022-08.jpg'" style="width:100%; height:auto; max-width:920px;">
                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-09.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-10.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div class="col2 col-reverse" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-12.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-11.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br><br>
                          <br><br>
                          <br>

                          <h2 class="ft21">沙发</h2>

                          <img :src="url+ 'misc/stories/story-visionnaire-2022-13.jpg'" style="width:100%; height:auto; max-width:1520px;">
                          <img :src="url+ 'misc/stories/story-visionnaire-2022-14.jpg'" style="width:100%; height:auto; max-width:1100px;">
                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-15.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-16.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div class="col2 col-reverse" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-18.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-17.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br><br>
                          <br><br>
                          <br>

                          <h2 class="ft21">餐桌</h2>

                          <img :src="url+ 'misc/stories/story-visionnaire-2022-19.jpg'" style="width:100%; height:auto; max-width:1600px;">
                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-20.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-21.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br>

                          <div class="col2 col-reverse" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-23.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-22.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br><br>
                          <br><br>
                          <br>

                          <h2 class="ft21">矮桌</h2>

                          <img :src="url+ 'misc/stories/story-visionnaire-2022-24.jpg'" style="width:100%; height:auto; max-width:1300px;">
                          <br><br>
                          <br><br>
                          <br>

                          <div class="col2" style="margin:0 auto;">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-25.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-2022-26.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br><br>

                          <img :src="url+ 'misc/stories/story-visionnaire-2022-27.jpg'" style="width:100%; height:auto; max-width:1300px;">
                      </div>
                      <div class="brand-btn">
                          <!--<a href="/assets/images/storypdf/2016 - Visionnaire - Steve Leung Natures JewelBox.pdf" target="_blank" class="btn ">{{ $t('sdxBrand.download_product_cat') }}</a>&nbsp;-->
                          <div @click="showContactVendorForm" class="btn">{{ $t('sdxBrand.contact_vendor') }}</div>
                      </div>
                  </div>

                  <div v-else>
                      <div class="brand-logo" style="margin:35px auto -10px;">
                          <img :src="url+logoPath" style="max-height:85px;" />
                      </div>


                      <div v-if="lang=='en'">
                          <div class="main-name">2019 FURNITURE SERIES</div>
                          <div class="year">{{ $t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2019</div>

                          <div style="max-width:930px; margin:0 auto;">
                              People have a close relationship with nature. Nature provides us with a lot of precious resources, and plants are an indispensable element.
                              <br><br>
                              In this new series, through the use of organic elements of plants, this unique linear form is presented in our gorgeous design. And through this series of products, nature is integrated into our lives, so that we can cherish and understand nature more and build a more harmonious and balanced relationship with it.
                          </div>
                          <br><br>
                          <br>

                          <div style="max-width:870px; margin:0 auto;">
                              <b>Presence</b>
                              <br><br>
                              The organically shaped sofa was inspired by the form of the petals, feels like an anther to be protected by the beautiful curved high and low backrest with pleated detail. The different bases to reflect noting is isometric perfectly in nature.
                          </div>
                          <br><br>

                          <div class="col2">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-01.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-02.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br><br>
                          <br>

                          <div style="max-width:870px; margin:0 auto;">
                              <b>Jet Plane</b>
                              <br><br>
                              The egg-shaped writing desk was inspired by a Leaf with the stem. Sculptural leather pedestal with a large contrast on both sides.
                          </div>
                          <br><br>

                          <div class="col2">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-03.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-04.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br><br>
                          <br>

                          <div style="max-width:890px; margin:0 auto;">
                              <b>Planet</b>
                              <br><br>
                              The elegance curves body with pleated leather and seat pad like the functions of sepal, to provide support and protection for the user. The welcome-arms in shaped to provide a very comfortable and relaxing for the arms.
                          </div>
                          <br><br>

                          <div class="col2">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-05.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-06.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <!--<br><br>
                              <br>

                              <div style="max-width:870px; margin:0 auto;">
                                  繁忙的城市生活中，都市人接触大自然的机会有限，此次将植物优雅的元素，如线条、形态、质感融入家具设计中，为生活空间营造一丝清新脱俗的气息。置身于自然之中，与天地融为一体，为思考打开另一方天地，让你的创造力和想象力无限迸发。
                              </div>-->
                      </div>
                      <div v-else-if="lang=='tc'">
                          <div class="main-name">2019 家具系列</div>
                          <div class="year">{{ $t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2019</div>

                          <div style="max-width:838px; margin:0 auto;">
                              在 Visionnaire 15 週年之際，SLD 再次攜手 Visionnaire 合作為其 Anniversary 週年系列推出了三款不同姿態的家具：Presence、Jet Plane、Planet。設計靈感來自植物的線條和花瓣的優雅形態，以現代手法演繹自然有機的質感。
                          </div>
                          <br><br>
                          <br>

                          <div style="max-width:870px; margin:0 auto;">
                              <b>Presence</b>
                              <br><br>
                              形態優雅和不對稱的沙發，左右兩邊靠背呈不同形狀和高低不一，賦予橢圓沙發獨特的新意，線條圓滑流暢，配以觸感細緻的絲絨材質，重塑花瓣自然優美的形態。
                          </div>
                          <br><br>

                          <div class="col2">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-01.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-02.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br><br>
                          <br>

                          <div style="max-width:870px; margin:0 auto;">
                              <b>Jet Plane</b>
                              <br><br>
                              鵝蛋形辦工桌是有機和未來設計的結合，猶如一片帶莖的葉子，又像一艘太空船，弧形桌腳連抽屜的流線型設計充滿動感，配以蜥蜴皮革的細節，低調奢華和實用性兼備，另一邊的桌腳以弧形金屬打造，為此家具系列帶來眼前一亮的效果。
                          </div>
                          <br><br>

                          <div class="col2">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-03.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-04.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br><br>
                          <br>

                          <div style="max-width:890px; margin:0 auto;">
                              <b>Planet</b>
                              <br><br>
                              如花萼包裹著人體的座椅，令人一見傾心。椅子的把手向外張開，美感與舒適並重，雅緻的褶狀皮革細節，讓座椅更添氣質。
                          </div>
                          <br><br>

                          <div class="col2">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-05.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-06.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br><br>
                          <br>

                          <div style="max-width:870px; margin:0 auto;">
                              繁忙的城市生活中，都市人接觸大自然的機會有限，此次將植物優雅的元素，如線條、形態、質感融入家具設計中，為生活空間營造一絲清新脫俗的氣息。置身於自然之中，與天地融為一體，為思考打開另一方天地，讓你的創造力和想像力無限迸發。
                          </div>
                      </div>
                      <div v-else>
                          <div class="main-name">2019 家具系列</div>
                          <div class="year">{{ $t('sdxBrand.furniture') }} &nbsp;&bull;&nbsp; 2019</div>

                          <div style="max-width:838px; margin:0 auto;">
                              在 Visionnaire 15 周年之际，SLD 再次携手 Visionnaire 合作为其 Anniversary 周年系列推出了三款不同姿态的家具：Presence、Jet Plane、Planet。设计灵感来自植物的线条和花瓣的优雅形态，以现代手法演绎自然有机的质感。
                          </div>
                          <br><br>
                          <br>

                          <div style="max-width:870px; margin:0 auto;">
                              <b>Presence</b>
                              <br><br>
                              形态优雅和不对称的沙发，左右两边靠背呈不同形状和高低不一，赋予椭圆沙发独特的新意，线条圆滑流畅，配以触感细致的丝绒材质，重塑花瓣自然优美的形态。
                          </div>
                          <br><br>

                          <div class="col2">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-01.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-02.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br><br>
                          <br>

                          <div style="max-width:870px; margin:0 auto;">
                              <b>Jet Plane</b>
                              <br><br>
                              鹅蛋形办工桌是有机和未来设计的结合，犹如一片带茎的叶子，又像一艘太空船，弧形桌脚连抽屉的流线型设计充满动感，配以蜥蜴皮革的细节，低调奢华和实用性兼备，另一边的桌脚以弧形金属打造，为此家具系列带来眼前一亮的效果。
                          </div>
                          <br><br>

                          <div class="col2">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-03.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-04.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br><br>
                          <br>

                          <div style="max-width:890px; margin:0 auto;">
                              <b>Planet</b>
                              <br><br>
                              如花萼包裹着人体的座椅，令人一见倾心。椅子的把手向外张开，美感与舒适并重，雅致的褶状皮革细节，让座椅更添气质。
                          </div>
                          <br><br>

                          <div class="col2">
                              <div style="margin-bottom:20px;">
                                  <img :src="url+ 'misc/stories/story-visionnaire-05.jpg'" style="width:100%; height:auto;">
                              </div>
                              <div>
                                  <img :src="url+ 'misc/stories/story-visionnaire-06.jpg'" style="width:100%; height:auto;">
                              </div>
                          </div>
                          <br><br>
                          <br>

                          <div style="max-width:870px; margin:0 auto;">
                              繁忙的城市生活中，都市人接触大自然的机会有限，此次将植物优雅的元素，如线条、形态、质感融入家具设计中，为生活空间营造一丝清新脱俗的气息。置身于自然之中，与天地融为一体，为思考打开另一方天地，让你的创造力和想象力无限迸发。
                          </div>
                      </div>
                      <div class="brand-btn">
                          <!--<a href="/assets/images/storypdf/2016 - Visionnaire - Steve Leung Natures JewelBox.pdf" target="_blank" class="btn ">{{ $t('sdxBrand.download_product_cat') }}</a>&nbsp;-->
                          <div @click="showContactVendorForm" class="btn">{{ $t('sdxBrand.contact_vendor') }}</div>
                      </div>
                  </div>

            </div>
            <div class="xinzhongyuan" v-else-if="id==170">


                  <div class="brand-logo" style="margin:35px auto -15px; padding-right:15px;">
                      <img :src="url+logoPath" style="max-height:90px;" />
                  </div>


                  <div v-if="lang=='en'">
                      <div class="main-name">XUAN SERIES</div>
                      <div class="year">Ceramic Tiles &nbsp;&bull;&nbsp; 2018 ({{$t('sdxBrand.discontinued') }})</div>

                      <div style="max-width:800px; margin:0 auto;">
                          Steve Leung’s collaboration with New Zhong Yuan Ceramics, began with the search for a poetic theme that could inspire the collection. A theme connecting the elements of Chinese Culture that Steve Leung deeply respects as a Designer.
                      </div>
                      <br><br>
                      <br>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-01.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>
                                  <b>Calligraphy</b><br>
                                  An artistic form of expression. Deep Black Ink Tones gently fade into the Textured White Xuan paper.
                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-02.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>
                                  <b>Art - Shan Shui Painting</b><br>
                                  Similar to Calligraphy, the paintings are created by Ink and brush on textured Xuan Paper. Mood, feeling and emotion are important with tones expressed by gently fading Black ink strokes.
                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-03.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>
                                  <b>Xuan Paper</b><br>
                                  Calligraphy and Shan Shui paintings are both produced on Xuan paper. The paper texture is fibrous and absorbent creating the fading tones of the ink.
                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-04.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>
                                  <b>Blue Brick</b><br>
                                  Hutong alleyways intersect the Siheyuan residences. Made with iconic ‘Blue Bricks’ they form the foundation of society. Their Tone and linear texture inspired the Floor tile in our collection.
                              </div>
                          </div>
                      </div>
                      <br><br>
                      <br>

                      <h2  class="ft21 mgb32">Wall Tile</h2>
                      <div class="col2 no-mobile" style="max-width:1240px; margin:0 auto;">
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-05.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>

                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-06.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>

                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-07.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>

                              </div>
                          </div>
                      </div>
                      <br><br>
                      <br>

                      <h2  class="ft21 mgb32">Floor Tile</h2>
                      <div class="col2" style="max-width:1240px; margin:0 auto;">
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-08.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>

                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-09.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>

                              </div>
                          </div>
                      </div>
                      <br><br>
                      <br>

                      <img :src="url+ 'misc/stories/story-xinzhongyuan-10.jpg'" style="width:100%; height:auto;">
                      <br><br>

                      <img :src="url+ 'misc/stories/story-xinzhongyuan-11.jpg'" style="width:100%; height:auto;">
                      <br><br>

                      <img :src="url+ 'misc/stories/story-xinzhongyuan-12.jpg'" style="width:100%; height:auto;">
                      <br><br>

                      <div class="col2">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-13.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-14.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                          </div>
                      </div>
                  </div>
                  <div v-else-if="lang=='tc'">
                      <div class="main-name">絢系列</div>
                      <div class="year">瓷磚 &nbsp;&bull;&nbsp; 2018 ({{$t('sdxBrand.discontinued') }})</div>

                      <div>
                          <div class="quote">松門亙五里，碧彩高下絢。</div>
                          <div class="caption">皮日休《太湖詩﹒雨中游包山精舍》</div>
                      </div>
                      <br><br>
                      <br>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-01.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>
                                  <b>書法</b><br>
                                  一種藝術的表達形式，融合筆墨與
                                  宣紙的中國傳統藝術。
                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-02.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>
                                  <b>藝術 - 山水畫</b><br>
                                  跟書法相同，細緻地運用筆墨把優美的風景描繪在宣紙上。氣氛和情感是山水畫的重要特質，透過輕柔褪色的筆跡呈現不同的格調。
                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-03.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>
                                  <b>宣紙</b><br>
                                  一種中國毛筆書畫的高級藝術用紙，它擁有良好的潤墨性及耐久性，是中國紙的代表。
                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-04.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>
                                  <b>青磚</b><br>
                                  古代的胡同小巷和四合院均由標誌性的青磚和瓦片所組成，建構了當時社會的基礎。這些格調和線性紋理成為我們系列中地板磚的創作靈感。
                              </div>
                          </div>
                      </div>
                      <br><br>
                      <br>

                      <h2  class="ft21 mgb32">牆磚</h2>
                      <div class="col2 no-mobile" style="max-width:1240px; margin:0 auto;">
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-05.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>
                                  雨霧
                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-06.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>
                                  晴空
                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-07.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>
                                  落霞
                              </div>
                          </div>
                      </div>
                      <br><br>
                      <br>

                      <h2  class="ft21 mgb32">地磚</h2>
                      <div class="col2" style="max-width:1240px; margin:0 auto;">
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-08.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>
                                  黛山
                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-09.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>
                                  雨霧
                              </div>
                          </div>
                      </div>
                      <br><br>
                      <br>

                      <img :src="url+ 'misc/stories/story-xinzhongyuan-10.jpg'" style="width:100%; height:auto;">
                      <br><br>

                      <img :src="url+ 'misc/stories/story-xinzhongyuan-11.jpg'" style="width:100%; height:auto;">
                      <br><br>

                      <img :src="url+ 'misc/stories/story-xinzhongyuan-12.jpg'" style="width:100%; height:auto;">
                      <br><br>

                      <div class="col2">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-13.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-14.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                          </div>
                      </div>
                  </div>
                  <div v-else>
                      <div class="main-name">绚系列</div>
                      <div class="year">瓷砖 &nbsp;&bull;&nbsp; 2018 ({{$t('sdxBrand.discontinued') }})</div>

                      <div>
                          <div class="quote">松门亘五里，碧彩高下绚。</div>
                          <div class="caption">皮日休《太湖诗﹒雨中游包山精舍》</div>
                      </div>
                      <br><br>
                      <br>

                      <div class="col2">
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-01.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>
                                  <b>书法</b><br>
                                  一种艺术的表达形式，融合笔墨与
                                  宣纸的中国传统艺术。
                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-02.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>
                                  <b>艺术 - 山水画</b><br>
                                  跟书法相同，细致地运用笔墨把优美的风景描绘在宣纸上。气氛和情感是山水画的重要特质，透过轻柔褪色的笔迹呈现不同的格调。
                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-03.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>
                                  <b>宣纸</b><br>
                                  一种中国毛笔书画的高级艺术用纸，它拥有良好的润墨性及耐久性，是中国纸的代表。
                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-04.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>
                                  <b>青砖</b><br>
                                  古代的胡同小巷和四合院均由标志性的青砖和瓦片所组成，建构了当时社会的基础。这些格调和线性纹理成为我们系列中地板砖的创作灵感。
                              </div>
                          </div>
                      </div>
                      <br><br>
                      <br>

                      <h2  class="ft21 mgb32">墙砖</h2>
                      <div class="col2 no-mobile" style="max-width:1240px; margin:0 auto;">
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-05.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>
                                  雨雾
                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-06.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>
                                  晴空
                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-07.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>
                                  落霞
                              </div>
                          </div>
                      </div>
                      <br><br>
                      <br>

                      <h2  class="ft21 mgb32">地砖</h2>
                      <div class="col2" style="max-width:1240px; margin:0 auto;">
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-08.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>
                                  黛山
                              </div>
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-09.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                              <div>
                                  雨雾
                              </div>
                          </div>
                      </div>
                      <br><br>
                      <br>

                      <img :src="url+ 'misc/stories/story-xinzhongyuan-10.jpg'" style="width:100%; height:auto;">
                      <br><br>

                      <img :src="url+ 'misc/stories/story-xinzhongyuan-11.jpg'" style="width:100%; height:auto;">
                      <br><br>

                      <img :src="url+ 'misc/stories/story-xinzhongyuan-12.jpg'" style="width:100%; height:auto;">
                      <br><br>

                      <div class="col2">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-13.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-xinzhongyuan-14.jpg'" style="width:100%; height:auto; margin-bottom:12px;">
                          </div>
                      </div>
                  </div>
                  <div class="brand-btn">
                      <a :href="url+ 'misc/stories/pdf/新中源《绚》图册-终.pdf'" target="_blank" class="btn ">{{ $t('sdxBrand.download_product_cat') }}</a>&nbsp;
                      <div @click="showContactVendorForm" class="btn">{{ $t('sdxBrand.contact_vendor') }}</div>
                  </div>

            </div>
            <div class="lema" v-else-if="id==501">


                  <div class="brand-logo" style="margin:35px auto -10px;">
                      <img :src="url+logoPath" style="max-height:85px;" />
                  </div>


                  <div v-if="lang=='en'">
                      <div class="main-name">EUREKA Wardrobe Vanity</div>
                      <div class="year">Fixed Furniture &nbsp;&bull;&nbsp; 2022</div>

                      <div style="max-width:805px; margin:0 auto;">
                          <div class="quote" style="display:inline; line-height:36px;">“Wardrobe is not only a storage: instead, it is a transforming object that evolves and adapts to become an integral part of our life.”</div>
                          <div class="" style="display:inline-block; padding-left:10px;">Steve Leung </div>
                      </div>
                      <br><br>
                      <br>

                      <div style="max-width:860px; margin:0 auto;">Thrilled to unveil Eureka, our debut collaboration with Italian acclaimed furniture brand Lema that rethinks the traditional concept of wardrobe to reveal an unconventional design with surprising features.</div>
                      <br><br>
                      <br><br>

                      <img :src="url+ 'misc/stories/story-lema-01.jpg'" style="width:100%; height:auto;">
                      <br><br>
                      <br><br>
                      <br>

                      <div style="max-width:980px; margin:0 auto;">“Eureka” literally means “I have found (it)!” in ancient Greek, this innovative wardrobe reveals countless possibilities and breaks the status quo, reimagining a traditional furniture object that pushes the boundaries and achieves a “Design Without Limits”.
                      <br><br>
                      Embracing clean lines, superb leather artisanship and discreet details, Eureka's compositional flexibility completes contemporary abodes featuring a range of distinctive modules furnished with reconstructed interior compartments beyond storage. Customizable in every detail, the vast range of modules communicate a sophisticated lifestyle through discreet glamour, endowing each space with simple aesthetics and spatial fluidity, and blending in perfectly within different interior styles.</div>
                      <br><br>
                      <br><br>
                      <br>


                      <div class="col2" style="max-width:1100px; margin:0 auto;">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-lema-02.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-lema-03.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <div class="col2">
                          <div style="flex:auto; margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-lema-04.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="flex:auto;">
                              <img :src="url+ 'misc/stories/story-lema-05.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>
                      <br>


                      <div class="col2" style="max-width:1100px; margin:0 auto;">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-lema-06.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-lema-07.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <div class="col2">
                          <div style="flex:auto; margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-lema-08.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="flex:auto;">
                              <img :src="url+ 'misc/stories/story-lema-09.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>
                      <br>


                      <div class="col2" style="max-width:1100px; margin:0 auto;">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-lema-10.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-lema-11.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <div class="col2">
                          <div style="flex:auto; margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-lema-12.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="flex:auto;">
                              <img :src="url+ 'misc/stories/story-lema-13.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>
                      <br>


                      <div class="col2" style="max-width:1100px; margin:0 auto;">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-lema-14.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-lema-15.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <div class="col2">
                          <div style="flex:auto; margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-lema-16.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="flex:auto;">
                              <img :src="url+ 'misc/stories/story-lema-17.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>

                  </div>
                  <div v-else-if="lang=='tc'">
                      <div class="main-name">EUREKA 衣櫃系列</div>
                      <div class="year">固定家具 &nbsp;&bull;&nbsp; 2022</div>

                      <div style="max-width:590px; margin:0 auto;">
                          <div class="quote" style="display:inline; line-height:36px;">“衣櫃不僅是儲物的空間，它亦可變成具有其他功能的物品，成為我們生活中不可或缺的一部份。”</div>
                          <div class="" style="display:inline-block; padding-left:10px;">梁志天</div>
                      </div>
                      <br><br>
                      <br>

                      <div style="max-width:800px; margin:0 auto;">SLD 首度與意大利著名家具品牌 Lema 合作，揭開 Eureka 的神秘面紗。Eureka 的出現顛覆了傳統衣櫃的概念，帶來集多功能於「一櫃」的全新設計。</div>
                      <br><br>
                      <br><br>

                      <img :src="url+ 'misc/stories/story-lema-01.jpg'" style="width:100%; height:auto;">
                      <br><br>
                      <br><br>
                      <br>

                      <div style="max-width:805px; margin:0 auto;">「Eureka」一詞在古希臘語中有「我找到了！」的意思。這個創新的衣櫃設計揭示了無限的可能性，打破衣櫃只供儲物的傳統思維，同時展現「設計無界限」的格言。
                <br><br>
                採用簡潔的線條、精湛的皮革工藝和謹慎的細節處理方式，Eureka 配備一系列獨特的組件及可自組的內部隔間，靈活的設計適合每個與眾不同的居所。此外，Eureka 每個細節都能定制，並具備多樣的組件可供選擇，賦予空間簡約的美感，並增強空間流動性。</div>
                      <br><br>
                      <br><br>
                      <br>


                      <div class="col2" style="max-width:1100px; margin:0 auto;">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-lema-02.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-lema-03.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <div class="col2">
                          <div style="flex:auto; margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-lema-04.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="flex:auto;">
                              <img :src="url+ 'misc/stories/story-lema-05.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>
                      <br>


                      <div class="col2" style="max-width:1100px; margin:0 auto;">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-lema-06.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-lema-07.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <div class="col2">
                          <div style="flex:auto; margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-lema-08.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="flex:auto;">
                              <img :src="url+ 'misc/stories/story-lema-09.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>
                      <br>


                      <div class="col2" style="max-width:1100px; margin:0 auto;">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-lema-10.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-lema-11.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <div class="col2">
                          <div style="flex:auto; margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-lema-12.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="flex:auto;">
                              <img :src="url+ 'misc/stories/story-lema-13.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>
                      <br>


                      <div class="col2" style="max-width:1100px; margin:0 auto;">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-lema-14.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-lema-15.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <div class="col2">
                          <div style="flex:auto; margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-lema-16.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="flex:auto;">
                              <img :src="url+ 'misc/stories/story-lema-17.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                  </div>
                  <div v-else>
                      <div class="main-name">EUREKA 衣柜系列</div>
                      <div class="year">固定家具 &nbsp;&bull;&nbsp; 2022</div>

                      <div style="max-width:590px; margin:0 auto;">
                          <div class="quote" style="display:inline; line-height:36px;">“衣柜不仅是储物的空间，它亦可变成具有其他功能的物品，成为我们生活中不可或缺的一部份。”</div>
                          <div class="" style="display:inline-block; padding-left:10px;">梁志天</div>
                      </div>
                      <br><br>
                      <br>

                      <div style="max-width:800px; margin:0 auto;">SLD 首度与意大利著名家具品牌 Lema 合作，揭开 Eureka 的神秘面纱。Eureka 的出现颠覆了传统衣柜的概念，带来集多功能于「一柜」的全新设计。</div>
                      <br><br>
                      <br><br>

                      <img :src="url+ 'misc/stories/story-lema-01.jpg'" style="width:100%; height:auto;">
                      <br><br>
                      <br><br>
                      <br>

                      <div style="max-width:805px; margin:0 auto;">「Eureka」一词在古希腊语中有「我找到了！」的意思。这个创新的衣柜设计揭示了无限的可能性，打破衣柜只供储物的传统思维，同时展现「设计无界限」的格言。
                <br><br>
                采用简洁的线条、精湛的皮革工艺和谨慎的细节处理方式，Eureka 配备一系列独特的组件及可自组的内部隔间，灵活的设计适合每个与众不同的居所。此外，Eureka 每个细节都能定制，并具备多样的组件可供选择，赋予空间简约的美感，并增强空间流动性。</div>
                      <br><br>
                      <br><br>
                      <br>


                      <div class="col2" style="max-width:1100px; margin:0 auto;">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-lema-02.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-lema-03.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <div class="col2">
                          <div style="flex:auto; margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-lema-04.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="flex:auto;">
                              <img :src="url+ 'misc/stories/story-lema-05.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>
                      <br>


                      <div class="col2" style="max-width:1100px; margin:0 auto;">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-lema-06.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-lema-07.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <div class="col2">
                          <div style="flex:auto; margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-lema-08.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="flex:auto;">
                              <img :src="url+ 'misc/stories/story-lema-09.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>
                      <br>


                      <div class="col2" style="max-width:1100px; margin:0 auto;">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-lema-10.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-lema-11.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <div class="col2">
                          <div style="flex:auto; margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-lema-12.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="flex:auto;">
                              <img :src="url+ 'misc/stories/story-lema-13.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>
                      <br>


                      <div class="col2" style="max-width:1100px; margin:0 auto;">
                          <div style="margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-lema-14.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div>
                              <img :src="url+ 'misc/stories/story-lema-15.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <div class="col2">
                          <div style="flex:auto; margin-bottom:20px;">
                              <img :src="url+ 'misc/stories/story-lema-16.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="flex:auto;">
                              <img :src="url+ 'misc/stories/story-lema-17.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                  </div>
                  <div class="brand-btn">
                      <!--<a href="../static/storypdf/新中源《绚》图册-终.pdf" target="_blank" class="btn ">{{ $t('sdxBrand.download_product_cat') }}</a>&nbsp;
                      <div @click="showContactVendorForm" class="btn">{{ $t('sdxBrand.contact_vendor') }}</div>-->
                  </div>

            </div>
            <div class="lualdi"  v-else-if="id==926">


                  <div class="brand-logo" style="margin:35px auto -5px;">
                      <img :src="url+logoPath" style="max-height:76px;" />
                  </div>


                  <div v-if="lang=='en'">
                      <div class="main-name">Ying Door</div>
                      <div class="year">Doors &nbsp;&bull;&nbsp; 2021</div>

                      <div class="col2 col-valign col-reverse" style="max-width:1240px; margin:0 auto;">
                          <div style="margin:0 40px 40px;">
                              <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify;">

                                  <b>Door</b>, an entrance, one of the basic elements of the building. In the system of traditional Chinese architecture, the door is the most interesting component, its shape, exquisite workmanship, and the color of the front door is very meaningful.
                              </div>
                          </div>
                          <div style="margin:0 40px 40px;">
                              <img :src="url+ 'misc/stories/story-lualdi-01.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>
                      <br><br>

                      <div style="max-width:630px; margin:0 auto;">
                          The front door color as an important symbol of social hierarchical sequence in Chinese culture.
                      </div>
                      <br><br>
                      <br><br>
                      <br><br>

                      <img :src="url+ 'misc/stories/story-lualdi-02.jpg'" style="width:100%; height:auto; max-width:1240px; margin-bottom:20px;">
                      <br><br>
                      <br>

                      <img :src="url+ 'misc/stories/story-lualdi-03.jpg'" style="width:100%; height:auto; max-width:802px; margin-bottom:20px;">
                      <br><br>
                      <br><br>
                      <br><br>
                      <br><br>
                      <br>

                      <div class="col2 col-valign" style="align-items:stretch;">
                          <div style="display:flex; margin-bottom:15px;">
                              <div :style="{ 'flex-grow':1, background:'url(' + url + 'misc/stories/story-lualdi-04.jpg) top left no-repeat', margin:'0 auto'}">
                              </div>
                              <div :style="{ 'flex-grow':2, display:'flex', margin:'0 auto', 'text-align':'justify', background:'url(' + url + 'misc/stories/story-lualdi-05.png) #f8f8f8 center right no-repeat', 'background-size':'cover' }">
                                  <div style="align-self:center; max-width:310px; margin:20% 10% !important;">
                                      <!--紅色朱門<br>-->
                                      Red door, an imperial color representing power, royalty and the emblem of emperor.
                                  </div>
                              </div>
                          </div>
                          <div style="margin-bottom:15px;">
                              <img :src="url+ 'misc/stories/story-lualdi-06.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <div class="col2 col-valign col-reverse" style="align-items:stretch;">
                          <div style="display:flex; margin-bottom:15px;">
                              <div :style="{ 'flex-grow':2, display:'flex', margin:'0 auto', 'text-align':'justify', background:'url(' + url + 'misc/stories/story-lualdi-08.png) #f8f8f8 center right no-repeat', 'background-size':'cover' }">
                                  <div style="align-self:center; max-width:310px; margin:20% 10% !important;">
                                      <!--黄色之門<br>-->
                                      Yellow door, a noble color representing warmth and happiness.
                                  </div>
                              </div>
                              <div :style="{ 'flex-grow':1, background:'url(' + url + 'misc/stories/story-lualdi-09.jpg) top left no-repeat','background-size':'cover', margin:'0 0 0 5px'}">
                              </div>
                          </div>
                          <div style="margin-bottom:15px;">
                              <img :src="url+ 'misc/stories/story-lualdi-07.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <div class="col2 col-valign" style="align-items:stretch;">
                          <div style="display:flex; margin-bottom:15px;">
                              <div :style="{ 'flex-grow':1, background:'url(' + url + 'misc/stories/story-lualdi-10.jpg) top left no-repeat','background-size':'cover', margin:'0 5px 0 0'}">
                              </div>
                              <div :style="{ 'flex-grow':2, display:'flex', margin:'0 auto', 'text-align':'justify', background:'url(' + url + 'misc/stories/story-lualdi-11.png) #f8f8f8 center right no-repeat', 'background-size':'cover' }">
                                  <div style="align-self:center; max-width:340px; margin:20% 10% !important;">
                                      <!--黑色大門<br>-->
                                      Black door, very general in Chinese culture. Black forresponds to water and is considered to be a neutral color.
                                  </div>
                              </div>
                          </div>
                          <div style="margin-bottom:15px;">
                              <img :src="url+ 'misc/stories/story-lualdi-12.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <div class="col2 col-valign col-reverse" style="align-items:stretch;">
                          <div style="display:flex; margin-bottom:15px;">
                              <div :style="{ 'flex-grow':2, display:'flex', margin:'0 auto', 'text-align':'justify', background:'url(' + url + 'misc/stories/story-lualdi-14.png) #f8f8f8 center right no-repeat', 'background-size':'cover' }">
                                  <div style="align-self:center; max-width:355px; margin:20% 10% !important;">
                                      <!--白板扉（門）<br>-->
                                      In contrast to the gorgeousness painted door, using a natural wood door evokes a pure and harmonious atmosphere.
                                  </div>
                              </div>
                              <div :style="{ 'flex-grow':1, background:'url(' + url + 'misc/stories/story-lualdi-15.jpg) top left no-repeat','background-size':'cover', margin:'0 0 0 5px'}">
                              </div>
                          </div>
                          <div style="margin-bottom:15px;">
                              <img :src="url+ 'misc/stories/story-lualdi-13.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>
                      <br>

                      <div style="max-width:700px; margin:0 auto;">
                          榫卯（sǔn mǎo） is the main structure of ancient Chinese architecture, furniture and other equipment. This Design reflects Chinese culture & wisdom, the link between tradition and modernity.
                      </div>
                      <br><br>
                      <br><br>
                      <br><br>

                      <div class="col2">
                          <div style="margin-bottom:15px;">
                              <img :src="url+ 'misc/stories/story-lualdi-16.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:15px;">
                              <img :src="url+ 'misc/stories/story-lualdi-17.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br>

                      <img :src="url+ 'misc/stories/story-lualdi-18.jpg'" style="width:100%; height:auto; margin-bottom:15px;">
                      <br><br>
                      <br><br>

                      <img :src="url+ 'misc/stories/story-lualdi-19.jpg'" style="width:100%; height:auto; margin-bottom:15px;">
                  </div>
                  <div v-else-if="lang=='tc'">
                      <div class="main-name">Ying 門</div>
                      <div class="year">門 &nbsp;&bull;&nbsp; 2021</div>

                      <div class="col2 col-valign col-reverse" style="max-width:1240px; margin:0 auto;">
                          <div style="margin:0 40px 40px;">
                              <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify;">
                                  <div class="quote" style="margin-left:0;"><span style="color:#950c0f;">門</span> · 承</div>
                                  <b>門</b> &nbsp; 是中國最早出現的文字，甲骨文中的“門”字，有門框、門楣，還有一對門扇。封建時代，對於大門的漆色也有明確的規定。入必由之，出必由之，歷史的風風雨雨，門總要首當其衝。
                              </div>
                          </div>
                          <div style="margin:0 40px 40px;">
                              <img :src="url+ 'misc/stories/story-lualdi-01.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>
                      <br><br>

                      <div style="max-width:630px; margin:0 auto;">
                          門，有門臉、門面之意。在古老的中國每朝每代，不同身份地位的人，門色亦不盡相同。現在，不同的色代表個性化、私人定制。
                      </div>
                      <br><br>
                      <br><br>
                      <br><br>

                      <img :src="url+ 'misc/stories/story-lualdi-02.jpg'" style="width:100%; height:auto; max-width:1240px; margin-bottom:20px;">
                      <br><br>
                      <br>

                      <img :src="url+ 'misc/stories/story-lualdi-03.jpg'" style="width:100%; height:auto; max-width:802px; margin-bottom:20px;">
                      <br><br>
                      <br><br>
                      <br><br>
                      <br><br>
                      <br>

                      <div class="col2 col-valign" style="align-items:stretch;">
                          <div style="display:flex; margin-bottom:15px;">
                              <div :style="{ 'flex-grow':1, background:'url(' + url + 'misc/stories/story-lualdi-04.jpg) top left no-repeat', margin:'0 5px 0 0'}">
                              </div>
                              <div :style="{ 'flex-grow':2, display:'flex', margin:'0 auto', 'text-align':'justify', background:'url(' + url + 'misc/stories/story-lualdi-05.png) #f8f8f8 center right no-repeat', 'background-size':'cover' }">
                                  <div style="align-self:center; max-width:310px; margin:20% 10% !important;">
                                      紅色朱門<br>
                                      朱（紅色）漆大門，曾是至尊至貴的標誌，只有天子才可使用。
                                  </div>
                              </div>
                          </div>
                          <div style="margin-bottom:15px;">
                              <img :src="url+ 'misc/stories/story-lualdi-06.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <div class="col2 col-valign col-reverse" style="align-items:stretch;">
                          <div style="display:flex; margin-bottom:15px;">
                              <div :style="{ 'flex-grow':2, display:'flex', margin:'0 auto', 'text-align':'justify', background:'url(' + url + 'misc/stories/story-lualdi-08.png) #f8f8f8 center right no-repeat', 'background-size':'cover' }">
                                  <div style="align-self:center; max-width:310px; margin:20% 10% !important;">
                                      黄色之門<br>
                                      黃色之門，也很高貴。<br>
                                      唐代用“黃閣”指宰相府
                                  </div>
                              </div>
                              <div :style="{ 'flex-grow':1, background:'url(' + url + 'misc/stories/story-lualdi-09.jpg) top left no-repeat', 'background-size':'cover', margin:'0 0 0 5px'}">
                              </div>
                          </div>
                          <div style="margin-bottom:15px;">
                              <img :src="url+ 'misc/stories/story-lualdi-07.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <div class="col2 col-valign" style="align-items:stretch;">
                          <div style="display:flex; margin-bottom:15px;">
                              <div :style="{ 'flex-grow':1, background:'url(' + url + 'misc/stories/story-lualdi-10.jpg) top left no-repeat', 'background-size':'cover', margin:'0 5px 0 0'}">
                              </div>
                              <div :style="{ 'flex-grow':2, display:'flex', margin:'0 auto', 'text-align':'justify', background:'url(' + url + 'misc/stories/story-lualdi-11.png) #f8f8f8 center right no-repeat', 'background-size':'cover' }">
                                  <div style="align-self:center; max-width:340px; margin:20% 10% !important;">
                                      黑色大門<br>
                                      黑色大門舊時很普遍，是普通家庭的門色。其黑一片，雖未描繪圖案，卻如同貼了五彩門神畫——那是“黑煞神“的象徵。
                                  </div>
                              </div>
                          </div>
                          <div style="margin-bottom:15px;">
                              <img :src="url+ 'misc/stories/story-lualdi-12.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <div class="col2 col-valign col-reverse" style="align-items:stretch;">
                          <div style="display:flex; margin-bottom:15px;">
                              <div :style="{ 'flex-grow':2, display:'flex', margin:'0 auto', 'text-align':'justify', background:'url(' + url + 'misc/stories/story-lualdi-14.png) #f8f8f8 center right no-repeat', 'background-size':'cover' }">
                                  <div style="align-self:center; max-width:355px; margin:20% 10% !important;">
                                      白板扉（門）<br>
                                      與彩繪門戶的華麗形成巨大反差，是白板扉。門不施漆，原木色，古代“白板扉”相比朱門彩扃，更多是簡樸生活的寫照。
                                  </div>
                              </div>
                              <div :style="{ 'flex-grow':1, background:'url(' + url + 'misc/stories/story-lualdi-15.jpg) top left no-repeat', 'background-size':'cover', margin:'0 0 0 5px'}">
                              </div>
                          </div>
                          <div style="margin-bottom:15px;">
                              <img :src="url+ 'misc/stories/story-lualdi-13.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>
                      <br>

                      <div style="max-width:690px; margin:0 auto;">
                          榫卯,是古典傢俱之魂，一榫一卯之間，一轉一折之際，凝結著中國幾千年傳統傢俱文化的精粹，沉澱著流光迴轉中經典傢俱款式的複合傳承。
                      </div>
                      <br><br>
                      <br><br>
                      <br><br>

                      <div class="col2">
                          <div style="margin-bottom:15px;">
                              <img :src="url+ 'misc/stories/story-lualdi-16.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:15px;">
                              <img :src="url+ 'misc/stories/story-lualdi-17.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br>

                      <img :src="url+ 'misc/stories/story-lualdi-18.jpg'" style="width:100%; height:auto; margin-bottom:15px;">
                      <br><br>
                      <br><br>

                      <img :src="url+ 'misc/stories/story-lualdi-19.jpg'" style="width:100%; height:auto; margin-bottom:15px;">
                  </div>

                  <div v-else>
                      <div class="main-name">Ying 门</div>
                      <div class="year">门 &nbsp;&bull;&nbsp; 2021</div>

                      <div class="col2 col-valign col-reverse" style="max-width:1240px; margin:0 auto;">
                          <div style="margin:0 40px 40px;">
                              <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify;">
                                  <div class="quote" style="margin-left:0;"><span style="color:#950c0f;">门</span> · 承</div>
                                  <b>门</b> &nbsp; 是中国最早出现的文字，甲骨文中的“门”字，有门框、门楣，还有一对门扇。封建时代，对于大门的漆色也有明确的规定。入必由之，出必由之，历史的风风雨雨，门总要首当其冲。
                              </div>
                          </div>
                          <div style="margin:0 40px 40px;">
                              <img :src="url+ 'misc/stories/story-lualdi-01.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>
                      <br><br>

                      <div style="max-width:630px; margin:0 auto;">
                          门，有门脸、门面之意。在古老的中国每朝每代，不同身份地位的人，门色亦不尽相同。现在，不同的色代表个性化、私人定制。
                      </div>
                      <br><br>
                      <br><br>
                      <br><br>

                      <img :src="url+ 'misc/stories/story-lualdi-02.jpg'" style="width:100%; height:auto; max-width:1240px; margin-bottom:20px;">
                      <br><br>
                      <br>

                      <img :src="url+ 'misc/stories/story-lualdi-03.jpg'" style="width:100%; height:auto; max-width:802px; margin-bottom:20px;">
                      <br><br>
                      <br><br>
                      <br><br>
                      <br><br>
                      <br>

                      <div class="col2 col-valign" style="align-items:stretch;">
                          <div style="display:flex; margin-bottom:15px;">
                              <div :style="{ 'flex-grow':1, background:'url(' + url + 'misc/stories/story-lualdi-04.jpg) top left no-repeat', 'background-size':'cover', margin:'0 5px 0 0'}">
                              </div>
                              <div :style="{ 'flex-grow':2, display:'flex', margin:'0 auto', 'text-align':'justify', background:'url(' + url + 'misc/stories/story-lualdi-05.png) #f8f8f8 center right no-repeat', 'background-size':'cover' }">
                                  <div style="align-self:center; max-width:310px; margin:20% 10% !important;">
                                      红色朱门<br>
                                      朱（红色）漆大门，曾是至尊至贵的标志，只有天子才可使用。
                                  </div>
                              </div>
                          </div>
                          <div style="margin-bottom:15px;">
                              <img :src="url+ 'misc/stories/story-lualdi-06.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <div class="col2 col-valign col-reverse" style="align-items:stretch;">
                          <div style="display:flex; margin-bottom:15px;">
                              <div :style="{ 'flex-grow':2, display:'flex', margin:'0 auto', 'text-align':'justify', background:'url(' + url + 'misc/stories/story-lualdi-08.png) #f8f8f8 center right no-repeat', 'background-size':'cover' }">
                                  <div style="align-self:center; max-width:310px; margin:20% 10% !important;">
                                      黄色之门<br>
                                      黄色之门，也很高贵。<br>
                                      唐代用“黄阁”指宰相府
                                  </div>
                              </div>
                              <div :style="{ 'flex-grow':1, background:'url(' + url + 'misc/stories/story-lualdi-09.jpg) top left no-repeat', 'background-size':'cover', margin:'0 0 0 5px'}">
                              </div>
                          </div>
                          <div style="margin-bottom:15px;">
                              <img :src="url+ 'misc/stories/story-lualdi-07.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <div class="col2 col-valign" style="align-items:stretch;">
                          <div style="display:flex; margin-bottom:15px;">
                              <div :style="{ 'flex-grow':1, background:'url(' + url + 'misc/stories/story-lualdi-10.jpg) top left no-repeat', 'background-size':'cover', margin:'0 5px 0 0'}">
                              </div>
                              <div :style="{ 'flex-grow':2, display:'flex', margin:'0 auto', 'text-align':'justify', background:'url(' + url + 'misc/stories/story-lualdi-11.png) #f8f8f8 center right no-repeat', 'background-size':'cover' }">
                                  <div style="align-self:center; max-width:340px; margin:20% 10% !important;">
                                      黑色大门<br>
                                      黑色大门旧时很普遍，是普通家庭的门色。其黑一片，虽未描绘图案，却如同贴了五彩门神画——那是“黑煞神“的象征。
                                  </div>
                              </div>
                          </div>
                          <div style="margin-bottom:15px;">
                              <img :src="url+ 'misc/stories/story-lualdi-12.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>

                      <div class="col2 col-valign col-reverse" style="align-items:stretch;">
                          <div style="display:flex; margin-bottom:15px;">
                              <div :style="{ 'flex-grow':2, display:'flex', margin:'0 auto', 'text-align':'justify', background:'url(' + url + 'misc/stories/story-lualdi-14.png) #f8f8f8 center right no-repeat', 'background-size':'cover' }">
                                  <div style="align-self:center; max-width:355px; margin:20% 10% !important;">
                                      白板扉（门）<br>
                                      与彩绘门户的华丽形成巨大反差，是白板扉。门不施漆，原木色，古代“白板扉”相比朱门彩扃，更多是简朴生活的写照。
                                  </div>
                              </div>
                              <div :style="{ 'flex-grow':1, background:'url(' + url + 'misc/stories/story-lualdi-15.jpg) top left no-repeat', 'background-size':'cover', margin:'0 0 0 5px'}">
                              </div>
                          </div>
                          <div style="margin-bottom:15px;">
                              <img :src="url+ 'misc/stories/story-lualdi-13.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>
                      <br>

                      <div style="max-width:690px; margin:0 auto;">
                          榫卯,是古典家具之魂，一榫一卯之间，一转一折之际，凝结着中国几千年传统家具文化的精粹，沉淀着流光回转中经典家具款式的复合传承。
                      </div>
                      <br><br>
                      <br><br>
                      <br><br>

                      <div class="col2">
                          <div style="margin-bottom:15px;">
                              <img :src="url+ 'misc/stories/story-lualdi-16.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:15px;">
                              <img :src="url+ 'misc/stories/story-lualdi-17.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br>

                      <img :src="url+ 'misc/stories/story-lualdi-18.jpg'" style="width:100%; height:auto; margin-bottom:15px;">
                      <br><br>
                      <br><br>

                      <img :src="url+ 'misc/stories/story-lualdi-19.jpg'" style="width:100%; height:auto; margin-bottom:15px;">
                  </div>


                  <div class="brand-btn">
                      <!--<a href="/assets/images/storypdf/Bisazza - Timeless_Collezione n25-pages-Steve Leung.pdf" target="_blank" class="btn ">{{ $t('sdxBrand.download_product_cat') }}</a>&nbsp;-->
                      <!--<div @click="showContactVendorForm" class="btn">{{ $t('sdxBrand.contact_vendor') }}</div>-->
                  </div>
              </div>

              <div class="rugstar" v-else-if="id==369">


                  <div class="brand-logo" style="margin:40px auto -5px;">
                      <!--<img src="{{ $resPrefix }}{{ $resDir.'/' }}{{ $brand->logo_path }}" style="max-height:76px;" />-->
                      <img :src="url+logoPath" style="max-height:90px;" />
                  </div>


                  <div v-if="lang=='en'">
                      <div class="main-name">Urban Ink Rug</div>
                      <div class="year">Rugs &nbsp;&bull;&nbsp; 2019</div>

                      <div class="col2 col-valign col-reverse">
                          <div style="margin:0 40px 40px;">
                              <div class="col-text" style="max-width:622px; margin:0 0; text-align:justify;">
                                  <div class="quote" style="margin-left:0; margin-bottom:10px;">Metropolis</div>
                                  The space and rug design that draws inspiration from the urban geometry of our surroundings wants to capture the energy and vitality of the ever-changing urban landscape, project it in daily life as a ink paint, and echo the profile of HIGHLINE.
                              </div>
                          </div>
                          <div style="margin:0 40px 40px;">
                              <img :src="url+ 'misc/stories/story-rugstar-01.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <div style="max-width:730px; margin:0 auto;">
                          The storytelling ink-paint like rug consists of a combination of fibers with 5 different shades of white and grey, and presents the modern architecture and shadows by using rich textures and sophisticated techniques.
                      </div>
                      <br><br>
                      <br><br>
                      <br>

                      <div class="col-auto" style="align-items:center;">
                          <div style="margin:0; flex:0.05;"></div>
                          <div style="margin-bottom:15px; flex:0.2775;" class="img1">
                              <img :src="url+ 'misc/stories/story-rugstar-02.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:15px; flex:0.325;" class="img2">
                              <img :src="url+ 'misc/stories/story-rugstar-03.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:15px; flex:0.325;" class="img3">
                              <img :src="url+ 'misc/stories/story-rugstar-04.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <div class="col2">
                          <div style="margin-bottom:15px;">
                              <img :src="url+ 'misc/stories/story-rugstar-05.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:15px;">
                              <img :src="url+ 'misc/stories/story-rugstar-06.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                  </div>
              <div v-else-if="lang=='tc'">
                      <div class="main-name">都市水墨地毯</div>
                      <div class="year">活動地毯 &nbsp;&bull;&nbsp; 2019</div>

                      <div class="col2 col-valign col-reverse">
                          <div style="margin:0 40px 40px;">
                              <div class="col-text" style="max-width:608px; margin:0 0; text-align:justify;">
                                  <div class="quote" style="margin-left:0; margin-bottom:10px;">都會</div>
                                  空間與地毯設計從豐富多樣的都市景觀汲取靈感，將時時刻刻變化的都市景觀以及蘊含的能量轉化為幾何形態，以水墨形式呈現到都市人的日常生活中，同時與 HC28 都匯裡的設計理念相呼應。
                              </div>
                          </div>
                          <div style="margin:0 40px 40px;">
                              <img :src="url+ 'misc/stories/story-rugstar-01.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <div style="max-width:650px; margin:0 auto;">
                          城市景觀中的建築與光影，通過地毯工藝特有的肌理變化呈現出來；絨高的變化、絲質紗線以及手工刺繡工藝產生出細微而生動的效果，講述獨特的城市故事。
                      </div>
                      <br><br>
                      <br><br>
                      <br>

                      <div class="col-auto" style="align-items:center;">
                          <div style="margin:0; flex:0.05;"></div>
                          <div style="margin-bottom:15px; flex:0.2775;" class="img1">
                              <img :src="url+ 'misc/stories/story-rugstar-02.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:15px; flex:0.325;" class="img2">
                              <img :src="url+ 'misc/stories/story-rugstar-03.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:15px; flex:0.325;" class="img3">
                              <img :src="url+ 'misc/stories/story-rugstar-04.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <div class="col2">
                          <div style="margin-bottom:15px;">
                              <img :src="url+ 'misc/stories/story-rugstar-05.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:15px;">
                              <img :src="url+ 'misc/stories/story-rugstar-06.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
              </div>

              <div v-else>
                      <div class="main-name">都市水墨地毯</div>
                      <div class="year">活动地毯 &nbsp;&bull;&nbsp; 2019</div>

                      <div class="col2 col-valign col-reverse">
                          <div style="margin:0 40px 40px;">
                              <div class="col-text" style="max-width:608px; margin:0 0; text-align:justify;">
                                  <div class="quote" style="margin-left:0; margin-bottom:10px;">都会</div>
                                  空间与地毯设计从丰富多样的都市景观汲取灵感，将时时刻刻变化的都市景观以及蕴含的能量转化为几何形态，以水墨形式呈现到都市人的日常生活中，同时与 HC28 都汇里的设计理念相呼应。
                              </div>
                          </div>
                          <div style="margin:0 40px 40px;">
                              <img :src="url+ 'misc/stories/story-rugstar-01.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <div style="max-width:650px; margin:0 auto;">
                          城市景观中的建筑与光影，通过地毯工艺特有的肌理变化呈现出来；绒高的变化、丝质纱线以及手工刺绣工艺产生出细微而生动的效果，讲述独特的城市故事。
                      </div>
                      <br><br>
                      <br><br>
                      <br>

                      <div class="col-auto" style="align-items:center;">
                          <div style="margin:0; flex:0.05;"></div>
                          <div style="margin-bottom:15px; flex:0.2775;" class="img1">
                              <img :src="url+ 'misc/stories/story-rugstar-02.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:15px; flex:0.325;" class="img2">
                              <img :src="url+ 'misc/stories/story-rugstar-03.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:15px; flex:0.325;" class="img3">
                              <img :src="url+ 'misc/stories/story-rugstar-04.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
                      <br><br>
                      <br><br>

                      <div class="col2">
                          <div style="margin-bottom:15px;">
                              <img :src="url+ 'misc/stories/story-rugstar-05.jpg'" style="width:100%; height:auto;">
                          </div>
                          <div style="margin-bottom:15px;">
                              <img :src="url+ 'misc/stories/story-rugstar-06.jpg'" style="width:100%; height:auto;">
                          </div>
                      </div>
              </div>

                  <div class="brand-btn">
                      <!--<a href="/assets/images/storypdf/Bisazza - Timeless_Collezione n25-pages-Steve Leung.pdf" target="_blank" class="btn ">{{ $t('sdxBrand.download_product_cat') }}</a>&nbsp;-->
                      <!--<div @click="showContactVendorForm" class="btn">{{ $t('sdxBrand.contact_vendor') }}</div>-->
                  </div>

            </div>

            <div class="medea" v-else-if="id==379">


                <div class="brand-logo" style="margin:40px auto 0px;">
                    <img :src="url+logoPath" style="max-height:86px;" />
                </div>


                <div v-if="lang=='en'">
                    <div class="main-name">HUO COLLECTION</div>
                    <div class="year">Loose Furniture &nbsp;&bull;&nbsp; 2020</div>

                    <div>
                        <div class="quote">“Tea is as indispensable in daily life as rice and salt”</div>
                        <div class="caption">Wang Anshi</div>
                    </div>
                    <br><br>
                    <br><br>

                    <div class="col2 col-valign">
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-01.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify;">
                                Inspired by tea, a traditional element of Oriental legacy, “HUO" seamlessly integrates European DNA with hints of Oriental sensibility. “HUO” collection speaks the profound Chinese wisdom of balanced harmony between human beings and nature, highlighting the centric concept of “harmony” commonly found in the archaic rituals of Chinese tea ceremony. “HUO” design also resembles the concentric pattern of “growth ring” – trees’ concentric circles of new cells formed each year, as a tribute to the rich design history of Medea 1905.

                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                    <br>

                    <div class="col2" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-02.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-03.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                    <br><br>
                    <br>

                    <div style="max-width:905px; margin:0 auto;">
                        “HUO” collection comprises exquisite tea table, chair, sofa, side table, water pedestal, armchair, coffee table, cabinet and screen. Imbued with Oriental charm, Steve crafted an intimate and timeless tearoom that encourage blissful gatherings with friends and family.
                    </div>
                    <br><br>
                    <br><br>
                    <br><br>

                    <div class="col2 col-reverse" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-05.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-04.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                    <br><br>
                    <br>

                    <div class="col2" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-06.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-07.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                    <br><br>
                    <br>

                    <div class="col2 col-reverse" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-09.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-08.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                    <br><br>
                    <br>

                    <div class="col2" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-10.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-11.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                    <br><br>
                    <br>

                    <div class="col2 col-reverse" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-13.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-12.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                    <br><br>
                    <br>

                    <div class="col2" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-14.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-15.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                    <br><br>
                    <br>

                    <div class="col2 col-reverse" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-17.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-16.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                    <br><br>
                    <br>

                    <div class="col2" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-18.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-19.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                </div>
                <div v-else-if="lang=='tc'">
                    <div class="main-name">和系列茶室家具</div>
                    <div class="year">活動家具 &nbsp;&bull;&nbsp; 2020</div>

                    <div>
                        <div class="quote">“夫茶之為民用，等於米鹽，不可一日以無也”</div>
                        <div class="caption">王安石</div>
                    </div>
                    <br><br>
                    <br><br>

                    <div class="col2 col-valign">
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-01.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify;">
                                將品牌的歐洲 DNA 和東方文化融為一體，以「茶」和 「年輪」為設計靈感，設計了命名為「和」的茶室傢俱。「和」代表著中國傳統追求「天人合一」和中國茶道「和諧」的核心思想。常言道「以茶待客」，茗茶亦是一種傳統的社交活動。年輪，是樹木歷史的佐證，代表生命和歷史感，也寓意 Medea 1905 深厚的歷史底蘊。

                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                    <br>

                    <div class="col2" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-02.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-03.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                    <br><br>
                    <br>

                    <div style="max-width:970px; margin:0 auto;">
                        「和」系列茶室傢俱包括茶桌、茶椅、沙發、茶几、飲水底座、邊桌扶手椅、咖啡桌、餐櫃和屏風等。設計團隊以現代簡約的設計手法，為傢俱產品注入東方韻味，建構出現代的茶室空間，讓其成為家人和朋友歡聚的空間。
                    </div>
                    <br><br>
                    <br><br>
                    <br><br>

                    <div class="col2 col-reverse" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-05.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-04.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                    <br><br>
                    <br>

                    <div class="col2" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-06.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-07.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                    <br><br>
                    <br>

                    <div class="col2 col-reverse" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-09.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-08.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                    <br><br>
                    <br>

                    <div class="col2" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-10.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-11.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                    <br><br>
                    <br>

                    <div class="col2 col-reverse" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-13.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-12.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                    <br><br>
                    <br>

                    <div class="col2" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-14.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-15.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                    <br><br>
                    <br>

                    <div class="col2 col-reverse" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-17.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-16.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                    <br><br>
                    <br>

                    <div class="col2" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-18.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-19.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                </div>

                <div v-else>
                    <div class="main-name">和系列茶室家具</div>
                    <div class="year">活动家具 &nbsp;&bull;&nbsp; 2020</div>

                    <div>
                        <div class="quote">“夫茶之为民用，等于米盐，不可一日以无也”</div>
                        <div class="caption">王安石</div>
                    </div>
                    <br><br>
                    <br><br>

                    <div class="col2 col-valign">
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-01.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <div class="col-text" style="max-width:645px; margin:0 auto; text-align:justify;">
                                将品牌的欧洲 DNA 和东方文化融为一体，以「茶」和 「年轮」为设计灵感，设计了命名为「和」的茶室家具。 「和」代表着中国传统追求「天人合一」和中国茶道「和谐」的核心思想。常言道「以茶待客」，茗茶亦是一种传统的社交活动。年轮，是树木历史的佐证，代表生命和历史感，也寓意 Medea 1905 深厚的历史底蕴。
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                    <br>

                    <div class="col2" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-02.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-03.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                    <br><br>
                    <br>

                    <div style="max-width:970px; margin:0 auto;">
                        「和」系列茶室家具包括茶桌、茶椅、沙发、茶几、饮水底座、边桌扶手椅、咖啡桌、餐柜和屏风等。设计团队以现代简约的设计手法，为家具产品注入东方韵味，建构出现代的茶室空间，让其成为家人和朋友欢聚的空间。
                    </div>
                    <br><br>
                    <br><br>
                    <br><br>

                    <div class="col2 col-reverse" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-05.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-04.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                    <br><br>
                    <br>

                    <div class="col2" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-06.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-07.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                    <br><br>
                    <br>

                    <div class="col2 col-reverse" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-09.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-08.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                    <br><br>
                    <br>

                    <div class="col2" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-10.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-11.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                    <br><br>
                    <br>

                    <div class="col2 col-reverse" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-13.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-12.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                    <br><br>
                    <br>

                    <div class="col2" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-14.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-15.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                    <br><br>
                    <br>

                    <div class="col2 col-reverse" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-17.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-16.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                    <br><br>
                    <br>

                    <div class="col2" style="margin:0 auto;">
                        <div style="margin-bottom:20px;">
                            <img :src="url+ 'misc/stories/story-medea-18.jpg'" style="width:100%; height:auto;">
                        </div>
                        <div>
                            <img :src="url+ 'misc/stories/story-medea-19.jpg'" style="width:100%; height:auto;">
                        </div>
                    </div>
                </div>

                <div class="brand-btn">
                    <!--<a href="/assets/images/storypdf/Bisazza - Timeless_Collezione n25-pages-Steve Leung.pdf" target="_blank" class="btn ">{{ $t('sdxBrand.download_product_cat') }}</a>&nbsp;-->
                    <div @click="showContactVendorForm" class="btn">{{ $t('sdxBrand.contact_vendor') }}</div>
                </div>

            </div>


            <div class="baccarat story-div" v-else-if="id==224">


              <div class="brand-logo" style="margin:40px auto 10px;">
                <img :src="url+logoPath" style="max-height:75px;" />
              </div>


              <div class="story-content" v-if="lang=='en'">
                <div class="main-name"><i>Printemps Bleu Zenith Chandelier</i></div>
                <div class="year">Decorative Lightings &nbsp;&bull;&nbsp; 2024</div>

                <br><br>
                <div style="max-width:800px; margin:0 auto;">
                  <div class="quote" style="line-height:36px; font-style:italic;">
                    <i>A Harmonious Blend of Crystal-Porcelain Chandelier to celebrate the 60<sup>th</sup> Anniversary of Sino-French Friendship</i>
                  </div>
                </div>
                <br>

                <br><br>
                <br><br>

                <img :src="url+ 'misc/stories/story-baccarat-01.png'" style="width:100%; max-width:1300px; height:auto;">
                <br><br>
                <br><br>
                <br>

                <div style="max-width:980px; margin:0 auto;">
                  The collaboration introduces the Printemps Bleu (meaning 'Blue Spring' in English), a poetic name that alludes to the rejuvenation of nature during springtime. Taking inspiration from Qinghua Ci, one of China’s most cherished and notable artefacts and Baccarat archive motif, reimaging Baccarat's iconic Zénith chandelier. The fusion of crystal and chandelier evokes the Chinese Yin-Yang philosophy, harmonizing the artistic heritage of France and China. This tasteful blend holds immense appeal for discerning collectors and connoisseurs of luxury decor.
                </div>
                <br><br>
                <br><br>

                <!-- <div :style="{ 'max-width':'1700px', 'margin':'0 auto', background:'url(' + url + 'misc/stories/story-baccarat-03.png) center right no-repeat', 'background-size':'contain'}">
                  <img :src="url+ 'misc/stories/story-baccarat-02.jpg'" style="width:100%; max-width:850px; height:auto; margin: 20px 0 50px;">
                </div> -->
                <div class="img2" style="max-width:1600px; margin:0 auto;">
                  <img :src="url+ 'misc/stories/story-baccarat-02.png'" style="width:100%; height:auto;">
                </div>
                <div class="img2mobile" style="margin:0 auto;">
                  <img :src="url+ 'misc/stories/story-baccarat-02-mobile.png'" style="width:100%; height:auto;">
                </div>
                <br><br>
                <br>

                <div style="max-width:980px; margin:0 auto;">
                  The captivating Chinese motifs in the design have deep roots in Baccarat's historical archives, dating back to 1878. Inspired by the remarkable arts and crafts of the Chinese imperial authority showcased during the Universal Exposition in Paris. Preserved within century-old sketches by European artists, these intricate motifs continue to resonate, reflecting craftsmanship and timeless allure. Adorning the porcelain elements of the chandelier, these meticulous motifs demonstrate the unwavering attention to detail.
                </div>
                <br><br>
                <br><br>
                <br>
                
                <div style="max-width:800px; margin:0 auto;">
                  <div class="quote" style="line-height:36px; font-style:italic;">
                    Where The Magic Starts
                  </div>
                </div>
                <br><br>

                <div class="col-auto" style="max-width:1500px; margin:0 auto;">
                  <div style="margin-bottom:15px">
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-09.jpg'" style="width:100%; height:auto;">
                      </div>
                  </div>
                  <div style="margin-bottom:15px">
                    <div class="col-auto no-mobile">
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-10.jpg'" style="height:100%; width:auto;">
                      </div>
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-11.jpg'" style="height:100%; width:auto;">
                      </div>
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-12.jpg'" style="height:100%; width:auto;">
                      </div>
                    </div>
                  </div>
                </div>
                <br><br>
                <br><br>
                
                <div style="max-width:800px; margin:0 auto;">
                  <div class="quote" style="line-height:36px; font-style:italic;">
                    Prototype Inspection at The Baccarat Factory
                  </div>
                </div>
                <br><br>

                <div class="col2" style="max-width:1500px; margin:0 auto;">
                  <div>
                    <div class="col2 no-mobile">
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-13.jpg'" style="width:100%; height:auto;">
                      </div>
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-14.jpg'" style="width:100%; height:auto;">
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="col2 no-mobile">
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-15.jpg'" style="width:100%; height:auto;">
                      </div>
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-16.jpg'" style="width:100%; height:auto;">
                      </div>
                    </div>
                  </div>
                </div>
                <br><br>
                <br><br>

                <img :src="url+ 'misc/stories/story-baccarat-04.jpg'" style="width:100%; max-width:1200px; height:auto;">
                <br><br>
                <br><br>

                <div style="max-width:980px; margin:0 auto;">
                  The artistry shines through in the meticulously crafted porcelain lampshades, ingeniously designed for transparency. Instead of painting on the outer surface, natural motifs adorn the inner part. When illuminated, the blue motifs gracefully dance upon the pristine white surfaces, delicately veiled by the thin porcelain.
                </div>
                <br><br>
                <br><br>
                <br>

                <div class="col2" style="max-width:1500px; margin:0 auto;">
                  <div>
                    <div class="col2 no-mobile">
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-05.jpg'" style="width:100%; height:auto;">
                      </div>
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-06.jpg'" style="width:100%; height:auto;">
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="col2 no-mobile">
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-07.jpg'" style="width:100%; height:auto;">
                      </div>
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-08.jpg'" style="width:100%; height:auto;">
                      </div>
                    </div>
                  </div>
                </div>
                <br><br>
                <br>

              </div>
              <div class="story-content" v-else-if="lang=='tc'">
                <div class="main-name"><i>Printemps Bleu</i> 天頂枝形吊燈</div>
                <div class="year">裝飾燈具 &nbsp;&bull;&nbsp; 2024</div>

                <br><br>
                <div style="max-width:970px; margin:0 auto;">
                  <div class="quote">
                    水晶與瓷器交織映中西　致賀中法建交 60 周年
                  </div>
                </div>
                <br>

                <br><br>
                <br><br>

                <img :src="url+ 'misc/stories/story-baccarat-01.png'" style="width:100%; max-width:1300px; height:auto;">
                <br><br>
                <br><br>
                <br>

                <div style="max-width:1000px; margin:0 auto;">
                  從中國名揚海外的青花瓷和巴卡拉典藏圖案為靈感，重新演繹巴卡拉標誌性的天頂枝形吊燈，取名為 Printemps Bleu，意為藍色的春天，寄意春天萬象更新、生機盎然，是對兩國與品牌美好未來的祝福。水晶與瓷器閃耀交織，融入中國的陰陽哲學，溯源中法美學氣韻，為獨具慧眼的收藏家和鑒賞家帶來別具品味、亦古亦今的藝術碰撞。
                </div>
                <br><br>
                <br><br>

                <!-- <div :style="{ 'max-width':'1700px', 'margin':'0 auto', background:'url(' + url + 'misc/stories/story-baccarat-03.png) center right no-repeat', 'background-size':'contain'}">
                  <img :src="url+ 'misc/stories/story-baccarat-02.jpg'" style="width:100%; max-width:850px; height:auto; margin: 20px 0 50px;">
                </div> -->
                <div class="img2" style="max-width:1600px; margin:0 auto;">
                  <img :src="url+ 'misc/stories/story-baccarat-02.png'" style="width:100%; height:auto;">
                </div>
                <div class="img2mobile" style="margin:0 auto;">
                  <img :src="url+ 'misc/stories/story-baccarat-02-mobile.png'" style="width:100%; height:auto;">
                </div>
                <br><br>
                <br>

                <div style="max-width:1000px; margin:0 auto;">
                  吊燈上精美的中式圖案，可追本溯源自巴卡拉記載於 1878 年的歷史典藏。巴卡拉對中國首次亮相於巴黎萬國博覽會時展示的青花瓷工藝品的印象尤為深刻。這些由歐洲畫家繪製的中國典藏圖案，歷經百年仍喚起共鳴，並重新演繹在吊燈的瓷器表面上，再度煥發永恆的魅力。
                </div>
                <br><br>
                <br><br>
                <br>

                <div style="max-width:800px; margin:0 auto;">
                  <div class="quote" style="line-height:36px;">
                    魔法誕生之始
                  </div>
                </div>
                <br><br>

                <div class="col-auto" style="max-width:1500px; margin:0 auto;">
                  <div style="margin-bottom:15px">
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-09.jpg'" style="width:100%; height:auto;">
                      </div>
                  </div>
                  <div style="margin-bottom:15px">
                    <div class="col-auto no-mobile">
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-10.jpg'" style="height:100%; width:auto;">
                      </div>
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-11.jpg'" style="height:100%; width:auto;">
                      </div>
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-12.jpg'" style="height:100%; width:auto;">
                      </div>
                    </div>
                  </div>
                </div>
                <br><br>
                <br><br>
                
                <div style="max-width:800px; margin:0 auto;">
                  <div class="quote" style="line-height:36px;">
                    在巴卡拉工廠內審核吊燈原模型
                  </div>
                </div>
                <br><br>

                <div class="col2" style="max-width:1500px; margin:0 auto;">
                  <div>
                    <div class="col2 no-mobile">
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-13.jpg'" style="width:100%; height:auto;">
                      </div>
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-14.jpg'" style="width:100%; height:auto;">
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="col2 no-mobile">
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-15.jpg'" style="width:100%; height:auto;">
                      </div>
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-16.jpg'" style="width:100%; height:auto;">
                      </div>
                    </div>
                  </div>
                </div>
                <br><br>
                <br><br>

                <img :src="url+ 'misc/stories/story-baccarat-04.jpg'" style="width:100%; max-width:1200px; height:auto;">
                <br><br>
                <br><br>

                <div style="max-width:850px; margin:0 auto;">
                  別具匠心的瓷質燈罩薄如綢紗，青花繪於燈罩內壁，閃耀著藝術的光彩。當燈光亮起時，內壁圖案在外部透出朦朧韻致，樹間鳴韻若隱若現，折射出迷人的柔和光芒。
                </div>
                <br><br>
                <br><br>
                <br>

                <div class="col2" style="max-width:1500px; margin:0 auto;">
                  <div>
                    <div class="col2 no-mobile">
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-05.jpg'" style="width:100%; height:auto;">
                      </div>
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-06.jpg'" style="width:100%; height:auto;">
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="col2 no-mobile">
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-07.jpg'" style="width:100%; height:auto;">
                      </div>
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-08.jpg'" style="width:100%; height:auto;">
                      </div>
                    </div>
                  </div>
                </div>
                <br><br>
                <br>
              </div>

              <div class="story-content" v-else>
                <div class="main-name"><i>Printemps Bleu</i> 天顶枝形吊灯</div>
                <div class="year">装饰灯具 &nbsp;&bull;&nbsp; 2024</div>

                <br><br>
                <div style="max-width:970px; margin:0 auto;">
                  <div class="quote">
                    水晶与瓷器交织映中西　致贺中法建交 60 周年
                  </div>
                </div>
                <br>

                <br><br>
                <br><br>

                <img :src="url+ 'misc/stories/story-baccarat-01.png'" style="width:100%; max-width:1300px; height:auto;">
                <br><br>
                <br><br>
                <br>

                <div style="max-width:1000px; margin:0 auto;">
                  从中国名扬海外的青花瓷和巴卡拉典藏图案为灵感，重新演绎巴卡拉标志性的天顶枝形吊灯，取名为 Printemps Bleu，意为蓝色的春天，寄意春天万象更新、生机盎然，是对两国与品牌美好未来的祝福。水晶与瓷器闪耀交织，融入中国的阴阳哲学，溯源中法美学气韵，为独具慧眼的收藏家和鉴赏家帶來别具品味、亦古亦今的艺术碰撞。
                </div>
                <br><br>
                <br><br>

                <!-- <div :style="{ 'max-width':'1700px', 'margin':'0 auto', background:'url(' + url + 'misc/stories/story-baccarat-03.png) center right no-repeat', 'background-size':'contain'}">
                  <img :src="url+ 'misc/stories/story-baccarat-02.jpg'" style="width:100%; max-width:850px; height:auto; margin: 20px 0 50px;">
                </div> -->
                <div class="img2" style="max-width:1600px; margin:0 auto;">
                  <img :src="url+ 'misc/stories/story-baccarat-02.png'" style="width:100%; height:auto;">
                </div>
                <div class="img2mobile" style="margin:0 auto;">
                  <img :src="url+ 'misc/stories/story-baccarat-02-mobile.png'" style="width:100%; height:auto;">
                </div>
                <br><br>
                <br>

                <div style="max-width:1000px; margin:0 auto;">
                  吊灯上精美的中式图案，可追本溯源自巴卡拉记载于 1878 年的历史典藏。巴卡拉对中国首次亮相于巴黎万国博览会时展示的青花瓷工艺品的印象尤为深刻。这些由欧洲画家绘制的中国典藏图案，历经百年仍唤起共鸣，并重新演绎在吊灯的瓷器表面上，再度焕发永恒的魅力。
                </div>
                <br><br>
                <br><br>
                <br>

                <div style="max-width:800px; margin:0 auto;">
                  <div class="quote" style="line-height:36px;">
                    魔法诞生之始
                  </div>
                </div>
                <br><br>

                <div class="col-auto" style="max-width:1500px; margin:0 auto;">
                  <div style="margin-bottom:15px">
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-09.jpg'" style="width:100%; height:auto;">
                      </div>
                  </div>
                  <div style="margin-bottom:15px">
                    <div class="col-auto no-mobile">
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-10.jpg'" style="height:100%; width:auto;">
                      </div>
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-11.jpg'" style="height:100%; width:auto;">
                      </div>
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-12.jpg'" style="height:100%; width:auto;">
                      </div>
                    </div>
                  </div>
                </div>
                <br><br>
                <br><br>
                
                <div style="max-width:800px; margin:0 auto;">
                  <div class="quote" style="line-height:36px;">
                    在巴卡拉工厂内审核吊灯原模型
                  </div>
                </div>
                <br><br>

                <div class="col2" style="max-width:1500px; margin:0 auto;">
                  <div>
                    <div class="col2 no-mobile">
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-13.jpg'" style="width:100%; height:auto;">
                      </div>
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-14.jpg'" style="width:100%; height:auto;">
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="col2 no-mobile">
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-15.jpg'" style="width:100%; height:auto;">
                      </div>
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-16.jpg'" style="width:100%; height:auto;">
                      </div>
                    </div>
                  </div>
                </div>
                <br><br>
                <br><br>

                <img :src="url+ 'misc/stories/story-baccarat-04.jpg'" style="width:100%; max-width:1200px; height:auto;">
                <br><br>
                <br><br>

                <div style="max-width:850px; margin:0 auto;">
                  别具匠心的瓷质灯罩薄如绸纱，青花绘于灯罩内壁，闪耀着艺术的光彩。当灯光亮起时，内壁图案在外部透出朦胧韵致，树间鸣韵若隐若现，折射出迷人的柔和光芒。
                </div>
                <br><br>
                <br><br>
                <br>

                <div class="col2" style="max-width:1500px; margin:0 auto;">
                  <div>
                    <div class="col2 no-mobile">
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-05.jpg'" style="width:100%; height:auto;">
                      </div>
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-06.jpg'" style="width:100%; height:auto;">
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="col2 no-mobile">
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-07.jpg'" style="width:100%; height:auto;">
                      </div>
                      <div>
                        <img :src="url+ 'misc/stories/story-baccarat-08.jpg'" style="width:100%; height:auto;">
                      </div>
                    </div>
                  </div>
                </div>
                <br><br>
                <br>
              </div>

              <div class="brand-btn">
                <a :href="url+ 'misc/stories/pdf/Baccarat Printemps Bleu_EN.pdf'" target="_blank" class="btn ">{{ $t('sdxBrand.download_product_cat') }}</a>&nbsp;
                <div @click="showContactVendorForm" class="btn">{{ $t('sdxBrand.contact_vendor') }}</div>
              </div>

            </div>


            <div class="kaldewei story-div" v-else-if="id==194">


              <div class="brand-logo" style="margin:40px auto -5px;">
                <img :src="url+logoPath" style="max-height:75px;" />
              </div>


              <div class="story-content" v-if="lang=='en'">
                <div class="main-name">CHEN Series</div>
                <div class="year">{{ $t('sdxBrand.san_products') }} &nbsp;&bull;&nbsp; 2024</div>

                <br><br>
                <div style="max-width:760px; margin:0 auto;">
                  This contemporary collection of CHEN represents the debuting design and manufacturing attempt of creating a full round-shaped bathtub entirely made in composite stones Kaldewei has ever embarked on since the establishment of its fourth-generation family-owned reputed company.
                </div>
                <br>

                <br><br>
                <br><br>

                <img :src="url+ 'misc/stories/story-kaldewei-01.jpg'" style="width:100%; max-width:1500px; height:auto;">
                <br><br>
                <br><br>
                <br>

                <div style="max-width:880px; margin:0 auto;">
                  The collection is named after the Chinese ideogram “辰” - Chen, which indicates the astrological phenomenon of the lunar eclipse.
                  The discreet, curved geometrical lines of the bathtubs and washbasin subtly recall the encounter of the rounded silhouettes of the sun and the moon, resulting into the overlap of two perfectly harmonised circles.
                </div>
                <br><br>
                <br><br>

                <img :src="url+ 'misc/stories/story-kaldewei-02.jpg'" style="width:100%; max-width:1500px; height:auto;">
                <br><br>
                <br><br>
                <br>

                <div style="max-width:770px; margin:0 auto;">
                  On the outer ledge, patrons can conveniently place a glass of champagne, delicious snacks and, maybe, even their phone tuned to a soothing R&B playlist. The inner ledge, inserted slightly lower to the outer one, enhances the bathing experience further. By providing an ergonomic shape for back-and-neck rest, users can comfortably rest their body inside the pleasant warmth of the water, indulging in the utmost relaxation.
                </div>
                <br><br>
                <br><br>
                <br>

                <div class="col-auto" style="max-width:1500px; margin:0 auto;">
                  <div style="margin-bottom:10px;">
                    <img :src="url+ 'misc/stories/story-kaldewei-03.jpg'" style="width:100%; height:auto;">
                  </div>
                  <div style="margin-bottom:10px;">
                    <img :src="url+ 'misc/stories/story-kaldewei-04.jpg'" style="width:100%; height:auto;">
                  </div>
                  <div style="margin-bottom:10px;">
                    <img :src="url+ 'misc/stories/story-kaldewei-05.jpg'" style="width:100%; height:auto;">
                  </div>
                </div>
                <br><br>
                <br><br>

                <div style="max-width:800px; margin:0 auto;">
                  This new collection includes freestanding round and oval bathtubs paired with a freestanding round washbasin - all embracing the same creative vision and matching design language. Defined by a minimalist aesthetics of clean lines, simple shapes, and smooth surfaces, the collection delivers a sleek and modern look.
                </div>
                <br><br>
                <br><br>
                <br>

                <img :src="url+ 'misc/stories/story-kaldewei-06.jpg'" style="width:100%; max-width:1500px; height:auto;">

                <div class="m-hide" style="height:110px;"></div>
                <div style="height:10px;"></div>

                <img :src="url+ 'misc/stories/story-kaldewei-07.jpg'" style="width:100%; max-width:1500px; height:auto;">
                <br><br>

              </div>
              <div class="story-content" v-else-if="lang=='tc'">
                <div class="main-name">辰系列</div>
                <div class="year">{{ $t('sdxBrand.san_products') }} &nbsp;&bull;&nbsp; 2024</div>

                <br><br>
                <div style="max-width:750px; margin:0 auto;">
                  一向以生產耐用、創新且具設計前瞻性的優質鋼瓷釉浴室設備而享譽國際的卡德維，迄今已馳名逾世紀。本次矚目亮相的當代系列是卡德維自第四代家族企業創立以來的又一新嘗試，首度推出以複合石材製成的圓形浴缸。
                </div>
                <br>

                <br><br>
                <br><br>

                <img :src="url+ 'misc/stories/story-kaldewei-01.jpg'" style="width:100%; max-width:1500px; height:auto;">
                <br><br>
                <br><br>
                <br>

                <div style="max-width:880px; margin:0 auto;">
                  新系列以中國漢字 “辰” 命名，含時間和日月交會的星象意蘊。
                </div>

                <div style="max-width:880px; margin:0 auto;">
                  浴缸及面盆含蓄的弧形幾何曲條，宛如日月圓缺的相會與邂逅，呈現雙圓合溯的和諧共鳴。
                </div>
                <br><br>
                <br><br>

                <img :src="url+ 'misc/stories/story-kaldewei-02.jpg'" style="width:100%; max-width:1500px; height:auto;">
                <br><br>
                <br><br>
                <br>

                <div style="max-width:740px; margin:0 auto;">
                  浴缸的外壁架可供用家擺放香檳及小吃，甚至放置播放著 R&B 歌曲的手機，讓用家在藍調音律之間享受忙裡偷閒。浴缸的設計同時符合人體工學原理，其內壁小邊台的高度略低於外壁的，讓用家安躺浸浴時，背部和頸部都得以放鬆，盡享浸浴的至高體驗。
                </div>
                <br><br>
                <br><br>
                <br>

                <div class="col-auto" style="max-width:1500px; margin:0 auto;">
                  <div style="margin-bottom:10px;">
                    <img :src="url+ 'misc/stories/story-kaldewei-03.jpg'" style="width:100%; height:auto;">
                  </div>
                  <div style="margin-bottom:10px;">
                    <img :src="url+ 'misc/stories/story-kaldewei-04.jpg'" style="width:100%; height:auto;">
                  </div>
                  <div style="margin-bottom:10px;">
                    <img :src="url+ 'misc/stories/story-kaldewei-05.jpg'" style="width:100%; height:auto;">
                  </div>
                </div>
                <br><br>
                <br><br>

                <div style="max-width:700px; margin:0 auto;">
                  新系列包括獨立式圓形、橢圓形浴缸，並配備獨立式圓形面盆，其設計願景及設計語言一脈相承，採用簡約美學設計，簡潔的線條、極簡的造型及流暢的表面，體現時尚及現代氣息。
                </div>
                <br><br>
                <br><br>
                <br>

                <img :src="url+ 'misc/stories/story-kaldewei-06.jpg'" style="width:100%; max-width:1500px; height:auto;">

                <div class="m-hide" style="height:110px;"></div>
                <div style="height:10px;"></div>

                <img :src="url+ 'misc/stories/story-kaldewei-07.jpg'" style="width:100%; max-width:1500px; height:auto;">
                <br><br>

              </div>

              <div class="story-content" v-else>
                <div class="main-name">辰系列</div>
                <div class="year">{{ $t('sdxBrand.san_products') }} &nbsp;&bull;&nbsp; 2024</div>

                <br><br>
                <div style="max-width:750px; margin:0 auto;">
                  一向以生产耐用、创新且具设计前瞻性的优质钢瓷釉浴室设备而享誉国际的卡德维，迄今已馳名逾世纪。本次瞩目亮相的当代系列是卡德维自第四代家族企业创立以来的又一新尝试，首度推出以复合石材制成的圆形浴缸。
                </div>
                <br>

                <br><br>
                <br><br>

                <img :src="url+ 'misc/stories/story-kaldewei-01.jpg'" style="width:100%; max-width:1500px; height:auto;">
                <br><br>
                <br><br>
                <br>

                <div style="max-width:880px; margin:0 auto;">
                  新系列以中国汉字 “辰” 命名，含时间和日月交会的星象意蕴。
                </div>

                <div style="max-width:880px; margin:0 auto;">
                  浴缸及面盆含蓄的弧形几何曲条，宛如日月圆缺的相会与邂逅，呈现双圆合溯的和谐共鸣。
                </div>
                <br><br>
                <br><br>

                <img :src="url+ 'misc/stories/story-kaldewei-02.jpg'" style="width:100%; max-width:1500px; height:auto;">
                <br><br>
                <br><br>
                <br>

                <div style="max-width:740px; margin:0 auto;">
                  浴缸的外壁架可供用家摆放香槟及小吃，甚至放置播放着 R&B 歌曲的手机，让用家在蓝调音律之间享受忙里偷闲。浴缸的设计同时符合人体工学原理，其内壁小边台的高度略低于外壁的，让用家安躺浸浴時，背部和颈部都得以放松，尽享浸浴的至高体验。
                </div>
                <br><br>
                <br><br>
                <br>

                <div class="col-auto" style="max-width:1500px; margin:0 auto;">
                  <div style="margin-bottom:10px;">
                    <img :src="url+ 'misc/stories/story-kaldewei-03.jpg'" style="width:100%; height:auto;">
                  </div>
                  <div style="margin-bottom:10px;">
                    <img :src="url+ 'misc/stories/story-kaldewei-04.jpg'" style="width:100%; height:auto;">
                  </div>
                  <div style="margin-bottom:10px;">
                    <img :src="url+ 'misc/stories/story-kaldewei-05.jpg'" style="width:100%; height:auto;">
                  </div>
                </div>
                <br><br>
                <br><br>

                <div style="max-width:700px; margin:0 auto;">
                  新系列包括独立式圆形、椭圆形浴缸，并配备独立式圆形面盆，其设计愿景及设计语言一脉相承，采用简约美学设计，简洁的线条、极简的造型及流畅的表面，体现时尚及现代气息。
                </div>
                <br><br>
                <br><br>
                <br>

                <img :src="url+ 'misc/stories/story-kaldewei-06.jpg'" style="width:100%; max-width:1500px; height:auto;">

                <div class="m-hide" style="height:110px;"></div>
                <div style="height:10px;"></div>

                <img :src="url+ 'misc/stories/story-kaldewei-07.jpg'" style="width:100%; max-width:1500px; height:auto;">
                <br><br>

              </div>

              <div class="brand-btn">
                <a v-if="lang=='en'" :href="url+ 'misc/stories/pdf/KALDEWEI_CHEN_EN.pdf'" target="_blank" class="btn ">{{ $t('sdxBrand.download_product_cat') }}</a>
                <a v-else-if="lang=='tc'" :href="url+ 'misc/stories/pdf/KALDEWEI_CHEN_CN.pdf'" target="_blank" class="btn ">{{ $t('sdxBrand.download_product_cat') }}</a>
                <a v-else :href="url+ 'misc/stories/pdf/KALDEWEI_CHEN_CN.pdf'" target="_blank" class="btn ">{{ $t('sdxBrand.download_product_cat') }}</a>
                &nbsp;<div @click="showContactVendorForm" class="btn">{{ $t('sdxBrand.contact_vendor') }}</div>
              </div>

            </div>


            <brand-product :product-by-cat="productByCat" :product-list-by-cat="productListByCat" :type=1 ></brand-product>

          </div>

        </b-col>
      </b-row>
    </b-container>


    <contact-form :is-dialog="isDialog" @vendorChange="vendorChange" :contact-data ="contactData"></contact-form>

  </div>
</template>

<script>
import contactFrom from '@/components/ContactForm'
import brandProduct from '@/components/Cooperative/BrandProduct'
import {getcontactVendor,getBrandDetail} from "@/api/brand";
export default {
  components:{
    'contact-form': contactFrom,
    'brand-product': brandProduct,
  },
  data() {
    return {
        url: process.env.VUE_APP_IMG_URL,
        lang: "",
        id: null,
        logoPath:'',
        collection: null,
        isDialog:false,
        loading:false,
        contactData:{},
        productByCat:{},
        productListByCat:[]
    };
  },
  created(){
      this.id =  this.$route.query.id;
      this.collection =  this.$route.query.collection;
      this.lang =localStorage.getItem('language');
      this.getcontactVendors();
      this.getBrandDetail();
  },
  methods: {
    showContactVendorForm() {
        this.isDialog = true;

    },
    vendorChange(val){
        this.isDialog = val
    },
    getcontactVendors(){
        this.loading = true;
        getcontactVendor(this.id).then(res=>{
            this.loading = false;
            this.contactData = res.data;

        })
    },
    getBrandDetail(){
        getBrandDetail(this.id).then(res=>{
            let result = res.data;
			if (result.brand) {
				this.logoPath = result.brand.logo_path;
			}

            for(let key in result.productListByCat){
                this.productListByCat = result.productListByCat[key].data;
            }
            const pcat = []
            res.data.catMapped.map((el) => {
            if (res.data.productListByCat[el.id].data.length>0) {
              pcat.push({ p_name: el.name, p_list: res.data.productListByCat[el.id].data,p_id:el.id })
            }
          })
            this.productListByCat = pcat
            this.productByCat = result;
        })
    }
  }
};
</script>

<style lang="scss" scoped>
.story {
  max-width: 1500px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 28px;
  @media (max-width: 1499px) {
    padding: 0 7.5px;
  }
  .brand-logo {
    display: inline-block;
    img {
      max-width: 260px;
    }
  }
  .main-name {
    color: #333;
    font-size: 21px;
    display: block;
    width: 100%;
    margin: 5px 0 5px;
    line-height: 28px;
  }
  .year {
    font-size: 12px;
    margin-bottom: 80px;
    line-height: normal;
  }
  .quote{
    font-size: 22px;
    line-height: normal;
    margin-bottom: 4px;
  }
  .caption{
    font-size: 13px;
  }
  .col2 {
      display: flex;
      div {
        margin: 0 10px 40px;
        flex: 1;
        line-height: 22px;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        @media (max-width: 767px) {
          margin-left: 0px !important;
          margin-right: 0px !important;
          &.col-text {
            padding-top: 40px;
            text-align: center !important;
            max-width: 100% !important;
          }
        }
      }
      &.col-min-margin {
          div{
              margin: 0 1px 40px;
          }
      }
      &.col-no-margin{
          div {
              margin: 0 0px 40px;
          }
      }
      @media (max-width: 767px) {
          display: block;
          &.no-mobile {
            display: flex;
            div {
              margin-left: 1px !important;
              margin-right: 1px !important;
              &:first-child {
                margin-left: 0px !important;
              }
              &:last-child {
                margin-right: 0px !important;
              }
            }
          }
      }
  }
  .col-auto {
      display: flex;
      div{
          margin: 0 10px 40px;
          flex: auto;
          line-height: 22px;
          &:first-child {
              margin-left: 0;
          }
          &:last-child {
              margin-right: 0;
          }
          @media (max-width: 767px) {
              margin-left: 0px !important;
          margin-right: 0px !important;
          }
      }
      &.col-min-margin{
          div{
              margin: 0 1px 40px;
          }
      }
      &.col-lg-margin{
          div {
              margin: 0 20px 40px;
          }
      }
      &.col-no-margin{
          div {
              margin: 0 0px 40px;
          }
      }
      @media (max-width: 767px) {
          display: block;
          &.no-mobile {
            display: flex;
          }
      }
  }
  .col-valign{
      align-items: center;
      div {
          line-height: 28px;
      }
  }
  .col-reverse{
      flex-direction: row-reverse;
      div {
          &:first-child {
              margin-right: 0;
              margin-left: 10px;
          }
          &:last-child {
              margin-left: 0;
              margin-right: 10px;
          }
          @media (max-width: 767px) {
              &:first-child {
                  margin-left: 0px !important;
              }
              &:last-child {
                  margin-right: 0px !important;
              }
          }
      }
  }
  @media (max-width: 767px) {
      font-size: 15px;
  }


  .brand-btn {
      padding: 80px 0 60px;
      .btn {
          background: #a1a1a1;
          font-size: 15px;
          font-weight: normal;
          padding: 10px 12px;
          min-width: 190px;
          color: #fff;
          &:hover {
              background: #999;
          }
          @media (max-width: 767px) {
              width: 100%;
          }
      }
  }
  .axent {
      .col2.col2-custom {
          div {
              &:first-child {
                  margin-right: 37px;
              }
              &:last-child {
                  margin-left: 37px;
              }
          }
      }
  }
  .fusital {
      .designers{
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
          list-style: none;
          margin: 0px auto 20px;
          padding: 0px;
          max-width: 1180px;
          li{
            margin: 0 0 10px 0;
            padding: 10px;
            width: 25%;
            font-size: 15px;
            line-height: normal;
            img{
              margin-bottom: 10px;
            }
            @media (max-width: 767px){
              width: 50%;
              padding: 5px;
              font-size: 12px;
              img{
                  margin-bottom: 5px;
              }
            }
          }
          @media (max-width: 1179px) {
            margin: 0px -10px 20px;
          }
          @media (max-width: 767px) {
            margin: 0 -5px;
          }
      }
      .concepts{
          div{
              margin-bottom: 20px;
            flex: 1;
              @media (max-width: 767px) {
                  font-size: 12px;
                  line-height: normal !important;
                  text-align: center !important;
              }
          }
          h2{
              margin-bottom: 20px;
              font-size: 21px;
          }
          img{
              margin-bottom: 10px;
          }
      }
  }
  .rugstar {
    @media (max-width: 767px) {
        .col-auto {
            .img1 img {
                width: 86% !important;
            }
            .img2 {
                margin-bottom: 13% !important;
            }
        }
    }
  }
  .treca{
    .designers{
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        list-style: none;
        margin: 0px auto 20px;
        padding: 0px;
        max-width: 1240px;
        li{
            margin: 0 0 10px 0;
            padding: 10px;
            width: 20%;
            font-size: 15px;
            line-height: normal;
            img{
                margin-bottom: 10px;
            }

        }
        @media (max-width: 767px) {
            li {
                    width: 50%;
                    padding: 5px;
                    font-size: 12px;
                    img {
                        margin-bottom: 5px;
                    }
                }
        }
        @media (max-width: 1239px) {
            margin: 0px -10px 20px;
        }
        @media (max-width: 767px) {
          margin: 0 -5px;
        }
    }
  }
  .m-hide {
    display: block;
    @media (max-width: 767px) {
      display: none;
    }
  }
}
html[lang="en"] {
    .story {
        font-size: 15px;
        .col2 {
            div {
                line-height: 28px;
                @media (max-width: 767px){
                    &.col-text {
                        text-align: justify !important;
                    }
                }
            }
        }
        .col-auto {
            div {
                line-height: 28px;
            }
        }
    }
    @media (max-width: 767px){
        .text-center {
            div {
                text-align: justify;
            }
            .year, h2 {
                text-align: center;
            }
        }
    }
}


.black {
  padding: 0px;
  .story {
    max-width: none;
    .story-div {
      background: #000000;
      color: #FFFFFF;
      padding: 0 15px;
      .story-content {
        max-width: 1500px;
        margin: 0 auto;
        .main-name {
          color: #FFFFFF;
        }
      }
      .brand-btn {
        background: #FFFFFF;
        margin: 0 -15px;
        padding-left: 15px;
        padding-right: 15px;
      }
      &.baccarat {
        .story-content {
          max-width: none;
          .main-name, .quote {
            font-family: Georgia, Helvetica, arial, "simhei", sans-serif;
            font-size: 21px;
            em, i {
              font-style: italic;
              letter-spacing: 3px;
            }
          }
          .img2 {
            @media (max-width: 980px){
              display: none;
            }
          }
          .img2mobile {
            display:none;
            @media (max-width: 980px){
              display: block;
            }
          }
          .col-auto {
            div{
              margin: 0 10px 0px;
              &:first-child {
                margin-left: 0;
              }
              &:last-child {
                margin-right: 0;
              }
              @media (max-width: 767px) {
                // margin: 0 7.5px 0px !important;
                // &:first-child {
                //   margin-left: 0 !important;
                // }
                // &:last-child {
                //     margin-right: 0 !important;
                // }
              }
            }
            &.no-mobile {
              height: 100%;
              @media (max-width: 767px) {
                display: flex;
                div {
                  margin: 0 7.5px !important;
                  &:first-child {
                    margin-left: 0 !important;
                  }
                  &:last-child {
                    margin-right: 0 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    .brand-product {
      max-width: 1530px;
      margin: 20px auto 0;
      padding: 0 15px;
    }
    @media (max-width: 1499px) {
      padding: 0 0px;
    }

    .col2 {
      div {
        margin: 0 10px 7.5px;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      @media (max-width: 767px) {
        display: block;
        &.no-mobile {
          display: flex;
          div {
            margin-left: 7.5px !important;
            margin-right: 7.5px !important;
            &:first-child {
              margin-left: 0px !important;
            }
            &:last-child {
              margin-right: 0px !important;
            }
          }
        }
      }
    }
  }

}
</style>
