<template>
  <div class="content" v-loading="loading">
    <b-container fluid>
      <b-row class="drs" v-if="Object.keys(detailData).length != 0">
        <b-col cols="12">
          <h5 class="name mgb40 tc">{{ detailData.tag.name }}</h5>
        </b-col>
        <b-col
          xs="12"
          class="brand-details-div posiR"
          v-if="detailData != ''"
        >
          <div class="brand-img" v-if="detailData.brand.image_path">
            <img :src="imgurl + detailData.brand.image_path" />
          </div>
          <div class="brand-basic-info tl">
            <div class="brand-logo">
              <img :src="imgurl + detailData.brand.logo_path" />
            </div>
          </div>
          <div class="brand-info tl">
            <h1>{{ detailData.brand.name }}</h1>
            <ul class="brand-cat" v-if="detailData.catMapped">
              <li
                class="cat-to-product"
                @click="goProduct(item.id)"
                v-for="(item, index) in detailData.catMapped"
                :key="index"
              >
                {{ item.name }}
              </li>
            </ul>
            <p class="ft14">{{ detailData.brand.desc }}</p>
            <br />
            <p v-if="detailData.brand.scope_en">
              服務範圍:<br />{{ detailData.brand.scope_en }}
            </p>
            <ul class="brand-links">
              <li v-if="detailData.brand[webSide]">
                <a :href="detailData.brand[webSide]" target="_blank"
                  ><span class="fa fa-link"></span>
                  {{ $t('Strateg.header_company_website') }}</a
                >
              </li>
              <li v-if="detailData.brand.id == 74">
                <a href="http://erp.creativehome.net/" target="_blank"
                  ><span class="fa fa-link"></span>
                  {{ $t('Strateg.ta_erp') }}</a
                >
                |
                <a
                  :href="url + 'brands/TA ERP操作指引(20201202).pdf'"
                  target="_blank"
                  ><span class="fa fa-download"></span>
                  {{ $t('Strateg.ta_erp_doc') }}</a
                >
              </li>
              <li v-if="detailData.productExist" @click="showContactVendorForm" style="cursor: pointer;">
                <span class="fa fa-envelope-o"></span>
                <span style="font-size: 12px">
                {{ $t('sdxBrand.contact_vendor') }}</span>
              </li>
              <li v-if="detailData.brand.shop_link">
                <a :href="detailData.brand.shop_link" target="_blank"
                  ><span class="fa fa-link"></span>
                  {{ $t('Strateg.header_online_store') }}</a
                >
              </li>
              <li v-if="detailData.brand[detailData.bFile]">
                <a :href="imgurl + detailData.brand[detailData.bFile]" target="_blank"
                  ><span class="fa fa-download"></span>
                  {{ $t('Strateg.header_product_catalogue') }}</a
                >
              </li>
              <li v-if="detailData.hasBrandShopList" @click="showStoreVisit" style="font-size:12px"> <span class="fa fa-map-marker"></span> {{ $t('sdxBrand.store_visiting') }}</li>
            </ul>
          </div>
        </b-col>
      </b-row>

      <div class="relProducts">
        <brand-product
          :product-by-cat="detailData"
          :product-list-by-cat="productListByCat"
          :type="1"
          v-show="true"
        ></brand-product>
      </div>
    </b-container>
    <contact-form
      :is-dialog="isDialog"
      @vendorChange="vendorChange"
      :contact-data="contactData"
    ></contact-form>
    <store-visit @storeChange="storeChange" :cat-id="catId" :is-store-dialog="isStoreDialog"></store-visit>
  </div>
</template>

<script>
import brandProduct from '@/components/Cooperative/BrandProduct'
import { getBrandDetail, getcontactVendor } from '@/api/brand'
import contactFrom from '@/components/ContactForm'
import storeVisit from '@/components/StoreVisit'
export default {
  components: {
    'brand-product': brandProduct,
    'contact-form': contactFrom,
    'store-visit':storeVisit
  },
  data() {
    return {
      url: process.env.VUE_APP_IMG_URL,
      loading: false,
      isStoreDialog:false,
      catId: null,
      detailData: {},
      imgurl: process.env.VUE_APP_IMG_URL,
      contactData: {},
      isDialog: false,
      productListByCat: [],
      region:window.localStorage.getItem("forRegion")
    }
  },
  computed:{
    webSide(){
      if(this.region == 'HK'){
        return 'website_tc'
      }else if(this.region == 'OS'){
        return 'website_en'
      }else{
        return 'website_sc'
      }
    }
  },
  created() {
    this.catId = this.$route.query.catId
    this.getDetail()
    this.getcontactVendors()
  },
  methods: {
    goProduct(cat_id) {
      this.$router.push(
        `/product?selectbrand=${this.detailData.brand.id}&selectcat=${cat_id}`
      )
    },
    getDetail() {
      this.loading = true
      getBrandDetail(this.catId).then((res) => {
        this.loading = false
        if (res.code == 200) {
          const pcat = []
          res.data.catMapped.map((el) => {
            if (res.data.productListByCat[el.id].data.length>0) {
              pcat.push({
                p_name: el.name,
                p_list: res.data.productListByCat[el.id].data,
                p_id:el.id
              })
            }
          })
          this.productListByCat = pcat
          this.detailData = res.data
        }
      })
    },
    showContactVendorForm() {
      this.isDialog = true
    },
    showStoreVisit(){
      this.isStoreDialog = true
    },
    vendorChange(val) {
      this.isDialog = val
    },
    storeChange(val) {
      this.isStoreDialog = val
    },
    getcontactVendors() {
      getcontactVendor(this.catId).then((res) => {
        this.contactData = res.data
      })
    },
  },
}
</script>

<style scoped lang="scss">
.content {
  padding: 25px 0 40px;
  overflow-x: hidden;
  .name {
    padding: 20px 0px 15px;
    font-size: 21px;
  }
  .brand-details-div {
    max-width: 1530px;
    margin: 0 auto 40px;
    padding: 0 15px;
    @media (max-width: 767px) {
      display: flex;
      flex-wrap: wrap;
    }
    .brand-img {
      position: relative;
      float: left;
      min-height: 1px;
      padding-right: 20px;
      /*padding-left: 15px;*/
      width: 50%;
      @media (max-width: 767px) {
        width: 100%;
        order: 2;
        padding: 0 0 35px 0;
      }
    }
    .brand-basic-info {
      position: relative;
      min-height: 1px;
      /*padding-right: 15px;*/
      margin-left: 50%;
      padding-left: 20px;
      position: unset;
      /*padding-bottom: 100px;
      flex: 1;*/
      width: auto;
      max-width: 1000px;
      @media (max-width: 767px) {
        width: 100%;
        order: 1;
        margin-top: -20px;
        margin-left: 0;
        padding-left: 0;
        text-align: center;
      }
      .brand-logo {
        margin-bottom: 28px;
        display: inline-block;
        @media (max-width: 767px) {
          margin-bottom: 38px;
        }
        img {
          max-width: 260px;
          max-height: 82px;
        }
      }
    }
    .brand-info {
      position: relative;
      min-height: 1px;
      /*padding-right: 15px;*/
      margin-left: 50%;
      padding-left: 20px;
      position: unset;
      padding-bottom: 100px;
      /*flex: 1;*/
      width: auto;
      max-width: 1000px;
      @media (max-width: 767px) {
        width: 100%;
        order: 3;
        margin-left: 0;
        padding-left: 0;
      }
      h1 {
        font-size: 25px;
        margin-bottom: 0;
      }
      .brand-cat {
        margin: 3px 0px 28px 0;
        li {
          display: inline-block;
          width: auto;
          font-size: 12px;
          font-weight: normal;
          color: #999999;
          border-right: 1px solid #cccccc;
          padding-right: 8px;
          margin-right: 7px;
          line-height: 12px;
          &:last-child {
            border: none;
            margin-right: 0px;
            padding-right: 0px;
          }
          span,
          a {
            color: #999;
          }
        }
      }
      .brand-links {
        position: absolute;
        bottom: 0px;
        li {
          margin-top: 3px;
          .fa {
            display: inline-block;
            min-width: 13px;
            text-align: center;
          }
        }
        li * {
          font-size: 12px;
          color: #555555;
        }
      }
    }
  }
  /deep/ .el-dialog {
    max-height: 80%;
    overflow: hidden;
  }
  /deep/ .el-dialog__header {
    padding: 20px 20px 20px;
  }
  /deep/ .el-dialog__body {
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    /*max-height: 400px;*/
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
  /deep/ .el-dialog__headerbtn {
    top: 5px;
    right: 10px;
    font-weight: bold;
  }

  .relProducts {
    max-width: 1515px;
    margin: 30px auto 0;
    padding: 0 7.5px;
  }
}
.cat-to-product:hover {
  cursor: pointer;
  color: #c63033 !important;
}
</style>
