<template>
  <div>
    <div class="pdp_info ft12 pdb10">
      <div class="pdp_title" v-if="inputmode == 0">
        <div class="brand_name_p" v-if="detailData.productInfo.brandInfo.id != 25">
          <label>{{ $t('productfilter.Brand') }}:</label>
          <h1>{{ detailData.productInfo.brandInfo.name }}</h1>
        </div>
        <div
          class="series_name_p"
          v-if="detailData.product.series1 != 168"
        >
          <label>{{ $t('productfilter.Series') }}:</label>
          <h1>{{ detailData.product.brand_series.name }}</h1>
        </div>
      </div>
      <div class="product-name-region flex-between">
        <h1 class="item_name_p">{{ detailData.product.name }}</h1>
        <div
          class="btn_wishlist pull-right"
          @click.once="addProductWish(detailData.product.id)"
        >
          <div class="text-center">
            <i
              class="fa popup-heart"
              :class="
                detailData.product.added_wish_count == 1
                  ? 'fa-heart'
                  : 'fa-heart-o '
              "
            ></i>
            <span></span>
          </div>
        </div>
      </div>
      <div class="itemFullCode ft11 mgb7" v-if="detailData.product.code">
        {{ detailData.product.code }}
      </div>
      <div class="price_p" v-if="subopt.price && inputmode != 1">
        <span style="font-size: 12px; font-weight: bold"
          >{{ $t('productfilter.retailprice') }}:</span
        >
        <br />

        <span
          id="priceVal"
          class="priceVal"
          v-loading="priceLoading"
          element-loading-spinner="el-icon-loading"
          >{{ subopt.price }}</span
        >
        <span>/{{ detailData.unit }}</span>
      </div>
      <div
        class="pdp_remark"
        style="font-size: 13px"
        v-if="detailData.product[detailData.remarkName]"
        v-html="
          detailData.product[detailData.remarkName].replace(/\r\n/g, '<br/>')
        "
      ></div>
      <div class="tax-ship mgt10">
        <span v-if="detailData.product.designer == 1">
          <i
            class="fa fa-handshake-o"
            :title="$t('productfilter.brand_products')"
          ></i>
          {{ $t('productfilter.brand_products') }}
        </span>
        <span v-else-if="detailData.productInfo.brandInfo.brand_tag == 1">
          <i class="fa fa-handshake-o" :title="$t('productfilter.strategic_products')"></i>
          {{ $t('productfilter.strategic_products') }}
        </span>
        <span v-else-if="detailData.productInfo.brandInfo.brand_tag == 2">
          <i
            class="fa fa-handshake-o"
            :title="$t('productfilter.recommended_products')"
          ></i>
          {{ $t('productfilter.recommended_products') }}
        </span>
        <span v-else-if="detailData.productInfo.brandInfo.brand_tag == 3">
          <i
            class="fa fa-handshake-o"
            :title="$t('productfilter.registered_products')"
          ></i>
          {{ $t('productfilter.registered_products') }}
        </span>

        <span v-if="subopt.subOpts[0].incShip">
          <i
            class="fa fa-truck"
            :title="$t('productfilter.inc_ship_cost')"
          ></i>
          {{ $t('productfilter.inc_ship_cost') }}
        </span>

        <span v-if="subopt.subOpts[0].incVat">
          <i
            class="icon-tax"
            :title="$t('productfilter.inc_vat')"
          ></i>
          {{ $t('productfilter.inc_vat') }}
        </span>

      </div>
      <div
        class="color_p mgt10"
        v-if="detailData.productOpts && detailData.productOpts.length > 1"
      >
        <div v-if="detailData.productOpts[0].typename_en == 'Color'">
          <div>
            <label>{{ $t('productfilter.Color') }}:</label>&nbsp;
          </div>
          <div class="item_color">
            <ul class="flex flex-wrap">
              <li
                class="flex"
                :class="selectColor == index ? 'selected' : ''"
                v-for="(item, index) in detailData.productOpts"
                :key="index"
                @click="changColor(item, index)"
              >
                <div class="colorBlock" v-if="item.pattern_img">
                  <img :src="url + item.pattern_img" />
                </div>
                <div
                  class="colorBlock"
                  v-if="item.color_code && !item.pattern_img"
                  :style="`background:#${item.color_code}`"
                >
                  <a href=""></a>
                </div>
                <div class="colorName">{{ item[detailData.subOptName] }}</div>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="detailData.productOpts[0].typename_en == 'Material'">
          <div>
            <label>{{ detailData.productOpts[0][detailData.typeName] }}:</label
            >&nbsp;
          </div>
          <div class="item_color">
            <ul class="flex flex-wrap">
              <li
                class="flex"
                :class="selectColor == index ? 'selected' : ''"
                v-for="(item, index) in detailData.productOpts"
                :key="index"
                @click="changeMaterial(item, index)"
              >
                <div
                  class="colorBlock"
                  :style="`background:#${item.color_code}`"
                >
                  <a href=""></a>
                </div>
                <div class="colorName">{{ item[detailData.subOptName] }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- item size -->
      <div class="size_p mgt10">
        <div
          v-if="
            detailData.productSubOpts &&
            detailData.productSubOpts.length > 1 &&
            subopt.subOpts
          "
        >
          <div>
            <label>{{ subopt.subOpts[0][detailData.typeName] }}:</label>&nbsp;
          </div>
          <div class="item_size">
            <ul class="flex flex-wrap">
              <li class="flex">
                <el-checkbox-group v-model="checkListOpt">
                  <el-checkbox
                    @change="checkBoxEvent(item, index)"
                    v-for="(item, index) in subopt.subOpts"
                    :key="index"
                    :label="index"
                    >{{ item[detailData.subOptName] }}</el-checkbox
                  >
                </el-checkbox-group>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion-flush tl">
      <el-collapse accordion class="addtobag" v-model="activeNames">
        <el-collapse-item name="1">
          <template slot="title">
            <div class="accordion-header flex-between">
              <span>{{ $t('productfilter.contactinfo') }}</span>
            </div>
          </template>
          <div class="accordion-collapse">
            <div
              class="accordion-body"
              :class="inputmode == 0 ? 'displaytable' : ''"
            >
              <div class="pdp_code">
                <div class="pdr15 pdb3">{{ detailData.defVenInfo.name }}</div>
                <div class="pdb3">
                  {{ $t('productfilter.Contactperson') }}:
                  {{ detailData.defVenInfo.contact_person }}
                </div>
              </div>
              <div class="pdp_code">
                <div class="pdr15">
                  {{ $t('productfilter.Telephone') }}:{{
                    detailData.defVenInfo.tel_1
                  }}
                </div>
                <div>
                  {{ $t('productfilter.Email') }}:{{
                    detailData.defVenInfo.email
                  }}
                </div>
              </div>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item name="2">
          <template slot="title">
            <div class="accordion-header">
              <span>{{ $t('productfilter.Vendor') }}</span>
            </div>
          </template>
          <div class="accordion-collapse">
            <div class="pdp_request">
              <div
                class="multiselect mgb15"
                @click.stop="programChecked = !programChecked"
              >
                <b-button variant="outline-secondary">{{
                  submitData.project.name == ''
                    ? $t('productfilter.projectNum')
                    : submitData.project.name
                }}</b-button>
                <b class="caret"></b>
              </div>
              <ul class="multiselect-container" v-show="programChecked">
                <li class="multiselect-item multiselect-filter">
                  <div class="input-group">
                    <span class="input-group-addon">
                      <i class="fa fa-search"></i>
                    </span>

                    <b-form-input
                      class="form-control multiselect-search"
                      id="input-small"
                      size="sm"
                      placeholder="Search"
                      @input="getSearch"
                    ></b-form-input>

                    <span class="input-group-btn">
                      <button
                        class="btn btn-default multiselect-clear-filter"
                        type="button"
                        @click="removeSearch"
                      >
                        <i class="fa fa-times-circle-o"></i>
                      </button>
                    </span>
                  </div>
                </li>
                <li class="muiselect-otheritem flex">
                  <b-form-radio name="plain-inline" disabled
                    >&nbsp;&nbsp;&nbsp;{{
                      $t('productfilter.projectNum')
                    }}</b-form-radio
                  >
                </li>
                <li
                  class="muiselect-otheritem flex"
                  v-for="(item, index) in projectList"
                  :key="index"
                >
                  <b-form-radio
                    name="plain-inline"
                    :value="item.id"
                    stacked
                    @change="getProgramRadio"
                    >&nbsp;&nbsp;&nbsp;{{
                      item.versionwithzero > 0
                        ? item.project_no + '-' + item.versionwithzero
                        : item.project_no
                    }}{{
                      item.project_type != null ? '-' + item.project_type : ''
                    }}
                    {{ item.name }}</b-form-radio
                  >
                </li>
              </ul>
            </div>
            <div class="pdp_checkbox mgb15 flex flex-wrap">
              <b-form-checkbox
                v-model="programselected"
                :value="item.type"
                v-for="(item, index) in $t('programOptions')"
                :key="index"
                @change="getprojectType"
                >&nbsp;{{ item.text }}</b-form-checkbox
              >
            </div>
            <div class="mgb15">
              <b-form-textarea
                class="ft14"
                v-model="submitData.msg"
                :placeholder="$t('productfilter.msg')"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </div>
            <div class="attachment mgb15" @click="showUploadDialog">
              <span
                class="fa fa-paperclip"
                style="
                  font-size: 16px;
                  vertical-align: text-bottom;
                  margin-left: 1px;
                  margin-right: 11px;
                "
              ></span>
              {{ $t('productfilter.Attachment') }}
            </div>

            <div class="attachment mgb15 posiR">
              <div @click.stop="cooperatecheck = !cooperatecheck">
                <span
                  class="fa fa-user-plus"
                  style="
                    font-size: 16px;
                    vertical-align: text-bottom;
                    margin-left: 1px;
                    margin-right: 11px;
                  "
                ></span>
                <span class="multiselect-selected-text" v-if="allSelected">{{
                  allSelected
                    ? $t('productfilter.checked') +
                      '(' +
                      cooperateselected.length +
                      ')'
                    : $t('productfilter.Toemail')
                }}</span>
                <span class="multiselect-selected-text" v-else>
                  <span v-if="cooperateselected.length == 0">{{
                    $t('productfilter.Toemail')
                  }}</span>
                  <span v-else-if="cooperateselected.length < 4">
                    <em
                      v-for="(cooper, index) in cooperateselectedText"
                      :key="index"
                      >{{ cooper }}</em
                    >
                  </span>
                  <span v-else
                    >{{ cooperateselected.length }}
                    {{ $t('productfilter.checkednum') }}</span
                  >
                </span>
              </div>
              <ul class="multiselect-container" v-show="cooperatecheck">
                <b-form-group>
                  <li class="muiselect-otheritem">
                    <b-form-checkbox
                      v-model="allSelected"
                      @change="toggleAll"
                      aria-describedby="flavours"
                      aria-controls="flavours"
                      >{{ $t('productfilter.checked') }}</b-form-checkbox
                    >
                  </li>
                  <template>
                    <b-form-checkbox-group
                      id="flavors"
                      v-model="cooperateselected"
                      :options="cooperateList"
                      name="flavors"
                      value-field="value"
                      text-field="text"
                      class="ft12"
                      @change="getCooper"
                    ></b-form-checkbox-group>
                  </template>
                </b-form-group>
              </ul>
            </div>
            <div class="pdp_wechat mgb10">
              <b-form-input
                v-model="submitData.mobileNo"
                :placeholder="$t('productfilter.Myphone')"
              ></b-form-input>
              <i class="fa fa-mobile"></i>
            </div>
            <div class="pdp_wechat mgb10">
              <b-form-input
                v-model="submitData.wechatNo"
                :placeholder="$t('productfilter.Wechat')"
              ></b-form-input>
              <i class="fa fa-weixin"></i>
            </div>
            <div class="mgb10">
              <b-form-datepicker
                id="example-datepicker"
                v-model="submitData.deadline"
                :placeholder="$t('productfilter.time')"
                class="mb-2 ft14"
              ></b-form-datepicker>
            </div>
            <div class="btn_addtobag flex-between">
              <button type="button" class="btn" @click.stop="saveAddequiry(1)">
                {{ $t('productfilter.Addequiry') }}
              </button>
              <button type="button" class="btn" @click.stop="saveAddequiry(2)">
                {{ $t('productfilter.Send') }}
              </button>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
      <div v-if="detailData.product.file_cad || detailData.product.file_ppt || detailData.product.file_spec || detailData.product.file_3d" class="download-section flex">
        <a
          class="option"
          :href="url + detailData.product.file_cad"
          target="_blank"
          v-if="detailData.product.file_cad"
        >
          <em> <i class="fa fa-download"></i> CAD </em>
        </a>
        <a
          class="option"
          v-if="detailData.product.file_ppt"
          target="_blank"
          :href="url + detailData.product.file_ppt"
        >
          <em> <i class="fa fa-download"></i> PPT </em>
        </a>
        <a
          class="option"
          v-if="detailData.product.file_spec"
          target="_blank"
          :href="url + detailData.product.file_spec"
        >
          <em> <i class="fa fa-download"></i> SPEC </em>
        </a>
        <a
          class="option"
          v-if="detailData.product.file_3d"
          target="_blank"
          :href="url + detailData.product.file_3d"
        >
          <em> <i class="fa fa-download"></i> 3D </em>
        </a>
      </div>
      <div
        class="mgb20 flex mgt15 ft13"
        style="color: #aaaaaa"
        v-if="inputmode == 0"
      >
        <div class="outOfStock mgr30" v-if="detailData.product.prod_lead_time">
          {{ $t('productfilter.Leadtime') }}:
          {{ detailData.product.prod_lead_time }} {{ $t("productfilter.Day") }}
        </div>
        <div>
          {{ $t('productfilter.Lastupdate') }}:
          {{ subopt.subOpts[0].updated_at | formatCreateTime }}
        </div>
      </div>
      <b-button
        v-if="type == 1 && !inputmode"
        @click="
          goPage(
            detailData.product.id,
            detailData.product.opt_id,
            detailData.product.subopt_id,
            hlStatus
          )
        "
        class="wid100Per ft14"
        style="
          background: #e0e0e0;
          border-color: #dddddd;
          color: #555;
          height: 42px;
        "
        >{{ $t('productfilter.moreDatail') }}</b-button
      >
    </div>

    <alert-tip
      v-if="alertShow"
      :type="alertType"
      :add-type="addType"
      @alertChange="closealertChange"
    ></alert-tip>
  </div>
</template>

<script>
import { addWish, informs, getProductsubopt } from '@/api/product'
import { formatDate } from '@/utils/date'
import alertTip from '@/components/AlertTip'
export default {
  components: {
   'alert-tip': alertTip,
  },
  data() {
    return {
      checkListOpt: [0],
      programselected: [3],
      programChecked: false,
      allSelected: false,
      cooperatecheck: false,
      cooperateselected: [],
      cooperateselectedText: [],
      cooperateList: [],
      value: '',
      selectColor: 0,
      wishStatus: false,

      submitData: {
        venID: 1,
        project: {
          id: '',
          project_no: '',
          versionwithzero: '',
          project_type: '',
          name: '',
          client: '',
          currency_id: '',
        },
        incPName: 0,
        incCName: 0,
        catName: '',
        brandName: '',
        seriesName: '',
        productsToAdd: [],
        msg: '',
        mobileNo: '',
        wechatNo: '',
        requestSample: 0,
        requestProposal: 0,
        deadline: '',
        orderId: '',
      },
      activeNames: ['1'],
      url: process.env.VUE_APP_IMG_URL,
      hl: null,
      alertType: '',
      alertShow: false,
      addType: 'product',
      projectList: [],
      newDetailData: '',
    }
  },
  props: {
    dialogVisible: {
      String,
    },
    priceLoading: {
      type: Boolean,
      default: false,
    },
    detailData: {
      type: Object,
    },
    subopt: {
      type: Object,
    },
    inputmode: {
      type: Number,
      default: 0,
    },
    hlStatus: {
      type: String,
    },
    type: {
      type: Number,
    },
  },
  created() {
    let arr = []
    for (var i in this.detailData.cooperateList) {
      let o = {}
      ;(o['value'] = i), (o['text'] = this.detailData.cooperateList[i])
      arr.push(o)
    }
    this.cooperateList = arr
    this.submitData.mobileNo =
      this.detailData.sender.mobile1 == null
        ? ''
        : this.detailData.sender.mobile1
    this.submitData.wechatNo =
      this.detailData.sender.wechat_no == null
        ? ''
        : this.detailData.sender.wechat_no
    this.submitData.venID =
      this.detailData.defVenInfo == undefined
        ? 1
        : this.detailData.defVenInfo.id
    this.projectList = this.detailData.projects
    if (this.detailData.productOpts[0].pattern_img) {
      this.detailData.productOpts.map((el, index) => {
        if (this.detailData.selectedOpt == el.id) {
          this.selectColor = index
        }
      })
    }
  },
  watch: {
    hlStatus() {
      this.hl = this.hlStatus
    },
    dialogVisible(value) {
      console.log(value)
      if (!value) {
        this.selectColor = 0
        this.checkListOpt = [0]
      } else {
        if (this.detailData.productOpts[0].pattern_img) {
          this.detailData.productOpts.map((el, index) => {
            if (this.detailData.selectedOpt == el.id) {
              this.selectColor = index
            }
          })
        } 
      }
    },
  },
  filters: {
    formatCreateTime(time) {
      let date = new Date(time)
      return formatDate(date, 'yyyy/MM/dd')
    },
  },
  methods: {
    getProgramRadio(e) {
      for (let i = 0; i < this.detailData.projects.length; i++) {
        if (e == this.detailData.projects[i].id) {
          this.submitData.project.id = this.detailData.projects[i].id
          this.submitData.project.project_no =
            this.detailData.projects[i].project_no
          this.submitData.project.versionwithzero =
            this.detailData.projects[i].versionwithzero
          this.submitData.project.project_type =
            this.detailData.projects[i].project_type
          this.submitData.project.name = this.detailData.projects[i].name_en
          this.submitData.project.client = this.detailData.projects[i].cname_en
          this.submitData.project.currency_id =
            this.detailData.projects[i].contract_currency
        }
      }
      this.programChecked = !this.programChecked
    },
    getprojectType(e) {
      for (let key in e) {
        if (e[key] == 1) {
          this.submitData.incPName = 1
        } else {
          this.submitData.incPName = 0
        }
        if (e[key] == 2) {
          this.submitData.incCName = 1
        } else {
          this.submitData.incCName = 0
        }
        if (e[key] == 3) {
          this.submitData.requestSample = 1
        } else {
          this.submitData.requestSample = 0
        }
        if (e[key] == 4) {
          this.submitData.requestProposal = 1
        } else {
          this.submitData.requestProposal = 0
        }
        // switch (e[key]) {
        //     case 1:
        //     this.submitData.incPName = 1;
        //     break;
        //     case 2:
        //     this.submitData.incCName = 1;
        //     break;
        //     case 3:
        //     this.submitData.requestSample = 1;
        //     break;
        //     case 4:
        //     this.submitData.requestProposal = 1;
        //     break;
        // }
      }
    },
    toggleAll(checked) {
      if (checked) {
        this.cooperateList.forEach((item) => {
          this.cooperateselected.push(item.value)
        })
      } else {
        this.cooperateselected = []
      }
    },
    getCooper(e) {
      for (let n in this.cooperateList) {
        for (let i in e) {
          if (e[i] == this.cooperateList[n].value) {
            this.cooperateselectedText.push(this.cooperateList[n].text)
          }
        }
      }
    },

    //add wish
    addProductWish(id) {
      addWish({ pid: id }).then((res) => {
        if (res.code == 200) {
          this.$message.success('Add collection succeeded!')
          this.detailData.product.added_wish_count = 1
        }
      })
    },
    saveAddequiry(itemtype) {
      this.submitData.catName = this.detailData.cat.name
      this.submitData.brandName = this.detailData.productInfo.brandInfo.name
      this.submitData.seriesName =
        this.detailData.productInfo.brandInfo.seriesName

      if (this.programselected.includes(1)) {
        this.submitData.incPName = 1
      }
      if (this.programselected.includes(2)) {
        this.submitData.incCName = 1
      }
      if (this.programselected.includes(3)) {
        this.submitData.requestSample = 1
      }
      if (this.programselected.includes(4)) {
        this.submitData.requestProposal = 1
      }

      let itemID = this.detailData.product.id
      let itemQty = 1
      let opt_id = this.detailData.product.opt_id
      let subopt_id = this.detailData.product.subopt_id
      let code =
        this.detailData.product.code +
        '-' +
        this.detailData.product.opt_code +
        '-' +
        this.detailData.product.opt_codesubopt_code
      let remark = this.submitData.msg
      let fcode = 'underfind'
      let belongArea = 'underfind'
      let valCombined =
        itemID +
        '::' +
        itemQty +
        '::' +
        code +
        '::' +
        opt_id +
        '::' +
        subopt_id +
        '::' +
        fcode +
        '::' +
        belongArea +
        '::' +
        remark
      this.submitData.productsToAdd.push(valCombined)
      this.$emit('submitData', this.submitData)

      //
      if (itemtype == 1) {
        if (this.verification()) {
          if (
            this.detailData.sender.mobile1 == null &&
            this.detailData.sender.wechat_no == null
          ) {
            this.$emit('showConfirmDialog', true)
          } else {
            this.alertShow = true
            informs(this.submitData).then((res) => {
              if (res.code == 200) {
                // this.$message.success("增加查询清单成功！");
                this.alertShow = false
                this.alertType = 'success'
              }
            })
          }
        }
      } else {
        if (this.sendverification()) {
          // this.send1Dialog = true;
          this.$emit('showsend1Dialog', true)
        }
      }
    },
    showUploadDialog() {
      this.$emit('upload-dialog', true)
    },

    verification() {
      let alertmsg = this.$t('productfilter.contact_alert_select_project')
      if (this.submitData.project.id == '') {
        this.$message.error(alertmsg)
        return
      }
      return true
    },
    sendverification() {
      let alertmsg = this.$t('productfilter.contact_alert_select_project')
      if (this.submitData.project.id == '') {
        this.$message.error(alertmsg)
        return
      }

      if (this.submitData.msg == '') {
        this.$message.error('請輸入查詢內容')
        return
      }

      return true
    },
    changColor(item, inx) {
      let imgurl = []
      let obj = {}
      obj.path = item.pattern_img
      imgurl.push(obj)
      this.selectColor = inx
      this.$emit('change-color', imgurl, item)
    },
    changeMaterial(item, inx) {
      this.selectColor = inx
      if (this.checkListOpt.length > 0) {
        this.checkListOpt = []
      }
      this.checkListOpt.push(0)
      this.$emit('changeMaterial', item)
    },
    checkBoxEvent(val, index) {
      if (this.checkListOpt.length > 0) {
        this.checkListOpt = []
      }
      this.checkListOpt.push(index)
      if (val.priceInq == 0) {
        this.subopt.price = val.price
      } else {
        this.subopt.price = this.$t('productfilter.product_tbc')
      }
      this.$emit('checkBoxEvent', val)
    },
    goPage(id, optID, subopt_id, hl) {
      // let hlVal = hl=='undefined'?'':hl;
      this.$router.push(
        `/productdetail?id=${id}&optID=${optID}&subOptID=${subopt_id}`
      )
    },
    getSearch(e) {
      let _search = e.toLowerCase()
      let newListData = []
      if (_search) {
        this.projectList.filter((item) => {
          if (
            (item.project_no + item.name).toLowerCase().indexOf(_search) !== -1
          ) {
            newListData.push(item)
          }
        })
        this.projectList = newListData
      } else {
        this.projectList = this.detailData.projects
      }
    },
    removeSearch() {
      this.projectsearchValue = ''
      this.projectList = this.detailData.projects
    },
    closealertChange(val) {
      this.alertShow = val
    },
  },
}
</script>

<style lang="scss" scoped>
.pdp_info {
  font-size: 14px;
  text-align: left;
  .pdp_title {
    overflow: hidden;
    margin-bottom: 10px;
    label {
      font-weight: 700;
      font-size: 12px;
      display: inline;
      margin-right: 5px;
    }
    h1 {
      font-weight: normal;
      font-size: 1em;
      padding: 0px;
      text-transform: uppercase;
      margin: 0;
      width: auto;
      display: inline;
    }
    .brand_name_p {
      display: inline-block;
      line-height: 15px;
      margin-right: 22px;
    }
    .series_name_p {
      display: inline-block;
      line-height: 15px;
      margin-left: -11px;
      border-left: 1px solid #cccccc;
      padding-left: 10px;
    }
  }
  .product-name-region {
    align-items: flex-start;
    .item_name_p {
      font-size: 26px;
      // padding: 0 0 2px 0;
      margin-bottom: 2px;
      margin-top: 0px;
      /* font-weight: bold; */
      text-align: left;
      word-break: break-word;
    }
    .btn_wishlist {
      padding: 0px 0 0 10px;
      .text-center {
        border-radius: 50%;
        border: 1px solid #e0e0e0;
        background: #f3f3f3;
        width: 35px;
        height: 35px;
        display: block;
        color: #555555;
        cursor: pointer;
        line-height: 35px;

        &:hover {
          background: #e0e0e0;
          border: 1px solid #dddddd;
        }
        i {
          font-size: 16px;
        }
      }
    }
  }
  .itemFullCode {
    color: #aaaaaa;
  }
  .price_p {
    font-size: 1.5em;
    padding: 5px 0;
  }
  .tax-ship {
    display: inline-block;
    font-size: 13px;
    font-weight: normal;
    /* font-style: italic; */
    color: #aaaaaa;
    clear: both;
    margin-right: 3px;
    i {
      color: #aaaaaa;
      margin-right: 3px;
    }
    .fa-truck {
      font-size: 15px;
    }
    span {
      margin-right: 10px;
    }
  }
  .color_p,
  .size_p {
    label {
      line-height: 20px;
      font-weight: bold;
    }
    .item_color,
    .item_size {
      li {
        max-width: 49%;
        margin: 10px 1% 0 0;
        flex: auto;
        min-width: 120px;
      }
      .colorBlock {
        width: 30px;
        height: 30px;
        border: 1px solid #dddddd;
        padding: 1px;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .selected {
        .colorBlock {
          border-color: #555;
        }
      }
      .colorName {
        padding: 0 5px;
      }
    }
  }
}
.accordion-flush {
  // border-top: 1px solid #dddddd;
  margin-top: 20px;
  .addtobag {
    // border-top: 1px solid #dddddd;
    .accordion-header {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      color: #555;
      // padding: 18px 0 16px;
      font-size: 18px;
      text-align: left;
      // background-color: #fff;
      border: 0;
      border-radius: 0;
      overflow-anchor: none;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        border-radius 0.15s ease;
    }
    .accordion-collapse {
      .displaytable {
        display: table;
        .pdp_code {
          display: table-row;
          font-size: 14px;
          > div {
            display: table-cell;
          }
        }
      }
      .accordion-body {
        padding: 0;
        margin-bottom: 15px;
        .pdp_code {
          font-size: 14px;
        }
      }
      .attachment {
        -webkit-appearance: none;
        background-color: #fff;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        height: 40px;
        line-height: 40px;
        outline: 0;
        padding: 0 15px;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 100%;
        &:hover {
          color: #333;
          background-color: #e6e6e6;
          border-color: #adadad;
        }
      }
      .pdp_wechat {
        position: relative;
        .fa {
          position: absolute;
          top: 12px;
          left: 12px;
          font-size: 16px;
          width: 16px;
          text-align: center;
          line-height: 18px;
        }
        .fa-mobile {
          font-size: 20px;
        }
        /deep/ .form-control {
          padding-left: 35px;
          font-size: 14px;
        }
      }
      .pdp_request {
        position: relative;
        .multiselect {
          width: 100%;
          position: relative;
          /deep/ .btn {
            width: 100%;
            text-align: left;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            -webkit-tap-highlight-color: transparent;
            font-size: 14px;
            border-color: #dcdfe6;
            &:hover {
              background-color: #e6e6e6;
              border-color: #adadad;
              color: #333;
            }
          }
          .caret {
            display: inline-block;
            width: 0;
            height: 0;
            margin-left: 2px;
            vertical-align: middle;
            border-top: 4px solid;
            border-right: 4px solid transparent;
            border-left: 4px solid transparent;
            margin-left: 0;
            position: absolute;
            top: 50%;
            right: 12px;
            margin-top: -2px;
            vertical-align: middle;
          }
        }
        .open {
          /deep/ .btn {
            background-color: #e6e6e6;
            border-color: #adadad;
          }
        }
      }
      .btn_addtobag {
        margin-bottom: 20px;
        .btn {
          width: 48%;
          color: #fff;
          background-color: #5cb85c;
          border-color: #4cae4c;
          font-size: 14px;
          &:hover {
            background-color: #449d44;
            border-color: #398439;
          }
        }
      }
      .pdp_checkbox {
        /deep/ .custom-control {
          width: 35%;
          margin-right: 20px;
        }
      }
    }
  }
  .download-section {
    margin-top: 17px;
    margin-bottom: 8px;
    border-radius: 4px;
    .option {
      font-size: 11px;
      line-height: 1;
      color: #ffffff;
      text-decoration: none;
      border: none;
      background: #aaaaaa;
      margin-right: 5px;
      /* padding: 7px 5px 6px; */
      padding: 6px 5px 5px;
      border-radius: 4px;
      i {
        color: #fff;
        transition: all 0.15s ease-in-out;
        -webkit-transition: all 0.15s ease-in-out;
        -moz-transition: all 0.15s ease-in-out;
        -o-transition: all 0.15s ease-in-out;
      }
      &:hover i {
        transform: translate(0, 2px);
        -webkit-transform: translate(0, 2px);
        -o-transform: translate(0, 2px);
        -moz-transform: translate(0, 2px);
      }
    }
  }
}
.multiselect-container {
  min-width: 200px;
  max-height: 50vh;
  overflow: scroll;
  overflow-x: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  // display: none;
  width: 100%;
  max-width: 100%;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
  li {
    .input-group {
      position: relative;
      margin: 5px;
      width: auto;
      .input-group-addon {
        padding: 6px 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 2;
        color: #555;
        text-align: center;
        background-color: #eee;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
      .input-group-btn {
        border: 1px solid #ccc;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
      }
    }
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }
  /deep/ .custom-control {
    font-size: 13px;
    color: #555555;
    padding: 0 20px;
    height: 22px;
    line-height: 22px;
    display: flex;
    align-items: center;
  }
  /deep/ .custom-control-label {
    margin-left: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
  }
}
/deep/ .el-checkbox-group {
  .is-checked + .el-checkbox__label {
    color: #555555 !important;
  }
}
.priceVal {
  /deep/ .el-loading-mask {
    position: absolute !important;
    .el-loading-spinner {
      margin-top: -15px;
      .el-icon-loading {
        color: #555555;
      }
    }
  }
}
</style>
