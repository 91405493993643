<template>
  <div class="content" v-loading="loading">
    <b-container fluid>
      <b-row class="deliverables">
        <b-col md="12">
          <div class="name mgb20 tc">
            <div>
              {{$t('designresinfo.standard_project_doc')}}
            </div>
            <div class="coCat tc mgt10">
              <el-select popper-append-to-body :style="{width:selectOptionWidth}" class="width125" v-model="select.sort" :placeholder="$t('Strateg.product_cat')" placeholder-color="#555" @change="getchangevalue">
                <el-option
                  v-for="item in sortOption"
                  :key="item.id"
                  @click.native="setOptionWidth"
                  :label="item.text"
                  :value="item.id">
                  <span style="float: left">{{ item.text }}</span>
                </el-option>
              </el-select>
            </div>

          </div>
          
        </b-col>
        <b-col md="12">
            
         <design-list :list-data="listData"></design-list> 
        </b-col>


    
      </b-row>
    </b-container>
  </div>
</template>

<script>
import designList from '@/components/Guideline/DesignList'
export default {
  components: {
    'design-list':designList
  },
  data(){
    return{
      selectOptionWidth:'125px',
      mid:3,
      listData:[
        {
          url:'/project-file',
          path:'https://res.steveleungdesign.com/drs/44/930/d780c34f72483ece5ddc364515d58e9b.jpg/s300',
          name:'SL2.0 匯報文檔格式'
        }
      ],
      loading:false,
      sortOption:[
        {
          id:null,
          text:'排序方式'
        },{
          id:2,
          text:'按更新時間降序'
        },{
          id:3,
          text:'按更新時間升序'
        },
      ],
      brandOption:[
        {
          id:null,
          text:'公司品牌'
        },{
          id:1,
          text:'SLA'
        },{
          id:2,
          text:'SLC'
        },{
          id:3,
          text:'SLW'
        },{
          id:4,
          text:'SLH'
        },{
          id:5,
          text:'SLL'
        }
      ],
      branchOption:[{
          id:null,
          text:'分公司'
        },{
          id:2,
          text:'香港總公司'
        },{
          id:3,
          text:'上海分公司'
      }],
      teamOption:[
        {
          id:null,
          text:'設計團隊'
        },{
          id:1,
          text:'(SLDBJ-B)'
        },{
          id:2,
          text:'(-EDL)'
        },{
          id:3,
          text:'(-Nil)'
        },{
          id:4,
          text:'Andy DING (DD)'
        }
      ],
      developerOption:[
        {
          id:null,
          text:'發展商'
        },{
          id:2,
          text:'1957 & Co. (Hospitality) Limited'
        },{
          id:3,
          text:'2013年北京設計周活動，北京室內設計協會'
        }
      ],
      locOption:[
        {
          id:null,
          text:'項目地點'
        },{
          id:2,
          text:'三亞'
        },{
          id:3,
          text:'上海'
        },{
          id:4,
          text:'中山'
        }
      ],
      opt_type:[
         {
          id:null,
          text:'項目類型'
        },{
          id:2,
          text:'企業/私人會所'
        },{
          id:3,
          text:'健康設計'
        },{
          id:4,
          text:'其他'
        }
      ],
      opt_area:[
        {
          id:null,
          text:'空間'
        },{
          id:2,
          text:'客飯廳'
        },{
          id:3,
          text:'飯廳'
        },{
          id:4,
          text:'書房'
        }
      ],
      sfw:[
        {
          id:null,
          text:'經營範圍'
        },{
          id:2,
          text:'活動家具'
        },{
          id:3,
          text:'裝飾燈具'
        },{
          id:4,
          text:'窗簾'
        }
      ],
      select:{
        sort:null,
        brand:null,
        branch:null,
        team:null,
        developer:null,
        loc:null,
        opt_type_id:null,
        opt_area_id:null,
        sfw:null

      }
    }
  },
  created(){
  

  },

  methods:{
    setOptionWidth(event){
      this.$nextTick(() => {
        if(event.target.localName == 'span'){
          this.nodeIndex = event.target.clientWidth + 30
        }else{
          this.nodeIndex = event.target.childNodes[0].clientWidth + 30
        }
        this.selectOptionWidth = this.nodeIndex + "px";
      });
    },
    goPage(url){
      this.$router.push(url)
    },
    getchangevalue(){

    }

  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 25px 0 40px;
  overflow-x: hidden;
  .name {
    padding: 20px 0px 20px;
    font-size: 21px;
  }
  
  
  
}
/deep/ .el-input__inner{
  border: none;
  font-size: 13px;
  color: #555;
  text-align: center;
  padding-left: 0;
}
.width125 /deep/ .el-input__inner{width:125px;}
.width59 /deep/ .el-input__inner{width:86px;}
.width72 /deep/ .el-input__inner{width:72px;}
.width86 /deep/ .el-input__inner{width:86px;}
.width73 /deep/ .el-input__inner{width:73px;}
.width60 /deep/ .el-input__inner{width:60px;}
.res-list {
    list-style: none;
    margin: 0 auto;
    /* max-width: 1100px; */
    width: 100%;
    padding: 0;
    text-align: center;
    text-align: justify;
    font-size: 0;
    margin-bottom: 50px;
    li{
      z-index: 0;
      display: inline-block;
      width: 19%;
      text-align: center;
      font-size: 15px;
      margin: 0px 0px 1% 0;
      padding: 15px;
      vertical-align: top;
      position: relative;
      @media (max-width: 1500px){
        width: 20%;
      }
      @media (max-width: 1023px){
        width: 33%;
      }
      @media (max-width: 767px) {
        margin: 0 0 20px 0;
        padding: 0 2%;
      }
      @media (max-width: 567px) {
        width: 50%;
      }
      .img-link {
        position: relative;
        padding: 0px;
        display: block;
        &:before {
          content: "";
          display: block;
          padding-top: 100%;
          overflow: hidden;
          background: #f2f2f2;
        }
        .mood-img {
          position: absolute;
          /* display: flex; */
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          overflow: hidden;
          img {
            display: block;
            min-width: 100%;
            min-height: 100%;
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            margin: auto 0;
            object-fit: cover;
            transition: transform 1s;
            transition-timing-function: ease;
          }
        }
      }
      &:hover{
        .img-link {
          img{
            transform: scale(1.1);
          }
        }
      }
      h2 {
        margin: 0;
        padding: 15px 0 8px;
        font-size: 18px;
        max-width: 100%;
        line-height: 26px;
        @media (max-width: 1023px) {
          padding: 15px 0 8px;
        }
        @media (max-width: 567px) {
          font-size: 15px;
        }
        @media (max-width: 375px) {
          font-size: 13px;
        }
      }
      .info {
        /* margin-top: 15px; */
        font-size: 10px;
        font-weight: normal;
        color: #999999;
        span {
          display: inline-block;
          color: #999999;
          margin: 0 3px 5px;
          &:first-child {
            display: block;
          }
        }
      }
    }
}
</style>