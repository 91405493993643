import { render, staticRenderFns } from "./designguideline.vue?vue&type=template&id=466eee38&scoped=true"
import script from "./designguideline.vue?vue&type=script&lang=js"
export * from "./designguideline.vue?vue&type=script&lang=js"
import style0 from "./designguideline.vue?vue&type=style&index=0&id=466eee38&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "466eee38",
  null
  
)

export default component.exports