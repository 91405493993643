var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"content"},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"consultant-details"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"name mgb20 mgt20 tc ft12"},[_c('router-link',{staticClass:"item",attrs:{"to":{ path: '/supplier-cats?name=' + encodeURIComponent(_vm.name) }}},[_vm._v(_vm._s(_vm.name)+" /")]),(_vm.query.catId != 3)?_c('router-link',{staticClass:"item",attrs:{"to":{ path: '/supplier?id=' + _vm.query.catId + '&name=' + encodeURIComponent(_vm.name) }}},[_vm._v(_vm._s(_vm.catName)+" /")]):_vm._e(),(_vm.query.catId == 3)?_c('router-link',{staticClass:"item",attrs:{"to":{
              path: '/supplier-cats?catId=' + _vm.query.catId + '&name=' + encodeURIComponent(_vm.name),
            }}},[_vm._v(_vm._s(_vm.catName)+" /")]):_vm._e(),_c('router-link',{staticClass:"item",attrs:{"to":{
              path:
                '/supplier-cats?catId=' +
                _vm.query.catId +
                '&id=' +
                _vm.query.supplieId +
                '&name=' +
                _vm.name,
            }}},[_vm._v(_vm._s(_vm.suppliecatName)+" ")])],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"brand-info"},[_c('h1',[_vm._v(_vm._s(_vm.detailData.name))]),(_vm.detailData.desc != null)?_c('div',{staticClass:"brand-company"},[_vm._v(" "+_vm._s(_vm.detailData.desc)+" ")]):_vm._e()]),_c('div',{staticClass:"supplier-brand"},[_c('h4',{staticClass:"mgb60 ft18"},[_vm._v(_vm._s(_vm.$t('supplierfilter.product_brand')))]),(_vm.brandsData.length > 0 && _vm.brandsData != '')?_c('ul',{staticClass:"brand-list"},_vm._l((_vm.brandsData),function(item,index){return _c('li',{key:index},[_c('div',{staticClass:"brand-photo"},[_c('div',{staticClass:"brand-photo-con"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.url + item.logo_path),expression:"url + item.logo_path"}],staticClass:"b-lazy b-error"})])]),_c('div',{staticClass:"brand-title"},[_c('h2',[_vm._v(_vm._s(item.name))])])])}),0):_c('div',{staticClass:"text-center ft18",staticStyle:{"color":"gainsboro"}},[_vm._v(" "+_vm._s(_vm.$t('supplierfilter.nodata'))+" ")])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }