<template>
  <div class="auth-bg">
    <b-container class="login">
        <div class="login-wrapper">
            <div class="login-content">
                <h2 class="mgb15" style="font-size:16px; letter-spacing:-0.5px;">
                       {{$t('common.resetPassword')}}</h2>
                <b-form @submit="onSubmit" >
                 <b-form-input type="email" required autofocus class="input mb-3" v-model="userEmail" :placeholder="$t('common.email')"></b-form-input>
                 <b-alert show variant="success" v-if="ismessage"> 邮件发送成功</b-alert>
                 <b-row>
                     <b-col class="tl">
                     </b-col>
                     <b-col class="tr">
                        <router-link to="/Login"> {{$t("common.message")}}</router-link>
                     </b-col>
                   
                 </b-row>
                 <b-button type="primary" class="login-btn mt-5 white" block size="sm"  :disabled="disabled">{{$t("common.action")}}</b-button>
                </b-form>
            </div>
            
        </div>
        <div class="lang text-center">
            <div :class="[localLan=='en'? 'active':'']" @click="changeLang('en')">EN</div>
            <div :class="[localLan=='sc'? 'active':'']" @click="changeLang('sc')">简</div>
            <div :class="[localLan=='tc'? 'active':'']" @click="changeLang('tc')">繁</div>
        </div>

    </b-container>
    <footer class="footer">
        <b-container>
            <p class="text-center">
			<img src="@/assets/images/logo2.png" height="12" style="margin-right:15px;">
			&copy; {{year}} Steve Leung Design Group Limited. {{$t('common.copyright')}}
			</p>
        </b-container> 
    </footer>
  </div>
</template>

<script>
import { setPassword } from '@/api/login'
import {getYears} from '@/utils/date.js'
// import { setToken } from '@/utils/auth'
export default {
    name:'reset-password',
    data(){
        return {
            userEmail:'',
            localLan:'tc',
            imgUrl:require("../assets/images/logo.png"),
            loading: true,
            year:getYears(),
            ismessage :false,
            disabled:false
        }


    },
    created() {
        this.localLan = localStorage.getItem("language");
       
    },
    methods: {
        onSubmit(event) {
            event.preventDefault();
            this.disabled = true;
            setPassword({email: this.userEmail}).then(res=>{
                if(res.code==200){
                    this.ismessage =true
                }else{
                    this.ismessage = this.disabled = false
                }
            }).catch(err=>{
                console.log(err);
                this.disabled = false;
            })
        },
        changeLang(type){
            localStorage.setItem('language',type);
            location. reload();
        },
  
       

    },
    
}
</script>

<style lang="scss" scoped>

.login{
    padding-top: 21vh;
    position: relative;
    z-index: 10;
    .login-wrapper{
        border-radius: 3px 3px 3px 3px;
        box-shadow: 0px 0px 4px #cccccc;
        right: 0px;
        margin: 0px auto;
        margin-top: 5%;
        max-width: 380px;
        position: relative;
        padding: 0;
        background: #FFFFFF;
        @media (max-width: 435px){
            box-shadow:none;
        }
        .separator{
            background: #F0F0F0;
            padding: 18px 40px 16px;
            border-radius: 3px 3px 0 0;
            border-bottom: 1px solid #EAEAEA;
            @media (max-width: 435px){
                padding: 18px 0px 16px;
                background: none;
            }
            .mySysSLD {
                background-repeat: no-repeat;
                background-size: 69px 23px;
                background-position: 0px 0px;
                padding-left: 81px;
                height: 25px;
                display: inline-block;
            }
        }
        .login-content{
            margin: 0 auto;
            padding: 40px 40px 40px;
            position: relative;
            text-align: center;
            text-shadow: 0 1px 0 #fff;
            min-width: 280px;
            font-size: 12px;
            .input{
                font-size: 12px;
                height: 38px;
            }
            .login-btn{
                width: 100%;
                background: #5cb85c;
                height: 38px;
                border-color: #5cb85c;
            }
        }
    }
    div.lang {
        
        margin-top: 15px;
        line-height: 48px;
        width: 100%;
        text-align: center;
        div{
            color: #555555;
            border-left: 1px solid #999999;
            padding: 0 15px;
            display: inline-block;
            line-height: 11px;
            cursor: pointer;
            &:first-child {
                border: none;
                padding-left: 0px;
            }
            &:last-child{
                padding-right: 0px;
            }
            &:hover{
                color: #999999 !important;
                background: none !important;
            }
        }
        div.active{
            color: #999999 !important;
            background: none !important;  
        }
    }
    a{
        color: #c63033;
        &:hover {
          color: #950c0f;
        }
    }
}
.check-rember .custom-control-label{
    margin-left: 5px;
}
.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 40px;
    font-size: 11px;
    font-weight: normal;
    background-color: #f5f5f5;
}
.auth-bg{
    background: #F3F3F3;
    height: 100%;
}


</style>