
<template>
  <div class="content">
    <b-container fluid>
      <b-col md="12">
        <div class="story">
          <h5 class="name mgb20 tc ft21">{{$t('Promotions.marco')}}</h5>

          <div v-if="lang == 'en'">
            <div class="flex col-valign">
              <div>
                <img
                  :src="url + 'promotion/marcoszeto.jpg'"
                  style="width:100%; height:auto;"
                />
              </div>
              <div>
                <div
                  class="col-text"
                  style="max-width:645px; margin:0 auto; text-align:justify;"
                >Steve's Friend, Marco Szeto is a genius in making use of a wide variety of media for the articulation of his ideas. Oil colour, water color, Chinese ink, pastel, fountain pen and even collage, can all be a tool for expressing his inspiration. He casts away the concepts of old or modern, Eastern or Western, traditions and conservatism, whatever suits his mood is the best tool for his art. The "Collage" collection is the most distinctive artwork that Steve Leung would like to recommend.</div>
              </div>
            </div>

            <div style="max-width:1070px; margin:85px auto 55px;">
              If there is any suitable project, design teams can recommend these items to the client and enjoy 25% discount in purchasing these items. Besides, Mr. Szeto can provide tailor made service in various size and requirements at negotiable price. The profit generated in the transaction will belong to the related design team.
              <br />
              <br />For further information and quotations, please contact Senior Purchasing Executive - Wally Chu; If you are interested in purchasing the items, please download and fill in the
              <a class="fce3" :href="orderFormUrl">order form</a>, and email to Wally Chu (
              <a class="fce3" href="mailto:wally.chu@ed-living.com">wally.chu@ed-living.com</a>).
              <br />
              <br />

              <div class="tc download-btn">
                <a
                  :href="orderFormUrl"
                  class="btn white"
                >Download Order Form</a>
              </div>

              <br />
              <i>Remarks: All artworks can be reserved for 1 month upon request.</i>
            </div>
            <br />

            <h2>Ceramic Paintings</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-CER0001.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Costume Sardegna</b>
                  <div class="price">HKD 60,000.00</div>
                  <div class="otherDetails">
                    380(W) x 710(H) x 180(D)
                    <br />Ceramic
                    <br />Year of creation: 2010
                    <br />Item code: CER0001
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-CER0002.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Costume Sardegna</b>
                  <div class="price">HKD 45,000.00</div>
                  <div class="otherDetails">
                    410(W) x 550(H) x 220(D)
                    <br />Ceramic
                    <br />Year of creation: 2008
                    <br />Item code: CER0002
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-CER0003.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Costume Sardegna</b>
                  <div class="price">HKD 45,000.00</div>
                  <div class="otherDetails">
                    360(W) x 460(H) x 230(D)
                    <br />Ceramic
                    <br />Year of creation: 2008
                    <br />Item code: CER0003
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-CER0004.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Estilo Mexicano</b>
                  <div class="price">HKD 30,000.00</div>
                  <div class="otherDetails">
                    330(W) x 440(H) x 150(D)
                    <br />Ceramic
                    <br />Year of creation: 2008
                    <br />Item code: CER0004
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-CER0005.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Costume Sardegna</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    290(W) x 280(H) x 110(D)
                    <br />Ceramic
                    <br />Year of creation: 2013
                    <br />Item code: CER0005
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-CER0006.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Green Owl</b>
                  <div class="price">HKD 5,000.00</div>
                  <div class="otherDetails">
                    180(W) x 160(H) x 90(D)
                    <br />Ceramic
                    <br />Year of creation: 2010
                    <br />Item code: CER0006
                  </div>
                </div>
              </li>
            </ul>

            <h2>Collage</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0001.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Hakata Old Town Area</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    180(W) x 180(H)
                    <br />Collage
                    <br />Year of creation: 2019
                    <br />Item code: COL0001
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0002.jpg'" />
                  </div>
                </div>
                <div>
                  <b>An Afternoon in Kurashiki</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    190(W) x 190(H)
                    <br />Collage
                    <br />Year of creation: 2019
                    <br />Item code: COL0002
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0003.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Timely Snow</b>
                  <div class="price">HKD 9,000.00</div>
                  <div class="otherDetails">
                    170(W) x 180(H)
                    <br />Collage
                    <br />Year of creation: 2013
                    <br />Item code: COL0003
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0004.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Red in the Snow</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    150(W) x 125(H)
                    <br />Collage
                    <br />Year of creation: 2018
                    <br />Item code: COL0004
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0005.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Hang Zhou Memory</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    105(W) x 165(H)
                    <br />Collage
                    <br />Year of creation: 2018
                    <br />Item code: COL0005
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0006.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Winter Snow</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    165(W) x 165(H)
                    <br />Collage
                    <br />Year of creation: 2018
                    <br />Item code: COL0006
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0007.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Hidden House</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    140(W) x 170(H)
                    <br />Collage
                    <br />Year of creation: 2018
                    <br />Item code: COL0007
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0008.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Nagoya</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    165(W) x 165(H)
                    <br />Collage
                    <br />Year of creation: 2019
                    <br />Item code: COL0008
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0009.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Mauritius</b>
                  <div class="price">HKD 6,800.00</div>
                  <div class="otherDetails">
                    140(W) x 165(H)
                    <br />Collage
                    <br />Year of creation: 2019
                    <br />Item code: COL0009
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0010.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Dancing Show in Mauritius</b>
                  <div class="price">HKD 13,800.00</div>
                  <div class="otherDetails">
                    160(W) x 165(H)
                    <br />Collage
                    <br />Year of creation: 2019
                    <br />Item code: COL0010
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0011.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Bird Study in Madagascar</b>
                  <div class="price">HKD 7,500.00</div>
                  <div class="otherDetails">
                    160(W) x 165(H)
                    <br />Collage
                    <br />Year of creation: 2019
                    <br />Item code: COL0011
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0012.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Madagascar</b>
                  <div class="price">HKD 6,500.00</div>
                  <div class="otherDetails">
                    150(W) x 170(H)
                    <br />Collage
                    <br />Year of creation: 2019
                    <br />Item code: COL0012
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0013.jpg'" />
                  </div>
                </div>
                <div>
                  <b>On the Beach</b>
                  <div class="price">HKD 6,500.00</div>
                  <div class="otherDetails">
                    155(W) x 170(H)
                    <br />Collage
                    <br />Year of creation: 2015
                    <br />Item code: COL0013
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0014.jpg'" />
                  </div>
                </div>
                <div>
                  <b>House in Bangkok</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    160(W) x 165(H)
                    <br />Collage
                    <br />Year of creation: 2015
                    <br />Item code: COL0014
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0015.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Madagascar</b>
                  <div class="price">HKD 7,000.00</div>
                  <div class="otherDetails">
                    105(W) x 150(H)
                    <br />Collage
                    <br />Year of creation: 2019
                    <br />Item code: COL0015
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0016.jpg'" />
                  </div>
                </div>
                <div>
                  <b>The Black Cow, Madagascar</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    155(W) x 120(H)
                    <br />Collage
                    <br />Year of creation: 2019
                    <br />Item code: COL0016
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0017.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Plovdiv, Bulgaria</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    180(W) x 180(H)
                    <br />Collage
                    <br />Year of creation: 2018
                    <br />Item code: COL0017
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0018.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Tai Chung</b>
                  <div class="price">HKD 7,000.00</div>
                  <div class="otherDetails">
                    165(W) x 165(H)
                    <br />Collage
                    <br />Year of creation: 2018
                    <br />Item code: COL0018
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0019.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Cong Café, Vietnam</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    155(W) x 165(H)
                    <br />Collage
                    <br />Year of creation: 2019
                    <br />Item code: COL0019
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0020.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Tai Chung</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    150(W) x 165(H)
                    <br />Collage
                    <br />Year of creation: 2016
                    <br />Item code: COL0020
                  </div>
                </div>
              </li>
            </ul>

            <h2>Drawings</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0001.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Andasibe Madagascar</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    120(W) x 160(H)
                    <br />Colour Pencil
                    <br />Year of creation: 2019
                    <br />Item code: DRA0001
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0002.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Andasibe Madagascar</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    120(W) x 160(H)
                    <br />Colour Pencil
                    <br />Year of creation: 2019
                    <br />Item code: DRA0002
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0003.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Andasibe Madagascar</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    120(W) x 160(H)
                    <br />Colour Pencil
                    <br />Year of creation: 2019
                    <br />Item code: DRA0003
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0004.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Andasibe Madagascar</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    120(W) x 160(H)
                    <br />Colour Pencil and Pastel
                    <br />Year of creation: 2019
                    <br />Item code: DRA0004
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0005.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Alicante, Spain</b>
                  <div class="price">HKD 18,000.00</div>
                  <div class="otherDetails">
                    300(W) x 370(H)
                    <br />Quill & Ink
                    <br />Year of creation: 2017
                    <br />Item code: DRA0005
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0006.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Fisherman's House in Hastings, UK</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    305(W) x 230(H)
                    <br />Mixed Media
                    <br />Year of creation: 2008
                    <br />Item code: DRA0006
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0007.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta in Ail</b>
                  <div class="price">HKD 8,500.00</div>
                  <div class="otherDetails">
                    165(W) x 230(H)
                    <br />Colour Pencil and Watercolour
                    <br />Year of creation: 2018
                    <br />Item code: DRA0007
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0008.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Aveiro Dream</b>
                  <div class="price">HKD 8,200.00</div>
                  <div class="otherDetails">
                    235(W) x 160(H)
                    <br />Mixed Media
                    <br />Year of creation: 2020
                    <br />Item code: DRA0008
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0009.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Pasticcio di Modigliani #1</b>
                  <div class="price">HKD 13,000.00</div>
                  <div class="otherDetails">
                    210(W) x 295(H)
                    <br />Mixed Media on Board
                    <br />Year of creation: 2017
                    <br />Item code: DRA0009
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0010.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Pasticcio di Modigliani #2</b>
                  <div class="price">HKD 13,000.00</div>
                  <div class="otherDetails">
                    175(W) x 295(H)
                    <br />Mixed Media on Board
                    <br />Year of creation: 2011
                    <br />Item code: DRA0010
                  </div>
                </div>
              </li>
            </ul>

            <h2>Glass Paintings</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-GLA0001.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Perugia</b>
                  <div class="price">HKD 16,000.00</div>
                  <div class="otherDetails">
                    265(W) x 265(H) x 65(D)
                    <br />Glass Art
                    <br />Year of creation:
                    <span class="na">-</span>
                    <br />Item code: GLA0001
                  </div>
                </div>
              </li>
            </ul>

            <h2>Oil Paintings</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0001.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Memories of French Riviera (Pasticcio di Jennifer)</b>
                  <div class="price">HKD 60,000.00</div>
                  <div class="otherDetails">
                    760(W) x 610(H)
                    <br />Oil on Canvas
                    <br />Year of creation: 2019
                    <br />Item code: OIL0001
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0002.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Mountain Snow</b>
                  <div class="price">HKD 18,000.00</div>
                  <div class="otherDetails">
                    290(W) x 200(H)
                    <br />Oil on Canvas
                    <br />Year of creation: 2013
                    <br />Item code: OIL0002
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0003.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Morocco</b>
                  <div class="price">HKD 30,000.00</div>
                  <div class="otherDetails">
                    505(W) x 610(H)
                    <br />Oil on Canvas
                    <br />Year of creation: 2020
                    <br />Item code: OIL0003
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0004.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Morocco</b>
                  <div class="price">HKD 28,000.00</div>
                  <div class="otherDetails">
                    410(W) x 510(H)
                    <br />Oil on Canvas
                    <br />Year of creation: 2020
                    <br />Item code: OIL0004
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0005.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Morocco</b>
                  <div class="price">HKD 14,500.00</div>
                  <div class="otherDetails">
                    360(W) x 250(H)
                    <br />Oil on Canvas
                    <br />Year of creation: 2020
                    <br />Item code: OIL0005
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0006.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Largo da Se</b>
                  <div class="price">HKD 19,500.00</div>
                  <div class="otherDetails">
                    450(W) x 480(H)
                    <br />Oil on Canvas
                    <br />Year of creation: 2014
                    <br />Item code: OIL0006
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0007.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta di Italia</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    250(W) x 200(H)
                    <br />Oil on Board
                    <br />Year of creation: 2019
                    <br />Item code: OIL0007
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0008.jpg'" />
                  </div>
                </div>
                <div>
                  <b>A Corner of My Studio</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    250(W) x 200(H)
                    <br />Oil on Board
                    <br />Year of creation: 2019
                    <br />Item code: OIL0008
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0009.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Stile Morocco</b>
                  <div class="price">HKD 22,000.00</div>
                  <div class="otherDetails">
                    610(W) x 300(H)
                    <br />Oil on Canvas
                    <br />Year of creation: 2018
                    <br />Item code: OIL0009
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0010.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Snow Love</b>
                  <div class="price">HKD 26,000.00</div>
                  <div class="otherDetails">
                    500(W) x 600(H)
                    <br />Oil on Canvas
                    <br />Year of creation: 2021
                    <br />Item code: OIL0010
                  </div>
                </div>
              </li>
              <!--<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0011.jpg'">
									</div>
								</div>
								<div>
									<b>Due in Montmarte</b>
									<div class="price">HKD 12,250.00</div>
									<div class="otherDetails">
										250(W) x 300(H)<br>
										Oil on Canvas<br>
										Year of creation: 2021<br>
										Item code: OIL0011
									</div>
								</div>
              </li>-->
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0012.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Ethiopia Coffee Time</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    200(W) x 250(H)
                    <br />Oil on Canvas
                    <br />Year of creation: 2021
                    <br />Item code: OIL0012
                  </div>
                </div>
              </li>
            </ul>

            <h2>Pastel Painting</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0001.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Nice</b>
                  <div class="price">HKD 12,600.00</div>
                  <div class="otherDetails">
                    315(W) x 240(H)
                    <br />Pastel on Card
                    <br />Year of creation: 2018
                    <br />Item code: PAS0001
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0002.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Take a Break</b>
                  <div class="price">HKD 32,000.00</div>
                  <div class="otherDetails">
                    395(W) x 195(H)
                    <br />Mixed Media
                    <br />Year of creation: 2019
                    <br />Item code: PAS0002
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0003.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Cubita</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    290(W) x 275(H)
                    <br />Mixed Media
                    <br />Year of creation: 2019
                    <br />Item code: PAS0003
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0004.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Homage to Miro</b>
                  <div class="price">HKD 12,800.00</div>
                  <div class="otherDetails">
                    290(W) x 240(H)
                    <br />Mixed Media
                    <br />Year of creation: 2019
                    <br />Item code: PAS0004
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0005.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta con Chitarra</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    180(W) x180(H)
                    <br />Gouache and Pastel on Board
                    <br />Year of creation: 2019
                    <br />Item code: PAS0005
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0006.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Light & Dark</b>
                  <div class="price">HKD 9,000.00</div>
                  <div class="otherDetails">
                    290(W) x 210(H)
                    <br />Pastel on Black Paper
                    <br />Year of creation:
                    <span class="na">-</span>
                    <br />Item code: PAS0006
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0007.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Homage to Picasso</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    310(W) x 215(H)
                    <br />Gouache and Pastel on Board
                    <br />Year of creation: 2019
                    <br />Item code: PAS0007
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0008.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta con Lemoniamo</b>
                  <div class="price">HKD 12,800.00</div>
                  <div class="otherDetails">
                    265(W) x 370(H)
                    <br />Pastel on Card
                    <br />Year of creation: 2016
                    <br />Item code: PAS0008
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0009.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Colour Rhythm</b>
                  <div class="price">HKD 16,800.00</div>
                  <div class="otherDetails">
                    265(W) x 370(H)
                    <br />Pastel on Black Paper
                    <br />Year of creation: 2016
                    <br />Item code: PAS0009
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0010.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Plovdiv, Bulgaria</b>
                  <div class="price">HKD 22,000.00</div>
                  <div class="otherDetails">
                    280(W) x 340(H)
                    <br />Pastel on Board
                    <br />Year of creation: 2016
                    <br />Item code: PAS0010
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0011.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Wild Leaves</b>
                  <div class="price">HKD 60,000.00</div>
                  <div class="otherDetails">
                    585(W) x 430(H)
                    <br />Pastel on Paper
                    <br />Year of creation: 1987
                    <br />Item code: PAS0011
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0012.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Wild Flowers</b>
                  <div class="price">HKD 60,000.00</div>
                  <div class="otherDetails">
                    560(W) x 380(H)
                    <br />Pastel on Paper
                    <br />Year of creation: 1987
                    <br />Item code: PAS0012
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0013.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Artist & Nature</b>
                  <div class="price">HKD 18,000.00</div>
                  <div class="otherDetails">
                    290(W) x 380(H)
                    <br />Pastel on Card
                    <br />Year of creation: 2009
                    <br />Item code: PAS0013
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0014.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Happy Hour</b>
                  <div class="price">HKD 26,800.00</div>
                  <div class="otherDetails">
                    495(W) x 325(H)
                    <br />Pastel on Card
                    <br />Year of creation: 2019
                    <br />Item code: PAS0014
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0015.jpg'" />
                  </div>
                </div>
                <div>
                  <b>A Familiar Corner Pastel on Card</b>
                  <div class="price">HKD 26,800.00</div>
                  <div class="otherDetails">
                    495(W) x 325(H)
                    <br />Pastel on Card
                    <br />Year of creation: 2019
                    <br />Item code: PAS0015
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0016.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Jazz Moments</b>
                  <div class="price">HKD 26,800.00</div>
                  <div class="otherDetails">
                    495(W) x 325(H)
                    <br />Pastel on Card
                    <br />Year of creation: 2019
                    <br />Item code: PAS0016
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0017.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Night Music</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    320(W) x 245(H)
                    <br />Pastel on Card
                    <br />Year of creation: 2019
                    <br />Item code: PAS0017
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0018.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Still Life in Studio</b>
                  <div class="price">HKD 23,000.00</div>
                  <div class="otherDetails">
                    370(W) x 520(H)
                    <br />Pastel on Card
                    <br />Year of creation: 2005
                    <br />Item code: PAS0018
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0019.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Mascherata #2</b>
                  <div class="price">HKD 19,800.00</div>
                  <div class="otherDetails">
                    310(W) x 480(H)
                    <br />Pastel on Card
                    <br />Year of creation: 2020
                    <br />Item code: PAS0019
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0020.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Black and Yellow</b>
                  <div class="price">HKD 9,000.00</div>
                  <div class="otherDetails">
                    200(W) x 300(H)
                    <br />Mixed Media on Board
                    <br />Year of creation: 2015
                    <br />Item code: PAS0020
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0021.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Homage to Picasso II</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    230(W) x 305(H)
                    <br />Colour and Pencil on Card
                    <br />Year of creation: 2017
                    <br />Item code: PAS0021
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0022.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Still Life from Portugal</b>
                  <div class="price">HKD 17,000.00</div>
                  <div class="otherDetails">
                    240(W) x 315(H)
                    <br />Pastel on Card
                    <br />Year of creation: 2018
                    <br />Item code: PAS0022
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0023.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Homage to Picasso I</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    255(W) x 355(H)
                    <br />Colour Pencil and Pastel
                    <br />Year of creation: 2017
                    <br />Item code: PAS0023
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0024.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Chianti</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    290(W) x 210(H)
                    <br />Pastel on Black Paper
                    <br />Year of creation: 2015
                    <br />Item code: PAS0024
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0025.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Artist & Nature Series (Picasso)</b>
                  <div class="price">HKD 23,000.00</div>
                  <div class="otherDetails">
                    290(W) x 390(H)
                    <br />Pastel on Card
                    <br />Year of creation: 2009
                    <br />Item code: PAS0025
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0026.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Stilo, Italy</b>
                  <div class="price">HKD 30,000.00</div>
                  <div class="otherDetails">
                    315(W) x 240(H)
                    <br />Pastel on Paper
                    <br />Year of creation:
                    <span class="na">-</span>
                    <br />Item code: PAS0026
                  </div>
                </div>
              </li>
            </ul>

            <h2>Watercolor Painting</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0001.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Nice Nightscene</b>
                  <div class="price">HKD 22,800.00</div>
                  <div class="otherDetails">
                    305(W) x 405(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 2018
                    <br />Item code: WAT0001
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0002.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Village du Luberon</b>
                  <div class="price">HKD 9,800.00</div>
                  <div class="otherDetails">
                    145(W) x 150(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 2018
                    <br />Item code: WAT0002
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0003.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Szentendre</b>
                  <div class="price">HKD 13,000.00</div>
                  <div class="otherDetails">
                    300(W) x 300(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 2015
                    <br />Item code: WAT0003
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0004.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Cuban Time</b>
                  <div class="price">HKD 15,400.00</div>
                  <div class="otherDetails">
                    320(W) x 240(H)
                    <br />Mixed Media
                    <br />Year of creation: 2019
                    <br />Item code: WAT0004
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0005.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Still Life</b>
                  <div class="price">HKD 13,800.00</div>
                  <div class="otherDetails">
                    190(W) x 265(H)
                    <br />Gouache on Canvas
                    <br />Year of creation: 2019
                    <br />Item code: WAT0005
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0006.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Hangzhou</b>
                  <div class="price">HKD 9,100.00</div>
                  <div class="otherDetails">
                    165(W) x 150(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 2018
                    <br />Item code: WAT0006
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0007.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Hangzhou</b>
                  <div class="price">HKD 11,800.00</div>
                  <div class="otherDetails">
                    170(W) x 145(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 2018
                    <br />Item code: WAT0007
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0008.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Hangzhou</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    180(W) x 155(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 2018
                    <br />Item code: WAT0008
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0009.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Hangzhou</b>
                  <div class="price">HKD 9,100.00</div>
                  <div class="otherDetails">
                    165(W) x 150(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 2018
                    <br />Item code: WAT0009
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0010.jpg'" />
                  </div>
                </div>
                <div>
                  <b>The Cubist Epoch</b>
                  <div class="price">HKD 16,600.00</div>
                  <div class="otherDetails">
                    290(W) x 305(H)
                    <br />Gouache on Board
                    <br />Year of creation: 2019
                    <br />Item code: WAT0010
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0011.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Okinawa</b>
                  <div class="price">HKD 16,600.00</div>
                  <div class="otherDetails">
                    305(W) x 330(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 2010
                    <br />Item code: WAT0011
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0012.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta</b>
                  <div class="price">HKD 15,600.00</div>
                  <div class="otherDetails">
                    245(W) x 290(H)
                    <br />Gouache on Board
                    <br />Year of creation: 2019
                    <br />Item code: WAT0012
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0013.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Madagascar Parsonie Cameleon #1</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    190(W) x 150(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 2019
                    <br />Item code: WAT0013
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0014.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Madagascar Parsonie Cameleon #2</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    150(W) x 175(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 2019
                    <br />Item code: WAT0014
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0015.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta in Studio</b>
                  <div class="price">HKD 16,000.00</div>
                  <div class="otherDetails">
                    340(W) x 280(H)
                    <br />Mixed Media
                    <br />Year of creation: 2016
                    <br />Item code: WAT0015
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0016.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta Turca</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    240(W) x 280(H)
                    <br />Gouache on Board
                    <br />Year of creation: 2018
                    <br />Item code: WAT0016
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0017.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Aveiro Dream #1</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    230(W) x 155(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 2020
                    <br />Item code: WAT0017
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0018.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Aveiro Dream #2</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    235(W) x 160(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 2020
                    <br />Item code: WAT0018
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0019.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Madagascar Dream</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    235(W) x 160(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 2020
                    <br />Item code: WAT0019
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0020.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Marseille</b>
                  <div class="price">HKD 22,000.00</div>
                  <div class="otherDetails">
                    265(W) x 265(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 2019
                    <br />Item code: WAT0020
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0021.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Monte Carlo</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    265(W) x 325(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 2020
                    <br />Item code: WAT0021
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0022.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Dancing Leaves #1</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    270(W) x 195(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 2020
                    <br />Item code: WAT0022
                  </div>
                </div>
              </li>
              <!--<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0023.jpg'">
									</div>
								</div>
								<div>
									<b>Natura Morta <h6>On hold until 25 Nov 2021</h6></b>
									<div class="price">HKD 10,000.00</div>
									<div class="otherDetails">
										250(W) x 200(H)<br>
										Mixed Media on Board<br>
										Year of creation: 2020<br>
										Item code: WAT0023
									</div>
								</div>
              </li>-->
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0024.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Still Life</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    280(W) x 200(H)
                    <br />Mixed Media on board
                    <br />Year of creation: 2020
                    <br />Item code: WAT0024
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0025.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Madagascar</b>
                  <div class="price">HKD 9,800.00</div>
                  <div class="otherDetails">
                    205(W) x 150(H)
                    <br />Mixed Media
                    <br />Year of creation: 2019
                    <br />Item code: WAT0025
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0026.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Morning Bird</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    200(W) x 290(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 2017
                    <br />Item code: WAT0026
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0027.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Turkmenistan</b>
                  <div class="price">HKD 11,800.00</div>
                  <div class="otherDetails">
                    195(W) x 300(H)
                    <br />Gouache on Board
                    <br />Year of creation: 2018
                    <br />Item code: WAT0027
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0028.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Sofia</b>
                  <div class="price">HKD 10,200.00</div>
                  <div class="otherDetails">
                    240(W) x 200(H)
                    <br />Mixed Media on Board
                    <br />Year of creation: 2016
                    <br />Item code: WAT0028
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0029.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Mel de Muntanya</b>
                  <div class="price">HKD 9,000.00</div>
                  <div class="otherDetails">
                    265(W) x 230(H)
                    <br />Pen & Ink
                    <br />Year of creation: 2018
                    <br />Item code: WAT0029
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0030.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Monte Carlo</b>
                  <div class="price">HKD 19,800.00</div>
                  <div class="otherDetails">
                    410(W) x 265(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 2019
                    <br />Item code: WAT0030
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0031.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta</b>
                  <div class="price">HKD 13,300.00</div>
                  <div class="otherDetails">
                    290(W) x 285(H)
                    <br />Mixed Media on Board
                    <br />Year of creation: 2015
                    <br />Item code: WAT0031
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0032.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Cannes</b>
                  <div class="price">HKD 16,800.00</div>
                  <div class="otherDetails">
                    410(W) x 320(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 2019
                    <br />Item code: WAT0032
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0033.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Little India, Singapore</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    410(W) x 320(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 1997
                    <br />Item code: WAT0033
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0034.jpg'" />
                  </div>
                </div>
                <div>
                  <b>EZE Village</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    370(W) x 310(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 2019
                    <br />Item code: WAT0034
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0035.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Aix en Provence</b>
                  <div class="price">HKD 13,800.00</div>
                  <div class="otherDetails">
                    410(W) x 275(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 2019
                    <br />Item code: WAT0035
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0036.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Visite a Menton</b>
                  <div class="price">HKD 16,800.00</div>
                  <div class="otherDetails">
                    410(W) x 290(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 2019
                    <br />Item code: WAT0036
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0037.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Nice</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    350(W) x 320(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 2019
                    <br />Item code: WAT0037
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0038.jpg'" />
                  </div>
                </div>
                <div>
                  <b>From Biot to Marseille</b>
                  <div class="price">HKD 13,800.00</div>
                  <div class="otherDetails">
                    410(W) x 240(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 2019
                    <br />Item code: WAT0038
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0039.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Homage to Miro</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    205(W) x 290(H)
                    <br />Gouache on Board
                    <br />Year of creation: 2019
                    <br />Item code: WAT0039
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0040.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Homage to Picasso</b>
                  <div class="price">HKD 16,000.00</div>
                  <div class="otherDetails">
                    190(W) x 265(H)
                    <br />Gouache on Board
                    <br />Year of creation: 2019
                    <br />Item code: WAT0040
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0041.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Morocco</b>
                  <div class="price">HKD 9,000.00</div>
                  <div class="otherDetails">
                    160(W) x 230(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 2020
                    <br />Item code: WAT0041
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0042.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Morocco</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    160(W) x 230(H)
                    <br />Watercolour on Paper
                    <br />Year of creation: 2020
                    <br />Item code: WAT0042
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0043.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta Turca</b>
                  <div class="price">HKD 12,800.00</div>
                  <div class="otherDetails">
                    250(W) x 300(H)
                    <br />Gouache on Board
                    <br />Year of creation: 2018
                    <br />Item code: WAT0043
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0044.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta Persian & Spanish (Style)</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    250(W) x 300(H)
                    <br />Gouache on Board
                    <br />Year of creation: 2018
                    <br />Item code: WAT0044
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0045.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta Turca</b>
                  <div class="price">HKD 8,800.00</div>
                  <div class="otherDetails">
                    210(W) x 280(H)
                    <br />Gouache on Board
                    <br />Year of creation: 2018
                    <br />Item code: WAT0045
                  </div>
                </div>
              </li>
            </ul>

            <h2>Watercolor & Chinese Ink</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0001.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Plovdiv, Bulgaria</b>
                  <div class="price">HKD 50,000.00</div>
                  <div class="otherDetails">
                    635(W) x 455(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2018
                    <br />Item code: WCI0001
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0002.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Still Life with Wampi</b>
                  <div class="price">HKD 36,000.00</div>
                  <div class="otherDetails">
                    380(W) x 330(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2018
                    <br />Item code: WCI0002
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0003.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Vence</b>
                  <div class="price">HKD 32,000.00</div>
                  <div class="otherDetails">
                    380(W) x 455(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2010
                    <br />Item code: WCI0003
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0004.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Ceramiche in Sardegna</b>
                  <div class="price">HKD 40,000.00</div>
                  <div class="otherDetails">
                    450(W) x 530(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation:
                    <span class="na">-</span>
                    <br />Item code: WCI0004
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0005.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Vitalita #1 / Vitalita #2</b>
                  <div class="price">HKD 50,000.00</div>
                  <div class="otherDetails">
                    340(W) x 137(H) / 340(W) x 137(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2016
                    <br />Item code: WCI0005 / WCI0006
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0007.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Monte Carlo</b>
                  <div class="price">HKD 32,000.00</div>
                  <div class="otherDetails">
                    460(W) x 590(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2019
                    <br />Item code: WCI0007
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0008.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Autumn Show</b>
                  <div class="price">HKD 32,000.00</div>
                  <div class="otherDetails">
                    600(W) x 450(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2020
                    <br />Item code: WCI0008
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0009.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Pasticcio di Braque</b>
                  <div class="price">HKD 20,000.00</div>
                  <div class="otherDetails">
                    460(W) x 640(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2020
                    <br />Item code: WCI0009
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0010.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Ventimiglia</b>
                  <div class="price">HKD 28,000.00</div>
                  <div class="otherDetails">
                    570(W) x 420(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2019
                    <br />Item code: WCI0010
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0011.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Little Pink</b>
                  <div class="price">HKD 50,000.00</div>
                  <div class="otherDetails">
                    805(W) x 340(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2016
                    <br />Item code: WCI0011
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0012.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Madonna</b>
                  <div class="price">HKD 30,000.00</div>
                  <div class="otherDetails">
                    525(W) x 370(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2016
                    <br />Item code: WCI0012
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0013.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Stand Still</b>
                  <div class="price">HKD 25,000.00</div>
                  <div class="otherDetails">
                    480(W) x 410(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2020
                    <br />Item code: WCI0013
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0014.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Cermaica</b>
                  <div class="price">HKD 33,000.00</div>
                  <div class="otherDetails">
                    380(W) x 455(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2004
                    <br />Item code: WCI0014
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0015.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Ville de Menton</b>
                  <div class="price">HKD 23,800.00</div>
                  <div class="otherDetails">
                    610(W) x 360(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2019
                    <br />Item code: WCI0015
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0016.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Cap d' Ail</b>
                  <div class="price">HKD 38,000.00</div>
                  <div class="otherDetails">
                    490(W) x 710(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2020
                    <br />Item code: WCI0016
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0017.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Bayamo, Cuba</b>
                  <div class="price">HKD 22,000.00</div>
                  <div class="otherDetails">
                    380(W) x 520(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2014
                    <br />Item code: WCI0017
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0018.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower & Lemons</b>
                  <div class="price">HKD 13,500.00</div>
                  <div class="otherDetails">
                    190(W) x 610(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2015
                    <br />Item code: WCI0018
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0019.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Kobe Snow Scene</b>
                  <div class="price">HKD 38,000.00</div>
                  <div class="otherDetails">
                    700(W) x 450(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2005
                    <br />Item code: WCI0019
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0020.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Red and Honey</b>
                  <div class="price">HKD 22,000.00</div>
                  <div class="otherDetails">
                    355(W) x 455(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2015
                    <br />Item code: WCI0020
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0021.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower Study</b>
                  <div class="price">HKD 9,000.00</div>
                  <div class="otherDetails">
                    245(W) x 335(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2021
                    <br />Item code: WCI0021
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0022.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Morocco Tour</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    245(W) x 335(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2020
                    <br />Item code: WCI0022
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0023.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Bird Talk</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    315(W) x 380(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2021
                    <br />Item code: WCI0023
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0024.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower Arrangement #1</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    305(W) x 340(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2021
                    <br />Item code: WCI0024
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0025.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower Arrangement #2</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    380(W) x 435(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2021
                    <br />Item code: WCI0025
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0026.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower Arrangement #3</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    380(W) x 475(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2021
                    <br />Item code: WCI0026
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0027.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Uccello e Fiori</b>
                  <div class="price">HKD 20,000.00</div>
                  <div class="otherDetails">
                    310(W) x 585(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2021
                    <br />Item code: WCI0027
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0028.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower Arrangement #4</b>
                  <div class="price">HKD 25,000.00</div>
                  <div class="otherDetails">
                    515(W) x 460(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2021
                    <br />Item code: WCI0028
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0029.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Bouquet</b>
                  <div class="price">HKD 30,000.00</div>
                  <div class="otherDetails">
                    400(W) x 535(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2021
                    <br />Item code: WCI0029
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0030.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower Arrangement #5</b>
                  <div class="price">HKD 30,000.00</div>
                  <div class="otherDetails">
                    380(W) x 530(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2021
                    <br />Item code: WCI0030
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0031.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower Study</b>
                  <div class="price">HKD 23,000.00</div>
                  <div class="otherDetails">
                    345(W) x 520(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2021
                    <br />Item code: WCI0031
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0032.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower Study</b>
                  <div class="price">HKD 22,000.00</div>
                  <div class="otherDetails">
                    395(W) x 450(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2021
                    <br />Item code: WCI0032
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0033.jpg'" />
                  </div>
                </div>
                <div>
                  <b>La Stazione</b>
                  <div class="price">HKD 34,800.00</div>
                  <div class="otherDetails">
                    590(W) x 460(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2019
                    <br />Item code: WCI0033
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0034.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Amapola</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    385(W) x 355(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2020
                    <br />Item code: WCI0034
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0035.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Day Walk in San Remo</b>
                  <div class="price">HKD 20,000.00</div>
                  <div class="otherDetails">
                    460(W) x 300(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2019
                    <br />Item code: WCI0035
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0036.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Una Strada</b>
                  <div class="price">HKD 18,000.00</div>
                  <div class="otherDetails">
                    300(W) x 460(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2019
                    <br />Item code: WCI0036
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0037.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Imperia</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    290(W) x 460(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2019
                    <br />Item code: WCI0037
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0038.jpg'" />
                  </div>
                </div>
                <div>
                  <b>San Remo</b>
                  <div class="price">HKD 39,000.00</div>
                  <div class="otherDetails">
                    630(W) x 460(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2019
                    <br />Item code: WCI0038
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0039.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Cuba Time</b>
                  <div class="price">HKD 38,000.00</div>
                  <div class="otherDetails">
                    520(W) x 440(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2014
                    <br />Item code: WCI0039
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0040.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Still Life</b>
                  <div class="price">HKD 28,500.00</div>
                  <div class="otherDetails">
                    390(W) x 380(H)
                    <br />Watercolour and Chinese Ink on Paper
                    <br />Year of creation: 2005
                    <br />Item code: WCI0040
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div v-else-if="lang == 'tc'">
            <div class="flex col-valign">
              <div>
                <img
                  :src="url + 'promotion/marcoszeto.jpg'"
                  style="width:100%; height:auto;"
                />
              </div>
              <div>
                <div
                  class="col-text"
                  style="max-width:645px; margin:0 auto; text-align:justify;"
                >梁先生的朋友，司徒志明善於利用不同的媒體來表達他心目中的意境，油彩、水彩、水墨、粉彩、鋼筆，甚至剪貼，都是他發揮靈感的工具，沒有古今中外、傳統派系的束縛，信手拈來便成佳作。其中的拼貼畫系列是梁先生覺得最有特色的作品。</div>
              </div>
            </div>

            <div style="max-width:990px; margin:85px auto 55px;">
              如有合適的項目，各設計團隊亦可向客戶推介在項目中使用並可以優惠價 75 折購買以下畫作。亦可邀請司徒先生按項目風格及需要訂制不同尺寸的畫作 / 藝術品，價格另議，而買賣過程中所產生的利潤將全數歸相關設計團隊。
              <br />
              <br />如欲了解詳情及索取報價，歡迎各設計團隊與高級招采主任 - 朱嘉儀 Wally 聯繫；各同事如有興趣購買，請下載及填妥
              <a :href="orderFormUrl"  class="fce3">訂購表格</a>，並電郵至 Wally (
              <a href="mailto:wally.chu@ed-living.com"  class="fce3">wally.chu@ed-living.com</a>)。
              <br />
              <br />

              <div class="tc download-btn">
                <a :href="orderFormUrl" class="btn white">下載訂購表格</a>
              </div>

              <br />
              <i>備註: 上述之畫作 / 藝術品可按需要預留1個月。</i>
            </div>
            <br />

            <h2>陶瓷彩繪</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-CER0001.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Costume Sardegna</b>
                  <div class="price">HKD 60,000.00</div>
                  <div class="otherDetails">
                    380(W) x 710(H) x 180(D)
                    <br />陶瓷
                    <br />創作年份: 2010
                    <br />藝術品編號: CER0001
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-CER0002.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Costume Sardegna</b>
                  <div class="price">HKD 45,000.00</div>
                  <div class="otherDetails">
                    410(W) x 550(H) x 220(D)
                    <br />陶瓷
                    <br />創作年份: 2008
                    <br />藝術品編號: CER0002
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-CER0003.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Costume Sardegna</b>
                  <div class="price">HKD 45,000.00</div>
                  <div class="otherDetails">
                    360(W) x 460(H) x 230(D)
                    <br />陶瓷
                    <br />創作年份: 2008
                    <br />藝術品編號: CER0003
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-CER0004.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Estilo Mexicano</b>
                  <div class="price">HKD 30,000.00</div>
                  <div class="otherDetails">
                    330(W) x 440(H) x 150(D)
                    <br />陶瓷
                    <br />創作年份: 2008
                    <br />藝術品編號: CER0004
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-CER0005.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Costume Sardegna</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    290(W) x 280(H) x 110(D)
                    <br />陶瓷
                    <br />創作年份: 2013
                    <br />藝術品編號: CER0005
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-CER0006.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Green Owl</b>
                  <div class="price">HKD 5,000.00</div>
                  <div class="otherDetails">
                    180(W) x 160(H) x 90(D)
                    <br />陶瓷
                    <br />創作年份: 2010
                    <br />藝術品編號: CER0006
                  </div>
                </div>
              </li>
            </ul>

            <h2>拼貼畫</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0001.jpg'" />
                  </div>
                </div>
                <div>
                  <b>博多舊街道</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    180(W) x 180(H)
                    <br />拼貼畫
                    <br />創作年份: 2019
                    <br />藝術品編號: COL0001
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0002.jpg'" />
                  </div>
                </div>
                <div>
                  <b>倉敷午後</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    190(W) x 190(H)
                    <br />拼貼畫
                    <br />創作年份: 2019
                    <br />藝術品編號: COL0002
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0003.jpg'" />
                  </div>
                </div>
                <div>
                  <b>瑞雪</b>
                  <div class="price">HKD 9,000.00</div>
                  <div class="otherDetails">
                    170(W) x 180(H)
                    <br />拼貼畫
                    <br />創作年份: 2013
                    <br />藝術品編號: COL0003
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0004.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Red in the Snow</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    150(W) x 125(H)
                    <br />拼貼畫
                    <br />創作年份: 2018
                    <br />藝術品編號: COL0004
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0005.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Hang Zhou Memory</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    105(W) x 165(H)
                    <br />拼貼畫
                    <br />創作年份: 2018
                    <br />藝術品編號: COL0005
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0006.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Winter Snow</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    165(W) x 165(H)
                    <br />拼貼畫
                    <br />創作年份: 2018
                    <br />藝術品編號: COL0006
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0007.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Hidden House</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    140(W) x 170(H)
                    <br />拼貼畫
                    <br />創作年份: 2018
                    <br />藝術品編號: COL0007
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0008.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Nagoya</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    165(W) x 165(H)
                    <br />拼貼畫
                    <br />創作年份: 2019
                    <br />藝術品編號: COL0008
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0009.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Mauritius</b>
                  <div class="price">HKD 6,800.00</div>
                  <div class="otherDetails">
                    140(W) x 165(H)
                    <br />拼貼畫
                    <br />創作年份: 2019
                    <br />藝術品編號: COL0009
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0010.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Dancing Show in Mauritius</b>
                  <div class="price">HKD 13,800.00</div>
                  <div class="otherDetails">
                    160(W) x 165(H)
                    <br />拼貼畫
                    <br />創作年份: 2019
                    <br />藝術品編號: COL0010
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0011.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Bird Study in Madagascar</b>
                  <div class="price">HKD 7,500.00</div>
                  <div class="otherDetails">
                    160(W) x 165(H)
                    <br />拼貼畫
                    <br />創作年份: 2019
                    <br />藝術品編號: COL0011
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0012.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Madagascar</b>
                  <div class="price">HKD 6,500.00</div>
                  <div class="otherDetails">
                    150(W) x 170(H)
                    <br />拼貼畫
                    <br />創作年份: 2019
                    <br />藝術品編號: COL0012
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0013.jpg'" />
                  </div>
                </div>
                <div>
                  <b>On the Beach</b>
                  <div class="price">HKD 6,500.00</div>
                  <div class="otherDetails">
                    155(W) x 170(H)
                    <br />拼貼畫
                    <br />創作年份: 2015
                    <br />藝術品編號: COL0013
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0014.jpg'" />
                  </div>
                </div>
                <div>
                  <b>House in Bangkok</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    160(W) x 165(H)
                    <br />拼貼畫
                    <br />創作年份: 2015
                    <br />藝術品編號: COL0014
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0015.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Madagascar</b>
                  <div class="price">HKD 7,000.00</div>
                  <div class="otherDetails">
                    105(W) x 150(H)
                    <br />拼貼畫
                    <br />創作年份: 2019
                    <br />藝術品編號: COL0015
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0016.jpg'" />
                  </div>
                </div>
                <div>
                  <b>The Black Cow, Madagascar</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    155(W) x 120(H)
                    <br />拼貼畫
                    <br />創作年份: 2019
                    <br />藝術品編號: COL0016
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0017.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Plovdiv, Bulgaria</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    180(W) x 180(H)
                    <br />拼貼畫
                    <br />創作年份: 2018
                    <br />藝術品編號: COL0017
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0018.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Tai Chung</b>
                  <div class="price">HKD 7,000.00</div>
                  <div class="otherDetails">
                    165(W) x 165(H)
                    <br />拼貼畫
                    <br />創作年份: 2018
                    <br />藝術品編號: COL0018
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0019.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Cong Café, Vietnam</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    155(W) x 165(H)
                    <br />拼貼畫
                    <br />創作年份: 2019
                    <br />藝術品編號: COL0019
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0020.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Tai Chung</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    150(W) x 165(H)
                    <br />拼貼畫
                    <br />創作年份: 2016
                    <br />藝術品編號: COL0020
                  </div>
                </div>
              </li>
            </ul>

            <h2>畫</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0001.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Andasibe Madagascar</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    120(W) x 160(H)
                    <br />彩色鉛筆畫
                    <br />創作年份: 2019
                    <br />藝術品編號: DRA0001
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0002.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Andasibe Madagascar</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    120(W) x 160(H)
                    <br />彩色鉛筆畫
                    <br />創作年份: 2019
                    <br />藝術品編號: DRA0002
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0003.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Andasibe Madagascar</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    120(W) x 160(H)
                    <br />彩色鉛筆畫
                    <br />創作年份: 2019
                    <br />藝術品編號: DRA0003
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0004.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Andasibe Madagascar</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    120(W) x 160(H)
                    <br />彩色鉛筆及粉彩畫
                    <br />創作年份: 2019
                    <br />藝術品編號: DRA0004
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0005.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Alicante, Spain</b>
                  <div class="price">HKD 18,000.00</div>
                  <div class="otherDetails">
                    300(W) x 370(H)
                    <br />水墨及鋼筆畫
                    <br />創作年份: 2017
                    <br />藝術品編號: DRA0005
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0006.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Fisherman's House in Hastings, UK</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    305(W) x 230(H)
                    <br />綜合媒材
                    <br />創作年份: 2008
                    <br />藝術品編號: DRA0006
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0007.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta in Ail</b>
                  <div class="price">HKD 8,500.00</div>
                  <div class="otherDetails">
                    165(W) x 230(H)
                    <br />彩色鉛筆和水彩畫
                    <br />創作年份: 2018
                    <br />藝術品編號: DRA0007
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0008.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Aveiro Dream</b>
                  <div class="price">HKD 8,200.00</div>
                  <div class="otherDetails">
                    235(W) x 160(H)
                    <br />綜合媒材
                    <br />創作年份: 2020
                    <br />藝術品編號: DRA0008
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0009.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Pasticcio di Modigliani #1</b>
                  <div class="price">HKD 13,000.00</div>
                  <div class="otherDetails">
                    210(W) x 295(H)
                    <br />綜合媒材 [畫板]
                    <br />創作年份: 2017
                    <br />藝術品編號: DRA0009
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0010.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Pasticcio di Modigliani #2</b>
                  <div class="price">HKD 13,000.00</div>
                  <div class="otherDetails">
                    175(W) x 295(H)
                    <br />綜合媒材 [畫板]
                    <br />創作年份: 2011
                    <br />藝術品編號: DRA0010
                  </div>
                </div>
              </li>
            </ul>

            <h2>玻璃畫</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-GLA0001.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Perugia</b>
                  <div class="price">HKD 16,000.00</div>
                  <div class="otherDetails">
                    265(W) x 265(H) x 65(D)
                    <br />玻璃擺設
                    <br />創作年份:
                    <span class="na">-</span>
                    <br />藝術品編號: GLA0001
                  </div>
                </div>
              </li>
            </ul>

            <h2>油畫</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0001.jpg'" />
                  </div>
                </div>
                <div>
                  <b>蔚藍海岸的回憶</b>
                  <div class="price">HKD 60,000.00</div>
                  <div class="otherDetails">
                    760(W) x 610(H)
                    <br />油畫 [畫布]
                    <br />創作年份: 2019
                    <br />藝術品編號: OIL0001
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0002.jpg'" />
                  </div>
                </div>
                <div>
                  <b>雪景</b>
                  <div class="price">HKD 18,000.00</div>
                  <div class="otherDetails">
                    290(W) x 200(H)
                    <br />油畫 [畫布]
                    <br />創作年份: 2013
                    <br />藝術品編號: OIL0002
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0003.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Morocco</b>
                  <div class="price">HKD 30,000.00</div>
                  <div class="otherDetails">
                    505(W) x 610(H)
                    <br />油畫 [畫布]
                    <br />創作年份: 2020
                    <br />藝術品編號: OIL0003
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0004.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Morocco</b>
                  <div class="price">HKD 28,000.00</div>
                  <div class="otherDetails">
                    410(W) x 510(H)
                    <br />油畫 [畫布]
                    <br />創作年份: 2020
                    <br />藝術品編號: OIL0004
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0005.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Morocco</b>
                  <div class="price">HKD 14,500.00</div>
                  <div class="otherDetails">
                    360(W) x 250(H)
                    <br />油畫 [畫布]
                    <br />創作年份: 2020
                    <br />藝術品編號: OIL0005
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0006.jpg'" />
                  </div>
                </div>
                <div>
                  <b>澳門大堂之夜</b>
                  <div class="price">HKD 19,500.00</div>
                  <div class="otherDetails">
                    450(W) x 480(H)
                    <br />油畫 [畫布]
                    <br />創作年份: 2014
                    <br />藝術品編號: OIL0006
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0007.jpg'" />
                  </div>
                </div>
                <div>
                  <b>意大利靜物</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    250(W) x 200(H)
                    <br />油畫 [畫板]
                    <br />創作年份: 2019
                    <br />藝術品編號: OIL0007
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0008.jpg'" />
                  </div>
                </div>
                <div>
                  <b>畫室一隅</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    250(W) x 200(H)
                    <br />油畫 [畫板]
                    <br />創作年份: 2019
                    <br />藝術品編號: OIL0008
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0009.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Stile Morocco</b>
                  <div class="price">HKD 22,000.00</div>
                  <div class="otherDetails">
                    610(W) x 300(H)
                    <br />油畫 [畫布]
                    <br />創作年份: 2018
                    <br />藝術品編號: OIL0009
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0010.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Snow Love</b>
                  <div class="price">HKD 26,000.00</div>
                  <div class="otherDetails">
                    500(W) x 600(H)
                    <br />油畫 [畫布]
                    <br />創作年份: 2021
                    <br />藝術品編號: OIL0010
                  </div>
                </div>
              </li>
              <!--<li>
					<div class="img-top mgb27">
						<div class="mood-img">
							<img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0011.jpg'">
						</div>
					</div>
					<div>
						<b>Due in Montmarte</b>
						<div class="price">HKD 12,250.00</div>
						<div class="otherDetails">
							250(W) x 300(H)<br>
							油畫 [畫布]<br>
							創作年份: 2021<br>
							藝術品編號: OIL0011
						</div>
					</div>
              </li>-->
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0012.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Ethiopia Coffee Time</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    200(W) x 250(H)
                    <br />油畫 [畫布]
                    <br />創作年份: 2021
                    <br />藝術品編號: OIL0012
                  </div>
                </div>
              </li>
            </ul>

            <h2>粉彩畫</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0001.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Nice</b>
                  <div class="price">HKD 12,600.00</div>
                  <div class="otherDetails">
                    315(W) x 240(H)
                    <br />粉彩畫 [卡紙]
                    <br />創作年份: 2018
                    <br />藝術品編號: PAS0001
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0002.jpg'" />
                  </div>
                </div>
                <div>
                  <b>悠閒一刻</b>
                  <div class="price">HKD 32,000.00</div>
                  <div class="otherDetails">
                    395(W) x 195(H)
                    <br />綜合媒材
                    <br />創作年份: 2019
                    <br />藝術品編號: PAS0002
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0003.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Cubita</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    290(W) x 275(H)
                    <br />綜合媒材
                    <br />創作年份: 2019
                    <br />藝術品編號: PAS0003
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0004.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Homage to Miro</b>
                  <div class="price">HKD 12,800.00</div>
                  <div class="otherDetails">
                    290(W) x 240(H)
                    <br />綜合媒材
                    <br />創作年份: 2019
                    <br />藝術品編號: PAS0004
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0005.jpg'" />
                  </div>
                </div>
                <div>
                  <b>有結他的靜物</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    180(W) x180(H)
                    <br />水彩及粉彩畫 [畫板]
                    <br />創作年份: 2019
                    <br />藝術品編號: PAS0005
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0006.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Light & Dark</b>
                  <div class="price">HKD 9,000.00</div>
                  <div class="otherDetails">
                    290(W) x 210(H)
                    <br />粉彩畫 [黑色畫紙]
                    <br />創作年份:
                    <span class="na">-</span>
                    <br />藝術品編號: PAS0006
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0007.jpg'" />
                  </div>
                </div>
                <div>
                  <b>向畢卡索禮讚</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    310(W) x 215(H)
                    <br />水彩及粉彩畫 [畫板]
                    <br />創作年份: 2019
                    <br />藝術品編號: PAS0007
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0008.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta con Lemoniamo</b>
                  <div class="price">HKD 12,800.00</div>
                  <div class="otherDetails">
                    265(W) x 370(H)
                    <br />粉彩畫 [卡紙]
                    <br />創作年份: 2016
                    <br />藝術品編號: PAS0008
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0009.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Colour Rhythm</b>
                  <div class="price">HKD 16,800.00</div>
                  <div class="otherDetails">
                    265(W) x 370(H)
                    <br />粉彩畫 [黑色畫紙]
                    <br />創作年份: 2016
                    <br />藝術品編號: PAS0009
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0010.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Plovdiv, Bulgaria</b>
                  <div class="price">HKD 22,000.00</div>
                  <div class="otherDetails">
                    280(W) x 340(H)
                    <br />粉彩畫 [畫板]
                    <br />創作年份: 2016
                    <br />藝術品編號: PAS0010
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0011.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Wild Leaves</b>
                  <div class="price">HKD 60,000.00</div>
                  <div class="otherDetails">
                    585(W) x 430(H)
                    <br />粉彩畫 [畫紙]
                    <br />創作年份: 1987
                    <br />藝術品編號: PAS0011
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0012.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Wild Flowers</b>
                  <div class="price">HKD 60,000.00</div>
                  <div class="otherDetails">
                    560(W) x 380(H)
                    <br />粉彩畫 [畫紙]
                    <br />創作年份: 1987
                    <br />藝術品編號: PAS0012
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0013.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Artist & Nature</b>
                  <div class="price">HKD 18,000.00</div>
                  <div class="otherDetails">
                    290(W) x 380(H)
                    <br />粉彩畫 [卡紙]
                    <br />創作年份: 2009
                    <br />藝術品編號: PAS0013
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0014.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Happy Hour</b>
                  <div class="price">HKD 26,800.00</div>
                  <div class="otherDetails">
                    495(W) x 325(H)
                    <br />粉彩畫 [卡紙]
                    <br />創作年份: 2019
                    <br />藝術品編號: PAS0014
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0015.jpg'" />
                  </div>
                </div>
                <div>
                  <b>A Familiar Corner Pastel on Card</b>
                  <div class="price">HKD 26,800.00</div>
                  <div class="otherDetails">
                    495(W) x 325(H)
                    <br />粉彩畫 [卡紙]
                    <br />創作年份: 2019
                    <br />藝術品編號: PAS0015
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0016.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Jazz Moments</b>
                  <div class="price">HKD 26,800.00</div>
                  <div class="otherDetails">
                    495(W) x 325(H)
                    <br />粉彩畫 [卡紙]
                    <br />創作年份: 2019
                    <br />藝術品編號: PAS0016
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0017.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Night Music</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    320(W) x 245(H)
                    <br />粉彩畫 [卡紙]
                    <br />創作年份: 2019
                    <br />藝術品編號: PAS0017
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0018.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Still Life in Studio</b>
                  <div class="price">HKD 23,000.00</div>
                  <div class="otherDetails">
                    370(W) x 520(H)
                    <br />粉彩畫 [卡紙]
                    <br />創作年份: 2005
                    <br />藝術品編號: PAS0018
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0019.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Mascherata #2</b>
                  <div class="price">HKD 19,800.00</div>
                  <div class="otherDetails">
                    310(W) x 480(H)
                    <br />粉彩畫 [卡紙]
                    <br />創作年份: 2020
                    <br />藝術品編號: PAS0019
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0020.jpg'" />
                  </div>
                </div>
                <div>
                  <b>黑與黃</b>
                  <div class="price">HKD 9,000.00</div>
                  <div class="otherDetails">
                    200(W) x 300(H)
                    <br />綜合媒材 [畫板]
                    <br />創作年份: 2015
                    <br />藝術品編號: PAS0020
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0021.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Homage to Picasso II</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    230(W) x 305(H)
                    <br />彩色鉛筆畫 [卡紙]
                    <br />創作年份: 2017
                    <br />藝術品編號: PAS0021
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0022.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Still Life from Portugal</b>
                  <div class="price">HKD 17,000.00</div>
                  <div class="otherDetails">
                    240(W) x 315(H)
                    <br />粉彩畫 [卡紙]
                    <br />創作年份: 2018
                    <br />藝術品編號: PAS0022
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0023.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Homage to Picasso I</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    255(W) x 355(H)
                    <br />彩色鉛筆及粉彩畫
                    <br />創作年份: 2017
                    <br />藝術品編號: PAS0023
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0024.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Chianti</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    290(W) x 210(H)
                    <br />粉彩畫 [黑色畫紙]
                    <br />創作年份: 2015
                    <br />藝術品編號: PAS0024
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0025.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Artist & Nature Series (Picasso)</b>
                  <div class="price">HKD 23,000.00</div>
                  <div class="otherDetails">
                    290(W) x 390(H)
                    <br />粉彩畫 [卡紙]
                    <br />創作年份: 2009
                    <br />藝術品編號: PAS0025
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0026.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Stilo, Italy</b>
                  <div class="price">HKD 30,000.00</div>
                  <div class="otherDetails">
                    315(W) x 240(H)
                    <br />粉彩畫 [畫紙]
                    <br />創作年份:
                    <span class="na">-</span>
                    <br />藝術品編號: PAS0026
                  </div>
                </div>
              </li>
            </ul>

            <h2>水彩畫</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0001.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Nice Nightscene</b>
                  <div class="price">HKD 22,800.00</div>
                  <div class="otherDetails">
                    305(W) x 405(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 2018
                    <br />藝術品編號: WAT0001
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0002.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Village du Luberon</b>
                  <div class="price">HKD 9,800.00</div>
                  <div class="otherDetails">
                    145(W) x 150(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 2018
                    <br />藝術品編號: WAT0002
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0003.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Szentendre</b>
                  <div class="price">HKD 13,000.00</div>
                  <div class="otherDetails">
                    300(W) x 300(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 2015
                    <br />藝術品編號: WAT0003
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0004.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Cuban Time</b>
                  <div class="price">HKD 15,400.00</div>
                  <div class="otherDetails">
                    320(W) x 240(H)
                    <br />綜合媒材
                    <br />創作年份: 2019
                    <br />藝術品編號: WAT0004
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0005.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Still Life</b>
                  <div class="price">HKD 13,800.00</div>
                  <div class="otherDetails">
                    190(W) x 265(H)
                    <br />水粉畫 [畫布]
                    <br />創作年份: 2019
                    <br />藝術品編號: WAT0005
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0006.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Hangzhou</b>
                  <div class="price">HKD 9,100.00</div>
                  <div class="otherDetails">
                    165(W) x 150(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 2018
                    <br />藝術品編號: WAT0006
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0007.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Hangzhou</b>
                  <div class="price">HKD 11,800.00</div>
                  <div class="otherDetails">
                    170(W) x 145(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 2018
                    <br />藝術品編號: WAT0007
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0008.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Hangzhou</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    180(W) x 155(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 2018
                    <br />藝術品編號: WAT0008
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0009.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Hangzhou</b>
                  <div class="price">HKD 9,100.00</div>
                  <div class="otherDetails">
                    165(W) x 150(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 2018
                    <br />藝術品編號: WAT0009
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0010.jpg'" />
                  </div>
                </div>
                <div>
                  <b>The Cubist Epoch</b>
                  <div class="price">HKD 16,600.00</div>
                  <div class="otherDetails">
                    290(W) x 305(H)
                    <br />水粉畫 [畫板]
                    <br />創作年份: 2019
                    <br />藝術品編號: WAT0010
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0011.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Okinawa</b>
                  <div class="price">HKD 16,600.00</div>
                  <div class="otherDetails">
                    305(W) x 330(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 2010
                    <br />藝術品編號: WAT0011
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0012.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta</b>
                  <div class="price">HKD 15,600.00</div>
                  <div class="otherDetails">
                    245(W) x 290(H)
                    <br />水粉畫 [畫板]
                    <br />創作年份: 2019
                    <br />藝術品編號: WAT0012
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0013.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Madagascar Parsonie Cameleon #1</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    190(W) x 150(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 2019
                    <br />藝術品編號: WAT0013
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0014.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Madagascar Parsonie Cameleon #2</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    150(W) x 175(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 2019
                    <br />藝術品編號: WAT0014
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0015.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta in Studio</b>
                  <div class="price">HKD 16,000.00</div>
                  <div class="otherDetails">
                    340(W) x 280(H)
                    <br />綜合媒材
                    <br />創作年份: 2016
                    <br />藝術品編號: WAT0015
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0016.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta Turca</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    240(W) x 280(H)
                    <br />水粉畫 [畫板]
                    <br />創作年份: 2018
                    <br />藝術品編號: WAT0016
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0017.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Aveiro Dream #1</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    230(W) x 155(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 2020
                    <br />藝術品編號: WAT0017
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0018.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Aveiro Dream #2</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    235(W) x 160(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 2020
                    <br />藝術品編號: WAT0018
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0019.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Madagascar Dream</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    235(W) x 160(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 2020
                    <br />藝術品編號: WAT0019
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0020.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Marseille</b>
                  <div class="price">HKD 22,000.00</div>
                  <div class="otherDetails">
                    265(W) x 265(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 2019
                    <br />藝術品編號: WAT0020
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0021.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Monte Carlo</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    265(W) x 325(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 2020
                    <br />藝術品編號: WAT0021
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0022.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Dancing Leaves #1</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    270(W) x 195(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 2020
                    <br />藝術品編號: WAT0022
                  </div>
                </div>
              </li>
              <!--<li>
					<div class="img-top mgb27">
						<div class="mood-img">
							<img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0023.jpg'">
						</div>
					</div>
					<div>
						<b>Natura Morta <h6>已被預留至2021年11月25日</h6></b>
						<div class="price">HKD 10,000.00</div>
						<div class="otherDetails">
							250(W) x 200(H)<br>
							綜合媒材 [畫板]<br>
							創作年份: 2020<br>
							藝術品編號: WAT0023
						</div>
					</div>
              </li>-->
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0024.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Still Life</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    280(W) x 200(H)
                    <br />綜合媒材 [畫板]
                    <br />創作年份: 2020
                    <br />藝術品編號: WAT0024
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0025.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Madagascar</b>
                  <div class="price">HKD 9,800.00</div>
                  <div class="otherDetails">
                    205(W) x 150(H)
                    <br />綜合媒材
                    <br />創作年份: 2019
                    <br />藝術品編號: WAT0025
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0026.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Morning Bird</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    200(W) x 290(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 2017
                    <br />藝術品編號: WAT0026
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0027.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Turkmenistan</b>
                  <div class="price">HKD 11,800.00</div>
                  <div class="otherDetails">
                    195(W) x 300(H)
                    <br />水粉畫 [畫板]
                    <br />創作年份: 2018
                    <br />藝術品編號: WAT0027
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0028.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Sofia</b>
                  <div class="price">HKD 10,200.00</div>
                  <div class="otherDetails">
                    240(W) x 200(H)
                    <br />綜合媒材 [畫板]
                    <br />創作年份: 2016
                    <br />藝術品編號: WAT0028
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0029.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Mel de Muntanya</b>
                  <div class="price">HKD 9,000.00</div>
                  <div class="otherDetails">
                    265(W) x 230(H)
                    <br />鋼筆畫
                    <br />創作年份: 2018
                    <br />藝術品編號: WAT0029
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0030.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Monte Carlo</b>
                  <div class="price">HKD 19,800.00</div>
                  <div class="otherDetails">
                    410(W) x 265(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 2019
                    <br />藝術品編號: WAT0030
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0031.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta</b>
                  <div class="price">HKD 13,300.00</div>
                  <div class="otherDetails">
                    290(W) x 285(H)
                    <br />綜合媒材 [畫板]
                    <br />創作年份: 2015
                    <br />藝術品編號: WAT0031
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0032.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Cannes</b>
                  <div class="price">HKD 16,800.00</div>
                  <div class="otherDetails">
                    410(W) x 320(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 2019
                    <br />藝術品編號: WAT0032
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0033.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Little India, Singapore</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    410(W) x 320(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 1997
                    <br />藝術品編號: WAT0033
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0034.jpg'" />
                  </div>
                </div>
                <div>
                  <b>EZE Village</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    370(W) x 310(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 2019
                    <br />藝術品編號: WAT0034
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0035.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Aix en Provence</b>
                  <div class="price">HKD 13,800.00</div>
                  <div class="otherDetails">
                    410(W) x 275(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 2019
                    <br />藝術品編號: WAT0035
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0036.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Visite a Menton</b>
                  <div class="price">HKD 16,800.00</div>
                  <div class="otherDetails">
                    410(W) x 290(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 2019
                    <br />藝術品編號: WAT0036
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0037.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Nice</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    350(W) x 320(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 2019
                    <br />藝術品編號: WAT0037
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0038.jpg'" />
                  </div>
                </div>
                <div>
                  <b>From Biot to Marseille</b>
                  <div class="price">HKD 13,800.00</div>
                  <div class="otherDetails">
                    410(W) x 240(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 2019
                    <br />藝術品編號: WAT0038
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0039.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Homage to Miro</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    205(W) x 290(H)
                    <br />水粉畫 [畫板]
                    <br />創作年份: 2019
                    <br />藝術品編號: WAT0039
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0040.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Homage to Picasso</b>
                  <div class="price">HKD 16,000.00</div>
                  <div class="otherDetails">
                    190(W) x 265(H)
                    <br />水粉畫 [畫板]
                    <br />創作年份: 2019
                    <br />藝術品編號: WAT0040
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0041.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Morocco</b>
                  <div class="price">HKD 9,000.00</div>
                  <div class="otherDetails">
                    160(W) x 230(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 2020
                    <br />藝術品編號: WAT0041
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0042.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Morocco</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    160(W) x 230(H)
                    <br />水彩畫 [畫紙]
                    <br />創作年份: 2020
                    <br />藝術品編號: WAT0042
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0043.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta Turca</b>
                  <div class="price">HKD 12,800.00</div>
                  <div class="otherDetails">
                    250(W) x 300(H)
                    <br />水粉畫 [畫板]
                    <br />創作年份: 2018
                    <br />藝術品編號: WAT0043
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0044.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta Persian & Spanish (Style)</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    250(W) x 300(H)
                    <br />水粉畫 [畫板]
                    <br />創作年份: 2018
                    <br />藝術品編號: WAT0044
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0045.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta Turca</b>
                  <div class="price">HKD 8,800.00</div>
                  <div class="otherDetails">
                    210(W) x 280(H)
                    <br />水粉畫 [畫板]
                    <br />創作年份: 2018
                    <br />藝術品編號: WAT0045
                  </div>
                </div>
              </li>
            </ul>

            <h2>水彩及水墨</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0001.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Plovdiv, Bulgaria</b>
                  <div class="price">HKD 50,000.00</div>
                  <div class="otherDetails">
                    635(W) x 455(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2018
                    <br />藝術品編號: WCI0001
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0002.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Still Life with Wampi</b>
                  <div class="price">HKD 36,000.00</div>
                  <div class="otherDetails">
                    380(W) x 330(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2018
                    <br />藝術品編號: WCI0002
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0003.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Vence</b>
                  <div class="price">HKD 32,000.00</div>
                  <div class="otherDetails">
                    380(W) x 455(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2010
                    <br />藝術品編號: WCI0003
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0004.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Ceramiche in Sardegna</b>
                  <div class="price">HKD 40,000.00</div>
                  <div class="otherDetails">
                    450(W) x 530(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份:
                    <span class="na">-</span>
                    <br />藝術品編號: WCI0004
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0005.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Vitalita #1 / Vitalita #2</b>
                  <div class="price">HKD 50,000.00</div>
                  <div class="otherDetails">
                    340(W) x 137(H) / 340(W) x 137(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2016
                    <br />藝術品編號: WCI0005 / WCI0006
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0007.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Monte Carlo</b>
                  <div class="price">HKD 32,000.00</div>
                  <div class="otherDetails">
                    460(W) x 590(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2019
                    <br />藝術品編號: WCI0007
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0008.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Autumn Show</b>
                  <div class="price">HKD 32,000.00</div>
                  <div class="otherDetails">
                    600(W) x 450(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2020
                    <br />藝術品編號: WCI0008
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0009.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Pasticcio di Braque</b>
                  <div class="price">HKD 20,000.00</div>
                  <div class="otherDetails">
                    460(W) x 640(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2020
                    <br />藝術品編號: WCI0009
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0010.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Ventimiglia</b>
                  <div class="price">HKD 28,000.00</div>
                  <div class="otherDetails">
                    570(W) x 420(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2019
                    <br />藝術品編號: WCI0010
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0011.jpg'" />
                  </div>
                </div>
                <div>
                  <b>微紅</b>
                  <div class="price">HKD 50,000.00</div>
                  <div class="otherDetails">
                    805(W) x 340(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2016
                    <br />藝術品編號: WCI0011
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0012.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Madonna</b>
                  <div class="price">HKD 30,000.00</div>
                  <div class="otherDetails">
                    525(W) x 370(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2016
                    <br />藝術品編號: WCI0012
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0013.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Stand Still</b>
                  <div class="price">HKD 25,000.00</div>
                  <div class="otherDetails">
                    480(W) x 410(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2020
                    <br />藝術品編號: WCI0013
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0014.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Cermaica</b>
                  <div class="price">HKD 33,000.00</div>
                  <div class="otherDetails">
                    380(W) x 455(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2004
                    <br />藝術品編號: WCI0014
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0015.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Ville de Menton</b>
                  <div class="price">HKD 23,800.00</div>
                  <div class="otherDetails">
                    610(W) x 360(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2019
                    <br />藝術品編號: WCI0015
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0016.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Cap d' Ail</b>
                  <div class="price">HKD 38,000.00</div>
                  <div class="otherDetails">
                    490(W) x 710(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2020
                    <br />藝術品編號: WCI0016
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0017.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Bayamo, Cuba</b>
                  <div class="price">HKD 22,000.00</div>
                  <div class="otherDetails">
                    380(W) x 520(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2014
                    <br />藝術品編號: WCI0017
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0018.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower & Lemons</b>
                  <div class="price">HKD 13,500.00</div>
                  <div class="otherDetails">
                    190(W) x 610(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2015
                    <br />藝術品編號: WCI0018
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0019.jpg'" />
                  </div>
                </div>
                <div>
                  <b>神戶雪景</b>
                  <div class="price">HKD 38,000.00</div>
                  <div class="otherDetails">
                    700(W) x 450(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2005
                    <br />藝術品編號: WCI0019
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0020.jpg'" />
                  </div>
                </div>
                <div>
                  <b>紅與蜜</b>
                  <div class="price">HKD 22,000.00</div>
                  <div class="otherDetails">
                    355(W) x 455(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2015
                    <br />藝術品編號: WCI0020
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0021.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower Study</b>
                  <div class="price">HKD 9,000.00</div>
                  <div class="otherDetails">
                    245(W) x 335(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2021
                    <br />藝術品編號: WCI0021
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0022.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Morocco Tour</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    245(W) x 335(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2020
                    <br />藝術品編號: WCI0022
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0023.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Bird Talk</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    315(W) x 380(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2021
                    <br />藝術品編號: WCI0023
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0024.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower Arrangement #1</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    305(W) x 340(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2021
                    <br />藝術品編號: WCI0024
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0025.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower Arrangement #2</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    380(W) x 435(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2021
                    <br />藝術品編號: WCI0025
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0026.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower Arrangement #3</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    380(W) x 475(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2021
                    <br />藝術品編號: WCI0026
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0027.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Uccello e Fiori</b>
                  <div class="price">HKD 20,000.00</div>
                  <div class="otherDetails">
                    310(W) x 585(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2021
                    <br />藝術品編號: WCI0027
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0028.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower Arrangement #4</b>
                  <div class="price">HKD 25,000.00</div>
                  <div class="otherDetails">
                    515(W) x 460(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2021
                    <br />藝術品編號: WCI0028
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0029.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Bouquet</b>
                  <div class="price">HKD 30,000.00</div>
                  <div class="otherDetails">
                    400(W) x 535(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2021
                    <br />藝術品編號: WCI0029
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0030.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower Arrangement #5</b>
                  <div class="price">HKD 30,000.00</div>
                  <div class="otherDetails">
                    380(W) x 530(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2021
                    <br />藝術品編號: WCI0030
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0031.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower Study</b>
                  <div class="price">HKD 23,000.00</div>
                  <div class="otherDetails">
                    345(W) x 520(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2021
                    <br />藝術品編號: WCI0031
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0032.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower Study</b>
                  <div class="price">HKD 22,000.00</div>
                  <div class="otherDetails">
                    395(W) x 450(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2021
                    <br />藝術品編號: WCI0032
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0033.jpg'" />
                  </div>
                </div>
                <div>
                  <b>La Stazione</b>
                  <div class="price">HKD 34,800.00</div>
                  <div class="otherDetails">
                    590(W) x 460(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2019
                    <br />藝術品編號: WCI0033
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0034.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Amapola</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    385(W) x 355(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2020
                    <br />藝術品編號: WCI0034
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0035.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Day Walk in San Remo</b>
                  <div class="price">HKD 20,000.00</div>
                  <div class="otherDetails">
                    460(W) x 300(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2019
                    <br />藝術品編號: WCI0035
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0036.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Una Strada</b>
                  <div class="price">HKD 18,000.00</div>
                  <div class="otherDetails">
                    300(W) x 460(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2019
                    <br />藝術品編號: WCI0036
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0037.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Imperia</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    290(W) x 460(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2019
                    <br />藝術品編號: WCI0037
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0038.jpg'" />
                  </div>
                </div>
                <div>
                  <b>San Remo</b>
                  <div class="price">HKD 39,000.00</div>
                  <div class="otherDetails">
                    630(W) x 460(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2019
                    <br />藝術品編號: WCI0038
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0039.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Cuba Time</b>
                  <div class="price">HKD 38,000.00</div>
                  <div class="otherDetails">
                    520(W) x 440(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2014
                    <br />藝術品編號: WCI0039
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0040.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Still Life</b>
                  <div class="price">HKD 28,500.00</div>
                  <div class="otherDetails">
                    390(W) x 380(H)
                    <br />水彩及水墨畫 [畫紙]
                    <br />創作年份: 2005
                    <br />藝術品編號: WCI0040
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div v-else>
            <div class="flex col-valign">
              <div>
                <img
                  :src="url + 'promotion/marcoszeto.jpg'"
                  style="width:100%; height:auto;"
                />
              </div>
              <div>
                <div
                  class="col-text"
                  style="max-width:645px; margin:0 auto; text-align:justify;"
                >梁先生的朋友，司徒志明善于利用不同的媒体来表达他心目中的意境，油彩、水彩、水墨、粉彩、钢笔，甚至剪贴，都是他发挥灵感的工具，没有古今中外、传统派系的束缚，信手拈来便成佳作。其中的拼贴画系列是梁先生觉得最有特色的作品。</div>
              </div>
            </div>

            <div style="max-width:990px; margin:85px auto 55px;">
              如有合适的项目，各设计团队亦可向客户推介在项目中使用并可以优惠价 75 折购买以下画作。亦可邀请司徒先生按项目风格及需要订制不同尺寸的画作 / 艺术品，价格另议，而买卖过程中所产生的利润将全数归相关设计团队。
              <br />
              <br />如欲了解详情及索取报价，欢迎各设计团队与高级招采主任 - 朱嘉仪 Wally 联系；各同事如有兴趣购买，请下载及填妥
              <a :href="orderFormUrl" class="fce3">订购表格</a>，并电邮至 Wally (
              <a href="mailto:wally.chu@ed-living.com" class="fce3">wally.chu@ed-living.com</a>)。
              <br />
              <br />

              <div class="tc download-btn">
                <a :href="orderFormUrl" class="btn white">下载订购表格</a>
              </div>

              <br />
              <i>备注: 上述之画作 / 艺术品可按需要预留1个月。</i>
            </div>
            <br />

            <h2>陶瓷彩绘</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-CER0001.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Costume Sardegna</b>
                  <div class="price">HKD 60,000.00</div>
                  <div class="otherDetails">
                    380(W) x 710(H) x 180(D)
                    <br />陶瓷
                    <br />创作年份: 2010
                    <br />艺术品编号: CER0001
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-CER0002.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Costume Sardegna</b>
                  <div class="price">HKD 45,000.00</div>
                  <div class="otherDetails">
                    410(W) x 550(H) x 220(D)
                    <br />陶瓷
                    <br />创作年份: 2008
                    <br />艺术品编号: CER0002
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-CER0003.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Costume Sardegna</b>
                  <div class="price">HKD 45,000.00</div>
                  <div class="otherDetails">
                    360(W) x 460(H) x 230(D)
                    <br />陶瓷
                    <br />创作年份: 2008
                    <br />艺术品编号: CER0003
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-CER0004.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Estilo Mexicano</b>
                  <div class="price">HKD 30,000.00</div>
                  <div class="otherDetails">
                    330(W) x 440(H) x 150(D)
                    <br />陶瓷
                    <br />创作年份: 2008
                    <br />艺术品编号: CER0004
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-CER0005.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Costume Sardegna</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    290(W) x 280(H) x 110(D)
                    <br />陶瓷
                    <br />创作年份: 2013
                    <br />艺术品编号: CER0005
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-CER0006.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Green Owl</b>
                  <div class="price">HKD 5,000.00</div>
                  <div class="otherDetails">
                    180(W) x 160(H) x 90(D)
                    <br />陶瓷
                    <br />创作年份: 2010
                    <br />艺术品编号: CER0006
                  </div>
                </div>
              </li>
            </ul>

            <h2>拼贴画</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0001.jpg'" />
                  </div>
                </div>
                <div>
                  <b>博多旧街道</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    180(W) x 180(H)
                    <br />拼贴画
                    <br />创作年份: 2019
                    <br />艺术品编号: COL0001
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0002.jpg'" />
                  </div>
                </div>
                <div>
                  <b>仓敷下午</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    190(W) x 190(H)
                    <br />拼贴画
                    <br />创作年份: 2019
                    <br />艺术品编号: COL0002
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0003.jpg'" />
                  </div>
                </div>
                <div>
                  <b>瑞雪</b>
                  <div class="price">HKD 9,000.00</div>
                  <div class="otherDetails">
                    170(W) x 180(H)
                    <br />拼贴画
                    <br />创作年份: 2013
                    <br />艺术品编号: COL0003
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0004.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Red in the Snow</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    150(W) x 125(H)
                    <br />拼贴画
                    <br />创作年份: 2018
                    <br />艺术品编号: COL0004
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0005.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Hang Zhou Memory</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    105(W) x 165(H)
                    <br />拼贴画
                    <br />创作年份: 2018
                    <br />艺术品编号: COL0005
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0006.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Winter Snow</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    165(W) x 165(H)
                    <br />拼贴画
                    <br />创作年份: 2018
                    <br />艺术品编号: COL0006
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0007.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Hidden House</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    140(W) x 170(H)
                    <br />拼贴画
                    <br />创作年份: 2018
                    <br />艺术品编号: COL0007
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0008.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Nagoya</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    165(W) x 165(H)
                    <br />拼贴画
                    <br />创作年份: 2019
                    <br />艺术品编号: COL0008
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0009.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Mauritius</b>
                  <div class="price">HKD 6,800.00</div>
                  <div class="otherDetails">
                    140(W) x 165(H)
                    <br />拼贴画
                    <br />创作年份: 2019
                    <br />艺术品编号: COL0009
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0010.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Dancing Show in Mauritius</b>
                  <div class="price">HKD 13,800.00</div>
                  <div class="otherDetails">
                    160(W) x 165(H)
                    <br />拼贴画
                    <br />创作年份: 2019
                    <br />艺术品编号: COL0010
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0011.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Bird Study in Madagascar</b>
                  <div class="price">HKD 7,500.00</div>
                  <div class="otherDetails">
                    160(W) x 165(H)
                    <br />拼贴画
                    <br />创作年份: 2019
                    <br />艺术品编号: COL0011
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0012.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Madagascar</b>
                  <div class="price">HKD 6,500.00</div>
                  <div class="otherDetails">
                    150(W) x 170(H)
                    <br />拼贴画
                    <br />创作年份: 2019
                    <br />艺术品编号: COL0012
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0013.jpg'" />
                  </div>
                </div>
                <div>
                  <b>On the Beach</b>
                  <div class="price">HKD 6,500.00</div>
                  <div class="otherDetails">
                    155(W) x 170(H)
                    <br />拼贴画
                    <br />创作年份: 2015
                    <br />艺术品编号: COL0013
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0014.jpg'" />
                  </div>
                </div>
                <div>
                  <b>House in Bangkok</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    160(W) x 165(H)
                    <br />拼贴画
                    <br />创作年份: 2015
                    <br />艺术品编号: COL0014
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0015.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Madagascar</b>
                  <div class="price">HKD 7,000.00</div>
                  <div class="otherDetails">
                    105(W) x 150(H)
                    <br />拼贴画
                    <br />创作年份: 2019
                    <br />艺术品编号: COL0015
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0016.jpg'" />
                  </div>
                </div>
                <div>
                  <b>The Black Cow, Madagascar</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    155(W) x 120(H)
                    <br />拼贴画
                    <br />创作年份: 2019
                    <br />艺术品编号: COL0016
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0017.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Plovdiv, Bulgaria</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    180(W) x 180(H)
                    <br />拼贴画
                    <br />创作年份: 2018
                    <br />艺术品编号: COL0017
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0018.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Tai Chung</b>
                  <div class="price">HKD 7,000.00</div>
                  <div class="otherDetails">
                    165(W) x 165(H)
                    <br />拼贴画
                    <br />创作年份: 2018
                    <br />艺术品编号: COL0018
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0019.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Cong Café, Vietnam</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    155(W) x 165(H)
                    <br />拼贴画
                    <br />创作年份: 2019
                    <br />艺术品编号: COL0019
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-COL0020.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Tai Chung</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    150(W) x 165(H)
                    <br />拼贴画
                    <br />创作年份: 2016
                    <br />艺术品编号: COL0020
                  </div>
                </div>
              </li>
            </ul>

            <h2>画</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0001.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Andasibe Madagascar</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    120(W) x 160(H)
                    <br />彩色铅笔画
                    <br />创作年份: 2019
                    <br />艺术品编号: DRA0001
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0002.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Andasibe Madagascar</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    120(W) x 160(H)
                    <br />彩色铅笔画
                    <br />创作年份: 2019
                    <br />艺术品编号: DRA0002
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0003.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Andasibe Madagascar</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    120(W) x 160(H)
                    <br />彩色铅笔画
                    <br />创作年份: 2019
                    <br />艺术品编号: DRA0003
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0004.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Andasibe Madagascar</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    120(W) x 160(H)
                    <br />彩色铅笔及粉彩画
                    <br />创作年份: 2019
                    <br />艺术品编号: DRA0004
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0005.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Alicante, Spain</b>
                  <div class="price">HKD 18,000.00</div>
                  <div class="otherDetails">
                    300(W) x 370(H)
                    <br />水墨及钢笔画
                    <br />创作年份: 2017
                    <br />艺术品编号: DRA0005
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0006.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Fisherman's House in Hastings, UK</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    305(W) x 230(H)
                    <br />综合媒材
                    <br />创作年份: 2008
                    <br />艺术品编号: DRA0006
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0007.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta in Ail</b>
                  <div class="price">HKD 8,500.00</div>
                  <div class="otherDetails">
                    165(W) x 230(H)
                    <br />彩色铅笔和水彩画
                    <br />创作年份: 2018
                    <br />艺术品编号: DRA0007
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0008.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Aveiro Dream</b>
                  <div class="price">HKD 8,200.00</div>
                  <div class="otherDetails">
                    235(W) x 160(H)
                    <br />综合媒材
                    <br />创作年份: 2020
                    <br />艺术品编号: DRA0008
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0009.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Pasticcio di Modigliani #1</b>
                  <div class="price">HKD 13,000.00</div>
                  <div class="otherDetails">
                    210(W) x 295(H)
                    <br />综合媒材 [画板]
                    <br />创作年份: 2017
                    <br />艺术品编号: DRA0009
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-DRA0010.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Pasticcio di Modigliani #2</b>
                  <div class="price">HKD 13,000.00</div>
                  <div class="otherDetails">
                    175(W) x 295(H)
                    <br />综合媒材 [画板]
                    <br />创作年份: 2011
                    <br />艺术品编号: DRA0010
                  </div>
                </div>
              </li>
            </ul>

            <h2>玻璃画</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-GLA0001.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Perugia</b>
                  <div class="price">HKD 16,000.00</div>
                  <div class="otherDetails">
                    265(W) x 265(H) x 65(D)
                    <br />玻璃摆设
                    <br />创作年份:
                    <span class="na">-</span>
                    <br />艺术品编号: GLA0001
                  </div>
                </div>
              </li>
            </ul>

            <h2>油画</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0001.jpg'" />
                  </div>
                </div>
                <div>
                  <b>蔚蓝海岸的回忆</b>
                  <div class="price">HKD 60,000.00</div>
                  <div class="otherDetails">
                    760(W) x 610(H)
                    <br />油画 [画布]
                    <br />创作年份: 2019
                    <br />艺术品编号: OIL0001
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0002.jpg'" />
                  </div>
                </div>
                <div>
                  <b>雪景</b>
                  <div class="price">HKD 18,000.00</div>
                  <div class="otherDetails">
                    290(W) x 200(H)
                    <br />油画 [画布]
                    <br />创作年份: 2013
                    <br />艺术品编号: OIL0002
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0003.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Morocco</b>
                  <div class="price">HKD 30,000.00</div>
                  <div class="otherDetails">
                    505(W) x 610(H)
                    <br />油画 [画布]
                    <br />创作年份: 2020
                    <br />艺术品编号: OIL0003
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0004.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Morocco</b>
                  <div class="price">HKD 28,000.00</div>
                  <div class="otherDetails">
                    410(W) x 510(H)
                    <br />油画 [画布]
                    <br />创作年份: 2020
                    <br />艺术品编号: OIL0004
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0005.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Morocco</b>
                  <div class="price">HKD 14,500.00</div>
                  <div class="otherDetails">
                    360(W) x 250(H)
                    <br />油画 [画布]
                    <br />创作年份: 2020
                    <br />艺术品编号: OIL0005
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0006.jpg'" />
                  </div>
                </div>
                <div>
                  <b>澳门大堂之夜</b>
                  <div class="price">HKD 19,500.00</div>
                  <div class="otherDetails">
                    450(W) x 480(H)
                    <br />油画 [画布]
                    <br />创作年份: 2014
                    <br />艺术品编号: OIL0006
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0007.jpg'" />
                  </div>
                </div>
                <div>
                  <b>意大利静物</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    250(W) x 200(H)
                    <br />油画 [画板]
                    <br />创作年份: 2019
                    <br />艺术品编号: OIL0007
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0008.jpg'" />
                  </div>
                </div>
                <div>
                  <b>画室一隅</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    250(W) x 200(H)
                    <br />油画 [画板]
                    <br />创作年份: 2019
                    <br />艺术品编号: OIL0008
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0009.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Stile Morocco</b>
                  <div class="price">HKD 22,000.00</div>
                  <div class="otherDetails">
                    610(W) x 300(H)
                    <br />油画 [画布]
                    <br />创作年份: 2018
                    <br />艺术品编号: OIL0009
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0010.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Snow Love</b>
                  <div class="price">HKD 26,000.00</div>
                  <div class="otherDetails">
                    500(W) x 600(H)
                    <br />油画 [画布]
                    <br />创作年份: 2021
                    <br />艺术品编号: OIL0010
                  </div>
                </div>
              </li>
              <!--<li>
					<div class="img-top mgb27">
						<div class="mood-img">
							<img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0011.jpg'">
						</div>
					</div>
					<div>
						<b>Due in Montmarte</b>
						<div class="price">HKD 12,250.00</div>
						<div class="otherDetails">
							250(W) x 300(H)<br>
							油画 [画布]<br>
							创作年份: 2021<br>
							艺术品编号: OIL0011
						</div>
					</div>
              </li>-->
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-OIL0012.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Ethiopia Coffee Time</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    200(W) x 250(H)
                    <br />油画 [画布]
                    <br />创作年份: 2021
                    <br />艺术品编号: OIL0012
                  </div>
                </div>
              </li>
            </ul>

            <h2>粉彩画</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0001.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Nice</b>
                  <div class="price">HKD 12,600.00</div>
                  <div class="otherDetails">
                    315(W) x 240(H)
                    <br />粉彩画 [卡纸]
                    <br />创作年份: 2018
                    <br />艺术品编号: PAS0001
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0002.jpg'" />
                  </div>
                </div>
                <div>
                  <b>悠闲一刻</b>
                  <div class="price">HKD 32,000.00</div>
                  <div class="otherDetails">
                    395(W) x 195(H)
                    <br />综合媒材
                    <br />创作年份: 2019
                    <br />艺术品编号: PAS0002
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0003.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Cubita</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    290(W) x 275(H)
                    <br />综合媒材
                    <br />创作年份: 2019
                    <br />艺术品编号: PAS0003
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0004.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Homage to Miro</b>
                  <div class="price">HKD 12,800.00</div>
                  <div class="otherDetails">
                    290(W) x 240(H)
                    <br />综合媒材
                    <br />创作年份: 2019
                    <br />艺术品编号: PAS0004
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0005.jpg'" />
                  </div>
                </div>
                <div>
                  <b>有结他的静物</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    180(W) x180(H)
                    <br />水彩及粉彩画 [画板]
                    <br />创作年份: 2019
                    <br />艺术品编号: PAS0005
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0006.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Light & Dark</b>
                  <div class="price">HKD 9,000.00</div>
                  <div class="otherDetails">
                    290(W) x 210(H)
                    <br />粉彩画 [黑色画纸]
                    <br />创作年份:
                    <span class="na">-</span>
                    <br />艺术品编号: PAS0006
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0007.jpg'" />
                  </div>
                </div>
                <div>
                  <b>向毕卡索礼赞</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    310(W) x 215(H)
                    <br />水彩及粉彩画 [画板]
                    <br />创作年份: 2019
                    <br />艺术品编号: PAS0007
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0008.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta con Lemoniamo</b>
                  <div class="price">HKD 12,800.00</div>
                  <div class="otherDetails">
                    265(W) x 370(H)
                    <br />粉彩画 [卡纸]
                    <br />创作年份: 2016
                    <br />艺术品编号: PAS0008
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0009.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Colour Rhythm</b>
                  <div class="price">HKD 16,800.00</div>
                  <div class="otherDetails">
                    265(W) x 370(H)
                    <br />粉彩画 [黑色画纸]
                    <br />创作年份: 2016
                    <br />艺术品编号: PAS0009
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0010.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Plovdiv, Bulgaria</b>
                  <div class="price">HKD 22,000.00</div>
                  <div class="otherDetails">
                    280(W) x 340(H)
                    <br />粉彩画 [画板]
                    <br />创作年份: 2016
                    <br />艺术品编号: PAS0010
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0011.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Wild Leaves</b>
                  <div class="price">HKD 60,000.00</div>
                  <div class="otherDetails">
                    585(W) x 430(H)
                    <br />粉彩画 [画纸]
                    <br />创作年份: 1987
                    <br />艺术品编号: PAS0011
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0012.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Wild Flowers</b>
                  <div class="price">HKD 60,000.00</div>
                  <div class="otherDetails">
                    560(W) x 380(H)
                    <br />粉彩画 [画纸]
                    <br />创作年份: 1987
                    <br />艺术品编号: PAS0012
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0013.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Artist & Nature</b>
                  <div class="price">HKD 18,000.00</div>
                  <div class="otherDetails">
                    290(W) x 380(H)
                    <br />粉彩画 [卡纸]
                    <br />创作年份: 2009
                    <br />艺术品编号: PAS0013
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0014.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Happy Hour</b>
                  <div class="price">HKD 26,800.00</div>
                  <div class="otherDetails">
                    495(W) x 325(H)
                    <br />粉彩画 [卡纸]
                    <br />创作年份: 2019
                    <br />艺术品编号: PAS0014
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0015.jpg'" />
                  </div>
                </div>
                <div>
                  <b>A Familiar Corner Pastel on Card</b>
                  <div class="price">HKD 26,800.00</div>
                  <div class="otherDetails">
                    495(W) x 325(H)
                    <br />粉彩画 [卡纸]
                    <br />创作年份: 2019
                    <br />艺术品编号: PAS0015
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0016.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Jazz Moments</b>
                  <div class="price">HKD 26,800.00</div>
                  <div class="otherDetails">
                    495(W) x 325(H)
                    <br />粉彩画 [卡纸]
                    <br />创作年份: 2019
                    <br />艺术品编号: PAS0016
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0017.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Night Music</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    320(W) x 245(H)
                    <br />粉彩画 [卡纸]
                    <br />创作年份: 2019
                    <br />艺术品编号: PAS0017
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0018.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Still Life in Studio</b>
                  <div class="price">HKD 23,000.00</div>
                  <div class="otherDetails">
                    370(W) x 520(H)
                    <br />粉彩画 [卡纸]
                    <br />创作年份: 2005
                    <br />艺术品编号: PAS0018
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0019.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Mascherata #2</b>
                  <div class="price">HKD 19,800.00</div>
                  <div class="otherDetails">
                    310(W) x 480(H)
                    <br />粉彩画 [卡纸]
                    <br />创作年份: 2020
                    <br />艺术品编号: PAS0019
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0020.jpg'" />
                  </div>
                </div>
                <div>
                  <b>黑与黄</b>
                  <div class="price">HKD 9,000.00</div>
                  <div class="otherDetails">
                    200(W) x 300(H)
                    <br />综合媒材 [画板]
                    <br />创作年份: 2015
                    <br />艺术品编号: PAS0020
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0021.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Homage to Picasso II</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    230(W) x 305(H)
                    <br />彩色铅笔画 [卡纸]
                    <br />创作年份: 2017
                    <br />艺术品编号: PAS0021
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0022.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Still Life from Portugal</b>
                  <div class="price">HKD 17,000.00</div>
                  <div class="otherDetails">
                    240(W) x 315(H)
                    <br />粉彩画 [卡纸]
                    <br />创作年份: 2018
                    <br />艺术品编号: PAS0022
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0023.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Homage to Picasso I</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    255(W) x 355(H)
                    <br />彩色铅笔及粉彩画
                    <br />创作年份: 2017
                    <br />艺术品编号: PAS0023
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0024.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Chianti</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    290(W) x 210(H)
                    <br />粉彩画 [黑色画纸]
                    <br />创作年份: 2015
                    <br />艺术品编号: PAS0024
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0025.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Artist & Nature Series (Picasso)</b>
                  <div class="price">HKD 23,000.00</div>
                  <div class="otherDetails">
                    290(W) x 390(H)
                    <br />粉彩画 [卡纸]
                    <br />创作年份: 2009
                    <br />艺术品编号: PAS0025
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-PAS0026.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Stilo, Italy</b>
                  <div class="price">HKD 30,000.00</div>
                  <div class="otherDetails">
                    315(W) x 240(H)
                    <br />粉彩画 [画纸]
                    <br />创作年份:
                    <span class="na">-</span>
                    <br />艺术品编号: PAS0026
                  </div>
                </div>
              </li>
            </ul>

            <h2>水彩画</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0001.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Nice Nightscene</b>
                  <div class="price">HKD 22,800.00</div>
                  <div class="otherDetails">
                    305(W) x 405(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 2018
                    <br />艺术品编号: WAT0001
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0002.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Village du Luberon</b>
                  <div class="price">HKD 9,800.00</div>
                  <div class="otherDetails">
                    145(W) x 150(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 2018
                    <br />艺术品编号: WAT0002
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0003.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Szentendre</b>
                  <div class="price">HKD 13,000.00</div>
                  <div class="otherDetails">
                    300(W) x 300(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 2015
                    <br />艺术品编号: WAT0003
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0004.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Cuban Time</b>
                  <div class="price">HKD 15,400.00</div>
                  <div class="otherDetails">
                    320(W) x 240(H)
                    <br />综合媒材
                    <br />创作年份: 2019
                    <br />艺术品编号: WAT0004
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0005.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Still Life</b>
                  <div class="price">HKD 13,800.00</div>
                  <div class="otherDetails">
                    190(W) x 265(H)
                    <br />水粉画 [画布]
                    <br />创作年份: 2019
                    <br />艺术品编号: WAT0005
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0006.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Hangzhou</b>
                  <div class="price">HKD 9,100.00</div>
                  <div class="otherDetails">
                    165(W) x 150(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 2018
                    <br />艺术品编号: WAT0006
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0007.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Hangzhou</b>
                  <div class="price">HKD 11,800.00</div>
                  <div class="otherDetails">
                    170(W) x 145(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 2018
                    <br />艺术品编号: WAT0007
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0008.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Hangzhou</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    180(W) x 155(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 2018
                    <br />艺术品编号: WAT0008
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0009.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Hangzhou</b>
                  <div class="price">HKD 9,100.00</div>
                  <div class="otherDetails">
                    165(W) x 150(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 2018
                    <br />艺术品编号: WAT0009
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0010.jpg'" />
                  </div>
                </div>
                <div>
                  <b>The Cubist Epoch</b>
                  <div class="price">HKD 16,600.00</div>
                  <div class="otherDetails">
                    290(W) x 305(H)
                    <br />水粉画 [画板]
                    <br />创作年份: 2019
                    <br />艺术品编号: WAT0010
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0011.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Okinawa</b>
                  <div class="price">HKD 16,600.00</div>
                  <div class="otherDetails">
                    305(W) x 330(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 2010
                    <br />艺术品编号: WAT0011
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0012.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta</b>
                  <div class="price">HKD 15,600.00</div>
                  <div class="otherDetails">
                    245(W) x 290(H)
                    <br />水粉画 [画板]
                    <br />创作年份: 2019
                    <br />艺术品编号: WAT0012
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0013.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Madagascar Parsonie Cameleon #1</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    190(W) x 150(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 2019
                    <br />艺术品编号: WAT0013
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0014.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Madagascar Parsonie Cameleon #2</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    150(W) x 175(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 2019
                    <br />艺术品编号: WAT0014
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0015.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta in Studio</b>
                  <div class="price">HKD 16,000.00</div>
                  <div class="otherDetails">
                    340(W) x 280(H)
                    <br />综合媒材
                    <br />创作年份: 2016
                    <br />艺术品编号: WAT0015
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0016.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta Turca</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    240(W) x 280(H)
                    <br />水粉画 [画板]
                    <br />创作年份: 2018
                    <br />艺术品编号: WAT0016
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0017.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Aveiro Dream #1</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    230(W) x 155(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 2020
                    <br />艺术品编号: WAT0017
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0018.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Aveiro Dream #2</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    235(W) x 160(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 2020
                    <br />艺术品编号: WAT0018
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0019.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Madagascar Dream</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    235(W) x 160(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 2020
                    <br />艺术品编号: WAT0019
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0020.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Marseille</b>
                  <div class="price">HKD 22,000.00</div>
                  <div class="otherDetails">
                    265(W) x 265(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 2019
                    <br />艺术品编号: WAT0020
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0021.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Monte Carlo</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    265(W) x 325(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 2020
                    <br />艺术品编号: WAT0021
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0022.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Dancing Leaves #1</b>
                  <div class="price">HKD 8,000.00</div>
                  <div class="otherDetails">
                    270(W) x 195(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 2020
                    <br />艺术品编号: WAT0022
                  </div>
                </div>
              </li>
              <!--<li>
					<div class="img-top mgb27">
						<div class="mood-img">
							<img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0023.jpg'">
						</div>
					</div>
					<div>
						<b>Natura Morta <h6>已被预留至2021年11月25日</h6></b>
						<div class="price">HKD 10,000.00</div>
						<div class="otherDetails">
							250(W) x 200(H)<br>
							综合媒材 [画板]<br>
							创作年份: 2020<br>
							艺术品编号: WAT0023
						</div>
					</div>
              </li>-->
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0024.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Still Life</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    280(W) x 200(H)
                    <br />综合媒材 [画板]
                    <br />创作年份: 2020
                    <br />艺术品编号: WAT0024
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0025.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Madagascar</b>
                  <div class="price">HKD 9,800.00</div>
                  <div class="otherDetails">
                    205(W) x 150(H)
                    <br />综合媒材
                    <br />创作年份: 2019
                    <br />艺术品编号: WAT0025
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0026.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Morning Bird</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    200(W) x 290(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 2017
                    <br />艺术品编号: WAT0026
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0027.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Turkmenistan</b>
                  <div class="price">HKD 11,800.00</div>
                  <div class="otherDetails">
                    195(W) x 300(H)
                    <br />水粉画 [画板]
                    <br />创作年份: 2018
                    <br />艺术品编号: WAT0027
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0028.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Sofia</b>
                  <div class="price">HKD 10,200.00</div>
                  <div class="otherDetails">
                    240(W) x 200(H)
                    <br />综合媒材 [画板]
                    <br />创作年份: 2016
                    <br />艺术品编号: WAT0028
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0029.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Mel de Muntanya</b>
                  <div class="price">HKD 9,000.00</div>
                  <div class="otherDetails">
                    265(W) x 230(H)
                    <br />钢笔画
                    <br />创作年份: 2018
                    <br />艺术品编号: WAT0029
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0030.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Monte Carlo</b>
                  <div class="price">HKD 19,800.00</div>
                  <div class="otherDetails">
                    410(W) x 265(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 2019
                    <br />艺术品编号: WAT0030
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0031.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta</b>
                  <div class="price">HKD 13,300.00</div>
                  <div class="otherDetails">
                    290(W) x 285(H)
                    <br />综合媒材 [画板]
                    <br />创作年份: 2015
                    <br />艺术品编号: WAT0031
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0032.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Cannes</b>
                  <div class="price">HKD 16,800.00</div>
                  <div class="otherDetails">
                    410(W) x 320(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 2019
                    <br />艺术品编号: WAT0032
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0033.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Little India, Singapore</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    410(W) x 320(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 1997
                    <br />艺术品编号: WAT0033
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0034.jpg'" />
                  </div>
                </div>
                <div>
                  <b>EZE Village</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    370(W) x 310(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 2019
                    <br />艺术品编号: WAT0034
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0035.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Aix en Provence</b>
                  <div class="price">HKD 13,800.00</div>
                  <div class="otherDetails">
                    410(W) x 275(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 2019
                    <br />艺术品编号: WAT0035
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0036.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Visite a Menton</b>
                  <div class="price">HKD 16,800.00</div>
                  <div class="otherDetails">
                    410(W) x 290(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 2019
                    <br />艺术品编号: WAT0036
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0037.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Nice</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    350(W) x 320(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 2019
                    <br />艺术品编号: WAT0037
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0038.jpg'" />
                  </div>
                </div>
                <div>
                  <b>From Biot to Marseille</b>
                  <div class="price">HKD 13,800.00</div>
                  <div class="otherDetails">
                    410(W) x 240(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 2019
                    <br />艺术品编号: WAT0038
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0039.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Homage to Miro</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    205(W) x 290(H)
                    <br />水粉画 [画板]
                    <br />创作年份: 2019
                    <br />艺术品编号: WAT0039
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0040.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Homage to Picasso</b>
                  <div class="price">HKD 16,000.00</div>
                  <div class="otherDetails">
                    190(W) x 265(H)
                    <br />水粉画 [画板]
                    <br />创作年份: 2019
                    <br />艺术品编号: WAT0040
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0041.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Morocco</b>
                  <div class="price">HKD 9,000.00</div>
                  <div class="otherDetails">
                    160(W) x 230(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 2020
                    <br />艺术品编号: WAT0041
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0042.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Morocco</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    160(W) x 230(H)
                    <br />水彩画 [画纸]
                    <br />创作年份: 2020
                    <br />艺术品编号: WAT0042
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0043.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta Turca</b>
                  <div class="price">HKD 12,800.00</div>
                  <div class="otherDetails">
                    250(W) x 300(H)
                    <br />水粉画 [画板]
                    <br />创作年份: 2018
                    <br />艺术品编号: WAT0043
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0044.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta Persian & Spanish (Style)</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    250(W) x 300(H)
                    <br />水粉画 [画板]
                    <br />创作年份: 2018
                    <br />艺术品编号: WAT0044
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WAT0045.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Natura Morta Turca</b>
                  <div class="price">HKD 8,800.00</div>
                  <div class="otherDetails">
                    210(W) x 280(H)
                    <br />水粉画 [画板]
                    <br />创作年份: 2018
                    <br />艺术品编号: WAT0045
                  </div>
                </div>
              </li>
            </ul>

            <h2>水彩及水墨</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0001.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Plovdiv, Bulgaria</b>
                  <div class="price">HKD 50,000.00</div>
                  <div class="otherDetails">
                    635(W) x 455(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2018
                    <br />艺术品编号: WCI0001
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0002.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Still Life with Wampi</b>
                  <div class="price">HKD 36,000.00</div>
                  <div class="otherDetails">
                    380(W) x 330(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2018
                    <br />艺术品编号: WCI0002
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0003.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Vence</b>
                  <div class="price">HKD 32,000.00</div>
                  <div class="otherDetails">
                    380(W) x 455(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2010
                    <br />艺术品编号: WCI0003
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0004.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Ceramiche in Sardegna</b>
                  <div class="price">HKD 40,000.00</div>
                  <div class="otherDetails">
                    450(W) x 530(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份:
                    <span class="na">-</span>
                    <br />艺术品编号: WCI0004
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0005.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Vitalita #1 / Vitalita #2</b>
                  <div class="price">HKD 50,000.00</div>
                  <div class="otherDetails">
                    340(W) x 137(H) / 340(W) x 137(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2016
                    <br />艺术品编号: WCI0005 / WCI0006
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0007.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Monte Carlo</b>
                  <div class="price">HKD 32,000.00</div>
                  <div class="otherDetails">
                    460(W) x 590(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2019
                    <br />艺术品编号: WCI0007
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0008.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Autumn Show</b>
                  <div class="price">HKD 32,000.00</div>
                  <div class="otherDetails">
                    600(W) x 450(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2020
                    <br />艺术品编号: WCI0008
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0009.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Pasticcio di Braque</b>
                  <div class="price">HKD 20,000.00</div>
                  <div class="otherDetails">
                    460(W) x 640(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2020
                    <br />艺术品编号: WCI0009
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0010.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Ventimiglia</b>
                  <div class="price">HKD 28,000.00</div>
                  <div class="otherDetails">
                    570(W) x 420(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2019
                    <br />艺术品编号: WCI0010
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0011.jpg'" />
                  </div>
                </div>
                <div>
                  <b>微红</b>
                  <div class="price">HKD 50,000.00</div>
                  <div class="otherDetails">
                    805(W) x 340(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2016
                    <br />艺术品编号: WCI0011
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0012.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Madonna</b>
                  <div class="price">HKD 30,000.00</div>
                  <div class="otherDetails">
                    525(W) x 370(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2016
                    <br />艺术品编号: WCI0012
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0013.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Stand Still</b>
                  <div class="price">HKD 25,000.00</div>
                  <div class="otherDetails">
                    480(W) x 410(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2020
                    <br />艺术品编号: WCI0013
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0014.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Cermaica</b>
                  <div class="price">HKD 33,000.00</div>
                  <div class="otherDetails">
                    380(W) x 455(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2004
                    <br />艺术品编号: WCI0014
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0015.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Ville de Menton</b>
                  <div class="price">HKD 23,800.00</div>
                  <div class="otherDetails">
                    610(W) x 360(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2019
                    <br />艺术品编号: WCI0015
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0016.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Cap d' Ail</b>
                  <div class="price">HKD 38,000.00</div>
                  <div class="otherDetails">
                    490(W) x 710(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2020
                    <br />艺术品编号: WCI0016
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0017.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Bayamo, Cuba</b>
                  <div class="price">HKD 22,000.00</div>
                  <div class="otherDetails">
                    380(W) x 520(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2014
                    <br />艺术品编号: WCI0017
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0018.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower & Lemons</b>
                  <div class="price">HKD 13,500.00</div>
                  <div class="otherDetails">
                    190(W) x 610(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2015
                    <br />艺术品编号: WCI0018
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0019.jpg'" />
                  </div>
                </div>
                <div>
                  <b>神户雪景</b>
                  <div class="price">HKD 38,000.00</div>
                  <div class="otherDetails">
                    700(W) x 450(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2005
                    <br />艺术品编号: WCI0019
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0020.jpg'" />
                  </div>
                </div>
                <div>
                  <b>红与蜜</b>
                  <div class="price">HKD 22,000.00</div>
                  <div class="otherDetails">
                    355(W) x 455(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2015
                    <br />艺术品编号: WCI0020
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0021.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower Study</b>
                  <div class="price">HKD 9,000.00</div>
                  <div class="otherDetails">
                    245(W) x 335(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2021
                    <br />艺术品编号: WCI0021
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0022.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Morocco Tour</b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    245(W) x 335(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2020
                    <br />艺术品编号: WCI0022
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0023.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Bird Talk</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    315(W) x 380(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2021
                    <br />艺术品编号: WCI0023
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0024.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower Arrangement #1</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    305(W) x 340(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2021
                    <br />艺术品编号: WCI0024
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0025.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower Arrangement #2</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    380(W) x 435(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2021
                    <br />艺术品编号: WCI0025
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0026.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower Arrangement #3</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    380(W) x 475(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2021
                    <br />艺术品编号: WCI0026
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0027.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Uccello e Fiori</b>
                  <div class="price">HKD 20,000.00</div>
                  <div class="otherDetails">
                    310(W) x 585(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2021
                    <br />艺术品编号: WCI0027
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0028.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower Arrangement #4</b>
                  <div class="price">HKD 25,000.00</div>
                  <div class="otherDetails">
                    515(W) x 460(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2021
                    <br />艺术品编号: WCI0028
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0029.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Bouquet</b>
                  <div class="price">HKD 30,000.00</div>
                  <div class="otherDetails">
                    400(W) x 535(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2021
                    <br />艺术品编号: WCI0029
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0030.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower Arrangement #5</b>
                  <div class="price">HKD 30,000.00</div>
                  <div class="otherDetails">
                    380(W) x 530(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2021
                    <br />艺术品编号: WCI0030
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0031.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower Study</b>
                  <div class="price">HKD 23,000.00</div>
                  <div class="otherDetails">
                    345(W) x 520(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2021
                    <br />艺术品编号: WCI0031
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0032.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Flower Study</b>
                  <div class="price">HKD 22,000.00</div>
                  <div class="otherDetails">
                    395(W) x 450(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2021
                    <br />艺术品编号: WCI0032
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0033.jpg'" />
                  </div>
                </div>
                <div>
                  <b>La Stazione</b>
                  <div class="price">HKD 34,800.00</div>
                  <div class="otherDetails">
                    590(W) x 460(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2019
                    <br />艺术品编号: WCI0033
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0034.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Amapola</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    385(W) x 355(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2020
                    <br />艺术品编号: WCI0034
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0035.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Day Walk in San Remo</b>
                  <div class="price">HKD 20,000.00</div>
                  <div class="otherDetails">
                    460(W) x 300(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2019
                    <br />艺术品编号: WCI0035
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0036.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Una Strada</b>
                  <div class="price">HKD 18,000.00</div>
                  <div class="otherDetails">
                    300(W) x 460(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2019
                    <br />艺术品编号: WCI0036
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0037.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Imperia</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    290(W) x 460(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2019
                    <br />艺术品编号: WCI0037
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0038.jpg'" />
                  </div>
                </div>
                <div>
                  <b>San Remo</b>
                  <div class="price">HKD 39,000.00</div>
                  <div class="otherDetails">
                    630(W) x 460(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2019
                    <br />艺术品编号: WCI0038
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0039.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Cuba Time</b>
                  <div class="price">HKD 38,000.00</div>
                  <div class="otherDetails">
                    520(W) x 440(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2014
                    <br />艺术品编号: WCI0039
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/marcoszeto-WCI0040.jpg'" />
                  </div>
                </div>
                <div>
                  <b>Still Life</b>
                  <div class="price">HKD 28,500.00</div>
                  <div class="otherDetails">
                    390(W) x 380(H)
                    <br />水彩及水墨画 [画纸]
                    <br />创作年份: 2005
                    <br />艺术品编号: WCI0040
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </b-col>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: "tc",
      url: process.env.VUE_APP_IMG_URL,
      orderFormUrl: this.url + "/promotion/SLART_Internal_Order_Form.xlsx",
    };
  },
  created() {
    this.lang = localStorage.getItem("language");
  },

  methods: {}
};
</script>

<style lang="scss" scoped>
	@import "@/assets/style/scss/promotion.scss";
</style>
