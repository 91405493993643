<template>
  <div class="content" v-loading="loading">
    <b-container fluid>
      <b-row class="drs" align-v="center">
        <b-col md="12">
          <h5 class="name tc">{{ $t('common.wishlist') }}</h5>
        </b-col>

        <b-col md="8">
          <div class="tl">
            <b-form-select
              class="byCat select-list"
              v-model="fromdata.selectcat"
              value-field="value"
              text-field="text"
              :options="category"
              @change="getfilter"
            ></b-form-select>
            <div
              style="display: inline-block; vertical-align: top"
              class="posiR mgl10"
            >
              <input
                type="text"
                class="form-control"
                id="q"
                v-model="fromdata.q"
                :placeholder="$t('common.headsearch')"
                style="height: 30px; font-size: 12px"
              />
              <button
                type="button"
                class="btn btn-default fa fa-search submitSearchBtn"
                style="padding: 4px 10px"
                @click="getSerarchWishlist"
              ></button>
            </div>
            <b-form-select
              class="byCat select-list category-select"
              v-model="fromdata.category_id"
              value-field="value"
              text-field="text"
              :options="wishCategoryList"
              @change="getfilter"
            ></b-form-select>
            <b-button
              size="sm"
              class="creat-catgory"
              @click="openCreatCategory"
              variant="outline-secondary"
              >{{ $t('productfilter.CreateCategory') }}</b-button
            >
            <b-button
              v-if="fromdata.category_id"
              class="creat-catgory"
              size="sm"
              @click="openCategoryFormVisible"
              variant="outline-secondary"
              >{{ $t('productfilter.UpdateName') }}</b-button
            >
            <b-button
              v-if="fromdata.category_id"
              size="sm"
              class="del-category"
              @click="delCategoryPop = true"
              variant="outline-secondary"
              >{{ $t('productfilter.DelCategory') }}</b-button
            >
            <div
              class="mgl15 ft12 text-left resultInfo"
              style="float: none; display: inline-block"
            >
              <div v-if="fromdata.selectcat || fromdata.category_id">
                {{ total }}{{ $t('productfilter.unit') }}
                <em v-for="(item, index) in category" :key="index">
                  <b v-if="fromdata.selectcat == index">
                    <em>{{ item }}</em>
                    <span
                      class="fa fa-times-circle"
                      @click="delSelect"
                    ></span></b
                ></em>
                <em v-for="catItem in wishCategoryList" :key="catItem.id">
                  <b
                    v-if="
                      fromdata.category_id == catItem.value &&
                      fromdata.category_id
                    "
                  >
                    <em>{{ catItem.text }}</em>
                    <span
                      class="fa fa-times-circle"
                      @click="delCategory"
                    ></span></b
                ></em>
                {{ $t('productfilter.resultxt') }}
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm class="flex" style="justify-content: flex-end">
          <div class="forRegion flex-wrap">
            <label class="bold ft12">{{ $t('productfilter.Region') }}</label>
            <b-form-select
              class="select-list"
              v-model="fromdata.forRegion"
              value-field="value"
              text-field="text"
              :options="$t('productfilter.regionption')"
              @change="setRegion(fromdata.forRegion)"
            ></b-form-select>
          </div>
          <div class="forRegion flex-wrap">
            <label class="bold ft12">{{ $t('productfilter.orderby') }}</label>
            <b-form-select
              class="select-list"
              v-model="fromdata.sort"
              :options="$t('productfilter.sortSelectOptions')"
              value-field="val"
              text-field="text"
              @change="getfilter"
            ></b-form-select>
          </div>
          <div class="forRegion flex-wrap">
            <label class="bold ft12">{{ $t('productfilter.pageShow') }}</label>
            <b-form-select
              class="select-list"
              v-model="fromdata.num"
              :options="numSelectOptions"
              @change="getfilter"
            ></b-form-select>
          </div>
        </b-col>
        <br /><br />
        <b-col md="12">
          <product-list
            :name="name"
            :product-list="productList"
            :categories="categories"
            :load="loading"
            :wish-category-list="defaultCategory"
            @deleteWish="deleteWish"
            @updateWishCategory="getwish"
          ></product-list>
        </b-col>
      </b-row>

      <b-row v-if="fileData && fileData.length > 1">
        <div class="col-md-12 libFile">
          {{ $t('common.lib_file') }}
          <a :href="url + fileData.path" target="_blank">
            <button class="fa fa-download"><span>SPEC</span></button>
          </a>
        </div>
      </b-row>
    </b-container>
    <!-- category -->
    <el-dialog
      class="category-dialog"
      :width="screenWidth > '768' ? '40%' : '90%'"
      :visible.sync="categoryFormVisible"
    >
      <b-row align-h="start" align-v="center">
        <b-col :cols="screenWidth > '768' ? 2 : 4"
          ><label class="" for="input-none"
            >{{ $t('productfilter.CategoryName') }}:</label
          ></b-col
        >
        <b-col cols="6"
          ><b-form-input
            class="form-control multiselect-search"
            id="input-small"
            size="sm"
            v-model="newCategoryValue"
          ></b-form-input
        ></b-col>
      </b-row>
      <div slot="footer" class="dialog-footer">
        <el-button
          class="update-btn"
          size="mini"
          type="info"
          @click="updateCategory"
          >{{ $t('productfilter.UpdateBtn') }}</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      class="category-dialog"
      :width="screenWidth > '768' ? '40%' : '90%'"
      :visible.sync="delCategoryPop"
    >
      <b-form-checkbox
        id="checkbox-1"
        v-model="delAllcategoryStatus"
        name="checkbox-1"
        :value="String(1)"
        :unchecked-value="String(0)"
      >
        {{ $t('productfilter.ConfirmDel') }}
      </b-form-checkbox>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" type="info" @click="deleteWishCategory">{{
          $t('productfilter.Confirm')
        }}</el-button>
      </div>
    </el-dialog>
    <el-dialog
      class="category-dialog"
      :width="screenWidth > '768' ? '40%' : '90%'"
      :visible.sync="creatCategoryShow"
    >
      <div>
        <b-row align-h="start" align-v="center">
          <b-col :cols="screenWidth > '768' ? '2' : '4'">
            <label for="input-none"
              >{{ $t('productfilter.CategoryName') }}:</label
            >
          </b-col>
          <b-col :cols="screenWidth > '768' ? '6' : '5'">
            <b-form-input
              type="text"
              class="form-control multiselect-search"
              id="input-small-creat"
              size="sm"
              v-model="createCategoryValue"
            ></b-form-input>
          </b-col>
          <b-col cols="1"
            ><el-button size="mini" type="info" @click="creatCategory">{{
              $t('productfilter.AddBtn')
            }}</el-button></b-col
          >
        </b-row>
        <div style="height: 30px; width: 100%"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import productList from '@/components/ProductList.vue'
import { region } from '@/api/home'
import {
  wishlist,
  productFilters,
  categoryList,
  deleteWish,
  creatCategory,
  updateCategory,
  deleteWishCategory,
} from '@/api/product'
export default {
  components: {
    'product-list':productList,
  },
  data() {
    return {
      createCategoryValue: '',
      creatCategoryShow: false,
      delCategoryPop: false,
      categoryFormVisible: false,
      delAllcategoryStatus: 1,
      newCategoryValue: '',
      wishCategoryList: [],
      defaultCategory: [],
      wishCategoryVal: '',
      name: 'wishList',
      forRegionSelect: 'HK',
      url: process.env.VUE_APP_IMG_URL,
      sortSelect: 'default',
      numSelectOptions: ['24', '48', '96'],
      numSelect: 24,
      fileData: [], //left category
      productList: [],
      screenWidth: document.body.clientWidth,
      fromdata: {
        forRegion: 'HK',
        sort: 'default',
        num: 24,
        selectcat: null,
        page: 1,
        q: null,
        category_id: null,
      },
      category: [
        {
          value: 1,
          text: '全部分類',
        },
        {
          value: 9,
          text: '特色材料',
        },
        {
          value: 2,
          text: '智能酒店系統',
        },
        {
          value: 3,
          text: '數字化工作空間解決方案',
        },
        {
          value: 4,
          text: '衛浴產品',
        },
        {
          value: 5,
          text: '金屬飾面',
        },
        {
          value: 6,
          text: '健康設計系統',
        },
        {
          value: 7,
          text: '家庭影院系統',
        },
        {
          value: 8,
          text: '專業供應商',
        },
      ],
      total: 0,
      loading: false,
      categories: [],
    }
  },
  created() {
    this.fromdata.forRegion = localStorage.getItem('forRegion')
    this.fromdata.sort = this.$route.query.sort
      ? this.$route.query.sort
      : 'default'
    this.fromdata.num = this.$route.query.num
      ? Number(this.$route.query.num)
      : 24
    this.fromdata.selectcat = this.$route.query.selectcat
      ? Number(this.$route.query.selectcat)
      : null
    this.fromdata.q = this.$route.query.q ? this.$route.query.q : null
    this.fromdata.category_id = this.$route.query.catId
      ? Number(this.$route.query.catId)
      : null
    this.getwish()
    this.getproductFilters()
    this.getCategory()
  },
  watch: {
    $route() {
      this.fromdata.forRegion = localStorage.getItem('forRegion')
      this.fromdata.sort = this.$route.query.sort
        ? this.$route.query.sort
        : 'default'
      this.fromdata.num = this.$route.query.num
        ? Number(this.$route.query.num)
        : 24
      this.fromdata.selectcat = this.$route.query.selectcat
        ? Number(this.$route.query.selectcat)
        : null
      this.fromdata.q = this.$route.query.q ? this.$route.query.q : null
      this.fromdata.category_id = this.$route.query.catId
        ? Number(this.$route.query.catId)
        : null
      this.getwish()
      this.getproductFilters()
      this.getCategory()
    },
  },
  methods: {
    setRegion(type) {
      localStorage.setItem('forRegion', type)
      const data = {
        region: type,
      }
      region(data).then((res) => {
        if (res.status == 'success') {
          this.$router.go(0)
        }
      })
    },
    pathChange() {
      const queryData = {
        selectcat: this.fromdata.selectcat,
        catId: this.fromdata.category_id,
        sort: this.fromdata.sort,
        num: this.fromdata.num,
        q: this.fromdata.q,
      }
      Object.keys(queryData).forEach((item) => {
        const key = queryData[item]
        if (key == null) {
          delete queryData[item]
        }
      })
      this.$router.push({
        path: '/wish-list',
        query: queryData,
      })
    },
    openCreatCategory() {
      this.creatCategoryShow = true
      this.createCategoryValue = ''
    },
    creatCategory() {
      const data = {
        name: this.createCategoryValue,
      }
      creatCategory(data).then((res) => {
        this.getCategory()
        this.creatCategoryShow = false
      })
    },
    openCategoryFormVisible() {
      this.categoryFormVisible = true
      this.wishCategoryList.map((el) => {
        if (el.value == this.fromdata.category_id) {
          this.newCategoryValue = el.text
        }
      })
    },
    updateCategory() {
      const data = {
        name: this.newCategoryValue,
      }
      updateCategory(data, this.fromdata.category_id).then(() => {
        this.categoryFormVisible = false
        this.getCategory()
        this.getwish()
      })
    },
    deleteWishCategory() {
      //delete all wish category
      this.loading = true
      deleteWishCategory(
        this.fromdata.category_id,
        this.delAllcategoryStatus
      ).then((res) => {
        this.delCategoryPop = false
        this.fromdata.category_id = null
        this.getwish()
        this.getCategory()
      })
    },
    deleteWish(pid) {
      //delete one wish list
      this.loading = true
      deleteWish(pid).then((res) => {
        this.getwish()
      })
    },
    getCategory() {
      //wishCategory
      categoryList().then((res) => {
        const newList = []
        const defaltList = []
        res.data.map((el) => {
          newList.push({ value: el.id, text: el.name })
          defaltList.push({ value: el.id, text: el.name })
        })
        newList.push({
          value: null,
          text: `${this.$t('common.wishCategory')}`,
        })
        this.wishCategoryList = newList
        defaltList.push({
          value: null,
          text: `${this.$t('productfilter.DefaultCategory')}`,
        })
        this.defaultCategory = defaltList
      })
    },
    getSort(e) {
      this.fromdata.sort = e
      //  this.getproductList();
    },
    getPagenum() {
      this.page = 1
      // this.getproductList();
    },
    getwish() {
      this.loading = true
      wishlist(this.fromdata).then((res) => {
        this.loading = false
        this.total = res.data.products.productList.total
        this.productList = res.data.products.productList.data
        this.fileData = res.data.products.libInfo
        const newCate = []
        this.productList.map((proItem) => {
          res.data.categories.map((cateItem) => {
            if (proItem.id == Object.keys(cateItem)) {
              newCate.push(cateItem)
            }
          })
        })
        this.categories = JSON.parse(JSON.stringify(newCate))
      })
    },
    getproductFilters() {
      let obj = {
        null: this.$t('common.prodCategory'),
      }
      productFilters().then((res) => {
        let data = res.data
        this.category = Object.assign(
          obj,
          data.catFiltersHard,
          data.catFiltersSoft
        )
      })
    },
    getfilter(e) {
      if (e == 'null') {
        this.fromdata.selectcat = null
      }
      this.pathChange()
      this.getwish()
    },
    delSelect() {
      this.fromdata.selectcat = null
      this.pathChange()
      this.getwish()
    },
    delCategory() {
      this.fromdata.category_id = null
      this.pathChange()
      this.getwish()
    },
    getSerarchWishlist() {
      this.fromdata.page = 1
      this.pathChange()
      this.getwish()
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  padding: 25px 0 40px;
  overflow-x: hidden;
  .name {
    padding: 20px 0px 20px;
    font-size: 21px;
  }
}
.drs {
  padding: 0 15px;
}
.forRegion {
  align-items: center;
  display: flex;
  margin-left: 5px;
}
// .byCat {
//   max-width: 172px !important;
// }
.select-list {
  height: 30px;
  padding: 6px 8px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
  margin-left: 10px;
  width: 120px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;

  @media (min-width: 768px) {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
}
.submitSearchBtn {
  line-height: 20px;
  background: #aaaaaa;
  color: #ffffff;
  position: absolute;
  top: 0px;
  right: 0px;
}
.resultInfo {
  b {
    font-size: 13px;
    border: 1px solid #ededed;
    border-radius: 5px;
    background: #f3f3f3;
    padding: 4px 6px 4px 6px;
    margin: 0px 5px 0 0;
  }
  span {
    color: #aaaaaa;
    display: inline-block;
    padding: 0px 0px 0px 4px;
    font-size: 17px;
    line-height: 23px;
    cursor: pointer;
    vertical-align: middle;
    margin-top: -3px;
    &:hover {
      color: #c63033;
    }
  }
  @media (max-width: 768px) {
    margin-top: 8px;
    margin-bottom: 10px;
  }
}
.category-list {
  .update-category {
    margin: 0 20px;
  }
}
.category-select {
  @media (max-width: 768px) {
    margin-top: 5px;
  }
}

.update-input {
  display: flex;
  @media (max-width: 768px) {
    // margin-top: 20px;
    // float: right;
  }
}

.creat-catgory {
  margin-left: 10px;
  padding: 3px 8px;
  @media (max-width: 768px) {
    margin-left: 10px;
  }
}
.del-category {
  margin-left: 10px;
  padding: 3px 8px;
  @media (max-width: 768px) {
    margin-left: 10px;
  }
}
</style>
