<template>
  <div class="content" v-loading="loading">
    <b-container fluid>
      <b-row class="consultant-details">
        <b-col cols="12">
          <div class="name mgb20 mgt20 tc ft12">
            <router-link
              :to="{ path: '/supplier-cats?name=' + encodeURIComponent(name) }"
              class="item"
              >{{ name }} /</router-link
            >
            <router-link
              v-if="query.catId != 3"
              :to="{ path: '/supplier?id=' + query.catId + '&name=' + encodeURIComponent(name) }"
              class="item"
              >{{ catName }} /</router-link
            >
            <router-link
              v-if="query.catId == 3"
              :to="{
                path: '/supplier-cats?catId=' + query.catId + '&name=' + encodeURIComponent(name),
              }"
              class="item"
              >{{ catName }} /</router-link
            >
            <router-link
              :to="{
                path:
                  '/supplier-cats?catId=' +
                  query.catId +
                  '&id=' +
                  query.supplieId +
                  '&name=' +
                  name,
              }"
              class="item"
              >{{ suppliecatName }}
            </router-link>
          </div>

        </b-col>
        <b-col cols="12">
          <div class="brand-info">
            <h1>{{ detailData.name }}</h1>
            <!-- <div class="star-rate mgt10" v-if="detailData.remark !=null">
                        <el-rate v-model="value1"></el-rate>
                    </div> -->
            <div class="brand-company" v-if="detailData.desc != null">
              {{ detailData.desc }}
            </div>
          </div>
          <div class="supplier-brand">
            <h4 class="mgb60 ft18">{{ $t('supplierfilter.product_brand') }}</h4>
            <ul
              class="brand-list"
              v-if="brandsData.length > 0 && brandsData != ''"
            >
              <li v-for="(item, index) in brandsData" :key="index">
                <div class="brand-photo">
                  <div class="brand-photo-con">
                    <img class="b-lazy b-error" v-lazy="url + item.logo_path" />
                  </div>
                </div>
                <div class="brand-title">
                  <h2>{{ item.name }}</h2>
                </div>
              </li>
            </ul>
            <div v-else class="text-center ft18" style="color: gainsboro">
              {{ $t('supplierfilter.nodata') }}
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  getsuppliersDetail,
  supplierCatFilters,
  getsuppliercatDetail,
} from '@/api/supply'
export default {
  data() {
    return {
      detailData: [],
      brandsData: [],
      loading: false,
      name: '',
      catName: '',
      suppliecatName: '',
      url: process.env.VUE_APP_IMG_URL,
      query: '',
    }
  },
  created() {
    let catId = this.$route.query.catId,
      supplieId = this.$route.query.supplieId,
      brandId = this.$route.query.brandId
    this.name = this.$route.query.name
    this.query = this.$route.query
    this.getDetails(catId, supplieId, brandId)
    this.getsupplierCatFilters(catId)
    this.getsuppliercatDetail(catId, supplieId)
  },
  methods: {
    //detail
    getDetails(catId, supplieId, brandId) {
      this.loading = true
      getsuppliersDetail(catId, supplieId, brandId).then((res) => {
        this.loading = false
        this.detailData = res.data.basic;
        this.brandsData = res.data.brands;
      })
    },
    getsupplierCatFilters(catId) {
      supplierCatFilters(catId).then((res) => {
        this.catName = res.data.name
      })
    },
    getsuppliercatDetail(catId, id) {
      getsuppliercatDetail(catId, id).then((res) => {
        if (id == 'null') {
          this.suppliecatName = `${this.$t('common.all_cat')}`
        } else {
          this.suppliecatName = res.data.name
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.consultant-details {
  max-width: 1530px;
  margin: 0 auto;
  .name {
    padding-top: 20px;
    span {
      color: #999;
      font-size: 50%;
    }
    .item {
      color: #999;
      &:hover {
        color: #950c0f;
      }
    }
  }
  .brand-info {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 20px;
    position: unset;
    /* padding-bottom: 100px; */
    flex: 1;
    width: auto;
    h1 {
      font-size: 25px;
    }
    .star-rat {
    }
    .brand-company {
      max-width: 1200px;
      margin: 20px auto 0;
    }
  }
  .supplier-brand {
    border-top: 1px solid #dddddd;
    margin-top: 40px;
    padding-top: 40px;
    text-align: center;
    .brand-list {
      max-width: 1560px;
      width: 100%;
      padding: 0;
      text-align: center;
      font-size: 0;
      margin-bottom: 50px;
      @media (max-width: 767px) {
        width: auto;
        margin-bottom: 50px;
      }
      @media (max-width: 766px) {
        text-align: justify;
      }
      li {
        display: inline-block;
        box-sizing: border-box;
        text-align: center;
        font: bold 18px sans-serif;
        background-color: #ffffff;
        /* border: 1px solid #DDDDDD; */
        /* max-width: 250px; */
        width: 12.5%;
        margin: 0 2% 50px;
        /* padding: 18px; */
        vertical-align: top;
        position: relative;
        @media (max-width: 1500px) {
          width: 15.2%;
        }
        @media (max-width: 1100px) {
          width: 20%;
        }
        @media (max-width: 1023px) {
          width: 26%;
        }
        @media (max-width: 767px) {
          width: 50%;
        }
        .brand-photo {
          position: relative;
          text-align: center;
          /* box-shadow: 0 0 10px 5px #f3f3f3 inset; */
          width: 100%;
          margin-bottom: 12px;
          /* padding: 10px 10px; */
          padding: 0px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          cursor: default;
          &::before {
            content: '';
            display: block;
            padding-top: 30%;
            margin: 0 auto;
            color: #d3d3d3;
            font-size: 40px;
          }
          .brand-photo-con {
            position: absolute;
            display: flex;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            align-items: center;
            img {
              display: block;
              max-width: 100%;
              max-height: 100%;
              margin: auto;
              width: auto;
              height: auto;
            }
          }
        }
        .brand-title {
          h2 {
            font-size: 18px;
            line-height: 22px;
            max-width: 100%;
          }
          .brand-cat {
            margin-top: 6px;
            li {
              width: auto;
              font-size: 12px;
              font-weight: normal;
              color: #999999;
              border-right: 1px solid #cccccc;
              padding-right: 10px;
              line-height: 12px;
              margin: 0 5px 5px 0px;
              &:last-child {
                border: none;
                margin-right: 0px;
                padding-right: 0px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
