<template>
  <div class="content" v-loading="loading">
    <b-container fluid>
      <b-row class="news">
        <b-col md="12">
          <div class="name mgb20 tc">
            <div>
              {{$t('common.news')}}
            </div>
            <div class="coCat tc mgt10">
              <el-select  v-model="year" :placeholder="$t('common.year')" placeholder-color="#555" @change="getchangevalue">
                <el-option
                  v-for="item in sortOption"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>

          </div>
          
        </b-col>
        <b-col md="12">
          <div class="news-list">
            <div class="newsRow" v-for="item in listData" :key="item.id" @click="getNoticesDetail(item)">
              <div class="pics"><img :src="url+item.photo_path" width="150"><i class="fa fa-circle"></i></div>
              <div class="infos">
               
                <h5>{{item.name}}</h5>
                <h6>{{item.type}}</h6>
                {{item.desc}}
                <span style="display:block; margin-top:15px;">{{ item.date?.substr(0, 10) }}</span>
        
                
              </div>
            </div>
         
          </div> 

        </b-col>


    
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { noticeslist ,noticesDetail} from "@/api/product";
export default {
  data(){
    return{
      listData:[],
      loading:false,
      sortOption:['年份'],
      url:process.env.VUE_APP_IMG_URL,
      year:null,
    }
  },
  created(){
    this.getNotices();

  },

  methods:{
    getchangevalue(){
      this.loading = true;
      this.getNotices()
    },
    getNotices() {
      this.loading = true;
      noticeslist({ year: this.year}).then((res) => {
        this.loading = false;
        this.listData =  res.data.notices;
        this.sortOption= res.data.years ;
      });
       
    },
    getNoticesDetail(item){
      noticesDetail(item.id).then(res=>{
        // console.log(res);
        window.location.assign(`${item.href}`)
      });
      
    }

 
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 25px 0 40px;
  overflow-x: hidden;
  .name {
    padding: 20px 0px 20px;
    font-size: 21px;
  }
  
  
  
}
/deep/ .el-input__inner{
  border: none;
  font-size: 13px;
  color: #555;
  text-align: center;
  padding-left: 0;
  width: 80px;
}
.news{
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  .news-list{
    display: table;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    vertical-align: top;
    font-size: 15px;
    .newsRow{
      display: table-row;
      >div{
        display: table-cell;
        text-align: left;
        padding: 20px 5px;
        border-bottom: 1px solid #e7e7e7;
        vertical-align: top;
      }
      .pics{
        min-width: 120px;
        padding: 20px 20px 20px 0px;
        .fa{
            font-size: 13px;
            color: #c63033;
            position: absolute;
            top: -6px;
            right: -6px;
            display: none;
        }
      }
      .infos{
        color: #555555;
        position: relative;
        cursor: pointer;
        h5 {
          font-size: 18px;
          color: #555;
          margin: 2px 0px 6px 0px;
          padding: 0px;
        }
        h6 {
          font-size: 12px;
          color: #aaaaaa;
          margin: 0px 0px 10px 0px;
          padding: 0px;
        }
        span {
          font-size: 12px;
        }
      }
    }
  }
}
/deep/ .el-loading-mask{
  position: fixed;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active{
    background: #ccc;
}

</style>