var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.deliverable.length > 0)?_c('div',{staticClass:"deliverable-list"},[_c('ul',{class:['flex-between', 'flex-wrap']},_vm._l((_vm.deliverable),function(item,index){return _c('li',{key:index,staticClass:"thumb"},[_c('a',{attrs:{"href":'#/deliverable-detail?id='+item.id}},[_c('div',{staticClass:"thumb-box"},[_c('div',{staticClass:"thumb-text"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"thumb-img"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                  _vm.imgurl + _vm.resultData.areaImgList[item.id][0].path + '/s500'
                ),expression:"\n                  imgurl + resultData.areaImgList[item.id][0].path + '/s500'\n                "}],staticClass:"img"})])]),_c('div',{staticClass:"mgt20"},[_c('span',{domProps:{"innerHTML":_vm._s(item.name)}})])]),_c('div',{staticClass:"specs"},[_c('div',{staticClass:"tr"},[_c('div',[_vm._v(_vm._s(_vm.$t("welcome.project_code"))+":")]),_c('div',{staticClass:"codes"},[_c('a',{attrs:{"href":'#/deliverable-detail?id='+item.id+'&relateId='+item.id}},[_c('span',{domProps:{"innerHTML":_vm._s(item.project_no)}})]),_vm._l((_vm.relatedProjects[item.id]),function(relate,inx){return _c('span',{key:inx},[_c('span',{domProps:{"innerHTML":_vm._s(` / `)}}),_c('a',{attrs:{"href":'#/deliverable-detail?id='+item.id+'&relateId='+relate.id}},[_c('span',{domProps:{"innerHTML":_vm._s(`${relate.project_no}`)}})])])})],2)]),_c('div',{staticClass:"tr"},[_c('div',[_vm._v(_vm._s(_vm.$t("welcome.project_location"))+":")]),_c('div',{domProps:{"innerHTML":_vm._s(item.location)}})]),_c('div',{staticClass:"tr"},[_c('div',[_vm._v(_vm._s(_vm.$t("welcome.project_type"))+":")]),_vm._l((_vm.opt_type),function(type,key){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.opt_type_ids == key),expression:"item.opt_type_ids == key"}],key:key},[(item.opt_type_ids == key)?_c('span',{domProps:{"innerHTML":_vm._s(type)}}):_vm._e()])})],2),_c('div',{staticClass:"tr"},[_c('div',[_vm._v(_vm._s(_vm.$t("welcome.project_client"))+":")]),_c('div',{domProps:{"innerHTML":_vm._s(item.developer)}})]),_c('div',{staticClass:"tr"},[_c('div',[_vm._v(_vm._s(_vm.$t("welcome.project_cost_fitting_out"))+":")]),(
                _vm.resultData.fitoutFinCostMin[item.id] &&
                _vm.resultData.fitoutFinCostMin[item.id] > 0 &&
                _vm.resultData.fitoutFinCostMax[item.id]
              )?_c('div',[(
                  _vm.resultData.fitoutFinCostMin[item.id] ==
                  _vm.resultData.fitoutFinCostMax[item.id]
                )?_c('span',[_vm._v(" "+_vm._s(item.currency_symbol)+_vm._s(_vm._f("toThousandFilter")(_vm.resultData.fitoutFinCostMin[item.id]))+" - "+_vm._s(_vm.$t("welcome.project_rmb_per_sqm"))+" ")]):_c('span',[_vm._v(" "+_vm._s(item.currency_symbol)+_vm._s(_vm._f("toThousandFilter")(_vm.resultData.fitoutFinCostMin[item.id]))+" - "+_vm._s(item.currency_symbol)+_vm._s(_vm._f("toThousandFilter")(_vm.resultData.fitoutFinCostMax[item.id]))+_vm._s(_vm.$t("welcome.project_rmb_per_sqm"))+" ")])]):(
                _vm.resultData.fitoutBudCostMin[item.id] &&
                _vm.resultData.fitoutBudCostMin[item.id] > 0 &&
                _vm.resultData.fitoutBudCostMax[item.id]
              )?_c('div',[(
                  _vm.resultData.fitoutBudCostMin[item.id] ==
                  _vm.resultData.fitoutBudCostMax[item.id]
                )?_c('span',[_vm._v(" "+_vm._s(item.currency_symbol)+_vm._s(_vm._f("toThousandFilter")(_vm.resultData.fitoutBudCostMin[item.id]))+" - "+_vm._s(item.currency_symbol)+_vm._s(_vm._f("toThousandFilter")(_vm.resultData.fitoutBudCostMax[item.id]))+" "+_vm._s(_vm.$t("welcome.project_rmb_per_sqm"))+" ")]):_c('span',[_vm._v(" "+_vm._s(item.currency_symbol)+_vm._s(_vm._f("toThousandFilter")(_vm.resultData.fitoutBudCostMin[item.id]))+" - "+_vm._s(item.currency_symbol)+_vm._s(_vm._f("toThousandFilter")(_vm.resultData.fitoutBudCostMax[item.id]))+"/"+_vm._s(_vm.$t("welcome.project_rmb_per_sqm"))+" ")])]):_c('div',[_vm._v("-")])]),_c('div',{staticClass:"tr"},[_c('div',[_vm._v(_vm._s(_vm.$t("welcome.project_cost_ffne"))+":")]),(
                _vm.resultData.ffeFinCostMin[item.id] &&
                _vm.resultData.ffeFinCostMin[item.id] > 0 &&
                _vm.resultData.ffeFinCostMax[item.id]
              )?_c('div',[(
                  _vm.resultData.ffeFinCostMin[item.id] ==
                  _vm.resultData.ffeFinCostMax[item.id]
                )?_c('span',[_vm._v(" "+_vm._s(item.currency_symbol)+_vm._s(_vm._f("toThousandFilter")(_vm.resultData.ffeFinCostMin[item.id]))+" "+_vm._s(item.currency)+"/"+_vm._s(_vm.$t("welcome.project_rmb_per_sqm"))+" ")]):_c('span',[_vm._v(" "+_vm._s(item.currency_symbol)+_vm._s(_vm._f("toThousandFilter")(_vm.resultData.ffeFinCostMin[item.id]))+"- "+_vm._s(item.currency_symbol)+_vm._s(_vm._f("toThousandFilter")(_vm.resultData.ffeFinCostMax[item.id]))+_vm._s(item.currency)+"/"+_vm._s(_vm.$t("welcome.project_rmb_per_sqm"))+" ")])]):(
                _vm.resultData.ffeBudCostMin[item.id] &&
                _vm.resultData.ffeBudCostMin[item.id] > 0 &&
                _vm.resultData.ffeBudCostMax[item.id]
              )?_c('div',[(
                  _vm.resultData.ffeBudCostMin[item.id] ==
                  _vm.resultData.ffeBudCostMax[item.id]
                )?_c('span',[_vm._v(" "+_vm._s(item.currency_symbol)+" "+_vm._s(_vm._f("toThousandFilter")(_vm.resultData.ffeBudCostMin[item.id]))+_vm._s(item.currency)+"/"+_vm._s(_vm.$t("welcome.project_rmb_per_sqm"))+" ")]):_c('span',[_vm._v(" "+_vm._s(item.currency_symbol)+" "+_vm._s(_vm._f("toThousandFilter")(_vm.resultData.ffeBudCostMin[item.id]))+" - "+_vm._s(item.currency_symbol)+_vm._s(_vm._f("toThousandFilter")(_vm.resultData.ffeBudCostMax[item.id]))+_vm._s(item.currency)+"/"+_vm._s(_vm.$t("welcome.project_rmb_per_sqm"))+" ")])]):_c('div',[_vm._v("-")])]),(item.involvedSfw)?_c('div',{staticClass:"tr"},[_c('div',[_vm._v(_vm._s(_vm.$t("welcome.project_scope"))+":")]),_vm._l((item.involvedSfw),function(sfw,key){return _c('span',{key:key},[_c('span',{domProps:{"innerHTML":_vm._s(sfw)}}),(key != Object.keys(item.involvedSfw).length)?_c('em',[_vm._v("/")]):_vm._e()])})],2):_vm._e()])])}),0)]):_c('div',{staticClass:"no-result"},[_vm._v(" "+_vm._s(_vm.$t("common.no_result"))+": "+_vm._s(_vm.deliverable)+"} ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }