<template>
  <div>
    <ul
      class="res-list"
      :class="deginrefList.length <= 4 ? 'center' : ''"
      v-if="deginrefList"
    >
      <li
        v-for="(item, index) in deginrefList"
        :key="index"
      ><a :href="'/#/project-photo-album?id=' + mid + '&refId=' + item.id">
        <div class="img-link">
          <div class="mood-img">
            <!-- <el-image  :src="imgurl+item.path" lazy></el-image> -->
            <img class="img" v-lazy="imgurl + item.path + '/s300'" />
          </div>
        </div>
        <h2 v-html="item.name"></h2>
        <div class="info">
          <span v-if="item.speaker"></span>
          <span v-if="item.date"></span>

          <span v-if="item.place"></span>
        </div>
        </a>
      </li>
      <li class="forJustify"></li>
      <li class="forJustify"></li>
      <li class="forJustify"></li>
      <li class="forJustify"></li>
      <li class="forJustify"></li>
    </ul>
    <div class="no-result pdt20 pdb20" v-else>
      {{ $t("common.no_result") }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgurl: process.env.VUE_APP_IMG_URL
    };
  },
  props: {
    deginrefList: {
      type: Array
    },
    mid: {
      type: Number
    }
  },
  created() {},
  methods: {
    goPage(id) {
      this.$router.push(`/project-photo-album?id=${this.mid}&refId=${id}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.res-list {
  list-style: none;
  margin: 0 auto;
  /* max-width: 1100px; */
  width: 100%;
  padding: 0;
  text-align: justify;
  font-size: 0;
  margin-bottom: 50px;
  li {
    z-index: 0;
    display: inline-block;
    width: 20%;
    text-align: center;
    font-size: 15px;
    margin: 0px 0px 1% 0;
    padding: 15px;
    vertical-align: top;
    position: relative;
    cursor: pointer;
    @media (max-width: 1559px) {
      padding: 7.5px;
    }
    @media (max-width: 1023px) {
      width: 33.33%;
    }
    @media (max-width: 767px) {
      margin: 0 0 20px 0;
    }
    @media (max-width: 567px) {
      width: 50%;
    }
    .img-link {
      position: relative;
      padding: 0px;
      display: block;
      &:before {
        content: "";
        display: block;
        padding-top: 100%;
        overflow: hidden;
        background: #f2f2f2;
      }
      .mood-img {
        position: absolute;
        /* display: flex; */
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;

        img {
          display: block;
          min-width: 100%;
          min-height: 100%;
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
          margin: auto 0;
          object-fit: cover;
          transition: transform 1s;
          transition-timing-function: ease;
        }
      }
    }
    &:hover {
      .img-link {
        /deep/ .el-image img {
          transform: scale(1.1);
        }
      }
    }
    h2 {
      margin: 0;
      padding: 15px 0 8px;
      font-size: 18px;
      max-width: 100%;
      line-height: 26px;
      @media (max-width: 1023px) {
        padding: 15px 0 8px;
      }
      @media (max-width: 567px) {
        font-size: 15px;
      }
      @media (max-width: 375px) {
        font-size: 13px;
      }
    }
    .info {
      /* margin-top: 15px; */
      font-size: 10px;
      font-weight: normal;
      color: #999999;
      span {
        display: inline-block;
        color: #999999;
        margin: 0 3px 5px;
        &:first-child {
          display: block;
        }
      }
    }
  }
  .forJustify {
    height: 1px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
}
.center {
  text-align: center !important;
}
.no-result {
  text-align: center;
  margin-bottom: 80px;
  color: #999;
}
</style>
