

const app = {
  state: {
    // sidebar: {
    //   opened: !+Cookies.get('sidebarStatus'),
    //   withoutAnimation: false
    // },
    // device: 'desktop',
    navcurrent:sessionStorage.getItem("navcurrent") || 0,
  },
  mutations: {
    SET_CURRENT: (state, navcurrent) => {
        state.navcurrent = navcurrent;
        sessionStorage.setItem('navcurrent',navcurrent);
     },
   
  },
  actions: {

    // ChangeCurrent:({ commit }) => {
    //     commit('SET_CURRENT')
    // }

  }
}

export default app
