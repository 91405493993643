import request from '@/utils/request'

// login
export function product (params) {
  return request({
    url: '/api/products',
    method: 'get',
    params:params
  })
}

export function productFilters (params) {
    return request({
      url: '/api/product-filters',
      method: 'get',
      params:params
    })
}

export function getProductDetial (id,params) {
  return request({
    url: `/api/products/${id}`,
    method: 'GET',
    params
  })
}

export function getProductsubopt (id,params) {
  return request({
    url: `/api/products/${id}/sub-opt`,
    method: 'GET',
    params
  })
}

export function informs (data) {
  return request({
    url: '/api/informs',
    method: 'POST',
    data
  })
}

export function informsnotify (data) {
  return request({
    url: '/api/informs/notify-vendor',
    method: 'POST',
    data
  })
}

export function addWish (data) {
  return request({
    url: '/api/wishlist',
    method: 'POST',
    data
  })
}


export function wishlist (params) {
  return request({
    url: '/api/wishlist',
    method: 'GET',
    params:params
  })
}

export function updateWishCategory (data) {
  return request({
    url: '/api/wishlist',
    method: 'PUT',
    data
  })
}

export function deleteWish (id) {
  return request({
    url: `/api/wishlist/${id}`,
    method: 'DELETE',
  })
}

export function informslist (params) {
  return request({
    url: '/api/informs',
    method: 'GET',
    params:params
  })
}
export function searchlist (params) {
  return request({
    url: '/api/system/search',
    method: 'GET',
    params:params
  })
}

export function ratingslist (params) {
  return request({
    url: '/api/ratings',
    method: 'GET',
    params:params
  })
}
export function noticeslist (params) {
  return request({
    url: '/api/notices',
    method: 'GET',
    params:params
  })
}

export function noticesDetail (id) {
  return request({
    url: `/api/notices/${id}`,
    method: 'PUT',
  })
}

export function categoryList (params) {
  return request({
    url: `/api/wishlist-categories`,
    method: 'GET',
    params
  })
}

export function creatCategory (data) {
  return request({
    url: `/api/wishlist-categories`,
    method: 'POST',
    data
  })
}

export function updateCategory (data, id) {
  return request({
    url: `/api/wishlist-categories/${id}`,
    method: 'PUT',
    data
  })
}

export function deleteWishCategory (id,allWish) {
  return request({
    url: `/api/wishlist-categories/${id}?all_wish=${allWish}`,
    method: 'DELETE',
  })
}