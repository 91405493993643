import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocale from './en'
import zhLocale from './zh'
import zhhkLocale from './zh-hk'
// import enLocale from 'element-ui/lib/locale/lang/en'
// import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
// import zhhkLocale from 'element-ui/lib/locale/lang/zh-TW'
Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
  },
  sc: {
    ...zhLocale,
  }, 
  tc: {
    ...zhhkLocale,
  }, 
}
export function getLanguage() {
  const chooseLanguage = localStorage.getItem('language')
  if (chooseLanguage && chooseLanguage !== 'undefined') return chooseLanguage
  return 'tc'
}

const i18n = new VueI18n({
  locale: getLanguage(),
  messages,
})

export default i18n
