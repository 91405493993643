
<template>
  <div class="content" v-loading="loading">
    <b-container fluid>
      <b-row class="drs">
        <b-col cols="12">
          <h5 class="name tc">{{$t('common.inform')}}</h5>
        </b-col>
        <div class="informs">
          <div class="table-row heading">
            <div class="cell projectCode" style="width:100px; text-align:left;" @click="checkfilter(1,'project_code')">
              <span
              >項目編號</span>
              <!-- <i :class="['fa',filter.direction ? 'fa-sort-asc':'fa-sort-desc']"></i> -->
              <i :class="['fa','fa-sort',{'fa-sort-asc':project_code==1},{'fa-sort-desc':project_code==2}]"></i>
            </div>
            <div class="cell" style="text-align:left;" @click="checkfilter(2,'tag')">
              <span
              >查詢編號</span>
              <i :class="['fa','fa-sort',{'fa-sort-asc':tag==1},{'fa-sort-desc':tag==2}]"></i>
            </div>
            <div class="cell createdTime"  @click="checkfilter(3,'created_at')">
              <span
              >建立日期</span>
              <i :class="['fa','fa-sort',{'fa-sort-numeric-asc':created_at==1},{'fa-sort-numeric-desc':created_at==2}]"></i>
            </div>
            <div class="cell updateTime" @click="checkfilter(4,'updated_at')">
              <span
              
              >修改日期</span>
              <i :class="['fa','fa-sort',{'fa-sort-numeric-asc':updated_at==1},{'fa-sort-numeric-desc':updated_at==2}]"></i>
            </div>
            <div class="cell" @click="checkfilter(5,'is_sent')">
              <span
              >發送狀態</span>
              <i :class="['fa','fa-sort',{'fa-sort-asc':is_sent==1},{'fa-sort-desc':is_sent==2}]"></i>
            </div>
            <div class="cell" @click="checkfilter(6,'first_sent_datetime')">
              <span
              >發送日期</span>
              <i :class="['fa','fa-sort',{'fa-sort-numeric-asc':first_sent_datetime==1},{'fa-sort-numeric-desc':first_sent_datetime==2}]"></i>
            </div>
            <div class="cell" @click="checkfilter(7,'sent_count')">
              <span
              >發送次數</span>
               <i :class="['fa','fa-sort',{'fa-sort-asc':sent_count==1},{'fa-sort-desc':sent_count==2}]"></i>
            </div>
          </div>


			<div class="row" style="display:table-row-group;" v-for="project in informData.projects" :key="project.id">
				<div class="cell" style="max-width: 1px; overflow: visible; font-size: 12px; display: table-cell; white-space: nowrap; padding:20px 3px;"><b>{{ project.versionwithzero>0? project.project_no+"-"+ project.versionwithzero: project.project_no }}{{  project.project_type!=""? +'-'+project.project_type:"" }} {{ project.dName }}</b></div>
			</div>


				<div class="table-row" v-for="(item,index) in informList.data" :key="index">
					<div class="cell"></div>
					<div class="cell" style="text-align:left;">{{ item.tag }}</div>
					<div class="cell">{{ item.created_at }}</div>
					<div class="cell">{{ item.updated_at }}</div>
					<div :class="['cell', item.is_sent==1?'':'notSend']">{{ item.is_sent==1? $t('common.sent'): $t('common.draft')}}</div>
					<div class="cell">
          <span v-if="item.is_sent==1">
						{{ item.sent_count==1 ? item.first_sent_datetime:item.resent_datetime }}
          </span>
          <span>
						-
				
          </span>
					</div> 
					<div class="cell">{{ item.is_sent==1?item.sent_count:"-" }}</div>
					<div class="cell actions" style="width:200px; text-align:right;">
				
					
						<button v-if="item.is_sent==0" type="button" class="btn btn-default btn-sm" @click="DialogVisible = true"><i class="fa fa-trash-o"></i></button>
						
						<a class="btn btn-default btn-sm" href="#" data-toggle="tooltip" data-placement="top"><span class="fa fa-ellipsis-h"></span> {{ $t('common.details') }}</a>
					</div>
				</div>

      

    
        </div>
      </b-row>
    </b-container>
    <el-dialog
      :title="$t('common.confirm_to_del')"
      :visible.sync="DialogVisible"
      width="30%"
      center>
     
      <span slot="footer" class="dialog-footer">
        <el-button @click="DialogVisible = false">{{$t('common.confirm')}}</el-button>
        <el-button type="primary" @click="DialogVisible = false">{{$t('common.cancel')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { informslist} from "@/api/product";
export default {
  data() {
    return {
      loading:false,
      informData:[],
      informList:'',
      filter:{
        sort:null,
        direction:null,
        page:1
      },
      project_code:0,
      tag:0,
      created_at:0,
      updated_at:0,
      is_sent:0,
      first_sent_datetime:0,
      sent_count:0,  
      DialogVisible:false
    };
  },

  created() {
    this.getinforms();
  },
  methods: {
    getinforms(){
      this.loading = true;
      informslist(this.filter).then(res=>{
        this.loading = false;
        this.informData = res.data;
        this.informList = res.data.list;
      })
    },
    checkfilter(num,type){
      this.filter.sort = type;
      switch(num){
        case 1:
          this.tag = this.created_at= this.updated_at=this.is_sent = this.first_sent_datetime = this.sent_count=0
          this.project_code = this.filterNum(this.project_code);
        break;
        case 2:
          this.project_code = this.created_at= this.updated_at=this.is_sent = this.first_sent_datetime = this.sent_count=0
          this.tag=this.filterNum(this.tag); 
        break; 
        case 3:
          this.project_code = this.tag =this.updated_at=this.is_sent = this.first_sent_datetime = this.sent_count=0
          this.created_at=this.filterNum(this.created_at); 
        break;  
        case 4:
          this.project_code = this.tag = this.created_at=this.is_sent = this.first_sent_datetime = this.sent_count=0
          this.updated_at=this.filterNum(this.updated_at); 
        break; 
        case 5:
          this.project_code = this.tag = this.created_at= this.updated_at =this.first_sent_datetime = this.sent_count=0
          this.is_sent=this.filterNum(this.is_sent); 
        break;   
        case 6:
          this.project_code = this.tag = this.created_at= this.updated_at=this.is_sent =this.sent_count=0
          this.first_sent_datetime=this.filterNum(this.first_sent_datetime); 
        break; 
        default:
          this.project_code = this.tag = this.created_at= this.updated_at=this.is_sent = this.first_sent_datetime =0
          this.sent_count=this.filterNum(this.sent_count); 
          break; 
      }
      this.getinforms();

    },
    filterNum(type){
      if(type == 0){
        type = 1;
      }else if(type==1){
        type = 2;
      }else if(type==2){
        type=1;
      }
      this.filter.direction = type==1? 'asc' :type==2?'desc':'';
      return type;
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  padding: 25px 0 40px;
  overflow-x: hidden;
  .name {
    padding: 20px 0px 20px;
    font-size: 21px;
  }
}
.informs{
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    display: table;
    border-collapse: collapse;
    width: 100%;
    margin: 22px 0px 30px;
    .table-row {
      margin: 0px;
      width: 100%;
      border-bottom: 1px solid #ddd;
      font-size: 0;
      display: table-row;
      position: relative;
    }
    .table-row.heading {
      border-bottom: 2px solid #ddd;
      .cell{
        font-weight: bold;
        white-space: nowrap;
        padding: 10px 3px;
      }
    }
    .cell {
      min-height: 1em;
      padding: 3px 3px;
      text-align: center;
      font-size: 12px;
      vertical-align: middle;
      display: table-cell;
      span{
        font-weight:bold;
        &:hover{
          color: #c63033;
        }
      }
      
    }
}
.drs{
  padding: 0 15px;
}
</style>