<template>
  <div class="content" v-loading="loading">
    <b-container fluid>
      <b-row class="deliverables">
        <b-col md="12">
          <div class="name mgb20 tc">
            <div>
              {{ref.name}}
            </div>
            <div class="coCat tc">
              <el-select popper-append-to-body :style="{width:selectOptionWidth}" v-model="sort"  placeholder-color="#555" @change="getchangevalue">
                <el-option
                  v-for="item in $t('common.sortOption')"
                  :key="item.value"
                  @click.native="setOptionWidth"
                  :label="item.text"
                  :value="item.value">
                  <span style="float: left">{{ item.text }}</span>
                </el-option>
              </el-select>
            </div>

          </div>

        </b-col>
        <b-col md="12">
          <design-list :list-data="listData" :mid="mid"></design-list>
        </b-col>



      </b-row>
    </b-container>
  </div>
</template>

<script>
import { getDesignrefList } from "@/api/design";
import designList from '@/components/Guideline/DesignList'
export default {
  components: {
    'design-list':designList
  },
  data(){
    return{
      selectOptionWidth:'86px',
      mid:null,
      loading:false,
      // sortOption:[
      //   {
      //     value:'',
      //     text:'排序方式'
      //   },{
      //     value:'asc',
      //     text:'按升序'
      //   },{
      //     value:'desc',
      //     text:'按降序'
      //   },
      // ],

      sort:'',
      listData:[],
      ref:''

    }
  },
  created(){
    this.mid = Number(this.$route.query.mid);
    this.sort = this.$route.query.sort;
    this.getDesign();

  },
  watch:{
      '$route' () {
        this.mid = Number(this.$route.query.mid);
        this.sort = this.$route.query.sort;
          this.getDesign();

      }

  },

  methods:{
    setOptionWidth(event){
      if(this.sort){
        this.$router.push({path:'design-guideline',query:{mid:this.mid,sort:this.sort}})
      }else{
        this.$router.push({path:'design-guideline',query:{mid:this.mid}})
      }
      this.$nextTick(() => {
        if(event.target.localName == 'span'){
          this.nodeIndex = event.target.clientWidth + 30
        }else{
          this.nodeIndex = event.target.childNodes[0].clientWidth + 30
        }
        this.selectOptionWidth = this.nodeIndex + "px";
      });
    },
    getDesign(){
      if (this.mid) {
        this.loading = true;
        getDesignrefList(this.mid,{sort:this.sort}).then(res=>{
          this.loading = false;
          this.listData = res.data.resList;
          this.ref = res.data.ref;
        })
      }

    },
    goPage(url){
      this.$router.push(url)
    },
    getchangevalue(e){
      this.sort = e;
      this.getDesign();
    }

  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 25px 0 40px;
  overflow-x: hidden;
  .name {
    padding: 20px 0px 20px;
    font-size: 21px;
  }
  .deliverables {
    max-width: 1530px;
    margin: 0 auto;
    text-align: left;
    @media (max-width: 1023px) {
      /*margin: 0 -15px;*/

    }
    @media (max-width: 767px) {
      /*margin: 0 -15px;*/
    }
    .col-md-12 {
      padding: 0;
    }
  }
}
/deep/ .el-input__inner{
  border: none;
  font-size: 13px;
  color: #555;
  text-align: center;
  padding-left: 0;
}
.width125 /deep/ .el-input__inner{width:125px;}
.width59 /deep/ .el-input__inner{width:86px;}
.width72 /deep/ .el-input__inner{width:72px;}
.width86 /deep/ .el-input__inner{width:86px;}
.width73 /deep/ .el-input__inner{width:73px;}
.width60 /deep/ .el-input__inner{width:60px;}

</style>
