<template>
  <div>
    <el-dialog class="upload-dialog" :visible.sync="isuploadDialog" width="40%" @close="closeUpload">
      <div class="pdl35 pdr35 pdb40">
        <div class="dislog-title tl">上傳附件</div>
        <div class="jconfirm-box">
          <el-upload class="upload-demo" action>
            <div class="dz-default dz-message">
              <span>
                拖放文件在這裡上傳
                <br />(檔案大小上限: 25MB)
              </span>
            </div>
          </el-upload>
        </div>
        <div class="mgt20 tl">
          <p>檔案大小上限為25MB</p>
          <h5 class="section-sub-title ft18">
            已上傳附件 (
            <span class="uploaded-files-count">0</span>)
          </h5>
          <div class="no-files-uploaded">尚無新文件上傳。</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
    data(){
        return{
            isuploadDialog:false
        }
    },
    props:{
        uploadDialog:{
            type:Boolean
        }
    },
    watch:{
        uploadDialog() {
            this.isuploadDialog = this.uploadDialog;
        },
        
    },
    methods:{
        closeUpload(){
            this.$emit("uploadChange", false);
        }
    }
}
</script>

<style lang="scss" scoped>
.upload-dialog {
  /deep/ .el-dialog__headerbtn{
    right: 10px;
    top: 10px;
    .el-dialog__close{
        font-size: 20px;
        font-weight: bold;
    }
  }
  /deep/ .el-dialog{
      height: 60%;
      overflow: hidden;
  }
  /deep/ .el-dialog__body{
    overflow-x: hidden;
    padding: 0 0 20px;
    height: 100%;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 3px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
  .dislog-title {
    font-family: "Montserrat", Arial, Sans-serif;
    font-size: 18px;
    line-height: 1.35;
    text-transform: capitalize;
  }
  .jconfirm-box {
    margin-top: 20px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: 1px dashed rgba(85, 85, 85, 0.5);
    background: #efefef;
    /deep/.el-upload {
      display: block;
    }
    .dz-default {
      padding: 70px 20px;
    }
  }
  .section-sub-title{
    line-height: 1.35;
    text-transform: capitalize;
    font-family: "Montserrat", Arial, Sans-serif;
  }
}
</style>