<template>
  <div>
      <el-dialog class="send1-dialog dialog" :visible.sync="issendDialog" width="55%" top="0"  v-if="detailData && submitData" @close="closeSendDialog">
        <div class="cat_preview tl">{{detailData.product.contact_person}}，您好！
            <br><br>
            我司想查询贵司的产品，烦请查看以下项目资料并尽早与我们设计师联系。 谢谢！ 
            <br><br><h6>项目资料</h6>
            <div v-if="submitData!=''">
                <label>项目编号:</label> 
                <span id="preview_project_text">{{submitData.project.project_no}} {{submitData.project.versionwithzero>0?`- ${submitData.project.versionwithzero}`:''}}</span>
            </div>
                <div v-if="submitData.inc_pname==1"><label>项目名称:</label> <span ></span></div>
                <div v-if="submitData.inc_cname==1"><label>客户名称:</label> <span ></span></div>
                
                <h6>产品资料</h6>
                <div><label>品牌:</label> <span >{{detailData.productInfo.brandInfo.name}}</span></div>
                <div><label>产品目录:</label> <span >{{detailData.product.name}}</span></div>
                <div><label>查询产品:</label> <span id="preview_msg">{{submitData.msg}}</span></div>
                <h6>负责设计师</h6>
                <div><label>名称:</label> <span >{{detailData.sender.english_name}}{{detailData.sender.lastname}}</span></div>
                <div><label>职位:</label> <span ></span>{{detailData.sender.title_tc}}</div>
                <div><label>邮箱:</label> <span ></span>{{detailData.sender.email}}</div>
                <div><label>公司地址:</label> <span >{{detailData.sender.officeAddr}}</span></div>
                <div><label>电话:</label> <span >{{detailData.sender.mobile2}}</span></div>
                <div><label>手提电话:</label> <span >{{detailData.sender.mobile1}}</span></div>
                <div><label>微信号:</label> <span >{{detailData.sender.wechat_no}}</span></div>
                <h6>要求提供</h6><div><span  v-if="submitData.requestSample==1">{{$t('productfilter.project3')}} </span><span  v-if="submitData.requestProposal==1">{{$t('productfilter.project4')}} </span></div>
                <h6>回复/汇报时间</h6><div><span >{{submitData.deline}}</span></div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="sendFrom">{{$t('productfilter.Send')}}</el-button>
                <el-button size="small"  @click="closeSendDialog">{{$t('productfilter.Cancel')}}</el-button>
            </div>
      </el-dialog>
      <alert-tip v-if="isalertShow" ref="alert" :type="alertType"  :add-type="addType" @alertChange="closealertChange"></alert-tip>
  </div>
</template>

<script>
import { informsnotify } from "@/api/product";
import alertTip from '@/components/AlertTip'
export default {
    components: {
        'alert-tip':alertTip
    },
    data(){
        return{
            issendDialog:false,
            alertType:'success',
            isalertShow:false,
            addType:'email'
        }
    },

    props:{
        isVisble:{
            type:Boolean
        },
        detailData:{
            type:Object
        },
        submitData:{
            // type:Object 
        }
    },
    watch:{
        isVisble() {
            this.issendDialog = this.isVisble;
        },
        
    },
    methods:{
        closeSendDialog(){
            this.$emit("dialogChange", false);
        },
        sendFrom() {  
            this.isalertShow = true; 
            informsnotify(this.submitData).then(res => {
                if (res.code == 200) {
                    this.isalertShow = false; 
                    this.issendDialog = false;
                }
            });
            
        
        },
        closealertChange(val){
            this.isalertShow = val; 
        }
    }
}
</script>

<style lang="scss" scoped>
.send1-dialog{
    padding: 40px 0;
    /deep/ .el-dialog__headerbtn{
        right: 10px;
        top: 10px;
        .el-dialog__close{
            font-size: 20px;
            font-weight: bold;
        }
    }
    /deep/ .el-dialog{
        height: 100%;
        overflow: hidden;
        margin: 0 auto;
    }
    /deep/ .el-dialog__body {
        // max-height: 75%;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0;
        margin-bottom: 20px;
        height: calc(100% - 115px);
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 3px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.3);
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
    }
    .cat_preview{
       padding: 0 20px;
       h6{
           font-size: 12px;
           margin: 20px auto 0;
           font-weight: 700;
       }
       >div{
            margin-top: 5px;
            line-height: 180%;
            label{
                
                font-size: 14px;
            }
        }
       
   }
}


.dialog /deep/ .el-dialog__footer{border-top: #ddd 1px solid; position: absolute;width: 100%; background: #fff;}
.dialog-footer{
  button{
      text-shadow: none;
      border: none;
      font-size: 14px;
      background-color: #ecf0f1;
      color: #000;
      &:hover{
        background-color: #bdc3c7;
      }
  }

}
</style>