import request from '@/utils/request'

// login
export function supplierCat (params) {
  return request({
    url: '/api/supplier-cats',
    method: 'get',
    params:params
  })
}

export function supplierCatFilters (id) {
    return request({
      url: `/api/supplier-cats/${id}`,
      method: 'get',
    })
}

export function getProductcat (id) {
  return request({
    url: `/api/supplier-cats/${id}/product-cats`,
    method: 'GET',
  })
}

export function getsuppliercatDetail (id,catid) {
  return request({
    url: `/api/supplier-cats/${id}/product-cats/${catid}`,
    method: 'GET',
  })
}
export function getsuppliers (id,catid) {
  return request({
    url: `/api/supplier-cats/${id}/product-cats/${catid}/suppliers`,
    method: 'GET',
  })
}
export function getsuppliersDetail (catId,supplieid,brandId) {
  return request({
    url: `/api/supplier-cats/${catId}/product-cats/${supplieid}/suppliers/${brandId}/brands`,
    method: 'GET',
  })
}
//third-party vendor
export function getsuppliersVendor (params) {
  return request({
    url: `/api/suppliers`,
    method: 'GET',
    params
  })
}
//company
export function getCompanyType (cl) {
  return request({
    url: `/api/company-types?cl=${cl}`,
    method: 'GET',
  })
}

export function getCompanyTypeDetail (cl) {
  return request({
    url: `/api/company-types/${cl}`,
    method: 'GET',
  })
}

export function getCompanies (cl,ct) {
  return request({
    url: `/api/companies?cl=${cl}&ct=${ct}`,
    method: 'GET',
  })
}
export function getCompaniesDetail (id) {
  return request({
    url: `/api/companies/${id}`,
    method: 'GET',
  })
}
// export function createrating(data) {
//   return request({
//     url: `/api/rating/create`,
//     method: 'GET',
//     data
//   })
// }

export function createrating (params) {
  return request({
    url: '/api/rating/create',
    method: 'get',
    params:params
  })
}

export function createratingfrom (data) {
  return request({
    url: '/api/rating',
    method: 'POST',
    data
  })
}


export function uploadFile (data) {
  return request({
    url: '/api/files',
    method: 'POST',
    data,
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded'
    // }
  })
}



