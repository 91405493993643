<template>
  <div>
    <el-dialog
      v-loading="pdfLoading"
      class="upload-dialog"
      :visible.sync="Dialog"
      width="85%"
      top="0"
      @close="diaClose"
    >
      <div
        v-if="pdfSrc"
        class="catalog_preview"
        style="flex: auto; position: relative; margin: 10px 8px 8px"
      >
        <div id="iframe-div" style="padding: 0px; background: #f0f0f0">
          <iframe :src="pdfSrc" frameborder="0"></iframe>
        </div>
      </div>
      <div v-else-if="errorText" class="pdf-error">{{errorText}}</div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Dialog: false,
    }
  },
  props: {
    pdfDialogVisible: {
      type: Boolean,
      default: false,
    },
    pdfSrc: {
      type: String,
    },
    pdfLoading: {
      type: Boolean,
      default: false,
    },
    errorText:{
      type: String,
    }
  },
  watch: {
    pdfDialogVisible(oldVal, newVal) {
      this.Dialog = this.pdfDialogVisible
    },
  },
  methods: {
    diaClose() {
      this.$emit('pdfChange', false,)
    },
  },
}
</script>

<style lang="scss" scoped>
.catalog_details {
  color: #555555;
  margin-top: -30px;
  .pdp_info {
    border-bottom: #eee 1px solid;
  }
  .addtobag {
    position: relative;
    width: 100%;
    .pdp_request {
      position: relative;
      .multiselect {
        width: 100%;
        position: relative;
        /deep/ .btn {
          width: 100%;
          text-align: left;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          -webkit-tap-highlight-color: transparent;
          font-size: 14px;
          &:hover {
            background-color: #e6e6e6;
            border-color: #adadad;
            color: #333;
          }
        }
        .caret {
          display: inline-block;
          width: 0;
          height: 0;
          margin-left: 2px;
          vertical-align: middle;
          border-top: 4px solid;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
          margin-left: 0;
          position: absolute;
          top: 50%;
          right: 12px;
          margin-top: -2px;
          vertical-align: middle;
        }
      }
      .open {
        /deep/ .btn {
          background-color: #e6e6e6;
          border-color: #adadad;
        }
      }
    }
    .multiselect-container {
      min-width: 200px;
      max-height: 50vh;
      overflow: scroll;
      overflow-x: hidden;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      // display: none;
      width: 100%;
      max-width: 100%;
      padding: 5px 0;
      margin: 2px 0 0;
      font-size: 14px;
      text-align: left;
      list-style: none;
      background-color: #fff;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      border: 1px solid #ccc;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
      li {
        .input-group {
          position: relative;
          margin: 5px;
          width: auto;
          .input-group-addon {
            padding: 6px 12px;
            font-size: 14px;
            font-weight: 400;
            line-height: 2;
            color: #555;
            text-align: center;
            background-color: #eee;
            border: 1px solid #ccc;
            border-radius: 4px;
          }
          .input-group-btn {
            border: 1px solid #ccc;
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
          }
        }
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
      }
      /deep/ .custom-control {
        font-size: 13px;
        color: #555555;
        padding: 0 20px;
        height: 22px;
        line-height: 22px;
        display: flex;
        align-items: center;
      }
      /deep/ .custom-control-label {
        margin-left: 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex: 1;
      }
    }
    .pdp_wechat {
      position: relative;
      .fa {
        position: absolute;
        top: 9px;
        left: 12px;
        font-size: 16px;
        width: 16px;
        text-align: center;
        line-height: 18px;
      }
      .fa-mobile {
        font-size: 20px;
      }
      /deep/ .form-control {
        padding-left: 35px;
        font-size: 14px;
      }
    }
  }
  .attachment {
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    &:hover {
      color: #333;
      background-color: #e6e6e6;
      border-color: #adadad;
    }
  }
}
/deep/ .btn-success {
  background-color: #5cb85c;
  border-color: #4cae4c;
  &:hover {
    background-color: #449d44;
    border-color: #398439;
  }
}

.preview-dialog {
  padding: 40px 0;
  .cat_preview {
    color: #555555;
    font-size: 14px;
    line-height: 20px;
    margin-top: -30px;
    .pdp_info {
      border-bottom: 1px solid #eee;
      margin-bottom: 20px;
      padding-bottom: 10px;
    }
    h6 {
      margin-top: 20px;
      font-weight: bold;
      font-size: 14px;
    }
    label {
      width: 100px;
      display: inline-block;
    }
    span {
      position: relative;
      display: inline-block;
      vertical-align: top;
      margin-bottom: 5px;
    }
  }
  .dialog-footer {
    button {
      text-shadow: none;
      border: none;
      font-size: 14px;
      background-color: #ecf0f1;
    }
    .btn1 {
      background-color: #ecf0f1;
      color: #000;
      &:hover {
        background-color: #bdc3c7;
        color: #000;
      }
    }
    .btn2 {
      color: #fff;
      background-color: #5cb85c;
      &:hover {
        background-color: #449d44;
      }
    }
  }
  /deep/ .el-dialog__footer {
    border-top: #ddd 1px solid;
  }
}
/deep/ .el-dialog {
  height: 100%;
}
/deep/ .el-dialog__body {
  max-height: 85%;
  overflow-x: hidden;
  overflow-y: auto;
  // padding: 0 0 20px;
  padding: 0;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
/deep/ .el-dialog__headerbtn {
  top: 5px;
  right: 10px;
  font-weight: bold;
  font-size: 20px;
}
.upload-dialog {
  padding: 40px 0;
  /deep/ .el-dialog {
    height: 100%;
    overflow: hidden;
    padding-bottom: 30px;
  }
}
.upload-dialog /deep/ .el-dialog__body {
  height: 100%;
  max-height: 100%;
}
.catalog_preview {
  width: auto;
  flex: auto;
  position: relative;
  iframe {
    width: 100%;
    height: 77.5vh;
    border: 5px solid #e5e5e5;
  }
}
.pdf-error{
  margin-top: 50px;
  text-align: center;
  font-weight: 600;
}
</style>
