import request from '@/utils/request'


export function getSldBrands (params) {
  return request({
    url: '/api/sld-brands',
    method: 'get',
    params:params
  })
}


export function getBrandDetail (id) {
  return request({
    url: `/api/sld-brands/${id}`,
    method: 'get',
  })
}


export function getproductcats (params) {
  return request({
    url: '/api/product-cats',
    method: 'get',
    params:params
  })
}


export function getBrand (type,cat) {
  return request({
    url: cat == null ? `/api/co-brands/${type}`: `/api/co-brands/${type}?cat=${cat}`,
    method: 'get',
   
  })
}

// export function getVendor (type,cat,loc) {
//   return request({
//     url: loc == null ? `/api/co-vendors/${type}`: `/api/co-vendors/${type}?loc=${loc}`,
//     method: 'get',
   
//   })
// }
export function getVendor (type,params) {
  return request({
    url:`/api/co-vendors/${type}`,
    method: 'get',
    params
  })
}

export function getcontactVendor (id) {
  return request({
    url:`/api/contact-vendor/${id}`,
    method: 'get',
  })
}

export function getBrandShops (id) {
  return request({
    url:`/api/co-brands/${id}/shops`,
    method: 'get',
  })
}