import request from '@/utils/request'

export function getDesignRef (params) {
  return request({
    url: '/api/design-refs',
    method: 'get',
    params:params
  })
}

export function getDesignrefList (id,params) {
  return request({
    url: `/api/design-refs/${id}`,
    method: 'get',
    params:params
  })
}
export function getDesignrefdetailList (id,refId,params) {
  return request({
    url: `/api/design-refs/${id}/${refId}`,
    method: 'get',
    params:params
  })
}

export function getDeliverables (params) {
  return request({
    url: '/api/deliverables',
    method: 'get',
    params:params
  })
}
export function deliverableSelections (params) {
  return request({
    url: '/api/deliverable-selections',
    method: 'get',
    params:params
  })
}
export function getdeliverablesdetail (id,params) {
  return request({
    url: `/api/deliverables/${id}`,
    method: 'get',
    params:params
  })
}
export function getfileViews (id) {
  return request({
    url: `/api/file-views/${id}`,
    method: 'get'
  })
}

export function getprivateHouse () {
  return request({
    url: `/api/private-house-resources`,
    method: 'get'
  })
}
// export function getFile(params) {
//   return request({
//     url: `/api/getfile`,
//     method: 'get',
//     params:params
//   })
// }

export function getFiles(params) {
  return request({
    url: `/get-file`,
    method: 'get',
    params:params
  })
}

export function getUrls(params) {
  return request({
    url: `/api/get-url`,
    method: 'get',
    params:params
  })
}
