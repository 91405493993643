<template>
  <div class="content" v-loading="loading">
    <b-container fluid>
      <b-row >
          <h5 class="name mgb20 tc ft21">{{$t('Promotions.type_private_house')}}</h5>

          <div class="mgt20 mgb10" v-if="ProductionCompany!=''">
            <h3 id="production-companies-title" class="tc ft18">{{ $t('Promotions.production_companies') }}</h3>
            <div class="region-row" v-for="(list,index) in ProductionCompany" :key="index">
                <h2 class="ft15 mgt10 mgb15">{{list.classname}}</h2>
                <div class="cards">
                    <div class="card-item"  v-for="item in list.classData" :key="item.id" @click="goPage(item.id)">
                        <div class="thumb">
                            <div class="thumb-photo">
                                <div class="background-fill">{{item.name}}</div>

                                <div class="img-container">
                                    <img v-lazy="imgurl+'/'+item.cover_path" />
                                </div>

                            </div>
                            <div class="title-link">
                                <span>{{item.name}}</span>
                            </div>
                            <div class="spec">{{item.spec}}</div>
                            <div class="spec">{{item.type_name}}</div>

                            <div class="score">
                                <el-rate v-model="item.avgRating" disabled disabled-void-color="#e4a100" disabled-void-icon-class="el-icon-star-off"></el-rate>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div class="mgt20 mgb10" v-if="EquipSysSupplier!=''">
            <h3 id="production-companies-title" class="tc ft18">{{ $t('Promotions.equip_sys_suppliers') }}</h3>
            <div class="region-row" v-for="(list,index) in EquipSysSupplier" :key="index">
                <h2 class="ft15 mgt10 mgb15">{{list.classname}}</h2>
                <div class="cards" >
                    <div class="card-item" v-for="item in list.classData" :key="item.id" @click="goPage(item.id)">
                        <div class="thumb">
                            <div class="thumb-photo">
                                <div class="background-fill">{{item.name}}</div>

                                <div class="img-container">
                                    <img v-lazy="imgurl+'/'+item.cover_path" />
                                </div>

                            </div>
                            <div class="title-link">
                                <span>{{item.name}}</span>
                            </div>
                            <div class="spec">{{item.spec}}</div>
                            <div class="spec">{{item.type_name}}</div>

                            <div class="score">
                                <el-rate v-model="item.avgRating" disabled disabled-void-color="#e4a100" disabled-void-icon-class="el-icon-star-off"></el-rate>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div class="mgt20 mgb10" v-if="FurnishingProduct!=''">
            <h3 id="production-companies-title" class="tc ft18">{{ $t('productfilter.Furnishings') }}</h3>
            <div class="region-row" v-for="(list,index) in FurnishingProduct" :key="index">
                <h2 class="ft15 mgt10 mgb15">{{list.classname}}</h2>
                <div class="cards">
                    <div class="card-item" v-for="item in list.classData" :key="item.id" @click="goProduct(item.id,item.opt_id ,item.subopt_id)">
                        <div class="thumb">
                            <div class="thumb-photo">
                                <div class="background-fill">{{item.name}}</div>

                                <div class="img-container">
                                    <img v-lazy="imgurl+'/'+item.cover_path" />
                                </div>

                            </div>
                            <div class="title-link">
                                <span class="brandname">{{item.brandname}}</span>
                                <span>{{item.name}}</span>
                            </div>

                            <div class="spec">{{item.subCat}}</div>


                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div class="mgt20 mgb10" v-if="FitoutProduct!=''">
            <h3 id="production-companies-title" class="tc ft18">{{ $t('productfilter.fit_out_products') }}</h3>
            <div class="region-row" v-for="(list,index) in FitoutProduct" :key="index">
                <h2 class="ft15 mgt10 mgb15">{{list.classname}}</h2>
                <div class="cards">
                    <div class="card-item"  v-for="item in list.classData" :key="item.id" @click="goProduct(item.id,item.opt_id ,item.subopt_id)">
                        <div class="thumb">
                            <div class="thumb-photo">
                                <div class="background-fill">{{item.name}}</div>

                                <div class="img-container">
                                    <img v-lazy="imgurl+'/'+item.cover_path" />
                                </div>

                            </div>
                            <div class="title-link">
                                <span class="brandname">{{item.brandname}}</span>
                                <span>{{item.name}}</span>
                            </div>

                            <div class="spec">{{item.subCat}}</div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div class="mgt20 mgb10 deliverable-region" v-if="Deliverable!=''">
            <h3 id="production-companies-title" class="tc ft18">{{ $t('welcome.design_deliverable') }}</h3>
            <div class="region-row">
                <div class="cards" >
                    <div class="card-item" v-for="(item,index) in Deliverable" :key="index" @click="goDeliverable(item.id)">
                        <div class="thumb">
                            <div class="thumb-photo">
                                <div class="background-fill">{{item.name ||'retret'}}</div>

                                <div class="img-container">
                                    <img v-lazy="imgurl+'/'+item.cover_path" />
                                </div>

                            </div>
                            <span class="project-name">{{item.name}}</span>
                            <div class="specs">
                                <div class="b-tr">
                                    <div>{{$t("welcome.project_code")}}:</div>
                                    <div class="project-no">{{item.project_no}}</div>
                                </div>
                                <div class="b-tr">
                                    <div>{{$t("welcome.project_location")}}:</div>
                                    <div>{{item.location}}</div>
                                </div>
                                <div class="b-tr">
                                    <div>{{$t("welcome.project_type")}}:</div>
                                    <div>{{item.optname}}</div>
                                </div>
                                <div class="b-tr">
                                    <div>{{$t("welcome.project_client")}}:</div>
                                    <div>{{item.delevoper}}</div>
                                </div>
                                <div class="b-tr">
                                    <div>{{$t("welcome.project_cost_fitting_out")}}:</div>
                                    <div>{{item.fitoutCost}}</div>
                                </div>
                                <div class="b-tr">
                                    <div>{{$t("welcome.project_cost_ffne")}}:</div>
                                    <div>{{item.ffeCost}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import { getprivateHouse } from "@/api/design";
export default {
    data(){
        return {
            ProductionCompany:[],
            EquipSysSupplier:[],
            FurnishingProduct:[],
            FitoutProduct:[],
            loading:false,
            Deliverable: [],
            imgurl:''
        }
    },
    created(){
        this.getprivate();
    },
    methods:{
        async getprivate(){
           this.loading = true;
           let result = await getprivateHouse();
           if(result){
               this.loading = false;
               this.ProductionCompany = result.data.ProductionCompany;
                this.EquipSysSupplier = result.data.EquipSysSupplier;
                this.FurnishingProduct = result.data.FurnishingProduct;
                this.FitoutProduct = result.data.FitoutProduct;
                this.Deliverable = result.data.Deliverable;
                this.imgurl = result.data.resPrefix;
           }

        },
        goDeliverable(id){
          this.$router.push(`/deliverable-detail?id=${id}`)
        },
        goPage(id){
          this.$router.push(`/supplierdetail?&id=${id}`)
        },
        goProduct(id,optID,subOptID){
             this.$store.commit('SET_CURRENT', 4);
             this.$router.push(`/productdetail?id=${id}&optID=${optID}&subOptID=${subOptID}`)
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    padding: 25px 0 40px;
    overflow-x: hidden;
    .name {
      padding: 20px 0px 20px;
      color: #333;
    }
}
.cards {
    width: 100%;
    max-width: 1530px;
    margin: 0 auto 50px;
    perspective: 800px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .card-item {
        width: 20%;
        color: #333;
        /* float: left; */
        margin: 0 0 0.2em 0;
        padding: 15px;
        z-index: 1;
        // opacity: 0;
        position: relative;
        animation-name: fadeIn;
        animation-duration: 1s;
        animation-timing-function: ease;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-play-state: paused;
        animation-fill-mode: forwards;
        cursor: pointer;
        @media (max-width: 1559px) {
          padding: 15px 7.5px;
        }
        @media (max-width: 1023px) {
          width: 33.33%;
        }
        @media (max-width: 567px) {
          width: 50%;
        }
        .thumb{
            .thumb-photo{
                position: relative;
                &::before{
                    content: "";
                    display: block;
                    padding-top: 100%;
                    overflow: hidden;
                    background: #f2f2f2;
                }
                .background-fill {
                    vertical-align: middle;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #CCC;
                    position: absolute;
                    top: 10px;
                    left: 0;
                    bottom: 0;
                    right: 0;
                }
                .img-container {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                }
            }
            img {
                display: block;
                min-width: 100%;
                min-height: 100%;
                max-width: 100%;
                max-height: 100%;
                width: auto;
                height: auto;
                margin: auto 0;
                object-fit: cover;
                z-index: -1;
            }
            .title-link{
                display: block;
                font-size: 14px;
                padding: 15px 0 2px;
                text-align: center;
                color: #555555;
            }
            .spec {
                display: block;
                text-align: center;
                font-size: 13px;
                color: #999999;
            }
        }
    }
}
.deliverable-region{
    .project-name{
        padding: 15px 0 2px;
        font-size: 14px;
        padding: 0px 0px;
        min-height: 40px;
        line-height: 18px;
        display: flex;
        margin: 5px 0;
        align-items: center;
    }
    .specs {
        font-size: 12px;
        color: #999999;
        display: table;
        margin-top: 0px;
        text-align: left;
        .b-tr{
            font-size: 12px;
            font-weight: normal;
            color: #999999;
            display: table-row;
            div{
                font-size: 12px;
                color: #999999;
                display: table-cell;
                padding-bottom: 1px;
                &:first-child{
                    padding-right: 5px;
                    min-width: 70px;
                }
            }
            .project-no{
                color: #555;
            }
        }
    }
}
/deep/ .el-rate__icon{
    font-size: 15px;
    margin-right: 5px;
}
</style>
