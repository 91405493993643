import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import page from './modules/page'
import app from './modules/nav'
import getters from './getters'
import mutations from './mutations'

Vue.use(Vuex)


export default new Vuex.Store({
  modules: {
    user,
    page,
    app
  },
  mutations,
  getters
})
