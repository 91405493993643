/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
	// return (num).toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
	return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}



