<template>
  <div class="content" v-loading="loading">
    <b-container fluid class="consultant-details">
      <b-row>
        <b-col cols="12">
          <h5 class="name mgb20 tc">{{ company.spec }}</h5>
        </b-col>
        <b-col lg="6">
          <div
            class="wid100Per"
            v-if="
              company.company_type != 38 &&
              company.company_type != 39 &&
              company.company_type != 40 &&
              company.company_type != 41
            "
          >
            <div
              class="brand-img"
              v-if="company.cover_path == null || company.cover_path == ''"
            >
              <div
                class="flex-center ft15"
                style="background: #f2f2f2; height: 400px; color: #ccc"
              >
                {{ company.name }}
              </div>
            </div>
            <div class="brand-img">
              <div style="margin-bottom: 25px">
                <img  v-if="company.cover_path" :src="url + company.cover_path" width="100%" />
                <div class="img-caption" v-if="company.cover_path_cap">
                  {{ company.cover_path_cap }}
                </div>
              </div>

              <div class="other-img flex mgb38">
                <div class="img-div" v-if="company.photo_path">
                  <img :src="url + company.photo_path" width="100%" />
                  <div class="img-caption">{{ company.photo_path_cap }}</div>
                </div>

                <div class="img-div" v-if="company.photo_path2">
                  <img :src="url + company.photo_path2" width="100%" />
                  <div class="img-caption">{{ company.photo_path2_cap }}</div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col lg="6" class="tl">
          <div class="brand-info">
            <h1 class="ft25">{{ company.name }}</h1>
            <ul class="brand-cat ft12">
              <li>{{ company.spec }}</li>
            </ul>
            <div class="star-rate-adv flex">
              <el-rate
                v-model="averageRating"
                :icon-classes="['fa fa-star', 'fa fa-star', 'fa fa-star']"
                disabled
                disabled-void-color="#e3e3e3"
                disabled-void-icon-class="fa fa-star"
              ></el-rate>
              <div class="num-review">
                {{ averageRating }} / 5 &nbsp;•&nbsp; {{ reviews.length }}
                {{ $t("supplierfilter.rating") }}
              </div>
            </div>
            <div class="ft14">
              <br />
              <p style="white-space: pre-wrap" v-html="company.desc"></p>
              <br /><br />
              {{ $t("supplierfilter.score") }}：<br />
              <el-table v-if="ratingInfo" :data="ratingInfo" style="width: 50%">
                <el-table-column prop="year" :label="$t('supplierfilter.year')">
                </el-table-column>
                <el-table-column prop="min" :label="$t('supplierfilter.min')">
                </el-table-column>
                <el-table-column prop="avg" :label="$t('supplierfilter.avg')">
                </el-table-column>
                <el-table-column prop="max" :label="$t('supplierfilter.max')">
                </el-table-column>
              </el-table>
              <br />
              <p v-if="company.contact_lang">
                {{ $t("supplierfilter.contact_lang") }}:
                {{ company.contact_lang }}
              </p>
              <br />
              <br />
              <p v-if="company.company_size">
                {{ $t("supplierfilter.company_size") }}:
                <span>{{ company.company_size }}</span>
              </p>
              <p
                class="mgt20"
                style="white-space: pre-wrap"
                v-html="company.contact"
              ></p>
              <p class="mgt20" v-if="company.referral != null">
                {{ $t("supplierfilter.recommend_by") }}：{{ company.referral }}
              </p>
              <p class="mgt20">
                {{ $t("supplierfilter.ware_date") }}：{{
                  company.created_at | formatCreateTime
                }}
              </p>
              <br />

              <ul class="brand-links" style="position: relative">
                <li v-if="company.intro_path != null">
                  <a :href="url + company.intro_path" target="_blank"
                    ><span class="fa fa-download"></span>
                    {{ $t("supplierfilter.company_intro") }}</a
                  >
                </li>
                <li v-if="company.pricerate_path != null">
                  <a :href="url + company.pricerate_path" target="_blank"
                    ><span class="fa fa-download"></span>
                    {{ $t("supplierfilter.price_rate") }}</a
                  >
                </li>
              </ul>

              <br />
              <b-row v-if="companyInfos">
                <b-col
                  sm="12"
                  lg="12"
                  class="project-ref"
                  v-for="(item, index) in companyInfos"
                  :key="index"
                >
                  <b-row class="mb-4">
                    <b-col sm="12" lg="6">
                      <div class="ft12 line">
                        {{ item.infoType }}
                      </div>
                    </b-col>
                    <b-col sm="12" lg="6">
                      <div class="ft12 line ver-line">&nbsp;</div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <ul style="column-count: 2">
                      <li
                        v-for="(itemInfo, indexInfo) in item.infos"
                        :key="indexInfo"
                      >
                        {{ itemInfo['name_'+$i18n.locale] }}
                      </li>
                    </ul>
                  </b-row>
                </b-col>
              </b-row>
              <b-row v-if="projects" style="margin-top: 40px">
                <b-col sm="12" lg="12" class="project-ref">
                  <b-row class="mb-4">
                    <b-col sm="12" lg="6">
                      <div class="ft12 line">
                        {{ $t("supplierfilter.num_project_pre")
                        }}{{ projects.length
                        }}{{ $t("supplierfilter.num_project_beh") }}
                      </div>
                    </b-col>
                    <b-col sm="12" lg="6">
                      <div class="ft12 line ver-line">&nbsp;</div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <ul style="column-count: 2">
                      <li v-for="(item, index) in projects" :key="index">
                        {{ item.name }}
                      </li>
                    </ul>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col xs="12">
          <!-- Review & Rating -->
          <div class="user-review">
            <h4>{{ $t("supplierfilter.userreview") }}</h4>

            <div class="review-container">
              <div class="star-rate-adv">
                <el-rate
                  v-model="averageRating"
                  :icon-classes="['fa fa-star', 'fa fa-star', 'fa fa-star']"
                  disabled
                  disabled-void-color="#e3e3e3"
                  disabled-void-icon-class="fa fa-star"
                ></el-rate>
              </div>
              <div class="num-review">
                {{ averageRating }} / 5 &nbsp;•&nbsp; {{ reviews.length }}
                {{ $t("supplierfilter.rating") }}
              </div>
              <div class="review" v-for="(item, index) in reviews" :key="index">
                <div class="user tl">
                  {{ item.user_info }}<br />{{ item.team_info }}
                </div>

                <div class="review-details tl">
                  <b>{{ item.relevant_p_no }} {{ item.relevant_name }}</b>

                  <div class="star-rate mgt5 mgb20">
                    <el-rate
                      v-model="item.rating"
                      :icon-classes="['fa fa-star', 'fa fa-star', 'fa fa-star']"
                      disabled
                      disabled-void-color="#e3e3e3"
                      disabled-void-icon-class="fa fa-star"
                    ></el-rate>
                  </div>

                  <div class="comment mgb20">{{ item.comment }}</div>

                  <ul class="album-list mgb20" v-if="item.reviewFiles != ''">
                    <li v-for="album in item.reviewFiles" :key="album.id">
                      <a
                        :href="`${staticInfo.resPrefix}/${album.path}/s300`"
                        target="_blank"
                        v-if="album.path"
                      >
                        <div class="mood-img">
                          <img
                            class="b-lazy b-loaded"
                            :src="`${staticInfo.resPrefix}/${album.path}/s300`"
                          />
                        </div>
                      </a>
                    </li>
                  </ul>

                  <div class="date">{{ item.created_at }}</div>
                </div>
              </div>
              <router-link
                :to="{
                  path:
                    '/supplier-review?tid=' + company.id + '&review_type=create'
                }"
                class="review-btn btn"
                >{{ $t("supplierfilter.writeBtn") }}</router-link
              >
            </div>
          </div>

          <!-- END Review & Rating -->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { getCompaniesDetail } from "@/api/supply";
import { formatDate } from "@/utils/date";
export default {
  data() {
    return {
      value1: 3,
      detailId: null,
      detailData: [],
      loading: false,
      company: [],
      url: process.env.VUE_APP_IMG_URL,
      averageRating: null,
      ratingInfo: [],
      companyInfos: [],
      reviews: [],
      newRating: [],
      projects: [],
      staticInfo: {}
    };
  },
  filters: {
    formatCreateTime(time) {
      let date = new Date(time);
      return formatDate(date, "yyyy/MM/dd");
    }
  },
  created() {
    this.detailId = this.$route.query.id;
    this.getCompaniesDetail();
  },
  methods: {
    getCompaniesDetail() {
      this.loading = true;
      getCompaniesDetail(this.detailId).then((res) => {
        this.loading = false;
        let result = res.data;
        this.company = result.company;
        this.averageRating = result.averageRating;
        this.ratingInfo = result.ratingInfo;
        this.companyInfos = result.companyInfos;
        this.reviews = result.reviews;
        this.staticInfo = result.staticInfo;
        this.newRating = result.newRating;
        this.projects = result.projects;
        this.newRating.map((el) => {
          el.min = "-";
          el.max = "-";
          el.avg = el.average.toFixed(2);
          this.ratingInfo.push(el);
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  padding: 25px 0 40px;
  overflow-x: hidden;
  .name {
    padding: 20px 0px 20px;
  }
  .consultant-details {
    .brand-cat {
      margin: 3px 0px;
    }
    .star-rate-adv {
      font-size: 12px;
      color: #aaaaaa;
    }
    .user-review {
      border-top: 1px solid #dddddd;
      margin-top: 40px;
      padding-top: 40px;
      text-align: center;
      h4 {
        font-size: 18px;
      }
      .review-container {
        width: 100%;
        /* max-width: 1100px; */
        margin: 20px auto 50px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 13px;
        .num-review {
          color: #aaaaaa;
          margin-bottom: 60px;
        }
        .review {
          margin-bottom: 85px;
          display: flex;
          .user {
            width: 180px;
          }
          .review-details {
            flex: 1;
            .album-list {
              font-size: 0;
              width: 100%;
              li {
                z-index: 0;
                display: inline-block;
                width: 7.3%;
                text-align: center;
                font-size: 15px;
                margin: 0px 1% 5px 0;
                padding: 1px;
                vertical-align: top;
                position: relative;
                /*background: #EEE;
                border: 1px solid #E0E0E0;*/
                a {
                  position: relative;
                  display: block;
                  text-align: center;
                  width: 100%;
                  padding: 0px;
                  box-sizing: border-box;
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  overflow: hidden;
                  &::before {
                    margin: 0 auto;
                    color: #d3d3d3;
                    font-size: 40px;
                    content: "";
                    display: block;
                    padding-top: 100%;
                  }
                  .mood-img {
                    position: absolute;
                    display: flex;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    align-items: center;
                    img {
                      display: block;
                      max-width: 100%;
                      max-height: 100%;
                      margin: auto;
                      width: auto;
                      height: auto;
                      transition: all 0.3s ease-in-out;
                      -webkit-transition: all 0.3s ease-in-out; /** Chrome & Safari **/
                      -moz-transition: all 0.3s ease-in-out; /** Firefox **/
                      -o-transition: all 0.3s ease-in-out; /** Opera **/
                    }
                  }
                }
              }
            }
          }
        }
        .review-btn {
          font-size: 13px;
          font-weight: normal;
          color: inherit;
          background: #f3f3f3;
          padding: 7px 50px 6px;
          border: #ccc 1px solid;
          &:hover {
            color: #950c0f;
          }
        }
      }
    }
    .brand-img {
      position: relative;
      min-height: 1px;
      padding-right: 20px;
      padding-left: 15px;
      width: 100%;
      .img-caption {
        /* text-align: right; */
        font-size: 12px;
        padding: 5px 0px 0px;
        line-height: 15px;
      }

      @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 25px;
        padding-right: 15px;
        padding: 0;
        .img-caption {
          text-align: center;
        }
      }
      .img-div {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &:first-child {
          padding-right: 10px;
        }
        &:last-child {
          padding-left: 10px;
        }
      }
    }
    .brand-links {
      li {
        margin-top: 3px;
        font-size: 12px;
      }
    }
    .project-ref {
      overflow: hidden;
      .line {
        color: #999999;
        border-bottom: 1px solid #ddd;
        padding: 0 0 10px 0;
      }
      .ver-line {
        @media screen and (max-width: 1199px) {
          display: none;
        }
      }
      ul {
        //margin: 10px 0 0 1.2em;
        margin: 10px 0 0 0;
        li {
          padding-left: 0px;
          padding-right: 3em;
          margin-bottom: 10px;
          margin-right: -0.4em;
          margin-left: 1.1em;
          break-inside: avoid-column;
          list-style: disc;
          &::marker {
            font-size: 14px;
          }
        }
      }
    }
  }
}
.brand-info /deep/ .el-rate__icon {
  margin-right: 3px;
  font-size: 16px;
}
</style>
