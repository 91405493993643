
<template>
  <div class="content" v-loading="loading">
    <b-container fluid >
      <b-row  class="drs">
        <b-col cols="12">
          <h5 class="name mgb20 tc">
					<router-link :to="{path:'/supplier-cats?name=' + encodeURIComponent(cattitle) }"  class="ft12 item"  v-if="catid!=null">{{cattitle}} / </router-link> {{catName}}
          </h5>
        </b-col>
        <b-col cols="12">
          <div class="drs-list ">
            <ul >
              <li class="thumb" v-for="(item,index) in resultList" :key="index" @click="goPage(catid,item.id)">
                <div class="img-box flex">
                    <img :src="url + 'misc/tiles/cat-img-28.jpg'" v-if="item.id == 2">

										<img :src="url + 'misc/tiles/decorative-lightings.jpg'" v-else-if="item.id==3">

										<img :src="url + 'misc/tiles/designres-design-guide-carpet.jpg'" v-else-if="item.id==4">

										<img :src="url + 'misc/tiles/soft-furnishings.jpg'" v-else-if="item.id==5">

										<img :src="url + 'misc/tiles/decorative-accessories.jpg'" v-else-if="item.id==6">

										<img :src="url + 'misc/tiles/curtain.jpg'" v-else-if="item.id==342">

										<img :src="url + 'misc/tiles/upholstery.jpg'" v-else-if="item.id==362">

										<img :src="url + 'misc/tiles/artworks.jpg'" v-else-if="item.id==393">

										<img :src="url + 'misc/tiles/recreational-products.jpg'" v-else-if="item.id==413">

										<img :src="url + 'misc/tiles/designres-tm002.jpg'" v-else>
                </div>
                <span>{{item.name}}</span>
              </li>
              <li class="thumb forJustify"></li>
              <li class="thumb forJustify"></li>
              <li class="thumb forJustify"></li>
              <li class="thumb forJustify"></li>
              <li class="thumb forJustify"></li>
            </ul>
          </div>
        </b-col>

      </b-row>
    </b-container>
  </div>
</template>

<script>
import { getProductcat,supplierCatFilters } from "@/api/supply";
export default {
  data(){
    return{
      catid:null,
      catName:'',
      cattitle:'',
      resultList:[],
      loading:false,
      url: process.env.VUE_APP_IMG_URL
    }
  },
  created(){
    this.catid =this.$route.query.id ? this.$route.query.id:null,
    this.cattitle = this.$route.query.name ? this.$route.query.name : '';
    this.getSupplierCats();
    this.getCatname();

  },
  watch:{
    '$route' (to,from) {
      this.catid =this.$route.query.id ? this.$route.query.id:null,
      this.cattitle = this.$route.query.name ? this.$route.query.name : '';
      this.getSupplierCats();
      this.getCatname();
    }

  },
  methods:{
    getSupplierCats(){
      this.loading = true;
      getProductcat(this.catid).then(res=>{
        this.loading = false;
        this.resultList = res.data;
      })
    },
    goPage(catid,id){
      this.$router.push(`/supplier-cats?catId=${catid}&id=${id}&name=${encodeURIComponent(this.cattitle)}`)
    },

    getCatname(){
      this.loading = true;
      supplierCatFilters(this.catid).then(res=>{
        this.loading = false;
        this.catName =res.data.name;

      })
    }

  }
}
</script>

<style lang="scss" scoped>
.content{
  padding: 25px 0 40px;
  overflow-x: hidden;
  .name{
    padding: 20px 0px 20px;
    .item{
      color: #999;
      &:hover{
        color: #950c0f;
      }
    }
  }
  .drs{
    max-width: 1530px;
    margin: 0 auto;
    text-align: left;
    .col-12 {
      padding: 0;
    }
    @media (max-width: 1559px) {
      .drs-list {
        text-align: justify;
        .thumb {
          padding: 7.5px;
        }
      }
    }

    @media (max-width: 1023px) {
      /*margin: 0 -15px;*/
      .drs-list {
        .thumb {
          width: 33.33%;
          span{
            padding: 15px 0 8px;
          }
        }
      }
    }
    @media (max-width: 767px) {
      /*margin: 0 -15px;*/
      .drs-list {
        width: auto;
        margin-bottom: 50px;
        .thumb {
          margin: 0 0 20px 0;
        }
      }

    }
    @media (max-width: 567px) {
      .drs-list {
        .thumb {
          width: 50%;
          font-size: 13px;
          span{
            font-size: 15px;
          }
        }
      }
    }
    @media (max-width: 375px) {
      .drs-list {
        .thumb {
          span{
            font-size: 13px;
          }
        }
      }
    }
  }
  .reders{
    max-width: 1530px;
    margin: 0 auto;
    text-align: left;
    @media (max-width: 1023px) {
      margin: 0 -15px;
      text-align: center;
    }
  }

}
.drs-list{

  margin: 0 auto;
  /* max-width: 1100px; */
  width: 100%;
  padding: 0;
  text-align: center;
  font-size: 0;
  margin-bottom: 50px;
  > ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .thumb{
    z-index: 0;
    display: inline-block;
    width: 20%;
    text-align: left;
    font-size: 15px;
    margin: 0px 0px 1% 0;
    padding: 15px;
    vertical-align: top;
    cursor: pointer;
    .img-box{
      position: relative;
      overflow: hidden;
      img{
        width: 100%;
        height: auto;
        transition: transform 1s;
        transition-timing-function: ease;
      }
    }
    span{
      display: block;
      font-size: 18px;
      padding: 20px 0 10px;
      text-align: center;
      letter-spacing: 1px;
      color: #555555;
      position: relative;
    }
    &:hover{
      img{
        transform: scale(1.1);
      }
    }
  }
  .forJustify{
    border: none;
    background: none;
    height: 1px;
    padding: 0px;
    /* margin: 0 2% 0 0; */

  }
}
.renderer-list{
  /* max-width: 1100px; */
  padding: 0;
  /* text-align: center; */
  text-align: justify;
  font-size: 0;
  margin: 0px 0 60px;
  vertical-align: top;
  ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li{
      box-sizing:border-box;
      font-size: 14px;
      background-color:#ffffff;
      /*border: 1px solid #DDDDDD;*/
      /*border-radius: 3px;
      box-shadow: 1px 3px 1px rgba(0, 0, 0, 0.08);*/
      /*max-width:250px;*/
      margin: 0px 0px 50px;
      padding: 15px 15px 35px;
      width: 19%;
      text-align: center;
      vertical-align: top;
      display: inline-block;
      position: relative;
      @media (max-width: 1023px) {
        width: 33%;
        padding: 0 2% 35px;
      }
      @media (max-width: 767px) {
        width: 49%;
        padding: 0 2% 35px;
        margin: 0 0 30px;
      }
      .render-top{
        position: relative;
        padding: 0px;
        display: block;
        color: #555555;
        .text{
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          vertical-align: middle;
          color: #CCC;
          padding: 10px;
        }
        .img-box{
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          img{
            display: block;
            min-width: 100%;
            min-height: 100%;
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            margin: auto 0;
            object-fit: cover;
          }
        }

      }
      .render-title{
          display: block;
          font-size: 15px;
          padding: 20px 0 2px;
          text-align: center;
          letter-spacing: 1px;
          color: #555555;
        }
        .expertise{
          margin: 0 0 15px;
          >div{
            font-size: 14px;
            color: #999999;
          }
        }
        .rating{
          display: flex;
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 100%;
          padding: 0px 15px;
          color: #999999;
          font-size: 12px;
          .rate{
            flex:1;
            &:last-child{
              border-left: 1px solid #EAEAEA;
            }
          }
        }
    }
  }
}
</style>
