
<template>
  <div class="content" v-loading="loading">
    <b-container fluid>
      <b-row class="drs">
        <b-col md="12">
          <div class="name mgb20 tc">
            <div v-if="pageType == 'brands'">
               {{$t('approved.approved_brands')}}
            </div>
            <div v-else>
              <span v-if="loc == 'CN'">{{$t('Strateg.approved_china_distributors')}}</span>
              <span v-else>{{$t('Strateg.approved_hk_distributors')}}</span>
            </div>
            <div class="coCat tc mgt10">
              <el-select popper-append-to-body v-model="cat" :style="{width:selectOptionWidth}" @change="getchangevalue">
                <el-option
                  v-for="item in productCat"
                  :key="item.id"
                  @click.native="setOptionWidth"
                  :label="item.name"
                  :value="item.id">
                  <span style="float: left">{{ item.name }}</span>
                </el-option>
              </el-select>
            </div>

          </div>
          
        </b-col>
        <b-col md="12" v-if="brand!='' && pageType == 'brands'">
            <brand-list :brand="brand"></brand-list>
        </b-col>

        <b-col md="12"  v-if="pageType == 'distributors'">
            <distributor-list :distributor-data="distributorData" v-if="distributorData!=''"></distributor-list>
            <div style="color: gainsboro; line-height:22px;" class="ft18 mgb50"  v-else>  
              {{$t('common.result_none')}}
            </div>
        </b-col>
        
        
    
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { getproductcats,getBrand ,getVendor} from "@/api/brand";
import brandList from '@/components/Cooperative/BrandList'
import distributorsList from '@/components/Cooperative/DistributorsList'
export default {
  components:{
    'brand-list': brandList,
    'distributor-list': distributorsList
  },
  data() {
    return {
      selectOptionWidth:'145px',
      loading:false,
      value:null,
      productCat:[],
      brand:[],
      stratype:2,  // strategic：1 or  
      cat:null,
      imgurl:process.env.VUE_APP_IMG_URL,
      pageType:'brands',  //brands or distributors
      loc:'',   //distributors
      distributorData:[],
      ventorType:5

    };
  },
  created() {
    this.cat = this.$route.query.cat ? Number(this.$route.query.cat) : null
    this.pageType = decodeURIComponent(this.$route.query.type) ;
    this.getProductcats();
    if(this.$route.query.type == 'brands') {
       this.getBrands();
    }else{
      this.loc = this.$route.query.loc == undefined ? '' : this.$route.query.loc;
      this.getVendor()
    }
  },
  watch:{
     
      '$route' () {
        this.cat = this.$route.query.cat ? Number(this.$route.query.cat) : null
        this.pageType = decodeURIComponent(this.$route.query.type) ;
        this.getProductcats();
        if(this.$route.query.type == 'brands') {
          this.getBrands();
        }else{
          this.loc = this.$route.query.loc == undefined ? '' : this.$route.query.loc;
          this.getVendor()
        }
      }  
  },
  methods: {
    setOptionWidth(event){
      if (this.cat) {
        this.$router.push({
          path: '/approved/brand',
          query: { type: this.pageType, cat: this.cat },
        })
      } else {
        this.$router.push({
          path: '/approved/brand',
          query: { type: this.pageType },
        })
      }
      this.$nextTick(() => {
        if(event.target.localName == 'span'){
          this.nodeIndex = event.target.clientWidth + 40
        }else{
          this.nodeIndex = event.target.childNodes[0].clientWidth + 40
        }
        this.selectOptionWidth = this.nodeIndex + "px";
      });
    },
    getProductcats() {
      let select = this.$t('Strateg.product_cat');
      getproductcats().then(res => {
        let result = res.data;
        this.productCat =select.concat(result);
      });
    },
    getBrands(){
      this.loading = true;
      getBrand(this.stratype,this.cat).then(res=>{
        this.loading = false;
        this.brand = res.data;
      })
    },
    getVendor(){
      this.loading = true;
      let params={
        cat:this.cat==null?'':this.cat,
        loc:this.loc
      }
      getVendor(this.ventorType,params).then(res=>{
        this.loading = false;
        this.distributorData = res.data;
      })
    },
    getchangevalue(e){
        // this.cat = e;
         if(this.$route.query.type == 'brands') {
            this.getBrands();
          }else{
            this.getVendor()
          }
    },
    
  }
};
</script>

<style lang="scss" scoped>
.content {
  padding: 25px 0 40px;
  overflow-x: hidden;
  .name {
    padding: 20px 0px 0px;
    font-size: 21px;
  }
  .brands{
    h3{
      font-size: 17px;
      font-weight: normal;
      margin: 0 0 50px;
    }
    .brand-list{
      margin: 0 auto;
      max-width: 1560px;
      width: 100%;
      padding: 0;
      text-align: center;
      text-align: justify;
      font-size: 0;
      margin-bottom: 50px;
      li{
        display: inline-block;
        box-sizing: border-box;
        text-align: center;
        font: bold 18px sans-serif;
        background-color: #ffffff;
        /* border: 1px solid #DDDDDD; */
        /* max-width: 250px; */
        width: 12.5%;
        margin: 0 2% 50px;
        /* padding: 18px; */
        vertical-align: top;
        position: relative;
        @media (max-width: 1500px){
          width: 15.2%;
        }
        @media (max-width: 1100px) {
          width: 20%;
        }
        @media (max-width: 1023px) {
          width: 26%;
        }
        @media (max-width: 767px) {
          width: auto;
          margin-bottom: 50px;
          li{
            // border: 1px solid #FFFFFF;
            width: 50%;
            margin: 0 0 15px 0;
          }
        }
        .brand-photo{
          position: relative;
          display: block;
          text-align: center;
          /* box-shadow: 0 0 10px 5px #f3f3f3 inset; */
          width: 100%;
          margin-bottom: 12px;
          /* padding: 10px 10px; */
          padding: 0px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          cursor: pointer;
          &::before{
            content: "";
            display: block;
            padding-top: 30%;
          }
          .brand-photo-con{
            position: absolute;
            display: flex;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            align-items: center;
            img{
              display: block;
              max-width: 100%;
              max-height: 100%;
              margin: auto;
              width: auto;
              height: auto;
            }
          }
        }
        .brand-info{
          margin: 0;
          font-size: 18px;
          line-height: 22px;
          max-width: 100%;
          
          h2{
            margin: 0;
            font-size: 18px;
            line-height: 22px;
            max-width: 100%;
            cursor: pointer;
          }
          .brand-cat{
            margin-top: 6px;
            li{
              width: auto;
              font-size: 12px;
              font-weight: normal;
              color: #999999;
              border-right: 1px solid #CCCCCC;
              padding-right: 10px;
              line-height: 12px;
              margin: 0 5px 5px 0px;
              &:last-child{
                border: none;
                margin-right: 0px;
                padding-right: 0px;
              }
              span{
                color: #999;
              }
            }
          }
        }
      }
    }
    .distributors{
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      margin: 0 auto;
      /* max-width: 1560px; */
      max-width: 1500px;
      width: 100%;
      padding: 0;
      text-align: center;
      text-align: justify;
      font-size: 0;
      margin-bottom: 50px;
      border-top: 1px solid #EAEAEA;
      h6{
        font-size: 16px;
        line-height: 22px;
        /* color: #AAAAAA; */
        margin: 0px 0px 25px 0px;
        padding-top: 80px;
        width: 25%;
        width: 16.66%;
        width: 20%;
        text-align: center;
        
        text-align: left;
      }
      .brand-list{
        width: 75%;
        width: 83.34%;
        width: 75.1%;
        margin: 0px 0px 20px 0px;
        padding: 50px 0 0 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
  
  
}
/deep/ .el-input__inner{
  border: none;
  font-size: 13px;
  color: #555;
  text-align: center;
}
</style>
