var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.dialogVisible)?_c('el-dialog',{class:_vm.inputmode == 0 ? 'detail-dialog' : 'detail-dialog catalog-dialog',attrs:{"visible":_vm.dialogVisible,"top":"0","destroy-on-close":""},on:{"update:visible":function($event){_vm.dialogVisible=$event},"close":_vm.diaClose}},[_c('el-main',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"dislogContent posiR",staticStyle:{"padding-top":"0","min-height":"100%"}},[(_vm.detailData != '')?_c('div',[_c('b-breadcrumb',{staticClass:"ft11"},[_c('b-breadcrumb-item',[_vm._v(_vm._s(_vm.detailData.cat.name))]),_c('b-breadcrumb-item',[_vm._v(_vm._s(_vm.detailData.subCat.name))])],1),(_vm.inputmode == 0)?[_c('b-row',{staticClass:"pdp_brief"},[_c('div',{staticClass:"pdp_thumbnail"},[_c('ul',_vm._l((_vm.itemPhotos),function(item,index){return _c('li',{key:index,on:{"click":function($event){return _vm.changeSlide(index)}}},[_c('img',{attrs:{"src":_vm.url + item.path}})])}),0)]),_c('div',{staticClass:"gallery"},[_c('swiper',{ref:"mySwiper",staticClass:"pdp_zoom",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.itemPhotos),function(item,index){return _c('swiper-slide',{key:index,staticClass:"slide"},[_c('div',{staticClass:"swiper-zoom-container"},[_c('img',{attrs:{"src":_vm.url + item.path}})])])}),1),_c('div',{staticClass:"swiper-pagination-product paginations",attrs:{"slot":"pagination"},slot:"pagination"})],1),_c('div',{staticClass:"pdp_details"},[_c('detail-info',{attrs:{"price-loading":_vm.priceLoading,"detail-data":_vm.detailData,"inputmode":_vm.inputmode,"type":1,"dialog-visible":_vm.dialogVisible,"subopt":_vm.subopt},on:{"submitData":_vm.getsubmitData,"change-color":_vm.changecolorEvent,"showsend1Dialog":_vm.showsend1Dialog,"upload-dialog":_vm.showuploadDialog,"changeMaterial":_vm.changeMaterial,"checkBoxEvent":_vm.checkBoxEvent}})],1)]),(
              _vm.detailData.product.len ||
              _vm.detailData.product.wd_dia ||
              _vm.detailData.product.hgt ||
              _vm.detailData.product[_vm.detailData.descName] ||
              _vm.detailData.product[_vm.detailData.subOptDescName] ||
              _vm.detailData.product[_vm.detailData.remarkName]
            )?_c('div',{staticClass:"pdp_description mgt20 pdt40"},[(
                _vm.detailData.product.len ||
                _vm.detailData.product.wd_dia ||
                _vm.detailData.product.hgt ||
                _vm.detailData.product[_vm.detailData.descName] ||
                _vm.detailData.product[_vm.detailData.subOptDescName]
              )?_c('h4',[_vm._v(" "+_vm._s(_vm.$t("productfilter.description"))+" ")]):_vm._e(),_c('div',{staticClass:"mgt30"},[(
                  _vm.detailData.product.len ||
                  _vm.detailData.product.wd_dia ||
                  _vm.detailData.product.hgt
                )?_c('span',{domProps:{"innerHTML":_vm._s(_vm.subopt.selectedSubOptSizeStr)}}):_vm._e(),(_vm.detailData.product[_vm.detailData.descName])?_c('p',{domProps:{"innerHTML":_vm._s(
                  _vm.detailData.product[_vm.detailData.descName].replace(
                    /\r\n/g,
                    '<br/>'
                  )
                )}}):_vm._e(),_c('p',[_vm._v(" "+_vm._s(_vm.detailData.product[_vm.detailData.subOptDescName])+" ")]),_c('br')]),(_vm.detailData.product[_vm.detailData.remarkName])?[_c('h4',[_vm._v(_vm._s(_vm.$t("productfilter.remark")))]),_c('div',{staticClass:"info-container",domProps:{"innerHTML":_vm._s(
                  _vm.detailData.product[_vm.detailData.remarkName].replace(
                    /\r\n/g,
                    '<br/>'
                  )
                )}})]:_vm._e(),(_vm.detailData.certInfo.length > 0)?_c('div',{staticClass:"cert-icons mgt50"},[_c('ul',_vm._l((_vm.detailData.certInfo),function(item,index){return _c('li',{key:index,on:{"click":function($event){return _vm.showCertInfo(item)}}},[(item.path)?_c('img',{attrs:{"src":_vm.url + item.path}}):_c('em',[_vm._v(" "+_vm._s(item.name_tc))])])}),0)]):_vm._e()],2):_vm._e()]:[_c('b-row',{staticClass:"pdp_brief catalog_view"},[_c('div',{staticClass:"pdp_thumbnail"},[_c('ul',_vm._l((_vm.itemPhotos),function(item,index){return _c('li',{key:index,on:{"click":function($event){return _vm.changeSlide(index)}}},[(item.path)?_c('img',{attrs:{"src":_vm.url + item.path}}):_vm._e()])}),0)]),_c('div',{staticClass:"catalog"},[_c('div',{staticClass:"catalog_preview",staticStyle:{"flex":"auto","position":"relative"}},[(_vm.detailData.product.file_catalog)?_c('div',{staticClass:"iframe-div"},[_c('PDFViewer',{attrs:{"pdf-url":_vm.url + _vm.detailData.product.file_catalog}})],1):_vm._e()])]),_c('div',{staticClass:"catalog_details"},[_c('detail-info',{attrs:{"price-loading":_vm.priceLoading,"detail-data":_vm.detailData,"hl-status":_vm.hlStatus,"type":1,"inputmode":_vm.inputmode,"subopt":_vm.subopt,"dialog-visible":_vm.dialogVisible},on:{"submitData":_vm.getsubmitData,"change-color":_vm.changecolorEvent,"showsend1Dialog":_vm.showsend1Dialog,"upload-dialog":_vm.showuploadDialog,"changeMaterial":_vm.changeMaterial,"checkBoxEvent":_vm.checkBoxEvent}})],1)])]],2):_vm._e()])],1):_vm._e(),_c('Upload',{ref:"upload",attrs:{"upload-dialog":_vm.uploadDialog},on:{"uploadChange":_vm.closeUploadChange}}),(_vm.detailData != '')?_c('confirm-message',{attrs:{"detail-data":_vm.detailData,"is-visble":_vm.sendDialog}}):_vm._e(),(_vm.detailData != '')?_c('send-message',{attrs:{"detail-data":_vm.detailData,"submit-data":_vm.submitData,"is-visble":_vm.send1Dialog},on:{"dialogChange":_vm.closedialogChange}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }