<template>
  <div>
      <div class="alert  added-to-cart-msg" :class="alertType=='success' ? 'alert-success':'alert-danger'" >
        <template v-if="alertType=='success'">
            <div  v-if="addType == 'product'">
                <button type="button" class="close fa fa-remove" @click="alertClose"></button>
                <strong>{{ $t('welcome.added_success') }}</strong>
                <br>
                {{ $t('welcome.added_to_cart') }}
                <br>
            </div>
            <div v-else>
                <button type="button" class="close fa fa-remove"  @click="alertClose"></button>
                <strong>{{ $t('welcome.send_email') }}</strong>
                <br>
            </div>
        </template>
        <template v-else>
            <div  v-if="addType == 'product'">
                <button type="button" class="close fa fa-remove"  @click="alertClose"></button>
                <strong>{{ $t('welcome.added_fail') }}</strong>
                <br>
                {{ $t('welcome.fail_to_add_product') }}
                <br>
            </div>
            <div v-else>
                <button type="button" class="close fa fa-remove"  @click="alertClose"></button>
                <strong>{{ $t('welcome.send_email_fail') }}</strong>
                <br>
            </div>

        </template>
       
    </div>

  </div>
</template>

<script>

export default {
    data(){
        return{
            isAlert:false
        }
    },
    props:{
        alertType:{
            type:String,
            default:'success'
        },
    
        addType:{
            type:String,
            default:'product'
        }
    },

    watched:{
       
    },
    methods:{
        alertClose() {
          this.$emit("alertChange", false);
        },
    }
}
</script>

<style scoped>
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.added-to-cart-msg {
    /* display: none; */
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 9999;
    left: 0;
    right: 0;
    margin: auto auto;
    width: 360px;
    height: 120px !important;
    min-height: 80px;
    color: #000;
    text-align: center;
    font-family: sans-serif;
    opacity: 0.95 !important;
    filter: alpha(opacity=95) !important;
}

.alert-dismissable,
.alert-dismissible {
  padding-right: 35px;
}
.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}
.alert-success {
  color: #555;
  font-size: 14px;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
.alert-success hr {
  border-top-color: #c9e2b3;
}
.alert-success .alert-link {
  color: #2b542c;
}
.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}
.alert-info hr {
  border-top-color: #a6e1ec;
}
.alert-info .alert-link {
  color: #245269;
}
.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.alert-warning hr {
  border-top-color: #f7e1b5;
}
.alert-warning .alert-link {
  color: #66512c;
}
.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
.alert-danger hr {
  border-top-color: #e4b9c0;
}
.alert-danger .alert-link {
  color: #843534;
}
.close {
    height: 40px;
    width: 40px;
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
    filter: alpha(opacity=20);
    opacity: .2;
    text-align: center;
    font-size: 14px;
    line-height: 34px;
    z-index: 10;
}
</style>