import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './lang'
import VueLazyLoad from 'vue-lazyload'

import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import "./assets/style/scss/reset.scss";
import "./assets/style/scss/common.scss";
import "./assets/style/styl/flex.styl";
import "./assets/style/styl/conditionals.styl";

import "./@font/css/fontello.css";
import 'font-awesome/css/font-awesome.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI)
Vue.use(BootstrapVue)
Vue.use(VueLazyLoad, {
  preLoad: 1,
  // error: require('./assets/imgError.png'), // 错误图片
  // loading: require('./assets/imgLoading.png'), // 加载中图片
  attempt: 2,
})

import * as filters from './filter'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
});


Vue.config.productionTip = false


new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
