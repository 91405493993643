
<template>
  <div class="content">
    <b-container fluid >
          <b-row class="drs" >
            <b-col md="12" class="" >
              <h5 class="name mgb20 tc ft21">
                {{$t('Promotions.promotions')}}
              </h5>
              <ul class="drs-list center2">

                    <li class="thumb"  @click="goPage('/promotions/1')">
                        <div class="img-box flex">
                            <img :src="url + 'misc/tiles/promotion-steveleung.jpg'">
                        </div>
                        <span>{{$t('Promotions.steve')}}</span>
                    </li>
                    <li class="thumb"  @click="goPage('/promotions/2')">
                        <div class="img-box flex">
                            <img :src="url + 'misc/tiles/promotion-marcoszeto.jpg'">
                        </div>
                        <span>{{$t('Promotions.marco')}}</span>
                    </li>
                    <li class="thumb"  @click="goPage('/privateresidence')">
                        <div class="img-box flex">
                            <img :src="url + 'misc/tiles/promotion-private-residence.jpg'">
                        </div>
                        <span>{{$t('Promotions.type_private_house')}}</span>
                    </li>
                </ul>


            </b-col>
          </b-row>

    </b-container>
    <!-- <lang-box></lang-box> -->
  </div>
</template>

<script>
// import LangBox from '@/components/CheckLang.vue'
export default {
  // components: {
  //   LangBox
  // },
  data(){
    return{
      url: process.env.VUE_APP_IMG_URL
    }
  },
  created(){


  },

  methods:{
    goPage(url){
      this.$router.push(url)
    },
  }
}
</script>

<style lang="scss" scoped>
.content{
  padding: 25px 0 40px;
  overflow-x: hidden;
  .name{
    padding: 20px 0px 20px;
    color: #333;
    .item{
      color: #999;
      &:hover{
        color: #950c0f;
      }
    }
  }
  .drs{
    max-width: 1530px;
    margin: 0 auto;
    text-align: left;
    .col-md-12 {
      padding: 0;
    }
    @media (max-width: 1559px) {
      .drs-list {
        text-align: justify;
        .thumb {
          padding: 7.5px;
        }
      }
      .center2{
          text-align: center !important;
      }
    }

    @media (max-width: 1023px) {
      /*margin: 0 -15px;*/
      .drs-list {
        .thumb {
          width: 33.33%;
          span{
            padding: 15px 0 8px;
          }
        }
      }
    }
    @media (max-width: 767px) {
      /*margin: 0 -15px;*/
      .drs-list {
        width: auto;
        margin-bottom: 50px;
        .thumb {
          margin: 0 0 20px 0;
        }
      }

    }
    @media (max-width: 567px) {
      .drs-list {
        .thumb {
          width: 50%;
          font-size: 13px;
          span{
            font-size: 15px;
          }
        }
      }
    }
    @media (max-width: 375px) {
      .drs-list {
        .thumb {
          span{
            font-size: 13px;
          }
        }
      }
    }
  }


}
.drs-list{

  margin: 0 auto;
  /* max-width: 1100px; */
  width: 100%;
  padding: 0;
  text-align: center;
  font-size: 0;
  margin-bottom: 50px;
//   > ul{
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: space-between;
//   }
  .thumb{
    z-index: 0;
    display: inline-block;
    width: 20%;
    text-align: left;
    font-size: 15px;
    margin: 0px 0px 1% 0;
    padding: 15px;
    vertical-align: top;
    .img-box{
      position: relative;
      overflow: hidden;
      img{
        width: 100%;
        height: auto;
        transition: transform 1s;
        transition-timing-function: ease;
      }
    }
    span{
      display: block;
      font-size: 18px;
      padding: 20px 0 10px;
      text-align: center;
      letter-spacing: 1px;
      color: #555555;
      position: relative;
    }
    &:hover{
      img{
        transform: scale(1.1);
      }
    }
  }
  .forJustify{
    border: none;
    background: none;
    height: 1px;
    padding: 0px;
    /* margin: 0 2% 0 0; */

  }
}

</style>
