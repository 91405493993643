<template>
  <div>
    <div class="deliverable-list" v-if="deliverable.length > 0">
      <ul :class="['flex-between', 'flex-wrap']">
        <li
          class="thumb"
          v-for="(item, index) in deliverable"
          :key="index"
        >
          <a :href="'#/deliverable-detail?id='+item.id">
            <div class="thumb-box">
              <div class="thumb-text">{{ item.name }}</div>
              <div class="thumb-img">
                <img
                  class="img"
                  v-lazy="
                    imgurl + resultData.areaImgList[item.id][0].path + '/s500'
                  "
                />
              </div>
            </div>

            <div class="mgt20">
              <span v-html="item.name"></span>
            </div>
          </a>

          <div class="specs">
            <div class="tr">
              <div>{{ $t("welcome.project_code") }}:</div>
              <div class="codes">
                <a :href="'#/deliverable-detail?id='+item.id+'&relateId='+item.id">
                  <span
                    v-html="item.project_no"
                  >
                  </span>
                </a>


                  <span
                    v-for="(relate, inx) in relatedProjects[item.id]"
                    :key="inx"
                  >
                    <span v-html="` / `"></span>
                    <a :href="'#/deliverable-detail?id='+item.id+'&relateId='+relate.id">
                      <span v-html="`${relate.project_no}`"></span>
                    </a>
                  </span>

              </div>
            </div>
            <div class="tr">
              <div>{{ $t("welcome.project_location") }}:</div>
              <div v-html="item.location"></div>
            </div>
            <div class="tr">
              <div>{{ $t("welcome.project_type") }}:</div>

              <div
                v-for="(type, key) in opt_type"
                :key="key"
                v-show="item.opt_type_ids == key"
              >
                <span v-if="item.opt_type_ids == key" v-html="type"> </span>
              </div>
            </div>
            <div class="tr">
              <div>{{ $t("welcome.project_client") }}:</div>
              <div v-html="item.developer"></div>
            </div>
            <div class="tr">
              <div>{{ $t("welcome.project_cost_fitting_out") }}:</div>
              <div
                v-if="
                  resultData.fitoutFinCostMin[item.id] &&
                  resultData.fitoutFinCostMin[item.id] > 0 &&
                  resultData.fitoutFinCostMax[item.id]
                "
              >
                <span
                  v-if="
                    resultData.fitoutFinCostMin[item.id] ==
                    resultData.fitoutFinCostMax[item.id]
                  "
                >
                  {{ item.currency_symbol
                  }}{{
                    resultData.fitoutFinCostMin[item.id] | toThousandFilter
                  }}
                  - {{ $t("welcome.project_rmb_per_sqm") }}
                </span>
                <span v-else>
                  {{ item.currency_symbol
                  }}{{
                    resultData.fitoutFinCostMin[item.id] | toThousandFilter
                  }}
                  - {{ item.currency_symbol
                  }}{{ resultData.fitoutFinCostMax[item.id] | toThousandFilter
                  }}{{ $t("welcome.project_rmb_per_sqm") }}
                </span>
              </div>
              <div
                v-else-if="
                  resultData.fitoutBudCostMin[item.id] &&
                  resultData.fitoutBudCostMin[item.id] > 0 &&
                  resultData.fitoutBudCostMax[item.id]
                "
              >
                <span
                  v-if="
                    resultData.fitoutBudCostMin[item.id] ==
                    resultData.fitoutBudCostMax[item.id]
                  "
                >
                  {{ item.currency_symbol
                  }}{{
                    resultData.fitoutBudCostMin[item.id] | toThousandFilter
                  }}
                  - {{ item.currency_symbol
                  }}{{
                    resultData.fitoutBudCostMax[item.id] | toThousandFilter
                  }}
                  {{ $t("welcome.project_rmb_per_sqm") }}
                </span>
                <span v-else>
                  {{ item.currency_symbol
                  }}{{
                    resultData.fitoutBudCostMin[item.id] | toThousandFilter
                  }}
                  - {{ item.currency_symbol
                  }}{{
                    resultData.fitoutBudCostMax[item.id] | toThousandFilter
                  }}/{{ $t("welcome.project_rmb_per_sqm") }}
                </span>
              </div>
              <div v-else>-</div>
            </div>
            <div class="tr">
              <div>{{ $t("welcome.project_cost_ffne") }}:</div>

              <div
                v-if="
                  resultData.ffeFinCostMin[item.id] &&
                  resultData.ffeFinCostMin[item.id] > 0 &&
                  resultData.ffeFinCostMax[item.id]
                "
              >
                <span
                  v-if="
                    resultData.ffeFinCostMin[item.id] ==
                    resultData.ffeFinCostMax[item.id]
                  "
                >
                  {{ item.currency_symbol
                  }}{{ resultData.ffeFinCostMin[item.id] | toThousandFilter }}
                  {{ item.currency }}/{{ $t("welcome.project_rmb_per_sqm") }}
                </span>
                <span v-else>
                  {{ item.currency_symbol
                  }}{{ resultData.ffeFinCostMin[item.id] | toThousandFilter }}-
                  {{ item.currency_symbol
                  }}{{ resultData.ffeFinCostMax[item.id] | toThousandFilter
                  }}{{ item.currency }}/{{ $t("welcome.project_rmb_per_sqm") }}
                </span>
              </div>
              <div
                v-else-if="
                  resultData.ffeBudCostMin[item.id] &&
                  resultData.ffeBudCostMin[item.id] > 0 &&
                  resultData.ffeBudCostMax[item.id]
                "
              >
                <span
                  v-if="
                    resultData.ffeBudCostMin[item.id] ==
                    resultData.ffeBudCostMax[item.id]
                  "
                >
                  {{ item.currency_symbol }}
                  {{ resultData.ffeBudCostMin[item.id] | toThousandFilter
                  }}{{ item.currency }}/{{ $t("welcome.project_rmb_per_sqm") }}
                </span>
                <span v-else>
                  {{ item.currency_symbol }}
                  {{ resultData.ffeBudCostMin[item.id] | toThousandFilter }} -
                  {{ item.currency_symbol
                  }}{{ resultData.ffeBudCostMax[item.id] | toThousandFilter
                  }}{{ item.currency }}/{{ $t("welcome.project_rmb_per_sqm") }}
                </span>
              </div>

              <div v-else>-</div>
            </div>
            <!-- Task: Add 软装“经营范围” filter for Design Deliverables -->

            <div class="tr" v-if="item.involvedSfw">
              <div>{{ $t("welcome.project_scope") }}:</div>
              <span
                v-for="(sfw, key) in item.involvedSfw"
                :key="key"
              >
              <span v-html="sfw"></span>
                <em v-if="key != Object.keys(item.involvedSfw).length"
                  >/</em
                ></span
              >
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="no-result" v-else>
      {{ $t("common.no_result") }}: {{ deliverable }}}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgurl: process.env.VUE_APP_IMG_URL,
      opt_type: []
    };
  },
  props: {
    deliverable: {
      type: Array
    },
    resultData: {
      type: Object
    },
    relatedProjects: {
      type: [Object, Array]
    }
  },
  watch: {
    resultData() {
      this.opt_type = this.resultData.optTypeList;
      console.log(this.opt_type);
    }
  },

  methods: {
    goPage(id, type, reId) {
      if (type) {
        this.$router.push(`/deliverable-detail?id=${id}&relateId=${reId}`);
      } else {
        this.$router.push(`/deliverable-detail?id=${id}`);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.deliverable-list {
  margin: 0px 0 60px;
  ul {
    text-align: justify;
    font-size: 0;
    align-items: flex-start !important;
    vertical-align: top;
    li {
      font-size: 14px;
      background-color: #ffffff;
      margin: 0px 0px 50px;
      padding: 15px 15px 35px;
      width: 20%;
      text-align: left;
      vertical-align: top;
      position: relative;
      display: inline-block;
      cursor: pointer;
      @media (max-width: 1559px) {
        padding: 7.5px;
      }
      @media (max-width: 1023px) {
        width: 33.33%;
      }
      @media (max-width: 767px) {
        width: 50%;
        margin: 0 0 30px;
      }
      .thumb-box {
        position: relative;
        &::before {
          content: "";
          display: block;
          padding-top: 100%;
          overflow: hidden;
          background: #f2f2f2;
        }
        .thumb-text {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          vertical-align: middle;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .thumb-img {
          position: absolute;
          /* display: flex; */
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          img {
            display: block;
            min-width: 100%;
            min-height: 100%;
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            margin: auto 0;
            object-fit: cover;
            transition: transform 1s;
            transition-timing-function: ease;
          }
        }
      }
      .project-name {
        min-height: 38px;
        line-height: 19px;
        margin-top: 20px;
        font-size: 15px;
      }
      .specs {
        font-size: 12px;
        color: #999999;
        display: table;
        margin-top: 5px;
        .tr {
          font-size: 12px;
          font-weight: normal;
          color: #999999;
          display: table-row;
          text-align: left;
          > div {
            font-size: 12px;
            color: #999999;
            display: table-cell;
            padding-bottom: 1px;
            text-align: left;
            &:first-child {
              padding-right: 5px;
              min-width: 70px;
            }
          }
        }
      }
    }
  }
}
.no-result {
  text-align: center;
  margin-bottom: 80px;
  color: #999;
}
.codes {
  color: #555555 !important;
  cursor: pointer;
}
.codes span:hover {
  color: #950c0f;
}
</style>
