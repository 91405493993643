
<template>
  <div class="content">
    <b-container fluid>
      <b-row class="drs">
        <b-col cols="12">
          <h5 class="name mgb20 tc">{{$t('Strateg.strategic')}}</h5>
        </b-col>
        <b-col cols="12">
            <brand-index :type="type"></brand-index>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import brandIndex from '@/components/Cooperative/BrandIndex.vue'
export default {
  components:{
      'brand-index': brandIndex
  },
  data() {
    return {
        type:1
    };
  },

  created() {


  },
  methods: {


  }
};
</script>

<style lang="scss" scoped>
.content {
  padding: 25px 0 40px;
  overflow-x: hidden;
  .name {
    padding: 20px 0px 20px;
    font-size: 21px;
  }
  .drs {
    max-width: 1530px;
    margin: 0 auto;
    text-align: left;
    .col-12 {
      padding: 0;
    }
    @media (max-width: 1023px) {
      /*margin: 0 -15px;*/

    }
    @media (max-width: 767px) {
      /*margin: 0 -15px;*/
    }
  }
}

</style>
