<template>
  <div class="content" v-loading="loading">
    <b-container fluid>
      <b-row class="deliverables">
        <b-col md="12">
          <div class="name mgb20 tc">
            <div>
              {{ name }}
            </div>
            <div class="coCat tc mgt10">
              <el-select
                popper-append-to-body
                :style="{ width: selectOptionWidth.sort }"
                v-model="select.sort"
                placeholder-color="#555"
                @change="getchangevalue"
              >
                <el-option
                  v-for="item in $t('common.sortOption')"
                  :key="item.value"
                  :label="item.text"
                  @click.native="setOptionWidth($event, 'sort')"
                  :value="item.value"
                >
                  <span style="float: left">{{ item.text }}</span>
                </el-option>
              </el-select>
              <el-select
                popper-append-to-body
                :style="{ width: selectOptionWidth.brand }"
                v-model="select.brand"
                placeholder-color="#555"
                @change="getchangevalue"
              >
                <el-option
                  v-for="(item, key) in brandOption"
                  :key="key"
                  @click.native="setOptionWidth($event, 'brand')"
                  :label="item"
                  :value="key"
                >
                  <span style="float: left">{{ item }}</span>
                </el-option>
              </el-select>

              <el-select
                popper-append-to-body
                :style="{ width: selectOptionWidth.optType }"
                v-model="select.optType"
                placeholder-color="#555"
                @change="getchangevalue"
              >
                <el-option
                  v-for="(item, key) in opt_type"
                  :key="key"
                  @click.native="setOptionWidth($event, 'optType')"
                  :label="item"
                  :value="key"
                >
                  <span style="float: left">{{ item }}</span>
                </el-option>
              </el-select>
              <el-select
                popper-append-to-body
                :style="{ width: selectOptionWidth.optArea }"
                v-model="select.optArea"
                placeholder-color="#555"
                @change="getchangevalue"
              >
                <el-option
                  v-for="(item, key) in opt_area"
                  :key="key"
                  @click.native="setOptionWidth($event, 'optArea')"
                  :label="item"
                  :value="key"
                >
                  <span style="float: left">{{ item }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
        </b-col>
        <b-col md="12">
          <project-photo :deginref-list="deginrefList" :mid="id"></project-photo>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { getDesignrefList } from '@/api/design'
import projectPhoto from '@/components/Guideline/ProjectPhoto'
export default {
  components: {
    'project-photo':projectPhoto,
  },
  data() {
    return {
      selectOptionWidth: {
        sort: '86px',
        brand: '86px',
        optType:
          window.localStorage.getItem('language') == 'en' ? '130px' : '86px',
        optArea: '86px',
      },
      name: '',
      id: null,
      loading: false,
      imgurl: process.env.VUE_APP_IMG_URL,
      brandOption: [],

      opt_type: [],
      opt_area: [],
      select: {
        sort: '',
        brand: '',
        optType: '',
        optArea: '',
      },
      deginrefList: [],
    }
  },
  created() {
    this.id = Number(this.$route.query.id)
    this.select.sort = this.$route.query.sort ? this.$route.query.sort : ''
    this.select.brand = this.$route.query.brand ? this.$route.query.brand : ''
    this.select.optType = this.$route.query.optType
      ? this.$route.query.optType
      : ''
    this.select.optArea = this.$route.query.optArea
      ? this.$route.query.optArea
      : ''
    if (
      this.$route.query.brand ||
      this.$route.query.optType ||
      this.$route.query.optArea
    ) {
      this.selectWidth = JSON.parse(window.sessionStorage.getItem('selectWidth'))
      if (this.selectWidth) {
        this.selectOptionWidth = this.selectWidth
      }
    } else {
      const selectWidth = {
        sort: '86px',
        brand: '86px',
        optType:
          window.localStorage.getItem('language') == 'en' ? '130px' : '86px',
        optArea: '86px',
      }
      this.selectOptionWidth = selectWidth
    }
    this.getDesign()
  },
  beforeRouteLeave(to, from, next) {
    this.$route.meta.savedPosition = window.pageYOffset;
    next();
  },
  watch: {
    $route() {
      this.id = Number(this.$route.query.id)
      this.select.sort = this.$route.query.sort ? this.$route.query.sort : ''
      this.select.brand = this.$route.query.brand ? this.$route.query.brand : ''
      this.select.optType = this.$route.query.optType
        ? this.$route.query.optType
        : ''
      this.select.optArea = this.$route.query.optArea
        ? this.$route.query.optArea
        : ''
      this.selectWidth = JSON.parse(
        window.sessionStorage.getItem('selectWidth')
      )
      if (
        this.$route.query.brand ||
        this.$route.query.optType ||
        this.$route.query.optArea
      ) {
        if (this.selectWidth) {
          this.selectOptionWidth = this.selectWidth
        }
      } else {
        const selectWidth = {
          sort: '86px',
          brand: '86px',
          optType:
            window.localStorage.getItem('language') == 'en' ? '130px' : '86px',
          optArea: '86px',
        }
        this.selectOptionWidth = selectWidth
      }
      //this.getDesign()
    },
  },
  methods: {
    setOptionWidth(event, type) {
      this.$nextTick(() => {
        if (event.target.localName == 'span') {
          this.nodeIndex = event.target.clientWidth + 30
        } else {
          this.nodeIndex = event.target.childNodes[0].clientWidth + 30
        }
        this.selectOptionWidth[type] = this.nodeIndex + 'px'
        window.sessionStorage.setItem(
          'selectWidth',
          JSON.stringify(this.selectOptionWidth)
        )
      })
      this.pathChange()
    },
    pathChange() {
      const queryData = {
        id: this.id,
        sort: this.select.sort,
        brand: this.select.brand,
        optType: this.select.optType,
        optArea: this.select.optArea,
      }
      Object.keys(queryData).forEach((item) => {
        const key = queryData[item]
        if (key == '') {
          delete queryData[item]
        }
      })
      this.$router.push({
        path: '/project-photo',
        query: queryData,
      })
    },
    getDesign() {
      if (this.id && this.select) {
        this.loading = true
        getDesignrefList(this.id, this.select).then((res) => {
          this.loading = false
          let result = res.data
          this.deginrefList = result.resList
          this.brandOption = result.brandList
          this.opt_type = result.optTypeList
          this.opt_area = result.optAreaList
          this.name = result.ref.name
        })
      }

    },

    getchangevalue() {
      this.getDesign()
    },
    // changedataType(list){

    //    let arr = [];
    //    for(let i in list){
    //       let obj= {};
    //       obj.id = i;
    //       obj.text = list[i]
    //       arr.push(obj);
    //     }

    //     return arr;
    // }
  },
}
</script>

<style lang="scss" scoped>
.content {
  padding: 25px 0 40px;
  overflow-x: hidden;
  .name {
    padding: 20px 0px 20px;
    font-size: 21px;
  }
  .deliverables {
    max-width: 1530px;
    margin: 0 auto;
    text-align: left;
    @media (max-width: 1023px) {
      /*margin: 0 -15px;*/
    }
    @media (max-width: 767px) {
      /*margin: 0 -15px;*/
    }
    .col-md-12 {
      padding: 0;
    }
  }
}
/deep/ .el-input__inner {
  border: none;
  font-size: 13px;
  color: #555;
  text-align: center;
  padding-left: 0;
}
.width125 /deep/ .el-input__inner {
  width: 125px;
}
.width59 /deep/ .el-input__inner {
  width: 86px;
}
.width72 /deep/ .el-input__inner {
  width: 72px;
}
.width86 /deep/ .el-input__inner {
  width: 86px;
}
.width73 /deep/ .el-input__inner {
  width: 73px;
}
.width60 /deep/ .el-input__inner {
  width: 60px;
}
</style>
