<template>
  <div>
      <el-dialog class="send-dialog dialog" :visible.sync="issendDialog" width="40%" v-if="detailData!=''" @close="closeDialog">
        <div class="cat_preview tl">
            <h6>请确认你的联络资料正确无误。资料将用于与供应商报备等工作的联系。</h6>
            <div>
            <label>名称:</label>
            <span>{{detailData.sender.english_name}}{{detailData.sender.lastname}}</span>
            </div>
            <div>
            <label>职位:</label>
            <span></span>
            {{detailData.sender.title_tc}}
            </div>
            <div>
            <label>邮箱:</label>
            <span></span>
            {{detailData.sender.email}}
            </div>
            <div>
            <label>公司地址:</label>
            <span>{{detailData.sender.officeAddr}}</span>
            </div>
            <div>
            <label>电话:</label>
            <span>{{detailData.sender.mobile2}}</span>
            </div>
            <div>
            <label>手提电话:</label>
            <span>{{detailData.sender.mobile1}}</span>
            </div>
            <br />
            <h6 class="ft12">如果联络资料有误，请联络行政部寻求进一步协助。</h6>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button size="small" @close="closeDialog">取 消</el-button>
            <el-button size="small" @click="sendDialog = false">确认无误</el-button>
        </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
    data(){
        return{
            issendDialog:false
        }
    },
    props:{
        isVisble:{
            type:Boolean
        },
        detailData:{
            type:Object
        }
    },
    watch:{
        isVisble() {
            this.issendDialog = this.isVisble;
        },
        
    },
    methods:{
        closeDialog(){
            this.$emit("dialogChange", false);
        }
    }
}
</script>

<style lang="scss" scoped>
.send-dialog .cat_preview{
    h6{
        margin-top: 20px;
        font-weight: bold;
        font-size: 14px;
    }
    >div{
        margin-top: 5px;
        line-height: 180%;
        label{
            width: 100px;
        }
    }
    
}
.dialog /deep/ .el-dialog__footer{border-top: #ddd 1px solid;}
.dialog-footer{
  button{
      text-shadow: none;
      border: none;
      font-size: 14px;
      background-color: #ecf0f1;
      color: #000;
      &:hover{
        background-color: #bdc3c7;
      }
  }

}
</style>