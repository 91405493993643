<template>
  <div class="show-category">
    <el-tag v-show="showTag" type="info">
      <b>{{
        categories ? categories.name : $t('productfilter.DefaultCategory')
      }}</b>
      <i class="el-icon-edit" @click="cateChange"></i>
    </el-tag>

    <b-form-select
      v-show="showChange"
      class="byCat select-list category-select"
      v-model="categoryId"
      value-field="value"
      text-field="text"
      :options="wishCategoryList"
    ></b-form-select>

    <span
      class="update-category"
      v-if="showChange"
      @click="updateWishCategory"
      >{{ $t('common.confirm') }}</span
    >
    <span class="update-category" v-if="showChange" @click="cateChange">{{
      $t('common.cancel')
    }}</span>
  </div>
</template>

<script>
import { updateWishCategory } from '@/api/product'
export default {
  props: {
    prodId: {
      type: Number,
    },
    categories: {
      type: Object,
    },
    wishCategoryList: {
      type: Array,
    },
  },
  data() {
    return {
      categoryId: null,
      showTag: true,
      showChange: false,
    }
  },
  methods: {
    cateChange() {
      this.showTag = !this.showTag
      this.showChange = !this.showChange
      this.categoryId = null
    },
    updateWishCategory() {
      const data = {
        pid: this.prodId,
        category_id: this.categoryId,
      }
      updateWishCategory(data).then((res) => {
        this.$emit('updateWishCategory')
        this.showChange = !this.showChange
        this.showTag = !this.showTag
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.select-list {
  height: 30px;
  padding: 6px 8px;
  font-size: 12px;
  line-height: 1.5;
  width: 100%;
  border-radius: 3px;
  margin-left: 7px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  @media (min-width: 768px) {
    display: inline-block;
    // width: auto;
    vertical-align: middle;
  }
}
.show-category {
  .el-icon-edit {
    margin-left: 5px;
    cursor: pointer;
    font-weight: 600;
  }
  .el-icon-edit:hover {
    color: #c63033;
  }
  .update-category {
    margin-left: 10px;
    font-size: 12px;
    cursor: pointer;
  }
  .update-category:hover {
    text-decoration: underline;
  }
  .el-tag {
    padding: 0 8px;
    height: 25px;
    line-height: 25px;
    color: #555;
  }
}
.category-select {
  width: 40%;
}
</style>
