
<template>
  <div class="content">
    <b-container fluid>
      <b-col md="12">
        <div class="story">
          <h5 class="name mgb20 tc ft21">{{$t('Promotions.steve')}}</h5>

          <div v-if="lang == 'en'">
            <img
            :src="url + 'promotion/steveleung.jpg'"
              style="width:100%; height:auto; max-width:1500px;"
            />

            <div style="max-width:980px; margin:55px auto;">
              Steve has decided to sell part of his private collection in exclusive offer. If there is any suitable project, design teams can recommend these items to the client and enjoy 10% discount in purchasing these items. The profit generated in the transaction will belong to the related design team.
              <br />
              <br />For further information and quotations, please contact Senior Purchasing Executive - Wally Chu; If you are interested in purchasing the items, please download and fill in the
              <a class="fce3" :href="orderFormUrl">order form</a>, and email to Wally Chu (
              <a class="fce3" href="mailto:wally.chu@ed-living.com">wally.chu@ed-living.com</a>).
              <br />
              <br />

              <div class="download-btn tc">
                <a
                  :href="orderFormUrl"
                  class="btn white"
                >Download Order Form</a>
              </div>

              <br />
              <i>Remarks: All artworks are in stock and can be reserved for 1 month upon request.</i>
            </div>
            <br />

            <h2>Paintings</h2>

            <ul class="album-list">
              <!--<li>
                  <div class="img-top mgb27">
                    <div class="mood-img">
                      <img class="b-lazy" :src="url + 'promotion/steveleung-01.jpg'">
                    </div>
                  </div>
                  <div>

                    <h3>Julia Brooker</h3>
                    <b>Bliss <h6>On hold until 17 Nov 2021</h6></b>
                    <div class="price">HKD 38,000.00</div>
                    <div class="otherDetails">
                      1250(W) x 1250(H)<br>
                      Acrylic on Aluminum<br>
                      Year of creation: 2005<br>
                      Provenance: Artist<br>
                      Item code: 1
                    </div>
                  </div>
              </li>-->
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-02.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Julia Brooker</h3>
                  <b>Glimpse</b>
                  <div class="price">HKD 38,000.00</div>
                  <div class="otherDetails">
                    1250(W) x 1250(H)
                    <br />Acrylic on Aluminum
                    <br />Year of creation: 2005
                    <br />Provenance: Artist
                    <br />Item code: 2
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-03.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Julia Brooker</h3>
                  <b>Constantinople</b>
                  <div class="price">HKD 38,000.00</div>
                  <div class="otherDetails">
                    1250(W) x 1250(H)
                    <br />Acrylic on Aluminum
                    <br />Year of creation: 2005
                    <br />Provenance: Artist
                    <br />Item code: 3
                  </div>
                </div>
              </li>
            </ul>

            <h2>Lithograph</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-04.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Zao Wou Ki</h3>
                  <b>Lithographie 198 Edition no. 15/90</b>
                  <div class="price">HKD 200,000.00</div>
                  <div class="otherDetails">
                    540(W) x 450(H)
                    <i>(Artwork Size)</i>
                    <br />880(W) x 45(D) x 790(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph
                    <br />Year of creation: 1968
                    <br />Provenance: Kwai Po Collection
                    <br />Item code: 4
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-05.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Ying Tian Qi</h3>
                  <b>Xidi Village Series no. 11</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    590(W) x 490(H)
                    <i>(Artwork Size)</i>
                    <br />895(W) x 1300(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph Numbered 23/50
                    <br />Year of creation: 1989
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 5
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-06.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Ying Tian Qi</h3>
                  <b>Xidi Village Series No.8</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    590(W) x 490(H)
                    <i>(Artwork Size)</i>
                    <br />895(W) x 1300(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph Numbered A.P
                    <br />Year of creation: 1988
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 6
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-07.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Ying Tian Qi</h3>
                  <b>Xidi Village Series No.5</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    595(W) x 500(H)
                    <i>(Artwork Size)</i>
                    <br />1110(W) x 900(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph Numbered A.P
                    <br />Year of creation: 1988
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 7
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-08.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Ying Tian Qi</h3>
                  <b>Xidi Village Series No.19</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    500(W) x 390(H)
                    <i>(Artwork Size)</i>
                    <br />895(W) x 1300(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph Numbered 8/50
                    <br />Year of creation: 1989
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 8
                  </div>
                </div>
              </li>
              <!--<li>
                  <div class="img-top mgb27">
                    <div class="mood-img">
                      <img class="b-lazy" :src="url + 'promotion/steveleung-09.jpg'">
                    </div>
                  </div>
                  <div>
                    <h3>Ying Tian Qi</h3>
                    <b>Repeated Xidi Village Series No.5 <h6>On hold until 17 Nov 2021</h6></b>
                    <div class="price">HKD 15,000.00</div>
                    <div class="otherDetails">
                      465(W) x 540(H) <i>(Artwork Size)</i><br>
                      900(W) x 900(H) <i>(Overall Size)</i><br>
                      Lithograph Numbered 16/50<br>
                      Year of creation: 1991<br>
                      Provenance: Kwai Fung Hin Art Gallery<br>
                      Item code: 9
                    </div>
                  </div>
              </li>-->
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-10.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Ying Tian Qi</h3>
                  <b>Repeated Xidi Village Series No.4</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    415(W) x 505(H)
                    <i>(Artwork Size)</i>
                    <br />900(W) x 900(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph Numbered A.P
                    <br />Year of creation: 1991
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 10
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-11.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Szeto Lap</h3>
                  <b>Leaves & Fruits</b>
                  <div class="price">HKD 25,000.00</div>
                  <div class="otherDetails">
                    770(W) x 1200(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph Numbered 26/138
                    <br />Year of creation: 2002
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 11
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-12.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Szeto Lap</h3>
                  <b>Tranquility II</b>
                  <div class="price">HKD 25,000.00</div>
                  <div class="otherDetails">
                    620(W) x 840(H)
                    <i>(Artwork Size)</i>
                    <br />895(W) x 1300(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph Numbered 26/138
                    <br />Year of creation: 2002
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 12
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-13.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Szeto Lap</h3>
                  <b>Tranquility II</b>
                  <div class="price">HKD 25,000.00</div>
                  <div class="otherDetails">
                    620(W) x 840(H) *No frame
                    <br />Lithograph Numbered 11/138
                    <br />Year of creation: 2002
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 13
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-14.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Hu Zhen Yu</h3>
                  <b>The Passing of Time Series of 4</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    500(W) x 500(H)
                    <i>(Artwork Size)</i>
                    <br />900(W) x 900(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph Numbered 182/198
                    <br />Year of creation: 2002
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 14
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-15.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Hu Zhen Yu</h3>
                  <b>The Passing of Time Series of 4</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    490(W) x 500(H)
                    <i>(Artwork Size)</i>
                    <br />900(W) x 900(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph Numbered 183/198
                    <br />Year of creation: 2002
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 15
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-16.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Hu Zhen Yu</h3>
                  <b>The Passing of Time Series of 4</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    500(W) x 500(H)
                    <i>(Artwork Size)</i>
                    <br />900(W) x 900(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph Numbered 183/198
                    <br />Year of creation: 2002
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 16
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-17.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Hu Zhen Yu</h3>
                  <b>The Passing of Time Series of 4</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    500(W) x 500(H)
                    <i>(Artwork Size)</i>
                    <br />900(W) x 900(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph Numbered 182/198
                    <br />Year of creation: 2002
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 17
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-18.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Guo Wei</h3>
                  <b>Room with Cloud No.5</b>
                  <div class="price">HKD 20,000.00</div>
                  <div class="otherDetails">
                    777(W) x 927(H)
                    <i>(Artwork Size)</i>
                    <br />1020(W) x 1170(H)
                    <i>(Overall Size)</i>
                    <br />Lithography Numbered 117/199
                    <br />Year of creation: 2007
                    <br />Provenance: Kwai Po Collection
                    <br />Item code: 18
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-19.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Guo Wei</h3>
                  <b>Cloud</b>
                  <div class="price">HKD 20,000.00</div>
                  <div class="otherDetails">
                    775(W) x 880(H)
                    <i>(Artwork Size)</i>
                    <br />1020(W) x 1170(H)
                    <i>(Overall Size)</i>
                    <br />Lithography Numbered 94/175
                    <br />Year of creation: 2007
                    <br />Provenance: Kwai Po Collection
                    <br />Item code: 19
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-20.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Guo Jin</h3>
                  <b>Children No.1</b>
                  <div class="price">HKD 20,000.00</div>
                  <div class="otherDetails">
                    597(W) x 1185(H)
                    <i>(Artwork Size)</i>
                    <br />820(W) x 1470(H)
                    <i>(Overall Size)</i>
                    <br />Lithography Numbered 163/220
                    <br />Year of creation: 2008
                    <br />Provenance: Kwai Po Collection
                    <br />Item code: 20
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-21.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Wang Guang Yi</h3>
                  <b>Pepsi</b>
                  <div class="price">HKD 28,000.00</div>
                  <div class="otherDetails">
                    1100(W) x 1250(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph
                    <br />Year of creation: 2002
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 21
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-22.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Wang Guang Yi</h3>
                  <b>Coca Col</b>
                  <div class="price">HKD 18,000.00</div>
                  <div class="otherDetails">
                    940(W) x 1030(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph
                    <br />Year of creation: 2002
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 22
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-23.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Wang Guang Yi</h3>
                  <b>Gillette</b>
                  <div class="price">HKD 18,000.00</div>
                  <div class="otherDetails">
                    940(W) x 1030(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph
                    <br />Year of creation: 2002
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 23
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-24.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Wang Guang Yi</h3>
                  <b>The Belie Series of 4</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    640(W) x 750(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph
                    <br />Year of creation: 2002
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 24
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-25.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Wang Guang Yi</h3>
                  <b>The Belie Series of 4</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    640(W) x 750(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph
                    <br />Year of creation: 2002
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 25
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-26.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Wang Guang Yi</h3>
                  <b>The Belie Series of 4</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    640(W) x 750(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph
                    <br />Year of creation: 2002
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 26
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-27.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Wang Guang Yi</h3>
                  <b>The Belie Series of 4</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    640(W) x 750(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph
                    <br />Year of creation: 2002
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 27
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-28.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Zhang Xiaogang</h3>
                  <b>My Dear Friend Series of 7</b>
                  <div class="price">
                    HKD 50,000.00
                    <i>(set of 7)</i>
                  </div>
                  <div class="otherDetails">
                    650(W) x 745(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph
                    <br />Year of creation: 2002
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 28
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-29.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Zhang Xiaogang</h3>
                  <b>My Dear Friend Series of 7</b>
                  <div class="price">
                    HKD 50,000.00
                    <i>(set of 7)</i>
                  </div>
                  <div class="otherDetails">
                    650(W) x 745(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph
                    <br />Year of creation: 2002
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 29
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-30.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Zhang Xiaogang</h3>
                  <b>My Dear Friend Series of 7</b>
                  <div class="price">
                    HKD 50,000.00
                    <i>(set of 7)</i>
                  </div>
                  <div class="otherDetails">
                    650(W) x 745(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph
                    <br />Year of creation: 2002
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 30
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-31.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Zhang Xiaogang</h3>
                  <b>My Dear Friend Series of 7</b>
                  <div class="price">
                    HKD 50,000.00
                    <i>(set of 7)</i>
                  </div>
                  <div class="otherDetails">
                    650(W) x 745(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph
                    <br />Year of creation: 2002
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 31
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-32.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Zhang Xiaogang</h3>
                  <b>My Dear Friend Series of 7</b>
                  <div class="price">
                    HKD 50,000.00
                    <i>(set of 7)</i>
                  </div>
                  <div class="otherDetails">
                    650(W) x 745(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph
                    <br />Year of creation: 2002
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 32
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-33.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Zhang Xiaogang</h3>
                  <b>My Dear Friend Series of 7</b>
                  <div class="price">
                    HKD 50,000.00
                    <i>(set of 7)</i>
                  </div>
                  <div class="otherDetails">
                    650(W) x 745(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph
                    <br />Year of creation: 2002
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 33
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-34.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Zhang Xiaogang</h3>
                  <b>My Dear Friend Series of 7</b>
                  <div class="price">
                    HKD 50,000.00
                    <i>(set of 7)</i>
                  </div>
                  <div class="otherDetails">
                    650(W) x 745(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph
                    <br />Year of creation: 2002
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 34
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-35.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Xu Lei</h3>
                  <b>
                    <span class="na">-</span>
                  </b>
                  <div class="price">HKD 80,000.00</div>
                  <div class="otherDetails">
                    650(W) x 900(H)
                    <i>(Artwork Size)</i>
                    <br />805(W) x 1020(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph
                    <br />Year of creation:
                    <span class="na">-</span>
                    <br />Provenance:
                    <span class="na">-</span>
                    <br />Item code: 35
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-37.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Zhang Xiaogang</h3>
                  <b>My Daughter</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    860(W) x 790(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph
                    <br />Year of creation: 2002
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 37
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-38.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Zhang Xiaogang</h3>
                  <b>Two Sisters</b>
                  <div class="price">HKD 30,000.00</div>
                  <div class="otherDetails">
                    860(W) x 790(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph
                    <br />Year of creation: 2003
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 38
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-39.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Xu Lei</h3>
                  <b>Day Dream</b>
                  <div class="price">HKD 80,000.00</div>
                  <div class="otherDetails">
                    650(W) x 900(H)
                    <br />Lithograph Edition 11/68
                    <br />Year of creation: 2007
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 39
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-40.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Ai Xuan</h3>
                  <b>Reeds in Chill</b>
                  <div class="price">HKD 50,000.00</div>
                  <div class="otherDetails">
                    990(W) x 785(H)
                    <i>(Artwork Size)</i>
                    <br />1120(W) x 930(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph Numbered 184/198
                    <br />Year of creation: 2002
                    <br />Provenance: Kwai Fung Hin Art Gallery
                    <br />Item code: 40
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-41.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Kim Tschang Yeul</h3>
                  <b>
                    <span class="na">-</span>
                  </b>
                  <div class="price">HKD 30,000.00</div>
                  <div class="otherDetails">
                    385(W) x 545(H)
                    <i>(Artwork Size)</i>
                    <br />730(W) x 960(H)
                    <i>(Overall Size)</i>
                    <br />Lithograph Edition 68/90
                    <br />Year of creation:
                    <span class="na">-</span>
                    <br />Provenance:
                    <span class="na">-</span>
                    <br />Item code: 41
                  </div>
                </div>
              </li>
            </ul>

            <h2>Photography</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-42.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Santiago Barrio</h3>
                  <b>5/15</b>
                  <div class="price">HKD 40,000.00</div>
                  <div class="otherDetails">
                    740(W) x 1100(H)
                    <i>(Artwork Size)</i>
                    <br />965(W) x 1340(H)
                    <i>(Overall Size)</i>
                    <br />Photography
                    <br />Year of creation: 2016
                    <br />Provenance: 柏(北京)国际陈设艺术顾问有限公司
                    <br />Item code: 42
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-43.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Santiago Barrio</h3>
                  <b>5/15</b>
                  <div class="price">HKD 40,000.00</div>
                  <div class="otherDetails">
                    740(W) x 1100(H)
                    <i>(Artwork Size)</i>
                    <br />965(W) x 1340(H)
                    <i>(Overall Size)</i>
                    <br />Photography
                    <br />Year of creation: 2015
                    <br />Provenance: 柏(北京)国际陈设艺术顾问有限公司
                    <br />Item code: 43
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-44.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Santiago Barrio</h3>
                  <b>5/15</b>
                  <div class="price">HKD 40,000.00</div>
                  <div class="otherDetails">
                    740(W) x 1100(H)
                    <i>(Artwork Size)</i>
                    <br />965(W) x 1340(H)
                    <i>(Overall Size)</i>
                    <br />Photography
                    <br />Year of creation: 2015
                    <br />Provenance: 柏(北京)国际陈设艺术顾问有限公司
                    <br />Item code: 44
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-45.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Santiago Barrio</h3>
                  <b>5/15</b>
                  <div class="price">HKD 40,000.00</div>
                  <div class="otherDetails">
                    740(W) x 1100(H)
                    <i>(Artwork Size)</i>
                    <br />965(W) x 1340(H)
                    <i>(Overall Size)</i>
                    <br />Photography
                    <br />Year of creation: 2015
                    <br />Provenance: 柏(北京)国际陈设艺术顾问有限公司
                    <br />Item code: 45
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-46.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Santiago Barrio</h3>
                  <b>5/15</b>
                  <div class="price">HKD 40,000.00</div>
                  <div class="otherDetails">
                    740(W) x 1100(H)
                    <i>(Artwork Size)</i>
                    <br />965(W) x 1340(H)
                    <i>(Overall Size)</i>
                    <br />Photography
                    <br />Year of creation: 2015
                    <br />Provenance: 柏(北京)国际陈设艺术顾问有限公司
                    <br />Item code: 46
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-47.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Santiago Barrio</h3>
                  <b>5/15</b>
                  <div class="price">HKD 40,000.00</div>
                  <div class="otherDetails">
                    740(W) x 1100(H)
                    <i>(Artwork Size)</i>
                    <br />965(W) x 1340(H)
                    <i>(Overall Size)</i>
                    <br />Photography
                    <br />Year of creation: 2015
                    <br />Provenance: 柏(北京)国际陈设艺术顾问有限公司
                    <br />Item code: 47
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-48.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Santiago Barrio</h3>
                  <b>5/15</b>
                  <div class="price">HKD 40,000.00</div>
                  <div class="otherDetails">
                    740(W) x 1100(H)
                    <i>(Artwork Size)</i>
                    <br />965(W) x 1340(H)
                    <i>(Overall Size)</i>
                    <br />Photography
                    <br />Year of creation: 2015
                    <br />Provenance: 柏(北京)国际陈设艺术顾问有限公司
                    <br />Item code: 48
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-49.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Santiago Barrio</h3>
                  <b>5/15</b>
                  <div class="price">HKD 40,000.00</div>
                  <div class="otherDetails">
                    740(W) x 1100(H)
                    <i>(Artwork Size)</i>
                    <br />965(W) x 1340(H)
                    <i>(Overall Size)</i>
                    <br />Photography
                    <br />Year of creation: 2015
                    <br />Provenance: 柏(北京)国际陈设艺术顾问有限公司
                    <br />Item code: 49
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-50.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Santiago Barrio</h3>
                  <b>5/15</b>
                  <div class="price">HKD 40,000.00</div>
                  <div class="otherDetails">
                    740(W) x 1100(H)
                    <i>(Artwork Size)</i>
                    <br />965(W) x 1340(H)
                    <i>(Overall Size)</i>
                    <br />Photography
                    <br />Year of creation: 2015
                    <br />Provenance: 柏(北京)国际陈设艺术顾问有限公司
                    <br />Item code: 50
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-51.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Santiago Barrio</h3>
                  <b>5/15</b>
                  <div class="price">HKD 40,000.00</div>
                  <div class="otherDetails">
                    740(W) x 1100(H)
                    <i>(Artwork Size)</i>
                    <br />965(W) x 1340(H)
                    <i>(Overall Size)</i>
                    <br />Photography
                    <br />Year of creation: 2015
                    <br />Provenance: 柏(北京)国际陈设艺术顾问有限公司
                    <br />Item code: 51
                  </div>
                </div>
              </li>
            </ul>

            <h2>Mixed Media</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-52.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Annette Merrild</h3>
                  <b>Sculptural Painting</b>
                  <div class="price">HKD 220,000.00</div>
                  <div class="otherDetails">
                    1480(W) x 2000(H)
                    <br />Photo with glue and mixed technique. Unique
                    <br />Year of creation: 2014
                    <br />Provenance: Ibiza Art Projects SL.
                    <br />Item code: 52
                  </div>
                </div>
              </li>
            </ul>

            <h2>Others</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-53.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Marcel Mouly</h3>
                  <b>
                    <span class="na">-</span>
                  </b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    365(W) x 490(H)
                    <i>(Overall Size)</i>
                    <br />Year of creation:
                    <span class="na">-</span>
                    <br />Provenance:
                    <span class="na">-</span>
                    <br />Item code: 53
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-54.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Marcel Mouly</h3>
                  <b>
                    <span class="na">-</span>
                  </b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    365(W) x 490(H)
                    <i>(Overall Size)</i>
                    <br />Year of creation:
                    <span class="na">-</span>
                    <br />Provenance:
                    <span class="na">-</span>
                    <br />Item code: 54
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-55.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Zao Wou Ki</h3>
                  <b>
                    <span class="na">-</span>
                  </b>
                  <div class="price">HKD 250,000.00</div>
                  <div class="otherDetails">
                    790(W) x 990(H)
                    <br />Beige Border
                    <br />Year of creation:
                    <span class="na">-</span>
                    <br />Provenance:
                    <span class="na">-</span>
                    <br />Item code: 55
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-56.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Sarah Brand</h3>
                  <b>
                    <span class="na">-</span>
                  </b>
                  <div class="price">HKD 30,000.00</div>
                  <div class="otherDetails">
                    400(W) x 1200(H)
                    <i>(Overall Size)</i>
                    <br />Year of creation:
                    <span class="na">-</span>
                    <br />Provenance:
                    <span class="na">-</span>
                    <br />Item code: 56
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-57.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>
                    <span class="na">-</span>
                  </h3>
                  <b>
                    <span class="na">-</span>
                  </b>
                  <div class="price">HKD 30,000.00</div>
                  <div class="otherDetails">
                    1320(W) x 1165(H)
                    <i>(Overall Size)</i>
                    <br />Year of creation:
                    <span class="na">-</span>
                    <br />Provenance:
                    <span class="na">-</span>
                    <br />Item code: 57
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-58.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Le Corbusier</h3>
                  <b>
                    <span class="na">-</span>
                  </b>
                  <div class="price">HKD 50,000.00</div>
                  <div class="otherDetails">
                    940(W) x 1050(H)
                    <i>(Overall Size)</i>
                    <br />Year of creation:
                    <span class="na">-</span>
                    <br />Provenance:
                    <span class="na">-</span>
                    <br />Item code: 58
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-59.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Aldo Rossi</h3>
                  <b>
                    <span class="na">-</span>
                  </b>
                  <div class="price">HKD 100,000.00</div>
                  <div class="otherDetails">
                    1430(W) x 1030(H)
                    <i>(Overall Size)</i>
                    <br />Year of creation:
                    <span class="na">-</span>
                    <br />Provenance:
                    <span class="na">-</span>
                    <br />Item code: 59
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-60.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Zao Wou Ki</h3>
                  <b>
                    <span class="na">-</span>
                  </b>
                  <div class="price">HKD 180,000.00</div>
                  <div class="otherDetails">
                    580(W) X 440(H)
                    <i>(Artwork Size)</i>
                    <br />880(W) x 740(H)
                    <i>(Overall Size)</i>
                    <br />Numbered 55/99
                    <br />Beige Border
                    <br />Year of creation:
                    <span class="na">-</span>
                    <br />Provenance:
                    <span class="na">-</span>
                    <br />Item code: 60
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-61.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>
                    <span class="na">-</span>
                  </h3>
                  <b>
                    <span class="na">-</span>
                  </b>
                  <div class="price">HKD 40,000.00</div>
                  <div class="otherDetails">
                    1525(W) X 1150(H)
                    <i>(Overall Size)</i>
                    <br />Year of creation:
                    <span class="na">-</span>
                    <br />Provenance:
                    <span class="na">-</span>
                    <br />Item code: 61
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div v-else-if="lang == 'tc'">
            <img
              :src="url + 'promotion/steveleung.jpg'"
              style="width:100%; height:auto; max-width:1500px;"
            />

            <div style="max-width:980px; margin:55px auto;">
              梁志天先生計劃以優惠價出售部分私人珍藏。如有合適的項目，各設計團隊亦可向客戶推介在項目中使用並可以優惠價 9 折購買以下畫作。而買賣過程中所產生的利潤將全數歸相關設計團隊。
              <br />
              <br />如欲了解詳情及索取報價，歡迎各設計團隊與高級招采主任 - 朱嘉儀 Wally 聯繫；各同事如有興趣購買，請下載及填妥
              <a class="fce3" :href="orderFormUrl">訂購表格</a>，並電郵至 Wally (
              <a class="fce3" href="mailto:wally.chu@ed-living.com">wally.chu@ed-living.com</a>)。
              <br />
              <br />


               <div class="download-btn tc">
                <a
                  :href="orderFormUrl"
                  class="btn white"
                >下載訂購表格</a>
              </div>

              <br />
              <i>備註: 上述之畫作均有存貨，並可按需要預留1個月。</i>
            </div>
            <br />

            <h2>畫</h2>

            <ul class="album-list">
              <!--<li>
								<a>
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-01.jpg'">
									</div>
								</a>
								<div>
									<h3>Julia Brooker</h3>
									<b>Bliss <h6>已被預留至2021年11月17日</h6></b>
									<div class="price">HKD 38,000.00</div>
									<div class="otherDetails">
										1250(W) x 1250(H)<br>
										壓克力 鋁板<br>
										創作年份: 2005<br>
										出處: 藝術家<br>
										藝術品編號: 1
									</div>
								</div>
              </li>-->
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-02.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Julia Brooker</h3>
                  <b>Glimpse</b>
                  <div class="price">HKD 38,000.00</div>
                  <div class="otherDetails">
                    1250(W) x 1250(H)
                    <br />壓克力 鋁板
                    <br />創作年份: 2005
                    <br />出處: 藝術家
                    <br />藝術品編號: 2
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-03.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Julia Brooker</h3>
                  <b>Constantinople</b>
                  <div class="price">HKD 38,000.00</div>
                  <div class="otherDetails">
                    1250(W) x 1250(H)
                    <br />壓克力 鋁板
                    <br />創作年份: 2005
                    <br />出處: 藝術家
                    <br />藝術品編號: 3
                  </div>
                </div>
              </li>
            </ul>

            <h2>版畫</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-04.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>趙無極</h3>
                  <b>Lithographie 198 Edition no. 15/90</b>
                  <div class="price">HKD 200,000.00</div>
                  <div class="otherDetails">
                    540(W) x 450(H)
                    <i>(畫芯尺寸)</i>
                    <br />880(W) x 45(D) x 790(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫
                    <br />創作年份: 1968
                    <br />出處: 季普園
                    <br />藝術品編號: 4
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-05.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>應天齊</h3>
                  <b>西遞村系列之十一</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    590(W) x 490(H)
                    <i>(畫芯尺寸)</i>
                    <br />895(W) x 1300(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫 Numbered 23/50
                    <br />創作年份: 1989
                    <br />出處: 季豐軒
                    <br />藝術品編號: 5
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-06.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>應天齊</h3>
                  <b>西遞村系列之八</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    590(W) x 490(H)
                    <i>(畫芯尺寸)</i>
                    <br />895(W) x 1300(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫 Numbered A.P
                    <br />創作年份: 1988
                    <br />出處: 季豐軒
                    <br />藝術品編號: 6
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-07.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>應天齊</h3>
                  <b>西遞村系列之五</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    595(W) x 500(H)
                    <i>(畫芯尺寸)</i>
                    <br />1110(W) x 900(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫 Numbered A.P
                    <br />創作年份: 1988
                    <br />出處: 季豐軒
                    <br />藝術品編號: 7
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-08.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>應天齊</h3>
                  <b>西遞村系列之十九</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    500(W) x 390(H)
                    <i>(畫芯尺寸)</i>
                    <br />895(W) x 1300(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫 Numbered 8/50
                    <br />創作年份: 1989
                    <br />出處: 季豐軒
                    <br />藝術品編號: 8
                  </div>
                </div>
              </li>
              <!--<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-09.jpg'">
									</div>
								</div>
								<div>
									<h3>應天齊</h3>
									<b>重複的西遞村系列之五 <h6>已被預留至2021年11月17日</h6></b>
									<div class="price">HKD 15,000.00</div>
									<div class="otherDetails">
										465(W) x 540(H) <i>(畫芯尺寸)</i><br>
										900(W) x 900(H) <i>(裝裱尺寸)</i><br>
										版畫 Numbered 16/50<br>
										創作年份: 1991<br>
										出處: 季豐軒<br>
										藝術品編號: 9
									</div>
								</div>
              </li>-->
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-10.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>應天齊</h3>
                  <b>重複的西遞村系列之四</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    415(W) x 505(H)
                    <i>(畫芯尺寸)</i>
                    <br />900(W) x 900(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫 Numbered A.P
                    <br />創作年份: 1991
                    <br />出處: 季豐軒
                    <br />藝術品編號: 10
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-11.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>司徒立</h3>
                  <b>畫室中的菜和果</b>
                  <div class="price">HKD 25,000.00</div>
                  <div class="otherDetails">
                    770(W) x 1200(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫 Numbered 26/138
                    <br />創作年份: 2002
                    <br />出處: 季豐軒
                    <br />藝術品編號: 11
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-12.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>司徒立</h3>
                  <b>澄明 II</b>
                  <div class="price">HKD 25,000.00</div>
                  <div class="otherDetails">
                    620(W) x 840(H)
                    <i>(畫芯尺寸)</i>
                    <br />895(W) x 1300(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫 Numbered 26/138
                    <br />創作年份: 2002
                    <br />出處: 季豐軒
                    <br />藝術品編號: 12
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-13.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>司徒立</h3>
                  <b>澄明 II</b>
                  <div class="price">HKD 25,000.00</div>
                  <div class="otherDetails">
                    620(W) x 840(H) *無框
                    <br />版畫 Numbered 11/138
                    <br />創作年份: 2002
                    <br />出處: 季豐軒
                    <br />藝術品編號: 13
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-14.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>胡振宇</h3>
                  <b>時光流逝 Series of 4</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    500(W) x 500(H)
                    <i>(畫芯尺寸)</i>
                    <br />900(W) x 900(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫 Numbered 182/198
                    <br />創作年份: 2002
                    <br />出處: 季豐軒
                    <br />藝術品編號: 14
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-15.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>胡振宇</h3>
                  <b>時光流逝 Series of 4</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    490(W) x 500(H)
                    <i>(畫芯尺寸)</i>
                    <br />900(W) x 900(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫 Numbered 183/198
                    <br />創作年份: 2002
                    <br />出處: 季豐軒
                    <br />藝術品編號: 15
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-16.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>胡振宇</h3>
                  <b>時光流逝 Series of 4</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    500(W) x 500(H)
                    <i>(畫芯尺寸)</i>
                    <br />900(W) x 900(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫 Numbered 183/198
                    <br />創作年份: 2002
                    <br />出處: 季豐軒
                    <br />藝術品編號: 16
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-17.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>胡振宇</h3>
                  <b>時光流逝 Series of 4</b>
                  <div class="price">HKD 12,000.00</div>
                  <div class="otherDetails">
                    500(W) x 500(H)
                    <i>(畫芯尺寸)</i>
                    <br />900(W) x 900(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫 Numbered 182/198
                    <br />創作年份: 2002
                    <br />出處: 季豐軒
                    <br />藝術品編號: 17
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-18.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>郭偉</h3>
                  <b>雲伴</b>
                  <div class="price">HKD 20,000.00</div>
                  <div class="otherDetails">
                    777(W) x 927(H)
                    <i>(畫芯尺寸)</i>
                    <br />1020(W) x 1170(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫 Numbered 117/199
                    <br />創作年份: 2007
                    <br />出處: 季普園
                    <br />藝術品編號: 18
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-19.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>郭偉</h3>
                  <b>雲</b>
                  <div class="price">HKD 20,000.00</div>
                  <div class="otherDetails">
                    775(W) x 880(H)
                    <i>(畫芯尺寸)</i>
                    <br />1020(W) x 1170(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫 Numbered 94/175
                    <br />創作年份: 2007
                    <br />出處: 季普園
                    <br />藝術品編號: 19
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-20.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>郭晉</h3>
                  <b>孩子肖像</b>
                  <div class="price">HKD 20,000.00</div>
                  <div class="otherDetails">
                    597(W) x 1185(H)
                    <i>(畫芯尺寸)</i>
                    <br />820(W) x 1470(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫 Numbered 163/220
                    <br />創作年份: 2008
                    <br />出處: 季普園
                    <br />藝術品編號: 20
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-21.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>王廣義</h3>
                  <b>大批判</b>
                  <div class="price">HKD 28,000.00</div>
                  <div class="otherDetails">
                    1100(W) x 1250(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫
                    <br />創作年份: 2002
                    <br />出處: 季豐軒
                    <br />藝術品編號: 21
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-22.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>王廣義</h3>
                  <b>大批判</b>
                  <div class="price">HKD 18,000.00</div>
                  <div class="otherDetails">
                    940(W) x 1030(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫
                    <br />創作年份: 2002
                    <br />出處: 季豐軒
                    <br />藝術品編號: 22
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-23.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>王廣義</h3>
                  <b>大批判</b>
                  <div class="price">HKD 18,000.00</div>
                  <div class="otherDetails">
                    940(W) x 1030(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫
                    <br />創作年份: 2002
                    <br />出處: 季豐軒
                    <br />藝術品編號: 23
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-24.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>王廣義</h3>
                  <b>信仰的面孔 Series of 4</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    640(W) x 750(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫
                    <br />創作年份: 2002
                    <br />出處: 季豐軒
                    <br />藝術品編號: 24
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-25.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>王廣義</h3>
                  <b>信仰的面孔 Series of 4</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    640(W) x 750(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫
                    <br />創作年份: 2002
                    <br />出處: 季豐軒
                    <br />藝術品編號: 25
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-26.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>王廣義</h3>
                  <b>信仰的面孔 Series of 4</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    640(W) x 750(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫
                    <br />創作年份: 2002
                    <br />出處: 季豐軒
                    <br />藝術品編號: 26
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-27.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>王廣義</h3>
                  <b>信仰的面孔 Series of 4</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    640(W) x 750(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫
                    <br />創作年份: 2002
                    <br />出處: 季豐軒
                    <br />藝術品編號: 27
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-28.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>張曉剛</h3>
                  <b>同志們 Series of 7</b>
                  <div class="price">
                    HKD 50,000.00
                    <i>(1套7件)</i>
                  </div>
                  <div class="otherDetails">
                    650(W) x 745(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫
                    <br />創作年份: 2002
                    <br />出處: 季豐軒
                    <br />藝術品編號: 28
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-29.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>張曉剛</h3>
                  <b>同志們 Series of 7</b>
                  <div class="price">
                    HKD 50,000.00
                    <i>(1套7件)</i>
                  </div>
                  <div class="otherDetails">
                    650(W) x 745(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫
                    <br />創作年份: 2002
                    <br />出處: 季豐軒
                    <br />藝術品編號: 29
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-30.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>張曉剛</h3>
                  <b>同志們 Series of 7</b>
                  <div class="price">
                    HKD 50,000.00
                    <i>(1套7件)</i>
                  </div>
                  <div class="otherDetails">
                    650(W) x 745(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫
                    <br />創作年份: 2002
                    <br />出處: 季豐軒
                    <br />藝術品編號: 30
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-31.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>張曉剛</h3>
                  <b>同志們 Series of 7</b>
                  <div class="price">
                    HKD 50,000.00
                    <i>(1套7件)</i>
                  </div>
                  <div class="otherDetails">
                    650(W) x 745(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫
                    <br />創作年份: 2002
                    <br />出處: 季豐軒
                    <br />藝術品編號: 31
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-32.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>張曉剛</h3>
                  <b>同志們 Series of 7</b>
                  <div class="price">
                    HKD 50,000.00
                    <i>(1套7件)</i>
                  </div>
                  <div class="otherDetails">
                    650(W) x 745(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫
                    <br />創作年份: 2002
                    <br />出處: 季豐軒
                    <br />藝術品編號: 32
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-33.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>張曉剛</h3>
                  <b>同志們 Series of 7</b>
                  <div class="price">
                    HKD 50,000.00
                    <i>(1套7件)</i>
                  </div>
                  <div class="otherDetails">
                    650(W) x 745(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫
                    <br />創作年份: 2002
                    <br />出處: 季豐軒
                    <br />藝術品編號: 33
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-34.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>張曉剛</h3>
                  <b>同志們 Series of 7</b>
                  <div class="price">
                    HKD 50,000.00
                    <i>(1套7件)</i>
                  </div>
                  <div class="otherDetails">
                    650(W) x 745(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫
                    <br />創作年份: 2002
                    <br />出處: 季豐軒
                    <br />藝術品編號: 34
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-35.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>徐累</h3>
                  <b>
                    <span class="na">-</span>
                  </b>
                  <div class="price">HKD 80,000.00</div>
                  <div class="otherDetails">
                    650(W) x 900(H)
                    <i>(畫芯尺寸)</i>
                    <br />805(W) x 1020(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫
                    <br />創作年份:
                    <span class="na">-</span>
                    <br />出處:
                    <span class="na">-</span>
                    <br />藝術品編號: 35
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-37.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>張曉剛</h3>
                  <b>我的女兒</b>
                  <div class="price">HKD 15,000.00</div>
                  <div class="otherDetails">
                    860(W) x 790(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫
                    <br />創作年份: 2002
                    <br />出處: 季豐軒
                    <br />藝術品編號: 37
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-38.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>張曉剛</h3>
                  <b>姊妹</b>
                  <div class="price">HKD 30,000.00</div>
                  <div class="otherDetails">
                    860(W) x 790(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫
                    <br />創作年份: 2003
                    <br />出處: 季豐軒
                    <br />藝術品編號: 38
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-39.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>徐累</h3>
                  <b>白日夢</b>
                  <div class="price">HKD 80,000.00</div>
                  <div class="otherDetails">
                    650(W) x 900(H)
                    <br />版畫 Edition 11/68
                    <br />創作年份: 2007
                    <br />出處: 季豐軒
                    <br />藝術品編號: 39
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-40.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>艾軒</h3>
                  <b>野草石風中搖曳</b>
                  <div class="price">HKD 50,000.00</div>
                  <div class="otherDetails">
                    990(W) x 785(H)
                    <i>(畫芯尺寸)</i>
                    <br />1120(W) x 930(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫 Numbered 184/198
                    <br />創作年份: 2002
                    <br />出處: 季豐軒
                    <br />藝術品編號: 40
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-41.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>金昌烈</h3>
                  <b>
                    <span class="na">-</span>
                  </b>
                  <div class="price">HKD 30,000.00</div>
                  <div class="otherDetails">
                    385(W) x 545(H)
                    <i>(畫芯尺寸)</i>
                    <br />730(W) x 960(H)
                    <i>(裝裱尺寸)</i>
                    <br />版畫 Edition 68/90
                    <br />創作年份:
                    <span class="na">-</span>
                    <br />出處:
                    <span class="na">-</span>
                    <br />藝術品編號: 41
                  </div>
                </div>
              </li>
            </ul>

            <h2>攝影</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-42.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>山地</h3>
                  <b>5/15</b>
                  <div class="price">HKD 40,000.00</div>
                  <div class="otherDetails">
                    740(W) x 1100(H)
                    <i>(畫芯尺寸)</i>
                    <br />965(W) x 1340(H)
                    <i>(裝裱尺寸)</i>
                    <br />攝影
                    <br />創作年份: 2016
                    <br />出處: 柏(北京)國際陳設藝術顧問有限公司
                    <br />藝術品編號: 42
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-43.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>山地</h3>
                  <b>5/15</b>
                  <div class="price">HKD 40,000.00</div>
                  <div class="otherDetails">
                    740(W) x 1100(H)
                    <i>(畫芯尺寸)</i>
                    <br />965(W) x 1340(H)
                    <i>(裝裱尺寸)</i>
                    <br />攝影
                    <br />創作年份: 2015
                    <br />出處: 柏(北京)國際陳設藝術顧問有限公司
                    <br />藝術品編號: 43
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-44.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>山地</h3>
                  <b>5/15</b>
                  <div class="price">HKD 40,000.00</div>
                  <div class="otherDetails">
                    740(W) x 1100(H)
                    <i>(畫芯尺寸)</i>
                    <br />965(W) x 1340(H)
                    <i>(裝裱尺寸)</i>
                    <br />攝影
                    <br />創作年份: 2015
                    <br />出處: 柏(北京)國際陳設藝術顧問有限公司
                    <br />藝術品編號: 44
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-45.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>山地</h3>
                  <b>5/15</b>
                  <div class="price">HKD 40,000.00</div>
                  <div class="otherDetails">
                    740(W) x 1100(H)
                    <i>(畫芯尺寸)</i>
                    <br />965(W) x 1340(H)
                    <i>(裝裱尺寸)</i>
                    <br />攝影
                    <br />創作年份: 2015
                    <br />出處: 柏(北京)國際陳設藝術顧問有限公司
                    <br />藝術品編號: 45
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-46.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>山地</h3>
                  <b>5/15</b>
                  <div class="price">HKD 40,000.00</div>
                  <div class="otherDetails">
                    740(W) x 1100(H)
                    <i>(畫芯尺寸)</i>
                    <br />965(W) x 1340(H)
                    <i>(裝裱尺寸)</i>
                    <br />攝影
                    <br />創作年份: 2015
                    <br />出處: 柏(北京)國際陳設藝術顧問有限公司
                    <br />藝術品編號: 46
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-47.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>山地</h3>
                  <b>5/15</b>
                  <div class="price">HKD 40,000.00</div>
                  <div class="otherDetails">
                    740(W) x 1100(H)
                    <i>(畫芯尺寸)</i>
                    <br />965(W) x 1340(H)
                    <i>(裝裱尺寸)</i>
                    <br />攝影
                    <br />創作年份: 2015
                    <br />出處: 柏(北京)國際陳設藝術顧問有限公司
                    <br />藝術品編號: 47
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-48.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>山地</h3>
                  <b>5/15</b>
                  <div class="price">HKD 40,000.00</div>
                  <div class="otherDetails">
                    740(W) x 1100(H)
                    <i>(畫芯尺寸)</i>
                    <br />965(W) x 1340(H)
                    <i>(裝裱尺寸)</i>
                    <br />攝影
                    <br />創作年份: 2015
                    <br />出處: 柏(北京)國際陳設藝術顧問有限公司
                    <br />藝術品編號: 48
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-49.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>山地</h3>
                  <b>5/15</b>
                  <div class="price">HKD 40,000.00</div>
                  <div class="otherDetails">
                    740(W) x 1100(H)
                    <i>(畫芯尺寸)</i>
                    <br />965(W) x 1340(H)
                    <i>(裝裱尺寸)</i>
                    <br />攝影
                    <br />創作年份: 2015
                    <br />出處: 柏(北京)國際陳設藝術顧問有限公司
                    <br />藝術品編號: 49
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-50.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>山地</h3>
                  <b>5/15</b>
                  <div class="price">HKD 40,000.00</div>
                  <div class="otherDetails">
                    740(W) x 1100(H)
                    <i>(畫芯尺寸)</i>
                    <br />965(W) x 1340(H)
                    <i>(裝裱尺寸)</i>
                    <br />攝影
                    <br />創作年份: 2015
                    <br />出處: 柏(北京)國際陳設藝術顧問有限公司
                    <br />藝術品編號: 50
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-51.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>山地</h3>
                  <b>5/15</b>
                  <div class="price">HKD 40,000.00</div>
                  <div class="otherDetails">
                    740(W) x 1100(H)
                    <i>(畫芯尺寸)</i>
                    <br />965(W) x 1340(H)
                    <i>(裝裱尺寸)</i>
                    <br />攝影
                    <br />創作年份: 2015
                    <br />出處: 柏(北京)國際陳設藝術顧問有限公司
                    <br />藝術品編號: 51
                  </div>
                </div>
              </li>
            </ul>

            <h2>綜合媒材</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-52.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Annette Merrild</h3>
                  <b>Sculptural Painting</b>
                  <div class="price">HKD 220,000.00</div>
                  <div class="otherDetails">
                    1480(W) x 2000(H)
                    <br />綜合媒材 [相片]。獨有
                    <br />創作年份: 2014
                    <br />出處: Ibiza Art Projects SL.
                    <br />藝術品編號: 52
                  </div>
                </div>
              </li>
            </ul>

            <h2>其他</h2>

            <ul class="album-list">
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-53.jpg'" />
                  </div>
                </div>

                <div>
                  <h3>馬塞爾</h3>
                  <b>
                    <span class="na">-</span>
                  </b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    365(W) x 490(H)
                    <i>(裝裱尺寸)</i>
                    <br />創作年份:
                    <span class="na">-</span>
                    <br />出處:
                    <span class="na">-</span>
                    <br />藝術品編號: 53
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-54.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>馬塞爾</h3>
                  <b>
                    <span class="na">-</span>
                  </b>
                  <div class="price">HKD 10,000.00</div>
                  <div class="otherDetails">
                    365(W) x 490(H)
                    <i>(裝裱尺寸)</i>
                    <br />創作年份:
                    <span class="na">-</span>
                    <br />出處:
                    <span class="na">-</span>
                    <br />藝術品編號: 54
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-55.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>趙無極</h3>
                  <b>
                    <span class="na">-</span>
                  </b>
                  <div class="price">HKD 250,000.00</div>
                  <div class="otherDetails">
                    790(W) x 990(H)
                    <br />咭紙偏米色不是白色
                    <br />創作年份:
                    <span class="na">-</span>
                    <br />出處:
                    <span class="na">-</span>
                    <br />藝術品編號: 55
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-56.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Sarah Brand</h3>
                  <b>
                    <span class="na">-</span>
                  </b>
                  <div class="price">HKD 30,000.00</div>
                  <div class="otherDetails">
                    400(W) x 1200(H)
                    <i>(裝裱尺寸)</i>
                    <br />創作年份:
                    <span class="na">-</span>
                    <br />出處:
                    <span class="na">-</span>
                    <br />藝術品編號: 56
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-57.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>
                    <span class="na">-</span>
                  </h3>
                  <b>
                    <span class="na">-</span>
                  </b>
                  <div class="price">HKD 30,000.00</div>
                  <div class="otherDetails">
                    1320(W) x 1165(H)
                    <i>(裝裱尺寸)</i>
                    <br />創作年份:
                    <span class="na">-</span>
                    <br />出處:
                    <span class="na">-</span>
                    <br />藝術品編號: 57
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-58.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Le Corbusier</h3>
                  <b>
                    <span class="na">-</span>
                  </b>
                  <div class="price">HKD 50,000.00</div>
                  <div class="otherDetails">
                    940(W) x 1050(H)
                    <i>(裝裱尺寸)</i>
                    <br />創作年份:
                    <span class="na">-</span>
                    <br />出處:
                    <span class="na">-</span>
                    <br />藝術品編號: 58
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-59.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>Aldo Rossi</h3>
                  <b>
                    <span class="na">-</span>
                  </b>
                  <div class="price">HKD 100,000.00</div>
                  <div class="otherDetails">
                    1430(W) x 1030(H)
                    <i>(裝裱尺寸)</i>
                    <br />創作年份:
                    <span class="na">-</span>
                    <br />出處:
                    <span class="na">-</span>
                    <br />藝術品編號: 59
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-60.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>趙無極</h3>
                  <b>
                    <span class="na">-</span>
                  </b>
                  <div class="price">HKD 180,000.00</div>
                  <div class="otherDetails">
                    580(W) X 440(H)
                    <i>(畫芯尺寸)</i>
                    <br />880(W) x 740(H)
                    <i>(裝裱尺寸)</i>
                    <br />Numbered 55/99
                    <br />咭紙偏米色不是白色
                    <br />創作年份:
                    <span class="na">-</span>
                    <br />出處:
                    <span class="na">-</span>
                    <br />藝術品編號: 60
                  </div>
                </div>
              </li>
              <li>
                <div class="img-top mgb27">
                  <div class="mood-img">
                    <img class="b-lazy" :src="url + 'promotion/steveleung-61.jpg'" />
                  </div>
                </div>
                <div>
                  <h3>
                    <span class="na">-</span>
                  </h3>
                  <b>
                    <span class="na">-</span>
                  </b>
                  <div class="price">HKD 40,000.00</div>
                  <div class="otherDetails">
                    1525(W) X 1150(H)
                    <i>(裝裱尺寸)</i>
                    <br />創作年份:
                    <span class="na">-</span>
                    <br />出處:
                    <span class="na">-</span>
                    <br />藝術品編號: 61
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div v-else>
						<img :src="url + 'promotion/steveleung.jpg'" style="width:100%; height:auto; max-width:1500px;">

						<div style="max-width:980px; margin:55px auto;">
							梁志天先生计划以优惠价出售部分私人珍藏。如有合适的项目，各设计团队亦可向客户推介在项目中使用并可以优惠价 9 折购买以下画作。而买卖过程中所产生的利润将全数归相关设计团队。
							<br><br>
							如欲了解详情及索取报价，欢迎各设计团队与高级招采主任 - 朱嘉仪 Wally 联系；各同事如有兴趣购买，请下载及填妥<a class="fce3" :href="orderFormUrl">订购表格</a>，并电邮至 Wally (<a class="fce3" href="mailto:wally.chu@ed-living.com">wally.chu@ed-living.com</a>)。
							<br><br>

							<div class="tc download-btn">
								<a  :href="orderFormUrl" class="btn  white">下载订购表格</a>
							</div>

							<br>
							<i>备注: 上述之画作均有存货，并可按需要预留1个月。</i>

						</div>
						<br>

						<h2>画</h2>

						<ul class="album-list">
							<!--<li>
								<a>
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-01.jpg'">
									</div>
								</a>
								<div>
									<h3>Julia Brooker</h3>
									<b>Bliss <h6>已被预留至2021年11月17日</h6></b>
									<div class="price">HKD 38,000.00</div>
									<div class="otherDetails">
										1250(W) x 1250(H)<br>
										压克力 铝板<br>
										创作年份: 2005<br>
										出处: 艺术家<br>
										艺术品编号: 1
									</div>
								</div>
							</li>-->
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-02.jpg'">
									</div>
								</div>
								<div>
									<h3>Julia Brooker</h3>
									<b>Glimpse</b>
									<div class="price">HKD 38,000.00</div>
									<div class="otherDetails">
										1250(W) x 1250(H)<br>
										压克力 铝板<br>
										创作年份: 2005<br>
										出处: 艺术家<br>
										艺术品编号: 2
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-03.jpg'">
									</div>
								</div>
								<div>
									<h3>Julia Brooker</h3>
									<b>Constantinople</b>
									<div class="price">HKD 38,000.00</div>
									<div class="otherDetails">
										1250(W) x 1250(H)<br>
										压克力 铝板<br>
										创作年份: 2005<br>
										出处: 艺术家<br>
										艺术品编号: 3
									</div>
								</div>
							</li>
						</ul>

						<h2>版画</h2>

						<ul class="album-list">
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-04.jpg'">
									</div>
								</div>
								<div>
									<h3>赵无极</h3>
									<b>Lithographie 198 Edition no. 15/90</b>
									<div class="price">HKD 200,000.00</div>
									<div class="otherDetails">
										540(W) x 450(H) <i>(画芯尺寸)</i><br>
										880(W) x 45(D) x 790(H) <i>(装裱尺寸)</i><br>
										版画<br>
										创作年份: 1968<br>
										出处: 季普园<br>
										艺术品编号: 4
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-05.jpg'">
									</div>
								</div>
								<div>
									<h3>应天齐</h3>
									<b>西递村系列之十一</b>
									<div class="price">HKD 15,000.00</div>
									<div class="otherDetails">
										590(W) x 490(H) <i>(画芯尺寸)</i><br>
										895(W) x 1300(H) <i>(装裱尺寸)</i><br>
										版画 Numbered 23/50<br>
										创作年份: 1989<br>
										出处: 季丰轩<br>
										艺术品编号: 5
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-06.jpg'">
									</div>
								</div>
								<div>
									<h3>应天齐</h3>
									<b>西递村系列之八</b>
									<div class="price">HKD 15,000.00</div>
									<div class="otherDetails">
										590(W) x 490(H) <i>(画芯尺寸)</i><br>
										895(W) x 1300(H) <i>(装裱尺寸)</i><br>
										版画 Numbered A.P<br>
										创作年份: 1988<br>
										出处: 季丰轩<br>
										艺术品编号: 6


									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-07.jpg'">
									</div>
								</div>
								<div>
									<h3>应天齐</h3>
									<b>西递村系列之五</b>
									<div class="price">HKD 15,000.00</div>
									<div class="otherDetails">
										595(W) x 500(H) <i>(画芯尺寸)</i><br>
										1110(W) x 900(H) <i>(装裱尺寸)</i><br>
										版画 Numbered A.P<br>
										创作年份: 1988<br>
										出处: 季丰轩<br>
										艺术品编号: 7
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-08.jpg'">
									</div>
								</div>
								<div>
									<h3>应天齐</h3>
									<b>西递村系列之十九</b>
									<div class="price">HKD 15,000.00</div>
									<div class="otherDetails">
										500(W) x 390(H) <i>(画芯尺寸)</i><br>
										895(W) x 1300(H) <i>(装裱尺寸)</i><br>
										版画 Numbered 8/50<br>
										创作年份: 1989<br>
										出处: 季丰轩<br>
										艺术品编号: 8
									</div>
								</div>
							</li>
							<!--<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-09.jpg'">
									</div>
								</div>
								<div>
									<h3>应天齐</h3>
									<b>重复的西递村系列之五 <h6>已被预留至2021年11月17日</h6></b>
									<div class="price">HKD 15,000.00</div>
									<div class="otherDetails">
										465(W) x 540(H) <i>(画芯尺寸)</i><br>
										900(W) x 900(H) <i>(装裱尺寸)</i><br>
										版画 Numbered 16/50<br>
										创作年份: 1991<br>
										出处: 季丰轩<br>
										艺术品编号: 9
									</div>
								</div>
							</li>-->
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-10.jpg'">
									</div>
								</div>
								<div>
									<h3>应天齐</h3>
									<b>重复的西递村系列之四</b>
									<div class="price">HKD 15,000.00</div>
									<div class="otherDetails">
										415(W) x 505(H) <i>(画芯尺寸)</i><br>
										900(W) x 900(H) <i>(装裱尺寸)</i><br>
										版画 Numbered A.P<br>
										创作年份: 1991<br>
										出处: 季丰轩<br>
										艺术品编号: 10
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-11.jpg'">
									</div>
								</div>
								<div>
									<h3>司徒立</h3>
									<b>画室中的菜和果</b>
									<div class="price">HKD 25,000.00</div>
									<div class="otherDetails">
										770(W) x 1200(H) <i>(装裱尺寸)</i><br>
										版画 Numbered 26/138<br>
										创作年份: 2002<br>
										出处: 季丰轩<br>
										艺术品编号: 11
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-12.jpg'">
									</div>
								</div>
								<div>
									<h3>司徒立</h3>
									<b>澄明 II</b>
									<div class="price">HKD 25,000.00</div>
									<div class="otherDetails">
										620(W) x 840(H) <i>(画芯尺寸)</i><br>
										895(W) x 1300(H) <i>(装裱尺寸)</i><br>
										版画 Numbered 26/138<br>
										创作年份: 2002<br>
										出处: 季丰轩<br>
										艺术品编号: 12
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-13.jpg'">
									</div>
								</div>
								<div>
									<h3>司徒立</h3>
									<b>澄明 II</b>
									<div class="price">HKD 25,000.00</div>
									<div class="otherDetails">
										620(W) x 840(H) *无框<br>
										版画 Numbered 11/138<br>
										创作年份: 2002<br>
										出处: 季丰轩<br>
										艺术品编号: 13
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-14.jpg'">
									</div>
								</div>
								<div>
									<h3>胡振宇</h3>
									<b>时光流逝 Series of 4</b>
									<div class="price">HKD 12,000.00</div>
									<div class="otherDetails">
										500(W) x 500(H) <i>(画芯尺寸)</i><br>
										900(W) x 900(H) <i>(装裱尺寸)</i><br>
										版画 Numbered 182/198<br>
										创作年份: 2002<br>
										出处: 季丰轩<br>
										艺术品编号: 14
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-15.jpg'">
									</div>
								</div>
								<div>
									<h3>胡振宇</h3>
									<b>时光流逝 Series of 4</b>
									<div class="price">HKD 12,000.00</div>
									<div class="otherDetails">
										490(W) x 500(H) <i>(画芯尺寸)</i><br>
										900(W) x 900(H) <i>(装裱尺寸)</i><br>
										版画 Numbered 183/198<br>
										创作年份: 2002<br>
										出处: 季丰轩<br>
										艺术品编号: 15
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-16.jpg'">
									</div>
								</div>
								<div>
									<h3>胡振宇</h3>
									<b>时光流逝 Series of 4</b>
									<div class="price">HKD 12,000.00</div>
									<div class="otherDetails">
										500(W) x 500(H) <i>(画芯尺寸)</i><br>
										900(W) x 900(H) <i>(装裱尺寸)</i><br>
										版画 Numbered 183/198<br>
										创作年份: 2002<br>
										出处: 季丰轩<br>
										艺术品编号: 16
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-17.jpg'">
									</div>
								</div>
								<div>
									<h3>胡振宇</h3>
									<b>时光流逝 Series of 4</b>
									<div class="price">HKD 12,000.00</div>
									<div class="otherDetails">
										500(W) x 500(H) <i>(画芯尺寸)</i><br>
										900(W) x 900(H) <i>(装裱尺寸)</i><br>
										版画 Numbered 182/198<br>
										创作年份: 2002<br>
										出处: 季丰轩<br>
										艺术品编号: 17
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-18.jpg'">
									</div>
								</div>
								<div>
									<h3>郭伟</h3>
									<b>云伴</b>
									<div class="price">HKD 20,000.00</div>
									<div class="otherDetails">
										777(W) x 927(H) <i>(画芯尺寸)</i><br>
										1020(W) x 1170(H) <i>(装裱尺寸)</i><br>
										版画 Numbered 117/199<br>
										创作年份: 2007<br>
										出处: 季普园<br>
										艺术品编号: 18
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-19.jpg'">
									</div>
								</div>
								<div>
									<h3>郭伟</h3>
									<b>云</b>
									<div class="price">HKD 20,000.00</div>
									<div class="otherDetails">
										775(W) x 880(H) <i>(画芯尺寸)</i><br>
										1020(W) x 1170(H) <i>(装裱尺寸)</i><br>
										版画 Numbered 94/175<br>
										创作年份: 2007<br>
										出处: 季普园<br>
										艺术品编号: 19
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-20.jpg'">
									</div>
								</div>
								<div>
									<h3>郭晋</h3>
									<b>孩子肖像</b>
									<div class="price">HKD 20,000.00</div>
									<div class="otherDetails">
										597(W) x 1185(H) <i>(画芯尺寸)</i><br>
										820(W) x 1470(H) <i>(装裱尺寸)</i><br>
										版画 Numbered 163/220<br>
										创作年份: 2008<br>
										出处: 季普园<br>
										艺术品编号: 20
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-21.jpg'">
									</div>
								</div>
								<div>
									<h3>王广义</h3>
									<b>大批判</b>
									<div class="price">HKD 28,000.00</div>
									<div class="otherDetails">
										1100(W) x 1250(H) <i>(装裱尺寸)</i><br>

										版画<br>
										创作年份: 2002<br>
										出处: 季丰轩<br>
										艺术品编号: 21
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-22.jpg'">
									</div>
								</div>
								<div>
									<h3>王广义</h3>
									<b>大批判</b>
									<div class="price">HKD 18,000.00</div>
									<div class="otherDetails">
										940(W) x 1030(H) <i>(装裱尺寸)</i><br>
										版画<br>
										创作年份: 2002<br>
										出处: 季丰轩<br>
										艺术品编号: 22
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-23.jpg'">
									</div>
								</div>
								<div>
									<h3>王广义</h3>
									<b>大批判</b>
									<div class="price">HKD 18,000.00</div>
									<div class="otherDetails">
										940(W) x 1030(H) <i>(装裱尺寸)</i><br>
										版画<br>
										创作年份: 2002<br>
										出处: 季丰轩<br>
										艺术品编号: 23
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-24.jpg'">
									</div>
								</div>
								<div>
									<h3>王广义</h3>
									<b>信仰的面孔 Series of 4</b>
									<div class="price">HKD 15,000.00</div>
									<div class="otherDetails">
										640(W) x 750(H) <i>(装裱尺寸)</i><br>
										版画<br>
										创作年份: 2002<br>
										出处: 季丰轩<br>
										艺术品编号: 24
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-25.jpg'">
									</div>
								</div>
								<div>
									<h3>王广义</h3>
									<b>信仰的面孔 Series of 4</b>
									<div class="price">HKD 15,000.00</div>
									<div class="otherDetails">
										640(W) x 750(H) <i>(装裱尺寸)</i><br>
										版画<br>
										创作年份: 2002<br>
										出处: 季丰轩<br>
										艺术品编号: 25
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-26.jpg'">
									</div>
								</div>
								<div>
									<h3>王广义</h3>
									<b>信仰的面孔 Series of 4</b>
									<div class="price">HKD 15,000.00</div>
									<div class="otherDetails">
										640(W) x 750(H) <i>(装裱尺寸)</i><br>
										版画<br>
										创作年份: 2002<br>
										出处: 季丰轩<br>
										艺术品编号: 26
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-27.jpg'">
									</div>
								</div>
								<div>
									<h3>王广义</h3>
									<b>信仰的面孔 Series of 4</b>
									<div class="price">HKD 15,000.00</div>
									<div class="otherDetails">
										640(W) x 750(H) <i>(装裱尺寸)</i><br>
										版画<br>
										创作年份: 2002<br>
										出处: 季丰轩<br>
										艺术品编号: 27
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-28.jpg'">
									</div>
								</div>
								<div>
									<h3>张晓刚</h3>
									<b>同志们 Series of 7</b>
									<div class="price">HKD 50,000.00 <i>(1套7件)</i></div>
									<div class="otherDetails">
										650(W) x 745(H) <i>(装裱尺寸)</i><br>
										版画<br>
										创作年份: 2002<br>
										出处: 季丰轩<br>
										艺术品编号: 28
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-29.jpg'">
									</div>
								</div>
								<div>
									<h3>张晓刚</h3>
									<b>同志们 Series of 7</b>
									<div class="price">HKD 50,000.00 <i>(1套7件)</i></div>
									<div class="otherDetails">
										650(W) x 745(H) <i>(装裱尺寸)</i><br>
										版画<br>

										创作年份: 2002<br>
										出处: 季丰轩<br>
										艺术品编号: 29
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-30.jpg'">
									</div>
								</div>
								<div>
									<h3>张晓刚</h3>
									<b>同志们 Series of 7</b>
									<div class="price">HKD 50,000.00 <i>(1套7件)</i></div>
									<div class="otherDetails">
										650(W) x 745(H) <i>(装裱尺寸)</i><br>
										版画<br>
										创作年份: 2002<br>
										出处: 季丰轩<br>
										艺术品编号: 30
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-31.jpg'">
									</div>
								</div>
								<div>
									<h3>张晓刚</h3>
									<b>同志们 Series of 7</b>
									<div class="price">HKD 50,000.00 <i>(1套7件)</i></div>
									<div class="otherDetails">
										650(W) x 745(H) <i>(装裱尺寸)</i><br>
										版画<br>
										创作年份: 2002<br>
										出处: 季丰轩<br>
										艺术品编号: 31
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-32.jpg'">
									</div>
								</div>
								<div>
									<h3>张晓刚</h3>
									<b>同志们 Series of 7</b>
									<div class="price">HKD 50,000.00 <i>(1套7件)</i></div>
									<div class="otherDetails">
										650(W) x 745(H) <i>(装裱尺寸)</i><br>
										版画<br>
										创作年份: 2002<br>
										出处: 季丰轩<br>
										艺术品编号: 32
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-33.jpg'">
									</div>
								</div>
								<div>
									<h3>张晓刚</h3>
									<b>同志们 Series of 7</b>
									<div class="price">HKD 50,000.00 <i>(1套7件)</i></div>
									<div class="otherDetails">
										650(W) x 745(H) <i>(装裱尺寸)</i><br>
										版画<br>
										创作年份: 2002<br>
										出处: 季丰轩<br>
										艺术品编号: 33
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-34.jpg'">
									</div>
								</div>
								<div>
									<h3>张晓刚</h3>
									<b>同志们 Series of 7</b>
									<div class="price">HKD 50,000.00 <i>(1套7件)</i></div>
									<div class="otherDetails">
										650(W) x 745(H) <i>(装裱尺寸)</i><br>
										版画<br>
										创作年份: 2002<br>
										出处: 季丰轩<br>
										艺术品编号: 34
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-35.jpg'">
									</div>
								</div>
								<div>
									<h3>徐累</h3>
									<b><span class="na">-</span></b>
									<div class="price">HKD 80,000.00</div>
									<div class="otherDetails">
										650(W) x 900(H) <i>(画芯尺寸)</i><br>
										805(W) x 1020(H) <i>(装裱尺寸)</i><br>
										版画<br>
										创作年份: <span class="na">-</span><br>
										出处: <span class="na">-</span><br>
										艺术品编号: 35
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-37.jpg'">
									</div>
								</div>
								<div>
									<h3>张晓刚</h3>
									<b>我的女儿</b>
									<div class="price">HKD 15,000.00</div>
									<div class="otherDetails">
										860(W) x 790(H) <i>(装裱尺寸)</i><br>
										版画<br>
										创作年份: 2002<br>
										出处: 季丰轩<br>
										艺术品编号: 37
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-38.jpg'">
									</div>
								</div>
								<div>
									<h3>张晓刚</h3>
									<b>姊妹</b>
									<div class="price">HKD 30,000.00</div>
									<div class="otherDetails">
										860(W) x 790(H) <i>(装裱尺寸)</i><br>
										版画<br>
										创作年份: 2003<br>
										出处: 季丰轩<br>
										艺术品编号: 38
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-39.jpg'">
									</div>
								</div>
								<div>
									<h3>徐累</h3>
									<b>白日梦</b>
									<div class="price">HKD 80,000.00</div>
									<div class="otherDetails">
										650(W) x 900(H)<br>
										版画 Edition 11/68<br>
										创作年份: 2007<br>
										出处: 季丰轩<br>
										艺术品编号: 39
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-40.jpg'">
									</div>
								</div>
								<div>
									<h3>艾轩</h3>
									<b>野草石风中摇曳</b>
									<div class="price">HKD 50,000.00</div>
									<div class="otherDetails">
										990(W) x 785(H) <i>(画芯尺寸)</i><br>
										1120(W) x 930(H) <i>(装裱尺寸)</i><br>
										版画 Numbered 184/198<br>
										创作年份: 2002<br>
										出处: 季丰轩<br>
										艺术品编号: 40
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-41.jpg'">
									</div>
								</div>
								<div>
									<h3>金昌烈</h3>
									<b><span class="na">-</span></b>
									<div class="price">HKD 30,000.00</div>
									<div class="otherDetails">
										385(W) x 545(H) <i>(画芯尺寸)</i><br>
										730(W) x 960(H) <i>(装裱尺寸)</i><br>
										版画 Edition 68/90<br>
										创作年份: <span class="na">-</span><br>
										出处: <span class="na">-</span><br>
										艺术品编号: 41
									</div>
								</div>
							</li>
						</ul>

						<h2>摄影</h2>

						<ul class="album-list">
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-42.jpg'">
									</div>
								</div>
								<div>
									<h3>山地</h3>
									<b>5/15</b>
									<div class="price">HKD 40,000.00</div>
									<div class="otherDetails">
										740(W) x 1100(H) <i>(画芯尺寸)</i><br>
										965(W) x 1340(H) <i>(装裱尺寸)</i><br>
										摄影<br>
										创作年份: 2016<br>
										出处: 柏(北京)国际陈设艺术顾问有限公司<br>
										艺术品编号: 42
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-43.jpg'">
									</div>
								</div>
								<div>
									<h3>山地</h3>
									<b>5/15</b>
									<div class="price">HKD 40,000.00</div>
									<div class="otherDetails">
										740(W) x 1100(H) <i>(画芯尺寸)</i><br>
										965(W) x 1340(H) <i>(装裱尺寸)</i><br>
										摄影<br>
										创作年份: 2015<br>
										出处: 柏(北京)国际陈设艺术顾问有限公司<br>
										艺术品编号: 43
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-44.jpg'">
									</div>
								</div>
								<div>
									<h3>山地</h3>
									<b>5/15</b>
									<div class="price">HKD 40,000.00</div>
									<div class="otherDetails">
										740(W) x 1100(H) <i>(画芯尺寸)</i><br>
										965(W) x 1340(H) <i>(装裱尺寸)</i><br>
										摄影<br>
										创作年份: 2015<br>
										出处: 柏(北京)国际陈设艺术顾问有限公司<br>
										艺术品编号: 44
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-45.jpg'">
									</div>
								</div>
								<div>
									<h3>山地</h3>
									<b>5/15</b>
									<div class="price">HKD 40,000.00</div>
									<div class="otherDetails">
										740(W) x 1100(H) <i>(画芯尺寸)</i><br>
										965(W) x 1340(H) <i>(装裱尺寸)</i><br>
										摄影<br>
										创作年份: 2015<br>
										出处: 柏(北京)国际陈设艺术顾问有限公司<br>
										艺术品编号: 45
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-46.jpg'">
									</div>
								</div>
								<div>
									<h3>山地</h3>
									<b>5/15</b>
									<div class="price">HKD 40,000.00</div>
									<div class="otherDetails">
										740(W) x 1100(H) <i>(画芯尺寸)</i><br>
										965(W) x 1340(H) <i>(装裱尺寸)</i><br>
										摄影<br>
										创作年份: 2015<br>
										出处: 柏(北京)国际陈设艺术顾问有限公司<br>
										艺术品编号: 46
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-47.jpg'">
									</div>
								</div>
								<div>
									<h3>山地</h3>
									<b>5/15</b>
									<div class="price">HKD 40,000.00</div>
									<div class="otherDetails">
										740(W) x 1100(H) <i>(画芯尺寸)</i><br>
										965(W) x 1340(H) <i>(装裱尺寸)</i><br>
										摄影<br>
										创作年份: 2015<br>
										出处: 柏(北京)国际陈设艺术顾问有限公司<br>
										艺术品编号: 47
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-48.jpg'">
									</div>
								</div>
								<div>
									<h3>山地</h3>
									<b>5/15</b>
									<div class="price">HKD 40,000.00</div>
									<div class="otherDetails">
										740(W) x 1100(H) <i>(画芯尺寸)</i><br>
										965(W) x 1340(H) <i>(装裱尺寸)</i><br>
										摄影<br>
										创作年份: 2015<br>
										出处: 柏(北京)国际陈设艺术顾问有限公司<br>
										艺术品编号: 48
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-49.jpg'">
									</div>
								</div>
								<div>
									<h3>山地</h3>
									<b>5/15</b>
									<div class="price">HKD 40,000.00</div>
									<div class="otherDetails">
										740(W) x 1100(H) <i>(画芯尺寸)</i><br>
										965(W) x 1340(H) <i>(装裱尺寸)</i><br>
										摄影<br>
										创作年份: 2015<br>
										出处: 柏(北京)国际陈设艺术顾问有限公司<br>
										艺术品编号: 49
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-50.jpg'">
									</div>
								</div>
								<div>
									<h3>山地</h3>
									<b>5/15</b>
									<div class="price">HKD 40,000.00</div>
									<div class="otherDetails">
										740(W) x 1100(H) <i>(画芯尺寸)</i><br>
										965(W) x 1340(H) <i>(装裱尺寸)</i><br>
										摄影<br>
										创作年份: 2015<br>
										出处: 柏(北京)国际陈设艺术顾问有限公司<br>
										艺术品编号: 50
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-51.jpg'">
									</div>
								</div>
								<div>
									<h3>山地</h3>
									<b>5/15</b>
									<div class="price">HKD 40,000.00</div>
									<div class="otherDetails">
										740(W) x 1100(H) <i>(画芯尺寸)</i><br>
										965(W) x 1340(H) <i>(装裱尺寸)</i><br>
										摄影<br>
										创作年份: 2015<br>
										出处: 柏(北京)国际陈设艺术顾问有限公司<br>
										艺术品编号: 51
									</div>
								</div>
							</li>
						</ul>

						<h2>综合媒材</h2>

						<ul class="album-list">
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-52.jpg'">
									</div>
								</div>
								<div>
									<h3>Annette Merrild</h3>
									<b>Sculptural Painting</b>
									<div class="price">HKD 220,000.00</div>
									<div class="otherDetails">
										1480(W) x 2000(H)<br>
										综合媒材 [相片] 。独有<br>
										创作年份: 2014<br>
										出处: Ibiza Art Projects SL.<br>
										艺术品编号: 52
									</div>
								</div>
							</li>
						</ul>


						<h2>其他</h2>

						<ul class="album-list">
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-53.jpg'">
									</div>
								</div>

								<div>
									<h3>马塞尔</h3>
									<b><span class="na">-</span></b>
									<div class="price">HKD 10,000.00</div>
									<div class="otherDetails">
										365(W) x 490(H) <i>(装裱尺寸)</i><br>
										创作年份: <span class="na">-</span><br>
										出处: <span class="na">-</span><br>
										艺术品编号: 53
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-54.jpg'">
									</div>
								</div>
								<div>
									<h3>马塞尔</h3>
									<b><span class="na">-</span></b>
									<div class="price">HKD 10,000.00</div>
									<div class="otherDetails">
										365(W) x 490(H) <i>(装裱尺寸)</i><br>
										创作年份: <span class="na">-</span><br>
										出处: <span class="na">-</span><br>
										艺术品编号: 54
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-55.jpg'">
									</div>
								</div>
								<div>
									<h3>赵无极</h3>
									<b><span class="na">-</span></b>
									<div class="price">HKD 250,000.00</div>
									<div class="otherDetails">
										790(W) x 990(H)<br>
										咭纸偏米色不是白色<br>
										创作年份: <span class="na">-</span><br>
										出处: <span class="na">-</span><br>
										艺术品编号: 55
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-56.jpg'">
									</div>
								</div>
								<div>
									<h3>Sarah Brand</h3>
									<b><span class="na">-</span></b>
									<div class="price">HKD 30,000.00</div>
									<div class="otherDetails">
										400(W) x 1200(H) <i>(装裱尺寸)</i><br>
										创作年份: <span class="na">-</span><br>
										出处: <span class="na">-</span><br>
										艺术品编号: 56
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-57.jpg'">
									</div>
								</div>
								<div>
									<h3><span class="na">-</span></h3>
									<b><span class="na">-</span></b>
									<div class="price">HKD 30,000.00</div>
									<div class="otherDetails">
										1320(W) x 1165(H) <i>(装裱尺寸)</i><br>
										创作年份: <span class="na">-</span><br>
										出处: <span class="na">-</span><br>
										艺术品编号: 57
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-58.jpg'">
									</div>
								</div>
								<div>
									<h3>Le Corbusier</h3>
									<b><span class="na">-</span></b>
									<div class="price">HKD 50,000.00</div>
									<div class="otherDetails">
										940(W) x 1050(H) <i>(装裱尺寸)</i><br>
										创作年份: <span class="na">-</span><br>
										出处: <span class="na">-</span><br>
										艺术品编号: 58
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-59.jpg'">
									</div>
								</div>
								<div>
									<h3>Aldo Rossi</h3>
									<b><span class="na">-</span></b>
									<div class="price">HKD 100,000.00</div>
									<div class="otherDetails">
										1430(W) x 1030(H) <i>(装裱尺寸)</i><br>
										创作年份: <span class="na">-</span><br>
										出处: <span class="na">-</span><br>
										艺术品编号: 59
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-60.jpg'">
									</div>
								</div>
								<div>
									<h3>赵无极</h3>
									<b><span class="na">-</span></b>
									<div class="price">HKD 180,000.00</div>
									<div class="otherDetails">
										580(W) X 440(H) <i>(画芯尺寸)</i><br>
										880(W) x 740(H) <i>(装裱尺寸)</i><br>
										Numbered 55/99<br>
										咭纸偏米色不是白色<br>
										创作年份: <span class="na">-</span><br>
										出处: <span class="na">-</span><br>
										艺术品编号: 60
									</div>
								</div>
							</li>
							<li>
								<div class="img-top mgb27">
									<div class="mood-img">
										<img class="b-lazy" :src="url + 'promotion/steveleung-61.jpg'">
									</div>
								</div>
								<div>
									<h3><span class="na">-</span></h3>
									<b><span class="na">-</span></b>
									<div class="price">HKD 40,000.00</div>
									<div class="otherDetails">
										1525(W) X 1150(H) <i>(装裱尺寸)</i><br>
										创作年份: <span class="na">-</span><br>
										出处: <span class="na">-</span><br>
										艺术品编号: 61
									</div>
								</div>
							</li>
						</ul>

					</div>
        </div>

      </b-col>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: "sc",
      url: process.env.VUE_APP_IMG_URL,
      orderFormUrl: this.url + "/promotion/SLART_Internal_Order_Form.xlsx",
    };
  },
  created() {
    this.lang =  localStorage.getItem('language');
  },

  methods: {
    goPage(catid, id) {
      this.$router.push(
        `/supplier-cats?catId=${catid}&id=${id}&name=${this.cattitle}`
      );
    }
  }
};
</script>

<style lang="scss" scoped>
	@import "@/assets/style/scss/promotion.scss";
</style>
