<template>
  <div>
    <el-dialog
      class="upload-dialog"
      :visible.sync="Dialog"
      width="73%"
      top="0"
      @close="diaClose"
    >
      <div class="catalog_details">
        <div class="pdp_info mgb18">
          <div class="pdp_title pdb10">
            <div id="selectoption"></div>
            <div class="ft21 bold tc pdt6 pdb6">直營/經銷商</div>
          </div>
        </div>
        <div class="search-info">
          <b-form-input
            id="q"
            class="input"
            v-model="searchVal"
            @change="inputChange"
            :placeholder="$t('common.headsearch')"
          ></b-form-input>
          <b-button
            v-if="searchVal"
            class="btn-default fa fa-times search-reset"
            @click="cleanChange"
          ></b-button>
          <b-button
            class="btn-default btn-search fa fa-search"
            @click="searchChange"
          ></b-button>
        </div>
        <b-table-simple
          bordered
          class="store-table"
          style="margin-bottom: 38px"
          v-for="(item, index) in area"
          v-show="shops[item].length > 0 && item"
          :key="'store'+index"
        >
          <b-thead>
            <b-tr>
              <b-th
                style="
                  background: #e2e2e2;
                  text-align: center;
                  font-size: 16px;
                  color: #555;
                "
                colspan="2"
                class="text-center"
                >{{ item }}</b-th
              >
            </b-tr>
            <b-tr>
              <b-th style="width: 48px; font-size: 14px; color: #555"
                >序號</b-th
              >
              <b-th style="text-align: left; font-size: 14px; color: #555"
                >地址</b-th
              >
            </b-tr>
          </b-thead>
          <b-tbody style="border-top: 1px solid #dee2e6">
            <b-tr v-for="(shopItem, shopIndex) in shops[item]" :key="shopIndex">
              <b-td style="width: 48px; font-size: 14px; color: #555">{{
                shopIndex + 1
              }}</b-td>
              <b-td style="text-align: left; font-size: 14px; color: #555">{{
                shopItem.address
              }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-table-simple
          bordered
          style="margin-bottom: 38px"
          v-if="area[0] == ''"
          :key="'table'+index"
        >
          <b-thead>
            <b-tr>
              <b-th style="width: 8%; font-size: 14px; color: #555"
                >序號</b-th
              >
              <b-th style="width:29%; text-align: left; font-size: 14px; color: #555"
                >名稱</b-th
              >
              <b-th style="width: 8%; text-align: left; font-size: 14px; color: #555"
                >城市</b-th
              >
              <b-th style="width:35%; text-align: left; font-size: 14px; color: #555"
                >地址</b-th
              >
              <b-th style="width:20%; text-align: left; font-size: 14px; color: #555"
                >座機</b-th
              >
            </b-tr>
          </b-thead>
          <b-tbody style="border-top: 1px solid #dee2e6">
            <b-tr
              v-for="(shopItem, shopIndex) in this.shopsList"
              :key="shopIndex"
            >
              <b-td style="font-size: 14px; color: #555">{{
                shopIndex + 1
              }}</b-td>
              <b-td style="text-align: left; font-size: 14px; color: #555">{{
                shopItem.name
              }}</b-td>
              <b-td style="text-align: left; font-size: 14px; color: #555">{{
                shopItem.city
              }}</b-td>
              <b-td style="text-align: left; font-size: 14px; color: #555">{{
                shopItem.address
              }}</b-td>
              <b-td style="text-align: left; font-size: 14px; color: #555">{{
                shopItem.contact
              }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getBrandShops } from '@/api/brand.js'

export default {
  data() {
    return {
      index:0,
      shopsList: [],
      shops: {},
      area: [],
      searchVal: '',
      Dialog: false,
      fields: ['first_name', 'last_name'],
      items: [
        { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
        { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
      ],
    }
  },
  props: {
    isStoreDialog: {
      type: Boolean,
      default: false,
    },
    catId: {
      type: String,
    },
  },
  watch: {
    isStoreDialog(oldVal, newVal) {
      this.Dialog = this.isStoreDialog
    },
  },
  created() {
    this.getBrandShops()
  },
  computed: {},
  methods: {
    inputChange() {
      if (!this.searchVal) {
        this.getBrandShops()
      }
    },
    cleanChange() {
      this.searchVal = ''
      this.getBrandShops()
    },
    searchChange() {
      if (this.searchVal) {
        if (this.area[0] == '') {
          let arr = this.shopsList.filter((item) => {
              return item.address.indexOf(this.searchVal) >= 0 || item.contact.indexOf(this.searchVal) >= 0 || item.name.indexOf(this.searchVal) >= 0 || item.city.indexOf(this.searchVal) >= 0
            })
            this.shopsList = arr
        } else {
          this.area.map((el) => {
            let arr = this.shops[el].filter((item) => {
              return item.address.indexOf(this.searchVal) >= 0
            })
            this.shops[el] = arr
          })
        }
      } else {
        this.getBrandShops()
      }
    },
    diaClose() {
      this.searchVal = ''
      this.$emit('storeChange', false)
    },
    getBrandShops() {
      getBrandShops(this.catId).then((res) => {
        this.shops = res.data.shops
        this.area = Object.keys(res.data.shops)
        let list = []
        this.area.map((el) => {
          this.shops[el].map((item) => {
            list.push(item)
          })
        })
        this.shopsList = list
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.catalog_details {
  color: #555555;
  margin-top: -30px;
  .addtobag {
    position: relative;
    width: 100%;
    .pdp_request {
      position: relative;
      .multiselect {
        width: 100%;
        position: relative;
        /deep/ .btn {
          width: 100%;
          text-align: left;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          -webkit-tap-highlight-color: transparent;
          font-size: 14px;
          &:hover {
            background-color: #e6e6e6;
            border-color: #adadad;
            color: #333;
          }
        }
        .caret {
          display: inline-block;
          width: 0;
          height: 0;
          margin-left: 2px;
          vertical-align: middle;
          border-top: 4px solid;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
          margin-left: 0;
          position: absolute;
          top: 50%;
          right: 12px;
          margin-top: -2px;
          vertical-align: middle;
        }
      }
      .open {
        /deep/ .btn {
          background-color: #e6e6e6;
          border-color: #adadad;
        }
      }
    }
    .multiselect-container {
      min-width: 200px;
      max-height: 50vh;
      overflow: scroll;
      overflow-x: hidden;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      // display: none;
      width: 100%;
      max-width: 100%;
      padding: 5px 0;
      margin: 2px 0 0;
      font-size: 14px;
      text-align: left;
      list-style: none;
      background-color: #fff;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      border: 1px solid #ccc;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
      li {
        .input-group {
          position: relative;
          margin: 5px;
          width: auto;
          .input-group-addon {
            padding: 6px 12px;
            font-size: 14px;
            font-weight: 400;
            line-height: 2;
            color: #555;
            text-align: center;
            background-color: #eee;
            border: 1px solid #ccc;
            border-radius: 4px;
          }
          .input-group-btn {
            border: 1px solid #ccc;
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
          }
        }
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
      }
      /deep/ .custom-control {
        font-size: 13px;
        color: #555555;
        padding: 0 20px;
        height: 22px;
        line-height: 22px;
        display: flex;
        align-items: center;
      }
      /deep/ .custom-control-label {
        margin-left: 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex: 1;
      }
    }
    .pdp_wechat {
      position: relative;
      .fa {
        position: absolute;
        top: 9px;
        left: 12px;
        font-size: 16px;
        width: 16px;
        text-align: center;
        line-height: 18px;
      }
      .fa-mobile {
        font-size: 20px;
      }
      /deep/ .form-control {
        padding-left: 35px;
        font-size: 14px;
      }
    }
  }
  .attachment {
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    &:hover {
      color: #333;
      background-color: #e6e6e6;
      border-color: #adadad;
    }
  }
}
/deep/ .btn-success {
  background-color: #5cb85c;
  border-color: #4cae4c;
  &:hover {
    background-color: #449d44;
    border-color: #398439;
  }
}

.preview-dialog {
  padding: 40px 0;
  .cat_preview {
    color: #555555;
    font-size: 14px;
    line-height: 20px;
    margin-top: -30px;
    .pdp_info {
      border-bottom: 1px solid #eee;
      margin-bottom: 20px;
      padding-bottom: 10px;
    }
    h6 {
      margin-top: 20px;
      font-weight: bold;
      font-size: 14px;
    }
    label {
      width: 100px;
      display: inline-block;
    }
    span {
      position: relative;
      display: inline-block;
      vertical-align: top;
      margin-bottom: 5px;
    }
  }
  .dialog-footer {
    button {
      text-shadow: none;
      border: none;
      font-size: 14px;
      background-color: #ecf0f1;
    }
    .btn1 {
      background-color: #ecf0f1;
      color: #000;
      &:hover {
        background-color: #bdc3c7;
        color: #000;
      }
    }
    .btn2 {
      color: #fff;
      background-color: #5cb85c;
      &:hover {
        background-color: #449d44;
      }
    }
  }
  /deep/ .el-dialog__footer {
    border-top: #ddd 1px solid;
  }
}
/deep/ .el-dialog {
  height: 100%;
}
/deep/ .el-dialog__body {
  max-height: 95%;
  overflow-x: hidden;
  overflow-y: auto;
  // padding: 0 0 20px;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
/deep/ .el-dialog__headerbtn {
  top: 5px;
  right: 10px;
  font-weight: bold;
  font-size: 20px;
}
.upload-dialog {
  padding: 40px 0;
  /deep/ .el-dialog {
    max-height: 95% !important;
    overflow: hidden;
    padding-bottom: 30px;
    @media (max-width: 650px) {
      width: 90% !important;
    }
  }
}
.upload-dialog /deep/ .el-dialog__body {
  height: 100%;
  max-height: 95% !important;
}
.search-info {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
  .input {
    width: 200px;
  }
  .btn-search,
  .search-reset {
    background: rgb(255, 255, 255);
    color: #555;
    border: 1px solid #ced4da;
  }
}
.store-table{
  
}
</style>
