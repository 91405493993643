<template>
  <div>
    <el-dialog class="dialog dialog" :visible.sync="isDialog" open="0" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" >
        <div class="lang-content">
            <strong>{{$t('common.select_region')}}</strong>
            <div class="lang-buttons">
                <button type="button" class="btn btn-default" @click="CheckforRegion('HK')">{{$t('common.forHK')}}</button>
                <button type="button" class="btn btn-default"  @click="CheckforRegion('CN')">{{$t('common.forCN')}}</button>
                <button type="button" class="btn btn-default"  @click="CheckforRegion('OS')">{{$t('common.forOS')}}</button>
            </div>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import {region} from "@/api/home";
export default {
    data(){
        return{
            isDialog :true
        }
    },
    methods:{
        CheckforRegion(lang){
            localStorage.setItem('forRegion',lang);
            const data = {
            region:lang
          }
          region(data).then(res=>{
            if(res.status == 'success'){
              this.$emit("dialogChange", lang);
              this.isDialog = false;
              location.reload()
            }
          })

        }
    }
}
</script>

<style lang="scss" scoped>
.dialog {
  padding: 7.5px;
}
/deep/ .el-dialog{
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto !important;
    width: 100%;
    max-width: 380px;
}
/deep/ .el-dialog__header{
    display: none;
}
/deep/ .el-dialog__body{
    padding: 0;

}
.lang-content{
    padding-top: 15px;
    strong{
        display: block;
        margin-bottom: 15px;
        font-size: 18px;
        word-break: break-word;
        padding: 0 15px;
    }
    .lang-buttons{
        padding: 10px;
        border-top: solid 1px #ddd;
        overflow: hidden;
        border-radius: 0 0 4px 4px;
        .btn{
            padding: 6px 12px;
            font-size: 13px;
            background-color: #ecf0f1;
            margin: 3px 3px;
            &:hover{
                background-color: #bdc3c7;
                color: #000;
            }
        }
    }
}
</style>
