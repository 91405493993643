import { login, logout, getUserInfo } from '@/api/login'
import { getAccessToken, getToken, setToken, removeToken} from '@/utils/auth'
import router from '@/router'
const user = {
  state: {
    access_token       : getAccessToken() || '',
    refresh_token      : getToken('refresh_token') || '',
    token_exp_in       : getToken('token_exp_in') && !isNaN(getToken('token_exp_in')) ? getToken('token_exp_in') : process.env.VUE_APP_TOKEN_REFRESH_PERIOD*60,
    token_gen_from     : getToken('token_gen_from') && !isNaN(getToken('token_gen_from')) ? getToken('token_gen_from') : Math.round(new Date().getTime()/ 1000),
    // token: getAccessToken(),
    userInfo:{},
    systems:[],
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.access_token = token
    },
    SET_USER: (state, info) => {
      state.userInfo = info
    },
    SET_SYSTEM: (state, info) => {
      state.systems = info
    },
    clearAuth(state) {
      state.access_token = "";
      state.refresh_token = "";
      state.token_exp_in = 0;
      state.token_gen_from = 0;
      removeToken('access_token');
      removeToken('refresh_token');
      removeToken('token_exp_in');
      removeToken('token_gen_from');
    },
    setTokenStateFromStorage(state){
      state.access_token     = getToken('access_token');
      state.refresh_token    = getToken('refresh_token');
      state.token_exp_in     = getToken('token_exp_in') && !isNaN(getToken('token_exp_in')) ? getToken('token_exp_in') : process.env.VUE_APP_TOKEN_REFRESH_PERIOD*60;
      state.token_gen_from   = getToken('token_gen_from') && !isNaN(getToken('token_gen_from')) ? getToken('token_gen_from') : Math.round(new Date().getTime()/ 1000);
    }
  },
  actions: {
    // 登录
    Login({ commit }, userInfo) {
    //   const username = userInfo.email.trim()
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          if (response.data.access_token) {
            const token_list = [
              { token_name: 'access_token', token_key: 'access_token' },
              { token_name: 'refresh_token', token_key: 'refresh_token' },
              { token_name: 'token_exp_in', token_key: 'expires_in' },
              { token_name: 'token_gen_from', token_key: 'token_gen_from' }
            ]

            token_list.map((el) => {
              let tokenVal;
              if (el.token_name == "access_token") {
                tokenVal = response.data["access_token"];
                commit('SET_TOKEN', tokenVal);
              } else if (el.token_name == "token_exp_in") {
                tokenVal = process.env.VUE_APP_TOKEN_REFRESH_PERIOD*60;
              } else if (el.token_name == "token_gen_from") {
                tokenVal = Math.round(new Date().getTime()/ 1000);
              } else {
                tokenVal = response.data[el.token_key];
              }
              setToken(el.token_name, tokenVal);
            })
            window.localStorage.setItem('userData', JSON.stringify(response.data.user));

            // commit('SET_USER', JSON.stringify(response.data.user));
            commit('SET_USER', response.data.user);
            commit('SET_SYSTEM', response.data.systems);
            commit('addTokenHeader');
            resolve()
          }
        }).catch(error => {
          console.log(error);
          reject(error)
        })
      })
    },
    // get user info if token exist
    GetUserInfo({ commit }) {
        return new Promise((resolve, reject) => {
          getUserInfo().then(response => {
            window.localStorage.setItem('userData', JSON.stringify(response.data.user));
            commit('SET_USER', response.data.user);
            commit('SET_SYSTEM', response.data.systems);
            commit('SET_TOKEN', getAccessToken());
            resolve('success')
          }).catch(error => {
            console.log(error);
            reject(error)
          })
        })
    },
    LogOut({ commit }) {
      return new Promise(resolve => {
          logout().then(response => {
          const remove_token = ['SLD_box_key','access_token','refresh_token','token_exp_in','token_gen_from']
          commit('SET_TOKEN', '')
          commit('SET_USER', '')
          commit('SET_SYSTEM', []);
          remove_token.map(rm => {
            removeToken(rm)
          })

          window.localStorage.removeItem('userData');
          window.sessionStorage.removeItem('navcurrent');
          commit('removeTokenHeader');
          resolve()
          router.push({name:'Login'})
        })
      })
    },
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        const remove_token = ['SLD_box_key','access_token','refresh_token','token_exp_in','token_gen_from']
        commit('SET_TOKEN', '')
        commit('SET_USER', '')
        commit('SET_SYSTEM', []);
        remove_token.map(rm => {
          removeToken(rm)
        })
        window.localStorage.removeItem('userData');
        window.sessionStorage.removeItem('navcurrent');
        resolve()
        router.push({name:'Login'})
      })
    },
    RestoreToken({commit, state}){
        commit('setTokenStateFromStorage')
        commit('addTokenHeader', null, {root:true})
    },
    CheckValidAccessData({commit, state}) {
      if (state.access_token === "") {
        // store token not exist
        return false;
      }
      if (state.access_token !== getAccessToken()) {
        // store token not match
        return false;
      }
      if (localStorage.getItem("userData") === null) {
        // no local record
        return false;
      }
      if (!state.userInfo.hasOwnProperty("id")) {
        // no user info
        return false;
      }
      return true;
    },

  },
  getters: {
    getSystems(state) {
      return state.systems;
    },
  }
}

export default user
