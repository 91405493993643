<template>
  <div class="sys-drop">
  <el-dropdown
    @command="getTo"
    trigger="click"
  >
    <div class="dots">
      <span></span>
      <span></span>
      <span></span>
      <!-- <i class="el-icon-more" style="transform: rotate(90deg);"></i> -->
    </div>
    <el-dropdown-menu slot="dropdown" class="sys-dropdown">
      <el-dropdown-item
        v-if="systemAvailable('drs')"
        command="drs"
      >
        <label for="drs">DRS</label>
      </el-dropdown-item>
      <el-dropdown-item
        v-if="systemAvailable('oas')"
        command="fcms"
      >
        <label for="oa">OA <span class="temp">(FCMS)</span></label>
      </el-dropdown-item>
      <el-dropdown-item
        v-if="systemAvailable('crm')"
        command="crm"
      >
        <label for="crm">CRM</label>
      </el-dropdown-item>
      <el-dropdown-item
        v-if="systemAvailable('portal')"
        command="portal"
        class="portal-home"
      >
        <label for="home"><i class="el-icon-s-home"></i></label>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</div>
</template>
<script setup>
import store from "@/store";

const props = defineProps({
  plateform: {
    type: String,
    default : "www"
  },
})
const emit = defineEmits([])
const getExtUrl = (url) => {
  const env = process.env.VUE_APP_ENV;
  let envPostfix = env ? "-" + env : "";
  let curSubDomain = props.plateform + envPostfix;
  let extSubDomain = url + envPostfix;
  return window.location.origin.replace(curSubDomain, extSubDomain);
}
const hasSystem = (value) => {
    let systemList = store.getters['getSystems'] ?? [];
    if (typeof(value) == 'string') return systemList.indexOf(value) > -1;
    let hasSys = false;
    if (typeof(value) == 'object') {
      value.forEach(function(n) {
        hasSys = systemList.indexOf(n) > -1 || hasSys;
      });
    }
    return hasSys;
}
const systemAvailable = (key) => {
  // not show current system
  if (props.plateform == key) return false;
  switch(key) {
    case "crm":
      return hasSystem(['crm', 'cms', 'pms']);
    default:
      return hasSystem(key);
  }
}

const getTo = (key) => {
  window.location.href = getExtUrl(key);
}

</script>

<style lang="scss" scoped>
span.logAfterEdit {
  display: inline-block;
  padding: 3px 5px;
  background-color: #e3fcef;
  color: #237d5e;
}
span.logBeforeEdit {
  display: inline-block;
  padding: 3px 5px;
  background-color: #ffebe6;
  color: #bf2600;
  text-decoration: line-through;
  text-decoration-color: #bf2600;
}

.sys-dropdown {
  left: 0px !important;
  top: 49px !important;
  /*margin-top: 1px;*/
  background: #f5f5f5;
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #e7e7e7;
  border-radius: 0;
  padding: 5px 0 10px;
  margin-top: 0px;
  min-width: 150px;
  box-shadow: none;
  @media (max-width: 1329px){
    border-width: 1px;
  }
  .dropdown-item,
  .el-dropdown-menu__item {
    font-family: Calibri, arial, SimHei, "黑体", sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #444444;
    line-height: 22px;
    padding: 5px 15px;
    &:hover {
      background: none;
      color: #950c0f;
      cursor: pointer;
      label {
        cursor: pointer;
      }
    }
  }
  .portal-home {
    padding-top: 5px;
    label {
      border-top: 1px solid #e7e7e7;
      padding-top: 7px;
      width: 100%;
      &:hover i {
        color: #950c0f;
      }
    }
    i {
      font-size: 18px;
      /*transform: scale(1, 0.95);*/
      color: #606266;
      margin: 0;
    }
  }
  .temp {
      display: inline-block;
      color: #999999;
      font-weight: normal;
      margin-left: 2px;
      transform: scale(0.5);
      transform-origin: left top;
      vertical-align: top;
      margin-top: 6px;
      line-height: 11px;
  }
  /deep/ .popper__arrow {
    display: none;
  }
}
</style>

