<template>
  <div class="content" v-loading="loading">
    <b-container fluid>
      <b-row class="drs">
        <b-col cols="12">
          <h5 class="name mgb20 tc" v-if="query.catId == undefined">
            {{ query.name }}
          </h5>
          <div class="name mgb20 tc" v-else>
            <router-link
              :to="{ path: '/supplier-cats?name=' + query.name }"
              class="ft12 item"
              >{{ query.name }} /</router-link
            >
            <span class="ft12" v-if="query.catId == 3">{{ catName }} /</span>
            <router-link
              v-if="query.catId != 3"
              :to="{
                path:
                  '/supplier?id=' + this.query.catId + '&name=' + query.name,
              }"
              class="ft12 item"
              >{{ catName }} /</router-link
            >
            {{ suppliecatName }}
          </div>
        </b-col>
        <b-col cols="12" v-if="query.catId == 3">
          <div class="coCat tc mgt10">
            <el-select
              popper-append-to-body
              :style="{ width: selectOptionWidth }"
              v-model="catVendor"
              placeholder="全部分類"
              placeholder-color="#555"
              @change="getchangevalue"
            >
              <el-option
                v-for="item in resultList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                @click.native="setOptionWidth"
              >
                <span style="float: left">{{ item.name }}</span>
              </el-option>
            </el-select>
          </div>
        </b-col>
        <b-col cols="12">
          <div v-if="catData.length > 0 && catData != ''">
            <ul class="drs-list" v-if="query.catId == undefined">
              <li
                class="thumb"
                v-for="(item,index) in catData"
                :key="index"
                @click="goPage(item.id)"
              >
                <span>{{ item.name }}</span>
              </li>
            </ul>
            <div class="drs-list" v-else>
              <ul>
                <li
                  class="thumb"
                  v-for="(item,index) in catData"
                  :key="index"
                  @click="govendor(query.catId, query.id, item.id)"
                >
                  <span>{{ item.name }}</span>
                </li>
                <li class="forJustify"></li>
                <li class="forJustify"></li>
                <li class="forJustify"></li>
                <li class="forJustify"></li>
                <li class="forJustify"></li>
              </ul>
            </div>
          </div>
          <div v-else>
            <h2 class="text-center ft18" style="color: gainsboro">
              {{ $t('common.result_none') }}
            </h2>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  supplierCat,
  getsuppliers,
  getProductcat,
  supplierCatFilters,
  getsuppliercatDetail,
  getsuppliersVendor,
} from '@/api/supply'
export default {
  data() {
    return {
      selectOptionWidth: '',
      catData: [],
      query: '',
      loading: false,
      name: '',
      catName: '',
      suppliecatName: '',
      resultList: [],
      catVendor: null,
    }
  },
  created() {
    this.query = this.$route.query
    if(this.$route.query.id == 'null'){
        this.$route.query.id = null
      }

    if (this.$route.query.catId == undefined) {
      this.getSupplier()
    } else if (this.$route.query.catId == 3) {
      this.getsupplierCatFilters(this.$route.query.catId)
      this.getsuppliercatDetail(this.$route.query.catId, this.$route.query.id)
      this.getSupplierCats()
      this.getsuppliersVendor(this.catVendor || this.$route.query.id)
    } else {
      this.getsupplierCatFilters(this.$route.query.catId)
      this.getsuppliercatDetail(this.$route.query.catId, this.$route.query.id)
      this.getsuppliers(this.$route.query.catId, this.$route.query.id)
    }
    if(this.$route.query.id){
          this.catVendor =Number(this.$route.query.id)
        }
    // let catId = this.$route.query.catId ? this.$route.query.catId:null;
    // if(catId=='underfind'){

    // }else{
    //   this.getsuppliers()
    // }
  },
  watch: {
    $route: {
      handler: function (val) {
        if (val) {
          this.$router.go(0)
        }
      },
    },
  },
  methods: {
    getchangevalue() {

      this.getsuppliercatDetail(this.$route.query.catId, this.catVendor)
      if(this.query.id){
        this.$router.push({query:{catId:this.query.catId,id:this.catVendor || 'null',name: encodeURIComponent(this.query.name)}})

      }else{
        this.getsuppliersVendor(this.catVendor)
      }
    },
    setOptionWidth(event) {
      this.$nextTick(() => {
        if (event.target.localName == 'span') {
          this.nodeIndex = event.target.clientWidth + 30
        } else {
          this.nodeIndex = event.target.childNodes[0].clientWidth + 30
        }
        this.selectOptionWidth = this.nodeIndex + 'px'
      })
    },
    getSupplierCats() {
      this.loading = true
      this.catid = 3
      getProductcat(this.catid).then((res) => {
        this.loading = false
        this.resultList = res.data
        const allCat = {
          name: `${this.$t('common.all_cat')}`,
          id: null,
        }
        this.resultList.push(allCat)
      })
    },
    getsuppliersVendor(pid) {
      this.loading = true
      const data = {
        supplier_cat_id: 3,
        product_cat_id: pid,
      }
      getsuppliersVendor(data).then((res) => {
        this.loading = false
        this.catData = res.data
      })
    },
    getSupplier() {
      this.loading = true
      supplierCat().then((res) => {
        this.loading = false
        this.catData = res.data
      })
    },
    goPage(id) {
      if (id == 3) {
        this.$router.push(`/supplier-cats?catId=${id}&name=${encodeURIComponent(this.query.name)}`)
      } else {
        this.$router.push(`/supplier?id=${id}&name=${encodeURIComponent(this.query.name)}`)
      }
    },
    govendor(catId, supplieid, id) {
      this.$router.push(
        `/supplier-ventor?catId=${catId}&supplieId=${
          supplieid || this.catVendor
        }&brandId=${id}&name=${encodeURIComponent(this.query.name)}`
      )
    },
    getsuppliers(catId, id) {
      this.loading = true
      getsuppliers(catId, id).then((res) => {
        this.loading = false
        this.catData = res.data
      })
    },
    getsupplierCatFilters(catId) {
      supplierCatFilters(catId).then((res) => {
        this.catName = res.data.name
      })
    },
    getsuppliercatDetail(catId, id) {
      getsuppliercatDetail(catId, id).then((res) => {
        if (!id) {
          this.suppliecatName = `${this.$t('common.all_cat')}`
        } else {
          this.suppliecatName = res.data.name
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  padding: 25px 0 40px;
  overflow-x: hidden;
  .name {
    padding: 20px 0px 20px;
    .item {
      color: #999;
      &:hover {
        color: #950c0f;
      }
    }
  }
  .drs {
    max-width: 1530px;
    margin: 0 auto;
    text-align: left;
    .col-12 {
      padding: 0;
    }
    @media (max-width: 1559px) {
      .drs-list {
        text-align: center;
        .thumb {
          padding: 7.5px;
        }
      }
    }

    @media (max-width: 1023px) {
      /*margin: 0 -15px;*/
      .drs-list {
        text-align: justify;
        .thumb {
          width: 33.33%;
          span {
            padding: 15px 0 8px;
          }
        }
      }
    }
    @media (max-width: 767px) {
      /*margin: 0 -15px;*/
      .drs-list {
        width: auto;
        margin-bottom: 50px;
        .thumb {
          margin: 0 0 20px 0;
        }
      }
    }
    @media (max-width: 567px) {
      .thumb {
        width: 50%;
        font-size: 13px;
        span {
          font-size: 15px;
        }
      }
    }
    @media (max-width: 375px) {
      .thumb {
        span {
          font-size: 13px;
        }
      }
    }
  }
  .reders {
    max-width: 1530px;
    margin: 0 auto;
    text-align: left;
    @media (max-width: 1023px) {
      margin: 0 -15px;
      text-align: center;
    }
  }
}
.drs-list {
  margin: 0 auto;
  /* max-width: 1100px; */
  width: 100%;
  padding: 0;
  text-align: center;
  font-size: 0;
  margin-bottom: 50px;
  > ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .thumb {
    z-index: 0;
    display: inline-block;
    width: 20%;
    text-align: left;
    font-size: 15px;
    margin: 0px 0px 1% 0;
    padding: 15px;
    vertical-align: top;
    cursor: pointer;
    .img-box {
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
        transition: transform 1s;
        transition-timing-function: ease;
      }
    }
    span {
      display: block;
      font-size: 18px;
      padding: 20px 0 10px;
      text-align: center;
      letter-spacing: 1px;
      color: #555555;
      position: relative;
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
      span {
        color: #c63033;
      }
    }
  }
}
.renderer-list {
  /* max-width: 1100px; */
  padding: 0;
  /* text-align: center; */
  text-align: justify;
  font-size: 0;
  margin: 0px 0 60px;
  vertical-align: top;
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      box-sizing: border-box;
      font-size: 14px;
      background-color: #ffffff;
      /*border: 1px solid #DDDDDD;*/
      /*border-radius: 3px;
      box-shadow: 1px 3px 1px rgba(0, 0, 0, 0.08);*/
      /*max-width:250px;*/
      margin: 0px 0px 50px;
      padding: 15px 15px 35px;
      width: 19%;
      text-align: center;
      vertical-align: top;
      display: inline-block;
      position: relative;
      @media (max-width: 1023px) {
        width: 33%;
        padding: 0 2% 35px;
      }
      @media (max-width: 767px) {
        width: 49%;
        padding: 0 2% 35px;
        margin: 0 0 30px;
      }
      .render-top {
        position: relative;
        padding: 0px;
        display: block;
        color: #555555;
        .text {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          vertical-align: middle;
          color: #ccc;
          padding: 10px;
        }
        .img-box {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          img {
            display: block;
            min-width: 100%;
            min-height: 100%;
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            margin: auto 0;
            object-fit: cover;
          }
        }
      }
      .render-title {
        display: block;
        font-size: 15px;
        padding: 20px 0 2px;
        text-align: center;
        letter-spacing: 1px;
        color: #555555;
      }
      .expertise {
        margin: 0 0 15px;
        > div {
          font-size: 14px;
          color: #999999;
        }
      }
      .rating {
        display: flex;
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        padding: 0px 15px;
        color: #999999;
        font-size: 12px;
        .rate {
          flex: 1;
          &:last-child {
            border-left: 1px solid #eaeaea;
          }
        }
      }
    }
  }
}
/deep/ .el-input__inner {
  border: none;
  font-size: 13px;
  color: #555;
  text-align: center;
  padding-left: 0;
}
</style>
