<template>
  <div id="app">
    <keep-alive>
      <router-view :key="$route.fullPath"/>
    </keep-alive>
    <div v-if="isforRegion">
      <check-lang />
    </div>
  </div>
</template>
<script>
import checkLang from "@/components/CheckLang.vue";
export default {
  components: { checkLang },
  data() {
    return { isforRegion: false };
  },
  watch: {
    $route(to) {
      this.$nextTick(() => {
        if (to.path != "/Login") {
          if (!localStorage.getItem("forRegion")) {
            this.isforRegion = true;
          } else {
            this.isforRegion = false;
          }
        }
      });
    }
  },
  created() {},
  mounted() {
    console.log(location.hash);
  }
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #555555;
  height: 100%;
  position: relative;
}
.nav-dropdown .router-link-active {
}
.actived {
  color: #950c0f;
}

.el-loading-spinner .path {
    stroke: #AAAAAA !important;
}

/* .dropdown-content .router-link-exact-active{
  color: #950c0f;
}  */
</style>
