import request from '@/utils/request'

// login
export function login (data) {
  return request({
    url: '/api/login',
    method: 'POST',
    data
  })
}

export function logout (data) {
  return request({
    url: '/api/logout',
    method: 'POST',
    data
  })
}

// get verifycode
export function loginPrepare () {
  return request({
    url: '/api/login-prepare',
    method: 'get',
  })
}

//set  language
export function setPassword (data) {
  return request({
    url: '/api/password/email',
    method: 'post',
    params:data
  })
}

// get user info only
export function getUserInfo () {
  return request({
    url: '/api/user-info',
    method: 'get',
  })
}