const pageData = {
	namespaced: true,
	state: {
	  redirectPath:null,
	},
	mutations: {      
	  setRedirectPath(state, data){
		state.redirectPath = data
	  },

	},
	getters: {
	  get: state=>state
	},
	actions: {
		UpdateRedirectPath({ commit }) {
			let loginUrl = process.env.VUE_APP_LOGIN_URL;
			let resetUrl = process.env.VUE_APP_RESET_PASSWORD_URL;
			let path = window.location.href.replace(/^.*#/,'');
			if (path == loginUrl || path == resetUrl || path == '/home') {
				// exclude login page and default home page
			} else {
				commit('setRedirectPath', path);
			}
		}
	}
}
export default pageData