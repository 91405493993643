<template>
  <div class="content">
    <b-container fluid v-loading="loading">
      <b-row class="renderers">
        <b-col cols="12">
          <h5 class="name tc">
            {{ ctName }}
          </h5>
          <div class="download-overview" v-if="ct == 14">
            <a
              :href="url + 'company/summary/2024 效果圖單價總覽 (國內) Renderer Price Summary (PRC).pdf'"
              target="_blank"
              >{{ $t("supplierfilter.pricerendersPRC") }}</a
            >
            <a
              :href="
                url + 'company/summary/2024 效果圖單價總覽 (泰國) Renderer Price Summary (Thai).pdf'
              "
              target="_blank"
            >
              {{ $t("supplierfilter.pricerendersThai") }}</a
            >
          </div>
          <!-- <div class="download-overview" v-if="ct == 14">
            <a
              :href="url + 'company/summary/效果图制作公司费用总览 (2021).pdf'"
              target="_blank"
              >{{ $t("supplierfilter.pricerenders") }} (2021)</a
            >
            <a
              :href="
                url + 'company/summary/效果图制作公司服务质量总览 (2020).pdf'
              "
              target="_blank"
            >
              {{ $t("supplierfilter.reviewsrenders") }} (2020)</a
            >
          </div> -->
          <div class="download-overview" v-if="ct == 15">
            <a
              :href="url + 'company/summary/扩初图制作公司费用总览 (2021).pdf'"
              target="_blank"
            >
              {{ $t("supplierfilter.designdraffers") }} (2021)</a
            >
            <a
              :href="
                url + 'company/summary/扩初图制作公司服务质量总览 (2020).pdf'
              "
              target="_blank"
            >
              {{ $t("supplierfilter.reviewdesigndraffers") }} (2020)</a
            >
          </div>
        </b-col>

        <b-col md="12">
          <div
            class="renderer-list"
            v-if="companiesData.length > 0 && companiesData != ''"
          >
            <ul>
              <li
                class="thumb"
                v-for="(item, index) in companiesData"
                :key="index"
                @click="goPage(item.id)"
              >
                <div v-if="item != null">
                  <div class="render-top">
                    <div
                      v-if="item.show_new == 1"
                      style="
                        position: absolute;
                        left: 0;
                        right: 0;
                        z-index: 8888;
                        background: #f5f5f5;
                        height: auto;
                        color: #333;
                        opacity: 0.95;
                        top: auto;
                        bottom: 0;
                        padding: 6px 0 4px;
                        font-size: 12px;
                        font-weight: bold;
                      "
                    >
                      {{ $t("supplierfilter.isnew") }}
                    </div>
                    <div
                      class="text flex-center"
                      v-if="item.cover_path == null || item.cover_path == ''"
                    >
                      {{ item.name }}
                    </div>
                    <div class="img-box" v-else>
                      <img v-lazy="url + item.cover_path + '/s500'" />
                    </div>
                  </div>
                  <div class="render-title">{{ item.name }}</div>
                  <div class="expertise">
                    <div>{{ item.spec }}</div>
                  </div>
                  <div class="score">
                    <el-rate
                    v-if="item.review_average && item.review_average.avg_rating"
                      v-model="item.review_average.avg_rating"
                      :icon-classes="['fa fa-star', 'fa fa-star', 'fa fa-star']"
                      disabled-void-icon-class="fa fa-star"
                      disabled
                      disabled-void-color="#e3e3e3"
                    ></el-rate>
                    <el-rate
                      v-else
                      v-model="score"
                      :icon-classes="['fa fa-star', 'fa fa-star', 'fa fa-star']"
                      disabled-void-icon-class="fa fa-star"
                      disabled
                      disabled-void-color="#e3e3e3"
                    ></el-rate>
                  </div>
                  <div class="rating">
                    <div
                      class="rate"
                      v-if="
                        item.company_type > 10 &&
                        item.company_type != 38 &&
                        item.company_type != 39 &&
                        item.company_type != 40 &&
                        item.company_type != 41
                      "
                    >

                    </div>
                    <!-- <div class="rate"><span class="">性价比:</span><br/><el-rate disabled v-model="item.ppr"></el-rate></div> -->
                  </div>
                </div>
              </li>
              <li class="forJustify"></li>
              <li class="forJustify"></li>
              <li class="forJustify"></li>
              <li class="forJustify"></li>
              <li class="forJustify"></li>
            </ul>
          </div>
          <div v-else class="text-center ft18" style="color: gainsboro">
            {{ $t("supplierfilter.nodata") }}
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { getCompanyTypeDetail, getCompanies } from "@/api/supply";
export default {
  data() {
    return {
      score: null,
      ctName: "",
      loading: false,
      companiesData: [],
      url: process.env.VUE_APP_IMG_URL,
      ct: null,
      cl: null
    };
  },
  created() {
    this.cl = this.$route.query.cl;
    this.ct = this.$route.query.ct;
    this.getTypeName();
    this.getCompaniesList();
  },
  watch: {
    $route(to, from) {
      this.cl = this.$route.query.cl;
      this.ct = this.$route.query.ct;
      this.getTypeName();
      this.getCompaniesList();
    }
  },
  methods: {
    getTypeName() {
      getCompanyTypeDetail(this.ct).then((res) => {
        if (res.data != "") {
          this.ctName = res.data.name;
        } else {
          this.ctName = "";
        }
      });
    },
    getCompaniesList() {
      this.loading = true;
      getCompanies(this.cl, this.ct).then((res) => {
        if (res.code == 200) {
          this.loading = false;
          this.companiesData = res.data;
        }
      });
    },
    goPage(id) {
      this.$router.push(`/supplierdetail?&id=${id}`);
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.content {
  padding: 25px 0 40px;
  overflow-x: hidden;
  .name {
    padding: 20px 0px 20px;
  }
  .download-overview {
    font-size: 15px;
    margin-bottom: 35px;
    line-height: 13px;
    a {
      display: inline-block;
      border-right: 1px solid #cccccc;
      padding-right: 15px;
      margin-right: 15px;
      &:last-child {
        border-right: none;
        padding-right: 0px;
        margin-right: 0px;
      }
    }
  }
  .reders {
    max-width: 1530px;
    margin: 0 auto;
    text-align: left;
    @media (max-width: 1023px) {
      margin: 0 -15px;
      text-align: center;
    }
  }
}
.renderers {
  max-width: 1530px;
  margin: 0 auto;
  text-align: center;
  .col-md-12 {
    padding: 0;
  }
  @media (max-width: 1023px) {
    /*margin: 0 -15px;*/
    text-align: center;
  }
}
.renderer-list {
  /* max-width: 1100px; */
  padding: 0;
  /* text-align: center; */
  text-align: justify;
  font-size: 0;
  margin: 0px 0 60px;
  vertical-align: top;
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      box-sizing: border-box;
      font-size: 14px;
      background-color: #ffffff;
      /*border: 1px solid #DDDDDD;*/
      /*border-radius: 3px;
      box-shadow: 1px 3px 1px rgba(0, 0, 0, 0.08);*/
      /*max-width:250px;*/
      margin: 0px 0px 50px;
      /*padding: 15px 15px 35px; OLD */
      padding: 15px 15px 25px;
      width: 20%;
      text-align: center;
      vertical-align: top;
      display: inline-block;
      position: relative;
      cursor: pointer;
      @media (max-width: 1559px) {
        padding: 7.5px;
      }
      @media (max-width: 1023px) {
        width: 33.33%;
        padding: 0 7.5px 35px;
      }
      @media (max-width: 767px) {
        padding: 0 7.5px 35px;
        margin: 0 0 30px;
      }
      @media (max-width: 567px) {
        width: 50%;
        padding: 0 7.5px 35px;
        margin: 0 0 30px;
      }
      .render-top {
        position: relative;
        padding: 0px;
        display: block;
        color: #555555;
        &::before {
          content: "";
          display: block;
          padding-top: 100%;
          overflow: hidden;
          background: #f2f2f2;
        }
        .text {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          vertical-align: middle;
          color: #ccc;
          padding: 10px;
        }
        .img-box {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          img {
            display: block;
            min-width: 100%;
            min-height: 100%;
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            margin: auto 0;
            object-fit: cover;
          }
        }
      }
      .render-title {
        display: block;
        font-size: 15px;
        padding: 20px 0 2px;
        text-align: center;
        letter-spacing: 1px;
        color: #555555;
      }
      .expertise {
        margin: 0 0 15px;
        > div {
          font-size: 14px;
          color: #999999;
        }
      }
      // .rating {
      //   display: flex;
      //   position: absolute;
      //   bottom: 0px;
      //   left: 0px;
      //   width: 100%;
      //   padding: 0px 15px;
      //   color: #999999;
      //   font-size: 12px;
      //   display: none;
      //   .rate {
      //     flex: 1;
      //   }
      // }
      .score {
        // position: absolute;
        // bottom: 0px;
        // left: 0px;
        width: 100%;
        padding: 0px 15px;
        color: #999999;
        font-size: 12px;
      }
    }
    .forJustify {
      border: none;
      background: none;
      height: 1px;
    }
  }
}
/deep/ .el-rate__icon {
  font-size: 13px;
  margin-right: 2px;
}
</style>
