import Cookies from 'js-cookie'

const S3Key = 's3_key'

const checkEnv = () => {
  const env = process.env.VUE_APP_ENV
  if(env) return env + "_"
  return ""
}

const envPrefix = checkEnv()

export function getAccessToken() {
  // add env prefix to token variable
  return Cookies.get(envPrefix + 'access_token')
}

export function getToken(key_name) {
  // add env prefix to token variable
  return Cookies.get(envPrefix + key_name)
}

export function setToken(key_name, token) {
  // add env prefix to token variable
  return Cookies.set(envPrefix + key_name, token, { expires: 7 ,domain: process.env.VUE_APP_MASTER_DOMAIN})
}

export function removeToken(key_name) {
  // add env prefix to token variable
  return Cookies.remove(envPrefix + key_name, { domain: process.env.VUE_APP_MASTER_DOMAIN})
}

export function getS3Key() {
  return Cookies.get(S3Key)
}

export function setS3Key(data) {
  const InFourteenMinutes = new Date((new Date().getTime() + 14) * 60 * 1000)
  return Cookies.set(S3Key, data, { expires: InFourteenMinutes })
}

export function get(key) {
  return Cookies.get(key)
}

export function set(key,value) {
  return Cookies.set(key, value)
}

export function remove(key) {
  return Cookies.remove(key)
}


