<template>
  <!-- brand page -->
  <div class="brands">
    <h3 class="text-center">{{$t('productfilter.FitOut')}}</h3>
    <ul class='brand-list' v-if="brand.fit_out_brands!=''&& brand.fit_out_brands!=null">
      <li class="brand-item" v-for="(item,index) in brand.fit_out_brands" :key="index" >
        <div class="brand-photo" @click="goPage(item.id)">
          <div class="brand-photo-con">
            <img class="b-lazy" :src="imgurl+item.logo_path" width="150" :alt="item.name" :title="item.name">
          </div>
        </div>
        <div class="posiR brand-info">
          <h2 @click="goPage(item.id)">
            {{item.sname}}
          </h2>
          <ul class="brand-cat">
                                    
            <!-- <li v-for="(cate,inx) in item.product_categories" :key="inx"><span>{{cate.name}}</span></li> -->
             <li v-for="(cate,inx) in item.product_categories" :key="inx"><span @click="goProduct(cate.pivot)">{{cate.name}}</span></li>                       
          </ul>
          
        </div>
      </li>
    </ul>
    <div style="color: gainsboro; line-height:22px;" class="ft18 mgb50" v-else>
      {{$t('common.result_none')}}
    </div>
    <h3 class="text-center">{{$t('productfilter.Furnishings')}}</h3>
    <ul class='brand-list' v-if="brand.furnishing_brands!=''&& brand.furnishing_brands!=null">
      <li class="brand-item" v-for="(item,index) in brand.furnishing_brands" :key="index" >
        <div class="brand-photo" @click="goPage(item.id)">
          <div class="brand-photo-con">
            <img class="b-lazy" :src="imgurl+item.logo_path" width="150" :alt="item.name" :title="item.name">
          </div>
        </div>
        <div class="posiR brand-info">
          <h2 @click="goPage(item.id)">
            {{item.sname}}
          </h2>
          
          <ul class="brand-cat">
                                    
            <li v-for="(cat,inx) in item.product_categories" :key="inx"><span @click="goProduct(cat.pivot)">{{cat.name}}</span></li>
                                    
          </ul>
          
        </div>
      </li>
    </ul>
    <div style="color: gainsboro; line-height:22px;" class="ft18 mgb50" v-else>
      {{$t('common.result_none')}}
    </div>
      

   
      <!--distributors  page -->
    

  </div>
</template>

<script>
export default {
    data(){
      return {
        imgurl:process.env.VUE_APP_IMG_URL,
      }
    },
    props: {
      brand:Object,
    },
    methods:{
      goPage(id) {
        this.$router.push(`/strategic/brand-detail?catId=${id}`);
      },
      goProduct(pivot){
        this.$router.push(`/product?selectbrand=${pivot.brand_id}&selectcat=${pivot.cat_id}`);
      }
    }
}
</script>

<style lang="scss" scoped>
.brands{
  h3{
    font-size: 17px;
    font-weight: normal;
    margin: 0 0 50px;
  }
  .brand-list{
    margin: 0 auto;
    max-width: 1560px;
    width: 100%;
    padding: 0;
    text-align: center;
    text-align: justify;
    font-size: 0;
    margin-bottom: 50px;
    .brand-item {
      display: inline-block;
      box-sizing: border-box;
      text-align: center;
      font: bold 18px sans-serif;
      background-color: #ffffff;
      /* border: 1px solid #DDDDDD; */
      /* max-width: 250px; */
      width: 16.66%;
      margin: 0 0 50px;
      padding: 0 15px;
      vertical-align: top;
      position: relative;
      @media (max-width: 1500px){
        width: 20%;
      }
      @media (max-width: 1200px) {
        width: 25%;
      }
      @media (max-width: 1023px) {
        width: 33.33%;
      }
      @media (max-width: 567px) {
        width: 50%;
        margin-bottom: 50px;
      }
      .brand-photo{
        position: relative;
        display: block;
        text-align: center;
        /* box-shadow: 0 0 10px 5px #f3f3f3 inset; */
        width: 100%;
        margin-bottom: 12px;
        /* padding: 10px 10px; */
        padding: 0px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        cursor: pointer;
        &::before{
          content: "";
          display: block;
          padding-top: 30%;
        }
        .brand-photo-con{
          position: absolute;
          display: flex;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          align-items: center;
          img{
            display: block;
            max-width: 100%;
            max-height: 100%;
            margin: auto;
            width: auto;
            height: auto;
          }
        }
      }
      .brand-info{
        margin: 0;
        font-size: 18px;
        line-height: 22px;
        max-width: 100%;
        
        h2{
          margin: 0;
          font-size: 18px;
          line-height: 22px;
          max-width: 100%;
          cursor: pointer;
        }
        .brand-cat{
          margin-top: 6px;
          li{
            display: inline-block;
            width: auto;
            font-size: 12px;
            font-weight: normal;
            color: #999999;
            border-right: 1px solid #CCCCCC;
            padding-right: 5px;
            line-height: 12px;
            margin: 0 5px 5px 0px;
            &:last-child{
              border: none;
              margin-right: 0px;
              padding-right: 0px;
            }
            span{
              color: #999;
              &:hover{
                color: #c63033;
              }
            }
          }
        }
      }
    }
  }
  .distributors{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 auto;
    /* max-width: 1560px; */
    max-width: 1500px;
    width: 100%;
    padding: 0;
    text-align: center;
    text-align: justify;
    font-size: 0;
    margin-bottom: 50px;
    border-top: 1px solid #EAEAEA;
    h6{
      font-size: 16px;
      line-height: 22px;
      /* color: #AAAAAA; */
      margin: 0px 0px 25px 0px;
      padding-top: 80px;
      width: 25%;
      width: 16.66%;
      width: 20%;
      text-align: center;
      
      text-align: left;
    }
    .brand-list{
      width: 75%;
      width: 83.34%;
      width: 75.1%;
      margin: 0px 0px 20px 0px;
      padding: 50px 0 0 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
</style>