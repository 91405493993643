<template>
  <div class="home">
    <div class="content" v-loading="loading">
      <div class="col-fixed-280 left-catmenu text-left">
        <div class="search-toggle" @click="collapseShow = !collapseShow">
          <span class="fa fa-filter" v-if="!collapseShow"></span>
          <span v-else class="fa fa-minus-square"></span>{{ $t("productfilter.mobileFilter") }}
        </div>
        <div
          class="collapse"
          :class="collapseShow ? 'in' : ''"
          id="collapseOne"
        >
          <div
            class="mb-3"
            v-if="filtersData.hlFilters != '' && fromdata.lib == null"
          >
            <h5 class="selLibrary">{{ $t("productfilter.Highlight") }}</h5>
            <ul>
              <li
                class="libFilters"
                :class="fromdata.hl == item.id ? 'active' : ''"
                v-for="(item, key, index) in filtersData.hlFilters"
                :key="index"
                @click="checkFilters(item.id, 'hl', item.name)"
              >
                <label class="mgb2">{{ item.name }}</label>
              </li>
            </ul>
          </div>
          <div
            class="mb-3"
            v-if="filtersData.catFiltersHard != 0 && fromdata.lib == null"
          >
            <h5 class="selLibrary">{{ $t("productfilter.Category") }}</h5>
            <div style="color: #aaaaaa; font-size: 14px; margin-bottom: 8px">
              {{ $t("productfilter.FitOut") }}
            </div>
            <ul>
              <li
                class="libFilters"
                :class="fromdata.selectcat == item.id ? 'active' : ''"
                v-for="(item, key, index) in filtersData.catFiltersHard"
                :key="index"
                @click="checkFilters(item.id, 'selectcat', item.name)"
              >
                <label class="mgb2">{{ item.name }}</label>
              </li>
            </ul>
          </div>
          <div
            class="mb-3"
            v-if="filtersData.catFiltersSoft != 0 && fromdata.lib == null"
          >
            <div style="color: #aaaaaa; font-size: 14px; margin-bottom: 8px">
              {{ $t("productfilter.Furnishings") }}
            </div>
            <ul class="scroll-cont">
              <li
                class="fromdata.libFilters"
                :class="fromdata.selectcat == item.id ? 'active' : ''"
                v-for="(item, key, index) in filtersData.catFiltersSoft"
                :key="index"
                @click="checkFilters(item.id, 'selectcat', item.name)"
              >
                <label class="mgb2">{{ item.name }}</label>
              </li>
            </ul>
          </div>
          <div class="mb-3" v-if="fromdata.selectcat != null">
            <h5 class="selLibrary">{{ $t("productfilter.filter_subcat") }}</h5>
            <ul>
              <li
                class="libFilters"
                :class="fromdata.selectsubcat == index ? 'active' : ''"
                v-for="(item, key, index) in filtersData.subCatFilters"
                :key="index"
                @click="checkFilters(item.id, 'selectsubcat', item.name)"
              >
                <input
                  :id="item.id"
                  type="checkbox"
                  name="flavour-1a"
                  :checked="fromdata.selectsubcat == item.id ? true : false"
                />
                <label :for="item.id">{{ item.name }}</label>
              </li>
            </ul>
          </div>

          <div
            class="mb-3"
            v-if="filtersData.brandFilters != 0 && fromdata.lib == null"
          >
            <h5 class="selLibrary">{{ $t("productfilter.Brand") }}</h5>
            <ul class="scroll-cont">
              <li
                class="libFilters"
                v-for="(item, key, index) in filtersData.brandFilters"
                :key="index"
                @click="checkFilters(item.id, 'selectbrand', item.name)"
              >
                <input
                  :id="index"
                  type="checkbox"
                  name="flavour-1a"
                  :checked="fromdata.selectbrand == item.id ? true : false"
                />
                <label :for="index">{{ item.name }}</label>
              </li>
            </ul>
          </div>
          <div
            class="mb-3"
            v-if="filtersData.seriesFilters != 0 && fromdata.lib == null"
          >
            <h5 class="selLibrary">{{ $t("productfilter.Series") }}</h5>
            <ul class="scroll-cont">
              <li
                class="libFilters"
                v-for="(item, key, index) in filtersData.seriesFilters"
                :key="index"
                @click="checkFilters(item.id, 'selectseries', item.name)"
              >
                <input
                  :id="index"
                  type="checkbox"
                  name="flavour-1a"
                  :checked="fromdata.selectseries == item.id ? true : false"
                />
                <label :for="index">{{ item.name }}</label>
              </li>
            </ul>
          </div>
          <div
            class="mb-3"
            v-if="filtersData.colorFilters != 0 && fromdata.lib == null"
          >
            <h5 class="selLibrary">{{ $t("productfilter.Color") }}</h5>
            <ul class="scroll-cont">
              <li
                class="libFilters"
                v-for="(item, key, index) in filtersData.colorFilters"
                :key="index"
                @click="checkFilters(item.id, 'selectcolor', item.name)"
              >
                <input
                  :id="index"
                  type="checkbox"
                  name="flavour-1a"
                  :checked="fromdata.selectcolor == item.id ? true : false"
                />
                <label :for="index">{{ item.name }}</label>
              </li>
            </ul>
          </div>
          <div
            class="mb-3"
            v-if="filtersData.materialFilters != 0 && fromdata.lib == null"
          >
            <h5 class="selLibrary">{{ $t("productfilter.MainMaterials") }}</h5>
            <ul class="scroll-cont">
              <li
                class="libFilters"
                v-for="(item, key, index) in filtersData.materialFilters"
                :key="index"
                @click="checkFilters(item.id, 'selectmaterial', item.name)"
              >
                <input
                  :id="index"
                  type="checkbox"
                  name="flavour-1a"
                  :checked="fromdata.selectmaterial == item.id ? true : false"
                />
                <label :for="index">{{ item.name }}</label>
              </li>
            </ul>
          </div>
          <div class="mb-3" v-if="filtersData.libFilters != ''">
            <h5 class="selLibrary">{{ $t("productfilter.Library") }}</h5>
            <ul>
              <li
                class="libFilters"
                :class="fromdata.lib == item.id ? 'active' : ''"
                v-for="(item, key, index) in filtersData.libFilters"
                :key="index"
                @click="checkFilters(item.id, 'lib', item.name)"
              >
                <label class="mgb2">{{ item.name }}</label>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-offset-280">
        <b-container class="bv-example-row">
          <b-row>
            <b-col lg="4" cols="12" class="no-of-result">
              <div class="tl resultInfo" v-if="productList.length > 0">
                {{ last_page }}-{{ per_page }} / {{ totals
                }}{{ $t("productfilter.unit") }}
                <b v-if="filters.hlfilter != null && filters.hlfilter.text">
                  {{ filters.hlfilter.text.name }}
                  <span
                    class="fa fa-times-circle"
                    @click="dele(filters.hlfilter.type)"
                  ></span>
                </b>
                <b
                  v-if="filters.brandfilter != null && filters.brandfilter.text"
                >
                  {{ filters.brandfilter.text.name }}
                  <span
                    class="fa fa-times-circle"
                    @click="dele(filters.brandfilter.type)"
                  ></span>
                </b>
                <b v-if="filters.catFilter != null && filters.catFilter.text">
                  {{ filters.catFilter.text.name }}
                  <span
                    class="fa fa-times-circle"
                    @click="dele(filters.catFilter.type)"
                  ></span>
                </b>
                <b
                  v-if="
                    filters.subcatFilter != null && filters.subcatFilter.text
                  "
                >
                  {{ filters.subcatFilter.text.name }}
                  <span
                    class="fa fa-times-circle"
                    @click="dele(filters.subcatFilter.type)"
                  ></span>
                </b>
                <b
                  v-if="
                    filters.seriesFilter != null && filters.seriesFilter.text
                  "
                >
                  {{ filters.seriesFilter.text.name }}
                  <span
                    class="fa fa-times-circle"
                    @click="dele(filters.seriesFilter.type)"
                  ></span>
                </b>
                <b
                  v-if="filters.colorFilter != null && filters.colorFilter.text"
                >
                  {{ filters.colorFilter.text.name }}
                  <span
                    class="fa fa-times-circle"
                    @click="dele(filters.colorFilter.type)"
                  ></span>
                </b>
                <b
                  v-if="
                    filters.materialFilter != null &&
                    filters.materialFilter.text
                  "
                >
                  {{ filters.materialFilter.text.name }}
                  <span
                    class="fa fa-times-circle"
                    @click="dele(filters.materialFilter.type)"
                  ></span>
                </b>
                <b v-if="filters.libFilter != null && filters.libFilter.text">
                  {{ filters.libFilter.text.name }}
                  <span
                    class="fa fa-times-circle"
                    @click="dele(filters.libFilter.type)"
                  ></span>
                </b>

                {{ $t("productfilter.resultxt") }}
              </div>
            </b-col>
            <b-col lg="8" cols="12" class="flex sorting" style="justify-content: flex-end">
              <div class="forRegion flex-wrap">
                <label class="bold">{{
                  $t("productfilter.pictureShows")
                }}</label>
                <b-form-select
                  class="select-list"
                  v-model="fromdata.inputmode"
                  value-field="value"
                  text-field="text"
                  :options="$t('productfilter.pictureOptions')"
                  @change="getInputMode(fromdata.inputmode)"
                ></b-form-select>
              </div>
              <div class="forRegion flex-wrap">
                <label class="bold">{{ $t("productfilter.Region") }}</label>
                <b-form-select
                  class="select-list"
                  v-model="fromdata.forRegion"
                  value-field="value"
                  text-field="text"
                  :options="$t('productfilter.regionption')"
                  @change="setRegion(fromdata.forRegion)"
                ></b-form-select>
              </div>
              <div class="forRegion flex-wrap">
                <label class="bold">{{ $t("productfilter.orderby") }}</label>
                <b-form-select
                  class="select-list"
                  v-model="fromdata.sort"
                  :options="$t('productfilter.sortSelectOptions')"
                  value-field="val"
                  text-field="text"
                  @change="getPagenum"
                ></b-form-select>
              </div>
              <div class="forRegion flex-wrap">
                <label class="bold">{{ $t("productfilter.pageShow") }}</label>
                <b-form-select
                  class="select-list"
                  v-model="fromdata.num"
                  :options="numSelectOptions"
                  @change="getPagenum"
                ></b-form-select>
              </div>
            </b-col>
          </b-row>
          <product-list
            :name="name"
            :product-list="productList"
            :lib-info="libInfo"
            :hl="fromdata.hl"
            :load="loading"
          ></product-list>

          <el-pagination
            class="posiR pagination flex-center mgt50"
            background
            layout="prev, pager, next"
            @size-change="getPagenum"
            @current-change="handleCurrentChange"
            :page-size="fromdata.num"
            :current-page.sync="page"
            :total="totals"
            v-if="productList.length > 0"
          >
          </el-pagination>
        </b-container>
      </div>
    </div>
    <!-- <div v-if="isforRegion">
      <check-lang @dialogChange="dialogChange" />
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src

import { productFilters, product } from "@/api/product";
import productList from "@/components/ProductList.vue";
import { region } from "@/api/home";
export default {
  name: "Product",
  components: {
    "product-list": productList
  },
  data() {
    return {
      name: "product",
      searchText: [],
      sortSelect: "default",
      numSelectOptions: [24, 48, 96],
      numSelect: 24,
      filtersData: [], //left category
      productList: [],
      libInfo: {},
      url: process.env.VUE_APP_IMG_URL,
      fromdata: {
        hl: null, //Highlight
        selectcat: null, //CATEGORY
        selectsubcat: null, //Subcategory
        selectbrand: null, //BRAND
        selectseries: null, //SERIES
        selectcolor: null, //COLOR
        selectmaterial: null, //MAIN MATERIALS
        forRegion: "HK",
        lib: null, //Library
        sort: "default",
        num: 24,
        fitout: null,
        furn: null,
        inputmode: null
      },
      totals: null,
      perPage: 24,
      page: 1,
      loading: false,

      last_page: "", //top from
      per_page: "",
      isforRegion: false,
      filters: {
        hlfilter: null,
        brandfilter: null,
        catFilter: null,
        seriesFilter: null,
        subcatFilter: null,
        colorFilter: null,
        materialFilter: null,
        libFilter: null
      },
      collapseShow: false
    };
  },
  created() {
    this.fromdata.lib = this.$route.query.lib ? this.$route.query.lib : null;
    this.page = this.$route.query.page ? Number(this.$route.query.page) : 1;
    this.fromdata.num = this.$route.query.num
      ? Number(this.$route.query.num)
      : 24;
    this.fromdata.inputmode = this.$route.query.inputmode
      ? Number(this.$route.query.inputmode)
      : null;
    this.fromdata.sort = this.$route.query.sort
      ? this.$route.query.sort
      : "default";
    this.fromdata.hl = this.$route.query.hl ? this.$route.query.hl : null;
    this.fromdata.selectcat = this.$route.query.selectcat
      ? this.$route.query.selectcat
      : null;
    this.fromdata.selectsubcat = this.$route.query.selectsubcat
      ? this.$route.query.selectsubcat
      : null;
    this.fromdata.selectbrand = this.$route.query.selectbrand
      ? this.$route.query.selectbrand
      : null;
    this.fromdata.fitout = this.$route.query.fitout
      ? this.$route.query.fitout
      : null;
    this.fromdata.furn = this.$route.query.fitout
      ? this.$route.query.fitout
      : null;
    this.fromdata.selectseries = this.$route.query.selectseries
      ? this.$route.query.selectseries
      : null;
    if (localStorage.getItem("forRegion")) {
      this.isforRegion = false;
      this.fromdata.forRegion = localStorage.getItem("forRegion");
    } else {
      this.isforRegion = true;
    }
    this.getproductList();
  },
  watch: {
    $route() {
      this.fromdata.lib = this.$route.query.lib ? this.$route.query.lib : null;
      this.page = this.$route.query.page ? Number(this.$route.query.page) : 1;
      this.fromdata.num = this.$route.query.num
        ? Number(this.$route.query.num)
        : 24;
      this.fromdata.inputmode = this.$route.query.inputmode
        ? Number(this.$route.query.inputmode)
        : null;
      this.fromdata.sort = this.$route.query.sort
        ? this.$route.query.sort
        : "default";
      this.fromdata.hl = this.$route.query.hl ? this.$route.query.hl : null;
      this.fromdata.selectcat = this.$route.query.selectcat
        ? this.$route.query.selectcat
        : null;
      this.fromdata.selectsubcat = this.$route.query.selectsubcat
        ? this.$route.query.selectsubcat
        : null;
      this.fromdata.selectbrand = this.$route.query.selectbrand
        ? this.$route.query.selectbrand
        : null;
      this.fromdata.selectseries = this.$route.query.selectseries
        ? this.$route.query.selectseries
        : null;
      if (localStorage.getItem("forRegion")) {
        this.fromdata.forRegion = localStorage.getItem("forRegion");
        this.isforRegion = false;
      } else {
        this.isforRegion = true;
      }
      this.getproductList();
    }
  },
  methods: {
    getInputMode(val) {
      this.fromdata.inputmode = val;
      this.pathChange();
      this.getproductList();
    },
    getproductFilters() {
      productFilters(this.fromdata).then((res) => {
        this.filtersData = res.data;
        console.log(this.filtersData);
        if (this.fromdata.hl != null) {
          let obj = {};
          for (let i in this.filtersData.hlFilters) {
            if (this.filtersData.hlFilters[i].id == this.fromdata.hl) {
              obj.type = "hl";
              obj.text = this.filtersData.hlFilters[i];
            }
          }
          this.filters.hlfilter = obj;
        } else {
          this.filters.hlfilter = null;
        }
        if (this.fromdata.selectcat != null) {
          let selectData = Object.assign(
            this.filtersData.catFiltersSoft,
            this.filtersData.catFiltersHard
          );
          let obj = {};
          for (let i in selectData) {
            if (
              this.filtersData.catFiltersSoft[i].id == this.fromdata.selectcat
            ) {
              obj.type = "selectcat";
              obj.text = this.filtersData.catFiltersSoft[i];
            }
          }
          this.filters.catFilter = obj;
        }
        if (this.fromdata.selectsubcat != null) {
          let obj = {};
          for (let i in this.filtersData.subCatFilters) {
            if (
              this.filtersData.subCatFilters[i].id == this.fromdata.selectsubcat
            ) {
              obj.type = "selectsubcat";
              obj.text = this.filtersData.subCatFilters[i];
            }
          }
          this.filters.subcatFilter = obj;
        } else {
          this.filters.subcatFilter = null;
        }
        if (this.fromdata.selectbrand != null) {
          let obj = {};

          for (let i in this.filtersData.brandFilters) {
            if (
              this.filtersData.brandFilters[i].id == this.fromdata.selectbrand
            ) {
              obj.type = "selectbrand";
              obj.text = this.filtersData.brandFilters[i];
            }
          }
          this.filters.brandfilter = obj;
        }
        console.log(this.filters);
      });
    },
    getproductList() {
      this.loading = true;
      window.scrollTo(0, 0);
      let pages = {
        page: this.page
      };
      let params = Object.assign(pages, this.fromdata);
      product(params).then((res) => {
        let result = res.data.productList;
        this.loading = false;
        this.productList = result.data;
        this.libInfo = res.data.libInfo;
        this.totals = result.total;
        this.last_page = result.from;
        this.per_page = result.to;
      });
      this.getproductFilters();
    },

    checkFilters(val, type, item) {
      let obj = {
        type: type,
        text: item
      };
      this.searchText = item;
      this.page = 1;
      if (type == "hl") {
        this.fromdata.hl = val;
        this.filters.hlfilter = obj;
        this.fromdata.selectbrand = null;
        this.fromdata.selectcat = null;
        this.fromdata.selectseries = null;
        this.fromdata.selectcolor = null;
        this.fromdata.selectmaterial = null;
        this.fromdata.lib = null;
        this.fromdata.selectsubcat = null;
        this.dele('is_hl');
        console.log(val, obj);
      } else if (type == "selectbrand") {
        this.fromdata.selectbrand = val;
        this.filters.brandfilter = obj;
      } else if (type == "selectcat") {
        this.fromdata.selectcat = val;
        this.filters.catFilter = obj;
      } else if (type == "selectseries") {
        this.fromdata.selectseries = val;
        this.seriesFilter = obj;
      } else if (type == "selectcolor") {
        this.fromdata.selectcolor = val;
        this.filters.colorFilter = obj;
      } else if (type == "selectmaterial") {
        this.fromdata.selectmaterial = val;
        this.filters.materialFilter = obj;
      } else if (type == "lib") {
        this.fromdata.lib = val;
        this.filters.libFilter = obj;
      } else if (type == "selectsubcat") {
        this.fromdata.selectsubcat = val;
        this.filters.subcatFilter = obj;
      }
      this.pathChange(type);
      this.getproductList();
    },
    pathChange(type) {
      console.log(type);
      const libData = {};
      if (type === "lib") {
        libData.lib = this.fromdata.lib;
        (libData.page = this.page),
          (libData.sort = this.fromdata.sort),
          (libData.num = this.fromdata.num),
          this.$router.push({
            path: "/product",
            query: libData
          });
      } else {
        // queryData.selectcat = this.fromdata.selectcat
        const queryData = {
          hl: this.fromdata.hl,
          selectcat: this.fromdata.selectcat,
          selectbrand: this.fromdata.selectbrand,
          selectseries: this.fromdata.selectseries,
          selectcolor: this.fromdata.selectcolor,
          selectmaterial: this.fromdata.selectmaterial,
          selectsubcat: this.fromdata.selectsubcat,
          page: this.page,
          sort: this.fromdata.sort,
          num: this.fromdata.num,
          inputmode: this.fromdata.inputmode
        };
        Object.keys(queryData).forEach((item) => {
          const key = queryData[item];
          if (key == null) {
            delete queryData[item];
          }
        });
        this.$router.push({
          path: "/product",
          query: queryData
        });
      }
    },
    dialogChange(e) {
      this.fromdata.forRegion = e;
      this.getproductList();
    },
    getPage(e) {
      this.page = e;
      this.pathChange();
      this.getproductList();
    },

    getSort(e) {
      this.fromdata.sort = e;
      this.pathChange();
      this.getproductList();
    },
    getPagenum() {
      this.page = 1;
      this.pathChange();
      // this.fromdata.num = e;
      this.getproductList();
    },
    dele(type) {
      if (type != "is_hl") {
        if (type == "hl") {
          this.fromdata.hl = null;
          this.filters.hlfilter = null;
        } else if (type == "selectbrand") {
          this.fromdata.selectbrand = null;
          this.filters.brandfilter = null;
        } else if (type == "selectcat") {
          this.fromdata.selectcat = null;
          this.filters.catFilter = null;
        } else if (type == "selectseries") {
          this.fromdata.selectseries = null;
          this.seriesFilter = null;
        } else if (type == "selectcolor") {
          this.fromdata.selectcolor = null;
          this.filters.colorFilter = null;
        } else if (type == "selectmaterial") {
          this.fromdata.selectmaterial = null;
          this.filters.materialFilter = null;
        } else if (type == "lib") {
          this.fromdata.lib = null;
          // this.filters.libFilter=null;
        } else if (type == "selectsubcat") {
          this.fromdata.selectsubcat = null;
          this.filters.subcatFilter = null;
        }
        this.pathChange(type);
      } else {
        this.fromdata.selectbrand = null;
        this.filters.brandfilter = null;
        this.fromdata.selectcat = null;
        this.filters.catFilter = null;
        this.fromdata.selectseries = null;
        this.seriesFilter = null;
        this.fromdata.selectcolor = null;
        this.filters.colorFilter = null;
        this.fromdata.selectmaterial = null;
        this.filters.materialFilter = null;
        this.fromdata.lib = null;
        this.fromdata.selectsubcat = null;
        this.filters.subcatFilter = null;
      }
      this.getproductList();
    },
    // handleSizeChange(val) {
    //     this.page = 1;
    //     this.fromdata.num = val;
    //     this.getproductList();
    // },
    handleCurrentChange(val) {
      this.page = val;
      this.pathChange();
      this.getproductList();
    },
    setRegion(type) {
      localStorage.setItem("forRegion", type);
      const data = {
        region: type
      };
      region(data).then((res) => {
        if (res.status == "success") {
          this.$router.go(0);
        }
      });
    }
  },
  mounted() {
    // this.getproductFilters();
  }
};
</script>
<style lang="scss" scoped>
.content {
  padding: 25px 0 40px;
  @media (max-width: 567px) {
    padding-top: 0;
  }
  .col-fixed-280 {
    width: 200px;
    position: absolute;
    height: 100%;
    z-index: 1;
  }
  .col-offset-280 {
    padding-left: 215px;
    @media (max-width: 567px) {
      padding-left: 0;
    }
    .bv-example-row {
      max-width: none !important;
      font-size: 12px;
      padding: 0 15px;
      .no-of-result {
        margin-top: 18px;
        @media (max-width: 991px) {
          order: 2;
          margin-top: 5px;
        }
        .resultInfo {
          padding-top: 4px;
          margin-bottom: 18px;
          line-height: 25px;
          @media (max-width: 1399px) {
            margin-bottom: 0px;
          }
          @media (max-width: 991px) {
            margin-bottom: 15px;
          }
          b {
            display: inline-block;
            font-size: 12px;
            line-height: 15px;
            border: 1px solid #ededed;
            border-radius: 5px;
            background: #f3f3f3;
            padding: 2px 6px 3px 6px;
            margin: 0px 2px 2px;
            font-weight: 700;
            span {
              color: #aaaaaa;
              font-size: 17px;
              line-height: 17px;
              vertical-align: text-bottom;
              cursor: pointer;
              &:hover {
                color: #c63033;
              }
            }
          }
        }
      }
      .sorting {
        margin-top: 0;
        @media (max-width: 991px) {
          order: 1;
          justify-content: space-between !important;
          margin-top: 23px;
          padding: 0 2.5px;
        }
        @media (max-width: 767px) {
          flex-wrap: wrap;
        }
        @media (max-width: 567px) {
          margin-top: 12px;
        }
        .forRegion {
          align-items: center;
          display: flex;
          margin-left: 10px;
          label {
            margin-right: 7px;
            @media (max-width: 1399px) {
              margin-right: 0px;
            }
          }
          @media (max-width: 991px) {
            margin: 0 0 15px;
            padding: 0 5px;
            width: 50%;
          }
          @media (max-width: 767px) {
            width: 50%;
          }
        }
        .select-list {
          display: inline-block;
          vertical-align: middle;
          height: 30px;
          padding: 6px 8px;
          font-size: 12px;
          line-height: 1.5;
          border-radius: 3px;
          margin-left: 0px;
          max-width: 110px;
          width: auto;
          color: #555;
          background-color: #fff;
          background-image: none;
          border: 1px solid #ccc;
          border-radius: 4px;
          @media (max-width: 1399px) {
            width: 100%;
            max-width: none;
          }
          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }
    }
  }
  .left-catmenu {
    padding: 0px 0 10px 15px;
    text-align: left;
    font-size: 12px;
    @media (max-width: 567px) {
      width: 100%;
      position: relative;
      height: auto;
      padding: 10px 15px 0;
      margin-top: 0px;
      margin-bottom: 15px;
      /* background: #F0F0F0; */
      border-bottom: 1px solid #e7e7e7;
      /* margin-top: 30px;*/
    }
    .search-toggle {
      margin: -10px -15px 0px;
      padding: 15px 15px 11px;
      font-weight: bold;
      vertical-align: middle;
      color: #555555 !important;
      /*text-transform: uppercase;*/
      display: block;
      @media (min-width: 568px) {
        display: none;
      }
      span {
        font-size: 18px;
        vertical-align: middle;
        color: #555555;
        margin-right: 5px;
      }
      .fa-minus-square-o {
        font-weight: bold;
      }
    }
    .collapse {
      display: none;
      visibility: hidden;
      @media (max-width: 567px) {
        padding: 15px 15px 0px;
        margin: 0px -15px;
        border-top: 1px solid #e7e7e7;
      }
      @media (min-width: 568px) {
        display: block !important;
        visibility: visible !important;
      }
      .selLibrary {
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 15px;
        font-size: 14px;
        margin-top: 10px;
      }
      ul {
        position: relative;
        li {
          font-weight: normal;
          font-size: 12px;
          cursor: pointer;
          line-height: 180%;
          span,
          label {
            margin-left: 0px;
            display: inline-block;
            max-width: 80%;
            vertical-align: top;
            cursor: pointer;
          }
          input {
            margin-right: 5px;
          }
          &:hover {
            color: #c63033;
          }
        }
        .active {
          border-right: 2px solid #c63033;
          background-color: #f3f3f3;
        }
      }
      .scroll-cont {
        max-height: 235px;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
      }
      @media (min-width: 568px) {
        display: block !important;
        visibility: visible !important;
      }
    }
    .collapse.in {
      display: block;
      visibility: visible;
    }
    /deep/ .custom-control-label {
      margin-left: 5px;
      max-width: 80%;
    }
    /deep/ .col-form-label {
      font-weight: bold;
      font-size: 14px;
    }
    /deep/ .custom-control {
      margin-bottom: 5px;
      display: flex;
    }
    /deep/ .bv-no-focus-ring {
      max-height: 235px;
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
      position: relative;
    }
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
}

/deep/ .el-loading-mask {
  position: fixed;
}
/deep/ .el-pagination.is-background {
  .btn-next,
  .btn-prev {
    background: none;
  }
  .el-pager li {
    background: none;
    &:not(.disabled).active {
      background: #cccccc;
    }
  }
}
/deep/ .pagination {
  @media (max-width: 768px) {
    /*display: none;*/
    .el-pager li {
      margin: 0px;
    }
  }
}
</style>
