<template>
  <div class="content" v-loading="loading">
    <b-container fluid>
      <b-row class="deliverables" ref="bookListWrapper">
        <b-col md="12">
          <div class="name mgb20 tc">
            <div>
              {{ $t("welcome.design_deliverable") }}
            </div>
            <div class="coCat tc mgt10">
              <el-select
                popper-append-to-body
                class=""
                v-model="select.sort"
                placeholder-color="#555"
                @change="getchangevalue"
                :style="{ width: selectOptionWidth.sort }"
              >
                <el-option
                  v-for="item in sortOption"
                  :key="item.value"
                  :label="$t('welcome.' + item.text)"
                  @click.native="setOptionWidth($event, 'sort')"
                  :value="item.value"
                >
                  <span style="float: left">{{
                    $t("welcome." + item.text)
                  }}</span>
                </el-option>
              </el-select>
              <el-select
                popper-append-to-body
                class=""
                v-model="select.brand"
                placeholder-color="#555"
                @change="getchangevalue"
                :style="{ width: selectOptionWidth.brand }"
              >
                <el-option
                  v-for="(item, key) in brandOption"
                  :key="key"
                  :label="item"
                  @click.native="setOptionWidth($event, 'brand')"
                  :value="key"
                >
                  <span style="float: left">{{ item }}</span>
                </el-option>
              </el-select>
              <el-select
                popper-append-to-body
                class=""
                v-model="select.branch"
                placeholder-color="#555"
                @change="getchangevalue"
                :style="{ width: selectOptionWidth.branch }"
              >
                <el-option
                  v-for="(item, key) in branchOption"
                  :key="key"
                  @click.native="setOptionWidth($event, 'branch')"
                  :label="item"
                  :value="key"
                >
                  <span style="float: left">{{ item }}</span>
                </el-option>
              </el-select>
              <el-select
                popper-append-to-body
                class=""
                v-model="select.team"
                placeholder-color="#555"
                @change="getchangevalue"
                :style="{ width: selectOptionWidth.team }"
              >
                <el-option
                  v-for="(item, key) in teamOption"
                  :key="key"
                  :label="item"
                  @click.native="setOptionWidth($event, 'team')"
                  :value="key"
                >
                  <span style="float: left">{{ item }}</span>
                </el-option>
              </el-select>
              <el-select
                popper-append-to-body
                class=""
                v-model="select.developer"
                placeholder-color="#555"
                :style="{ width: selectOptionWidth.developer }"
                @change="getchangevalue"
              >
                <el-option
                  v-for="(item, key) in developerOption"
                  :key="key"
                  :label="item"
                  @click.native="setOptionWidth($event, 'developer')"
                  :value="key"
                >
                  <span style="float: left">{{ item }}</span>
                </el-option>
              </el-select>
              <el-select
                popper-append-to-body
                class=""
                v-model="select.loc"
                placeholder-color="#555"
                :style="{ width: selectOptionWidth.loc }"
                @change="getchangevalue"
              >
                <el-option
                  v-for="(item, key) in locOption"
                  :key="key"
                  @click.native="setOptionWidth($event, 'loc')"
                  :label="item"
                  :value="key"
                >
                  <span style="float: left">{{ item }}</span>
                </el-option>
              </el-select>
              <el-select
                popper-append-to-body
                class=""
                v-model="select.type"
                placeholder-color="#555"
                :style="{ width: selectOptionWidth.optType }"
                @change="getchangevalue"
              >
                <el-option
                  v-for="(item, key) in opt_type"
                  :key="key"
                  @click.native="setOptionWidth($event, 'optType')"
                  :label="item"
                  :value="key"
                >
                  <span style="float: left">{{ item }}</span>
                </el-option>
              </el-select>
              <el-select
                popper-append-to-body
                class=""
                v-model="select.area"
                :style="{ width: selectOptionWidth.optArea }"
                placeholder-color="#555"
                @change="getchangevalue"
              >
                <el-option
                  v-for="(item, key) in opt_area"
                  :key="key"
                  :label="item"
                  @click.native="setOptionWidth($event, 'optArea')"
                  :value="key"
                >
                  <span style="float: left">{{ item }}</span>
                </el-option>
              </el-select>
              <el-select
                popper-append-to-body
                class=""
                v-model="select.sfw"
                :style="{ width: selectOptionWidth.sfw }"
                placeholder-color="#555"
                @change="getchangevalue"
              >
                <el-option
                  v-for="(item, key) in sfw"
                  :key="key"
                  @click.native="setOptionWidth($event, 'sfw')"
                  :label="item"
                  :value="key"
                >
                  <span style="float: left">{{ item }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
        </b-col>
        <b-col md="12">
          <deliverable-list
            :deliverable="deliverable"
            :result-data="resultData"
            :related-projects="relatedProjects"
          ></deliverable-list>
          <div class="flex-jus-center load-more">
            <span v-if="!isLoadMore"
              >{{ $t("common.loadover") }}</span
            >
            <b-button
              v-if="isLoadMore"
              @click="LoadMore"
              variant="light"
              size="sm"
              class="btn-load-more"
              >{{ $t("common.loadmore") }}</b-button
            >
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { getDeliverables, deliverableSelections } from "@/api/design";
import deliverableList from "@/components/Guideline/Deliverable";

export default {
  components: {
    "deliverable-list": deliverableList
  },
  data() {
    return {
      mid: 3,
      selectOptionWidth: {
        sort:
          window.localStorage.getItem("language") == "en" ? "200px" : "125px",
        brand: "86px",
        branch: "72px",
        team:
          window.localStorage.getItem("language") == "en" ? "120px" : "86px",
        developer: "78px",
        loc: window.localStorage.getItem("language") == "en" ? "130px" : "86px",
        optType:
          window.localStorage.getItem("language") == "en" ? "130px" : "86px",
        optArea: "64px",
        sfw: window.localStorage.getItem("language") == "en" ? "240px" : "86px"
      },
      loading: false,
      imgurl: process.env.VUE_APP_IMG_URL,
      sortOption: [
        {
          value: "",
          text: "sortby"
        },
        {
          value: "asc",
          text: "sort_asc_update"
        },
        {
          value: "desc",
          text: "sort_desc_update"
        }
      ],
      brandOption: [],
      branchOption: [],
      teamOption: [],
      developerOption: [],
      locOption: [],
      opt_type: [],
      opt_area: [],
      sfw: [],
      select: {
        sort: "desc",
        brand: "",
        branch: "",
        team: "",
        developer: "",
        loc: "",
        type: "",
        area: "",
        sfw: "",
        del_num: 500,
        del_page: 1
      },
      recordCount: 0,
      isLoadMore: true,
      deliverable: [],
      areaImgList: [],
      resultData: {},
      relatedProjects: {}
    };
  },
  created() {
    console.log("created");
    this.select.sort = this.$route.query.sort ? this.$route.query.sort : "desc";
    this.select.brand = this.$route.query.brand ? this.$route.query.brand : "";
    this.select.branch = this.$route.query.branch
      ? this.$route.query.branch
      : "";
    this.select.team = this.$route.query.team ? this.$route.query.team : "";
    this.select.developer = this.$route.query.developer
      ? this.$route.query.developer
      : "";
    this.select.loc = this.$route.query.loc ? this.$route.query.loc : "";
    this.select.type = this.$route.query.type ? this.$route.query.type : "";
    this.select.area = this.$route.query.area ? this.$route.query.area : "";
    this.select.sfw = this.$route.query.sfw ? this.$route.query.sfw : "";
    this.select.del_num = this.$route.query.del_num
      ? Number(this.$route.query.del_num)
      : 100;
    this.select.del_page = this.$route.query.del_page
      ? Number(this.$route.query.del_page)
      : 1;
    if (
      this.$route.query.brand ||
      this.$route.query.branch ||
      this.$route.query.team ||
      this.$route.query.developer ||
      this.$route.query.loc ||
      this.$route.query.type ||
      this.$route.query.area ||
      this.$route.query.sfw
    ) {
      this.selectWidth = JSON.parse(
        window.sessionStorage.getItem("selectWidth")
      );
      if (this.selectWidth) {
        this.selectOptionWidth = this.selectWidth;
      }
    } else {
      const selectWidth = {
        sort:
          window.localStorage.getItem("language") == "en" ? "200px" : "125px",
        brand: "86px",
        branch: "72px",
        team:
          window.localStorage.getItem("language") == "en" ? "120px" : "86px",
        developer: "78px",
        loc: window.localStorage.getItem("language") == "en" ? "130px" : "86px",
        optType:
          window.localStorage.getItem("language") == "en" ? "130px" : "86px",
        optArea: "64px",
        sfw: window.localStorage.getItem("language") == "en" ? "240px" : "86px"
      };
      this.selectOptionWidth = selectWidth;
    }
    // console.log("on created")
    this.getDeliverable();
    this.getDeliverableSelection();
  },
  beforeRouteLeave(to, from, next) {
    this.$route.meta.savedPosition = window.pageYOffset;
    next();
  },

  watch: {
    $route() {
      console.log("route changed!");
      this.select.sort = this.$route.query.sort ? this.$route.query.sort : "";
      this.select.brand = this.$route.query.brand
        ? this.$route.query.brand
        : "";
      this.select.branch = this.$route.query.branch
        ? this.$route.query.branch
        : "";
      this.select.team = this.$route.query.team ? this.$route.query.team : "";
      this.select.developer = this.$route.query.developer
        ? this.$route.query.developer
        : "";
      this.select.loc = this.$route.query.loc ? this.$route.query.loc : "";
      this.select.type = this.$route.query.type ? this.$route.query.type : "";
      this.select.area = this.$route.query.area ? this.$route.query.area : "";
      this.select.sfw = this.$route.query.sfw ? this.$route.query.sfw : "";
      this.select.del_num = this.$route.query.del_num
        ? Number(this.$route.query.del_num)
        : 100;
      this.select.del_page = this.$route.query.del_page
        ? Number(this.$route.query.del_page)
        : 1;
      this.selectWidth = JSON.parse(
        window.sessionStorage.getItem("selectWidth")
      );
      if (
        this.$route.query.brand ||
        this.$route.query.branch ||
        this.$route.query.team ||
        this.$route.query.developer ||
        this.$route.query.loc ||
        this.$route.query.type ||
        this.$route.query.area ||
        this.$route.query.sfw
      ) {
        if (this.selectWidth) {
          this.selectOptionWidth = this.selectWidth;
        }
      } else {
        const selectWidth = {
          sort:
            window.localStorage.getItem("language") == "en" ? "200px" : "125px",
          brand: "86px",
          branch: "72px",
          team:
            window.localStorage.getItem("language") == "en" ? "120px" : "86px",
          developer: "78px",
          loc:
            window.localStorage.getItem("language") == "en" ? "130px" : "86px",
          optType:
            window.localStorage.getItem("language") == "en" ? "130px" : "86px",
          optArea: "64px",
          sfw:
            window.localStorage.getItem("language") == "en" ? "240px" : "86px"
        };
        this.selectOptionWidth = selectWidth;
      }

      this.recordCount =0;
      this.isLoadMore = true;
      // console.log("on watch route");
      // this.getDeliverable();
      // this.getDeliverableSelection();
    }
  },
  methods: {
    LoadMore() {
      // if (this.deliverable.length >= this.select.del_num) {
        this.select.del_num = this.select.del_num + 100;
        this.getDeliverable();
      // }
    },
    setOptionWidth(event, type) {
      this.$nextTick(() => {
        if (event.target.localName == "span") {
          this.nodeIndex = event.target.clientWidth + 30;
        } else {
          this.nodeIndex = event.target.childNodes[0].clientWidth + 30;
        }
        this.selectOptionWidth[type] = this.nodeIndex + "px";
        window.sessionStorage.setItem(
          "selectWidth",
          JSON.stringify(this.selectOptionWidth)
        );
      });
      this.select.del_page = 1;
      this.pathChange();
    },
    pathChange() {
      const queryData = {
        sort: this.select.sort,
        brand: this.select.brand,
        branch: this.select.branch,
        team: this.select.team,
        developer: this.select.developer,
        loc: this.select.loc,
        type: this.select.type,
        area: this.select.area,
        sfw: this.select.sfw,
      };
      Object.keys(queryData).forEach((item) => {
        const key = queryData[item];
        if (key == "") {
          delete queryData[item];
        }
      });
      this.$router.push({
        path: "/deliverable",
        query: queryData
      });
    },
    getDeliverable() {
      this.loading = true;
      getDeliverables(this.select).then((res) => {
        this.loading = false;
        this.deliverable = res.data.list;
        this.resultData = res.data;
        let arr = [];
        for (let i in res.data.areaImgList) {
          if (res.data.areaImgList[i]) {
            arr.push(res.data.areaImgList[i][0]);
          }
        }
        this.areaImgList = arr;
        this.relatedProjects = res.data.relatedProjects;

        if (res.data.list.length > this.recordCount) {
          this.recordCount = res.data.list.length;
          this.isLoadMore = true;
        } else {
          this.isLoadMore = false;
        }

      });
    },
    getDeliverableSelection() {
      deliverableSelections().then((res) => {
        // this.resultList = res.data;
        this.opt_type = res.data.optTypeList;
        this.brandOption = res.data.brandList;
        this.branchOption = res.data.branchList;
        this.teamOption = res.data.teamList;
        this.developerOption = res.data.developerList;
        this.locOption = res.data.locList;
        this.opt_type = res.data.optTypeList;
        this.opt_area = res.data.optAreaList;
        this.sfw = res.data.sfwList;
      });
    },
    goPage(id) {
      this.$router.push(`/deliverable-detail?id=${id}`);
    },
    getchangevalue() {
      // console.log("getchangevalue");
    },
    changeArr(list) {
      let arr = [];
      for (let i in list) {
        if (list[i]) {
          arr.push(list[i][0]);
        }
      }
      return arr;
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  padding: 25px 0 40px;
  overflow-x: hidden;
  @media (max-width: 767px) {
    padding-top: 50px;
  }
  .name {
    padding: 20px 0px 20px;
    font-size: 21px;
  }
}
/deep/ .el-input__inner {
  border: none;
  font-size: 13px;
  color: #555;
  text-align: center;
  padding-left: 0;
}
.width125 /deep/ .el-input__inner {
  width: 125px;
}
.width59 /deep/ .el-input__inner {
  width: 95px;
}
.width72 /deep/ .el-input__inner {
  width: 72px;
}
.width86 /deep/ .el-input__inner {
  width: 86px;
}
.width73 /deep/ .el-input__inner {
  width: auto;
}
.width60 /deep/ .el-input__inner {
  width: 60px;
}
.deliverables {
  max-width: 1530px;
  margin: 0 auto;
  // text-align: center;
  .col-md-12 {
    padding: 0;
  }
}
.load-more {
  color: #999999;
  font-size: 13px;
  margin-top: -60px;
  .btn-load-more {
      background: #a1a1a1;
      font-size: 13px;
      font-weight: normal;
      padding: 6px 10px;
      min-width: 160px;
      color: #fff;
      cursor: pointer;
      &:hover {
        background: #999;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
  }
}
</style>
