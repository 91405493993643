<template>
  <div class="layoutWrap posiR full-height">
      <head-nav />
      <!-- <el-scrollbar class="mains"> -->
        <router-view> </router-view>
      <!-- </el-scrollbar> -->
  </div>
</template>

<script>
import headNav from '@/components/HeadNav.vue'
export default {
    name: 'layout',
    components: {
        'head-nav':headNav
    },
    
}
</script>

<style lang="scss" >
.full-height{
  height: 100vh;
  padding-top: 50px;
  @media (max-width: 1329px){
    padding-top: 94px;
  }
  @media (max-width: 991px){
    padding-top: 50px;
  }
  @media (max-width: 610px){
    padding-top: 93px;
  }
}
html[lang="en"] .full-height {
  @media (max-width: 1579px){
    padding-top: 94px;
  }
  @media (max-width: 1079px) {
    padding-top: 50px;
  }
  @media (max-width: 620px){
    padding-top: 93px;
  }
}

</style>