<template>
  <div class="content" v-loading="loading">
    <b-container fluid>
      <b-row class="drs">
        <b-col md="12">
          <div class="name mgb20 tc">
            <div v-if="pageType == 'brands'">
              {{ $t('Strateg.strategic_brands') }}
            </div>
            <div v-else>
              {{ $t('Strateg.strategic_distributors') }}
            </div>
            <div class="coCat tc mgt10">
              <el-select
                popper-append-to-body
                :style="{ width: selectOptionWidth }"
                v-model="cat"
                @change="getchangevalue"
              >
                <el-option
                  v-for="item in productCat"
                  :key="item.id"
                  @click.native="setOptionWidth"
                  :label="item.name"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.name }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
        </b-col>
        <b-col md="12" v-if="pageType == 'brands'">
          <brand-list :brand="brand" v-if="brand != ''"></brand-list>
        </b-col>

        <b-col md="12" v-if="pageType == 'distributors'">
          <distributor-list
            :distributor-data="distributorData"
            v-if="distributorData.length > 0"
          ></distributor-list>
          <div
            style="color: gainsboro; line-height: 22px"
            class="ft18 mgb50"
            v-else
          >
            {{ $t('common.result_none') }}
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { getproductcats, getBrand, getVendor } from '@/api/brand'
import brandList from '@/components/Cooperative/BrandList'
import distributorsList from '@/components/Cooperative/DistributorsList'
export default {
  components: {
    'brand-list': brandList,
    'distributor-list': distributorsList,
  },
  data() {
    return {
      selectOptionWidth: '145px',
      loading: false,
      value: null,
      productCat: [], //catgoryList
      brand: [],
      stratype: 1, // strategic：1 or
      cat: null,
      imgurl: process.env.VUE_APP_IMG_URL,
      pageType: 'brands', //brands or distributors
      loc: '', //distributors
      distributorData: [],
    }
  },
  created() {
    this.cat = this.$route.query.cat ? Number(this.$route.query.cat) : null
    this.pageType = decodeURIComponent(this.$route.query.type)
    this.selectWidth = window.sessionStorage.getItem('selectWidth')
    if (this.$route.query.cat) {
      this.selectOptionWidth = this.selectWidth
    }else{
      this.selectOptionWidth = '124px'
    }
    this.getProductcats()
    if (this.$route.query.type == 'brands') {
      this.getBrands()
    } else {
      this.getVendor()
    }
  },

  watch: {
    $route() {
      this.cat = this.$route.query.cat ? Number(this.$route.query.cat) : null
      this.pageType = decodeURIComponent(this.$route.query.type)
      this.selectWidth = window.sessionStorage.getItem('selectWidth')
      if (this.$route.query.cat) {
        this.selectOptionWidth = this.selectWidth
      }else{
      this.selectOptionWidth = '124px'
    }
      this.getProductcats()
      if (this.$route.query.type == 'brands') {
        this.getBrands()
      } else {
        this.getVendor()
      }
    },
  },
  methods: {
    setOptionWidth(event) {
      this.$nextTick(() => {
        if (event.target.localName == 'span') {
          this.nodeIndex = event.target.clientWidth + 50
        } else {
          this.nodeIndex = event.target.childNodes[0].clientWidth + 50
        }
        this.selectOptionWidth = this.nodeIndex + 'px'
        window.sessionStorage.setItem('selectWidth', this.selectOptionWidth)
      })
      if (this.cat) {
        this.$router.push({
          path: '/strategic/brand',
          query: { type: this.pageType, cat: this.cat },
        })
      } else {
        this.$router.push({
          path: '/strategic/brand',
          query: { type: this.pageType },
        })
      }
    },
    getProductcats() {
      let select = this.$t('Strateg.product_cat')
      getproductcats().then((res) => {
        let result = res.data
        this.productCat = select.concat(result)
      })
    },
    getBrands() {
      this.loading = true
      getBrand(this.stratype, this.cat).then((res) => {
        this.loading = false
        this.brand = res.data
      })
    },
    getVendor() {
      this.loading = true
      let params = {
        cat: this.cat == null ? '' : this.cat,
        loc: this.loc,
      }
      getVendor(this.stratype, params).then((res) => {
        this.loading = false
        this.distributorData = res.data
      })
    },
    getchangevalue(e) {
      // this.cat = e
      if (this.$route.query.type == 'brands') {
        this.getBrands()
      } else {
        this.getVendor()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  padding: 25px 0 40px;
  overflow-x: hidden;
  .name {
    padding: 20px 0px 20px;
    font-size: 21px;
  }
}
/deep/ .el-input__inner {
  border: none;
  font-size: 13px;
  color: #555;
  text-align: center;
}
</style>
