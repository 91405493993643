<template>
  <div class="content" v-loading="loading">
    <b-container fluid>
      <b-row class="deliverables pdl15 pdr15">
        <b-col md="12">
          <div class="name mgb20 tc">
            <div>
              <span><a :href="'/#/project-photo?id='+ id">{{ refname }} </a>/ </span>
              {{ resListname }}
            </div>
            <div class="coCat tc mgt10">
              <el-select
                popper-append-to-body
                v-model="select.sort"
                :style="{ width: selectOptionWidth }"
                placeholder-color="#555"
                @change="getchangevalue"
              >
                <el-option
                  v-for="item in $t('common.sortOption')"
                  :key="item.value"
                  @click.native="setOptionWidth"
                  :label="item.text"
                  :value="item.value"
                >
                  <span style="float: left">{{ item.text }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
        </b-col>

        <b-col md="12">
          <div class="select-upload">
            <el-checkbox
              style="color: #555"
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
              >{{ $t('welcome.select_all') }}</el-checkbox
            >
            <span @click="downloadFile" class="icon-download">
              <i class="fa fa-download text-upload"></i>{{ $t('welcome.download') }}
            </span>
          </div>
        </b-col>

        <b-col md="12" v-loading="uploadImg">
          <el-checkbox-group
            v-model="checkedUpload"
            @change="handleCheckedUploadChange"
          >
          <template v-if="Object.values(albumList).length > 0">
            <div v-for="(grp, inx) in albumList" :key="inx">
              <h6 class="album-sub-head">{{ inx }}</h6>
              <ul class="album-list flex-around flex-wrap">
                <li v-for="(item, i) in grp" :key="i">
                  <a
                  :href="imgurl + item.path"
                  data-fancybox="gallery"
                  data-preload="false"
                  :data-caption="item.filename"
                  class="img-link">
                    <div class="mood-img">
                      <img class="img" v-lazy="imgurl + item.path+'/s300'" />
                    </div>
                  </a>

                  <label>
                    <h2>{{ item.filename }}</h2>
                  <el-checkbox class="hover-checkbox" :label="item.id">
                  </el-checkbox>
                  </label>
                </li>
                <li class="forJustify"></li>
                <li class="forJustify"></li>
                <li class="forJustify"></li>
                <li class="forJustify"></li>
                <li class="forJustify"></li>
                <li class="forJustify"></li>
                <li class="forJustify"></li>
              </ul>
            </div>
          </template>

          <template v-else>
            <ul class="album-list flex-around flex-wrap">
              <li v-for="(item, i) in itemList" :key="i">
                <a
                :href="imgurl + item.path"
                data-fancybox="gallery"
                data-preload="false"
                :data-caption="item.filename"
                class="img-link">
                  <div class="mood-img">
                    <img class="img" v-lazy="imgurl + item.path+'/s300'" />
                  </div>
                </a>

                <label>
                  <h2>{{ item.filename }}</h2>
                <el-checkbox class="hover-checkbox" :label="item.id">
                </el-checkbox>
                </label>
              </li>
              <li class="forJustify"></li>
              <li class="forJustify"></li>
              <li class="forJustify"></li>
              <li class="forJustify"></li>
              <li class="forJustify"></li>
              <li class="forJustify"></li>
              <li class="forJustify"></li>
            </ul>

          </template>

          </el-checkbox-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { getDesignrefdetailList,getFiles } from '@/api/design'
import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox.css'
export default {
  data() {
    return {
      uploadImg:false,
      checkAll: false,
      checkedUpload: [],
      isIndeterminate: false,
      selectOptionWidth: '86px',
      id: null,
      refId: null,
      loading: false,
      imgurl: process.env.VUE_APP_IMG_URL,
      setImgUrl:process.env.VUE_APP_BASE_API,
      select: {
        sort: 'asc',
      },
      refname: '',
      albumList: [],
      itemList: [],
      resListname: '',
    }
  },
  mounted() {
    Fancybox.bind('[data-fancybox]', {
      // Your options go here
      Thumbs: false,
    })
  },
  created() {
    this.id = this.$route.query.id
    this.refId = this.$route.query.refId
    this.getDesign()
  },

  methods: {
    downloadFile() {
      this.uploadImg = true
       let uploadList = this.checkedUpload.join(",")
       const data = {
         target:"designresattach",
         act:"dl",
         ids:uploadList
       }
      getFiles(data)
        .then((res) => {
            let fileData = []
            fileData.push(res)
            let blob = new Blob(fileData, { type: res.type })
            const elink = document.createElement('a')
            elink.download = this.resListname + '.zip'
            elink.style.display = 'none'
            elink.href = window.URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href)
            document.body.removeChild(elink)
            this.uploadImg = false
        })
        .catch((error) => {
            this.uploadImg = false
        })
    },
    handleCheckAllChange(val) {
      const idList = []
      this.itemList.map((el) => {
        idList.push(el.id)
      })

      this.checkedUpload = val ? idList : []
      this.isIndeterminate = false
    },
    handleCheckedUploadChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.itemList.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.itemList.length
    },
    setOptionWidth(event) {
      this.$nextTick(() => {
        if (event.target.localName == 'span') {
          this.nodeIndex = event.target.clientWidth + 30
        } else {
          this.nodeIndex = event.target.childNodes[0].clientWidth + 30
        }
        this.selectOptionWidth = this.nodeIndex + 'px'
      })
    },
    goPage(url) {
      this.$router.push(url)
    },
    getchangevalue(e) {
      console.log(e)
    },
    getDesign() {
      // console.log("called getDesign");
      if (this.id && this.refId) {
        this.loading = true;
        getDesignrefdetailList(this.id, this.refId).then((res) => {
          this.loading = false;
          let result = res.data;
          this.refname = result.ref.name;
          this.albumList = result.itemGrp;
          this.itemList = result.itemList;
          this.resListname = result.targetResList.name;
          // this.resultList = res.data;
        })
      }

    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  padding: 25px 0 40px;
  overflow-x: hidden;
  .name {
    padding: 20px 0px 20px;
    font-size: 21px;
    span {
      color: #999;
      font-size: 12px;
    }
  }
  .deliverables {
    max-width: 1530px;
    margin: 0 auto;
    text-align: left;
    @media (max-width: 1023px) {
      margin: 0 -15px;

    }
    @media (max-width: 767px) {
      margin: 0 -15px;
    }
    .col-md-12 {
      padding: 0;
    }
  }
}
/deep/ .el-input__inner {
  border: none;
  font-size: 13px;
  color: #555;
  text-align: center;
  padding-left: 0;
}
.width125 /deep/ .el-input__inner {
  width: 125px;
}
.width59 /deep/ .el-input__inner {
  width: 86px;
}
.width72 /deep/ .el-input__inner {
  width: 72px;
}
.width86 /deep/ .el-input__inner {
  width: 86px;
}
.width73 /deep/ .el-input__inner {
  width: 73px;
}
.width60 /deep/ .el-input__inner {
  width: 60px;
}
h6.album-sub-head {
  font-size: 15px;
  color: #aaaaaa;
  margin: 0px 0px 25px;
  text-align: center;
}
.album-list {
  list-style: none;
  margin: 0 auto;
  // max-width: 1100px;
  width: 100%;
  padding: 0 0 20px;
  font-size: 0;
  margin-bottom: 30px;
  align-items: flex-start;

  li {
    z-index: 0;
    width: 11.5%;
    text-align: center;
    font-size: 15px;
    margin: 0px 0px 1% 0;
    padding: 10px;
    vertical-align: top;
    position: relative;
    @media (max-width: 1500px) {
      width: 12.5%;
    }
    @media (max-width: 1023px) {
      width: 20%;
    }
    @media (max-width: 767px) {
      margin: 0 0 20px 0;
      padding: 0 2%;
    }
    @media (max-width: 567px) {
      width: 33.3%;
    }
    .img-link {
      position: relative;
      display: block;
      text-align: center;
      width: 100%;
      padding: 0px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      cursor: pointer;
      overflow: hidden;
      &:before {
        content: '';
        display: block;
        padding-top: 100%;
        overflow: hidden;
        background: #f2f2f2;
      }
      .mood-img {
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        align-items: center;
        .img {
          display: block;
          max-width: 100%;
          max-height: 100%;
          margin: auto;
          width: auto;
          height: auto;
          transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
        }
      }
    }
    &:hover {
      .img-link {
        /deep/ .el-image img {
          transform: scale(1.1);
        }
      }
    }
    label {
      width: 100%;
      height: 95px;
      cursor: pointer;
      h2 {
        margin: 0;
        padding: 15px 0 8px;
        font-size: 13px;
        max-width: 100%;
        line-height: 18px;
        word-break: break-all;
      }
    }
  }
  .forJustify {
    height: 1px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
}
.select-upload {
  float: right;
  height: 24px;
  /deep/ .el-checkbox__label {
    padding-left: 5px;
  }
  /deep/ .el-checkbox__inner {
    width: 16px;
    height: 16px;
    &::before {
      background-color: #FFF;
      height: 2px;
      transform: none;
      left: 2px;
      right: 0;
      top: 6px;
      width: 10px;
    }
  }
  .icon-download {
    font-size: 14px;
    color: #555;
    cursor: pointer;
    border-left: 1px solid #c0c4cc;
    margin-left: 10px;
    padding-left: 10px;
    .text-upload {
      font-size: 14px;
      color: #555;
      margin-right: 5px;
    }
  }
}
.el-checkbox {
  /deep/ .el-checkbox__input {
    .el-checkbox__inner {
      border-color: #C0C4CC;
      width: 16px;
      height: 16px;
      &::after {
        height: 8px;
        border-right-width: 2px;
        border-bottom-width: 2px;
      }
    }
    &.is-indeterminate {
      .el-checkbox__inner{
        border-color: #409EFF;
      }
    }
  }

}
.hover-checkbox {
  height: 20px !important;
  /deep/ .el-checkbox__input {
    visibility: hidden !important;
  }
  /deep/ .el-checkbox__label {
    display: none !important;
  }
}
.is-checked {
  /deep/ .el-checkbox__input {
    visibility: visible !important;
    .el-checkbox__inner{
      border-color: #409EFF;
      width: 16px;
      height: 16px;
      &::after {
        height: 8px;
        border-right-width: 2px;
        border-bottom-width: 2px;
      }
    }
  }
  /deep/ .el-checkbox__label {
    color: inherit;
  }
}

.album-list li:hover {
  /deep/ .el-checkbox__input {
    visibility: visible !important;
  }
}
/deep/.el-loading-mask {
  position: fixed;
}
.detail-dialog /deep/.el-loading-mask {
  position: absolute;
}
</style>
