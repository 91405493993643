<template>
  <div class="auth-bg">
    <b-container class="login">
      <div class="login-wrapper">
        <div class="separator text-center">
          <div>
            <!--<h1 style="font-size:16px; letter-spacing:-0.5px;">
                                <span class="mySysSLD" :style="{backgroundImage:'url('+imgUrl+')'}"  >{{$t("common.system")}}</span></h1>	-->
            <b>DRS</b> {{ $t("common.system") }}
          </div>
        </div>
        <div class="login-content">
          <b-form-input
            class="input mb-3"
            v-model="userEmail"
            :placeholder="$t('common.email')"
            :autofocus="true"
            @blur.native="handleBlur"
            @keyup.enter.native="handleLogin"
          ></b-form-input>
          <b-form-input
            class="input mb-3"
            type="password"
            v-model="password"
            :placeholder="$t('common.password')"
            @keyup.enter.native="handleLogin"
          ></b-form-input>
          <b-row>
            <b-col class="tl">
              <b-form-checkbox
                class="check-rember"
                id="checkbox-1"
                v-model="status"
                name="checkbox-1"
                value="accepted"
              >
                {{ $t("common.rember") }}
              </b-form-checkbox>
            </b-col>
            <b-col class="tr">
              <!--router-link :to="'/reset-password'">
                {{ $t('common.forgetPassword') }}</router-link-->
              <a :href="resetPwdUrl" class="forget-pw">{{ $t("common.forgetPassword") }}</a>
            </b-col>
          </b-row>
          <el-button
            native-type="button"
            type="primary"
            class="login-btn mt-5 white"
            block
            size="sm"
            variant="success"
            :loading="loading"
            @click="handleLogin"
            >{{ $t("common.login") }}</el-button
          >
        </div>
      </div>
      <div class="lang text-center">
        <div
          :class="[localLan == 'en' ? 'active' : '']"
          @click="changeLang('en')"
        >
          EN
        </div>
        <div
          :class="[localLan == 'sc' ? 'active' : '']"
          @click="changeLang('sc')"
        >
          简
        </div>
        <div
          :class="[localLan == 'tc' ? 'active' : '']"
          @click="changeLang('tc')"
        >
          繁
        </div>
      </div>
    </b-container>
    <footer class="footer">
      <b-container>
        <p class="text-center">
          <img
            src="@/assets/images/logo2.png"
            height="12"
            style="margin-right: 15px"
          />
          &copy; {{ year }} Steve Leung Design Group Limited.
          {{ $t("common.copyright") }}
        </p>
      </b-container>
    </footer>
  </div>
</template>

<script>
import { loginPrepare } from "@/api/login";
import { getYears } from "@/utils/date.js";

// import { setToken } from '@/utils/auth'
export default {
  name: "Login",
  data() {
    return {
      userEmail: "",
      password: "",
      status: "",
      localLan: "",
      imgUrl: require("../assets/images/logo.png"),
      verifycode: "",
      loading: false,
      year: getYears(),
      resetPwdUrl: process.env.VUE_APP_FCMS_URL + "#/reset",
      storLan: localStorage.getItem("language")
    };
  },
  watch: {
    storLan(newdata) {
      if (newdata) {
        location.reload();
      }
    }
  },
  created() {
    this.sessionLan = sessionStorage.getItem("language");
    if (!this.storLan) {
      if (this.sessionLan) {
        localStorage.setItem("language", this.sessionLan);
        this.storLan = localStorage.getItem("language");
      }
    }
    this.localLan = localStorage.getItem("language") || "tc";
    if (window.localStorage.getItem("forRegion")) {
      window.localStorage.removeItem("forRegion");
    }
    this.getVerify();
  },
  methods: {
    changeLang(type) {
      localStorage.setItem("language", type);
      sessionStorage.setItem("language", type);
      location.reload();
    },

    getVerify() {
      let that = this;
      loginPrepare().then((res) => {
        that.verifycode = res.data.verify;
      });
    },
    LGgc(a, d) {
      var c = "",
        al = a.length,
        dl = d.length,
        sl = 0;
      var g = "";
      for (var k = 0; k < al && k < dl; k++) {
        sl = (d[dl - 1 - k].charCodeAt(0) % 9) + 1;
        g = this.LGrs(sl, sl);
        c += g;
        c += this.LGsc(a[k], g[0]);
      }
      return c;
    },
    LGrs(i, j) {
      var c =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%^&*(),.;[]<>?:";
      var cl = c.length;
      var r = "";
      var rn = Math.round(Math.random() * (j - i)) + i;
      for (var k = 0; k < rn; k++) {
        r += c[Math.round(Math.random() * (cl - 1))];
      }
      return r;
    },
    LGsc(e, d) {
      var ei = e.charCodeAt(0);
      var di = (d.charCodeAt(0) % 4) + 1;
      return String.fromCharCode(ei + di);
    },

    handleBlur() {
      if (this.userEmail.indexOf("@") == -1) {
        // auto append @steveleung.com
        this.userEmail = this.userEmail + process.env.VUE_APP_MAIL_DOMAIN;
      }
    },
    handleLogin() {
      localStorage.setItem(
        "language",
        this.localLan == null ? "tc" : this.localLan
      );
      sessionStorage.setItem(
        "language",
        this.localLan == null ? "tc" : this.localLan
      );
      let that = this;
      if (!that.userEmail || !that.password) {
        return;
      }
      that.loading = true;
      const logindata = {
        email: this.userEmail,
        password: that.LGgc(this.password, this.verifycode),
        verify_code: this.verifycode
      };

      this.$store
        .dispatch("Login", logindata)
        .then(() => {
          this.loading = false;
			const redirectPath = this.$store.getters['page/get'].redirectPath
			if(redirectPath) {
				this.$router.push(redirectPath)
				//const nextUrl = this.$store.getters['page/get'].redirectPath
				//this.$store.commit('page/setRedirectPath', null)
				////only take the part behind #
				////this.$router.push(nextUrl.replace(/^.*#/,''))
				//this.$router.push(nextUrl)

			} else {
				this.$router.push('/home')
			}
          // this.$router.replace({
          //   // this.$route.query.path获取原来的地址,没有则走'/layout'
          //   path: this.$router.go(-1) || "/home"
          //   // path: "/home"
          // });
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
body {
  background: #f3f3f3;
}
#app {
  font-size: 14px;
  height: 100vh;
  min-height: 550px;
}
.login {
  /*padding-top: 21vh;*/
  .login-wrapper {
    border-radius: 3px 3px 3px 3px;
    box-shadow: 0px 0px 4px #cccccc;
    right: 0px;
    margin: 0px auto;
    /*margin-top: 5%;*/
    max-width: 380px;
    position: relative;
    padding: 0;
    background: #ffffff;
    z-index: 10;
    @media (max-width: 435px) {
      box-shadow: none;
    }
    .separator {
      background: #f0f0f0;
      padding: 15px 40px 9px;
      border-radius: 3px 3px 0 0;
      border-bottom: 1px solid #eaeaea;
      @media (max-width: 435px) {
        padding: 18px 0px 16px;
        background: none;
      }
      .mySysSLD {
        background-repeat: no-repeat;
        background-size: 69px 23px;
        background-position: 0px 0px;
        padding-left: 81px;
        height: 25px;
        display: inline-block;
      }
      div {
        font-size: 17px;
        font-weight: normal;
        line-height: 25px;
        margin: 10px 0 10px;
        text-align: center;
        //letter-spacing: -0.5px;
        b {
          font-family: "MyriadPro-Bold", "Helvetica Neue", Helvetica;
          font-size: 35px;
          line-height: 35px;
          letter-spacing: 1px;
          background: linear-gradient(to bottom, #838383 0%, #5a5a5a 120%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
    }
    .login-content {
      margin: 0 auto;
      padding: 40px 40px 40px;
      position: relative;
      text-align: center;
      text-shadow: 0 1px 0 #fff;
      min-width: 280px;
      font-size: 12px;
      .input {
        font-size: 12px;
        height: 38px;
      }
      .login-btn {
        width: 100%;
        background: #5cb85c;
        height: 38px;
        border-color: #5cb85c;
      }
      .forget-pw {
        color: #c63033;
        &:hover {
          color: #950c0f;
        }
      }
    }
  }
  div.lang {
    margin-top: 15px;
    line-height: 48px;
    width: 100%;
    text-align: center;
    div {
      font-family: Calibri, arial, SimHei, "黑体", sans-serif;
      font-size: 14px;
      color: #555555;
      border-left: 1px solid #999999;
      padding: 0 19px;
      display: inline-block;
      line-height: 11px;
      cursor: pointer;
      &:first-child {
        border: none;
        padding-left: 0px;
      }
      &:last-child {
        padding-right: 0px;
      }
      &:hover {
        color: #950c0f;
        background: none !important;
      }
    }
    div.active {
      color: #000 !important;
      font-weight: bold;
      background: none !important;
      &:hover {
        cursor: text;
      }
    }
  }
}
.check-rember {
  /deep/ .custom-control-input {
    margin-right: 5px;
  }
  .custom-control-label {
    margin-left: 5px;
  }
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 40px;
  font-size: 11px;
  font-weight: normal;
  background-color: #f5f5f5;
}
.auth-bg {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f3f3;
}
</style>
