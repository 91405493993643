<template>
  <div class="content" v-loading="loading">
    <b-container fluid>
      <b-row>
        <b-col>
          <div class="coCat tc mgt10">
            <el-select
              class="width125"
              v-model="select.sort"
              placeholder="排序方式"
              placeholder-color="#555"
              @change="sortChange"
            >
              <el-option
                v-for="item in sortOption"
                :key="item.id"
                :label="item.text"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </b-col>
      </b-row>
      <!-- product  -->
      <b-row class="deliverables" v-if="products">
        <b-col md="12" v-if="productList.length > 0">
          <div class="name mgb20 tc">
            <router-link to="/product" style="color: #999; font-size: 50%"
              >{{ $t("common.product") }} /</router-link
            >
            {{ $t("common.search_results") }}
          </div>
          <product-list
            :name="name"
            :product-list="products.productList.data"
            :lib-info="products.libInfo"
          ></product-list>

          <div class="flex-jus-center load-more" style="margin-top:40px; margin-bottom:100px;">
            <span v-if="products.productList.data.length < select.num"
              >{{ $t("common.loadover") }}</span
            >
            <b-button
              v-if="select.num <= products.productList.data.length"
              @click="LoadMore('products')"
              size="sm"
              variant="light"
              class="btn-load-more"
              >{{ $t("common.loadmore") }}</b-button
            >
          </div>
          <br /><br />
        </b-col>
      </b-row>
      <!-- deliverables  -->

      <b-row class="deliverables" v-if="deliverablist.length > 0">
        <b-col md="12">
          <div class="name mgb20 tc">
            <router-link to="/deliverable" style="color: #999; font-size: 50%"
              >{{ $t("welcome.design_deliverable") }} /</router-link
            >
            {{ $t("common.search_results") }}
          </div>
        </b-col>
        <b-col md="12">
          <deliverable-list
            :deliverable="deliverablist"
            :result-data="deliverables"
            :related-projects="relatedProjects"
          ></deliverable-list>
          <div class="flex-jus-center load-more" style="margin-top:-90px; margin-bottom:100px;">
            <span v-if="deliverables.list.length < select.num"
              >{{ $t("common.loadover") }}</span
            >
            <b-button
              v-if="select.num <= deliverables.list.length"
              @click="LoadMore('deliverables')"
              variant="light"
              size="sm"
              class="btn-load-more"
              >{{ $t("common.loadmore") }}</b-button
            >
            <!-- <span class="load-more">載入更多</span> -->
          </div>
        </b-col>
      </b-row>
      <!-- project-photo  -->
      <b-row class="deliverables" v-if="projectImages">
        <b-col md="12">
          <div>
            <router-link
              to="/guideline?mid=6"
              style="color: #999; font-size: 50%"
              >{{ $t("welcome.project_photos") }} /</router-link
            >
            {{ $t("common.search_results") }}
          </div>
          <br /><br />
        </b-col>
        <b-col
          md="12"
          v-if="projectImages.resList != null && projectImages.resList != ''"
        >
          <div v-for="ref in projectImages.refList" :key="ref.id">
            <h3 class="text-center">{{ ref.name }}</h3>
            <project-photo
              :deginref-list="projectImages.resList[ref.id]"
              :mid="ref.id"
            ></project-photo>
          </div>
        </b-col>
      </b-row>

      <!-- learnResource  -->
      <b-row
        class="deliverables"
        v-if="learnResource.resList != null && learnResource.resList != ''"
      >
        <b-col md="12">
          <div class="name mgb20 tc">
            <div>
              <router-link
                to="/guideline?mid=6"
                style="color: #999; font-size: 50%"
                >{{ $t("welcome.learning_resources") }} /</router-link
              >
              {{ $t("common.search_results") }}
            </div>
            <!-- <div class="coCat tc mgt10">
              <el-select class="width125" v-model="select.sort" :placeholder="$t('Strateg.product_cat')" placeholder-color="#555" @change="getchangevalue">
                <el-option
                  v-for="item in sortOption"
                  :key="item.id"
                  :label="item.text"
                  :value="item.id">
                </el-option>
              </el-select>
            </div> -->
          </div>
        </b-col>
        <b-col md="12" class="search-results">
          <div v-for="ref in learnResource.refList" :key="ref.id">
            <h3 class="text-center">{{ ref.name }}</h3>
            <design-list
              :list-data="learnResource.resList[ref.id]"
              :mid="ref.id"
            ></design-list>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { searchlist } from "@/api/product";
import designList from "@/components/Guideline/DesignList";
import productList from "@/components/ProductList.vue";
import deliverableList from "@/components/Guideline/Deliverable";
import projectPhoto from "@/components/Guideline/ProjectPhoto";
export default {
  components: {
    "design-list": designList,
    "product-list": productList,
    "deliverable-list": deliverableList,
    "project-photo": projectPhoto
  },
  data() {
    return {
      name: "product",
      mid: 3,
      loading: false,
      sortOption: [
        {
          id: '',
          text: "排序方式"
        },
        {
          id: "asc",
          text: "按更新時間升序"
        },
        {
          id: "desc",
          text: "按更新時間降序"
        }
      ],

      select: {
        sort: '',
        q: null,
        page: 1,
        num: 48,
        del_num: 50,
        del_page: 1
      },
      products: {},
      deliverables: {},
      projectImages: {},
      learnResource: {},
      productList: [],
      deliverablist: [],
      relatedProjects: []
    };
  },
  created() {
    this.select.q = this.$route.query.val;
    this.select.num = Number(this.$route.query.num) || 48;
    this.select.page = Number(this.$route.query.page) || 1;
    this.select.del_num = Number(this.$route.query.del_num) || 50;
    this.select.del_page = Number(this.$route.query.del_page) || 1;
    this.select.sort = this.$route.query.sort || '';
    this.getSearch();
  },
  watch: {
    $route() {
      this.select.q = this.$route.query.val;
      this.select.num = Number(this.$route.query.num) || 48;
      this.select.page = Number(this.$route.query.page) || 1;
      this.select.del_num = Number(this.$route.query.del_num) || 50;
      this.select.del_page = Number(this.$route.query.del_page) || 1;
      this.select.sort = this.$route.query.sort || '';
      this.getSearch();
    }
  },

  methods: {
    sortChange() {
      this.getSearch();
      this.$router.push(
        `/search?val=${this.select.q}&sort=${this.select.sort}`
      );
    },
    LoadMore(type) {
      if (type == "products") {
        if (this.products.productList.data.length >= this.select.num) {
          this.select.num = this.select.num + 48;
          this.getSearch();
        }
      } else {
        if (this.deliverables.list.length >= this.select.del_num) {
          this.select.del_num = this.select.del_num + 50;
          this.getSearch();
        }
      }
    },
    goPage(url) {
      this.$router.push(url);
    },
    getchangevalue() {},
    getSearch() {
      this.loading = true;
      searchlist(this.select).then((res) => {
        this.loading = false;
        let result = res.data;
        this.products = result.products;
        this.productList = result.products.productList.data;
        this.deliverables = result.deliverables;
        this.deliverablist = result.deliverables.list;
        this.projectImages = result.projectImages;
        this.learnResource = result.learnResource;
        this.relatedProjects = result.deliverables.relatedProjects;
        let reg = new RegExp(this.select.q, "ig");
        if (this.deliverablist && this.deliverablist.length > 0) {
          this.deliverablist.map((el) => {
            if (el.developer && el.developer.includes(this.select.q)) {
              el.developer = el.developer.replace(
                reg,
                `<span style="background: #fdff00">${this.select.q}</span>`
              );
            }
            if (el.name && el.name.includes(this.select.q)) {
              el.name = el.name.replace(
                reg,
                `<span style="background: #fdff00">${this.select.q}</span>`
              );
            }
            if (el.location && el.location.includes(this.select.q)) {
              el.location = el.location.replace(
                reg,
                `<span style="background: #fdff00">${this.select.q}</span>`
              );
            }
            if (el.project_no && el.project_no.includes(this.select.q)) {
              el.project_no = el.project_no.replace(
                reg,
                `<span style="background: #fdff00">${this.select.q}</span>`
              );
            }
            if (this.relatedProjects[el.id]) {
              this.relatedProjects[el.id].map((rel) => {
                if (rel.project_no && rel.project_no.includes(this.select.q)) {
                  rel.project_no = rel.project_no.replace(
                    reg,
                    `<span style="background: #fdff00">${this.select.q}</span>`
                  );
                }
              });
            }
            let optkeys = Object.keys(this.deliverables.optTypeList);
            optkeys.map((optkey) => {
              if (el.opt_type_ids == optkey) {
                if (
                  this.deliverables.optTypeList[optkey] &&
                  this.deliverables.optTypeList[optkey].includes(this.select.q)
                ) {
                  this.deliverables.optTypeList[optkey] =
                    this.deliverables.optTypeList[optkey].replace(
                      reg,
                      `<span style="background: #fdff00">${this.select.q}</span>`
                    );
                }
              }
            });
            if (el.involvedSfw) {
              for (const key in el.involvedSfw) {
                if (
                  el.involvedSfw[key] &&
                  el.involvedSfw[key].includes(this.select.q)
                ) {
                  el.involvedSfw[key] = el.involvedSfw[key].replace(
                    reg,
                    `<span style="background: #fdff00">${this.select.q}</span>`
                  );
                }
              }
            }
          });
        }
        if (this.projectImages && this.projectImages.length > 0) {
          this.projectImages.refList.map((ref) => {
            if (this.projectImages.resList[ref.id]) {
              this.projectImages.resList[ref.id].map((projectEl) => {
                if (projectEl.name && projectEl.name.includes(this.select.q)) {
                  projectEl.name = projectEl.name.replace(
                    reg,
                    `<span style="background: #fdff00">${this.select.q}</span>`
                  );
                }
              });
            }
          });
        }
        if (this.productList && this.productList.length > 0) {
          this.productList.map((productEl) => {
            if (productEl.name && productEl.name.includes(this.select.q)) {
              productEl.name = productEl.name.replace(
                reg,
                `<span style="background: #fdff00">${this.select.q}</span>`
              );
            }
            if (
              productEl.brand.name &&
              productEl.brand.name.includes(this.select.q)
            ) {
              productEl.brand.name = productEl.brand.name.replace(
                reg,
                `<span style="background: #fdff00">${this.select.q}</span>`
              );
            }
            if (
              productEl.brand_series.name &&
              productEl.brand_series.name.includes(this.select.q)
            ) {
              productEl.brand_series.name = productEl.brand_series.name.replace(
                reg,
                `<span style="background: #fdff00">${this.select.q}</span>`
              );
            }
            if (productEl.remark && productEl.remark.includes(this.select.q)) {
              productEl.remark = productEl.remark.replace(
                reg,
                `<span style="background: #fdff00">${this.select.q}</span>`
              );
            }
          });
        }
        if (
          this.learnResource &&
          this.learnResource.refList &&
          this.learnResource.refList.length > 0
        ) {
          this.learnResource.refList.map((learnRef) => {
            if (this.learnResource.resList[learnRef.id]) {
              this.learnResource.resList[learnRef.id].map((learnRes) => {
                if (learnRes.name && learnRes.name.includes(this.select.q)) {
                  learnRes.name = learnRes.name.replace(
                    reg,
                    `<span style="background: #fdff00">${this.select.q}</span>`
                  );
                }

                if (
                  learnRes.speaker &&
                  learnRes.speaker.includes(this.select.q)
                ) {
                  learnRes.speaker = learnRes.speaker.replace(
                    reg,
                    `<span style="background: #fdff00">${this.select.q}</span>`
                  );
                }

                if (learnRes.place && learnRes.place.includes(this.select.q)) {
                  learnRes.place = learnRes.place.replace(
                    reg,
                    `<span style="background: #fdff00">${this.select.q}</span>`
                  );
                }
              });
            }
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  padding: 25px 0 40px;
  overflow-x: hidden;
  .name {
    padding: 20px 0px 20px;
    font-size: 21px;
  }
}
/deep/ .el-input__inner {
  border: none;
  font-size: 13px;
  color: #555;
  text-align: center;
  padding-left: 0;
}
.deliverables {
  max-width: 1530px;
  margin: 0 auto;
}
.res-list {
  list-style: none;
  margin: 0 auto;
  /* max-width: 1100px; */
  width: 100%;
  padding: 0;
  text-align: center;
  text-align: justify;
  font-size: 0;
  margin-bottom: 50px;
  li {
    z-index: 0;
    display: inline-block;
    width: 19%;
    text-align: center;
    font-size: 15px;
    margin: 0px 0px 1% 0;
    padding: 15px;
    vertical-align: top;
    position: relative;
    @media (max-width: 1500px) {
      width: 20%;
    }
    @media (max-width: 1023px) {
      width: 33%;
    }
    @media (max-width: 767px) {
      margin: 0 0 20px 0;
      padding: 0 2%;
    }
    @media (max-width: 567px) {
      width: 50%;
    }
    .img-link {
      position: relative;
      padding: 0px;
      display: block;
      &:before {
        content: "";
        display: block;
        padding-top: 100%;
        overflow: hidden;
        background: #f2f2f2;
      }
      .mood-img {
        position: absolute;
        /* display: flex; */
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
        img {
          display: block;
          min-width: 100%;
          min-height: 100%;
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
          margin: auto 0;
          object-fit: cover;
          transition: transform 1s;
          transition-timing-function: ease;
        }
      }
    }
    &:hover {
      .img-link {
        img {
          transform: scale(1.1);
        }
      }
    }
    h2 {
      margin: 0;
      padding: 15px 0 8px;
      font-size: 18px;
      max-width: 100%;
      line-height: 26px;
      @media (max-width: 1023px) {
        padding: 15px 0 8px;
      }
      @media (max-width: 567px) {
        font-size: 15px;
      }
      @media (max-width: 375px) {
        font-size: 13px;
      }
    }
    .info {
      /* margin-top: 15px; */
      font-size: 10px;
      font-weight: normal;
      color: #999999;
      span {
        display: inline-block;
        color: #999999;
        margin: 0 3px 5px;
        &:first-child {
          display: block;
        }
      }
    }
  }
}
.search-results {
  h3 {
    font-size: 18px;
    font-weight: normal;
    margin: 35px 0 30px;
  }
  .no-result {
    text-align: center;
    margin-bottom: 80px;
    color: #999;
  }
}
.load-more {
  color: #999999;
  font-size: 13px;
  .btn-load-more {
      background: #a1a1a1;
      font-size: 13px;
      font-weight: normal;
      padding: 6px 10px;
      min-width: 160px;
      color: #fff;
      cursor: pointer;
      &:hover {
        background: #999;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
  }
}
</style>
