<template>
  <div id="viewer" class="pdfViewerContainer"></div>
</template>

<script>
import PDFJSExpress from '@pdftron/pdfjs-express-viewer'

export default {
  name: 'PDFViewer',
  props: {
    /**
     * pdf地址
     */
    pdfUrl: {
      type: String,
      default: '',
    },
    /**
     * pdf缩放比例
     */
    scale: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      pdfDocRef: null,
    }
  },
  mounted() {
    PDFJSExpress({
      path: '/pdfjsexpress',
      licenseKey: process.env.VUE_APP_PDFJSEXPRESS_LICENSE,
      initialDoc: this.pdfUrl,
      disabledElements: [
        'menuButton',
      ]
    }, document.getElementById('viewer'))
    .then(instance => {
        // now you can access APIs through the WebViewer instance
        const { Core, UI } = instance;

        console.log(this.$i18n.locale);
        if (this.$i18n.locale == 'tc') {
          UI.setLanguage('zh_tw');
        } else if (this.$i18n.locale == 'sc') {
          UI.setLanguage('zh_cn');
        } else {
          UI.setLanguage(this.$i18n.locale);
        }


        // adding an event listener for when a document is loaded
        Core.documentViewer.addEventListener('documentLoaded', () => {
          console.log('document loaded');
        });

        // adding an event listener for when the page number has changed
        // Core.documentViewer.addEventListener('pageNumberUpdated', (pageNumber) => {
        //   console.log(`Page number is: ${pageNumber}`);
        // });
      });
  },
}
</script>

<style scoped></style>
