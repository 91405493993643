<template>
  <div class="brand-product">
      <b-row>
        <b-col xs="12" v-if="productListByCat&& productListByCat.length>0">
            <template >
              <div class="product-title">{{$t('productfilter.products')}}</div>
            </template>
            <div v-for="(pItem,pIndex) in productListByCat" :key="pIndex" style="margin-bottom:40px;">
              <div>
                <div v-if="type == 1" class="product-type flex-between">
                  <h3 class="ft12 cat_name">{{pItem.p_name}}</h3>
                  <div class="ft12 all_cate" @click="goPage(pItem.p_id)">{{$t('sdxBrand.see_all')}}</div>
                </div>
                <div class="product-type flex-between"  v-else>
                  <div class="kind">活動家具</div>
                  <div class="btn">{{$t('sdxBrand.see_all')}}</div>
                </div>
              </div>
              <swiper  :options="swiperOption" ref="mySwiper" class="relProject">
              <swiper-slide class="slide" v-for="(item,index) in pItem.p_list"  :key="index">
                 <div class="slide-item" @click="getDetail(item.id,item.opt_id,item.subopt_id,item.input_mode)">
                   <div class="product-photo" >
                     <div class="product-photo-con">
                       <img :src="url+item.path" height="105" width="150" :alt="item.name">
                     </div>
                   </div>
                   <!-- <div class="product-photo fa fa-chain-broken"></div> -->
                   <div class="product-info posiR">
                      <!-- <div class="new"><span>New</span></div>	 -->
                      <!-- <h5 class="product-brand" v-if="item.brand != 25">
                        <span>{{item.bname}}</span>
                        <span>{{item.brand_series.name}}</span>
                      </h5> -->
                      <h2>{{item.name}}</h2>
                      <p class="product-code hide">{{item.code}}</p>
                      <!-- <p class="product-price">
                        <span style="font-size:12px; font-weight:normal;">參考零售價</span><br>
													查詢(另議)	<span class="product-updated"><br>(最後更新: 2020-07-13 )</span>
											</p> -->
                   </div>
                    <div class="product-count">
                      <b-col xs="6">
                        <span title="Viewed">
                          <i class="fa fa-eye"></i> 0
                        </span>&nbsp;
                        <span class="icon-wishlist" @click.once="addProductWish(item)">
                          <!-- <i class="fa  mgr3" :class="item.wished?'fa-heart':'fa-heart-o '"></i> -->
                          <span >0</span>
                        </span>
                      </b-col>
                      <b-col xs="6">
                        <div>
													<!-- <i class="icon-parcel"></i> <span>{{ __('views.products.index.remain_qry') }} : {{$pdt->stock_qty}} {{$unitTypes[$pdt->unit] or ''}}</span> -->
												</div>
                        <div>
												  <!-- <span>{{ __('views.products.index.lead_time') }}: {{$pdt->production_lead_time}} {{ __('views.products.index.day') }}</span> -->
												</div>
                      </b-col>
                    </div>
                 </div>

              </swiper-slide>
              <div class="swiper-pagination1 paginations" slot="pagination"></div>
            </swiper>
            
            </div>

        </b-col>
      </b-row>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  components: {
    swiper,
    'swiper-slide':swiperSlide
  },
  data(){
    return{
      swiperOption:{
          loop: false,
          autoplay: false,
          slidesPerView: 6,
          slidesPerGroup: 6,
          spaceBetween: 30,
          centeredSlides: false,
          watchOverflow: true,
          breakpoints: {
              567: {
                slidesPerView: 2,
                slidesPerGroup: 2,
              },
              1023: {
                slidesPerView: 3,
                slidesPerGroup: 3,
                spaceBetween: 15,
              },
          },
          pagination: {
          el: ".swiper-pagination1",
          clickable: true
        }
      },


      url:process.env.VUE_APP_IMG_URL,
    }

  },

  props:{
    productByCat:{
      type:Object
    },
    type:{
      type:Number,
      default:1
    },
    productListByCat:{

    }
  },
  created(){
  },
  methods:{
    goPage(id){
      // get the cat mapped detail
      var t = this.productByCat.catMapped.find((item) => item.id === id);

      console.log(t);

      if (t) {
        if (t.parent_id == 0) {
          var catParam = 'selectcat='+t.id;
        } else {
          var catParam = 'selectcat='+t.parent_id+'&selectsubcat='+t.id;
        }

      }

      this.$router.push(`/product?selectbrand=${this.productByCat.brand.id}&${catParam}`)
    },
    getDetail(id,optID,subOptID){
      this.$router.push(`/productdetail?id=${id}?&optID=${optID}&subOptID=${subOptID}`)
    }
  }


}
</script>

<style lang="scss" scoped>
.product-title{
  color: #333;
  font-size: 21px;
  padding: 20px 0;
}
.product-type{
  padding-bottom: 3px;
  border-bottom: 1px solid #dddddd;
  .kind{
    font-size: 15px;
    color: #999999;
  }
  .btn{
    font-size: 12px;
    color: #555555;
    &:hover{
      color: #c63033;
    }
  }
}
.relProject{
  margin-top: 20px;
  .slide-item{
    width: 100%;
    /*padding: 18px;*/
    font: bold 18px sans-serif;
    background-color: #ffffff;
    .product-photo{
      position: relative;
      display: block;
      text-align: center;
      /* box-shadow: 0 0 10px 5px #f3f3f3 inset; */
      width: 100%;
      margin-bottom: 5px;
      /* padding: 10px 10px; */
      padding: 0px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      cursor: pointer;
      &::before{
        content: "";
        display: block;
        padding-top: 100%;
      }
      .product-photo-con {
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        img{
          display: block;
          max-width: 100%;
          max-height: 100%;
          margin: auto;
          width: auto !important;
          height: auto;
        }
      }
    }
    .product-info{
      margin-top: 20px;
      .product-brand{
        font-size: 12px;
        text-transform: uppercase;
        margin-top: 12px;
        margin-bottom: 3px;
        max-width: 100%;
        min-height: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      h2{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        font-size: 17px;
        font-weight: normal;
        max-width: 100%;
        text-align: center;
      }
      .product-code{
        font-size: 12px;
        margin: 5px 0px 0px;
        color: #AAAAAA;
      }
      .product-price{
        font-size: 16px;
        margin: 15px 0 15px 0;
        display: none;
        .product-updated{
          font-size: 11px;
          font-weight: normal;
          font-style: italic;
          color: #AAAAAA;
        }
      }
    }
    .product-count{
      border-top: 1px solid #ddd;
      padding-top: 10px;
      display: none;
    }
  }
}
.cat_name{
  font-size: 14px;
  color: #999999;
}
.all_cate{
  font-size: 12px;
  cursor: pointer;
}
.all_cate:hover{
  color: #c63033;
}

.paginations {
  position: static;
  margin-top: 20px;
  span {
    cursor: pointer;
  }
}
/deep/ .swiper-pagination-bullet {
  width: 50px;
  border-radius: 0;
  opacity: 1;
  margin: 0 2px !important;
  padding: 15px 0;
  background: none;
  @media (max-width: 1024px) {
    width: 40px;
  }
  @media (max-width: 758px) {
    width: 30px;
  }
  @media (max-width: 375px) {
    width: 20px;
  }
  &::before {
    display: block;
    width: 100%;
    height: 2px;
    background: #d6d6d6;
    content: " ";
  }
  &:hover::before {
    background: #869791;
  }
}
/deep/ .swiper-pagination-bullet-active {
  background: none;
  &::before {
    background: #869791;
  }
}
</style>
