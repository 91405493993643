<template>
  <div class="content" v-loading="loading">
    <b-container fluid>
      <b-row>
        <b-col xs="12">
          <h5 class="name mgb20 tc">
            {{ $t("supplierfilter.supplier_rating") }}
          </h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="7" class="review-form ft14" v-if="reviewData != ''">
          <div class="form-item flex">
            <div class="item-th">{{ $t("supplierfilter.rating_target") }}:</div>
            <div class="item-td">{{ reviewData.com.name }}</div>
          </div>
          <div class="form-item flex">
            <div class="item-th">
              {{ $t("supplierfilter.rating_target_type") }}:
            </div>
            <div class="item-td">{{ reviewData.com.company_type_name }}</div>
          </div>
          <div class="form-item flex">
            <div class="item-th">
              {{ $t("supplierfilter.rating_project") }}:
            </div>
            <div class="item-td">
              <el-select
                popper-append-to-body
                :style="{ width: selectOptionWidth }"
                size="small"
                v-model="form.relevant_id"
                :placeholder="$t('supplierfilter.please_select')"
              >
                <el-option
                  v-for="(item, index) in reviewData.projects"
                  :key="index"
                  :label="item.project_no + '-' + item.name_en"
                  :value="item.id"
                  @click.native="setOptionWidth"
                >
                  <span style="float: left">{{
                    item.project_no + "-" + item.name_en
                  }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="form-item flex">
            <div class="item-th">{{ $t("supplierfilter.rating_rate") }}:</div>
            <div class="item-td">
              <el-rate
                v-model="form.rating"
                :icon-classes="['fa fa-star', 'fa fa-star', 'fa fa-star']"
                void-icon-class="fa fa-star"
                void-color="#e3e3e3"
              ></el-rate>
            </div>
          </div>
          <div class="form-item flex" style="align-items: flex-start">
            <div class="item-th">
              {{ $t("supplierfilter.rating_review") }}:
              {{ $t("supplierfilter.optional") }}
            </div>
            <div class="item-td">
              <el-input
                type="textarea"
                :rows="3"
                :placeholder="$t('supplierfilter.optional')"
                v-model="form.comment"
              >
              </el-input>
            </div>
          </div>
          <div class="form-item flex">
            <div class="item-th">
              {{ $t("supplierfilter.upload_attartch") }}:{{
                $t("supplierfilter.optional")
              }}
            </div>
            <div class="item-td">
              <el-button size="mini" class="flex" @click="setuploadDialog">
                <span
                  class="fa fa-paperclip"
                  style="font-size: 16px; margin-right: 6px"
                ></span>
                {{ $t("productfilter.Attachment") }}
              </el-button>
            </div>
          </div>
          <br />
          <div class="form-item flex">
            <div class="item-th"></div>
            <div class="item-td">
              <div class="mgb20">
                <div
                  class="mgb10"
                  v-for="(item, index) in fileList"
                  :key="index"
                >
                  <img class="mgr20" :src="item.url" width="160" height="160" />
                  <el-button
                    size="mini"
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    @click="handleRemove(item)"
                  ></el-button>
                </div>
              </div>
              <b-button size="sm" @click="submitFrom">{{
                $t("supplierfilter.submit")
              }}</b-button>
            </div>
          </div>
        </b-col>
        <b-col lg="4" offset-lg="1" class="deliverable-details tl">
          <div class="files">
            <span class="mgb20"
              >{{ $t("supplierfilter.related_document") }}:</span
            >
            <ul v-if="reviewData.relFiles && reviewData.relFiles.length > 0">
              <li
                class="flex-between mgb10"
                style="color: #6c757d"
                v-for="(item, index) in reviewData.relFiles"
                :key="index"
              >
                <p style="width: 70%">{{ item.filename }}</p>
                <p style="width: 20%" class="flex-jus-end">
                  <span>{{ item.file_size }}</span> &nbsp;&nbsp;
                  <span>{{ item.file_ext }}</span>
                </p>
              </li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <el-dialog class="upload-dialog flex" :visible.sync="uploadDialog" top="0">
      <div class="jconfirm-box">
        <el-upload
          ref="upload"
          class="upload-demo"
          :action="fileaction"
          drag
          multiple
          :before-upload="beforeUpload"
          :on-preview="handlePictureCardPreview"
          :on-change="hanleChangeFile"
          list-type="picture"
          :http-request="uploadFile"
          accept=".jpg,.jpeg,.png,.gif,.pdf,.JPG,.JPEG,.PBG,.GIF,.webp"
        >
          <i class="el-icon-upload"></i>
          <div class="dz-default dz-message">
            <span
              >{{ $t("supplierfilter.upload_drag") }}<br />（{{
                $t("supplierfilter.upload_width")
              }}：<span class="text-danger">600px</span>，{{
                $t("supplierfilter.upload_height")
              }}：<span class="text-danger">600px</span>）</span
            >
          </div>
        </el-upload>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { createrating, createratingfrom, uploadFile } from "@/api/supply";
export default {
  data() {
    return {
      selectOptionWidth: "",
      loading: false,
      uploadDialog: false,
      reviewData: [],
      form: {
        review_id: "",
        target_id: "",
        relevant_id: "",
        target_type: 1,
        comment: "",
        review_image: [],
        rating: null
      },
      fileList: [],
      fileaction: ""
    };
  },
  created() {
    this.form.review_id = this.$route.query.id;
    this.form.target_id = this.$route.query.tid;
    this.fileaction = process.env.VUE_APP_BASE_API;
    this.review_type = this.$route.query.review_type;
    this.createratinginfo();
  },
  methods: {
    setOptionWidth(event) {
      this.$nextTick(() => {
        if (event.target.localName == "span") {
          this.nodeIndex = event.target.clientWidth + 30;
        } else {
          this.nodeIndex = event.target.childNodes[0].clientWidth + 30;
        }
        this.selectOptionWidth = this.nodeIndex + "px";
      });
    },
    async createratinginfo() {
      this.loading = true;
      const target_data = {
        target_id: this.form.target_id
      };
      const review_data = {
        review_id: this.form.review_id
      };
      await createrating(
        this.review_type == "create" ? target_data : review_data
      ).then((res) => {
        this.loading = false;
        this.reviewData = res.data;
        if (this.review_type == "edit") {
          this.form.target_id = this.reviewData.review.target_id;
          this.form.relevant_id = this.reviewData.review.relevant_id;
          this.form.comment = this.reviewData.review.comment;
          this.form.rating = this.reviewData.review.rating;
          this.fileList = this.reviewData.fileList;
          this.staticInfo = this.reviewData.staticInfo;
          this.fileList.map((el) => {
            el.url = `${this.staticInfo.resPrefix}/${el.path}`;
          });
        }
      });
    },
    submitFrom() {
      if (this.verification()) {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)"
        });
        let ids = [];
        this.fileList.map((el) => {
          if (el.id) {
            ids.push(el.id);
          }
        });
        if (this.review_type == "edit") {
          this.editForm = {
            review_id: this.reviewData.review.id,
            target_id: this.reviewData.review.target_id,
            relevant_id: this.form.relevant_id,
            target_type: 1,
            comment: this.form.comment,
            review_image: this.form.review_image,
            rating: this.form.rating,
            "file-exist": ids
          };
        }
        createratingfrom(
          this.review_type == "edit" ? this.editForm : this.form
        ).then((res) => {
          if (res.code == 200) {
            loading.close();
            this.$message({
              message: this.$t("common.ratings_success"),
              type: "success"
            });
            setTimeout(() => {
              // this.$router.push(`/supplierdetail?id=${this.form.review_id}`);
              this.$router.push("/review");
            }, 2500);
          }
        });
      }
    },
    verification() {
      if (this.form.relevant_id == "") {
        this.$message.error(this.$t("common.ratings_mandatory"));
        return;
      }
      if (this.form.rating == "") {
        this.$message.error(this.$t("common.ratings_mandatory_rating"));
        return;
      }

      return true;
    },
    beforeUpload(file) {
      console.log(file);
      const isJpg =
        file.type === "image/jpg" ||
        file.type === "image/jpeg" ||
        file.type === "image/png";
      const islt2M = file.size / 1024 / 1024 < 2;
      if (!isJpg) {
        this.$message.error(this.$t("supplierfilter.upload_limit"));
        return false;
      }
      if (!islt2M) {
        this.$message.error(this.$t("supplierfilter.upload_size"));
        return false;
      }
    },
    handleRemove(file) {
      this.fileList.map((el, index) => {
        if (el.upload_ID && el.upload_ID == file.upload_ID) {
          this.fileList.splice(index, 1);
        }
        if (el.id && el.id == file.id) {
          this.fileList.splice(index, 1);
        }
      });
      this.form.review_image.map((reviewEl, index) => {
        if (file.upload_ID == reviewEl) {
          this.form.review_image.splice(index, 1);
        }
      });
    },
    hanleChangeFile(file, fileList) {
      const isJpg =
        file.raw.type === "image/jpg" ||
        file.raw.type === "image/jpeg" ||
        file.raw.type === "image/png";
      const islt2M = file.size / 1024 / 1024 < 2;
      if (!isJpg || !islt2M) {
        return false;
      }
      fileList.map((el) => {
        this.fileList.push(el);
      });
      this.$refs.upload.clearFiles();
    },
    uploadFile(item) {
      let obj = new FormData();
      obj.append("file", item.file);
      uploadFile(obj).then((res) => {
        this.form.review_image.push(res.data.upload_ID);
        let newFile = [];
        this.fileList.map((el, index) => {
          if (!el.id) {
            newFile.push(el);
            this.fileList[index].upload_ID =
              this.form.review_image[newFile.length - 1];
          }
        });
      });
    },
    setuploadDialog() {
      this.uploadDialog = true;
    },
    handlePictureCardPreview(file) {
      console.log(file, "查看");
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  padding: 25px 0 40px;
  overflow-x: hidden;
  .name {
    padding: 20px 0px 20px;
  }
  .review-form {
    .form-item {
      font-weight: normal;
      color: #999999;
      display: flex;
      min-height: 31px;
      margin-bottom: 8px;
      .item-th {
        color: #999;
        width: 158px;
        /* flex: auto; */
        padding-bottom: 5px;
        text-align: left;
      }
      .item-td {
        color: #555;
        /* flex: 1; */
        flex: auto;
        width: calc(100% - 158px);
        /* padding-bottom: 5px; */
        padding-right: 10px;
        text-align: left;
      }
    }
  }
  .deliverable-details {
    font-size: 14px;
    .files {
      color: #999;
    }
  }
}
.jconfirm-box {
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: #efefef;

  .dz-message {
    color: #555;
    .text-danger {
      color: #a94442;
    }
  }
}
.upload-dialog {
  padding: 40px 0;
}
/deep/ .el-upload-dragger {
  width: 100%;
  border: none;
  background: #efefef;
}
/deep/ .el-upload {
  display: block;
}
</style>
