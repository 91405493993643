<template>
  <!-- brand page -->
  <div class="brands">
    <div class="distributors" v-for="(item,index) in distributorData" :key="index">
      <h6>{{item.name}}</h6>
      <ul class='brand-list'  v-if="distributorData!=''&& distributorData.length>0">
        <li class="brand-item" v-for="(brand,inx) in item.brands" :key="inx">
          <div class="brand-photo" v-if="brand.logo_path != null"  @click="goPage(brand.id)">
            <div class="brand-photo-con">
              <b-img-lazy :src="imgurl+brand.logo_path" width="150" :alt="brand.name" ></b-img-lazy>
            </div>
          </div>
          <div class="posiR brand-info"  v-if="brand.logo_path != null">
            <!-- <h2 @click="goPage(brand.id)">
              {{brand.sname}}
            </h2> -->
            <ul class="brand-cat">
                                      
              <li v-for="(cate,inx) in brand.product_categories" :key="inx" @click="toProductPage(cate.pivot)"><span>{{cate.name}}</span></li>
                                      
            </ul>
            
          </div>
        </li>
        
      </ul>
      
    </div>
      

   
      <!--distributors  page -->
    

  </div>
</template>

<script>
export default {
    data(){
      return {
        imgurl:process.env.VUE_APP_IMG_URL,
      }
    },
    props: {
      distributorData:Array,
    },
    methods:{
      goPage(id) {
        this.$router.push(`/strategic/brand-detail?catId=${id}`);
      },
      toProductPage(item){
        this.$router.push(`/product?selectbrand=${item.brand_id}&selectcat=${item.cat_id}`);
      }
    }
}
</script>

<style lang="scss" scoped>
.brands{
  @media (max-width: 1530px) {
    padding: 0 7.5px;
  }
  h3{
    font-size: 17px;
    font-weight: normal;
    margin: 0 0 50px;
  }
  .brand-list{
    margin: 0 auto;
    max-width: 1560px;
    width: 100%;
    padding: 0;
    text-align: center;
    text-align: justify;
    font-size: 0;
    margin-bottom: 50px;
    .brand-item{
      display: inline-block;
      box-sizing: border-box;
      text-align: center;
      font: bold 18px sans-serif;
      background-color: #ffffff;
      /* border: 1px solid #DDDDDD; */
      /* max-width: 250px; */
      width: 16.66%;
      margin: 0 0 40px;
      padding: 15px;
      vertical-align: top;
      position: relative;
      @media (max-width: 1500px){
        /*width: 15.2%;*/
      }
      @media (max-width: 1200px) {
        width: 25%;
      }
      @media (max-width: 1023px) {
        
      }
      @media (max-width: 767px) {
        width: 33.33%;
      }
      @media (max-width: 567px) {
        width: 50%;
      }
      .brand-photo{
        position: relative;
        display: block;
        text-align: center;
        /* box-shadow: 0 0 10px 5px #f3f3f3 inset; */
        width: 100%;
        margin-bottom: 12px;
        /* padding: 10px 10px; */
        padding: 0px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        cursor: pointer;
        &::before{
          content: "";
          display: block;
          padding-top: 30%;
        }
        .brand-photo-con{
          position: absolute;
          display: flex;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          align-items: center;
          img{
            display: block;
            max-width: 100%;
            max-height: 100%;
            margin: auto;
            width: auto;
            height: auto;
          }
        }
      }
      .brand-info{
        margin: 0;
        font-size: 18px;
        line-height: 22px;
        max-width: 100%;
        
        h2{
          margin: 0;
          font-size: 18px;
          line-height: 22px;
          max-width: 100%;
          cursor: pointer;
        }
        .brand-cat{
          margin-top: 6px;
          li{
            display: inline-block;
            width: auto;
            font-size: 12px;
            font-weight: normal;
            color: #999999;
            border-right: 1px solid #CCCCCC;
            padding-right: 6px;
            line-height: 12px;
            margin: 0 5px 5px 0px;
            cursor: pointer;
            &:last-child{
              border: none;
              margin-right: 0px;
              padding-right: 0px;
            }
            span{
              color: #999;
            }
          }
        }
      }
    }
  }
  .distributors{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 auto;
    /* max-width: 1560px; */
    max-width: 1500px;
    width: 100%;
    padding: 0;
    text-align: center;
    text-align: justify;
    justify-content: space-between;
    font-size: 0;
    margin-bottom: 10px;
    /*border-top: 1px solid #EAEAEA;*/
    @media (max-width: 567px) {
      margin-bottom: 30px;
    }
    h6{
      font-size: 16px;
      line-height: 22px;
      /* color: #AAAAAA; */
      margin: 0px 0px 25px 0px;
      padding-top: 36px;
      width: 23%;
      text-align: center;
      text-align: left;
      border-top: 1px solid #999999;
      @media (max-width: 1200px) {
        width: 19%;
      }
      @media (max-width: 767px) {
        width: 24%;
      }
      @media (max-width: 567px) {
        width: 100%;
        text-align: center;
        border: 0;
      }
    }
    .brand-list{
      width: 75%;
      margin: 0px 0px 20px 0px;
      padding: 10px 0 0 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid #e7e7e7;
      @media (max-width: 1200px) {
        width: 80%;
      }
      @media (max-width: 767px) {
        width: 75%;
      }
      @media (max-width: 567px) {
        width: 100%;
        border: 0;
      }
    }
  }
}
</style>