<template>
  <div class="content" v-loading="loading">
    <b-container fluid>
      <b-row
        class="consultants"
        v-for="(item, index) in allData.resList"
        :key="index"
      >
        <div v-if="item.id == id">
          <b-col
            md="12"
            v-if="
              allData.fileByResList[item.id] &&
              Object.values(allData.fileByResList).length > 0
            "
          >
            <div class="name mgb20 tc">
              <div>
                <span>{{ refname }} /</span>
                {{ resListName }}
              </div>
              <div class="coCat tc mgt10" v-if="item.view_mode != 'v'">
                <el-select
                  popper-append-to-body
                  :style="{ width: selectOptionWidth }"
                  v-model="select.sort"
                  placeholder-color="#555"
                  @change="getchangevalue"
                >
                  <el-option
                    v-for="item in $t('common.sortOption')"
                    :key="item.value"
                    @click.native="setOptionWidth"
                    :label="item.text"
                    :value="item.value"
                  >
                    <span style="float: left">{{ item.text }}</span>
                  </el-option>
                </el-select>
              </div>
            </div>
          </b-col>

          <b-col md="12" v-if="item.view_mode == 'a'">
            <div
              class="album-action"
              v-if="allData.userTeam.is_design_team == 1"
            >
              <el-checkbox v-model="checkAll">{{
                $t("welcome.select_all")
              }}</el-checkbox>
              <button class="mgl15 download-btn" @click="downloadFile">
                <i class="fa fa-download"></i> {{ $t("welcome.download") }}
              </button>
            </div>
            <div class="album-statement" v-if="item.dd_only == 1">
              {{ $t("welcome.album_statement") }}
            </div>
            <template v-if="Object.values(allData.itemGrp).length > 0">
              <div v-for="(grp, inx) in allData.itemGrp" :key="inx">
                <h6 class="album-sub-head">{{ inx }}</h6>
                <ul class="album-list">
                  <li v-for="(album, i) in grp" :key="i">
                    <a
                      data-fancybox="gallery"
                      :data-caption="album.filename"
                      data-preload="false"
                      :href="url + album.path"
                      v-if="album.id != 33"
                    >
                      <div class="mood-img">
                        <img
                          class="b-lazy"
                          :src="url + album.path + '/s300'"
                          :data-src="url + album.path"
                        />
                      </div>
                    </a>
                    <a href="#" target="_blank" v-else>
                      <div class="mood-img">
                        <img
                          class="b-lazy"
                          :src="url + album.path + '/s300'"
                          :data-src="url + album.path"
                        />
                      </div>
                    </a>

                    <label>
                      <h2 v-if="album.id != 33">{{ album.filename }}</h2>
                      <span v-if="allData.userTeam.is_design_team == 1">
                        <input
                          type="checkbox"
                          name="fileSelected"
                          :value="album.id"
                        /><span class="checkmark"></span>
                      </span>
                    </label>
                  </li>
                </ul>
              </div>
            </template>
            <template v-else>
              <ul class="album-list">
                <li v-for="(album, i) in filList" :key="i">
                  <a
                    data-fancybox="gallery"
                    :data-caption="album.filename"
                    data-preload="false"
                    :href="url + album.path"
                    v-if="album.id != 33"
                  >
                    <div class="mood-img">
                      <img
                        class="b-lazy"
                        :src="url + album.path + '/s300'"
                        :data-src="url + album.path"
                      />
                    </div>
                  </a>
                  <a href="#" target="_blank" v-else>
                    <div class="mood-img">
                      <img
                        class="b-lazy"
                        :src="url + album.path + '/s300'"
                        :data-src="url + album.path"
                      />
                    </div>
                  </a>

                  <label>
                    <h2 v-if="album.id != 33">{{ album.filename }}</h2>
                    <span v-if="allData.userTeam.is_design_team == 1">
                      <input
                        type="checkbox"
                        name="fileSelected"
                        :value="album.id"
                      /><span class="checkmark"></span>
                    </span>
                  </label>
                </li>

                <li class="forJustify"></li>
                <li class="forJustify"></li>
                <li class="forJustify"></li>
                <li class="forJustify"></li>
                <li class="forJustify"></li>
                <li class="forJustify"></li>
                <li class="forJustify"></li>
              </ul>
            </template>
            <div class="album-statement" v-if="item.dd_only == 1">
              {{ $t("welcome.album_statement") }}
            </div>
            <div
              class="album-action"
              v-if="allData.userTeam.is_design_team == 1"
            >
              <el-checkbox v-model="checkAll">{{
                $t("welcome.select_all")
              }}</el-checkbox>
              <button class="mgl15 download-btn" @click="downloadFile">
                <i class="fa fa-download"></i> {{ $t("welcome.download") }}
              </button>
            </div>
          </b-col>

          <b-col md="12" v-else-if="item.view_mode == 'v'">
            <div class="main-preview-player flex-wrap flex-between">
              <div class="video-container">
                <video-player
                  class="video-player vjs-custom-skin"
                  ref="videoPlayer"
                  :playsinline="true"
                  :options="playerOptions"
                ></video-player>
              </div>
              <div class="playlist-container">
                <div class="vjs-playlist vjs-playlist-vertical">
                  <ol class="vjs-playlist-item-list">
                    <li
                      :class="[
                        'vjs-playlist-item',
                        videoSelectIndex == inx ? 'vjs-selected' : ''
                      ]"
                      v-for="(video, inx) in filList"
                      :key="inx"
                      @click="changeSelectVideo(video, inx)"
                    >
                      <div class="vjs-playlist-thumbnail-div">
                        <div
                          class="vjs-playlist-thumbnail vjs-playlist-now-playing"
                        >
                          <img
                            :src="url + video.thumb"
                            v-if="video.thumb != null"
                          />
                        </div>
                        <span
                          class="vjs-playlist-now-playing-text fa fa-play"
                        ></span>
                      </div>
                      <div class="vjs-playlist-title-container">
                        <p class="vjs-playlist-name" :title="video.filename">
                          {{ video.filename }}
                        </p>
                        <div class="vjs-playlist-counter">
                          <!-- Button trigger modal -->
                          <button
                            @click="getViews(video.id)"
                            type="button"
                            class="btn ft14 btn-secondary view-btn"
                          >
                            {{ $t("welcome.view_count") }}: {{ allData.viewCountList[video.id] || 0 }}
                          </button>
                        </div>
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
              <div class="video-info ft14">
                <div class="tr-info" v-if="item.speaker != null">
                  <div class="td">{{ $t("designresinfo.speaker") }}:</div>
                  <div class="td">{{ item.speaker }}</div>
                </div>

                <div class="tr-info" v-if="item.date != null">
                  <div class="td">{{ $t("designresinfo.date") }}:</div>
                  <div class="td">{{ item.date }}</div>
                </div>
                <div class="tr-info" v-if="item.place != null">
                  <div class="td">{{ $t("designresinfo.place") }}:</div>
                  <div class="td">{{ item.place }}</div>
                </div>
                <div class="tr-info" v-if="item.event_name != null">
                  <div class="td">{{ $t("designresinfo.event_name") }}:</div>
                  <div class="td">{{ item.event_name }}</div>
                </div>

                <div class="tr-info" v-if="item.event_org != null">
                  <div class="td">{{ $t("designresinfo.event_org") }}:</div>
                  <div class="td">{{ item.event_org }}</div>
                </div>
                <div class="tr-info" v-if="item.theme">
                  <div class="td">{{ $t("designresinfo.theme") }}:</div>
                  <div class="td">{{ item.theme }}</div>
                </div>
                <div class="tr-info" v-if="item.people">
                  <div class="td">{{ $t("designresinfo.people") }}:</div>
                  <div class="td">{{ item.people }}</div>
                </div>
                <div class="tr-info" v-if="item.audience">
                  <div class="td">{{ $t("designresinfo.audience") }}:</div>
                  <div class="td">{{ item.audience }}</div>
                </div>
                <div class="tr-info" v-if="item.brief">
                  <div class="td">{{ $t("designresinfo.brief") }}:</div>
                  <div class="td">{{ item.brief }}</div>
                </div>
              </div>
            </div>
            <el-dialog
              :title="$t('designresinfo.whoview')"
              class="dialog"
              :visible.sync="Dialog"
              width="45%"
            >
              <div v-loading="viewloading">
                <div class="modal-body">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">{{ $t("designresinfo.viewname") }}</th>
                        <th scope="col">{{ $t("designresinfo.viewcount") }}</th>
                        <th scope="col">{{ $t("designresinfo.lastview") }}</th>
                      </tr>
                    </thead>

                    <tbody id="modal-tbody">
                      <tr v-for="(views, index) in fileview" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ views.name }}</td>
                        <td>{{ views.count }}</td>
                        <td>{{ views.time }}</td>
                      </tr>
                      <!-- insert info here -->
                    </tbody>
                  </table>
                </div>
                <div class="modal-footer" slot="footer">
                  <!-- <button type="button" class="btn btn-secondary" @click="closedialog">{{$t("designresinfo.confirm")}}</button> -->
                  <el-button size="small" class="btn" @click="closedialog">{{
                    $t("designresinfo.confirm")
                  }}</el-button>
                </div>
              </div>
            </el-dialog>
          </b-col>
          <b-col md="12" v-else>
            <div
              class="consultant-list"
              v-if="
                allData.itemGrp && Object.values(allData.itemGrp).length > 0
              "
            >
              <div v-for="(grp, inx) in allData.itemGrp" :key="inx">
                <h6 class="album-sub-head">{{ inx }}</h6>
                <ul>
                  <li v-for="(album, i) in grp" :key="i">
                    <a @click="downloadUrlFile(album)">
                      <i class="fa" :class="fileExtType(album.file_ext)"></i>
                      <span>{{ album.filename }}</span>
                    </a>
                  </li>
                  <li
                    class="related-url"
                    v-if="grp.length > 0 && grp[0].related_url"
                  >
                    <a @click="$router.push(`/product?lib=${grp[0].related_id}`)"
                      ><i class="fa fa-link"></i
                      >{{ $t("welcome.related_url") }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="consultant-list" v-else>
              <ul>
                <li v-for="(file, index) in filList" :key="index">
                  <a @click="downloadUrlFile(file)">
                    <i :class="['fa', fileExtType(file.file_ext)]"></i>
                    <span>{{ file.filename }}</span></a
                  >
                </li>
                <li
                  class="related-url"
                  v-if="filList.length > 0 && filList[0].related_url"
                >
                  <a :href="filList[0].related_url"
                    ><i class="fa fa-link"></i
                    >{{ $t("welcome.related_url") }}</a
                  >
                </li>
              </ul>
            </div>
          </b-col>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { getDesignrefdetailList, getfileViews, getFiles, getUrls } from "@/api/design";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
export default {
  components: {
    "video-player": videoPlayer
  },
  data() {
    return {
      selectOptionWidth: "100px",
      mid: null,
      url: process.env.VUE_APP_IMG_URL,
      checkAll: false, //all selected
      sortOption: [
        {
          value: null,
          text: "排序方式"
        },
        {
          value: "asc",
          text: "按升序"
        },
        {
          value: "desc",
          text: "按降序"
        }
      ],

      select: {
        sort: ""
      },
      refname: "",
      loading: false,
      id: null,
      resListName: "",
      filList: [],
      allData: {},
      itemGrp: [],
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //Playback speed
        autoplay: true, //If true, playback starts when the browser is ready.
        muted: false, // Any audio will be eliminated by default
        loop: false, // Start over as soon as the video ends.
        preload: "auto", // It is recommended that the browser should start downloading the video data after <video> loads the element. Auto browser selects the best behavior and starts loading the video immediately (if the browser supports it)
        language: "zh-CN",
        aspectRatio: "16:9", // Place the player in smooth mode and use this value when calculating the dynamic size of the player. The value should represent a scale - two numbers separated by a colon (e.g. "16:9" or "4:3")
        fluid: true, // When true, the video.js Player will have the fluid size. In other words, it will scale to fit its container.
        sources: [
          {
            src: "",
            type: "video/mp4"
          }
        ],
        poster: "", //cover address
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此視頻無法播放，請稍後再試", //Allows overwriting the default information that is displayed when a media source cannot be played by video.js.
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true //Full screen button
        }
      },
      videoSelectIndex: 0,
      Dialog: false,
      fileview: [],
      viewloading: false
    };
  },
  mounted() {
    Fancybox.bind("[data-fancybox]", {
      // Your options go here
      Thumbs: false
    });
  },
  created() {
    this.id = this.$route.query.id;
    this.mid = this.$route.query.mid;
    if (this.id && this.mid) {
     this.getDesign();
    }
  },
  watch: {
    $route() {
      this.id = this.$route.query.id;
      this.mid = this.$route.query.mid;
      if (this.id && this.mid) {
        this.getDesign();
      }
    }
  },
  computed: {
    fileExtType() {
      return function (type) {
        if (type == "ppt" || type == "pptx") {
          return "fa-file-powerpoint-o";
        } else if (type == "pdf") {
          return "fa-file-pdf-o";
        } else if (type == "xlsx" || type == "xls") {
          return "fa-file-excel-o";
        } else if (type == "doc" || type == "docx") {
          return "fa-file-word-o";
        } else if (type == "rar" || type == "zip") {
          return "fa-file-archive-o";
        }
      };
    }
  },
  methods: {
    setOptionWidth(event) {
      this.$nextTick(() => {
        if (event.target.localName == "span") {
          this.nodeIndex = event.target.clientWidth + 30;
        } else {
          this.nodeIndex = event.target.childNodes[0].clientWidth + 30;
        }
        this.selectOptionWidth = this.nodeIndex + "px";
      });
    },
    getDesign() {
      // console.log("called getDesign");
      if (!this.mid || !this.id || !this.select) {
        return;
      }
      this.loading = true;
      getDesignrefdetailList(this.mid, this.id, this.select).then((res) => {
        this.loading = false;
        let result = res.data;
        this.allData = result;
        this.refname = result.ref.name;
        this.filList = result.itemList;
        let nameList = Object.keys(this.allData.itemGrp);
        nameList.map((el) => {
          this.allData.itemGrp[el].map((grp) => {
            if (grp.related_url) {
              let related_id = grp.related_url.split("=");
              grp.related_id = related_id.slice(-1)[0];
              console.log(grp.related_id);
            }
          });
        });
        console.log(this.filList);
        // this.resListName = result.resList[this.id].name;
        result.resList.forEach((element) => {
          if (element.id == this.id) this.resListName = element.name;
          if (element.view_mode == "v") {
            this.getFiles();
          }
        });
        this.itemGrp = this.changeArr(result.itemGrp);
        this.playerOptions["sources"][0]["src"] =
          this.url + result.itemList[0].path;
        if (result.itemList[0].thumb) {
          this.playerOptions["poster"] = this.url + result.itemList[0].thumb;
        } else {
          this.playerOptions["poster"] = null;
        }
      });
    },
    goPage(url) {
      this.$router.push(url);
    },
    getchangevalue() {
      this.getDesign();
    },
    downloadFile(album, type) {
      let data = {
        target: "designresattach",
        id: album.id,
        act: "rd"
      };
      this.loading = true;
      getFiles(data)
        .then((res) => {
          let fileData = [];
          fileData.push(res);
          let fileName = album.filename + "." + album.file_ext;
          let blob = new Blob(fileData, { type: res.type });
          if (type && album.file_ext == "pdf") {
            window.open(window.URL.createObjectURL(blob), "_blank");
          } else {
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = window.URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
          }

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    downloadUrlFile(album) {
      let data = {
        target: "designresattach",
        id: album.id,
        // act: "rd",
        act: "dl",
        mode: "url"
      };
      this.loading = true;
      getUrls(data)
        .then((res) => {
          let fileName = album.filename + "." + album.file_ext;
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = res;
          document.body.appendChild(elink);
          elink.click();
          document.body.removeChild(elink);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    changeArr(list) {
      let arr = [];
      for (let i in list) {
        arr.push(list[i]);
      }
      return arr;
    },
    getViews(id) {
      this.Dialog = true;
      this.viewloading = true;
      getfileViews(id).then((res) => {
        this.viewloading = false;
        this.fileview = res.data;
      });
    },
    closedialog() {
      this.Dialog = false;
    },
    changeSelectVideo(items, index) {
      this.videoSelectIndex = index;
      this.playerOptions["sources"][0]["src"] = this.url + items.path;
    },
    async getFiles() {
      let params = {
        target: "designresattach",
        id: this.allData.fileByResList[this.id][0].id,
        act: "rd"
      };
      await getFiles(params);
    }

    // let params ={
    //           target:'designresattach',
    //           id:id,
    //           act:'rd'
    //         }
    //         this.getFile(params);
  }
};
</script>

<style lang="scss" scoped>
.content {
  padding: 25px 0 40px;
  overflow-x: hidden;
  .name {
    padding: 20px 0px 20px;
    font-size: 21px;
    span {
      color: #999;
      font-size: 12px;
    }
  }
  .row {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }
}
/deep/ .el-input__inner {
  border: none;
  font-size: 13px;
  color: #555;
  text-align: center;
  padding-left: 0;
}
.width125 /deep/ .el-input__inner {
  width: 125px;
}
.width59 /deep/ .el-input__inner {
  width: 86px;
}
.width72 /deep/ .el-input__inner {
  width: 72px;
}
.width86 /deep/ .el-input__inner {
  width: 86px;
}
.width73 /deep/ .el-input__inner {
  width: 73px;
}
.width60 /deep/ .el-input__inner {
  width: 60px;
}

.consultants {
  max-width: 1500px;
  margin: 0 auto;
  text-align: center;
  .consultant-list {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    /* text-align: justify; */
    font-size: 0;
    display: inline-block;
    margin: 0px 1.5% 60px;
    vertical-align: top;
    h6 {
      font-size: 15px;
      color: #aaaaaa;
      text-align: left;
      margin: 0px 0px 15px;
    }
    > div {
      border-bottom: 1px solid #eee;
      padding-bottom: 10px;
      margin: 0px 0px 20px;
      &:last-child {
        margin-bottom: 0px;
        border: none;
      }
    }
    ul {
      padding: 0px;
      text-align: left;
      padding-left: 20px !important;
      li {
        background-color: #ffffff;
        margin: 0 0 15px;
        vertical-align: middle;
        position: relative;
        display: flex;
        a {
          flex: auto;
          font-size: 14px;
          cursor: pointer;
          i {
            color: #c63033;
            font-size: 14px;
            margin-right: 5px;
          }
          &:hover {
            color: #950c0f;
            i {
              color: #950c0f;
            }
          }
        }
      }
    }

    .related-url a {
      color: #c63033;
      font-size: 12px;
      text-align: left;
    }
  }
}
.album-action {
  font-size: 13px;
  text-align: right;
  margin-top: -20px;
  margin-bottom: 25px;
  line-height: 15px;
  color: #555;
  &:last-child {
    margin-bottom: 0;
  }
  .download-btn {
    background: none;
    border-left: 1px solid #cccccc;
    padding-left: 15px;
    line-height: 15px;
    cursor: pointer;
    color: #555;
  }
}
.album-statement {
  margin-bottom: 40px;
  color: #950c0f;
  font-size: 15px;
  font-style: italic;
}
h6.album-sub-head {
  font-size: 15px;
  color: #aaaaaa;
  margin: 0px 0px 25px;
}
.album-list {
  list-style: none;
  margin: 0 auto;
  /*max-width: 1100px;*/
  width: 100%;
  padding: 0 0 20px;
  text-align: center;
  text-align: justify;
  font-size: 0;
  margin-bottom: 30px;
  /*border-bottom: 1px solid #EEE;*/
  &:last-child {
    border-bottom: none;
  }
  li {
    z-index: 0;
    display: inline-block;
    width: 11.5%;
    text-align: center;
    font-size: 15px;
    margin: 0px 0px 1% 0;
    padding: 10px;
    vertical-align: top;
    position: relative;
    h2 {
      margin: 0;
      padding: 15px 0 8px;
      font-size: 13px;
      max-width: 100%;
      line-height: 18px;
      word-break: break-all;
    }
    a {
      position: relative;
      display: block;
      text-align: center;
      width: 100%;
      padding: 0px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      cursor: pointer;
      overflow: hidden;
      &:before {
        margin: 0 auto;
        color: #d3d3d3;
        font-size: 40px;
        content: "";
        display: block;
        padding-top: 100%;
      }
      .mood-img {
        /*position: relative;
          display: flex;
          align-items: center;
          overflow: hidden;*/

        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        align-items: center;
        img {
          display: block;
          max-width: 100%;
          max-height: 100%;
          margin: auto;
          width: auto;
          height: auto;
          transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out; /** Chrome & Safari **/
          -moz-transition: all 0.3s ease-in-out; /** Firefox **/
          -o-transition: all 0.3s ease-in-out; /** Opera **/
        }
      }
      &:hover .mood-img {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
  .forJustify {
    height: 1px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
.main-preview-player {
  align-items: stretch;
  .video-container {
    flex: 3 1 61%;
    max-width: 77%;
    margin: 0 auto;
    @media (max-width: 970px) {
      flex: 3 1 61%;
      max-width: none;
      margin: 0 auto;
      /*order: 2;*/
      margin-bottom: 15px;
      .playlist-container {
        min-height: 250px;
        /*order: 3;*/
      }
    }
  }
  .playlist-container {
    flex: 1 1 15%;
    position: relative;
    // min-width: 350px;
    min-height: 150px;
    /*height: 42vw; Wistkey*/
    height: auto;
    overflow-y: auto;
    @media (max-width: 1350px) {
      min-width: 350px;
      /*height: 39vw; Wistkey*/
    }
    @media (max-width: 1250px) {
      min-width: 350px;
      /*height: 37vw; Wistkey*/
    }
    @media (max-width: 1170px) {
      min-width: 350px;
      /*height: 36vw; Wistkey*/
    }
    @media (max-width: 1044px) {
      min-width: 350px;
      /*height: 34vw; Wistkey*/
    }
    .vjs-playlist {
      font-size: 13px !important;
      text-align: left;
      margin: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: none !important;
      .vjs-playlist-item-list {
        padding: 0;
        margin: 0;
      }
      .vjs-playlist-item {
        position: relative;
        cursor: pointer;
        overflow: hidden;
        display: block;
        margin-left: 2em;
        margin-bottom: 1em !important;
        @media (max-width: 970px) {
          margin-left: 0;
          padding: 0;
        }
        .vjs-playlist-thumbnail-div {
          width: 35%;
          position: relative;
          float: left;
          @media (max-width: 970px) {
            width: 120px;
          }
          &:before {
            content: "";
            display: block;
            padding-top: 56.2%;
            overflow: hidden;
            background: #ddd;
          }
          .vjs-playlist-thumbnail {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            img {
              display: block;
              min-width: 100%;
              min-height: 100%;
              max-width: 100%;
              max-height: 100%;
              width: auto;
              height: auto;
              margin: auto 0;
              object-fit: contain;
              transition: transform 1s;
              transition-timing-function: ease;
            }
          }
          .vjs-playlist-now-playing-text {
            top: auto !important;
            left: auto !important;
            bottom: 5px !important;
            right: 5px !important;
            margin: 0 !important;
            color: #fff !important;
            font-size: 12px !important;
            border-radius: 35%;
            padding: 3px 4px 3px 6px !important;
            background-color: rgba(26, 26, 26, 0.8);
            position: absolute;
          }
        }
        .vjs-playlist-title-container {
          position: relative !important;
          text-shadow: none !important;
          width: auto !important;
          margin-left: 36%;
          padding-top: 3px !important;
          padding-bottom: 0px !important;
          text-align: left;
          bottom: 0;
          box-sizing: border-box;
          padding: 0.5rem 0.8rem;

          @media (max-width: 970px) {
            margin-left: 120px;
          }
          .vjs-playlist-name {
            max-height: 100% !important;
            text-overflow: clip !important;
            white-space: normal !important;
            display: -webkit-box !important;
            -webkit-line-clamp: 2 !important;
            -webkit-box-orient: vertical !important;
            overflow: hidden !important;
            padding: 0 !important;
            line-height: 17px !important;
          }
          .vjs-playlist-counter {
            margin-top: 4px;
            font-size: 12px;
            color: #999;
            .view-btn {
              padding: 0px;
              /*background-color: #555;*/
              color: #999;
              line-height: 1.4;
              font-size: 12px;
              background: none;
              border: none;
            }
          }
        }
        &:hover {
          background: #eee;
        }
      }
      .vjs-selected {
        background: #eee;
        .vjs-playlist-thumbnail {
          img {
            opacity: 0.8 !important;
            filter: grayscale(50%);
          }
        }
      }
    }
    .vjs-playlist-vertical {
      overflow-y: auto;
      overflow-x: visible !important;
    }
  }
  .video-info {
    margin-top: 30px;
    margin-bottom: 30px;
    color: #555;
    text-align: left;
    flex: 3 1 61%;
    max-width: 77%;
    .tr-info {
      display: table-row;
      .td {
        display: table-cell;
        color: #555;
        vertical-align: top;
        padding-bottom: 6px;
        &:first-child {
          color: #999;
          padding-right: 20px;
          min-width: 90px;
        }
      }
    }
    @media (max-width: 970px) {
      max-width: none;
      /*order: 2;*/
      margin-top: 20px;
      border-top: 1px solid #eee;
      padding-top: 20px;
    }
  }
}
// @media (max-width: 1250px) {
//   .main-preview-player {
//     // height: auto;
//     // flex-direction: column;
//     // width: 100%;
//     .playlist-container {
//       max-width: 350px;
//       height: 300px;
//     }
//   }
// }
/deep/ .video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.dialog /deep/ .el-dialog__body {
  padding: 0;
}
.modal-footer {
  .btn {
    text-shadow: none;
    border: none;
    font-size: 14px;
    background-color: #ecf0f1;
    color: #000;
    &:hover {
      background-color: #bdc3c7;
    }
  }
}
.table {
  color: #555;
}
/deep/.el-loading-mask {
  position: fixed;
}
.detail-dialog /deep/.el-loading-mask {
  position: absolute;
}
</style>
