<template>
  <div>
      <el-dialog class="upload-dialog" :visible.sync="Dialog" width="600px" top="0"  @close="diaClose">
        <div class="catalog_details">
          <div class="pdp_info mgb18"  >
            <div class="pdp_title pdb10">
              <div id="selectoption"></div>
              <div class="ft21 bold tc pdt6 pdb6" >{{$t('sdxBrand.contact_vendor')}}</div>
            </div>
          </div>
          <div class="addtobag tl" v-if="Object.keys(contactData).length!=0">
              <div class="pdp_code" style="border-bottom:1px solid #EEE; margin-bottom:18px; padding-bottom:18px;"  v-if="contactData.defVenInfo != undefined ">
                <b style="display:block; padding-bottom:5px;">{{$t('productfilter.contactinfo')}}</b>
                <div style="display:inline-block; margin-right:20px; width:45%;">{{contactData.defVenInfo.name}}</div>
                <div style="display:inline-block;">{{$t('productfilter.Contactperson')}}: {{contactData.defVenInfo.contact_person}}</div>
                <br>
                <div style="display:inline-block; margin-right:20px; width:45%;">{{$t('productfilter.Email')}}: {{contactData.defVenInfo.email}}</div>
                <div style="display:inline-block;">{{$t('productfilter.Telephone')}}: {{contactData.defVenInfo.tel_1}}</div>
              </div>
              <div class="pdp_request">
                <b style="display:block; padding-bottom:5px;">{{$t('productfilter.contact_project_info')}}</b>
                <div class="multiselect mgb15" @click.stop="programChecked=!programChecked">
                  <b-button
                    variant="outline-secondary"
                  >{{submitData.project.name==''? $t('productfilter.projectNum'):submitData.project.name}}</b-button>
                  <b class="caret"></b>
                </div>
                <ul class="multiselect-container" v-show="programChecked">
                  <li class="multiselect-item multiselect-filter">
                    <div class="input-group">
                      <span class="input-group-addon">
                        <i class="fa fa-search"></i>
                      </span>

                      <b-form-input
                        class="form-control multiselect-search"
                        id="input-small"
                        size="sm"
                        v-model="projectsearchValue"
                        placeholder="Search"
                        @input="getSearch"
                      ></b-form-input>

                      <span class="input-group-btn">
                        <button
                          class="btn btn-default multiselect-clear-filter"
                          type="button"
                          @click="removeSearch"
                        >
                          <i class="fa fa-times-circle-o"></i>
                        </button>
                      </span>
                    </div>
                  </li>
                  <li class="muiselect-otheritem flex">
                    <b-form-radio name="plain-inline" disabled>&nbsp;&nbsp;&nbsp;{{$t('productfilter.projectNum')}}</b-form-radio>
                    
                  </li>
                  <li
                    class="muiselect-otheritem flex"
                    v-for="(item,index) in projectList"
                    :key="index"
                  >
                    <b-form-radio
                      name="plain-inline"
                      :value="item.id"
                      stacked
                      @change="getProgramRadio"
                    >&nbsp;&nbsp;&nbsp; {{ item.versionwithzero>0? item.project_no +"-"+item.versionwithzero:item.project_no }}{{ item.project_type!=null?"-"+item.project_type:"" }}  {{item.name_en}}</b-form-radio>
                    <!-- <label class="mgl10" for="index">{{item.name_en}}</label> -->
                  </li>
                </ul>
              </div>
              <div class="pdp_checkbox mgb15 flex flex-wrap">
                <b-form-checkbox class="pdr15"
                  v-model="programselected"
                  :value="item.type"
                  v-for="(item,index) in $t('Strateg.projectType1')"
                  :key="index"
                > &nbsp;{{item.text}}</b-form-checkbox>
              </div>
              <div class="mgb15">
                 <b style="display:block; padding-bottom:5px;">{{$t('productfilter.contact_product_info')}}</b>
                <b-form-textarea class="ft14"
                  v-model="submitData.msg"
                  :placeholder="$t('productfilter.msg')"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </div>
              <div class="pdp_checkbox mgb15">
                <b style="display:block; padding-bottom:5px;">{{$t('productfilter.contact_request')}}</b>
                <div class="flex">
                  <b-form-checkbox class="pdr15 "
                    v-model="programneed"
                    :value="item.type"
                    v-for="(item,index) in $t('Strateg.projectType2')"
                    :key="index"
                  > &nbsp;{{item.text}}</b-form-checkbox>
                </div>
              </div>
              <div class="mgt15">
                <b style="display:block; padding-bottom:5px;">{{$t('productfilter.time')}}</b>
                <b-form-datepicker
                  
                  :placeholder ="$t('productfilter.time')"
                  id="example-datepicker"
                  v-model="submitData.deadline"
                  class="mb-2 ft14"
                ></b-form-datepicker>
              </div>
              <div class="mgt15">
                <b style="display:block; padding-bottom:5px;">{{$t('productfilter.contact_mobile')}}</b>
                <div class="pdp_wechat ">
                  
                  <b-form-input
                    v-model="submitData.mobileNo"
                    :placeholder="$t('productfilter.Myphone')"
                  ></b-form-input>
                  <i class="fa  fa-mobile"></i>
                </div>
              </div>
              <div class="mgt15">
                <b style="display:block; padding-bottom:5px;">{{$t('productfilter.contact_wechat')}}</b>
                <div class="pdp_wechat">
                  <b-form-input
                    v-model="submitData.wechatNo"
                    :placeholder="$t('productfilter.Wechat')"
                  ></b-form-input>
                  <i class="fa  fa-weixin"></i>
                </div>
              </div>
              <div class="mgt15">
                <b style="display:block; padding-bottom:5px;">{{$t('productfilter.Attachment')}}</b>
                <div class="attachment " @click="uploadDialog=true">
                  <span
                    class="fa fa-paperclip"
                    style="font-size:16px; vertical-align:text-bottom; margin-left:1px; margin-right:11px;"
                  ></span>
                  {{$t('productfilter.Attachment')}}
                </div>
              </div>
            </div>
            <div class="btn_addtobag mgt10 tr" >
              <b-button class="btn-success"  @click="previewChange">{{$t('Strateg.contact_preview')}}</b-button>
            </div>
        </div>
    </el-dialog>

     <el-dialog class="preview-dialog" :visible.sync="previewDialog" width="48%" top="0">
       
       <div class="cat_preview tl"  v-if="Object.keys(contactData).length!=0">
         <div class="pdp_info">
           <div style="font-size:21px; font-weight: bold; text-align:center; padding:10px 0 5px;">邮件预览</div>
           <div style="font-size:13px; text-align:center; padding:0px 0 10px;">请确认以下资料是否正确</div>
         </div>
         <div v-if="contactData.defVenInfo != undefined ">{{contactData.defVenInfo.contact_person }}，您好！</div>
         <br>
         <br>
         我司想查询贵司的产品，烦请查看以下项目资料并尽早与我们设计师联系。 谢谢！<br><br>
         <h6>项目资料</h6>
         <div>
           <label>项目编号:</label>
           <span id="preview_project_text">{{submitData.project.project_no}} {{submitData.project.versionwithzero>0?`- ${submitData.project.versionwithzero}`:''}} <i class="fa  fce3 fa-pencil" @click.stop="closepreviewDialog"></i></span>
         </div>
         <div v-if="submitData.inc_pname == 1"><label>项目名称:</label> <span>{{submitData.project.name}}</span></div>
         <div v-if="submitData.inc_cname == 1"><label>客户名称:</label> <span>{{submitData.project.client}}</span></div>
         <h6>产品资料</h6>
         <div><label>品牌:</label> <span >{{submitData.brandName}}</span></div>
         <div><label>查询产品详情:</label> <span >{{submitData.msg}}<i class="fa fce3 fa-pencil" @click.stop="closepreviewDialog"></i></span></div>
         <h6>要求提供</h6>
         <div><span ><em v-if="this.programneed.includes(3)">{{$t('productfilter.project3')}}</em> <em v-if="this.programneed.includes(4)">{{$t('productfilter.project4')}}</em>  <i class="fa   fce3 fa-pencil" @click.stop="closepreviewDialog"></i></span></div>
         <h6>回复/汇报时间</h6>
         <div><span > {{submitData.deadline}} <i class="fa fce3 fa-pencil" @click.stop="closepreviewDialog"></i></span></div>
         <h6>负责设计师</h6>
         <div><label>名称:</label> <span >{{contactData.seeder.english_name}}{{contactData.seeder.lastname}}</span></div>
          <div><label>职位:</label> <span ></span>{{contactData.seeder.title_tc}}</div>
          <div><label>邮箱:</label> <span ></span>{{contactData.seeder.email}}</div>
         <div><label>公司地址:</label> 
              <span v-if="contactData.seeder.loc == 'HK'">梁志天設計師有限公司<br>香港九龍九龍灣宏泰道23號30樓</span>
              <span v-else-if="contactData.seeder.loc == 'BJ'">梁志天室内设计（北京）有限公司 <br>北京市朝阳区东直门外大街56号文创园B座101室  邮编: 100027</span>
              <span v-else-if="contactData.seeder.loc == 'SH'">梁志天室内设计（北京）有限公司上海分公司<br>上海市徐汇区田林路130号81号3楼  邮编: 200233</span>
              <span v-else>梁志天室内设计（北京）有限公司广州分公司<br>广州市海珠区新港中路397号自编创意大道8-1号  邮编: 510310</span>
          </div>
          <div><label>电话:</label> <span > {{contactData.seeder.direct}}</span></div>
          <div v-if="contactData.seeder.mobile1!='' || contactData.seeder.mobile2!=''"><label>手提电话:</label> <span >{{contactData.seeder.mobile1?contactData.seeder.mobile2?contactData.seeder.mobile1+' / '+contactData.seeder.mobile2:contactData.seeder.mobile1:contactData.seeder.mobile2}} <i class="fa fce3 fa-pencil" @click.stop="closepreviewDialog"></i></span></div>
          <div v-else-if="submitData.mobileNo != ''"><label>手提电话:</label> <span >{{submitData.mobileNo}} <i class="fa fce3 fa-pencil" @click.stop="closepreviewDialog"></i></span></div>
          <div><label>微信号:</label> <span >{{submitData.wechatNo}} <i class="fa fce3 fa-pencil" @click.stop="closepreviewDialog"></i></span></div>
       </div>
       <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="closepreviewDialog"  class="btn1" >取 消</el-button>
          <el-button size="small"  @click="sendFrom"   class="btn2">发送</el-button>
      </div>
     </el-dialog>

     <alert-tip v-if="alertShow" :type="alertType" :add-type="addType" @alertChange="closealertChange"></alert-tip>
  </div>
</template>

<script>
import { informsnotify } from "@/api/product";
import alerTip from '../components/AlertTip'
export default {
    components:{
      'alert-tip': alerTip,
    },
    data(){
        return{
            Dialog: false,
            programselected: [],
            programneed:[3],
            programChecked: false,
            submitData: {
              venID: 1,
              project: {
                id: "",
                project_no: "",
                versionwithzero: "",
                project_type: "",
                name: "",
                client: "",
                currency_id: ""
              },
              incPName: 0,
              incCName: 0,
              catName: "",
              brandName: "",
              seriesName: "",
              productsToAdd: [],
              msg: "",
              mobileNo: "",
              wechatNo: "",
              requestSample: 0,
              requestProposal: 0,
              deadline: "",
              orderId: ""
            },
            previewDialog:false,
            projectsearchValue:'',
            projectList:[],
            alertType:'success',
            alertShow:false,
            addType:'product'
            
        }
    },
    props:{
      isDialog:{ 
          type: Boolean,         
          default: false
      },
      contactData:{
            type: Object
      },
    },
    watch:{
      isDialog(oldVal,newVal){
        this.Dialog = this.isDialog
      },
      contactData(){
        this.submitData.venID = this.contactData.defVenInfo == undefined ? 1 : this.contactData.defVenInfo.id;
        this.projectList = this.contactData.projects;
      }
    },
    methods:{
      diaClose(){
          this.$emit('vendorChange', false)
      },
      getProgramRadio(e) {
        for (let i = 0; i < this.contactData.projects.length; i++) {
          if (e == this.contactData.projects[i].id) {
            this.submitData.project.id = this.contactData.projects[i].id;
            this.submitData.project.project_no = this.contactData.projects[i].project_no;
            this.submitData.project.versionwithzero = this.contactData.projects[i].versionwithzero;
            this.submitData.project.project_type = this.contactData.projects[i].project_type;
            this.submitData.project.name = this.contactData.projects[i].name_en;
            this.submitData.project.client = this.contactData.projects[i].cname_en;
            this.submitData.project.currency_id = this.contactData.projects[i].contract_currency;
          }
        }
        this.programChecked = !this.programChecked;
      },
      getprojectType(e) {
          // for(let key in e){
          //   if(e[key]==1){
          //       this.submitData.incPName = 1;
          //   } else{
          //       this.submitData.incPName = 0;
          //   }
          //   if(e[key]==2){
          //       this.submitData.incCName = 1;
          //   } else{
          //       this.submitData.incCName = 0;
          //   }
          //   if(e[key]==3){
          //       this.submitData.requestSample = 1;
          //   } else{
          //       this.submitData.requestSample = 0;
          //   }
          //   if(e[key]==4){
          //       this.submitData.requestProposal = 1;
          //   } else{
          //       this.submitData.requestProposal = 0;
          //   }

          // }
      },

      previewChange(){
        if(this.verification()){
          this.previewDialog =true;
        }
        
      },
      sendFrom(){
        if(this.programselected.includes(1)){
          this.submitData.incPName = 1;
        } 
        if(this.programselected.includes(2)){
          this.submitData.incCName = 1;
        }
        this.programneed.forEach(item=>{
          if(item==3){
            this.submitData.requestSample = 1;
          }
          if(item == 4){
            this.submitData.requestProposal = 1;
          }
        })
        this.alertShow = true;
        informsnotify(this.submitData).then(res => {
            if(res.code==200){
                this.alertShow = false;
                this.alertType = 'success';
                this.submitData.msg = '';
            }
        
        }).catch(err=>{
          this.alertShow = false;
          this.alertType = 'fail';
        });
      },

      verification() {
        let alertmsg=this.$t('productfilter.contact_alert_select_project');
          if (this.submitData.project.id == "") {
              this.$message.error(alertmsg);
              return;
          }
          if (this.submitData.msg == "") {
              this.$message.error("请输入通知内容！");
              return;
          }
       

        return true;
      },
      closepreviewDialog(){
        this.previewDialog = false;
      },
      getSearch(e){
        let _search = e.toLowerCase();
        let newListData = []; 
        if (_search) {        
          this.projectList.filter(item => {
            if ((item.project_no + item.name).toLowerCase().indexOf(_search) !== -1) {
                newListData.push(item);
            }
          }) 
          this.projectList = newListData;
        }else{
          this.projectList = this.contactData.projects;
        }
       
      },
      removeSearch(){
        this.projectsearchValue = '';
        this.projectList = this.contactData.projects;
      },
      closealertChange(val){
        this.alertShow = val; 
      }
    }
}
</script>

<style lang="scss" scoped>
.catalog_details{
  color: #555555;
  margin-top: -30px;
  .pdp_info{ 
    border-bottom: #eee 1px solid;
  }
  .addtobag {
    position: relative;
    width: 100%;
    .pdp_request {
      position: relative;
      .multiselect {
        width: 100%;
        position: relative;
        /deep/ .btn {
          width: 100%;
          text-align: left;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          -webkit-tap-highlight-color: transparent;
          font-size: 14px;
          &:hover {
            background-color: #e6e6e6;
            border-color: #adadad;
            color: #333;
          }
        }
        .caret {
          display: inline-block;
          width: 0;
          height: 0;
          margin-left: 2px;
          vertical-align: middle;
          border-top: 4px solid;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
          margin-left: 0;
          position: absolute;
          top: 50%;
          right: 12px;
          margin-top: -2px;
          vertical-align: middle;
        }
      }
      .open {
        /deep/ .btn {
          background-color: #e6e6e6;
          border-color: #adadad;
        }
      }
    }
    .multiselect-container {
      min-width: 200px;
      max-height: 50vh;
      overflow: scroll;
      overflow-x: hidden;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      // display: none;
      width: 100%;
      max-width: 100%;
      padding: 5px 0;
      margin: 2px 0 0;
      font-size: 14px;
      text-align: left;
      list-style: none;
      background-color: #fff;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      border: 1px solid #ccc;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
      li {
        .input-group {
          position: relative;
          margin: 5px;
          width: auto;
          .input-group-addon {
            padding: 6px 12px;
            font-size: 14px;
            font-weight: 400;
            line-height: 2;
            color: #555;
            text-align: center;
            background-color: #eee;
            border: 1px solid #ccc;
            border-radius: 4px;
          }
          .input-group-btn {
            border: 1px solid #ccc;
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
          }
        }
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
      }
      /deep/ .custom-control {
        font-size: 13px;
        color: #555555;
        padding: 0 20px;
        height: 22px;
        line-height: 22px;
        display: flex;
        align-items: center;
      }
      /deep/ .custom-control-label {
        margin-left: 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex: 1;
      }
    }
    .pdp_wechat {
      position: relative;
      .fa {
        position: absolute;
        top: 9px;
        left: 12px;
        font-size: 16px;
        width: 16px;
        text-align: center;
        line-height: 18px;
      }
      .fa-mobile {
        font-size: 20px;
      }
      /deep/ .form-control {
        padding-left: 35px;
        font-size: 14px;
      }
    }
  }
  .attachment {
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    &:hover {
      color: #333;
      background-color: #e6e6e6;
      border-color: #adadad;
    }
  }
}
/deep/ .btn-success{
  background-color: #5cb85c;
  border-color: #4cae4c;
  &:hover{
    background-color: #449d44;
    border-color: #398439;
  }
}

.preview-dialog{
  padding: 40px 0;
  .cat_preview{
    color: #555555;
    font-size: 14px;
    line-height: 20px;
    margin-top: -30px;
    .pdp_info{
      border-bottom: 1px solid #EEE;
      margin-bottom: 20px;
      padding-bottom: 10px;
    }
    h6{
      margin-top: 20px;
      font-weight: bold;
      font-size: 14px;
    }
    label{
      width: 100px;
      display: inline-block;
    }
    span{
      position: relative;
      display: inline-block;
      vertical-align: top;
      margin-bottom: 5px;
    }
  }
  .dialog-footer{
    button{
        text-shadow: none;
        border: none;
        font-size: 14px;
        background-color: #ecf0f1;
    }
    .btn1{
      background-color: #ecf0f1;
      color: #000;
      &:hover{
        background-color: #bdc3c7;
        color: #000;
      }
    }
    .btn2{
      color: #fff;
      background-color: #5cb85c;
      &:hover{
        background-color: #449d44;
      }
    }
  }
  /deep/ .el-dialog__footer{border-top: #ddd 1px solid;}
 
  
}
/deep/ .el-dialog{
  height: 100%;
}
/deep/ .el-dialog__body {
  max-height: 85%;
  overflow-x: hidden;
  overflow-y: auto;
  // padding: 0 0 20px;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
/deep/ .el-dialog__headerbtn{
  top: 5px;
  right: 10px;
  font-weight: bold;
  font-size: 20px;
}
.upload-dialog {
  padding: 40px 0;
  /deep/ .el-dialog{
    height: 85% !important;
    overflow: hidden;
    padding-bottom: 30px;
    @media (max-width: 650px) {
      width: 90% !important;
    }
  }
}
.upload-dialog /deep/ .el-dialog__body{
  height: 100%;
  @media (max-width: 650px) {
      max-height: 95% !important;
    }
}
</style>